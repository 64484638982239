import {Component, OnInit, ViewChild} from '@angular/core';
import {AggridBanComponent} from '../aggrid-ban/aggrid-ban.component';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {BanArchivoOcp, BandejaCompEgresos, BanDetBandejaPagos, BanEncBandejaPagos} from '../baninterfaces/banBandejaPagos';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {SegMaePermiso} from '../../advantage/advantageinterfaces/segmaepermiso';
import {Usuario} from '../../usuario';
import {PermisosService} from '../../advantage/advantageservices/permisos.service';
import {ConfirmationService, MessageService, SelectItem} from 'primeng';
import {BanbandejapagosService} from '../banservicios/banbandejapagos.service';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {DatePipe} from '@angular/common';
import {InvbusquedaService} from '../../inv/invservicios/invbusqueda.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';

@Component({
  selector: 'app-banbandejapagos',
  templateUrl: './banbandejapagos.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class BanbandejapagosComponent implements OnInit {
  
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  botonPrimero: boolean;
  botonAnterior: boolean;
  botonSiguiente: boolean;
  botonUltimo: boolean;
  btnBuscar: boolean;
  btnAnular: boolean;
  
  public frameworkComponents;
  rowStyle;
  defaultColBanPago;
  defaultColComEgreso;
  defaultDocsProv;
  rowSelection = 'multiple';
  largo: string;
  largoComp: string;
  
  // encBandejaPagos: BanEncBandejaPagos;
  // detBandejaPagos: BanDetBandejaPagos[];
  comprobantesEgreso: BandejaCompEgresos[];
  detalleSelecBandeja: BanDetBandejaPagos;
  detalleSelecComprobante: BandejaCompEgresos
  detArchivoOcp: BanArchivoOcp[];
  detalleSelecDocsProv: BandejaCompEgresos;
  comprobantesDocsprov:BandejaCompEgresos[];

  permisos: SegMaePermiso;
  
  strControlActivaOpciones: string;
  strControlAbrirDoc = '0';
  lblMensaje = '';
  
  selectTab = 0;
  editNumComprob = false;
  boolBtnAceptar = false;
  cmbNumEnvio: any[];
  strNumEnvio: any;
  
  orden: string;
  chkIncluirDetalle: boolean;
  
  // Busqueda
  
  displayDialogBus: boolean;
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  arregloBus: any[];
  where: string;
  opcion: string;
  
  // Configuracion
  public hmpConfiguracion: any[];
  strTIPDET: string;
  intCOMBPAUT = 0;
  strControlNuevoReg = '';
  
  // Clave Auth
  
  claveDialog: boolean;
  claveAuth: string;
  intNumReg = 0;
  accionClave: string;
  
  colorColumna = '#C2F8F8';
  porcentaje = 0;
  fecha: any;
  //CDPJ
  auxreporte: number = 0;
  strNombreForma: string;
  displayDialogReportes: boolean;
  asi_nro:any='';
  spin:boolean;
  displayAcciones: boolean;
  displayDocsprov:boolean;
  //CDPJ
  columnDefsBanPagos = [
    {
      headerName: 'Sel.', field: 'CHECK', cellRendererFramework: AggridBanComponent, editable: (params) => {
        return false;
      }, width: 50, onCellClicked: (params) => {
        this.docSeleccionado(params);
      }
    },
    {
      headerName: 'S.Exp', field: 'SEL_EXP', cellRendererFramework: AggridBanComponent, editable: false, width: 50
    },
    {
      headerName: 'Código Proveedor', field: 'PRO_CODIGO', editable: false, width: 175, onCellDoubleClicked: (params) => {
        // this.editCashMng(params.data);
      }
    },
    {
      headerName: 'Nombre Proveedor', field: 'PRO_NOMBRE', editable: false, width: 250, cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'F.Trans.', field: 'TRNCOBRO_FECHATRN', editable: false, width: 100, cellStyle: {background: this.colorColumna},
    },
    {
      headerName: 'Nro.Documento', field: 'DETBANDEJA_COMPROBANTE', editable: false, width: 150,
      cellStyle: { textAlign: 'left', background: this.colorColumna },
    },
    {
      headerName: 'Comprobante', field: 'TRNCOBRO_NROCOMPROBANTE', editable: false, width: 150,
      cellStyle: { textAlign: 'left', background: this.colorColumna }
    },
    {
      headerName: 'Concepto', field: 'TRNCOBRO_CONCEPTO', editable: false, width: 150,
      cellStyle: {textAlign: 'left', background: this.colorColumna},
    },
    {
      headerName: 'F.Venc.', field: 'TRNCOBRO_FECHAVENCE', editable: false, width: 100, cellStyle: { background: this.colorColumna},
    },
    {
      headerName: 'Importe', field: 'IMPORTE', width: 100, cellStyle: {textAlign: 'right'},
      editable: (params) => {
        return false
      }
    },
    {
      headerName: 'Pagado', field: 'PAGADO',  width: 100, cellStyle: { textAlign: 'right' },
      background: this.colorColumna,editable: (params) => {
        return false
      }
    },
    {
      headerName: 'Saldo', field: 'SALDO',  width: 100, cellStyle: { textAlign: 'right', background: this.colorColumna},editable: (params) => {
        return false
      }
    },
    {
      headerName: 'Importe', field: 'DETBANDEJA_IMPORTE', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: (params) => {
        const boolEdit = this.isEdit(params.data) ;
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            
          }
        }
    },
    {
      headerName: 'Retención', field: 'RETENCIONES',width: 100, cellStyle: { textAlign: 'right', background: this.colorColumna },
      editable: (params) => {
        return false
      }
    },
    {
      headerName: 'Dscto', field: 'DETBANDEJA_DESCUENTO', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: (params) => {
        const boolEdit = this.isEdit(params.data) ;
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            
          }
        }
    },
    {
      headerName: 'T.Dscto(F/V)', field: 'DETBANDEJA_TIPODSCTO', width: 100, cellStyle: { textAlign: 'right' }, editable: (params) => {
        const boolEdit = this.isEdit(params.data) ;
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            regexp: /^[F]$|^[V]$|^[f]$|^[v]$/,
            tamanocelda: 1,
            tienecalculos: true
          }
        }
    },
    {
      headerName: 'Devolución', field: 'NOTASCREDITO', editable: false, width: 100, cellStyle: {background: this.colorColumna }
    },
    {
      headerName: 'Nro.Cuotas', field: 'DETCASHMNG_IMPORTE', editable: false, width: 100, cellStyle: {background: this.colorColumna }
    },
    {
      headerName: 'T.Cuotas', field: 'DETCASHMNG_IMPORTE', editable: false, width: 100, cellStyle: {background: this.colorColumna }
    },
    {
      headerName: 'ID', field: 'DETBANDEJA_REF', editable: false, width: 100, cellStyle: {background: this.colorColumna }
    },
    {
      headerName: 'Expediente', field: 'EXP_NUMERO', editable: false, width: 100,
    }
  ];
  
  columnDefsBanComEgreso = [
    {
      headerName: 'Código Proveedor', field: 'PRO_CODIGO', editable: false, width: 175,
    },
    {
      headerName: 'Nombre', field: 'PRO_NOMBRE', editable: false, width: 250, cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'Comprobante', field: 'TRNCOBRO_NRODOC', editable: false, width: 150, cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'IMPORTE', field: 'IMPORTE', editable: false, width: 150, cellStyle: { textAlign: 'left' },
    },
  ];
  columnDefsDocxprov = [
    {
      headerName: 'Sel.', field: 'CHECK', cellRendererFramework: AggridBanComponent, editable: (params) => {
        return false;
      }, width: 50, onCellClicked: (params) => {
        this.docsProvSeleccionado(params);
      }
    },
    {
      headerName: 'Código Proveedor', field: 'PRO_CODIGO', editable: false, width: 175,
    },
    {
      headerName: 'Nombre', field: 'PRO_NOMBRE', editable: false, width: 250, cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'Comprobante', field: 'TRNCOBRO_NRODOC', editable: false, width: 150, cellStyle: { textAlign: 'left' },
    },
    {
      headerName: 'IMPORTE', field: 'IMPORTE', editable: false, width: 150, cellStyle: { textAlign: 'left' },
    },
  ];
  constructor(private init: NuevoComponentService, public usuario: Usuario, private permisosService: PermisosService,
              private message: MessageService, public bandejaPagoService: BanbandejapagosService,
              private errorService: ErroresBaseDatosService,  private datePipe: DatePipe,
              private busquedaSer: InvbusquedaService, private auditoriagral: AuditoriagralService,
              private confirmationService: ConfirmationService, private utilitariosService: UtilitariosService,
              private confIniciales: ConfInicialesService) {
    this.agTable();
    // this.bandejaPagoService.encBandejaPagos = {};
    this.bandejaPagoService.detBandejaPagos = [];
    this.comprobantesEgreso = [];
    this.comprobantesDocsprov =[];
    this.detArchivoOcp = [];
    
    this.bandejaPagoService.encBandejaPagos = {};
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    
    this.defaultColBanPago = {
      editable: true,
      objeto: 'bandejapagos',
      resizable: true
    };
    
    this.defaultColComEgreso = {
      editable: true,
      objeto: 'bandejacomegreso',
      resizable: true
    };
    this.defaultDocsProv ={
      editable:true,
      objeto: 'bandejadocsprov',
      resizable: true
    }
  }
  
  async ngOnInit() {
    this.largo = '450';
    this.largoComp = '580';
    
    this.btnBuscar = false;
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = true;
    this.botonAnterior = false;
    this.botonPrimero = false;
    this.botonSiguiente = false;
    this.botonUltimo = false;
    this.spin=false;
    this.displayAcciones = false;
    this.displayDocsprov = false;
    this.bandejaPagoService.encBandejaPagos = {
      BAN_BANCO: '',
      COM_CODIGO: '01',
      BAN_CODIGO: '',
      ENCBANDEJA_BENEFICIARIO: '',
      ENCBANDEJA_CODIGO: '',
      ENCBANDEJA_COMPROBANTE: '',
      ENCBANDEJA_CONCEPTO: '',
      ENCBANDEJA_ESTADO: '',
      ENCBANDEJA_FECDESDE: '',
      ENCBANDEJA_FECFIN: '',
      ENCBANDEJA_FECHAEMISION: new Date(),
      ENCBANDEJA_INCRET: '',
      ENCBANDEJA_INCTOD: '',
      ENCBANDEJA_NUMENVIO: '',
      ENCBANDEJA_PATHARCHIVO: '',
      ENCBANDEJA_TOTAL: '',
      USUIDENTIFICACION: '',
    };
    
    this.permisosService.getListaPermisos('BAN', '8', '61').subscribe((permiso) => {
      this.permisos = permiso[0];
    });
    
    this.configuracionInicial();
    
    this.displayDialogBus = false;
    this.arregloBus = [];
    this.opcion = '';
    
    const rs = await this.bandejaPagoService.obtenerConfiguracion();
    this.hmpConfiguracion = rs;
    this.strTIPDET = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'TIPDET').CFG_VALOR3;
    this.intCOMBPAUT = this.hmpConfiguracion.find(element => element.CFG_CODIGO === 'COMBPAUT').CFG_VALOR1;
    
    this.claveDialog = false;
    this.claveAuth = '';
    this.accionClave = '';
    this.detalleSelecComprobante={};//CDPJ
    this.detalleSelecDocsProv={}
  }
  //CDPJ
  ngOnDestroy(): void {
    this.confIniciales.instanciasBandejapago--;
  }
  //CDPJ
  async manejarSenales(valor) {
    if (valor === 'Primero') {
      this.seleccionarRegistro('P');
    }
    if (valor === 'Siguiente') {
      this.seleccionarRegistro('S');
    }
    if (valor === 'Anterior') {
      this.seleccionarRegistro('A');
    }
    if (valor === 'Ultimo') {
      this.seleccionarRegistro('U');
    }
    if (valor === 'Buscar') {
      this.buscarRegistro();
    }
    if (valor === 'Nuevo') {
      this.nuevoRegistro();
    }
    if (valor === 'Guardar') {
      this.guardar();
    }
    if (valor === 'Borrar') {
      this.eliminarRegistro();
    }
    if (valor === 'Cancelar') {
      this.cancelarRegistro();
    }
    if (valor === 'Aprobar') {
      this.autorizarBandejaPagos();
    }
    if (valor === 'GenerarArchivo') {
      if (this.obtenerPathImpExp()) {
        
        await this.archivoOcplistarDatos(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO);
        this.escribirArchivo();
        this.mensajeAlerta('info', 'Información', 'Archivo se generó satisfactoriamente');
      }
    }
    if (valor === 'CashMng') {
      // pichincha
      if (this.bandejaPagoService.encBandejaPagos.BAN_CODIGO === '003' || this.bandejaPagoService.encBandejaPagos.BAN_CODIGO === '02' ||
        this.bandejaPagoService.encBandejaPagos.BAN_CODIGO === '001' || this.bandejaPagoService.encBandejaPagos.BAN_CODIGO === '1') {
        this.generarAchivoCashFormato3();
      }
      // internacional
      if (this.bandejaPagoService.encBandejaPagos.BAN_CODIGO === '028' || this.bandejaPagoService.encBandejaPagos.BAN_CODIGO === '029') {
        this.cashBancoInternacional();
      }
      
      // produbanco
      if (this.bandejaPagoService.encBandejaPagos.BAN_CODIGO === '023' || this.bandejaPagoService.encBandejaPagos.BAN_CODIGO === '024') {
        this.cashBancoProdubanco();
      }
      
    }
    //CDPJ
    if (valor === 'Imprimir') {
      this.strNombreForma = this.init.tabs[this.init.tabindex].component;
      this.auxreporte++;

      const data= await this.bandejaPagoService.obtenerAsiNro(this.detalleSelecComprobante.TRNCOBRO_NRODOC)
      if(data !== null && data !== undefined){
        for(const data1 of data){
          if(data1.ASI_NRO !== null && data1.ASI_NRO !== undefined && data1.ASI_NRO !==''){
            this.asi_nro=data1.ASI_NRO;
          }
        }
      }
      console.log('asi_nro',this.asi_nro)
      this.displayDialogReportes = true;
    }
    //CDPJ
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  //CDPJ
  cerrarReportes() {
    this.displayDialogReportes = false;
  }
  //CDPJ
  async seleccionarRegistro(strTipo) {
    if (this.permisos != null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERSELECCION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , no tiene permiso de Selección. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }
    let strEncbandejaCodigo = '';
    
    try {
      const rs = await this.bandejaPagoService.navegarRegistro(strTipo, this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO);
      if (rs !== null) {
        strEncbandejaCodigo = rs[0].ENCBANDEJA_CODIGO === null ? '' : rs[0].ENCBANDEJA_CODIGO;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
    }
    if (strEncbandejaCodigo !== '') {
      this.mostrarDatos(strEncbandejaCodigo);
    }
  }
  
  async mostrarDatos(strEncBanCodigo) {
    this.limpiarDatos();
    this.bandejaPagoService.detBandejaPagos = [];
    this.comprobantesEgreso = [];
    this.comprobantesDocsprov = [];
    let value;
    
    try {
      const rs = await this.bandejaPagoService.obtenerDatosEnc(strEncBanCodigo);
      if (rs.length > 0) {
        this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO = rs[0].ENCBANDEJA_CODIGO === null ? '' : rs[0].ENCBANDEJA_CODIGO;
        this.bandejaPagoService.encBandejaPagos.BAN_BANCO = rs[0].BAN_BANCO === null ? '' : rs[0].BAN_BANCO;
        this.bandejaPagoService.encBandejaPagos.BAN_CODIGO = rs[0].BAN_CODIGO === null ? '' : rs[0].BAN_CODIGO;
        this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CONCEPTO = rs[0].ENCBANDEJA_CONCEPTO === null ? '' : rs[0].ENCBANDEJA_CONCEPTO;
        this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_COMPROBANTE = rs[0].ENCBANDEJA_COMPROBANTE === null ? '' : rs[0].ENCBANDEJA_COMPROBANTE;
        this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_ESTADO = rs[0].ENCBANDEJA_ESTADO === null ? '' : rs[0].ENCBANDEJA_ESTADO;
        this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_BENEFICIARIO = rs[0].ENCBANDEJA_BENEFICIARIO === null ? '' : rs[0].ENCBANDEJA_BENEFICIARIO;
        this.editNumComprob = true;
        this.boolBtnAceptar = true;
        
        for (const a of this.cmbNumEnvio) {
          if (Number(a.label) === rs[0].ENCBANDEJA_NUMENVIO) {
            this.strNumEnvio = {label: a.label};
          }
        }
        
        if ((rs[0].ENCBANDEJA_INCRET === null ? '0' : rs[0].ENCBANDEJA_INCRET ) === '0') {
          this.chkIncluirDetalle = false;
        } else {
          this.chkIncluirDetalle = true;
        }
        
        value = rs[0].ENCBANDEJA_TOTAL === 0 ? 0 : rs[0].ENCBANDEJA_TOTAL;
        value = Number(value).toFixed(2);
        this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_TOTAL = value;
        this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_FECHAEMISION = rs[0].ENCBANDEJA_FECHAEMISION === null ? '' : new Date(rs[0].ENCBANDEJA_FECHAEMISION);
        this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_PATHARCHIVO = rs[0].ENCBANDEJA_PATHARCHIVO === null ? '' : rs[0].ENCBANDEJA_PATHARCHIVO;
        this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_NOMESTADO = await this.nombreEstado(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_ESTADO);
        this.strControlAbrirDoc = '1';
        this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_TOTAL = this.init.moneyValidation(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_TOTAL);
        
        // Mostrar detalle
        this.mostrarDocumentos(strEncBanCodigo);
        this.strControlAbrirDoc = '0';
        this.listarDatos(strEncBanCodigo);
        
        const dia = this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_FECHAEMISION.getDate();
        const mes = this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_FECHAEMISION.getMonth();
        const anio = this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_FECHAEMISION.getFullYear();
        
        
        if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
          this.strControlActivaOpciones = 'NoModificar';
        }
        
        
        if (await this.verificarDocumentoConciliado() !== 0) {
          this.strControlActivaOpciones = 'NoModificar';
          this.lblMensaje = 'Documento conciliado';
        }
        
        if (this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_ESTADO === 'T') {
          this.strControlActivaOpciones = 'NoModificar';
        }
        
        if (this.strControlActivaOpciones === 'NoModificar') {
          this.activaBotones('NoModificar', false, true, true, true, false,
            false, false, false, false, true, true, true,
            true, false, false);
        } else {
          this.activaBotones('NAV', false, true, true, false, false, false,
            false, false, false, false, false, false,
            false, false, false);
        }
        this.aggrid.bloquearGrid();
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
    }
  }
  
  async mostrarDocumentos(strTrnCobroNroDoc) {
    let dblPagado = 0;
    let dblRetencion = 0;
    let dblSaldo = 0;
    let dblTrnCobroImporte = 0;
    let dblSaldoOriginal = 0;
    let dblTrnDocumentoDescuento = 0;
    let dblTrnDocumentoImporte = 0;
    let dblNotasCredito = 0;
    let intIndiceLista = 0;
    
    try {
      const rs = await this.bandejaPagoService.mostrarDocumentos(strTrnCobroNroDoc);
      if (rs.length > 0) {
        for (const dato of rs) {
          const item: BanDetBandejaPagos = {};
          dblNotasCredito = dato.NOTASCREDITO === 0 ? 0 : dato.NOTASCREDITO;
          item.NOTASCREDITO = Number(dblNotasCredito).toFixed(2);
          
          dblPagado = dato.PAGADO === 0 ? 0 : dato.PAGADO;
          item.PAGADO = Number(dblPagado).toFixed(2);
          
          dblRetencion = dato.RETENCIONES === 0 ? 0 : dato.RETENCIONES;
          item.RETENCIONES = Number(dblRetencion).toFixed(2);
          
          dblSaldo = dato.SALDO === 0 ? 0 : dato.SALDO;
          item.SALDO = Number(dblRetencion).toFixed(3);
          
          dblTrnCobroImporte = dato.IMPORTE === 0 ? 0 : dato.IMPORTE;
          item.IMPORTE = Number(dblTrnCobroImporte).toFixed(2);
          
          dblTrnDocumentoDescuento = dato.DETBANDEJA_DESCUENTO === 0 ? 0 : dato.DETBANDEJA_DESCUENTO;
          item.DETBANDEJA_DESCUENTO = Number(dblTrnDocumentoDescuento).toFixed(2);
          
          dblTrnDocumentoImporte = dato.DETBANDEJA_IMPORTE === 0 ? 0 : dato.DETBANDEJA_IMPORTE;
          item.DETBANDEJA_IMPORTE = Number(dblTrnDocumentoImporte).toFixed(2);
          
          dblSaldoOriginal = dblSaldo + dblTrnDocumentoImporte;
          item.SALDO_ORIGINAL = Number(dblSaldoOriginal).toFixed(2);
          item.NUM_CUOTAS = 0;
          item.TOTAL_CUOTAS = 0;
          item.TRNCOBRO_CONCEPTO = dato.TRNCOBRO_CONCEPTO === null ? '' : dato.TRNCOBRO_CONCEPTO;
          item.TRNCOBRO_FECHATRN = dato.TRNCOBRO_FECHATRN === null ? '' : dato.TRNCOBRO_FECHATRN;
          if (item.TRNCOBRO_FECHATRN.toString().includes('-') && item.TRNCOBRO_FECHATRN !== '') {
            item.TRNCOBRO_FECHATRN  = this.datePipe.transform(item.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
          }
          item.TRNCOBRO_FECHAVENCE = dato.TRNCOBRO_FECHAVENCE === null ? '' : dato.TRNCOBRO_FECHAVENCE;
          if (item.TRNCOBRO_FECHAVENCE.toString().includes('-') && item.TRNCOBRO_FECHAVENCE !== '') {
            item.TRNCOBRO_FECHAVENCE  = this.datePipe.transform(item.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
          }
          item.DETBANDEJA_COMPROBANTE = dato.DETBANDEJA_COMPROBANTE === null ? '' : dato.DETBANDEJA_COMPROBANTE;
          item.DETBANDEJA_REF = dato.DETBANDEJA_REF === null ? '' : dato.DETBANDEJA_REF;
          item.DETBANDEJA_TIPODOC = dato.DETBANDEJA_TIPODOC === null ? '' : dato.DETBANDEJA_TIPODOC;
          item.DETBANDEJA_TIPODSCTO = dato.DETBANDEJA_TIPODSCTO === null ? '' : dato.DETBANDEJA_TIPODSCTO;
          item.TRNCOBRO_NROCOMPROBANTE = dato.TRNCOBRO_NROCOMPROBANTE === null ? '' : dato.TRNCOBRO_NROCOMPROBANTE;
          item.PRO_CODIGO = dato.PRO_CODIGO === null ? '' : dato.PRO_CODIGO;
          item.PRO_NOMBRE = dato.PRO_NOMBRE === null ? '' : dato.PRO_NOMBRE;
          item.CONTROL_GRABAR = '1';
          item.EXP_NUMERO = dato.EXP_NUMERO === null ? '' : dato.EXP_NUMERO;
          item.CHECK = true;
          item.SEL_EXP = false;
          
          // hashcontenidoCheck.put(1+"/"+(intIndiceLista+1),true);
          intIndiceLista = intIndiceLista + 1;
          this.bandejaPagoService.detBandejaPagos.push(item);
        }
       // this.aggrid.refreshColumnDefs();
        this.aggrid.refreshaggrid(this.bandejaPagoService.detBandejaPagos, this.defaultColBanPago.objeto);
      } else {
        this.bandejaPagoService.detBandejaPagos = [];
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
    }
    
    
  }
  
  async listarDatos(strEncBandejaCodigo) {
    let dblPagado = 0;
    
    try {
      const rs = await this.bandejaPagoService.listarDatos(strEncBandejaCodigo);
      if (rs.length > 0) {
        for (const dato of rs) {
          const item: BandejaCompEgresos = {};
          item.TRNCOBRO_NRODOC = dato.TRNCOBRO_NRODOC === null ? '' : dato.TRNCOBRO_NRODOC;
          item.PRO_CODIGO =  dato.PRO_CODIGO === null ? '' : dato.PRO_CODIGO;
          item.PRO_NOMBRE = dato.PRO_NOMBRE === null ? '' : dato.PRO_NOMBRE;
          dblPagado = dato.IMPORTE === 0 ? 0 : dato.IMPORTE;
          item.IMPORTE = Number(dblPagado).toFixed(2);
          
          this.comprobantesEgreso.push(item);
          this.comprobantesDocsprov.push(item);
        }
        // this.aggrid.refreshColumnDefs();
        this.aggrid.refreshaggrid(this.comprobantesEgreso, this.defaultColComEgreso.objeto);
      } else {
        this.comprobantesEgreso = [];
        this.comprobantesDocsprov = [];
      }
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
    }
  }
  
  activaBotones(strTipo, bolNuevo, bolGuardar, bolCancelar, bolEliminar, bolInicio, bolAnterior, bolSiguiente, bolUltimo, bolBuscar,
                bolAutorizar, bolTransferir, bolEliminarAsiento, bolGenerarAsiento, bolVisualizarAsiento, bolImprimir) {
    this.btnNuevo = bolNuevo;
    this.btnBorrar = bolEliminar;
    this.btnGuardar = bolGuardar;
    this.btnRegresar = bolCancelar;
    this.botonPrimero = bolInicio;
    this.botonUltimo = bolUltimo;
    this.botonSiguiente = bolSiguiente;
    this.botonAnterior = bolAnterior;
    this.btnBuscar = bolBuscar;
    if (strTipo === 'NAV') {
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      document.getElementById('divPrincipal2').style.pointerEvents = 'all';
    }
    
    if (strTipo === 'N') {
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      document.getElementById('divPrincipal2').style.pointerEvents = 'all';
    }
    
    if (strTipo === 'NoModificar') {
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      document.getElementById('divPrincipal2').style.pointerEvents = 'all';
    } else {
      // btnAutorizar.setEnabled(true);
    }
    
    
  } // Fin activar botones
  
  aceptar() {
    let strOrdenamiento = '';
    if (this.orden === 'proveedor') {
      strOrdenamiento = ' order by pro_nombre,trncobro_fechatrn asc ';
    }
    if (this.orden === 'fecha') {
      strOrdenamiento = ' order by trncobro_fechatrn asc  ';
    }
    this.mostrarDocumentos2(strOrdenamiento, '');
  }
  
  mostrarDocumentos2(strOrdenamiento, strCodProv) {
    this.bandejaPagoService.detBandejaPagos = [];
    this.listarDatos2(strOrdenamiento, strCodProv);
  }
  
  async listarDatos2(strOrden, StrCodProveedor) {
    let dblPagado = 0;
    let dblRetencion = 0;
    let dblSaldo = 0;
    let dblTrnCobroImporte = 0;
    let dblSaldoOriginal = 0;
    let dblTrnDocumentoDescuento = 0;
    let dblTrnDocumentoImporte = 0;
    let dblNotasCredito = 0;
    
    try {
      const rs = await this.bandejaPagoService.listarDatos2(strOrden);
      console.log(rs);
      if (rs.length > 0) {
        for (const dato of rs) {
          const item: BanDetBandejaPagos = {};
          
          dblNotasCredito = dato.NOTASCREDITO === 0 ? 0 : dato.NOTASCREDITO;
          item.NOTASCREDITO = Number(dblNotasCredito).toFixed(2);
          
          dblPagado = dato.PAGADO === 0 ? 0 : dato.PAGADO;
          item.PAGADO = Number(dblPagado).toFixed(2);
          
          dblRetencion = dato.RETENCIONES === 0 ? 0 : dato.RETENCIONES;
          item.RETENCIONES = Number(dblRetencion).toFixed(2);
          
          dblSaldo = dato.SALDO === 0 ? 0 : dato.SALDO;
          item.SALDO = Number(dblSaldo).toFixed(3);
          
          dblTrnCobroImporte = dato.TRNCOBRO_IMPORTE === 0 ? 0 : dato.TRNCOBRO_IMPORTE;
          item.IMPORTE = Number(dblTrnCobroImporte).toFixed(2);
          
          dblTrnDocumentoDescuento = 0;
          item.DETBANDEJA_DESCUENTO = Number(dblTrnDocumentoDescuento).toFixed(2);
          
          dblTrnDocumentoImporte = 0;
          item.DETBANDEJA_IMPORTE = Number(dblTrnDocumentoImporte).toFixed(2);
          
          dblSaldoOriginal = dblSaldo + dblTrnDocumentoImporte;
          item.SALDO_ORIGINAL = Number(dblSaldoOriginal).toFixed(2);
          
          item.NUM_CUOTAS = 0;
          item.TOTAL_CUOTAS = 0;
          
          item.TRNCOBRO_CONCEPTO = dato.TRNCOBRO_CONCEPTO === null ? '' : dato.TRNCOBRO_CONCEPTO;
          
          item.TRNCOBRO_FECHATRN = dato.TRNCOBRO_FECHATRN === null ? '' : dato.TRNCOBRO_FECHATRN;
          if (item.TRNCOBRO_FECHATRN.toString().includes('-') && item.TRNCOBRO_FECHATRN !== '') {
            item.TRNCOBRO_FECHATRN  = this.datePipe.transform(item.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
          }
          item.TRNCOBRO_FECHAVENCE = dato.TRNCOBRO_FECHAVENCE === null ? '' : dato.TRNCOBRO_FECHAVENCE;
          if (item.TRNCOBRO_FECHAVENCE.toString().includes('-') && item.TRNCOBRO_FECHAVENCE !== '') {
            item.TRNCOBRO_FECHAVENCE  = this.datePipe.transform(item.TRNCOBRO_FECHAVENCE, 'dd/MM/yyyy');
          }
          item.DETBANDEJA_COMPROBANTE = dato.TRNCOBRO_NRODOC === null ? '' : dato.TRNCOBRO_NRODOC;
          item.DETBANDEJA_REF = dato.TRNCOBRO_TIPODOC === null ? '' : dato.TRNCOBRO_TIPODOC;
          item.DETBANDEJA_TIPODOC = dato.TRNCOBRO_TIPODOC === null ? '' : dato.TRNCOBRO_TIPODOC;
          item.DETBANDEJA_TIPODSCTO = '';
          item.TRNCOBRO_NROCOMPROBANTE = dato.TRNCOBRO_NROCOMPROBANTE === null ? '' : dato.TRNCOBRO_NROCOMPROBANTE;
          item.PRO_CODIGO = dato.PRO_CODIGO === null ? '' : dato.PRO_CODIGO;
          item.PRO_NOMBRE = dato.PRO_NOMBRE === null ? '' : dato.PRO_NOMBRE;
          item.CONTROL_GRABAR = '';
          item.CHECK = false;
          item.SEL_EXP = false;
          this.bandejaPagoService.detBandejaPagos.push(item);
        }
        // this.aggrid.refreshColumnDefs();
        this.aggrid.refreshaggrid(this.bandejaPagoService.detBandejaPagos, this.defaultColBanPago.objeto);
        
      } else {
        this.bandejaPagoService.detBandejaPagos = [];
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  } // fin listar datos
  
  onTab1Change(event) {
    this.selectTab = event.index;
  }
  
  async limpiarDatos() {
    this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_FECHAEMISION = new Date();
    this.strControlActivaOpciones = '';
    this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO = '';
    this.bandejaPagoService.encBandejaPagos.BAN_BANCO = '';
    this.bandejaPagoService.encBandejaPagos.BAN_CODIGO = '';
    this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CONCEPTO = '';
    this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_TOTAL = '';
    this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_COMPROBANTE = '';
    this.strControlAbrirDoc = '0';
    this.strControlActivaOpciones = '';
    this.lblMensaje = '';
    this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_PATHARCHIVO = '';
    this.bandejaPagoService.detBandejaPagos = [];
    this.comprobantesEgreso = [];
    this.comprobantesDocsprov = [];
    this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_ESTADO = 'P';
    this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_NOMESTADO = await this.nombreEstado('P');
    this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_BENEFICIARIO = '';
  }
  
  async nombreEstado(strEstado) {
    let strRefNombreC = '';
    try {
      const rs = await this.bandejaPagoService.encontrarNombreEstado(strEstado);
      if (strRefNombreC === null) {
        strRefNombreC = 'PENDIENTE';
      } else {
        strRefNombreC = rs[0].REF_NOMBREC;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
    }
    return  strRefNombreC;
  }
  
  configuracionInicial() {
    let i = 0;
    let strNumero = '';
    this.cmbNumEnvio = [];
    for (i = 1; i < 1000 ; i++) {
      
      strNumero = this.bandejaPagoService.autoCompletar(String(i), 3);
      this.cmbNumEnvio.push({label: strNumero});
    }
    
    this.strNumEnvio = this.cmbNumEnvio[0];
    
  }
  
  async verificarDocumentoConciliado() {
    let intNumReg = 0;
    try {
      const rs = await this.bandejaPagoService.verificarDocConcil(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO);
      if (rs.length > 0) {
        intNumReg = rs[0]['COUNT(*)'];
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
    }
    return intNumReg;
    
  }
  
  nuevoRegistro() {
    this.boolBtnAceptar = false;
    this.editNumComprob = false;
    this.limpiarDatos();
    this.activaBotones('N', true, false, false, true, true, true,
      true, true, true, true, true, true,
      true, true, true);
    this.aggrid.habilitarGrid();
  }
  
  buscarBancos(parametro) {
    this.opcion = 'BuscarBanco';
    this.types = [
      {label: 'CÓDIGO', value: 'BAN_CODIGO'},
      {label: 'BANCO', value: 'BAN_BANCO'},
      {label: 'CUENTA NRO', value: 'BAN_CTANRO'},
      {label: 'JEFE CUENTA', value: 'BAN_JEFECTA'},
      {label: 'SUCURSAL', value: 'BAN_SUCURSAL'},
      {label: 'DIRECCIÓN', value: 'BAN_DIRECCION'},
      {label: 'TELEFONO1', value: 'BAN_TELEFONO1'},
      {label: 'REF. CONTABLE', value: 'CON_CODIGO'},
      {label: 'CEN_CODIGO', value: 'CEN_CODIGO'},
      {label: 'TIPO DE CUENTA', value: 'BAN_TIPOCUENTA'},
      {label: 'DOC. POR PAGAR', value: 'CON_CODIGO_2'},
      {label: 'PROCESO', value: 'BAN_PROCESO_CM'},
      {label: 'DIRECTORIOCM', value: 'BAN_DIRECTORIO_CM'},
      {label: 'DINEROELEC', value: 'BAN_DINEROELEC'},
      {label: 'TELCEL', value: 'BAN_TELEFONOCEL'},
      {label: 'ULT. CHEQUE', value: 'BAN_ULTIMOCHEQUE'},
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
    this.consulta = 'BAN_codigo,BAN_banco, NVL(BAN_ctanro, \'*\') AS BAN_ctanro, BAN_jefecta,BAN_sucursal,' +
      ' BAN_direccion, BAN_telefono1, NVL(CON_codigo, \'\') as CON_codigo, CEN_CODIGO,BAN_tipocuenta,' +
      ' con_codigo_2, ban_proceso_cm, ban_directorio_cm, ban_dineroelec, ban_telefonocel,BAN_ULTIMOCHEQUE';
    this.tabla = 'BAN_MAEBAN';
    this.where = '';
    
    this.bandejaPagoService.encontrarBanco(this.types[0].value + ' LIKE \'' + parametro + '\'').subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busquedaSer.busquedaBanMaeBan().subscribe((res: any[]) => {
            this.arregloCons = res;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaBanMaeBan().subscribe((res: any[]) => {
          this.arregloCons = res;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }
  
  async manejarSeleccion(valor) {
    if (this.opcion === 'BuscarBanPagos') {
      this.mostrarDatos(valor.ENCBANDEJA_CODIGO);
    }
    
    if (this.opcion === 'BuscarBanco') {
      if (valor.BAN_CODIGO !== '') {
        this.bandejaPagoService.encBandejaPagos.BAN_CODIGO = valor.BAN_CODIGO;
        this.bandejaPagoService.encBandejaPagos.BAN_BANCO = valor.BAN_BANCO;
        this.activaBotones('M', true, false, false, true, true,
          true, true, true, true, true, true, true,
          true, true, true);
        
      }
    }
    
    if (this.opcion === 'BuscarExpediente') {
      if (valor.EXP_NUMERO !== '') {
        this.asignarNumeroExpendienteNuevo(valor.EXP_NUMERO);
      }
    }
    
    
    this.displayDialogBus = false;
    this.displayDialogBusquedaFast = false;
  }
  
  buscarRegistro() {
    if (this.permisos != null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERSELECCION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , no tiene permiso de Selección. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }
    
    this.opcion = 'BuscarBanPagos';
    this.types = [
      {label: 'NRO DOC', value: 'ENCBANDEJA_CODIGO'},
      {label: 'NRO COMPROBANTE', value: 'ENCBANDEJA_COMPROBANTE'},
      {label: 'FECHA EMISIÓN', value: 'ENCBANDEJA_FECHAEMISION'},
      {label: 'CÓDIGO BANCO', value: 'BAN_CODIGO'},
      {label: 'BANCO', value: 'BAN_BANCO'},
      {label: 'CONCEPTO', value: 'ENCBANDEJA_CONCEPTO'},
    ];
    this.busquedacampos = ['', '', '', '', '', ''];
    this.tabla = 'ban_encbandeja';
    this.where = '';
    this.consulta = 'encbandeja_codigo,encbandeja_comprobante ,encbandeja_fechaemision,ban_codigo,' +
      'BAN_FNC_NOMBRE_BANCO(ban_codigo, \'01\') as ban_banco ,encbandeja_concepto';
    this.busquedaSer.busquedaBanEncBandeja().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.arregloCons.map((dato) => {
        if (dato.ENCBANDEJA_FECHAEMISION !== null) {
          dato.ENCBANDEJA_FECHAEMISION = this.datePipe.transform(dato.ENCBANDEJA_FECHAEMISION, 'dd/MM/yyyy');
        } else {
          dato.ENCBANDEJA_FECHAEMISION = '';
        }
      });
      this.displayDialogBusquedaFast = true;
    });
  }
  verdocsprov(){
    this.closeSidebar();
    if(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_ESTADO === 'P'){
      for(const data of this.comprobantesDocsprov){
        data.CHECK=false;
      }
      this.aggrid.refreshaggrid(this.comprobantesDocsprov, this.defaultDocsProv.objeto);
      this.displayDocsprov=true;
    }else{
      this.mensajeAlerta('info', 'Información', 'No se puede modificar el documento porque ya está autorizado.');
    }
    
  }
  acciones() {
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className = 'layout-config';
      }
    }
  }
  closeSidebar() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
  }
 async manejarSenales2(valor){
    if (valor === 'Borrar') {
      this.spin=true;
      await this.eliminarDocsprov();
    }
    if(valor === 'Salir'){
      this.displayDocsprov=false;
      this.cancelarRegistro();
    }
  }
  async eliminarDocsprov(){
    if (this.permisos != null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERELIMACION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , no tiene permiso de eliminación. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }
    let importe=0;
    for(const data of this.comprobantesDocsprov){
      if(data.CHECK === true){
        console.log('1')
        await this.eliminaDocsprov(data)
        importe=importe+Number(data.IMPORTE)
      }
    }
    this.mensajeAlerta('success', 'Información', 'Registro Eliminadao Correctamente');
    this.spin=false;
  }
  async eliminaDocsprov(data){
    try {
      await this.bandejaPagoService.eliminardocsprov(data,this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO);
      this.auditoriagral.registrarAuditoria('BAN_ENCBANDEJA', this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO + '/' +
          this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_COMPROBANTE+'/'+data.TRNCOBRO_NRODOC+'/'+data.PRO_CODIGO+'/'+data.IMPORTE , 'E',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
      this.spin=false;

    }
    
  }
  docsProvSeleccionado(valor) {
    //console.log('check', this.cxptrnpagodocService.check);
    // console.log('data',valor.data);
    // console.log('objeto',valor.object);
    const indi = this.comprobantesDocsprov.indexOf(valor.data);
      console.log(indi)
      this.detalleSelecDocsProv = valor.data;
      if( this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_ESTADO === 'P'
        ){
          if (valor.data.CHECK) {
            if (this.strControlAbrirDoc === '0') {
                    // this.activaBotones('M', true, false, false, true, true, true,
                    // true, true, true, true, true, true, true,
                    // true, true);
                    // valor.data.DETBANDEJA_IMPORTE = valor.data.SALDO;
                    // valor.data.SALDO = Number(valor.data.SALDO_ORIGINAL).toFixed(2);
                    // this.calcularImporteTotal();
                    this.aggrid.refreshaggridindexSNMVSCROLL(this.comprobantesDocsprov,this.defaultDocsProv.objeto,indi - 1)
                    this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultDocsProv.objeto].setFocusedCell(indi, 'CHECK');
              }
            } else {
              if (this.strControlAbrirDoc === '0') {
                // this.activaBotones('M', true, false, false, true, true, true,
                //   true, true, true, true, true, true, true,
                //   true, true);
        
                //   valor.data.DETBANDEJA_IMPORTE = Number(0).toFixed(2);
                //   valor.data.SALDO = Number(valor.data.SALDO_ORIGINAL).toFixed(2);
                 
                //   this.calcularImporteTotal();
                  this.aggrid.refreshaggridindexSNMVSCROLL(this.comprobantesDocsprov,this.defaultDocsProv.objeto,indi - 1);
                  this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultDocsProv.objeto].setFocusedCell(indi, 'CHECK');
              }
            }
        }
  }
  cambiarFoco(evento) {
    
    if (evento.target.id === 'comprobante') {
      document.getElementById('inputNumEnvio').focus();
    }
    if (evento.target.id === 'inputNumEnvio') {
      document.getElementById('BAN_CODIGO').focus();
    }
    if (evento.target.id === 'BAN_CODIGO') {
      document.getElementById('concepto').focus();
    }
    if (evento.target.id === 'concepto') {
      document.getElementById('inputNumEnvio').focus();
    }
    
  }
  
  seleccionItem(valor) {
    const index = this.bandejaPagoService.detBandejaPagos.findIndex(i => i.DETBANDEJA_COMPROBANTE === valor.DETBANDEJA_COMPROBANTE);
    if (this.bandejaPagoService.detBandejaPagos[index].CHECK === true) {
      if (this.strControlAbrirDoc === '0') {
        this.activaBotones('M', true, false, false, true, true, true,
          true, true, true, true, true, true, true,
          true, true);
        this.bandejaPagoService.detBandejaPagos[index].DETBANDEJA_IMPORTE = this.bandejaPagoService.detBandejaPagos[index].SALDO;
        this.bandejaPagoService.detBandejaPagos[index].SALDO = Number(this.bandejaPagoService.detBandejaPagos[index].SALDO_ORIGINAL).toFixed(2);
        this.calcularImporteTotal();
        this.aggrid.refreshaggridindexSNMVSCROLL(this.bandejaPagoService.detBandejaPagos[index],this.defaultColBanPago.objeto,index - 1)
        
        
      }
    }
    if (this.bandejaPagoService.detBandejaPagos[index].CHECK === false) {
      if (this.strControlAbrirDoc === '0') {
        this.activaBotones('M', true, false, false, true, true, true,
          true, true, true, true, true, true, true,
          true, true);
        this.bandejaPagoService.detBandejaPagos[index].DETBANDEJA_IMPORTE = Number(0).toFixed(2);
        this.bandejaPagoService.detBandejaPagos[index].SALDO = Number(this.bandejaPagoService.detBandejaPagos[index].SALDO_ORIGINAL).toFixed(2);
       
        this.calcularImporteTotal();
        this.aggrid.refreshaggridindexSNMVSCROLL(this.bandejaPagoService.detBandejaPagos[index],this.defaultColBanPago.objeto,index - 1)
      }
    }
    
  }
  //CDPJ
  docSeleccionado(valor) {

    //console.log('check', this.cxptrnpagodocService.check);
    // console.log('data',valor.data);
    // console.log('objeto',valor.object);
    const indi = this.bandejaPagoService.detBandejaPagos.indexOf(valor.data);
      console.log(indi)
      this.detalleSelecBandeja = valor.data;
      if(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO === null ||
        this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO ===  undefined ||
        this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO === ''
        ){
          if (valor.data.CHECK) {
            if (this.strControlAbrirDoc === '0') {
                    this.activaBotones('M', true, false, false, true, true, true,
                    true, true, true, true, true, true, true,
                    true, true);
                    valor.data.DETBANDEJA_IMPORTE = valor.data.SALDO;
                    valor.data.SALDO = Number(valor.data.SALDO_ORIGINAL).toFixed(2);
                    this.calcularImporteTotal();
                    this.aggrid.refreshaggridindexSNMVSCROLL(this.bandejaPagoService.detBandejaPagos,this.defaultColBanPago.objeto,indi - 1)
                    this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColBanPago.objeto].setFocusedCell(indi, 'CHECK');
              }
            } else {
              if (this.strControlAbrirDoc === '0') {
                this.activaBotones('M', true, false, false, true, true, true,
                  true, true, true, true, true, true, true,
                  true, true);
        
                  valor.data.DETBANDEJA_IMPORTE = Number(0).toFixed(2);
                  valor.data.SALDO = Number(valor.data.SALDO_ORIGINAL).toFixed(2);
                 
                  this.calcularImporteTotal();
                  this.aggrid.refreshaggridindexSNMVSCROLL(this.bandejaPagoService.detBandejaPagos,this.defaultColBanPago.objeto,indi - 1);
                  this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColBanPago.objeto].setFocusedCell(indi, 'CHECK');
              }
            }
        }
  }
  //CDPJ
  calcularImporteTotal(): number {
    let intIndiceLista = 0;
    let dblTotalImporte = 0;
    // Total de los documentos seleccionados
    for (intIndiceLista = 0; intIndiceLista < this.bandejaPagoService.detBandejaPagos.length; intIndiceLista++) {
      const item = this.bandejaPagoService.detBandejaPagos[intIndiceLista];
      if(item.CHECK === false){
        item.DETBANDEJA_IMPORTE=Number(0).toFixed(2);
      }
      dblTotalImporte = dblTotalImporte + Number(item.DETBANDEJA_IMPORTE) - Number(item.DETBANDEJA_DESCUENTO);
      console.log('dblTotalImporte',dblTotalImporte)
    }
    for (intIndiceLista = 0; intIndiceLista < this.bandejaPagoService.detBandejaPagos.length; intIndiceLista++) {
      const item = this.bandejaPagoService.detBandejaPagos[intIndiceLista];
      item.DETBANDEJA_IMPORTE=Number(item.DETBANDEJA_IMPORTE).toFixed(2);
      
    }
    const value = dblTotalImporte;
    this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_TOTAL = Number(value).toFixed(2);
    this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_TOTAL = this.init.moneyValidation(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_TOTAL);
    
    return dblTotalImporte;
    
  }
  
  guardar() {
    console.log(this.fecha);
    if (this.selectTab === 0) { // FACTURA
      this.spin=true;//CDPJ
      this.guardarRegistro();
    }
    if (this.selectTab === 1) {
    
    }
  }
  
  async guardarRegistro() {
    let strIncluirDetalle = '';
    
    if (await this.faltaCampos() === false) {
      return;
    }
    
    this.calcularImporteTotal();
    
    const strFechaEmision = this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_FECHAEMISION.toLocaleString('en-GB').split(',');
    
    if (this.chkIncluirDetalle === true) {
      strIncluirDetalle = '1';
    } else {
      strIncluirDetalle = '0';
    }
    
    if (this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO === '') {
      this.strControlNuevoReg = 'N';
    }
    
    let strCodigo = '';
    if (this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO === '') {
      strCodigo = '0';
    } else {
      strCodigo = this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO;
    }
    
    if (this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO === '') {
      this.strControlNuevoReg = 'N';
    }
    this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_TOTAL = this.init.quitarComas(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_TOTAL);
    
    try {
      const rs = await this.bandejaPagoService.guardarEncabezadoBanPagos(strCodigo, this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_COMPROBANTE,
        strFechaEmision[0],
        this.bandejaPagoService.encBandejaPagos.BAN_CODIGO, '', '', strIncluirDetalle, '', this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CONCEPTO,
        Number(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_TOTAL),
        this.strNumEnvio.label, this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_PATHARCHIVO,
        this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_ESTADO, this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_BENEFICIARIO);
      if (rs.length > 0) {
        let intCodigo = 0;
        intCodigo = rs[0][':B2'];
        this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO = intCodigo;
        
        if (Number(this.intCOMBPAUT) === 1) {
          this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_COMPROBANTE = rs[0][':B1'];
          
        }
        console.log('rs[0][\':B1\']',rs[0][':B1']);
        if(this.strControlNuevoReg === 'N'){
          this.auditoriagral.registrarAuditoria('BAN_ENCBANDEJA', this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO + '/' +
          this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_COMPROBANTE , 'I',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
        }else{
          this.auditoriagral.registrarAuditoria('BAN_ENCBANDEJA', this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO + '/' +
          this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_COMPROBANTE , 'A',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
        }
        
        if (await this.guardarDetalle() === true) { /*guarda el detalle de documentos y de varios*/
          if (await this.generaComprobantesEgreso() === false) {
            this.reversaGrabacionTransacciones();
            return;
          } else {
            this.actualizarSaldoDocumento();
            this.mostrarDatos(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO);
          }
        } else {
          this.reversaGrabacionTransacciones();
          this.spin=false;//CDPJ
          return;
        }
        
        
        this.porcentaje = 0;
        this.mensajeAlerta('success', 'Información', 'Registro guardado exitosamente !!!');
        this.spin=false;//CDPJ
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
    }
    
  }
  
  async guardarDetalle() {
    let intIndiceLista = 0;
    let item: BanDetBandejaPagos = {};
    
    if (this.faltanCamposDet() === false) {
      return false;
    }

    this.porcentaje = 0;
    
    for (intIndiceLista = 0; intIndiceLista < this.bandejaPagoService.detBandejaPagos.length; intIndiceLista++) {
      item = this.bandejaPagoService.detBandejaPagos[intIndiceLista];
      this.porcentaje = this.porcentaje + Number(100 / this.bandejaPagoService.detBandejaPagos.length);
      if (item.CHECK === true) { // si esta seleccionado
        try {
          await this.bandejaPagoService.guardarDetBanPagos(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO, item);
          if (item.CONTROL_GRABAR.trim() === '') {
            this.auditoriagral.registrarAuditoria('BAN_DETBANDEJA', this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO + '/' +
              item.DETBANDEJA_COMPROBANTE , 'I',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
            });
          } else {
            this.auditoriagral.registrarAuditoria('BAN_DETBANDEJA', this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO + '/' +
              item.DETBANDEJA_COMPROBANTE , 'A',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
            });
          }
          item.CONTROL_GRABAR = '1';
          
        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('info', 'Error', mensaje);
          this.spin=false;//CDPJ
          return false;
        }
      } else { // elimina del detalle
        
        try {
          await this.bandejaPagoService.eliminarItemDetalle(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO,
            item.DETBANDEJA_COMPROBANTE, item.PRO_CODIGO);
          item.CONTROL_GRABAR = '';
        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('info', 'Error', mensaje);
          this.spin=false;//CDPJ
          return false;
        }
      }
      
    } // Fin de for*/
    
    return true;
    
  }
  
  async generaComprobantesEgreso() {
    try {
      await this.bandejaPagoService.generarComprobanteEgreso(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO);
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
      this.spin=false;//CDPJ
      return false;
    }
    
    return true;
  }
  
  async reversaGrabacionTransacciones() {
    if (this.strControlNuevoReg !== 'N') {
      return;
    }
    try {
      await this.bandejaPagoService.eliminarEncBandeja(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO);
      this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO = '';
      this.actualizarCampoContrlGrabar();
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
    }
  }
  
  actualizarCampoContrlGrabar() {
    let intIndiceLista = 0;
    let item: BanDetBandejaPagos = {};
    for (intIndiceLista = 0; intIndiceLista < this.bandejaPagoService.detBandejaPagos.length; intIndiceLista++) {
      item = this.bandejaPagoService.detBandejaPagos[intIndiceLista];
      item.CONTROL_GRABAR = '';
    }
  }
  
  async actualizarSaldoDocumento() {
    let intIndiceLista = 0;
    let item: BanDetBandejaPagos = {};
    for (intIndiceLista = 0; intIndiceLista < this.bandejaPagoService.detBandejaPagos.length; intIndiceLista++) {
      item = this.bandejaPagoService.detBandejaPagos[intIndiceLista];
      if (item.DETBANDEJA_TIPODOC === 'FC') {
        // Actualiza el estado de la factura
        await this.bandejaPagoService.actualizarEstadoFacturaProv(item.PRO_CODIGO, item.DETBANDEJA_COMPROBANTE);
      }
    }
  }
  
  faltanCamposDet(): boolean {
    let intIndiceLista = 0;
    let item: BanDetBandejaPagos = {};
    let intSwControl = 0;
    
    for (intIndiceLista = 0; intIndiceLista < this.bandejaPagoService.detBandejaPagos.length; intIndiceLista++) {
      item = this.bandejaPagoService.detBandejaPagos[intIndiceLista];
      if (item.CHECK === true) {// si esta seleccionado
        if (Number(item.DETBANDEJA_IMPORTE) === 0) {
          this.mensajeAlerta('info', 'Información', 'Error, Ingrese el valor de importe');
          this.spin=false;//CDPJ
          return false;
        }
      }
      
      intSwControl = 1;
      
    } // fin de for
    
    if (intSwControl === 0) {
      this.mensajeAlerta('info', 'Información', 'Error, No existen documentos en el detalle');
      this.spin=false;//CDPJ
      return false;
    }
    
    return true;
  }
  
  eliminarRegistro() {
    if (this.selectTab === 0) { // generales
      if (this.permisos != null) {
        if (this.permisos.PERTODO === 0) {
          if (this.permisos.PERELIMACION === 0) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ' , no tiene permiso de eliminación. Consulte con el Administrador del Sistema.');
            return;
          }
        }
      }
    }
    
    this.confirmationService.confirm({
      message: 'Está seguro de eliminar el registro?',
      header: 'Eliminar',
      icon: 'pi pi-question-circle',
      key: 'bandejaPagosconf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminar();
      },
      reject: () => {
        this.init.tabs.splice(this.init.tabindex, 1);
      }
    });
    
    
    
  }
  
  async eliminar() {
    if (this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO === '') {
      this.mensajeAlerta('info', 'Información', 'Seleccione el registro a Eliminar');
      return;
    }
    
    try {
      
      await this.bandejaPagoService.eliminarEncBandeja(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO);
      
      this.auditoriagral.registrarAuditoria('BAN_ENCBANDEJA', this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO + '/' +
        this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_COMPROBANTE , 'E',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
      
      this.actualizarSaldoDocumento();
      // modeloBanPagos.anularNumeroExpediente();
      
      this.limpiarDatos();
      this.bandejaPagoService.detBandejaPagos = [];
      
      this.activaBotones('INI', false, true, true, true, false, false, false, false, false, true, true, true, true, true, true);
      this.mensajeAlerta('success', 'Información', 'Registro eliminado exitosamente !!!');
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
    }
  }
  
  async faltaCampos() {
    
    // Verifica que documento este dentro del periodo contable
    const dia = this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_FECHAEMISION.getDate();
    const mes = this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_FECHAEMISION.getMonth();
    const anio = this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_FECHAEMISION.getFullYear();
    
    
    if (await this.utilitariosService.verificarPeriodoContable(dia, mes, anio) === false) {
      this.mensajeAlerta('info', 'Información', 'Error, fecha debe de estar dentro del período contable');
      this.spin=false;//CDPJ
      return false;
    }
    // Verificar el número del comprobante
    if (this.intCOMBPAUT === 0) {
      if (this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_COMPROBANTE.trim() === '') {
        this.mensajeAlerta('info', 'Información', 'Error, Ingrese el número de comprobante');
        this.spin=false;//CDPJ
        return false;
      }
    }
    // Banco
    if (this.bandejaPagoService.encBandejaPagos.BAN_CODIGO.trim() === '') {
      this.mensajeAlerta('info', 'Información', 'Error, Ingrese el código del banco');
      this.spin=false;//CDPJ
      return false;
    }
    // Controla si el importe es negativo
    if (this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_TOTAL.trim() === '' ||
      Number(this.init.quitarComas(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_TOTAL)) === 0) {
      this.mensajeAlerta('info', 'Información', 'Error, el valor del Importe no puede ser cero');
      this.spin=false;//CDPJ
      return false;
    }
    // Número de envio
    if (this.strNumEnvio.label === '') {
      this.mensajeAlerta('info', 'Información', 'Error, Ingrese el número de envío');
      this.spin=false;//CDPJ
      return false;
    }
    // Concepto
    if (this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CONCEPTO.trim() === '') {
      this.mensajeAlerta('info', 'Información', 'Error, Ingrese el concepto');
      this.spin=false;//CDPJ
      return false;
    }
    return true;
  }
  
  cancelarRegistro() {
    if (this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO !== '') {
      this.mostrarDatos(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO);
    } else {
      this.nuevoRegistro();
      this.activaBotones('INI', false, true, true, true, false, false, false, false, false, true, true, true, true, true, true);
    }
  }
  
  async seleccionDetBandeja(valor) {
    console.log(valor);
    
    if (valor === 'expedienteNuevo') {
      if (this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO === '') {
        return;
      }
      const strNumeroExpediente = await this.obtenerNumeroExpendiente();
      if (strNumeroExpediente !== '') {
        this.asignarNumeroExpendienteNuevo(strNumeroExpediente);
      }
    }
    if (valor === 'expedienteExistente') {
      this.opcion = 'BuscarExpediente';
      this.types = [
        {label: 'EXP_NUMERO', value: 'EXP_NUMERO'},
      
      ];
      this.busquedacampos = [''];
      this.tabla = 'ban_maeexpediente';
      this.where = '';
      this.consulta = 'distinct exp_numero';
      this.busquedaSer.busquedaBanMaeExpediente().subscribe((res: any[]) => {
        this.arregloCons = res;
        this.displayDialogBusquedaFast = true;
      });
    }
    
    if (valor.object !== undefined) {
      this.detalleSelecBandeja = valor.object;
    }
    
  }
  //CDPJ
  seleccionDetComprobante(valor){
    if (valor.object !== undefined) {
      this.detalleSelecComprobante = valor.object;
    }
  }
  selecccionDocsprov(valor){
    if(valor.object!== undefined){
      this.detalleSelecDocsProv=valor.object;
    }
  }
  //CDPJ
  async obtenerNumeroExpendiente() {
    let strNumero = '';
    try {
      const rs = await this.bandejaPagoService.obtenerNumeroExpendiente();
      if (rs.length > 0) {
        strNumero = rs[0].EXPEDIENTE;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
    }
    
    return strNumero;
  }
  
  async asignarNumeroExpendienteNuevo(strNumeroExpediente) {
    
    for (const item of this.bandejaPagoService.detBandejaPagos) {
      if (item.SEL_EXP === true) {
        if (await this.VeriExistExpediente(item.EXP_NUMERO, 'FC', item.DETBANDEJA_COMPROBANTE, 'BANDEJA', 'P',
          this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO) === true) {
          if (await this.insertarExpendiente(strNumeroExpediente, 'FC', item.DETBANDEJA_COMPROBANTE, 'BANDEJA',
            'P', this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO) === true) {
            if (await this.actualizarExpendiente(item.DETBANDEJA_COMPROBANTE, item.PRO_CODIGO, strNumeroExpediente) === true) {
              item.EXP_NUMERO = strNumeroExpediente;
              item.SEL_EXP = false;
            }
          }
        }
        // actualiza el expendiente en la base de datos
      }
      
    }
    this.aggrid.refreshColumnDefs();
  }
  
  async VeriExistExpediente(strExpNumero, strExpTipo, strExpDocumento, strExpOrigen, strExpEstado, strReferencia) {
    let bolResultado = true;
    
    try {
      await this.bandejaPagoService.veriExistExpediente(strExpNumero, strExpTipo, strExpDocumento, strExpOrigen, strExpEstado, strReferencia);
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
      bolResultado = false;
    }
    return bolResultado;
  }
  
  async insertarExpendiente(strExpNumero, strExpTipo, strExpDocumento, strExpOrigen, strExpEstado, strReferencia) {
    let bolResultado = true;
    try {
      await this.bandejaPagoService.insertarExpendiente(strExpNumero, strExpTipo, strExpDocumento, strExpOrigen, strExpEstado, strReferencia);
      this.auditoriagral.registrarAuditoria('BAN_MAEEXPEDIENTE', strExpNumero + '/' + strExpTipo + '/' +
        strExpDocumento + '/' + strExpOrigen + '/' + strExpEstado , 'I',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
      bolResultado = false;
    }
    return bolResultado;
  }
  
  async actualizarExpendiente(strBandejaComprobante, strProCodigo, strExpNumero) {
    let bolResultado = true;
    try {
      await this.bandejaPagoService.actualizarExpediente(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO, strBandejaComprobante,
        strProCodigo, strExpNumero);
      this.auditoriagral.registrarAuditoria('BAN_MAEEXPEDIENTE', this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO + '/' +
        strBandejaComprobante + '/' + strExpNumero, 'I',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
      });
      
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', mensaje);
      bolResultado = false;
    }
    
    return bolResultado;
  }
  
  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'bandejaPagos',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
  
  isEdit(data): boolean {
    console.log(data)
    if (this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_ESTADO === 'T' || data.CHECK === false) {
      return false;
    }
    
    return true;
  }
  
  async autorizarBandejaPagos() {
    
    if (this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO === '') {
      return;
    }
    
    if (this.btnGuardar === false) {
      this.mensajeAlerta('info', 'Información', 'Primero tiene que guardar el registro');
      return;
    }
    
    if (this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_ESTADO === 'P') {
      this.claveAuth = '';
      this.accionClave = 'estadoAutorizado';
      this.claveDialog = true;
    }
    
    if (this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_ESTADO === 'T') {
      
      this.confirmationService.confirm({
        message: 'Está seguro de reversar la autorización?',
        header: 'Reversar Autorización',
        icon: 'pi pi-question-circle',
        key: 'bandejaPagosconf',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        accept: () => {
          this.claveAuth = '';
          this.accionClave = 'estadoPendiente';
          this.claveDialog = true;
        },
        reject: () => {
          this.init.tabs.splice(this.init.tabindex, 1);
        }
      });
      
      this.mostrarDatos(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO);
      return;
    }
  }
  
  async comprobarClave() {
    this.intNumReg = 0;
    try {
      const clave = await this.bandejaPagoService.obtenerClave();
      let claveReal;
      if (Object.keys(clave).length > 0) {
        claveReal = clave[0].AUTORIZACION_CLAVE;
        if (claveReal === this.claveAuth) {
          this.claveDialog = false;
          if (this.accionClave === 'estadoAutorizado') {
            try {
              await this.bandejaPagoService.updateEstadoBandejaT(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO);
            } catch (err) {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
              this.mensajeAlerta('info', 'Error', '! Proceso actualizar estado de bandeja de pagos no terminó satisfactoriamente.... !' + mensaje);
              return;
            }
            this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_ESTADO = 'T';
            this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_NOMESTADO = this.nombreEstado('T');
            this.mostrarDatos(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO);
            return;
          }
          
          if (this.accionClave === 'estadoPendiente') {
            try {
              await this.bandejaPagoService.updateEstadoBandejaP(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO);
            } catch (err) {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
              this.mensajeAlerta('info', 'Error', '! Proceso actualizar estado de bandeja de pagos no terminó satisfactoriamente.... !' + mensaje);
              return;
            }
            this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_ESTADO = 'P';
            this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_NOMESTADO = this.nombreEstado('P');
            this.mostrarDatos(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO);
            return;
          }
          
          this.intNumReg = 1;
          this.claveAuth = '';
        } else if (clave !== this.claveAuth) {
          this.claveDialog = false;
          this.intNumReg = 0;
          this.mensajeAlerta('error', 'Error', 'La clave está incorrecta');
        }
      } else  {
        this.claveDialog = false;
        this.mensajeAlerta('error', 'Error', 'La clave está incorrecta');
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', 'No se puede validar la clave' + mensaje);
    }
  }
  
  obtenerPathImpExp(): boolean {
    let bolObtener = false;
    const strDirectorio = '.';
    let strNombreArchivo = '';
    let strDia = '';
    let strMes = '';
    let strAnio = '';
    
    if (this.btnGuardar === false) {
      this.mensajeAlerta('info', 'Información', 'Primero debe guardar el registro');
      return false;
    }
    
    strDia = this.completarFecha(String(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_FECHAEMISION.getDate()));
    strMes = this.completarFecha(String(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_FECHAEMISION.getMonth() + 1));
    strAnio = this.completarFecha(String(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_FECHAEMISION.getFullYear()));
    
    strNombreArchivo = 'PAGOS_MULTICASH_' + strAnio +  strMes +  strDia + '_' + this.strNumEnvio.label + '.txt';
    
    if (strNombreArchivo != null) {
      bolObtener = true;
    }
    return bolObtener;
  }
  
  completarFecha(strFecha): string {
    if (strFecha.length === 1) {
      return '0' + strFecha;
    } else {
      return strFecha;
    }
    
  }
  
  async archivoOcplistarDatos(bandejaCodigo) {
    let tipoDoc = '';
    let numComprobante = '';
    let refEstCta = '';
    let tipoId = '';
    let strNumCta = '';
    let strBeneficiario = '';
    let strTipCta = '';
    let dblImporte = 0;
    let intEntera;
    let intDecimal;
    let i = 0;
    try {
      const rs = await this.bandejaPagoService.listarDatosArchivoOcp(bandejaCodigo);
      this.detArchivoOcp = [];
      if (rs.length > 0) {
        for (const dato of rs) {
          const item: BanArchivoOcp = {};
          i++;
          tipoDoc = dato.DETBANDEJA_TIPODOC === null ? '' : dato.DETBANDEJA_TIPODOC;
          numComprobante = dato.DETBANDEJA_COMPROBANTE === null ? '' : dato.DETBANDEJA_COMPROBANTE;
          refEstCta = 'PAGO' + tipoDoc + numComprobante;
          if (refEstCta.length > 20) {
            refEstCta = refEstCta.substring(0, 20);
          } else {
            refEstCta = this.autoCompletarEspacios(refEstCta, 20);
          }
          if (numComprobante.length > 15) {
            numComprobante = numComprobante.substring(0, 15);
          } else {
            numComprobante = this.autoCompletarEspacios(numComprobante, 15);
          }
          
          item.REFESTCTA = refEstCta;
          item.CONCEPTO = this.autoCompletarEspacios(numComprobante, 15);
          item.PRO_CODIGO = dato.PRO_CODIGO === null ? '' :  dato.PRO_CODIGO;
          strBeneficiario = dato.PRO_NOMBRE === null ? '' : dato.PRO_NOMBRE;
          item.BENEFICIARIO = this.autoCompletarEspacios(strBeneficiario, 30);
          item.FORMA_PAGO = 'CU';
          item.PRO_RUCIDE = dato.PRO_RUCIDE === null ? '' : dato.PRO_RUCIDE;
          strNumCta = dato.COM_NUMCTACM === null ? '' : dato.COM_NUMCTACM;
          item.COM_NUMCTACM = this.autoCompletarCeros(strNumCta, 8);
          strTipCta = dato.COM_TIPOCTACM === null ? '' : dato.COM_TIPOCTACM;
          item.COM_TIPOCTACM = this.autoCompletarEspacios(strTipCta, 2);
          tipoId = dato.PRO_TIPOIDE === null ? '' : dato.PRO_TIPOIDE;
          
          if (tipoId === '1') {
            tipoId = 'R';
          } else if (tipoId === '2') {
            tipoId = 'C';
          } else {
            tipoId = 'P';
          }
          
          item.TIPONUC = tipoId;
          dblImporte = dato.DETBANDEJA_IMPORTE === 0 ? 0 : dato.DETBANDEJA_IMPORTE;
          intEntera = dato.IMPORTE_ENTERA === 0 ? 0 : dato.IMPORTE_ENTERA;
          intDecimal = dato.IMPORTE_DECIMAL === 0 ? 0 : dato.IMPORTE_DECIMAL;
          item.IMPORTE = String(dblImporte);
          item.IMPORTE_DECIMAL = this.autoCompletarCeros(String(intDecimal), 2);
          item.IMPORTE_ENTERA = this.autoCompletarCeros(String(intEntera), 13);
          this.detArchivoOcp.push(item);
        }
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', '! Proceso listar cargos no terminó satisfactoriamente.... ! ' + mensaje);
    }
  }
  
  autoCompletarEspacios(strCadena, intTamanioCadena): string {
    let intAux = 0;
    let indice = 0;
    let strAutoCompletar = '';
    
    intAux = strCadena.length;
    for (indice = 1; indice <= intTamanioCadena - intAux; indice++) {
      strAutoCompletar = ' ' + strAutoCompletar;
    }
    strAutoCompletar = strAutoCompletar + strCadena;
    return strAutoCompletar;
  }
  
  autoCompletarCeros(strCadena, intTamanioCadena) {
    let intAux = 0;
    let indice = 0;
    let strAutoCompletar = '';
    
    intAux = strCadena.length;
    for (indice = 1; indice <= intTamanioCadena - intAux; indice++) {
      strAutoCompletar = '0' + strAutoCompletar;
    }
    strAutoCompletar = strAutoCompletar + strCadena;
    return strAutoCompletar;
  }
  
  escribirArchivo() {
    
    let strCadena = '';
    let strNombreArchivo = '';
    let strDia = '';
    let strMes = '';
    let strAnio = '';
    let intIndiceLista = 0;
    let item: BanArchivoOcp = {};
    
    for (intIndiceLista = 0; intIndiceLista < this.detArchivoOcp.length; intIndiceLista++) {
      item = this.detArchivoOcp[intIndiceLista];
      strCadena = strCadena + '1OCPPR' + item.COM_TIPOCTACM + item.COM_NUMCTACM + item.IMPORTE_ENTERA + item.IMPORTE_DECIMAL;
      strCadena = strCadena + item.CONCEPTO + item.REFESTCTA + item.FORMA_PAGO;
      strCadena = strCadena + 'USD' + item.BENEFICIARIO + '    ' + item.TIPONUC;
      strCadena = strCadena + item.PRO_RUCIDE;
      strCadena = strCadena + '\r\n';
    }
    
    
    strDia = this.completarFecha(String(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_FECHAEMISION.getDate()));
    strMes = this.completarFecha(String(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_FECHAEMISION.getMonth() + 1));
    strAnio = this.completarFecha(String(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_FECHAEMISION.getFullYear()));
    
    strNombreArchivo = 'PAGOS_MULTICASH_' + strAnio +  strMes +  strDia + '_' + this.strNumEnvio.label + '.txt';
    
    this.download(strNombreArchivo, strCadena);
    
  }
  
  download(filename, text) {
    const blob = new Blob([text], {type: 'text/plain'});
    const element = document.createElement('a');
    element.setAttribute('download', filename);
    element.setAttribute('href', window.URL.createObjectURL(blob));
    element.click();
    // element.style.display = 'none';
    // document.body.appendChild(element);
    // element.click();
    // document.body.removeChild(element);
  }
  
  async generarAchivoCashFormato3() {
    let strCadena = '';
    try {
      await this.bandejaPagoService.ejeSpAchivoCash(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO);
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('info', 'Error', '! Proceso generar archivo no terminó satisfactoriamente.... ! ' + mensaje);
      
    }
    let strNombreArchivo = '';
    let strDia = '';
    let strMes = '';
    let strAnio = '';
    
    
    strDia = this.completarFecha(String(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_FECHAEMISION.getDate()));
    strMes = this.completarFecha(String(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_FECHAEMISION.getMonth() + 1));
    strAnio = this.completarFecha(String(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_FECHAEMISION.getFullYear()));
    
    
    strNombreArchivo = 'PAGOS_MULTICASH_' + strAnio +  strMes +  strDia + '_' +  this.strNumEnvio.label + '.txt';
    
    let dblImporte = 0;
    let dblAux = 0;
    let intParteEntera = 0;
    let strConcepto = '2';
    
    try {
      const rs = await this.bandejaPagoService.obtenerDatosAchivoCashFormato3();
      if ( rs !== null) {
        for (const item of rs) {
          
          // 1  codigo orientación
          strCadena = strCadena + 'PA' + '\t';
          
          // 2 Cuenta bancaria del banco
          if (item.DETCASHMNG_NUMEROCTABANCO !== null) {
            strCadena = strCadena  + item.DETCASHMNG_NUMEROCTABANCO + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 3 Secuencial del pago
          strCadena = strCadena  + item.DETCASHMNG_SECUENCIA + '\t';
          
          // 4 Comprobante de pago
          if (item.DETCASHMNG_COMPROBANTE !== null) {
            strCadena = strCadena  + item.DETCASHMNG_COMPROBANTE + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 5  identificacion del proveedor
          if (item.DETCASHMNG_PROCODIGO !== null) {
            strCadena = strCadena  + item.DETCASHMNG_PROCODIGO + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 6  Moneda
          strCadena = strCadena  + 'USD' + '\t';
          
          // 7 valor
          if (item.DETCASHMNG_IMPORTE !== null) {
            dblImporte = item.DETCASHMNG_IMPORTE;
          } else {
            dblImporte = 0;
          }
          
          intParteEntera = Math.trunc(dblImporte);
          
          strCadena = strCadena  + this.autoCompletar(String(intParteEntera), 11);
          // --parte decimal
          dblAux = dblImporte - Math.trunc(dblImporte);
          dblAux =  dblAux * 100;
          dblAux = Number(Number(dblAux).toFixed(2));
          const y = Math.trunc(Math.round(dblAux));
          
          strCadena = strCadena  + this.autoCompletar(String(y), 2) + '\t';
          
          // 8 forma de pago
          if (item.DETCASHMNG_FORPAGCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_FORPAGCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 9 codigo del banco
          if (item.DETCASHMNG_CODIGOBANCOCASH !== null) {
            strCadena = strCadena  + item.DETCASHMNG_CODIGOBANCOCASH + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 10 tipo de cuenta
          if (item.DETCASHMNG_TIPOCTACM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_TIPOCTACM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 11 Número de cuenta
          if (item.DETCASHMNG_NUMCTACM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_NUMCTACM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 12 Tipo de Id del cliente beneficiario
          
          if (item.DETCASHMNG_TIPOIDENTIFCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_TIPOIDENTIFCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 13codigo del proveedor
          if (item.DETCASHMNG_PROCODIGO !== null) {
            strCadena = strCadena  + this.autoCompletar(item.DETCASHMNG_PROCODIGO, 13) +  '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 14 Nombre del Cliente Beneficiario
          if (item.DETCASHMNG_BENFCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_BENFCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          // 15 Direccion del beneficiario
          if (item.DETCASHMNG_DIFBENFCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_DIFBENFCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 16 ciudad
          if (item.DETCASHMNG_CIUDADCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_CIUDADCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 17 telefono
          if (item.DETCASHMNG_TELFBENFCM !== null) {
            strCadena = strCadena  + this.autoCompletar(item.DETCASHMNG_TELFBENFCM.replace('-', ''), 9) + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 18 sector
          strCadena = strCadena  + '\t';
          
          // 19 referencia
          if (item.DETCASHMNG_CONCEPTO !== null) {
            let strObserva = item.DETCASHMNG_CONCEPTO;
            strObserva = strObserva.replace('\r\n', '');
            strCadena = strCadena  + strObserva +  '\t';
            strConcepto = item.DETCASHMNG_CONCEPTO;
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 20 email
          if (item.DETCASHMNG_EMAILCM !== null) {
            strCadena = strCadena  + '|' + item.DETCASHMNG_EMAILCM + '|' + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          strCadena = strCadena + '\r\n';
        }
        this.download(strNombreArchivo, strCadena);
      }
    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso generar archivo no terminó satisfactoriamente.... !');
    }
  }
  
  async cashBancoInternacional() {
    let strCadena = '';
    let p = '';
    try {
      await this.bandejaPagoService.ejeSpAchivoCash(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO);
    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso generar archivo no terminó satisfactoriamente.... !');
    }
    let strNombreArchivo = '';
    let strDia = '';
    let strMes = '';
    let strAnio = '';
    
    
    strDia = this.completarFecha(String(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_FECHAEMISION.getDate()));
    strMes = this.completarFecha(String(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_FECHAEMISION.getMonth() + 1));
    strAnio = this.completarFecha(String(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_FECHAEMISION.getFullYear()));
    
    
    strNombreArchivo = 'PAGOS_MULTICASH_' + strAnio +  strMes +  strDia + '_' +  this.strNumEnvio.label + '.txt';
    
    let dblImporte = 0;
    let dblAux = 0;
    let intParteEntera = 0;
    let strConcepto = '2';
    const strRefAdic = '';
    
    try {
      const rs = await this.bandejaPagoService.obtenerDatosAchivoCashFormato3();
      if ( rs !== null) {
        for (const item of rs) {
          
          // 1  codigo orientación
          strCadena = strCadena + 'PA' + '\t';
          
          // 2 contrapartida
          if (item.DETCASHMNG_IDPROV !== null) {
            strCadena = strCadena  + item.DETCASHMNG_IDPROV + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 3  Moneda
          strCadena = strCadena  + 'USD' + '\t';
          
          // 4 valor
          if (item.DETCASHMNG_IMPORTE !== null) {
            dblImporte = item.DETCASHMNG_IMPORTE;
          } else {
            dblImporte = 0;
          }
          
          // -- parte entera
          intParteEntera = Math.trunc(dblImporte);
          strCadena = strCadena  + Math.trunc(intParteEntera);
          // --parte decimal
          dblAux = dblImporte - Math.trunc(dblImporte);
          dblAux =  dblAux * 100;
          
          if (Math.trunc(dblAux) === 0) {
            p = '00';
            strCadena = strCadena + p	+ '\t';
          } else {
            const y = Math.trunc(Math.round(dblAux));
            strCadena = strCadena  + this.autoCompletar(String(y), 2) + '\t';
          }
          
          // 5 forma de pago
          strCadena = strCadena + 'CTA' + '\t';
          
          // 6 tipo de cuenta
          if (item.DETCASHMNG_TIPOCTACM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_TIPOCTACM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 7 Número de cuenta
          if (item.DETCASHMNG_NUMCTACM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_NUMCTACM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 8 Concepto
          if (item.DETCASHMNG_CONCEPTO !== null) {
            strCadena = strCadena  + item.DETCASHMNG_CONCEPTO +  '\t';
            strConcepto = item.DETCASHMNG_CONCEPTO;
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 9 Tipo de Id del cliente beneficiario
          if (item.DETCASHMNG_TIPOIDENTIFCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_TIPOIDENTIFCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 10 Id del proveedor
          if (item.DETCASHMNG_IDPROV !== null) {
            strCadena = strCadena  + this.autoCompletar(item.DETCASHMNG_IDPROV, 13) + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 11 Nombre del Cliente Beneficiario
          if (item.DETCASHMNG_BENFCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_BENFCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 12 codigo del banco
          if (item.DETCASHMNG_CODIGOBANCOCASH !== null) {
            strCadena = strCadena  + item.DETCASHMNG_CODIGOBANCOCASH + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          strCadena = strCadena + strRefAdic + '\t';
          
          if (this.chkIncluirDetalle === true) {
            this.generaDetalleRubrosLineaFormato(item.DETCASHMNG_DOCUMENTO, Number(item.DETCASHMNG_SECUENCIA),
              strConcepto);
          }
          
          strCadena = strCadena + '\r\n';
        }
        
        this.download(strNombreArchivo, strCadena);
      }
    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso generar archivo no terminó satisfactoriamente.... !');
    }
  }
  
  async generaDetalleRubrosLineaFormato(strDocumento, intSecuencial, strConcepto) {
    let strCadenaPagos = '';
    let dblImporte = 0;
    let intParteEntera = 0;
    let dblAux = 0;
    
    try {
      const rs = await this.bandejaPagoService.obtenerDatosRubro(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO);
      if (rs !== null) {
        for (const item of rs) {
          // 1 Tipo de registro
          strCadenaPagos = 'DE' + '\t';
          // 2 Secuencial del  pago
          strCadenaPagos = strCadenaPagos + intSecuencial + '\t';
          // 3 Tipo de rubro
          strCadenaPagos = strCadenaPagos + 'INGRESO' + '\t';
          // 4 Concepto
          strCadenaPagos = strCadenaPagos + strConcepto + '\t';
          // 5 Valor Base
          if (item.TRNDOCUMENTO_IMPORTE !== null) {
            dblImporte = item.TRNDOCUMENTO_IMPORTE;
          } else {
            dblImporte = 0;
          }
          // --parte entera
          intParteEntera = Math.trunc(dblImporte);
          strCadenaPagos  = strCadenaPagos  + this.autoCompletar(String(intParteEntera), 11);
          // --parte decimal
          dblAux = dblImporte - Math.trunc(dblImporte);
          intParteEntera = Math.trunc(dblAux * 100);
          strCadenaPagos = strCadenaPagos  + this.autoCompletar(String(intParteEntera), 2) + '\t';
          
          // 6 pocentaje
          strCadenaPagos = strCadenaPagos + '00000' + '\t';
          
          // 7 Valor neto
          if (item.TRNDOCUMENTO_IMPORTE !== null) {
            dblImporte = item.TRNDOCUMENTO_IMPORTE;
          } else {
            dblImporte = 0;
          }
          
          // --parte entera
          intParteEntera = Math.trunc(dblImporte);
          strCadenaPagos = strCadenaPagos  + this.autoCompletar(String(intParteEntera), 11);
          // --parte decimal
          dblAux = dblImporte - Math.trunc(dblImporte);
          intParteEntera =  Math.trunc(dblAux * 100);
          strCadenaPagos = strCadenaPagos  + this.autoCompletar(String(intParteEntera), 2) + '\t';
          
          strCadenaPagos = strCadenaPagos + '\r\n';
        }
      }
    } catch (err) {
      
      this.mensajeAlerta('error', 'Error', '! Proceso leer el detalle de los pagos no terminó satisfactoriamente....!');
    }
    
  }
  
  autoCompletar(strCadena, intTamanioCadena) {
    let intAux = 0;
    let indice = 0;
    let strAutoCompletar = '';
    
    intAux = strCadena.length;
    for (indice = 1; indice <= intTamanioCadena - intAux; indice++) {
      strAutoCompletar = '0' + strAutoCompletar;
    }
    strAutoCompletar = strAutoCompletar + strCadena;
    return strAutoCompletar;
  }
  
  async cashBancoProdubanco() {
    let strCadena = '';
    try {
      await this.bandejaPagoService.ejeSpAchivoCash(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_CODIGO);
    } catch (err) {
      this.mensajeAlerta('error', 'Error', '! Proceso generar archivo no terminó satisfactoriamente.... !');
    }
    let strNombreArchivo = '';
    let strDia = '';
    let strMes = '';
    let strAnio = '';
    
    
    strDia = this.completarFecha(String(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_FECHAEMISION.getDate()));
    strMes = this.completarFecha(String(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_FECHAEMISION.getMonth() + 1));
    strAnio = this.completarFecha(String(this.bandejaPagoService.encBandejaPagos.ENCBANDEJA_FECHAEMISION.getFullYear()));
    
    
    strNombreArchivo = 'PAGOS_MULTICASH_' + strAnio +  strMes +  strDia + '_' +  this.strNumEnvio.label + '.txt';
    
    let dblImporte = 0;
    let dblAux = 0;
    let intParteEntera = 0;
    let strConcepto = '2';
    let strRefAdic = '';
    let intCntrl = 0;
    
    try {
      const rs = await this.bandejaPagoService.obtenerDatosAchivoCashFormato3();
      
      if ( rs !== null) {
        for (const item of rs) {
          
          // 1  codigo orientación
          strCadena = strCadena + 'PA' + '\t';
          
          // 2 Cuenta bancaria del banco
          if (item.DETCASHMNG_NUMEROCTABANCO !== null) {
            strCadena = strCadena  + this.autoCompletar(item.DETCASHMNG_NUMEROCTABANCO, 11) + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 3 Secuencial del pago
          strCadena = strCadena  + item.DETCASHMNG_SECUENCIA + '\t';
          
          // 4 Comprobante de pago
          if (item.DETCASHMNG_COMPROBANTE !== null) {
            strCadena = strCadena  + item.DETCASHMNG_COMPROBANTE + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 5   contrapartida
          if (item.DETCASHMNG_FORPAGCM !== null) {
            if (item.DETCASHMNG_FORPAGCM === 'CTA') {
              strCadena = strCadena  + item.DETCASHMNG_NUMCTACM + '\t';
            } else {
              strCadena = strCadena  + item.DETCASHMNG_IDPROV + '\t';
            }
            
          } else {
            strCadena = strCadena  + item.DETCASHMNG_IDPROV + '\t';
          }
          
          // 6  Moneda
          strCadena = strCadena  + 'USD' + '\t';
          
          // 7 valor
          if (item.DETCASHMNG_IMPORTE !== null) {
            dblImporte = item.DETCASHMNG_IMPORTE;
          } else {
            dblImporte = 0;
          }
          
          intParteEntera = Math.trunc(dblImporte);
          strCadena = strCadena  + this.autoCompletar(String(intParteEntera), 11);
          
          // --parte decimal
          dblAux = dblImporte - Math.trunc(dblImporte);
          dblAux =  dblAux * 100;
          dblAux = Number(Number(dblAux).toFixed(2));
          const y = Math.trunc(Math.round(dblAux));
          
          strCadena = strCadena  + this.autoCompletar(String(y), 2) + '\t';
          
          // 8 forma de pago
          if (item.DETCASHMNG_FORPAGCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_FORPAGCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 9 codigo del banco
          if (item.DETCASHMNG_CODIGOBANCOCASH !== null) {
            if (item.DETCASHMNG_FORPAGCM === 'EFE' || item.DETCASHMNG_FORPAGCM === 'CHQ') {
              strCadena = strCadena  + '0036' + '\t';
            } else {
              strCadena = strCadena  + item.DETCASHMNG_CODIGOBANCOCASH + '\t';
            }
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 10 tipo de cuenta
          if (item.DETCASHMNG_FORPAGCM !== null ) {
            if (item.DETCASHMNG_FORPAGCM === 'CTA') {
              strCadena = strCadena  + item.DETCASHMNG_TIPOCTACM + '\t';
            }
            if (item.DETCASHMNG_FORPAGCM === 'CHQ' || item.DETCASHMNG_FORPAGCM === 'EFE') {
              strCadena = strCadena  + '' + '\t';
            }
            if (item.DETCASHMNG_FORPAGCM === null) {
              strCadena = strCadena  + '' + '\t';
            }
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 11 Número de cuenta
          if (item.DETCASHMNG_FORPAGCM !== null) {
            if (item.DETCASHMNG_FORPAGCM === 'CTA') {
              if (item.DETCASHMNG_CODIGOBANCOCASH === '036') {
                this.autoCompletar(item.DETCASHMNG_NUMCTACM, 11);
              } else {
                strCadena = strCadena  + item.DETCASHMNG_NUMCTACM + '\t';
              }
              
            }
          } else {
            this.mensajeAlerta('info', 'Información', 'Forma de pago no existe del proveedor ' + item.DETCASHMNG_IDPROV);
          }
          
          if (item.DETCASHMNG_FORPAGCM === 'CHQ' || item.DETCASHMNG_FORPAGCM === 'EFE') {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 12 Tipo de Id del cliente beneficiario
          if (item.DETCASHMNG_TIPOIDENTIFCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_TIPOIDENTIFCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 13 Número ID Cliente Beneficiario
          if (item.DETCASHMNG_IDPROV !== null) {
            strCadena = strCadena  + item.DETCASHMNG_IDPROV + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 14 Nombre del Cliente Beneficiario
          if (item.DETCASHMNG_BENFCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_BENFCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 15 Direccion del beneficiario
          if (item.DETCASHMNG_DIFBENFCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_DIFBENFCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 16 ciudad
          if (item.DETCASHMNG_CIUDADCM !== null) {
            strCadena = strCadena  + item.DETCASHMNG_CIUDADCM + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 17 telefono
          if (item.DETCASHMNG_TELFBENFCM !== null) {
            strCadena = strCadena  + this.autoCompletar(item.DETCASHMNG_TELFBENFCM.replace('-', ''), 9) + '\t';
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 18 localidad o sector
          if (item.DETCASHMNG_FORPAGCM === 'CTA') {
            strCadena = strCadena  + '' + '\t';
          } else {
            if (item.DETCASHMNG_LOCPAGCM !== null) {
              strCadena = strCadena  + item.DETCASHMNG_LOCPAGCM + '\t';
            } else {
              strCadena = strCadena  + '' + '\t';
            }
          }
          
          // 19 referencia
          if (item.DETCASHMNG_CONCEPTO !== null) {
            strCadena = strCadena  + item.DETCASHMNG_CONCEPTO +  '\t';
            strConcepto = item.DETCASHMNG_CONCEPTO;
          } else {
            strCadena = strCadena  + '' + '\t';
          }
          
          // 20 email
          if (item.DETCASHMNG_COMPROBANTE !== null) {
            strRefAdic = item.DETCASHMNG_COMPROBANTE;
          }
          intCntrl = 0;
          
          if (item.DETCASHMNG_EMAILCM !== null) {
            strRefAdic = strRefAdic + '|' + item.DETCASHMNG_EMAILCM;
            intCntrl = 1;
          }
          
          strCadena = strCadena  +  strRefAdic +  '\t';
          
          strCadena = strCadena + '\r\n';
        }
        
        this.download(strNombreArchivo, strCadena);
      }
    } catch (err) {
      
      this.mensajeAlerta('error', 'Error', '! Proceso generar archivo no terminó satisfactoriamente.... !');
    }
  }
  
  cambio(event) {
    if (this.bandejaPagoService.encBandejaPagos !== undefined) {
      this.btnGuardar = false;
      this.btnRegresar = false;
      this.btnNuevo = true;
      // this.btnNuevoGrid = false;
      this.botonUltimo = true;
      this.botonAnterior = true;
      this.botonSiguiente = true;
      this.botonPrimero = true;
      this.btnBuscar = true;
    }
  }
  
}
