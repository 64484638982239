import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy, DecimalPipe } from '@angular/common';
import { AppRoutes } from './app.routes';
import { ReactiveFormsModule } from '@angular/forms';
import { DragDropModule, InputNumberModule } from 'primeng';
import { FileUploadModule } from 'primeng/fileupload';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { GalleriaModule } from 'primeng/galleria';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { KeyFilterModule } from 'primeng/keyfilter';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { CaptchaModule } from 'primeng/captcha';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
import { AppLoginMarcaComponent } from './pages/app.loginmarca.component';
import { AppMenuComponent, AppSubMenuComponent } from './app.menu.component';
import { AppRightMenuComponent } from './app.right-menu.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';
import { UsuarioEsquema } from './usuarioEsquema';
import { Usuario } from './usuario';
import { UsuarioDescargas } from './usuarioDescargas';

// Angular Material Components

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDividerModule } from '@angular/material/divider';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRippleModule } from '@angular/material/core';

// Components

import { AgGridModule } from 'ag-grid-angular';
import { CellEditorPrueba } from './advantage/config/cell-edit-prueba';
import { SafePipe } from './directivas/safe.pipe';
import { DatePipe } from '@angular/common';
import { SidebarModule } from 'primeng';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatTreeModule } from '@angular/material/tree';

import { ListacomponentesComponent } from './listacomponentes/listacomponentes.component';
import { MaesubcategoriaComponent } from './pdv/maesubcategoria/maesubcategoria.component';
import { MaecategoriaComponent } from './pdv/maecategoria/maecategoria.component';
import { MaestroComponent } from './pdv/maestro/maestro.component';
import { MaepantallaComponent } from './pdv/maepantalla/maepantalla.component';
import { AggridsaciComponent } from './advantage/aggridsaci/aggridsaci.component';
import { PdvprincipalComponent } from './pdv/pdvprincipal/pdvprincipal.component';
import { PdvproductosComponent } from './pdv/pdvproductos/pdvproductos.component';
import { PdvfacturaComponent } from './pdv/pdvfactura/pdvfactura.component';
import { PdvcalculadoraComponent } from './pdv/pdvcalculadora/pdvcalculadora.component';
import { PdvcobrarComponent } from './pdv/pdvcobrar/pdvcobrar.component';
import { InvmaearticuloComponent } from './inv/invmaearticulo/invmaearticulo.component';
import { InvmaebodegaComponent } from './inv/invmaebodega/invmaebodega.component';
import { InvmaebarraComponent } from './advantage/invmaebarra/invmaebarra.component';
import { InvbusquedaComponent } from './advantage/invbusqueda/invbusqueda.component';
import { InvmaegruposComponent } from './inv/invmaegrupos/invmaegrupos.component';
import { InvmaeunidadesComponent } from './inv/invmaeunidades/invmaeunidades.component';
import { InventradaComponent } from './inv/inventrada/inventrada.component';
import { BusquedadlgComponent } from './advantage/busquedadlg/busquedadlg.component';
import { InvsalidaComponent } from './inv/invsalida/invsalida.component';
import { ReportesComponent } from './reportes/reportes.component';
import { MantenimientoReportesComponent } from './mantenimiento-reportes/mantenimiento-reportes.component';
import { ReportePdfComponent } from './reporte-pdf/reporte-pdf.component';
import { VenmaeclienteComponent } from './ven/venmaecliente/venmaecliente.component';
import { InvtomafisicaComponent } from './inv/invtomafisica/invtomafisica.component';
import { BancierreCajaComponent } from './ban/bancierre-caja/bancierre-caja.component';
import { BanbusquedaComponent } from './ban/banbusqueda/banbusqueda.component';
import { AggridBanComponent } from './ban/aggrid-ban/aggrid-ban.component';
import { PedprincipalComponent } from './ven/pedido/pedprincipal/pedprincipal.component';
import { PedpedidoComponent } from './ven/pedido/pedpedido/pedpedido.component';
import { PedcalculadoraComponent } from './ven/pedido/pedcalculadora/pedcalculadora.component';
import { PedproductosComponent } from './ven/pedido/pedproductos/pedproductos.component';
import { InvprrecalkardexComponent } from './inv/invprrecalkardex/invprrecalkardex.component';
import { InvcontdocumentosComponent } from './inv/invcontdocumentos/invcontdocumentos.component';
import { VenmaeciudadComponent } from './ven/venmaeciudad/venmaeciudad.component';
import { VenmaegruposComponent } from './ven/venmaegrupos/venmaegrupos.component';
import { VenmaedescuentosComponent } from './ven/venmaedescuentos/venmaedescuentos.component';
import { VenmaeserviciosComponent } from './ven/venmaeservicios/venmaeservicios.component';
import { VenmaetransportistasComponent } from './ven/venmaetransportistas/venmaetransportistas.component';
import { VenmaevendedorComponent } from './ven/venmaevendedor/venmaevendedor.component';
import { VenmaepromocionComponent } from './ven/venmaepromocion/venmaepromocion.component';
import { EletrndocumentoComponent } from './ven/eletrndocumento/eletrndocumento.component';
import { CommaegrupoComponent } from './com/commaegrupo/commaegrupo.component';
import { CommaeserviciosComponent } from './com/commaeservicio/commaeservicio.component';
import { CommaecargosComponent } from './com/commaecargos/commaecargos.component';
import { CxcbancosClienteComponent } from './cxc/cxcbancos-cliente/cxcbancos-cliente.component';
import { CxccomisionComponent } from './cxc/cxccomision/cxccomision.component';
import { InvnumtransferenciaComponent } from './inv/invnumtransferencia/invnumtransferencia.component';
import { VenmaepryComponent } from './ven/venmaepry/venmaepry.component';
import { VenencfacComponent } from './ven/venencfac/venencfac.component';
import { CxcmaeretencionComponent } from './cxc/cxcmaeretencion/cxcmaeretencion.component';
import { CxcformaPagoComponent } from './cxc/cxcforma-pago/cxcforma-pago.component';
import { CxctarjetaCreditoComponent } from './cxc/cxctarjeta-credito/cxctarjeta-credito.component';
import { BanCajaComponent } from './ban/ban-caja/ban-caja.component';
import { BanmaebanComponent } from './ban/banmaeban/banmaeban.component';
import { VenencpedComponent } from './ven/venencped/venencped.component';
import { CommaeproveedorComponent } from './com/commaeproveedor/commaeproveedor.component';
import { ConmaecenComponent } from './cont/conmaecen/conmaecen.component';
import { VenencguiaRemisionComponent } from './ven/venencguia-remision/venencguia-remision.component';
import { ConstruccionComponent } from './advantage/construccion/construccion.component';
import { ConmaetipoDiarioComponent } from './cont/conmaetipo-diario/conmaetipo-diario.component';
import { ConmaeconComponent } from './cont/conmaecon/conmaecon.component';
import { PagocontadoComponent } from './ven/pagocontado/pagocontado.component';
import { InvactpreciosComponent } from './inv/invactprecios/invactprecios.component';
import { ComencordcomComponent } from './com/comencordcom/comencordcom.component';
import { InvrecalcularcostopmdComponent } from './inv/invrecalcularcostopmd/invrecalcularcostopmd.component';
import { InvtrnkardexotrComponent } from './ven/invtrnkardexotr/invtrnkardexotr.component';
import { CxcenccuotaComponent } from './ven/cxcenccuota/cxcenccuota.component';
import { VendetfacComponent } from './ven/vendetfac/vendetfac.component';
import { VenpedidosComponent } from './ven/venpedidos/venpedidos.component';
import { InvtrnloteComponent } from './ven/invtrnlote/invtrnlote.component';
import { ComnotrecComponent } from './com/comnotrec/comnotrec.component';
import { VenencdevfacComponent } from './ven/venencdevfac/venencdevfac.component';
import { NumeracionComponent } from './advantage/numeracion/numeracion.component';
import { InvmaeloteComponent } from './ven/invmaelote/invmaelote.component';
import { PagocontadodvfComponent } from './ven/pagocontadodvf/pagocontadodvf.component';
import { DatosventaarticuloComponent } from './ven/datosventaarticulo/datosventaarticulo.component';
import { ComencfacproComponent } from './com/comencfacpro/comencfacpro.component';
import { ComencdevfacComponent } from './com/comencdevfac/comencdevfac.component';
import { DatosanexosComponent } from './com/datosanexos/datosanexos.component';
import { ComdetfacproComponent } from './com/comdetfacpro/comdetfacpro.component';
import { ComdetnotrecComponent } from './com/comdetnotrec/comdetnotrec.component';
import { AnexmaecodsriComponent } from './anex/anexmaecodsri/anexmaecodsri.component';
import { ComdetordcomComponent } from './com/comdetordcom/comdetordcom.component';
import { CxccarteraactivaComponent } from './cxc/cxccarteraactiva/cxccarteraactiva.component';
import { CxctrnpagoretComponent } from './cxc/cxctrnpagoret/cxctrnpagoret.component';
import { ComtrndistribucionccComponent } from './com/comtrndistribucioncc/comtrndistribucioncc.component';
import { CxptrnpagodocumentoComponent } from './com/cxptrnpagodocumento/cxptrnpagodocumento.component';
import { DescargasHomeComponent } from './descargas/descargas-home/descargas-home.component';
import { RecuperarPasswdComponent } from './descargas/recuperar-passwd/recuperar-passwd.component';
import { ComtrnreembolsosComponent } from './com/comtrnreembolsos/comtrnreembolsos.component';
import { ActualizacionpreciosfpComponent } from './com/actualizacionpreciosfp/actualizacionpreciosfp.component';
import { ConcontabilizadocComponent } from './cont/concontabilizadoc/concontabilizadoc.component';
import { SacimaeconfiguracionComponent } from './seg/sacimaeconfiguracion/sacimaeconfiguracion.component';
import { SacimaeperiodoComponent } from './cont/sacimaeperiodo/sacimaeperiodo.component';
import { ConcierrecontableComponent } from './cont/concierrecontable/concierrecontable.component';
import { BanconcilbancariaComponent } from './ban/banconcilbancaria/banconcilbancaria.component';
import { ConencasilComponent } from './cont/conencasil/conencasil.component';
import { BantrnbaneditComponent } from './ban/bantrnbanedit/bantrnbanedit.component';
import { BantrnbanComponent } from './ban/bantrnban/bantrnban.component';
import { ReportepdfviewerComponent } from './reportepdfviewer/reportepdfviewer.component';
import { BusquedadlgmaeComponent } from './advantage/busquedadlgmae/busquedadlgmae.component';
import { CxptrncobroComponent } from './cxp/cxptrncobro/cxptrncobro.component';
import { ContrndistribucionpccComponent } from './cont/contrndistribucionpcc/contrndistribucionpcc.component';
import { ComliquidcomComponent } from './com/comliquidcom/comliquidcom.component';
import { SacimaecfngimprComponent } from './advantage/sacimaecfngimpr/sacimaecfngimpr.component';
import { SacimaeauditoriaComponent } from './advantage/sacimaeauditoria/sacimaeauditoria.component';
import { BancashmanagementComponent } from './ban/bancashmanagement/bancashmanagement.component';
import { BancashmngeditComponent } from './ban/bancashmngedit/bancashmngedit.component';
import { BanbandejapagosComponent } from './ban/banbandejapagos/banbandejapagos.component';
import { BantrntarjetaComponent } from './ban/bantrntarjeta/bantrntarjeta.component';
import { SegmaecompaniaComponent } from './seg/segmaecompania/segmaecompania.component';
import { BantrncajaComponent } from './ban/bantrncaja/bantrncaja.component';
import { BanmodificarmovcajaComponent } from './ban/banmodificarmovcaja/banmodificarmovcaja.component';
import { DashboardComponent } from './analitica/dashboard/dashboard.component';
import { InvenctransferComponent } from './inv/invenctransfer/invenctransfer.component';
import { SegmaeusuarioComponent } from './seg/segmaeusuario/segmaeusuario.component';
import { NomcfgrgenComponent } from './nom/nomcfgrgen/nomcfgrgen.component';
import { NommaedepartamentoComponent } from './nom/nommaedepartamento/nommaedepartamento.component';
import { NommaeperiodoComponent } from './nom/nommaeperiodo/nommaeperiodo.component';
import { NommaeprovinciasComponent } from './nom/nommaeprovincias/nommaeprovincias.component';
import { NommaetiporolComponent } from './nom/nommaetiporol/nommaetiporol.component';
import { NommaetablitaretComponent } from './nom/nommaetablitaret/nommaetablitaret.component';
import { SegmaeautorizacionComponent } from './seg/segmaeautorizacion/segmaeautorizacion.component';
import { SegmaeorganizacionComponent } from './seg/segmaeorganizacion/segmaeorganizacion.component';
import { SegmaeperfilComponent } from './seg/segmaeperfil/segmaeperfil.component';
import { CxpenccuotaComponent } from './cxp/cxpenccuota/cxpenccuota.component';
import { SegconformacionComponent } from './seg/segconformacion/segconformacion.component';
import { InvutilexistenciasComponent } from './inv/invutilexistencias/invutilexistencias.component';
import { BusquedabdgComponent } from './advantage/busquedabdg/busquedabdg.component';
import { SegmaetiprefComponent } from './seg/segmaetipref/segmaetipref.component';
import { InvtrnajustesComponent } from './inv/invtrnajustes/invtrnajustes.component';
import { SegmaenumeraciongComponent } from './seg/segmaenumeraciong/segmaenumeraciong.component';
import { SacimaenumeracionCComponent } from './seg/sacimaenumeracion-c/sacimaenumeracion-c.component';
import { SegcambioclaveComponent } from './seg/segcambioclave/segcambioclave.component';
import { SegmaereferenciaComponent } from './seg/segmaereferencia/segmaereferencia.component';
import { SegmaereppantallaComponent } from './seg/segmaereppantalla/segmaereppantalla.component';
import { VenmaeprovinciasComponent } from './ven/venmaeprovincias/venmaeprovincias.component';
import { ConasidescuadreComponent } from './cont/conasidescuadre/conasidescuadre.component';
import { CondlginicializarcontadoresComponent } from './cont/condlginicializarcontadores/condlginicializarcontadores.component';
import { NommaerubroComponent } from './nom/nommaerubro/nommaerubro.component';
import { InvtrndatosaltComponent } from './inv/invtrndatosalt/invtrndatosalt.component';
import { FirmardocumentoComponent } from './ven/firmardocumento/firmardocumento.component';
import { InvtrnreservasComponent } from './inv/invtrnreservas/invtrnreservas.component';
import { InvreservasComponent } from './inv/invreservas/invreservas.component';
import { ExistenciabodegaComponent } from './ven/existenciabodega/existenciabodega.component';
import { AnexgenerararchivosComponent } from './anex/anexgenerararchivos/anexgenerararchivos.component';
import { AnexgenerarxmlComponent } from './anex/anexgenerarxml/anexgenerarxml.component';
import { SacimaeempresasComponent } from './anex/sacimaeempresas/sacimaeempresas.component';
import { SacimaeempresaComponent } from './seg/sacimaeempresa/sacimaeempresa.component';
import { NomencrolgenecabeComponent } from './nom/nomencrolgenecabe/nomencrolgenecabe.component';
import { NomcierreperiodoComponent } from './nom/nomcierreperiodo/nomcierreperiodo.component';
import { CxpbuscardocpagadosComponent } from './cxp/cxpbuscardocpagados/cxpbuscardocpagados.component';
import { NomexpliqutiarchtxtComponent } from './nom/nomexpliqutiarchtxt/nomexpliqutiarchtxt.component';
import { NomproccontabComponent } from './nom/nomproccontab/nomproccontab.component';
import { NomempleadoComponent } from './nom/nomempleado/nomempleado.component';
import { NommaedatosadiComponent } from './nom/nommaedatosadi/nommaedatosadi.component';
import { NommatdatadiComponent } from './nom/nommatdatadi/nommatdatadi.component';
import { NommaeddataglobComponent } from './nom/nommaeddataglob/nommaeddataglob.component';
import { NomempleadoperiodoComponent } from './nom/nomempleadoperiodo/nomempleadoperiodo.component';
import { NomrhucabhoraextrComponent } from './nom/nomrhucabhoraextr/nomrhucabhoraextr.component';
import { NomencrolcerradosComponent } from './nom/nomencrolcerrados/nomencrolcerrados.component';
import { GenerarpedidosComponent } from './ven/generarpedidos/generarpedidos.component';
import { VentransforpedComponent } from './ven/ventransforped/ventransforped.component';
import { NomrhucabimpurentComponent } from './nom/nomrhucabimpurent/nomrhucabimpurent.component';
import { SegclaveautorizaComponent } from './nom/segclaveautoriza/segclaveautoriza.component';
import { NomtrannovedadComponent } from './nom/nomtrannovedad/nomtrannovedad.component';
import { NomencrolComponent } from './nom/nomencrol/nomencrol.component';
import { Nomencrol1Component } from './nom/nomencrol/componentes/nomencrol1/nomencrol1.component';
import { Nomencrol3Component } from './nom/nomencrol/componentes/nomencrol3/nomencrol3.component';
import { ProdencordproComponent } from './prod/prodencordpro/prodencordpro.component';
import { ProdmaecostoindComponent } from './prod/prodmaecostoind/prodmaecostoind.component';
import { InvtrnfichatecnicaComponent } from './inv/invtrnfichatecnica/invtrnfichatecnica.component';
import { ProdcostosxfechaComponent } from './prod/prodcostosxfecha/prodcostosxfecha.component';
import { ProdrecalcularcostosindComponent } from './prod/prodrecalcularcostosind/prodrecalcularcostosind.component';
import { NomutilidadesComponent } from './nom/nomutilidades/nomutilidades.component';
import { ProdcontrolcalidadComponent } from './prod/prodcontrolcalidad/prodcontrolcalidad.component';
import { NomliqudecitercComponent } from './nom/nomliqudeciterc/nomliqudeciterc.component';
import { NomexpliqubenesocarchtxtComponent } from './nom/nomexpliqubenesocarchtxt/nomexpliqubenesocarchtxt.component';
import { NomepleadovacComponent } from './nom/nomepleadovac/nomepleadovac.component';
import { NomtransotregresosComponent } from './nom/nomtransotregresos/nomtransotregresos.component';
import { InvconsultalotesComponent } from './ven/invconsultalotes/invconsultalotes.component';
import { ImportadatosexcelComponent } from './nom/importadatosexcel/importadatosexcel.component';
import { TecmaeetiquetaComponent } from './tec/tecmaeetiqueta/tecmaeetiqueta.component';
import { TecmaeequipoComponent } from './tec/tecmaeequipo/tecmaeequipo.component';
import { TecencmantComponent } from './tec/tecencmant/tecencmant.component';
import { UploadfilesComponent } from './advantage/uploadfiles/uploadfiles.component';
import { TwoDigitDecimaNumberDirective } from './directivas/two-digit-decima-number.directive';
import { ThreeDigitDecimalNumberDirective } from './directivas/three-digit-decimal-number.directive';
import { FourDigitDecimalNumberDirective } from './directivas/four-digit-decimal-number.directive';
import { TecencordtraComponent } from './tec/tecencordtra/tecencordtra.component';
import { ActmaegrupoComponent } from './act/actmaegrupo/actmaegrupo.component';
import { InvrecalcularcostopromprodComponent } from './inv/invrecalcularcostopromprod/invrecalcularcostopromprod.component';
import { VentrnreembolsosComponent } from './ven/ventrnreembolsos/ventrnreembolsos.component';
import { VenexportacionnuevoComponent } from './ven/venexportacionnuevo/venexportacionnuevo.component';
import { ActmaepolizaComponent } from './act/actmaepoliza/actmaepoliza.component';
import { ActmaeactivoComponent } from './act/actmaeactivo/actmaeactivo.component';
import { VenencptovtaComponent } from './ven/venencptovta/venencptovta.component';
import { DlgnuevoclienteComponent } from './ven/dlgnuevocliente/dlgnuevocliente.component';

// Services

import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { CalculosServiceService } from './servicios/calculos-service.service';
import { ConfInicialesService } from './servicios/conf-iniciales.service';
import { AuditoriagralService } from './advantage/advantageservices/auditoriagral.service';
import { ActenckardexComponent } from './act/actenckardex/actenckardex.component';
import { ActencsalidaComponent } from './act/actencsalida/actencsalida.component';
import { SacimaereferenciaactarComponent } from './act/sacimaereferenciaactar/sacimaereferenciaactar.component';
import { SacimaereferenciaactdpComponent } from './act/sacimaereferenciaactdp/sacimaereferenciaactdp.component';
import { SacimaereferenciaactofComponent } from './act/sacimaereferenciaactof/sacimaereferenciaactof.component';
import { SacimaereferenciaactmtslafComponent } from './act/sacimaereferenciaactmtslaf/sacimaereferenciaactmtslaf.component';
import { MaecategoriaService } from './servicios/maecategoria.service';
import { InvvehiculoComponent } from './src/advantage/invvehiculo/invvehiculo.component';
import { VencuadredecajaComponent } from './vencuadredecaja/vencuadredecaja.component';
import { VenconsulpedComponent } from './ven/venconsulped/venconsulped.component';
import { AneximpdocsriComponent } from './anex/aneximpdocsri/aneximpdocsri.component';
import { CxctrnretencionesComponent } from './cxc/cxctrnretenciones/cxctrnretenciones.component';
import { CxctrncobranzasComponent } from './cxc/cxctrncobranzas/cxctrncobranzas.component';
import { CxctrnedithistComponent } from './cxc/cxctrnedithist/cxctrnedithist.component';
import { NomenviorolesComponent } from './nom/nomenvioroles/nomenvioroles.component';
import { ComimpdoceleComponent } from './com/comimpdocele/comimpdocele.component';
import { SegencenviocorreoComponent } from './seg/segencenviocorreo/segencenviocorreo.component';
import { SacifrmenviocorreosComponent } from './seg/sacifrmenviocorreos/sacifrmenviocorreos.component';
import { CxceditdatosclienteComponent } from './cxc/cxceditdatoscliente/cxceditdatoscliente.component';
import { CxctrnabonoComponent } from './cxc/cxctrnabono/cxctrnabono.component';
import { CxctrncancelacionComponent } from './cxc/cxctrncancelacion/cxctrncancelacion.component';
import { CxcconsulfacvenComponent } from './cxc/cxcconsulfacven/cxcconsulfacven.component';
import { BantrninversionesComponent } from './ban/bantrninversiones/bantrninversiones.component';
import { BanmaeentifinComponent } from './ban/banmaeentifin/banmaeentifin.component';
import { ProdencprodprocesoComponent } from './prod/prodencprodproceso/prodencprodproceso.component';
import { InvhistorialserialComponent } from './inv/invhistorialserial/invhistorialserial.component';
import { InvtrnajustenegComponent } from './inv/invtrnajusteneg/invtrnajusteneg.component';



// import {ValorTestingComponent} from './ven/venencfac/venencfac.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutes,
    HttpClientModule,
    BrowserAnimationsModule,
    AccordionModule,
    AutoCompleteModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CarouselModule,
    ChartModule,
    CheckboxModule,
    ChipsModule,
    CodeHighlighterModule,
    ConfirmDialogModule,
    ColorPickerModule,
    ContextMenuModule,
    DataViewModule,
    DialogModule,
    DropdownModule,
    EditorModule,
    FieldsetModule,
    FileUploadModule,
    FullCalendarModule,
    GalleriaModule,
    InplaceModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    LightboxModule,
    ListboxModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OrderListModule,
    OrganizationChartModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PanelMenuModule,
    PasswordModule,
    PickListModule,
    ProgressBarModule,
    RadioButtonModule,
    RatingModule,
    ScrollPanelModule,
    SelectButtonModule,
    SlideMenuModule,
    SliderModule,
    SpinnerModule,
    SplitButtonModule,
    StepsModule,
    TableModule,
    TabMenuModule,
    TabViewModule,
    TerminalModule,
    TieredMenuModule,
    ToastModule,
    ToggleButtonModule,
    ToolbarModule,
    TooltipModule,
    TreeModule,
    TreeTableModule,
    VirtualScrollerModule,
    AgGridModule.withComponents([CellEditorPrueba]),
    SidebarModule,
    ProgressSpinnerModule,
    KeyFilterModule,
    NgxExtendedPdfViewerModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    OverlayPanelModule,
    CaptchaModule,
    AgGridModule,
    PdfViewerModule,
    InputNumberModule,
    MatTreeModule,
    AgGridModule,
    ReactiveFormsModule//CDPJ
  ],
  declarations: [
    AppComponent,
    AppMainComponent,
    AppMenuComponent,
    AppRightMenuComponent,
    AppSubMenuComponent,
    AppTopBarComponent,
    AppFooterComponent,
    AppNotfoundComponent,
    AppErrorComponent,
    AppAccessdeniedComponent,
    AppLoginComponent,
    AppLoginMarcaComponent,
    ListacomponentesComponent,
    PdvprincipalComponent,
    PdvproductosComponent,
    PdvfacturaComponent,
    PdvcalculadoraComponent,
    PdvcobrarComponent,
    TwoDigitDecimaNumberDirective,
    ThreeDigitDecimalNumberDirective,
    FourDigitDecimalNumberDirective,
    SafePipe,
    InvmaearticuloComponent,
    InvmaebodegaComponent,
    InvmaebarraComponent,
    InvbusquedaComponent,
    InvmaegruposComponent,
    InvmaeunidadesComponent,
    InventradaComponent,
    BusquedadlgComponent,
    CellEditorPrueba,
    AggridsaciComponent,
    InvsalidaComponent,
    ReportesComponent,
    MantenimientoReportesComponent,
    ReportePdfComponent,
    VenmaeclienteComponent,
    InvtomafisicaComponent,
    BancierreCajaComponent,
    BanbusquedaComponent,
    AggridBanComponent,
    PedprincipalComponent,
    PedpedidoComponent,
    PedcalculadoraComponent,
    PedproductosComponent,
    InvprrecalkardexComponent,
    InvcontdocumentosComponent,
    VenmaeciudadComponent,
    VenmaegruposComponent,
    VenmaedescuentosComponent,
    VenmaeserviciosComponent,
    VenmaetransportistasComponent,
    VenmaevendedorComponent,
    VenmaepromocionComponent,
    EletrndocumentoComponent,
    CommaeserviciosComponent,
    CommaegrupoComponent,
    CommaecargosComponent,
    CxcbancosClienteComponent,
    CxccomisionComponent,
    InvnumtransferenciaComponent,
    VenmaepryComponent,
    VenencfacComponent,
    CxcmaeretencionComponent,
    CxcformaPagoComponent,
    CxctarjetaCreditoComponent,
    BanCajaComponent,
    MaesubcategoriaComponent,
    MaecategoriaComponent,
    MaepantallaComponent,
    MaestroComponent,
    BanmaebanComponent,
    VenencpedComponent,
    CommaeproveedorComponent,
    ConmaecenComponent,
    VenencguiaRemisionComponent,
    ConstruccionComponent,
    ConmaetipoDiarioComponent,
    ConmaeconComponent,
    PagocontadoComponent,
    InvactpreciosComponent,
    ComencordcomComponent,
    InvrecalcularcostopmdComponent,
    InvtrnkardexotrComponent,
    CxcenccuotaComponent,
    VendetfacComponent,
    VenpedidosComponent,
    InvtrnloteComponent,
    ComnotrecComponent,
    VenencdevfacComponent,
    NumeracionComponent,
    InvmaeloteComponent,
    PagocontadodvfComponent,
    DatosventaarticuloComponent,
    ComencfacproComponent,
    ComencdevfacComponent,
    DatosanexosComponent,
    ComdetfacproComponent,
    ComdetnotrecComponent,
    AnexmaecodsriComponent,
    ComdetordcomComponent,
    CxccarteraactivaComponent,
    CxctrnpagoretComponent,
    ComtrndistribucionccComponent,
    CxptrnpagodocumentoComponent,
    DescargasHomeComponent,
    RecuperarPasswdComponent,
    ComtrnreembolsosComponent,
    ActualizacionpreciosfpComponent,
    ConcontabilizadocComponent,
    SacimaeconfiguracionComponent,
    SacimaeperiodoComponent,
    ConcierrecontableComponent,
    BanconcilbancariaComponent,
    ConencasilComponent,
    BantrnbaneditComponent,
    BantrnbanComponent,
    ReportepdfviewerComponent,
    BusquedadlgmaeComponent,
    CxptrncobroComponent,
    ContrndistribucionpccComponent,
    ComliquidcomComponent,
    SacimaecfngimprComponent,
    SacimaeauditoriaComponent,
    BancashmanagementComponent,
    BancashmngeditComponent,
    BanbandejapagosComponent,
    BantrntarjetaComponent,
    SegmaecompaniaComponent,
    BantrncajaComponent,
    BanmodificarmovcajaComponent,
    DashboardComponent,
    InvenctransferComponent,
    SegmaeusuarioComponent,
    NomcfgrgenComponent,
    NommaedepartamentoComponent,
    NommaeperiodoComponent,
    NommaeprovinciasComponent,
    NommaetiporolComponent,
    NommaetablitaretComponent,
    SegmaeautorizacionComponent,
    SegmaeorganizacionComponent,
    SegmaeperfilComponent,
    CxpenccuotaComponent,
    SegconformacionComponent,
    InvutilexistenciasComponent,
    BusquedabdgComponent,
    SegmaetiprefComponent,
    InvtrnajustesComponent,
    SegmaenumeraciongComponent,
    SacimaenumeracionCComponent,
    SegcambioclaveComponent,
    SegmaereferenciaComponent,
    SegmaereppantallaComponent,
    VenmaeprovinciasComponent,
    ConasidescuadreComponent,
    CondlginicializarcontadoresComponent,
    NommaerubroComponent,
    InvtrndatosaltComponent,
    FirmardocumentoComponent,
    InvtrnreservasComponent,
    InvreservasComponent,
    ExistenciabodegaComponent,
    AnexgenerararchivosComponent,
    AnexgenerarxmlComponent,
    SacimaeempresasComponent,
    SacimaeempresaComponent,
    NomencrolgenecabeComponent,
    NomcierreperiodoComponent,
    CxpbuscardocpagadosComponent,
    NomexpliqutiarchtxtComponent,
    NomproccontabComponent,
    NomempleadoComponent,
    NomepleadovacComponent,
    NommaedatosadiComponent,
    NommatdatadiComponent,
    NommaeddataglobComponent,
    NomempleadoperiodoComponent,
    NomrhucabhoraextrComponent,
    NomencrolcerradosComponent,
    GenerarpedidosComponent,
    VentransforpedComponent,
    NomrhucabimpurentComponent,
    SegclaveautorizaComponent,
    NomtrannovedadComponent,
    NomencrolComponent,
    Nomencrol1Component,
    Nomencrol3Component,
    ProdcostosxfechaComponent,
    ProdencordproComponent,
    ProdmaecostoindComponent,
    InvtrnfichatecnicaComponent,
    ProdrecalcularcostosindComponent,
    NomutilidadesComponent,
    ProdcontrolcalidadComponent,
    NomliqudecitercComponent,
    NomexpliqubenesocarchtxtComponent,
    NomtransotregresosComponent,
    InvconsultalotesComponent,
    ImportadatosexcelComponent,
    TecmaeetiquetaComponent,
    TecmaeequipoComponent,
    TecencmantComponent,
    UploadfilesComponent,
    TecencordtraComponent,
    ActmaegrupoComponent,
    InvrecalcularcostopromprodComponent,
    VentrnreembolsosComponent,
    VenexportacionnuevoComponent,
    ActmaepolizaComponent,
    ActmaeactivoComponent,
    VenencptovtaComponent,
    ActenckardexComponent,
    ActencsalidaComponent,
    SacimaereferenciaactarComponent,
    SacimaereferenciaactdpComponent,
    SacimaereferenciaactofComponent,
    SacimaereferenciaactmtslafComponent,
    DlgnuevoclienteComponent,
    InvvehiculoComponent,
    VencuadredecajaComponent,
    VenconsulpedComponent,
    AneximpdocsriComponent,
    CxctrnretencionesComponent,
    CxctrncobranzasComponent,
    CxctrnedithistComponent,
    NomenviorolesComponent,
    ComimpdoceleComponent,
    SegencenviocorreoComponent,
    SacifrmenviocorreosComponent,
    CxceditdatosclienteComponent,
    CxctrnabonoComponent,
    CxctrncancelacionComponent,
    CxcconsulfacvenComponent,
    BantrninversionesComponent,
    BanmaeentifinComponent,
    ProdencprodprocesoComponent,
    InvhistorialserialComponent,
    InvtrnajustenegComponent,
  ],
  entryComponents: [
    MaesubcategoriaComponent,
    MaecategoriaComponent,
    MaepantallaComponent,
    MaestroComponent,
    AggridsaciComponent,
    InvtrnloteComponent,
    InvtrnkardexotrComponent,
    PagocontadoComponent,
    PagocontadodvfComponent,
    ConencasilComponent,
    SacimaecfngimprComponent,
    BantrnbaneditComponent,
    DlgnuevoclienteComponent
  ], exports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    ConfirmDialogModule,
    DragDropModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    UsuarioEsquema,
    Usuario,
    UsuarioDescargas,
    CalculosServiceService,
    ConfInicialesService,
    DatePipe,
    DecimalPipe,    
    MessageService,
    ConfirmationService,
    AuditoriagralService,
    MaecategoriaService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
