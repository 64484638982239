import { Component, EventEmitter, Input, OnInit, Output, ViewChild, SimpleChanges, ɵConsole,ElementRef, AfterViewInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { InvmaearticuloService } from '../../inv/invservicios/invmaearticulo.service';
import { MessageService, SelectItem } from 'primeng/api';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { InvmaebodegaService } from '../../inv/invservicios/invmaebodega.service';
import { InvbusquedaComponent } from '../invbusqueda/invbusqueda.component';
import { InvmaeunidadesService } from '../../inv/invservicios/invmaeunidades.service';
import { InvgrupoService } from '../../inv/invservicios/invgrupo.service';
import { InvtomafisicaService } from '../../inv/invservicios/invtomafisica.service';
import { InvsalidaService } from '../../inv/invservicios/invsalida.service';
import { BancierrecajaService } from '../../ban/banservicios/bancierrecaja.service';
import { BusquedadlgComponent } from '../busquedadlg/busquedadlg.component';
import { InventradaService } from '../../inv/invservicios/inventrada.service';
import { VenmaegruposService } from '../../ven/venservicios/venmaegrupos.service';
import { VenmaeserviciosService } from '../../ven/venservicios/venmaeservicios.service';
import { CommaeservicioService } from '../../com/comservicios/commaeservicio.service';
import { CommaegrupoService } from '../../com/comservicios/commaegrupo.service';
import { CommaecargosService } from '../../com/comservicios/commaecargos.service';
import { VenmaepromocionService } from '../../ven/venservicios/venmaepromocion.service';
import { CxcomisionService } from '../../cxc/cxcservicios/cxcomision.service';
import { CxcmaeretencionService } from '../../cxc/cxcservicios/cxcmaeretencion.service';
import { VenmaepryService } from '../../ven/venservicios/venmaepry.service';
import { CxcformaPagoService } from '../../cxc/cxcservicios/cxcforma-pago.service';
import { CxctarjetaCreditoService } from '../../cxc/cxcservicios/cxctarjeta-credito.service';
import { BancajaService } from '../../ban/banservicios/bancaja.service';
import { VenmaedescuentosService } from '../../ven/venservicios/venmaedescuentos.service';
import { VenencfacService } from '../../ven/venservicios/venencfac.service';
import { VenencpedService } from '../../ven/venservicios/venencped.service';
import { UtilitariosService } from '../advantageservices/utilitarios.service';
import { PagocontadoService } from '../../ven/venservicios/pagocontado.service';
import { CommaeproveedorService } from '../../com/comservicios/commaeproveedor.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { VenDetfacZ } from '../../ven/veninterfaces/vendetfacz';
import { ComencordcomService } from '../../com/comservicios/comencordcom.service';
import { VenenguiaRemisionService } from '../../ven/venservicios/venenguia-remision.service';
import { InvtrnloteService } from '../../ven/venservicios/invtrnlote.service';
import { InvmaeloteService } from '../../ven/venservicios/invmaelote.service';
import { PagocontadodvfService } from '../../ven/venservicios/pagocontadodvf.service';
import { ComnotrecService } from '../../com/comservicios/comnotrec.service';
import { VenencdevfacService } from '../../ven/venservicios/venencdevfac.service';
import { ComencfacproService } from '../../com/comservicios/comencfacpro.service';
import { DatosanexosService } from '../../com/comservicios/datosanexos.service';
import { ComencdevfacService } from '../../com/comservicios/comencdevfac.service';
import { AnexmaecodsriService } from '../../anex/anexservicios/anexmaecodsri.service';
import { CxccarteraactivaService } from '../../cxc/cxcservicios/cxccarteraactiva.service';
import { CxctrnpagoretService } from '../../cxc/cxcservicios/cxctrnpagoret.service';
import { ComtrndistribucionccService } from '../../com/comservicios/comtrndistribucioncc.service';
import { CxptrnpagodocumentoService } from '../../com/comservicios/cxptrnpagodocumento.service';
import { ConencasilService } from '../../cont/conservicios/conencasil.service';
import { BantrnbaneditService } from '../../ban/banservicios/bantrnbanedit.service';
import { ContrndistribpccService } from '../../cont/conservicios/contrndistribpcc.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { BantrntarjetaService } from '../../ban/banservicios/bantrntarjeta.service';
import { BantrncajaService } from '../../ban/banservicios/bantrncaja.service';
import { InvenctransferService } from '../../inv/invservicios/invenctransfer.service';
import { CxptrncobroService } from '../../cxp/cxpservicios/cxptrncobro.service';
import { SacimaeconfiguracionService } from '../../seg/segservicios/sacimaeconfiguracion.service';
import { InvtrnajustesService } from '../../inv/invservicios/invtrnajustes.service';
import { SacimaenumeracionCService } from 'src/app/seg/segservicios/sacimaenumeracion-c.service';
import { CxpenccuotaService } from '../../cxp/cxpservicios/cxpenccuota.service';
import { NommaerubroService } from 'src/app/nom/nomservicios/nommaerubro.service';
import { InvtrnreservasService } from '../../inv/invservicios/invtrnreservas.service';
import { NomempleadosService } from 'src/app/nom/nomservicios/nomempleados.service';
import { VenmaeclienteService } from 'src/app/ven/venservicios/venmaecliente.service';
import { NomcabimpurentserviceService } from 'src/app/nom/nomservicios/nomcabimpurentservice.service';
import { NomtrannovedadService } from 'src/app/nom/nomservicios/nomtrannovedad.service';
import { ProdencordproService } from 'src/app/prod/prodservicios/prodencordpro.service';
import { ProdcostosfechaService } from 'src/app/prod/prodservicios/prodcostosfecha.service';
import { NomutilidadesService } from 'src/app/nom/nomservicios/nomutilidades.service';
import { NomliqudecitercService } from 'src/app/nom/nomservicios/nomliqudeciterc.service';
import { VenDetped } from 'src/app/ven/veninterfaces/vendetped';
import { TecmaeequipoService } from 'src/app/tec/tecservicios/tecmaeequipo.service';
import { TecencmantService } from 'src/app/tec/tecservicios/tecencmant.service';
import { TecencordtraService } from 'src/app/tec/tecservicios/tecencordtra.service';
import { ActmaegrupoService } from 'src/app/act/actservicios/actmaegrupo.service';
import { ActencsalidaService } from 'src/app/act/actservicios/actencsalida.service';
import { VenencptovtaService } from 'src/app/ven/venservicios/venencptovta.service';
import {AneximpdocsriService}from'src/app/anex/anexservicios/aneximpdocsri.service';//GSRF
import { NomencrolService } from 'src/app/nom/nomservicios/nomencrol.service';//GSRF
import {CxctrnretencionesService} from '../../cxc/cxcservicios/cxctrnretenciones.service';//CDPJ
import { ComimpdoceleService } from '../../com/comservicios/comimpdocele.service';//GSRF
import {CxctrncancelacionService} from '../../cxc/cxcservicios/cxctrncancelacion.service';//CDPJ
import {CxctrnabonoService} from '../../cxc/cxcservicios/cxctrnabono.service';//CDPJ
import { InvtrnajustenegService } from "../../inv/invservicios/invtrnajusteneg.service";

@Component({
  selector: 'app-aggridsaci',
  templateUrl: './aggridsaci.component.html',
  styleUrls: ['./aggridsaci.component.css']
})
export class AggridsaciComponent implements OnInit,AfterViewInit {
  @ViewChild('gridContainer') gridContainer:ElementRef;
  @Output() selected = new EventEmitter<any>();
  @Output() cellClick = new EventEmitter<any>();
  @Output() cambios = new EventEmitter<boolean>();
  @Output() cambioboton = new EventEmitter<boolean>();
  @Output() rdbtipodet = new EventEmitter<any>();
  @Output() guardarEnter = new EventEmitter<boolean>();
  @Input() width: any;
  @Input() height: string;
  @Input() margintop: string;
  @Input() enableSorting: any;
  @Input() enableFilter: any;
  @Input() rowData: any;
  @Input() frameworkComponents: any;
  @Input() animateRows: any;
  @Input() rowSelection: any;
  @Input() columnDefs: any[];
  @Input() defaultColDef: any;
  @Input() rowStyle: any;
  @Input() singleClickEdit: any;
  @Input() botones: any;
  @Input() indice: number;
  @Input() mostrarGuardar: any;
  @Input() botonesAdicionales: any;
  @Input() comboOpciones: any[];
  @Input() tipodetalle: string;
  @Input() botonNuevo: boolean;
  @Input() botonGuardar: boolean;
  @Input() botonRegresar: boolean;
  @Input() botonBorrar: boolean;
  @Input() botonImagen: boolean;
  @Input() botonPedidos: boolean;
  @Input() botonOrdenCompra: boolean;
  @Input() botonOrden: boolean;
  @Input() botonFactura: boolean;
  @Input() botonFacturaPro: boolean;
  @Input() botonTransferencia: boolean;
  @Input() botonVerificarExis: boolean;
  @Input() botonOrganizar: boolean;
  @Input() botonCXC: boolean;
  @Input() botonInsertTransBan: boolean;
  @Input() botonDistribCC: boolean;
  @Input() botonAnticipo: boolean;
  @Input() imagenProducto: string;
  @Input() notlistenkey: boolean;
  @Input() suppressCellSelection: boolean;
  @Input() tipodetalleactivo: string;
  @Input() botonEditCashmng: boolean;
  @Input() botonExpedienteNuevo: boolean;
  @Input() botonExpedienteExistente: boolean;
  @Input() btnRecalcularCosto: boolean;
  @Input() btnCopiarCostos: boolean;
  @Input() btnVerExistenciaBodPed: boolean;
  @Input() btnInsertarReservas: boolean;
  @Input() btnInsertarLotes: boolean;
  @Input() botonBuscar: boolean;
  @Input() solobotoneliminar: boolean;
  @Input() btnVerInfoPedArt: boolean;
  @Input() btnVervehiculo: boolean;
  @Input() btnhomologa: boolean;//GSRF
  @Input() btnAgitems: boolean;//CDPJ
  @Input() origenLote:string;//CDPJ
  @Input() btnXmlret: boolean;//CDPJ
  @Input() btnGenconcepto:boolean;//CDPJ
  @Input() btnActCantKardex:boolean;//CDPJ
  public gridApi;

  private gridColumnApi;
  consulta: string;

  displayDialogBusqueda: boolean;
  displayDialogBusquedaFast: boolean;
  displayvehiculo: boolean;

  deshabilitarbotones: boolean;

  types: SelectItem[];
  arregloCons: any[];
  opcionbusqueda: string;

  index: number;
  indexunidad: number;
  busquedacampos: string[];
  tabla: string;
  where: string;
  existencias:string;
  precioarticulo:string;
  descuentoarticulo:string;
  ////GSRF
  poscidetfac: number;
  posartfac: number;
  public idcell = '';
  public rowcell = '';
  ////GSRF
  localeText = {
    // Set Filter
    selectAll: '(Seleccionar Todo)',
    selectAllSearchResults: '(Select All Search Results)',
    searchOoo: 'Buscar...',
    blanks: '(En Blanco)',
    noMatches: 'No matches',

    // Number Filter & Text Filter
    filterOoo: 'Filtrar...',
    equals: 'Igual',
    notEqual: 'Diferente',
    empty: 'Choose One',

    // Number Filter
    lessThan: 'Menos que',
    greaterThan: 'Mayor que',
    lessThanOrEqual: 'Menos o igual que',
    greaterThanOrEqual: 'Mayor o igual que',
    inRange: 'En rango de',
    inRangeStart: 'to',
    inRangeEnd: 'from',

    // Text Filter
    contains: 'Contiene',
    notContains: 'No contiene',
    startsWith: 'Empieza con',
    endsWith: 'Termina en',

    // Date Filter
    dateFormatOoo: 'yyyy-mm-dd',

    // Filter Conditions
    andCondition: 'Y',
    orCondition: 'O',

    // Filter Buttons
    applyFilter: 'Apply',
    resetFilter: 'Reset',
    clearFilter: 'Clear',
    cancelFilter: 'Cancel',

    // Filter Titles
    textFilter: 'Text Filter',
    numberFilter: 'Number Filter',
    dateFilter: 'Date Filter',
    setFilter: 'Set Filter',

    // Side Bar
    columns: 'Columnas',
    filters: 'Filtros',

    // columns tool panel
    pivotMode: 'Pivot Mode',
    groups: 'Row Groups',
    rowGroupColumnsEmptyMessage: 'Drag here to set row groups',
    values: 'Values',
    valueColumnsEmptyMessage: 'Drag here to aggregate',
    pivots: 'Column Labels',
    pivotColumnsEmptyMessage: 'Drag here to set column labels',

    // Header of the Default Group Column
    group: 'Group',

    // Other
    loadingOoo: 'Cargando...',
    noRowsToShow: 'No hay filas para mostrar',
    enabled: 'Enabled',

    // Menu
    pinColumn: 'Pin Column',
    pinLeft: 'Pin Left',
    pinRight: 'Pin Right',
    noPin: 'No Pin',
    valueAggregation: 'Value Aggregation',
    autosizeThiscolumn: 'Autosize This Column',
    autosizeAllColumns: 'Autosize All Columns',
    groupBy: 'Group by',
    ungroupBy: 'Un-Group by',
    resetColumns: 'Reset Columns',
    expandAll: 'Expand All',
    collapseAll: 'Close All',
    copy: 'Copy',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'Copy With Headers',
    paste: 'Paste',
    ctrlV: 'Ctrl+V',
    export: 'Export',
    csvExport: 'CSV Export',
    excelExport: 'Excel Export (.xlsx)',
    excelXmlExport: 'Excel Export (.xml)',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Sum',
    min: 'Min',
    max: 'Max',
    none: 'None',
    count: 'Count',
    avg: 'Average',
    filteredRows: 'Filtered',
    selectedRows: 'Selected',
    totalRows: 'Total Rows',
    totalAndFilteredRows: 'Rows',
    more: 'More',
    to: 'to',
    of: 'of',
    page: 'Page',
    nextPage: 'Next Page',
    lastPage: 'Last Page',
    firstPage: 'First Page',
    previousPage: 'Previous Page',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
    pivotChart: 'Pivot Chart',
    chartRange: 'Chart Range',

    columnChart: 'Column',
    groupedColumn: 'Grouped',
    stackedColumn: 'Stacked',
    normalizedColumn: '100% Stacked',

    barChart: 'Bar',
    groupedBar: 'Grouped',
    stackedBar: 'Stacked',
    normalizedBar: '100% Stacked',

    pieChart: 'Pie',
    pie: 'Pie',
    doughnut: 'Doughnut',

    line: 'Line',

    xyChart: 'X Y (Scatter)',
    scatter: 'Scatter',
    bubble: 'Bubble',

    areaChart: 'Area',
    area: 'Area',
    stackedArea: 'Stacked',
    normalizedArea: '100% Stacked',

    histogramChart: 'Histogram',

    // Charts
    pivotChartTitle: 'Pivot Chart',
    rangeChartTitle: 'Range Chart',
    settings: 'Settings',
    data: 'Data',
    format: 'Format',
    categories: 'Categories',
    defaultCategory: '(None)',
    series: 'Series',
    xyValues: 'X Y Values',
    paired: 'Paired Mode',
    axis: 'Axis',
    navigator: 'Navigator',
    color: 'Color',
    thickness: 'Thickness',
    xType: 'X Type',
    automatic: 'Automatic',
    category: 'Category',
    number: 'Number',
    time: 'Time',
    xRotation: 'X Rotation',
    yRotation: 'Y Rotation',
    ticks: 'Ticks',
    width: 'Width',
    height: 'Height',
    length: 'Length',
    padding: 'Padding',
    spacing: 'Spacing',
    chart: 'Chart',
    title: 'Title',
    titlePlaceholder: 'Chart title - double click to edit',
    background: 'Background',
    font: 'Font',
    //top: 'Top',
    //right: 'Right',
    //bottom: 'Bottom',
    //left: 'Left',
    labels: 'Labels',
    size: 'Size',
    minSize: 'Minimum Size',
    maxSize: 'Maximum Size',
    legend: 'Legend',
    position: 'Position',
    markerSize: 'Marker Size',
    markerStroke: 'Marker Stroke',
    markerPadding: 'Marker Padding',
    itemSpacing: 'Item Spacing',
    itemPaddingX: 'Item Padding X',
    itemPaddingY: 'Item Padding Y',
    layoutHorizontalSpacing: 'Horizontal Spacing',
    layoutVerticalSpacing: 'Vertical Spacing',
    strokeWidth: 'Stroke Width',
    offset: 'Offset',
    offsets: 'Offsets',
    tooltips: 'Tooltips',
    callout: 'Callout',
    markers: 'Markers',
    shadow: 'Shadow',
    blur: 'Blur',
    xOffset: 'X Offset',
    yOffset: 'Y Offset',
    lineWidth: 'Line Width',
    normal: 'Normal',
    bold: 'Bold',
    italic: 'Italic',
    boldItalic: 'Bold Italic',
    predefined: 'Predefined',
    fillOpacity: 'Fill Opacity',
    strokeOpacity: 'Line Opacity',
    histogramBinCount: 'Bin count',
    columnGroup: 'Column',
    barGroup: 'Bar',
    pieGroup: 'Pie',
    lineGroup: 'Line',
    scatterGroup: 'X Y (Scatter)',
    areaGroup: 'Area',
    histogramGroup: 'Histogram',
    groupedColumnTooltip: 'Grouped',
    stackedColumnTooltip: 'Stacked',
    normalizedColumnTooltip: '100% Stacked',
    groupedBarTooltip: 'Grouped',
    stackedBarTooltip: 'Stacked',
    normalizedBarTooltip: '100% Stacked',
    pieTooltip: 'Pie',
    doughnutTooltip: 'Doughnut',
    lineTooltip: 'Line',
    groupedAreaTooltip: 'Area',
    stackedAreaTooltip: 'Stacked',
    normalizedAreaTooltip: '100% Stacked',
    scatterTooltip: 'Scatter',
    bubbleTooltip: 'Bubble',
    histogramTooltip: 'Histogram',
    noDataToChart: 'No data available to be charted.',
    pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
    chartSettingsToolbarTooltip: 'Menu',
    chartLinkToolbarTooltip: 'Linked to Grid',
    chartUnlinkToolbarTooltip: 'Unlinked from Grid',
    chartDownloadToolbarTooltip: 'Download Chart',

    // ARIA
    ariaHidden: 'hidden',
    ariaVisible: 'visible',
    ariaChecked: 'checked',
    ariaUnchecked: 'unchecked',
    ariaIndeterminate: 'indeterminate',
    ariaColumnSelectAll: 'Toggle Select All Columns',
    ariaInputEditor: 'Input Editor',
    ariaDateFilterInput: 'Date Filter Input',
    ariaFilterInput: 'Filter Input',
    ariaFilterColumnsInput: 'Filter Columns Input',
    ariaFilterValue: 'Filter Value',
    ariaFilterFromValue: 'Filter from value',
    ariaFilterToValue: 'Filter to value',
    ariaFilteringOperator: 'Filtering Operator',
    ariaColumnToggleVisibility: 'column toggle visibility',
    ariaColumnGroupToggleVisibility: 'column group toggle visibility',
    ariaRowSelect: 'Press SPACE to select this row',
    ariaRowDeselect: 'Press SPACE to deselect this row',
    ariaRowToggleSelection: 'Press Space to toggle row selection',
    ariaRowSelectAll: 'Press Space to toggle all rows selection',
    ariaSearch: 'Search',
    ariaSearchFilterValues: 'Search filter values',
  };

  style = {};
  styleimg = {};
  objetoSeleccionado: any;

  scrollPosition:{top:number,left:number}={top:0,left:0}
  // @ts-ignore
  @ViewChild(InvbusquedaComponent) busq: InvbusquedaComponent;
  // @ts-ignore
  @ViewChild(BusquedadlgComponent) busqF: BusquedadlgComponent;

  displayDialogOpcionesDetProv: boolean;
  detalleSelDetFacPro: any;
  tipoDetFactXML = 'noXML';


  constructor(private datePipe: DatePipe, private artService: InvmaearticuloService,
    private busqService: InvbusquedaService, private bodService: InvmaebodegaService,
    private unidadService: InvmaeunidadesService, private grupService: InvgrupoService,
    private tomService: InvtomafisicaService, private salidaService: InvsalidaService,
    private cajasService: BancierrecajaService, private messageService: MessageService,
    private entradaService: InventradaService, private grupoService: VenmaegruposService,
    private serviService: VenmaeserviciosService, private comService: CommaeservicioService,
    private commaeGrupo: CommaegrupoService, private cargoService: CommaecargosService,
    private ServicioPromo: VenmaepromocionService, private comisionService: CxcomisionService,
    private cuentaContableServicio: CxcmaeretencionService, private pryService: VenmaepryService,
    private formapagosService: CxcformaPagoService, private tarjetaService: CxctarjetaCreditoService,
    private BancajaServices: BancajaService, private descuentoService: VenmaedescuentosService,
    private encfacService: VenencfacService, private encpedService: VenencpedService,
    private utilitariosService: UtilitariosService, private pagconservice: PagocontadoService,
    private confIniciales: ConfInicialesService, private encordService: ComencordcomService,
    private proveedorServicio: CommaeproveedorService, private venenguiaRemisionService: VenenguiaRemisionService,
    private trnloteservice: InvtrnloteService, private maeloteservice: InvmaeloteService,
    private pagconservicedvf: PagocontadodvfService, public comnotrecServicio: ComnotrecService,
    private venDevFacService: VenencdevfacService, private encfacproService: ComencfacproService,
    private datosanexService: DatosanexosService, private comDevFacService: ComencdevfacService,
    private anexmaecodsriService: AnexmaecodsriService, private cxcCarteraService: CxccarteraactivaService,
    private cxctrnpagoretService: CxctrnpagoretService, private distriCCService: ComtrndistribucionccService,
    private cxptrnpagodocService: CxptrnpagodocumentoService, private encasilService: ConencasilService,
    private bantrnbanEditService: BantrnbaneditService, private contrnDistribccService: ContrndistribpccService,
    public init: NuevoComponentService, private bantrnTarjetaService: BantrntarjetaService,
    private bantrnCajaService: BantrncajaService, private enctransferService: InvenctransferService,
    private cxptrncobroService: CxptrncobroService, private sacimaeconfService: SacimaeconfiguracionService,
    private invtrnajustesService: InvtrnajustesService, private numCService: SacimaenumeracionCService,
    private cxpenccuotaService: CxpenccuotaService, private nonmaeRubService: NommaerubroService,
    private invreservaService: InvtrnreservasService, public nomempleadosService: NomempleadosService,
    private cliService: VenmaeclienteService, public impurentService: NomcabimpurentserviceService,
    public nomtrannovedadservice: NomtrannovedadService, public encordproService: ProdencordproService,
    public costfechservi: ProdcostosfechaService, public nomUtilidadesService: NomutilidadesService,
    public liqservice: NomliqudecitercService, public equipoService: TecmaeequipoService,
    public tecencmantService: TecencmantService, public encordtraService: TecencordtraService,
    private actmaegrupoService: ActmaegrupoService, private actencsalidaService: ActencsalidaService,
    private encptovtaService: VenencptovtaService,private AneximpdocsriService:AneximpdocsriService
    ,private rolProcesoSrv: NomencrolService,
    private cxctrnretencionesService:CxctrnretencionesService, private ComimpdoceleService:ComimpdoceleService,
    private cxctrnabonoService:CxctrnabonoService,
    private cxctrncancelacionService:CxctrncancelacionService, private invtrnajustenegService:InvtrnajustenegService) {
    this.suppressCellSelection = false;
    this.tipodetalleactivo = 'all';
    
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.aggFuncService = this.defaultColDef.objeto;
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.gridApi.aggFuncService] = this.gridApi;
  }
  ngAfterViewInit(){
    if(this.gridContainer){
      console.log('grid',this.gridContainer.nativeElement)
    }
  }
  ngOnInit() {
    this.busqService.refrescarURL();

    this.style = {
      height: 'calc(800px - ' + this.height + 'px)'
      ,width: this.width+'px'
    };

    if (this.margintop === undefined) {
      this.margintop = '50';
    };

    this.styleimg = {
      'position': 'absolute',
      'margin-top': this.margintop + 'px',
      'width': '45%',
      'height': '40%'
    };

    if (this.utilitariosService.getActivacion()) {
      this.deshabilitarbotones = true;
    } else {
      this.deshabilitarbotones = false;
    }

    this.consulta = '';
    this.imagenProducto = '';
  }

  isEditable(bandera: boolean) {
    const col1 = this.columnDefs[0];
    col1.editable = bandera;
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setColumnDefs(this.columnDefs);
  }

  // andres
  isEditable4(bandera: boolean) {
    const col1 = this.columnDefs[0];
    col1.editable = bandera;
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setColumnDefs(this.columnDefs);
  }

  isEditableProG(bandera: boolean) {
    const col2 = this.columnDefs[5];
    col2.editable = bandera;
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setColumnDefs(this.columnDefs);
  }

  isEditableProA(bandera: boolean) {
    const col3 = this.columnDefs[6];
    col3.editable = bandera;
    const col4 = this.columnDefs[9];
    col4.editable = bandera;
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setColumnDefs(this.columnDefs);
  }

  refreshaggrid(objeto, name) {
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + name].setRowData(objeto);
    if (name === 'comnotrecRecepcion') {
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + name].setFocusedCell(objeto.length - 1, this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDef.objeto].columnController.allDisplayedColumns[1].colId);
    } else {
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + name].setFocusedCell(objeto.length - 1, this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDef.objeto].columnController.allDisplayedColumns[0].colId);
    }

    this.visualizarFocus(objeto, name);
  }

  stopediting(indice, objeto) {
    this.init.grids[indice + '_' + objeto].stopEditing();
  }

  bloquearGrid() {
    this.notlistenkey = true;
  }

  habilitarGrid() {
    this.notlistenkey = false;
  }

  visualizarFocus(objeto, name) {
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + name].forEachNode((node) => {
      const h = objeto.length - 1;
      if (Number(node.id) === h) {
        node.setSelected(true);
      }
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + name].ensureIndexVisible(h, 'bottom');
    });
  }

  visualizarArriba(name) {
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + name].forEachNode((node) => {
      const h = 0;
      if (Number(node.id) === h) {
        node.setSelected(true);
      }
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + name].ensureIndexVisible(h, 'middle');
    });
  }

  visualizarAbajo(name, lenght) {
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + name].forEachNode((node) => {
      const h = lenght;
      if (Number(node.id) === h) {
        node.setSelected(true);
      }
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + name].ensureIndexVisible(h, 'middle');
    });
  }

  refreshColumnDefs() {
    if (this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto] !== undefined) {
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setColumnDefs(this.columnDefs);
    }
  }

  refreshColumnDefsIndice(indice, objeto, columnas) {
    if (this.init.grids[indice + '_' + objeto] !== undefined) {
      this.init.grids[indice + '_' + objeto].setColumnDefs(columnas);
    }
  }

  refreshGridData(data) {
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(data);
  }

  refreshColumnDefs2(column) {
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setColumnDefs(column);
  }

  hideColumn(column, bool) {
    this.gridColumnApi.setColumnVisible(column, bool);
  }

  refreshaggridindex(objeto, name, index) {
    ///GSRF
    this.poscidetfac=index;
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + name].setRowData(objeto);
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + name].setFocusedCell(index + 1, this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].columnController.allDisplayedColumns[0].colId);
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + name].forEachNode((node) => {
      const h = index + 1;
      if (Number(node.id) === h) {
        node.setSelected(true);
      }
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + name].ensureIndexVisible(h, 'bottom');
    });
  }
  async refreshaggridindexSNMVSCROLL(objeto, name, index) {
    ///GSRF
    this.poscidetfac=index;
    //console.log(this.gridContainer.nativeElement)
    ///const scrollTop=this.gridContainer.nativeElement;
    //this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + name].setData(objeto);
    //this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + name].setFocusedCell(index + 1, this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].columnController.allDisplayedColumns[0].colId);
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + name].
    forEachNode((node) => {
      const h = this.gridApi.getFocusedCell().rowIndex;
      
      const node2=this.gridApi.getRowNode(node.id)
      
      if (node2.selected) {
        //node.setSelected(true);
        console.log(node)
        console.log(node2.selected)
        console.log('objeto',objeto[Number(node.id)])
        console.log('node2',node2)
        node2.setData(objeto[Number(node.id)])
        this.gridApi.refreshCells({
          rowNodes:[Number(node.id)],
          force:true,
          suppressFlash:false
        })
      }
    //this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + name].ensureColumVisible(this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].columnController.allDisplayedColumns[0].colId,'auto');
    });
    // setTimeout(()=>{
    //   this.restoreScrollPosition()
    // },0)
    //this.gridContainer.nativeElement.scrollTop=scrollTop;
  }

  onCellKeyDown(e) {
    if (this.notlistenkey === true) {
      return;
    }

    this.cambios.emit(true);
    let gridapi: any;
    let maestro: any;
    let coldef: any;

    if (this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].getFocusedCell() === null) {
      return;
    }

    if (this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
      this.defaultColDef.objeto].getFocusedCell().rowIndex !== false) {
      gridapi = this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto];
      maestro = this.rowData;
      coldef = this.columnDefs;
    }

    if (gridapi === undefined) {
      return;
    }

    let auxiliar = 0;
    if (typeof gridapi.getFocusedCell().column.colDef.editable === 'function') {
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].forEachNode((node) => {
        if (node.selected === true) {
          if (gridapi.getFocusedCell().column.colDef.editable(node) === false) {
            auxiliar++;
          }
        }
      });
    }

    if (auxiliar > 0) {
      return;
    }

    if (e.key !== 'Enter' &&
      e.key !== 'ArrowRight' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'ArrowUp' &&
      e.key !== 'ArrowDown' &&
      e.key !== 'Shift' &&
      e.key !== 'Home' &&
      e.key !== 'CapsLock' &&
      e.key !== 'Alt' &&
      e.key !== 'Tab' &&
      e.key !== 'Insert' &&
      e.key !== 'End' &&
      e.key !== 'Backspace' &&
      e.key !== 'PageUp' &&
      e.key !== 'PageDown' &&
      e.key !== 'Control' &&
      e.key !== 'META' &&
      e.key !== 'Meta' &&
      e.key !== 'NUMLOCK' &&
      e.key !== 'F1' &&
      e.key !== 'F2' &&
      e.key !== 'F3' &&
      e.key !== 'F4' &&
      e.key !== 'F5' &&
      e.key !== 'F6' &&
      e.key !== 'F7' &&
      e.key !== 'F8' &&
      e.key !== 'F9' &&
      e.key !== 'F10' &&
      e.key !== 'F11' &&
      e.key !== 'F12' &&
      e.key !== ' ' &&
      e.key !== 'Escape' &&
      e.key !== 'AudioVolumeMute' &&
      e.key !== 'AudioVolumeUp' &&
      e.key !== 'AudioVolumeDown' &&
      e.key !== 'MEDIASTOP' &&
      e.key !== 'MEDIAPLAYPAUSE' &&
      e.key !== 'MEDIATRACKNEXT' &&
      e.key !== 'MEDIATRACKPREVIOUS'
    ) {

      if (this.getParameter(gridapi.getFocusedCell().column.colId, 'numeros', coldef) === false
        && this.getParameter(gridapi.getFocusedCell().column.colId, 'alphabetic', coldef) === false) {
        return;
      }

      if (this.getParameter(gridapi.getFocusedCell().column.colId, 'regexp', coldef) !== undefined) {
        const str = new RegExp(this.getParameter(gridapi.getFocusedCell().column.colId, 'regexp', coldef));
        if (str.test(e.key) === true) {
          maestro[gridapi.getFocusedCell().rowIndex][gridapi.getFocusedCell().column.colId] = e.key;
        } else {
          return;
        }
      }

      if (this.getParameter(gridapi.getFocusedCell().column.colId, 'clave', coldef) !== true) {
        if (this.getParameter(gridapi.getFocusedCell().column.colId, 'numeros', coldef) === true
          && this.getParameter(gridapi.getFocusedCell().column.colId, 'alphabetic', coldef) === false) {
          if (/^\d/.test(e.key)) {
            maestro[gridapi.getFocusedCell().rowIndex][gridapi.getFocusedCell().column.colId] = e.key;
          }
        } else if (this.getParameter(gridapi.getFocusedCell().column.colId, 'mayusculas', coldef) === true) {
          const key = (e.key + '').toUpperCase();
          maestro[gridapi.getFocusedCell().rowIndex][gridapi.getFocusedCell().column.colId] = key;
        } else {
          maestro[gridapi.getFocusedCell().rowIndex][gridapi.getFocusedCell().column.colId] = e.key;
        }
      }
    }

    if (gridapi.getEditingCells()[0] !== undefined) {
      if (gridapi.getEditingCells()[0].column.colId === 'UNI_CODIGO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'RETENCION_CODIGO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'ART_CODIGOP') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'CON_CODIGO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'CEN_CODIGO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'CON_CODIGO2') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'CON_CODIGO_VTA') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'SER_FORMSRICOM') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'REF_CODIGO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'PRO_NOMBRE') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'CENCODIGO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'GRUP_CODIGO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'MAEPRM_ARTSRV') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'MAEPRM_ARTORG') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'MAEDSCTO_ARTSVR') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'CAJ_CODIGO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'SERCOM_TRIBUTAIVA') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'FORMAPAGO_CODIGOSRI') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'SER_FORMSRIVTAS') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'CLI_CODIGO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'ART_CODIGO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'DETPED_CODIGO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'DETGRE_CODIGO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'DETORDCOM_CODIGO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'DETNOTREC_CODIGO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'DETDVF_CODIGO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'DETNOTREC_CANTIDAD') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'DETDEV_CODIGO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'CodigoCon') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'TRNCAJ_TIPO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'TRNPAGO_CODSRI') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'BANCLI_CODIGO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'TRNPAGO_NUMERO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId.trim() === 'BANCLI_CODIGO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId.trim() === 'UNI_CODIGODESTINO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'PROG_CODIGO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'CON_CODIGO_2') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'CON_CODIGO_3') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'CON_CODIGO_4') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'CON_CODIGO_5') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'CON_CODIGO_6') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'CON_CODIGO_7') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'CON_CODIGOHABE') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'CON_CODIGOHABE_2') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'CON_CODIGOHABE_3') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'CON_CODIGOHABE_4') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'CON_CODIGOHABE_5') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'CON_CODIGOHABE_6') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'CON_CODIGOHABE_7') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'NUM_DOCREF') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'BOD_CODIGODES') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'BANCODIGO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'MAERUBRO_CODIGO2') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'EMPCARGFAMI_TIPOIDE') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'EMPCARGFAMI_SEXO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'EMPCARGFAMI_TIPORELA2') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'EMPCARGFAMI_ESTATUS') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'TRANVACAEMPL_TIPO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'TRANVACAEMPL_ESTADO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'EMP_CODIGO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'MAERUBRO_CODRUBR') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'TRNCOMQUI_CODIGO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'TRNAPLI_CODIGO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'CIN_CODIGO') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'CIN_CODIGO_1') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'NOM_TIPOPAGOUTIL') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'NOM_TIPOPAGO_SALDIG') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'NOM_FORMPAGOCOD') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'NOM_BANCPAGOCOD') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'NOM_TIPOCUENCOD') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'DETLIQUBENESOCI_FORMPAGODESC') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'DETLIQUBENESOCI_BANCPAGODESC') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'DETLIQUBENESOCI_TIPOCUENDESC') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'ACT_CODIGO') {
        this.init.auxsalto = false;
      }
      //CDPJ
      if(gridapi.getEditingCells()[0].column.colId === 'CONCODIGO'){
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'SERCOM_PORCEIVA') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'DETORDCOM_PORIVA') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'DETNOTREC_PORIVA') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'DETFACPRO_PORIVA') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'DETDEV_PORIVA') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'DETPED_PORIVA') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'DETGRE_PORIVA') {
        this.init.auxsalto = false;
      }
      if (gridapi.getEditingCells()[0].column.colId === 'DETFAC_PORIVA') {
        this.init.auxsalto = false;
      }
      //CDPJ
    }


    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'DETFACPRO_TIPOSRI' && this.init.auxsalto === false) {
      this.busquedaSRI(maestro[gridapi.getFocusedCell().rowIndex], 'DETFACPRO_TIPOSRI');
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'DETFAC_TIPOSRI' && this.init.auxsalto === false) {
      if(gridapi.aggFuncService === 'vendetdvf'){
        this.busquedaSRI(maestro[gridapi.getFocusedCell().rowIndex], 'DETDVF_TIPOSRI');
      }else{
        this.busquedaSRI(maestro[gridapi.getFocusedCell().rowIndex], 'DETFAC_TIPOSRI');
      }
     
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'UNI_CODIGO' && this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'maeartprecio') {
        this.busquedaunidad(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaunidadprecio');
      }
      if (gridapi.aggFuncService === 'maeartparte') {
        this.busquedaunidad(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaunidadparte');
      }
      if (gridapi.aggFuncService === 'maeartformula') {
        //this.busquedaunidad(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaunidadformula');
        //CDPJ
        if (maestro[gridapi.getFocusedCell().rowIndex].ART_MULTIUNIDAD === 'S'){
          this.busquedaunidad(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaunidadformula');
        }
        //CDPJ
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'RETENCION_CODIGO') {
      if (gridapi.aggFuncService === 'commaeproveedorRt') {
        this.busquedanombreRetencion(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaNombre', maestro[gridapi.getFocusedCell().rowIndex].RETENCION_CODIGO);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'TRNPAGO_NUMERO' && this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'cxcformapago') {
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO === 'ANCL') {
          this.busqService.encontrarRegistro1(maestro[gridapi.getFocusedCell().rowIndex].CLI_CODIGO).subscribe(eR => {
            let intAnticipos = 0;
            if (eR !== undefined) {
              if (eR[0] !== null) {
                intAnticipos = Number(eR[0].COUN);
              }
            }
            if (intAnticipos !== 0) {
              this.busquedaAnticiposCli(maestro[gridapi.getFocusedCell().rowIndex], 'cxcformapagoANC', maestro[gridapi.getFocusedCell().rowIndex].CLI_CODIGO);
            } else {
              this.messageService.add({
                key: 'cartera',
                severity: 'warn',
                summary: 'Información',
                detail: 'No existen anticipos pendientes.'
              });
            }
          });
        }
      }
      //CDPJ
      if (gridapi.aggFuncService === 'cxctrnabonoformapago') {
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO === 'ANCL') {
          this.busqService.encontrarRegistro1(maestro[gridapi.getFocusedCell().rowIndex].CLI_CODIGO).subscribe(eR => {
            let intAnticipos = 0;
            if (eR !== undefined) {
              if (eR[0] !== null) {
                intAnticipos = Number(eR[0].COUN);
              }
            }
            if (intAnticipos !== 0) {
              this.busquedaAnticiposCli(maestro[gridapi.getFocusedCell().rowIndex], 'cxctrnabonoformapagoANC', maestro[gridapi.getFocusedCell().rowIndex].CLI_CODIGO);
            } else {
              this.messageService.add({
                key: 'cxctrnabono',
                severity: 'warn',
                summary: 'Información',
                detail: 'No existen anticipos pendientes.'
              });
            }
          });
        }
      }
      if (gridapi.aggFuncService === 'cxctrncancelacionformapago') {
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO === 'ANCL') {
          this.busqService.encontrarRegistro1(maestro[gridapi.getFocusedCell().rowIndex].CLI_CODIGO).subscribe(eR => {
            let intAnticipos = 0;
            if (eR !== undefined) {
              if (eR[0] !== null) {
                intAnticipos = Number(eR[0].COUN);
              }
            }
            if (intAnticipos !== 0) {
              this.busquedaAnticiposCli(maestro[gridapi.getFocusedCell().rowIndex], 'cxctrncancelacionformapagoANC', maestro[gridapi.getFocusedCell().rowIndex].CLI_CODIGO);
            } else {
              this.messageService.add({
                key: 'cxctrncancelacion',
                severity: 'warn',
                summary: 'Información',
                detail: 'No existen anticipos pendientes.'
              });
            }
          });
        }
      }
      // if (gridapi.aggFuncService === 'cxctrnanticipoformapago') {
      //   if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO === 'ANCL') {
      //     this.busqService.encontrarRegistro1(maestro[gridapi.getFocusedCell().rowIndex].CLI_CODIGO).subscribe(eR => {
      //       let intAnticipos = 0;
      //       if (eR !== undefined) {
      //         if (eR[0] !== null) {
      //           intAnticipos = Number(eR[0].COUN);
      //         }
      //       }
      //       if (intAnticipos !== 0) {
      //         this.busquedaAnticiposCli(maestro[gridapi.getFocusedCell().rowIndex], 'cxctrnanticipoformapagoANC', maestro[gridapi.getFocusedCell().rowIndex].CLI_CODIGO);
      //       } else {
      //         this.messageService.add({
      //           key: 'cxctrnanticipos',
      //           severity: 'warn',
      //           summary: 'Información',
      //           detail: 'No existen anticipos pendientes.'
      //         });
      //       }
      //     });
      //   }
      // }
      //CDPJ
      if (gridapi.aggFuncService === 'pagcon') {
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO === 'ANCL') {
          this.busqService.encontrarRegistro1(this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO).subscribe(eR => {
            let intAnticipos = 0;
            if (eR !== undefined) {
              if (eR[0] !== null) {
                intAnticipos = Number(eR[0].COUN);
              }
            }
            if (intAnticipos !== 0) {
              this.busquedaAnticiposCli(maestro[gridapi.getFocusedCell().rowIndex], 'pagconFormaPagoAnc', this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO);
            } else {
              this.messageService.add({
                key: 'pagcon',
                severity: 'warn',
                summary: 'Información',
                detail: 'No existen anticipos pendientes.'
              });
            }
          });
        }
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'TRNCAJ_TIPO' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'cxcformapagodev') {
        this.busquedatipopago(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaTipoCaj', maestro[gridapi.getFocusedCell().rowIndex].TRNCAJ_TIPO);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'BAN_CODIGO') {
      if (gridapi.aggFuncService === 'cxcformapagodev') {
        this.busquedaBanco(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaBancoFP', maestro[gridapi.getFocusedCell().rowIndex].BAN_CODIGO);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'COMISION_CODIGO') {
      if (gridapi.aggFuncService === 'cxcotrosgastos') {
        this.busquedacomision(maestro[gridapi.getFocusedCell().rowIndex], 'comisionCod', maestro[gridapi.getFocusedCell().rowIndex].COMISION_CODIGO);
      }

    }
     //CDPJ
     if (e.key === 'Enter' &&
     gridapi.getFocusedCell().column.colId === 'COMISION_CODIGO') {
     if (gridapi.aggFuncService === 'cxctrnabonootrosgastos') {
       this.busquedacomision(maestro[gridapi.getFocusedCell().rowIndex], 'cxctrnabonootrosgastos', maestro[gridapi.getFocusedCell().rowIndex].COMISION_CODIGO);
     }
     
   }
   if (e.key === 'Enter' &&
     gridapi.getFocusedCell().column.colId === 'COMISION_CODIGO') {
     if (gridapi.aggFuncService === 'cxctrncancelacionotrosgastos') {
       this.busquedacomision(maestro[gridapi.getFocusedCell().rowIndex], 'cxctrncancelacionotrosgastos', maestro[gridapi.getFocusedCell().rowIndex].COMISION_CODIGO);
     }
     
   }
   //CDPJ

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'TRNFORMAPAGO_CODIGO') {
      if (gridapi.aggFuncService === 'commaeproveedorFp') {
        this.busquedaFormaPago(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaNombresri', maestro[gridapi.getFocusedCell().rowIndex].TRNFORMAPAGO_CODIGO);
      }
      if (gridapi.aggFuncService === 'datosanexos') {
        this.busquedaFormaPago(maestro[gridapi.getFocusedCell().rowIndex], 'busquedafpdatoanexos', maestro[gridapi.getFocusedCell().rowIndex].TRNFORMAPAGO_CODIGO);
      }
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CLI_CODIGO' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'venmaepry') {
        this.busquedaCliente(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaCodCli');
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'TAR_CODSRI') {
      this.busquedaTar(maestro[gridapi.getFocusedCell().rowIndex], 'TAR_CODSRI',
        maestro[gridapi.getFocusedCell().rowIndex].TAR_CODSRI);
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CAJ_CODIGO' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'formaPago') {
        this.busquedaCaja(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaCaja', maestro[gridapi.getFocusedCell().rowIndex].CAJ_CODIGO);//CDPJ
      }
      if (gridapi.aggFuncService === 'pagcon') {
        this.busquedaCaja(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaCajaPago', maestro[gridapi.getFocusedCell().rowIndex].CAJ_CODIGO);//CDPJ
      }
      if (gridapi.aggFuncService === 'pagcondvf') {
        this.busquedaCaja(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaCajaPagodvf', maestro[gridapi.getFocusedCell().rowIndex].CAJ_CODIGO);//CDPJ
      }
      if (gridapi.aggFuncService === 'cxcpagoretformapago') {
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO.trim() === 'EF') {
          this.busquedaCaja(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaCajaCXCpagoret', maestro[gridapi.getFocusedCell().rowIndex].CAJ_CODIGO);//CDPJ
        }
      }
      if (gridapi.aggFuncService === 'cxcformapago') {
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO.trim() === 'EF' || maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO.trim() === 'CH'
          || maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO.trim() === 'ANCL' || maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO.trim() === 'TA') {
          this.busquedaCaja(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaCajaformapago', maestro[gridapi.getFocusedCell().rowIndex].CAJ_CODIGO);//CDPJ
        }
      }
      //CDPJ
      if (gridapi.aggFuncService === 'cxctrnabonoformapago') {
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO.trim() === 'EF' || maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO.trim() === 'CH'
          || maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO.trim() === 'ANCL' || maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO.trim() === 'TA') {
          this.busquedaCaja(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaCajaTrnabonoformapago', maestro[gridapi.getFocusedCell().rowIndex].CAJ_CODIGO);//CDPJ
        }
      }
      if (gridapi.aggFuncService === 'cxctrncancelacionformapago') {
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO.trim() === 'EF' || maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO.trim() === 'CH'
          || maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO.trim() === 'ANCL' || maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO.trim() === 'TA') {
          this.busquedaCaja(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaCajaTrncancelacionformapago', maestro[gridapi.getFocusedCell().rowIndex].CAJ_CODIGO);//CDPJ
        }
      }
      // if (gridapi.aggFuncService === 'cxctrnanticipoformapago') {
      //   if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO.trim() === 'EF' || maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO.trim() === 'CH'
      //     || maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO.trim() === 'ANCL' || maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO.trim() === 'TA') {
      //     this.busquedaCaja(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaCajaTrnanticipoformapago', maestro[gridapi.getFocusedCell().rowIndex].CAJ_CODIGO);//CDPJ
      //   }
      // }
      //CDPJ
      if (gridapi.aggFuncService === 'cxcformapagodev') {
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNCAJ_TIPO.trim() === 'EF' || maestro[gridapi.getFocusedCell().rowIndex].TRNCAJ_TIPO.trim() === 'CH') {
          this.busquedaCaja(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaCajaformapagodev', maestro[gridapi.getFocusedCell().rowIndex].CAJ_CODIGO);//CDPJ
        }
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === '' &&
      this.init.auxsalto === false) {

    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'ART_CODIGOP' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'maeartparte') {
        this.busquedaarticulodetalle(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaarticuloparte', maestro[gridapi.getFocusedCell().rowIndex].ART_CODIGOP);
      }
      if (gridapi.aggFuncService === 'maeartformula') {
        this.busquedaarticulodetalle(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaarticuloformula', maestro[gridapi.getFocusedCell().rowIndex].ART_CODIGOP);
      }
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'BOD_CODIGO' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'maeexistbod') {
        this.busquedabodega(maestro[gridapi.getFocusedCell().rowIndex], 'busquedabodega');
      }
      if (gridapi.aggFuncService === 'maetomafisica') {
        this.busquedabodega(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaToma');
      }
      if (gridapi.aggFuncService === 'maesalida') {
        this.busquedabodega(maestro[gridapi.getFocusedCell().rowIndex], 'busquedabodegasal');
      }
      if (gridapi.aggFuncService === 'maeentrada') {
        this.busquedabodega(maestro[gridapi.getFocusedCell().rowIndex], 'busquedabodegaent');
      }
      if (gridapi.aggFuncService === 'detfac') {
        this.busquedabodega(maestro[gridapi.getFocusedCell().rowIndex], 'busquedabodegadetfac');
      }
      if (gridapi.aggFuncService === 'detfacpv') {
        this.busquedabodega(maestro[gridapi.getFocusedCell().rowIndex], 'busquedabodegadetfacpv');
      }
      if (gridapi.aggFuncService === 'vendetped') {
        this.busquedabodega(maestro[gridapi.getFocusedCell().rowIndex], 'busquedabodegaped');
      }
      if (gridapi.aggFuncService === 'detfacpro') {
        this.busquedabodega(maestro[gridapi.getFocusedCell().rowIndex], 'busquedabodegadetfacpro');
      }
      if (gridapi.aggFuncService === 'invtrnajustes') {
        this.busquedabodega(maestro[gridapi.getFocusedCell().rowIndex], 'BOD_CODIGOAJUSTE');
      }
      if (gridapi.aggFuncService === 'vendetguia') {
        this.busquedabodega(maestro[gridapi.getFocusedCell().rowIndex], 'BOD_CODIGOGUIAREM');
      }
      if (gridapi.aggFuncService === 'detalleordentrabajo') {
        this.busquedabodega(maestro[gridapi.getFocusedCell().rowIndex], 'bodordentrabajo');
      }
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'BOD_CODIGODES' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'invtrnreservas') {
        this.busquedabodegaReserva(maestro[gridapi.getFocusedCell().rowIndex], 'BOD_CODIGORESERVA');
      }
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'BOD_CODIGO1' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'venmaepry') {
        this.busquedabodega(maestro[gridapi.getFocusedCell().rowIndex], 'bod_codigo1');
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'BOD_CODIGO2' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'venmaepry') {
        this.busquedabodega(maestro[gridapi.getFocusedCell().rowIndex], 'bod_codigo2');
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'BOD_CODIGO3' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'venmaepry') {
        this.busquedabodega(maestro[gridapi.getFocusedCell().rowIndex], 'bod_codigo3');
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'BOD_CODIGO4' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'venmaepry') {
        this.busquedabodega(maestro[gridapi.getFocusedCell().rowIndex], 'bod_codigo4');
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'BOD_CODIGO5' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'venmaepry') {
        this.busquedabodega(maestro[gridapi.getFocusedCell().rowIndex], 'bod_codigo5');
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'BOD_CODIGO6' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'venmaepry') {
        this.busquedabodega(maestro[gridapi.getFocusedCell().rowIndex], 'bod_codigo6');
      }
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'NUM_DOCREF' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'maeNumeracionC') {
        this.busquedaSRI(maestro[gridapi.getFocusedCell().rowIndex], 'NUM_DOCREFBUS');
      }
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CIN_CODIGO' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'maecostoest') {
        this.busquedacostosind(maestro[gridapi.getFocusedCell().rowIndex], 'busquedacostosind');
      }
      if (gridapi.aggFuncService === 'trncostoind') {
        this.busquedacostosind(maestro[gridapi.getFocusedCell().rowIndex], 'busquedacostosindprod');
      }
      if (gridapi.aggFuncService === 'trnhorashombre') {
        this.busquedacostosind(maestro[gridapi.getFocusedCell().rowIndex], 'busquedacihorashombre');
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'SER_FORMSRICOM' && this.init.auxsalto === false) {
      this.busquedaSRI(maestro[gridapi.getFocusedCell().rowIndex], 'SRI_BUS');
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'REF_CODIGO' && this.init.auxsalto === false) {
      this.busquedaSRI(maestro[gridapi.getFocusedCell().rowIndex], 'REF_CODIGOIMP');
    }
    //GSRF
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'PRO_NOMBRE') {
      console.log('aqui enter')   
      this.busquedaprove(maestro[gridapi.getFocusedCell().rowIndex], 'PRO_CODIGONOM');  
    }
    //GSRF
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'SER_FORMSRIVTAS' && this.serviService.auxsalto === false) {
      this.busquedaSRI(maestro[gridapi.getFocusedCell().rowIndex], 'SRI_BUSVEN');
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CEN_CODIGO' && this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'maebodega') {

        this.busquedaCEN(maestro[gridapi.getFocusedCell().rowIndex], 'CEN');

      }
      if (gridapi.aggFuncService === 'maetomafisicavar') {
        if (maestro[gridapi.getFocusedCell().rowIndex].CON_CENTRO === 'Si') {
          this.busquedaCentroCostos(maestro[gridapi.getFocusedCell().rowIndex], 'TOM_CEN', maestro[gridapi.getFocusedCell().rowIndex].CEN_CODIGO);
        }
      }
      if (gridapi.aggFuncService === 'maesalidavar') {
        this.busquedaCEN(maestro[gridapi.getFocusedCell().rowIndex], 'SAL_CEN');
      }
      if (gridapi.aggFuncService === 'maeentradavar') {
        this.busquedaCEN(maestro[gridapi.getFocusedCell().rowIndex], 'ENT_CEN');
      }
      //CDPJ
      if (gridapi.aggFuncService === 'maesalida') {
        this.busquedaCEN(maestro[gridapi.getFocusedCell().rowIndex], 'SAL_CENI');
      }
      if (gridapi.aggFuncService === 'maeentrada') {
        this.busquedaCEN(maestro[gridapi.getFocusedCell().rowIndex], 'ENT_CENI');
      }
      if (gridapi.aggFuncService === 'comnotrecVarios') {
        console.log(maestro[gridapi.getFocusedCell().rowIndex].CON_CENTRO)
        if(maestro[gridapi.getFocusedCell().rowIndex].CON_CENTRO === 'Si'){
          this.busquedaCEN(maestro[gridapi.getFocusedCell().rowIndex], 'CEN_NOTREC');
        }
      }
      if (gridapi.aggFuncService === 'vendetped') {
        //console.log(maestro[gridapi.getFocusedCell().rowIndex].CON_CENTRO)
        //if(maestro[gridapi.getFocusedCell().rowIndex].CON_CENTRO === 'Si'){
          this.busquedaCEN(maestro[gridapi.getFocusedCell().rowIndex], 'CEN_PED');
        //}
      }
      //CDPJ
      if (gridapi.aggFuncService === 'commaeservicios') {
        this.busquedaCEN(maestro[gridapi.getFocusedCell().rowIndex], 'CEN_SERVICIO');
      }
      if (gridapi.aggFuncService === 'maeBanCaja') {
        if (maestro[gridapi.getFocusedCell().rowIndex].CON_CENTRO === 'Si') {
          this.busquedaCEN(maestro[gridapi.getFocusedCell().rowIndex], 'CEN_BANCAJA');
        }
      }

      if (gridapi.aggFuncService === 'maeservicios') {
        this.busquedaCEN(maestro[gridapi.getFocusedCell().rowIndex], 'CEN_VENSERVICIO');
      }
      if (gridapi.aggFuncService === 'maecargos') {
        if (maestro[gridapi.getFocusedCell().rowIndex].CON_CENTRO === 'Si') {
          this.busquedaCEN(maestro[gridapi.getFocusedCell().rowIndex], 'CEN_CARGO');
        }
      }
      if (gridapi.aggFuncService === 'districc') {
        this.busquedaCEN(maestro[gridapi.getFocusedCell().rowIndex], 'CEN_CODIGODISTRI');
      }
      if (gridapi.aggFuncService === 'cxppagovarios') {
        if (maestro[gridapi.getFocusedCell().rowIndex].CON_CENTRO === 'Si') {
          this.busquedaCentroCostos(maestro[gridapi.getFocusedCell().rowIndex], 'CEN_CODIGOCXPVARIOS', maestro[gridapi.getFocusedCell().rowIndex].CEN_CODIGO);
        }
      }
      if (gridapi.aggFuncService === 'conencasil') {
        if (maestro[gridapi.getFocusedCell().rowIndex].CON_CENTRO === 'Si') {
          this.busquedaCentroCostos(maestro[gridapi.getFocusedCell().rowIndex], 'CEN_CONENCASIL', maestro[gridapi.getFocusedCell().rowIndex].CEN_CODIGO);
        }
      }
      if (gridapi.aggFuncService === 'banTrnBanVar') {
        if (maestro[gridapi.getFocusedCell().rowIndex].CON_CENTRO === 'Si') {
          this.busquedaCentroCostos(maestro[gridapi.getFocusedCell().rowIndex], 'CEN_BANVARIOS', maestro[gridapi.getFocusedCell().rowIndex].CEN_CODIGO);
        }
      }
      if (gridapi.aggFuncService === 'contrnDistribcc') {
        this.busquedaCentroCostos(maestro[gridapi.getFocusedCell().rowIndex], 'CEN_CONDISTRIBCC', maestro[gridapi.getFocusedCell().rowIndex].CEN_CODIGO);
      }
      if (gridapi.aggFuncService === 'bantarVarios') {
        if (maestro[gridapi.getFocusedCell().rowIndex].CON_CENTRO === 'Si') {
          this.busquedaCentroCostos(maestro[gridapi.getFocusedCell().rowIndex], 'CEN_CODIGOTRNTARJETA', maestro[gridapi.getFocusedCell().rowIndex].CEN_CODIGO);
        }
      }
      if (gridapi.aggFuncService === 'bancajatrnVarios') {
        if (maestro[gridapi.getFocusedCell().rowIndex].CON_CENTRO === 'Si') {
          this.busquedaCentroCostos(maestro[gridapi.getFocusedCell().rowIndex], 'CEN_CODIGOBANCAJA', maestro[gridapi.getFocusedCell().rowIndex].CEN_CODIGO);
        }
      }
      if (gridapi.aggFuncService === 'cxpvarios') {
        if (maestro[gridapi.getFocusedCell().rowIndex].CON_CENTRO === 'Si') {
          this.busquedaCentroCostos(maestro[gridapi.getFocusedCell().rowIndex],
            'CENCODIGOcxpvarios', maestro[gridapi.getFocusedCell().rowIndex].CENCODIGO);
        }
      }
      if (gridapi.aggFuncService === 'invtrnajustes') {
        if (maestro[gridapi.getFocusedCell().rowIndex].CON_CENTRO === 'Si') {
          this.busquedaCentroCostos(maestro[gridapi.getFocusedCell().rowIndex],
            'CENCODIGO_AJUSTES', maestro[gridapi.getFocusedCell().rowIndex].CEN_CODIGO);
        }
      }
      if (gridapi.aggFuncService === 'cxpcuotavar') {
        if (maestro[gridapi.getFocusedCell().rowIndex].CON_CENTRO === 'Si') {
          this.busquedaCentroCostos(maestro[gridapi.getFocusedCell().rowIndex],
            'CENCODIGO_CXPCUOTA', maestro[gridapi.getFocusedCell().rowIndex].CEN_CODIGO);
        }
      }

      if (gridapi.aggFuncService === 'detfacpro') {

        this.busquedaCEN(maestro[gridapi.getFocusedCell().rowIndex], 'FACPRO_CEN');

      }
        //CDPJ
        if(gridapi.aggFuncService=== 'comdetdev'){
          this.busquedaCEN(maestro[gridapi.getFocusedCell().rowIndex], 'FACPRODEV_CEN');
        }
        //CDPJ
      if (gridapi.aggFuncService === 'detfac') {
        this.busquedaCEN(maestro[gridapi.getFocusedCell().rowIndex], 'DETFAC_CENCOD');
        console.log(maestro[gridapi.getFocusedCell().rowIndex]);
      }
        //cdpJ
        if (gridapi.aggFuncService === 'vendetguia') {
          //this.busquedaCEN(maestro[gridapi.getFocusedCell().rowIndex], 'CEN_CODIGO');
          //this.busquedaCEN(maestro[gridapi.getFocusedCell().rowIndex], 'CEN_GUIA');        
          //GSRF  
            console.log('+++++++ cen_codigo', maestro[gridapi.getFocusedCell().rowIndex].CEN_CODIGO)
            if(maestro[gridapi.getFocusedCell().rowIndex].CEN_CODIGO === undefined 
            || maestro[gridapi.getFocusedCell().rowIndex].CEN_CODIGO === null){
              maestro[gridapi.getFocusedCell().rowIndex].CEN_CODIGO = '';
            }
            console.log('+++++++ cen_codigo2', maestro[gridapi.getFocusedCell().rowIndex].CEN_CODIGO)
          //GSRF
          this.busquedaCentroCostos(maestro[gridapi.getFocusedCell().rowIndex], 'CEN_GUIA', maestro[gridapi.getFocusedCell().rowIndex].CEN_CODIGO);
        }
        //CDPJ
      if (gridapi.aggFuncService === 'nomliqdecetervar') {
        this.busquedaCCVarios(maestro[gridapi.getFocusedCell().rowIndex],
          'CENCODIGO_LIQ_SER', maestro[gridapi.getFocusedCell().rowIndex].CEN_CODIGO);

      }
      //CDPJ
      if (gridapi.aggFuncService === 'cxcvarios') {
        if(maestro[gridapi.getFocusedCell().rowIndex].CON_CENTRO === 'Si'){
          this.busquedaCEN(maestro[gridapi.getFocusedCell().rowIndex], 'CXCVAR_CENCOD');
         
        }
       
      }
      if (gridapi.aggFuncService === 'actdetvarsal') {
        if(maestro[gridapi.getFocusedCell().rowIndex].CON_CENTRO === 'Si'){
          this.busquedaCEN(maestro[gridapi.getFocusedCell().rowIndex], 'CEN_CODIGO_ACTSALIDA');
         
        }
        
      }
      //CDPJ
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CodigoCon' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'anexmaecodsri') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'ANEX_CON_CODIGO',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CONCODIGO' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'cxpvarios') {
        this.cxptrncobroService.spin=true;//CDPJ
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CONCODIGOcxpvarios',
          maestro[gridapi.getFocusedCell().rowIndex].CONCODIGO);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CENCODIGO') {
        console.log('1---')
        if (gridapi.aggFuncService === 'cxpvarios') {
          console.log('2---')
          // if (maestro[gridapi.getFocusedCell().rowIndex].CON_CENTRO === 'Si') {
          //   console.log('3<---')
            this.busquedaCentroCostos(maestro[gridapi.getFocusedCell().rowIndex],
              'CENCODIGOcxpvarios', maestro[gridapi.getFocusedCell().rowIndex].CENCODIGO);
          //}
        }
        //CDPJ    
    }
      //CDPJ
      if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'SERCOM_PORCEIVA' && this.init.auxsalto === false) { 
        if(maestro[gridapi.getFocusedCell().rowIndex].SERCOM_PORCEIVA === null || maestro[gridapi.getFocusedCell().rowIndex].SERCOM_PORCEIVA === undefined){
          maestro[gridapi.getFocusedCell().rowIndex].SERCOM_PORCEIVA='';
        }     
        // if (gridapi.aggFuncService === 'maeservicios') {
        //     this.busquedaTariivacodigo(maestro[gridapi.getFocusedCell().rowIndex],'tarivacodiomaeser', maestro[gridapi.getFocusedCell().rowIndex].TARIIVA_CODIGO);
        
        //   }
        if (gridapi.aggFuncService === 'commaeservicios') {
          if(maestro[gridapi.getFocusedCell().rowIndex].SERCOM_TRIBUTAIVA ==='S'){
            this.busquedaTariivacodigoS(maestro[gridapi.getFocusedCell().rowIndex],'tarivacodiocommaeserS', maestro[gridapi.getFocusedCell().rowIndex].SERCOM_PORCEIVA);
          }
          if(maestro[gridapi.getFocusedCell().rowIndex].SERCOM_TRIBUTAIVA ==='N'){
            this.busquedaTariivacodigoN(maestro[gridapi.getFocusedCell().rowIndex],'tarivacodiocommaeserN', maestro[gridapi.getFocusedCell().rowIndex].SERCOM_PORCEIVA);
          }
      }
      }
      //VENTAS 
      if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'SER_PORCEIVA' && this.init.auxsalto === false) { 
        if(maestro[gridapi.getFocusedCell().rowIndex].SER_PORCEIVA === null || maestro[gridapi.getFocusedCell().rowIndex].SER_PORCEIVA === undefined){
          maestro[gridapi.getFocusedCell().rowIndex].SER_PORCEIVA='';
        }     
        // if (gridapi.aggFuncService === 'maeservicios') {
        //     this.busquedaTariivacodigo(maestro[gridapi.getFocusedCell().rowIndex],'tarivacodiomaeser', maestro[gridapi.getFocusedCell().rowIndex].TARIIVA_CODIGO);
        
        //   }
        if (gridapi.aggFuncService === 'maeservicios') {
          if(maestro[gridapi.getFocusedCell().rowIndex].SER_TRIBUTAIVA ==='S'){
            this.busquedaTariivacodigoS(maestro[gridapi.getFocusedCell().rowIndex],'tarivacodiovenmaeserS', maestro[gridapi.getFocusedCell().rowIndex].SER_PORCEIVA);
          }
          if(maestro[gridapi.getFocusedCell().rowIndex].SER_TRIBUTAIVA ==='N'){
            this.busquedaTariivacodigoN(maestro[gridapi.getFocusedCell().rowIndex],'tarivacodiovenmaeserN', maestro[gridapi.getFocusedCell().rowIndex].SER_PORCEIVA);
          }
      }
      }
      ////TRANSACICONALES 
      if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'DETORDCOM_PORIVA' && this.init.auxsalto === false) { 
        if(maestro[gridapi.getFocusedCell().rowIndex].DETORDCOM_PORIVA === null || maestro[gridapi.getFocusedCell().rowIndex].DETORDCOM_PORIVA === undefined){
          maestro[gridapi.getFocusedCell().rowIndex].DETORDCOM_PORIVA='';
        }     
        if (gridapi.aggFuncService === 'comdetordcom') {
          if(maestro[gridapi.getFocusedCell().rowIndex].DETORDCOM_TIPODET !=='*'){
            if(maestro[gridapi.getFocusedCell().rowIndex].DETORDCOM_TRIBIVA ==='S'){
              this.busquedaTariivacodigoS(maestro[gridapi.getFocusedCell().rowIndex],'detordcomtarivacodS', maestro[gridapi.getFocusedCell().rowIndex].DETORDCOM_PORIVA);
            }
            if(maestro[gridapi.getFocusedCell().rowIndex].DETORDCOM_TRIBIVA ==='N'){
              this.busquedaTariivacodigoN(maestro[gridapi.getFocusedCell().rowIndex],'detordcomtarivacodN', maestro[gridapi.getFocusedCell().rowIndex].DETORDCOM_PORIVA);
            }
            //this.busquedaTariivacodigo(maestro[gridapi.getFocusedCell().rowIndex],'detordcomtarivacod', maestro[gridapi.getFocusedCell().rowIndex].DETORDCOM_PORIVA);
          }
          
        }
      }
      if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'DETNOTREC_PORIVA' && this.init.auxsalto === false) { 
        console.log(maestro[gridapi.getFocusedCell().rowIndex].DETNOTREC_PORIVA)
        if(maestro[gridapi.getFocusedCell().rowIndex].DETNOTREC_PORIVA === null || maestro[gridapi.getFocusedCell().rowIndex].DETNOTREC_PORIVA === undefined){
          maestro[gridapi.getFocusedCell().rowIndex].DETNOTREC_PORIVA='';
        }     
        if (gridapi.aggFuncService === 'comnotrecRecepcion') {
          if(maestro[gridapi.getFocusedCell().rowIndex].DETNOTREC_TIPODET !=='*'){
            if(maestro[gridapi.getFocusedCell().rowIndex].DETNOTREC_TRIBIVA ==='S'){
              this.busquedaTariivacodigoS(maestro[gridapi.getFocusedCell().rowIndex],'detnotrectarivacodS', maestro[gridapi.getFocusedCell().rowIndex].DETNOTREC_PORIVA);
            }
            if(maestro[gridapi.getFocusedCell().rowIndex].DETNOTREC_TRIBIVA ==='N'){
              this.busquedaTariivacodigoN(maestro[gridapi.getFocusedCell().rowIndex],'detnotrectarivacodN', maestro[gridapi.getFocusedCell().rowIndex].DETNOTREC_PORIVA);
            }
            //this.busquedaTariivacodigo(maestro[gridapi.getFocusedCell().rowIndex],'detordcomtarivacod', maestro[gridapi.getFocusedCell().rowIndex].DETORDCOM_PORIVA);
          }
          
        }
      }
      if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'DETFACPRO_PORIVA' ) { 
        console.log(Number(maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_PORIVA));
        console.log(maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_PORIVA)
        if(maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_PORIVA === null || maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_PORIVA === undefined){
          maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_PORIVA='';
        }     
        if (gridapi.aggFuncService === 'detfacpro') {
          if(maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_TIPODET !=='*'){
            // if(Number(maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_PORIVA) !== 0){
            //   this.busquedaTariivacodigoS(maestro[gridapi.getFocusedCell().rowIndex],'detfacprotarivacodS', Number(maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_PORIVA));
            // }
            // if(Number(maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_PORIVA) === 0){
            //   this.busquedaTariivacodigoN(maestro[gridapi.getFocusedCell().rowIndex],'detfacprotarivacodN',Number(maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_PORIVA));
            // }
            this.busquedaTariivacodigo(maestro[gridapi.getFocusedCell().rowIndex],'detfacprotarivacod', maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_PORIVA);
          }
          
        }
      }

      if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'DETDEV_PORIVA' ) { 
        console.log(Number(maestro[gridapi.getFocusedCell().rowIndex].DETDEV_PORIVA));
        console.log(maestro[gridapi.getFocusedCell().rowIndex].DETDEV_PORIVA)
        if(maestro[gridapi.getFocusedCell().rowIndex].DETDEV_PORIVA === null || maestro[gridapi.getFocusedCell().rowIndex].DETDEV_PORIVA === undefined){
          maestro[gridapi.getFocusedCell().rowIndex].DETDEV_PORIVA='';
        }     
        if (gridapi.aggFuncService === 'comdetdev') {
          if(maestro[gridapi.getFocusedCell().rowIndex].DETDEV_TIPODET !=='*'){
            // if(Number(maestro[gridapi.getFocusedCell().rowIndex].DETDEV_PORIVA) !== 0){
            //   this.busquedaTariivacodigoS(maestro[gridapi.getFocusedCell().rowIndex],'detdevtarivacodS', Number(maestro[gridapi.getFocusedCell().rowIndex].DETDEV_PORIVA));
            // }
            // if(Number(maestro[gridapi.getFocusedCell().rowIndex].DETDEV_PORIVA) === 0){
            //   this.busquedaTariivacodigoN(maestro[gridapi.getFocusedCell().rowIndex],'detdevtarivacodN',Number(maestro[gridapi.getFocusedCell().rowIndex].DETDEV_PORIVA));
            // }
            this.busquedaTariivacodigo(maestro[gridapi.getFocusedCell().rowIndex],'detdevtarivacod', maestro[gridapi.getFocusedCell().rowIndex].DETDEV_PORIVA);
          }
          
        }
      }
      //VENTAS
      if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'DETPED_PORIVA' && this.init.auxsalto === false) { 
        if(maestro[gridapi.getFocusedCell().rowIndex].DETPED_PORIVA === null || maestro[gridapi.getFocusedCell().rowIndex].DETPED_PORIVA === undefined){
          maestro[gridapi.getFocusedCell().rowIndex].DETPED_PORIVA='';
        }     
        if (gridapi.aggFuncService === 'vendetped') {
          if(maestro[gridapi.getFocusedCell().rowIndex].DETPED_TIPODET !=='*'){
            if(maestro[gridapi.getFocusedCell().rowIndex].DETPED_TRIBIVA ==='S'){
              this.busquedaTariivacodigoS(maestro[gridapi.getFocusedCell().rowIndex],'detpedtarivacodS', maestro[gridapi.getFocusedCell().rowIndex].DETPED_PORIVA);
            }
            if(maestro[gridapi.getFocusedCell().rowIndex].DETPED_TRIBIVA ==='N'){
              this.busquedaTariivacodigoN(maestro[gridapi.getFocusedCell().rowIndex],'detpedtarivacodN', maestro[gridapi.getFocusedCell().rowIndex].DETPED_PORIVA);
            }
            //this.busquedaTariivacodigo(maestro[gridapi.getFocusedCell().rowIndex],'detordcomtarivacod', maestro[gridapi.getFocusedCell().rowIndex].DETORDCOM_PORIVA);
          }
          
        }
      }
      if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'DETGRE_PORIVA' && this.init.auxsalto === false) { 
        if(maestro[gridapi.getFocusedCell().rowIndex].DETGRE_PORIVA === null || maestro[gridapi.getFocusedCell().rowIndex].DETGRE_PORIVA === undefined){
          maestro[gridapi.getFocusedCell().rowIndex].DETGRE_PORIVA='';
        }     
        if (gridapi.aggFuncService === 'vendetguia') {
          if(maestro[gridapi.getFocusedCell().rowIndex].DETGRE_TIPODET !=='*'){
            if(maestro[gridapi.getFocusedCell().rowIndex].DETGRE_TRIBIVA ==='S'){
              this.busquedaTariivacodigoS(maestro[gridapi.getFocusedCell().rowIndex],'detgretarivacodS', maestro[gridapi.getFocusedCell().rowIndex].DETGRE_PORIVA);
            }
            if(maestro[gridapi.getFocusedCell().rowIndex].DETGRE_TRIBIVA ==='N'){
              this.busquedaTariivacodigoN(maestro[gridapi.getFocusedCell().rowIndex],'detgretarivacodN', maestro[gridapi.getFocusedCell().rowIndex].DETGRE_PORIVA);
            }
            //this.busquedaTariivacodigo(maestro[gridapi.getFocusedCell().rowIndex],'detordcomtarivacod', maestro[gridapi.getFocusedCell().rowIndex].DETORDCOM_PORIVA);
          }
          
        }
      }

      if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'DETFAC_PORIVA' && this.init.auxsalto === false) { 
        if(maestro[gridapi.getFocusedCell().rowIndex].DETFAC_PORIVA === null || maestro[gridapi.getFocusedCell().rowIndex].DETFAC_PORIVA === undefined){
          maestro[gridapi.getFocusedCell().rowIndex].DETFAC_PORIVA='';
        }     
        if (gridapi.aggFuncService === 'detfac') {
          if(maestro[gridapi.getFocusedCell().rowIndex].DETFAC_TIPODET !=='*'){
            if(maestro[gridapi.getFocusedCell().rowIndex].DETFAC_TRIBIVA ==='S'){
              this.busquedaTariivacodigoS(maestro[gridapi.getFocusedCell().rowIndex],'detfactarivacodS', maestro[gridapi.getFocusedCell().rowIndex].DETFAC_PORIVA);
            }
            if(maestro[gridapi.getFocusedCell().rowIndex].DETFAC_TRIBIVA ==='N'){
              this.busquedaTariivacodigoN(maestro[gridapi.getFocusedCell().rowIndex],'detfactarivacodN', maestro[gridapi.getFocusedCell().rowIndex].DETFAC_PORIVA);
            }
            //this.busquedaTariivacodigo(maestro[gridapi.getFocusedCell().rowIndex],'detordcomtarivacod', maestro[gridapi.getFocusedCell().rowIndex].DETORDCOM_PORIVA);
          }
        }
        if (gridapi.aggFuncService === 'detfacpv') {
          if(maestro[gridapi.getFocusedCell().rowIndex].DETFAC_TIPODET !=='*'){
            if(maestro[gridapi.getFocusedCell().rowIndex].DETFAC_TRIBIVA ==='S'){
              this.busquedaTariivacodigoS(maestro[gridapi.getFocusedCell().rowIndex],'detfacpvtarivacodS', maestro[gridapi.getFocusedCell().rowIndex].DETFAC_PORIVA);
            }
            if(maestro[gridapi.getFocusedCell().rowIndex].DETFAC_TRIBIVA ==='N'){
              this.busquedaTariivacodigoN(maestro[gridapi.getFocusedCell().rowIndex],'detfacpvtarivacodN', maestro[gridapi.getFocusedCell().rowIndex].DETFAC_PORIVA);
            }
            //this.busquedaTariivacodigo(maestro[gridapi.getFocusedCell().rowIndex],'detordcomtarivacod', maestro[gridapi.getFocusedCell().rowIndex].DETORDCOM_PORIVA);
          }
        }
        
      }
      if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'DETDVF_PORIVA' && this.init.auxsalto === false) { 
        if(maestro[gridapi.getFocusedCell().rowIndex].DETDVF_PORIVA === null || maestro[gridapi.getFocusedCell().rowIndex].DETDVF_PORIVA === undefined){
          maestro[gridapi.getFocusedCell().rowIndex].DETDVF_PORIVA='';
        }     
        if (gridapi.aggFuncService === 'vendetdvf') {
          if(maestro[gridapi.getFocusedCell().rowIndex].DETDVF_TIPODET !=='*'){
            if(maestro[gridapi.getFocusedCell().rowIndex].DETDVF_TRIBIVA ==='S'){
              this.busquedaTariivacodigoS(maestro[gridapi.getFocusedCell().rowIndex],'detdvffactarivacodS', maestro[gridapi.getFocusedCell().rowIndex].DETDVF_PORIVA);
            }
            if(maestro[gridapi.getFocusedCell().rowIndex].DETDVF_TRIBIVA ==='N'){
              this.busquedaTariivacodigoN(maestro[gridapi.getFocusedCell().rowIndex],'detdvffactarivacodN', maestro[gridapi.getFocusedCell().rowIndex].DETDVF_PORIVA);
            }
            //this.busquedaTariivacodigo(maestro[gridapi.getFocusedCell().rowIndex],'detordcomtarivacod', maestro[gridapi.getFocusedCell().rowIndex].DETORDCOM_PORIVA);
          }
          
        }
      }
      
    //CDPJ
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CON_CODIGO' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'maebodega') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'comnotrecVarios') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGOVARIOS',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'maesalidavar') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGOSAL',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'maemovimiento') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_MOV',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'maetomafisicavar') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'TOM_CON',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'maeentradavar') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGOENT',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'maegroup') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_GRUPO',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'maeservicios') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGOSERV',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'commaeservicios') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_SERVICIO',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'commaegrupo') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_COM_GRUPO',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'maecargos') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CARGOS',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'comision') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_COMISION',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'cxcmaerentencion') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'RETENCION',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }

      if (gridapi.aggFuncService === 'cxcmaetarjetacredito') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGOT',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'maeBanCaja') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_BANCAJA',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'districc') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGODISTRI',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'cxppagovarios') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGOCXPVARIOS',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'cxcvarios') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGOCXC',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'conencasil') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGOENCASIL',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'banTrnBanVar') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGOBANVARIOS',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'contrnDistribcc') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGODISTRIBCC',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'bantarVarios') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGOTRNTARJETA',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'bancajatrnVarios') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGOBANCAJA',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'saciconfmaeimp') {
        this.sacimaeconfService.actualizarCodContable(maestro[gridapi.getFocusedCell().rowIndex]);
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGOIMP',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'invtrnajustes') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CONCODIGO_AJUSTES',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      //CDPJ
      if (gridapi.aggFuncService === 'invtrnajustesneg') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CONCODIGO_AJUSTESNEG',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      //CDPJ
      if (gridapi.aggFuncService === 'cxpcuotavar') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CONCODIGO_CXPCUOTA',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'nomMaeRubros') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGO_NOM_RUB',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'nomMaeIngrFij') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGO_INGRFIJ',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }

      if (gridapi.aggFuncService === 'nomMaeEgrFij') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGO_EGREFIJ',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'nomliqdecetervar') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGO_LIQDECEVAR',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      // if (gridapi.aggFuncService === 'costosInd') {
      //   this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGOcostosInd',
      //     maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      // }
      if (gridapi.aggFuncService === 'actmaegrupo') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGO_ACTGRUPO',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }
      if (gridapi.aggFuncService === 'actdetvarsal') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGO_ACTSALIDA',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
      }

      //contable
    }
    //GSRF
    if (e.key === 'Enter' && gridapi.aggFuncService === 'cxcvarios') {
      this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGOCXC',
        maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO);
    }
    //GSRF
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CON_CODIGO2'
      && this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'commaeservicios') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_SERVICIOS2',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO2);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CON_CODIGO_VTA'
      && this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'cxcmaerentencion') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'RETENCIONvta',
          maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO_VTA);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CON_CODIGOCOM') {
      this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGOCOM',
        maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGOCOM);
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CON_CODIGORFTE') {
      this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGORFTE',
        maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGORFTE);
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CON_CODIGOFIVA') {
      this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGOFIVA',
        maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGOFIVA);
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'UNI_CODIGODESTINO' && this.init.auxsalto === false) {
      this.busquedaunidad(maestro[gridapi.getFocusedCell().rowIndex], 'UNI_CODIGODESTINO');
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CON_CODIGOGAS') {
      this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGOGAS',
        maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGOGAS);
    }
    //CDPJ
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CON_CODIGOACT') {
      this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGOACT',
        maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGOACT);
    }
    //CDPJ
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CON_CODIGOING') {
      this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'CON_CODIGOING',
        maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGOING);
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'ART_CODIGO' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'maesalida') {
        this.busquedaarticuloexistencia(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaarticulodetallesalida',
          maestro[gridapi.getFocusedCell().rowIndex].ART_CODIGO);
      }
      if (gridapi.aggFuncService === 'maetomafisica') {
        this.busquedaarticuloexistenciaTom(maestro[gridapi.getFocusedCell().rowIndex], 'tomaBusArt',
          maestro[gridapi.getFocusedCell().rowIndex].ART_CODIGO);
      }
      if (gridapi.aggFuncService === 'maeentrada') {
        this.busquedaarticuloexistenciaEnt(maestro[gridapi.getFocusedCell().rowIndex], 'artEntrada',
          maestro[gridapi.getFocusedCell().rowIndex].ART_CODIGO);
      }
      if (gridapi.aggFuncService === 'maelote') {
        this.busquedaarticulodetalle(maestro[gridapi.getFocusedCell().rowIndex], 'artmaelote', maestro[gridapi.getFocusedCell().rowIndex].ART_CODIGO);
      }
      if (gridapi.aggFuncService === 'dettransfer') {
        this.busquedaarticulotransferencia(maestro[gridapi.getFocusedCell().rowIndex], 'dettransfer', maestro[gridapi.getFocusedCell().rowIndex].ART_CODIGO);
      }
      if (gridapi.aggFuncService === 'invtrnajustes') {
        this.busquedaarticulodetalle(maestro[gridapi.getFocusedCell().rowIndex], 'ARTCODIGO_AJUSTE', maestro[gridapi.getFocusedCell().rowIndex].ART_CODIGO);
      }
      if (gridapi.aggFuncService === 'invtrnajustesneg') {
        this.busquedaarticulodetalle(maestro[gridapi.getFocusedCell().rowIndex], 'ARTCODIGO_AJUSTENEG', maestro[gridapi.getFocusedCell().rowIndex].ART_CODIGO);
      }
      if (gridapi.aggFuncService === 'invtrnreservas') {
        this.busquedaarticuloreserva(maestro[gridapi.getFocusedCell().rowIndex], 'ARTCODIGO_RESERVA', maestro[gridapi.getFocusedCell().rowIndex].ART_CODIGO);
      }
      if (gridapi.aggFuncService === 'detordpro') {
        this.busquedaarticuloexistencia(maestro[gridapi.getFocusedCell().rowIndex], 'artcodigodetordpro', maestro[gridapi.getFocusedCell().rowIndex].ART_CODIGO);
      }
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'TRNCOMQUI_CODIGO' && this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'invprodtrncomqui') {
        this.busquedacomposicionquimica(maestro[gridapi.getFocusedCell().rowIndex], 'TRNCOMQUI', maestro[gridapi.getFocusedCell().rowIndex].TRNCOMQUI_CODIGO);
      }
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'TRNAPLI_CODIGO' && this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'invprodtrnaplica') {
        this.busquedaprodaplicacion(maestro[gridapi.getFocusedCell().rowIndex], 'TRNAPLI', maestro[gridapi.getFocusedCell().rowIndex].TRNAPLI_CODIGO);

      }
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'ACT_CODIGO' && this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'actdetsalida') {
        this.busquedaactivosalida(maestro[gridapi.getFocusedCell().rowIndex], 'ACTCODIGO', maestro[gridapi.getFocusedCell().rowIndex].ACT_CODIGO);

      }
    }


    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'DETPED_CODIGO' && this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'vendetped') {
        if (maestro[gridapi.getFocusedCell().rowIndex].DETPED_TIPODET === 'A') {
          this.busquedaarticulopedido(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaPedido', maestro[gridapi.getFocusedCell().rowIndex].DETPED_CODIGO);
        } else if (maestro[gridapi.getFocusedCell().rowIndex].DETPED_TIPODET === 'S') {
          this.busquedaservicioPedido(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaPedidoServicio', maestro[gridapi.getFocusedCell().rowIndex].DETPED_CODIGO);
        } else if (maestro[gridapi.getFocusedCell().rowIndex].DETPED_TIPODET === '*') {
        }
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'DETDVF_CODIGO' && this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'vendetdvf') {
        if (maestro[gridapi.getFocusedCell().rowIndex].DETDVF_TIPODET === 'A') {
          // this.busquedaarticulodev(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaDVF', maestro[gridapi.getFocusedCell().rowIndex].DETDVF_CODIGO);
        } else if (maestro[gridapi.getFocusedCell().rowIndex].DETDVF_TIPODET === 'S') {
          this.busquedaservicioPedido(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaDvfServicio', maestro[gridapi.getFocusedCell().rowIndex].DETDVF_CODIGO);
        } else if (maestro[gridapi.getFocusedCell().rowIndex].DETDVF_TIPODET === 'C') {
          this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'busquedacuentadev', maestro[gridapi.getFocusedCell().rowIndex].DETDVF_CODIGO);
        } else if (maestro[gridapi.getFocusedCell().rowIndex].DETDVF_TIPODET === '*') {
        }
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'DETDEV_CODIGO' && this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'comdetdev') {
        if (maestro[gridapi.getFocusedCell().rowIndex].DETDEV_TIPODET === 'A') {
          // this.busquedaarticulodev(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaDEVCOM', maestro[gridapi.getFocusedCell().rowIndex].DETDEV_CODIGO);
        } else if (maestro[gridapi.getFocusedCell().rowIndex].DETDEV_TIPODET === 'S') {
          this.busquedaservicioPedido(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaDevServicioCom', maestro[gridapi.getFocusedCell().rowIndex].DETDEV_CODIGO);
        } else if (maestro[gridapi.getFocusedCell().rowIndex].DETDEV_TIPODET === 'C') {
          this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'busquedacuentadevCOM', maestro[gridapi.getFocusedCell().rowIndex].DETDEV_CODIGO);
        } else if (maestro[gridapi.getFocusedCell().rowIndex].DETDEV_TIPODET === '*') {
        }
      }
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'DETNOTREC_CODIGO' && this.init.auxsalto === false) {

      if (gridapi.aggFuncService === 'comnotrecRecepcion') {
        if (maestro[gridapi.getFocusedCell().rowIndex].DETNOTREC_TIPODET === 'A') {

          this.busquedaarticulosnotasrec(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaarticulosnotasrec', maestro[gridapi.getFocusedCell().rowIndex].DETNOTREC_CODIGO);
        } else if (maestro[gridapi.getFocusedCell().rowIndex].DETNOTREC_TIPODET === 'S') {
          this.busquedaserviciopro(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaservicioNotRec', maestro[gridapi.getFocusedCell().rowIndex].DETNOTREC_CODIGO);

        } else if (maestro[gridapi.getFocusedCell().rowIndex].DETNOTREC_TIPODET === '*') {
          this.indexunidad = this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].findIndex(
            detalle => detalle.LINEA === (maestro[gridapi.getFocusedCell().rowIndex].LINEA));
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETNOTREC_DESCRIPCION');
          this.cambioboton.emit(true);
        }
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'DETNOTREC_DESCRIPCION') {
      if (maestro[gridapi.getFocusedCell().rowIndex].DETNOTREC_TIPODET === '*') {
        this.cambioboton.emit(true);

      }
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CEN_CODIGO' && this.init.auxsalto === false) {

      if (gridapi.aggFuncService === 'comnotrecRecepcion') {
        if (maestro[gridapi.getFocusedCell().rowIndex].DETNOTREC_TIPODET === 'A') {

          this.busquedaCEN(maestro[gridapi.getFocusedCell().rowIndex], 'CEN_NOTASREC');
        }
      }
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'DETGRE_CODIGO' && this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'vendetguia') {
        if (maestro[gridapi.getFocusedCell().rowIndex].DETGRE_TIPODET === 'A') {
          this.busquedaarticuloguia(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaGuia', maestro[gridapi.getFocusedCell().rowIndex].DETGRE_CODIGO);
        } else if (maestro[gridapi.getFocusedCell().rowIndex].DETGRE_TIPODET === 'S') {
          this.busquedaservicioPedido(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaGuiaServicio', maestro[gridapi.getFocusedCell().rowIndex].DETGRE_CODIGO);
        } else if (maestro[gridapi.getFocusedCell().rowIndex].DETGRE_TIPODET === 'C') {
          this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'busquedacuentaguia', maestro[gridapi.getFocusedCell().rowIndex].DETGRE_CODIGO);
        } else if (maestro[gridapi.getFocusedCell().rowIndex].DETGRE_TIPODET === '*') {
        }
      }
    }
        //cdpj
        if (e.key === 'Enter' &&
        gridapi.getFocusedCell().column.colId === 'CEN_CODIGO' && this.init.auxsalto === false) {
        if (gridapi.aggFuncService === 'vendetguia') {
          //this.busquedaCEN(maestro[gridapi.getFocusedCell().rowIndex], 'CEN_CODIGO');
          //this.busquedaCEN(maestro[gridapi.getFocusedCell().rowIndex], 'CEN_GUIA');
          this.busquedaCentroCostos(maestro[gridapi.getFocusedCell().rowIndex], 'CEN_GUIA', maestro[gridapi.getFocusedCell().rowIndex].CEN_CODIGO);
        }
      }
      if (e.key === 'Enter' &&
        gridapi.getFocusedCell().column.colId === 'CEN_CODIGO' && this.init.auxsalto === false) {
        if (gridapi.aggFuncService === 'vendetdvf') {
          this.busquedaCentroCostos(maestro[gridapi.getFocusedCell().rowIndex], 'CEN_DEVF', maestro[gridapi.getFocusedCell().rowIndex].CEN_CODIGO);
        }
      }
      
        //CDPJ
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'DETORDCOM_CODIGO' && this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'comdetordcom') {
        if (maestro[gridapi.getFocusedCell().rowIndex].DETORDCOM_TIPODET === 'A') {
          this.busquedaarticuloorden(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaOrden', maestro[gridapi.getFocusedCell().rowIndex].DETORDCOM_CODIGO);
        } else if (maestro[gridapi.getFocusedCell().rowIndex].DETORDCOM_TIPODET === 'S') {
          this.busquedaservicioPedido(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaOrdenServicio', maestro[gridapi.getFocusedCell().rowIndex].DETORDCOM_CODIGO);
        } else if (maestro[gridapi.getFocusedCell().rowIndex].DETORDCOM_TIPODET === '*') {
        }
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'MAEPRM_ARTSRV' && this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'maePromocion') {
        if (maestro[gridapi.getFocusedCell().rowIndex].MAEPRM_TIPO === 'A'
          && (maestro[gridapi.getFocusedCell().rowIndex].GRUP_CODIGO === undefined
            || maestro[gridapi.getFocusedCell().rowIndex].GRUP_CODIGO === null
            || maestro[gridapi.getFocusedCell().rowIndex].GRUP_CODIGO === '')) {
          this.busquedaarticulodetalle(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaPromocion', maestro[gridapi.getFocusedCell().rowIndex].MAEPRM_ARTSRV);
          if (maestro[gridapi.getFocusedCell().rowIndex].MAEPRM_TIPO !== 'A' &&
            (maestro[gridapi.getFocusedCell().rowIndex].GRUP_CODIGO === undefined
              || maestro[gridapi.getFocusedCell().rowIndex].GRUP_CODIGO === null
              || maestro[gridapi.getFocusedCell().rowIndex].GRUP_CODIGO === '')) {
            maestro[gridapi.getFocusedCell().rowIndex].MAEPRM_ARTSRV = '';
            maestro[gridapi.getFocusedCell().rowIndex].MAEPRM_ARTCANT = '';
            maestro[gridapi.getFocusedCell().rowIndex].MAEPRM_UNIDAD = '';
          }
        }
        if (maestro[gridapi.getFocusedCell().rowIndex].MAEPRM_TIPO === 'S'
          && (maestro[gridapi.getFocusedCell().rowIndex].GRUP_CODIGO === undefined
            || maestro[gridapi.getFocusedCell().rowIndex].GRUP_CODIGO === null
            || maestro[gridapi.getFocusedCell().rowIndex].GRUP_CODIGO === '')) {
          this.busquedaserviciopro(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaPromocionServicio', maestro[gridapi.getFocusedCell().rowIndex].MAEPRM_ARTSRV);
          if (maestro[gridapi.getFocusedCell().rowIndex].MAEPRM_TIPO === 'S') {
            maestro[gridapi.getFocusedCell().rowIndex].MAEPRM_ARTSRV === '';
          }
        }
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'MAEDSCTO_ARTSVR' && this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'maedescuento') {
        if (maestro[gridapi.getFocusedCell().rowIndex].MAEDSCTO_TIPO === 'A') {
          this.busquedaarticulodetalle(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaDescuento', maestro[gridapi.getFocusedCell().rowIndex].MAEDSCTO_ARTSVR);
        }
        if (maestro[gridapi.getFocusedCell().rowIndex].MAEDSCTO_TIPO === 'S') {
          this.busquedaserviciopro(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaDescuentoServicio', maestro[gridapi.getFocusedCell().rowIndex].MAEDSCTO_ARTSVR);
        }
      }
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'MAEPRM_ARTORG' && this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'maePromocion') {

        if (maestro[gridapi.getFocusedCell().rowIndex].MAEPRM_TIPO === 'A') {
          this.busquedaarticulodetalle(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaPromocion2', maestro[gridapi.getFocusedCell().rowIndex].MAEPRM_ARTORG);
        }
        if (maestro[gridapi.getFocusedCell().rowIndex].MAEPRM_TIPO === 'S'
          && (maestro[gridapi.getFocusedCell().rowIndex].GRUP_CODIGO === undefined
            || maestro[gridapi.getFocusedCell().rowIndex].GRUP_CODIGO === null
            || maestro[gridapi.getFocusedCell().rowIndex].GRUP_CODIGO === '')) {
          this.busquedaserviciopro(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaPromocionServicio2', maestro[gridapi.getFocusedCell().rowIndex].MAEPRM_ARTORG);
        }
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'GRUP_CODIGO' && this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'maePromocion') {
        if (maestro[gridapi.getFocusedCell().rowIndex].MAEPRM_TIPO === 'A'
          && (maestro[gridapi.getFocusedCell().rowIndex].MAEPRM_ARTSRV === undefined
            || maestro[gridapi.getFocusedCell().rowIndex].MAEPRM_ARTSRV === null
            || maestro[gridapi.getFocusedCell().rowIndex].MAEPRM_ARTSRV === '')
        ) {
          this.busquedagrupoPro(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaGrupoPromocion', maestro[gridapi.getFocusedCell().rowIndex].GRUP_CODIGO);
        }
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'ART_NOMBRE') {
      if (gridapi.aggFuncService === 'maetomafisica') {
        this.busquedaarticuloexistenciaTom(maestro[gridapi.getFocusedCell().rowIndex], 'tomaBusArt',
          maestro[gridapi.getFocusedCell().rowIndex].ART_CODIGO);
      }
      if (gridapi.aggFuncService === 'maeentrada') {
        this.busquedaarticuloexistenciaEnt(maestro[gridapi.getFocusedCell().rowIndex], 'artEntrada',
          maestro[gridapi.getFocusedCell().rowIndex].ART_CODIGO);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'DETKAR_COSTOUNIT') {
      if (gridapi.aggFuncService === 'maeentrada') {
        this.indexunidad = this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice].findIndex(entrada =>
          entrada.BOD_CODIGO === maestro[gridapi.getFocusedCell().rowIndex].BOD_CODIGO);
        this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETKAR_COSTOTOTAL = Number(this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETKAR_COSTOUNIT) * Number(this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETKAR_CANTIDAD);
        this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETKAR_COSTOTOTAL = this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETKAR_COSTOTOTAL.toFixed(4);
        this.selected.emit('cambio');
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'TRNTOMAFISICA_COSTOPROM') {
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'DETFAC_CODIGO') {
      if (gridapi.aggFuncService === 'detfac') {
        if (maestro[gridapi.getFocusedCell().rowIndex].DETFAC_TIPODET === 'A') {
          this.busquedaarticuloexistencia(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaarticulodetfac',
            maestro[gridapi.getFocusedCell().rowIndex].DETFAC_CODIGO);
        }
        if (maestro[gridapi.getFocusedCell().rowIndex].DETFAC_TIPODET === 'S') {
          this.busquedaserviciodetalle(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaserviciodetfac', maestro[gridapi.getFocusedCell().rowIndex].DETFAC_CODIGO);
        }
        if (maestro[gridapi.getFocusedCell().rowIndex].DETFAC_TIPODET === 'C') {
          this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'busquedacuentadetfac', maestro[gridapi.getFocusedCell().rowIndex].DETFAC_CODIGO);
        }
      }
      if (gridapi.aggFuncService === 'detfacpv') {
        this.busquedaarticuloexistencia(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaarticulodetfacpv',
          maestro[gridapi.getFocusedCell().rowIndex].DETFAC_CODIGO);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'TRNPAGO_TIPO') {
      if (gridapi.aggFuncService === 'pagcon') {
        this.busquedatipopago(maestro[gridapi.getFocusedCell().rowIndex], 'busquedatipopago', maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO);
      }
      if (gridapi.aggFuncService === 'pagcondvf') {
        this.busquedatipopago(maestro[gridapi.getFocusedCell().rowIndex], 'busquedatipopagodvf', maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO);
      }
      if (gridapi.aggFuncService === 'cxcformapago') {
        this.busquedatipopago(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaFormapagoCXC', maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO);
      }
      //CDPJ
      if (gridapi.aggFuncService === 'cxctrnabonoformapago') {
        this.busquedatipopago(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaFormapagoCXCTrnabono', maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO);
      }
      if (gridapi.aggFuncService === 'cxctrncancelacionformapago') {
        this.busquedatipopago(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaFormapagoCXCTrncancelacion', maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO);
      }
      // if (gridapi.aggFuncService === 'cxctrnanticipoformapago') {
      //   this.busquedatipopago(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaFormapagoCXCTrnanticipo', maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO);
      // }
      //CDPJ
      if (gridapi.aggFuncService === 'cxcpagoretformapago') {
        this.busquedatipopago(maestro[gridapi.getFocusedCell().rowIndex], 'busquedapagoretFormapago', maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'BANCLI_CODIGO' && this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'pagcon') {
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO === 'CH') {
          this.busquedaBancoCliente(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaBancoCliente', maestro[gridapi.getFocusedCell().rowIndex].BANCLI_CODIGO);
        }
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO === 'TA') {
          this.busquedaTarjetaC(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaTarjetaC', maestro[gridapi.getFocusedCell().rowIndex].BANCLI_CODIGO);
        }
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO === 'DP') {
          this.busquedaBanco(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaBanco', maestro[gridapi.getFocusedCell().rowIndex].BANCLI_CODIGO);
        }
      }
      if (gridapi.aggFuncService === 'pagcondvf') {
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO === 'CH') {
          this.busquedaBanco(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaBancodvf', maestro[gridapi.getFocusedCell().rowIndex].BANCLI_CODIGO);//CDPJ ANTES TRNPAGO_TIPO
        }
      }
      if (gridapi.aggFuncService === 'cxcformapago') {
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO === 'CH') {
          this.busquedaBancoCliente(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaBancoClienteCarteraCH', maestro[gridapi.getFocusedCell().rowIndex].BANCLI_CODIGO);
        }
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO === 'TA') {
          this.busquedaTarjetaC(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaTarjetaCartera', maestro[gridapi.getFocusedCell().rowIndex].BANCLI_CODIGO);
        }
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO === 'DP') {
          this.busquedaBanco(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaBancoClienteCartera', maestro[gridapi.getFocusedCell().rowIndex].BANCLI_CODIGO);
        }
      }
      if (gridapi.aggFuncService === 'cxctrnabonoformapago') {
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO === 'CH') {
          this.busquedaBancoCliente(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaBancoClienteAbonoCH', maestro[gridapi.getFocusedCell().rowIndex].BANCLI_CODIGO);
        }
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO === 'TA') {
          this.busquedaTarjetaC(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaTarjetaAbono', maestro[gridapi.getFocusedCell().rowIndex].BANCLI_CODIGO);
        }
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO === 'DP') {
          this.busquedaBanco(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaBancoClienteAbono', maestro[gridapi.getFocusedCell().rowIndex].BANCLI_CODIGO);
        }
      }
      if (gridapi.aggFuncService === 'cxctrncancelacionformapago') {
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO === 'CH') {
          this.busquedaBancoCliente(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaBancoClienteCancelacionCH', maestro[gridapi.getFocusedCell().rowIndex].BANCLI_CODIGO);
        }
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO === 'TA') {
          this.busquedaTarjetaC(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaTarjetaCancelacion', maestro[gridapi.getFocusedCell().rowIndex].BANCLI_CODIGO);
        }
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO === 'DP') {
          this.busquedaBanco(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaBancoClienteCancelacion', maestro[gridapi.getFocusedCell().rowIndex].BANCLI_CODIGO);
        }
      }
      // if (gridapi.aggFuncService === 'cxctrnanticipoformapago') {
      //   if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO === 'CH') {
      //     this.busquedaBancoCliente(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaBancoClienteAnticipoCH', maestro[gridapi.getFocusedCell().rowIndex].BANCLI_CODIGO);
      //   }
      //   if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO === 'TA') {
      //     this.busquedaTarjetaC(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaTarjetaAnticipo', maestro[gridapi.getFocusedCell().rowIndex].BANCLI_CODIGO);
      //   }
      //   if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO === 'DP') {
      //     this.busquedaBanco(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaBancoClienteAnticipo', maestro[gridapi.getFocusedCell().rowIndex].BANCLI_CODIGO);
      //   }
      // }
      //CDPJ
      if (gridapi.aggFuncService === 'cxcpagoretformapago') {

        this.busquedaBanco(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaBancoPagoRet', maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO);

      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'TRNPAGO_CODSRI' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'pagcon') {
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO === 'TA') {
          this.busquedaTar(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaCodigoSRIpago',
            maestro[gridapi.getFocusedCell().rowIndex].TAR_CODSRI);
        } else {
          this.busquedaCodigoSRI(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaCodigoSRIpago');
        }
      }
      if (gridapi.aggFuncService === 'cxcformapago') {
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO === 'TA') {
          this.busquedaTar(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaCodigoSRICartera',
            maestro[gridapi.getFocusedCell().rowIndex].TAR_CODSRI);
        } else {
          this.busquedaCodigoSRI(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaCodigoSRICartera');
        }
      }
      //CDPJ
      if (gridapi.aggFuncService === 'cxctrnabonoformapago') {
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO === 'TA') {
          this.busquedaTar(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaCodigoSRIAbono',
            maestro[gridapi.getFocusedCell().rowIndex].TAR_CODSRI);
        } else {
          this.busquedaCodigoSRI(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaCodigoSRIAbono');
        }
      }
      if (gridapi.aggFuncService === 'cxctrncancelacionformapago') {
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO === 'TA') {
          this.busquedaTar(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaCodigoSRICancelacion',
            maestro[gridapi.getFocusedCell().rowIndex].TAR_CODSRI);
        } else {
          this.busquedaCodigoSRI(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaCodigoSRICancelacion');
        }
      }
      // if (gridapi.aggFuncService === 'cxctrnanticipoformapago') {
      //   if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGO_TIPO === 'TA') {
      //     this.busquedaTar(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaCodigoSRIAnticipo',
      //       maestro[gridapi.getFocusedCell().rowIndex].TAR_CODSRI);
      //   } else {
      //     this.busquedaCodigoSRI(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaCodigoSRIAnticipo');
      //   }
      // }
      //CDPJ
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'RETENCION_CODIGO' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'retpagcon') {
        this.busquedaretencion(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaretencion',
          maestro[gridapi.getFocusedCell().rowIndex].RETENCION_CODIGO);
      }
      if (gridapi.aggFuncService === 'trnretencion') {
        this.busquedaretencion(maestro[gridapi.getFocusedCell().rowIndex], 'trnretencion',
          maestro[gridapi.getFocusedCell().rowIndex].RETENCION_CODIGO);
      }
      if (gridapi.aggFuncService === 'cxcretenciones') {
        this.busquedaretencion(maestro[gridapi.getFocusedCell().rowIndex], 'cxcretenciones',
          maestro[gridapi.getFocusedCell().rowIndex].RETENCION_CODIGO);
      }
      if (gridapi.aggFuncService === 'cxcpagoretret') {
        this.busquedaretencion(maestro[gridapi.getFocusedCell().rowIndex], 'cxcpagoretret',
          maestro[gridapi.getFocusedCell().rowIndex].RETENCION_CODIGO);
      }
      if (gridapi.aggFuncService === 'bantarRetencion') {
        this.busquedaretencion(maestro[gridapi.getFocusedCell().rowIndex], 'BANTARJETA_RETENCION',
          maestro[gridapi.getFocusedCell().rowIndex].RETENCION_CODIGO);
      }
       //CDPJ
       if (gridapi.aggFuncService === 'cxctrnretenciones') {
        this.busquedaretencion(maestro[gridapi.getFocusedCell().rowIndex], 'cxctrnretenciones',
          maestro[gridapi.getFocusedCell().rowIndex].RETENCION_CODIGO);
      }
      if (gridapi.aggFuncService === 'cxctrnabonoretenciones') {
        this.busquedaretencion(maestro[gridapi.getFocusedCell().rowIndex], 'cxctrnabonoretenciones',
          maestro[gridapi.getFocusedCell().rowIndex].RETENCION_CODIGO);
      }
      if (gridapi.aggFuncService === 'cxctrncancelacionretenciones') {
        this.busquedaretencion(maestro[gridapi.getFocusedCell().rowIndex], 'cxctrncancelacionretenciones',
          maestro[gridapi.getFocusedCell().rowIndex].RETENCION_CODIGO);
      }
      //CDPJ
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'LOTE_NUMERO' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'tablalote') {
        const VALLOTART = this.trnloteservice.hmpConfiguracion.find(element => element.CFG_CODIGO === 'VALLOTART').CFG_VALOR1;
        if (VALLOTART === 1 && (this.origenLote ==='FACVEN'|| this.origenLote ==='SA'|| this.origenLote === 'GUIREM' || this.origenLote === 'DEVCOM')) {
          this.busquedalotescant(maestro[gridapi.getFocusedCell().rowIndex], 'busquedalotes',
            maestro[gridapi.getFocusedCell().rowIndex].LOTE_NUMERO);
        } else {
          this.busquedalotes(maestro[gridapi.getFocusedCell().rowIndex], 'busquedalotes',
            maestro[gridapi.getFocusedCell().rowIndex].LOTE_NUMERO);
        }
      }
    }
      //GSRF
      if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CODALT_CODIGO') {
        console.log('entra----', maestro[gridapi.getFocusedCell().rowIndex].CODALT_CODIGO )
        console.log('entra----', maestro[gridapi.getFocusedCell().rowIndex].CODALT_TIPO )
          if (maestro[gridapi.getFocusedCell().rowIndex].CODALT_TIPO === 'A') {
            this.busquedaarticulodetalle(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaarticulodetfaccodalt', maestro[gridapi.getFocusedCell().rowIndex].CODALT_CODIGO);
          }
          if (maestro[gridapi.getFocusedCell().rowIndex].CODALT_TIPO === 'S') {
            this.busquedaserviciopro(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaserviciodetfacprocodalt', maestro[gridapi.getFocusedCell().rowIndex].CODALT_CODIGO);

          }
          if (maestro[gridapi.getFocusedCell().rowIndex].CODALT_TIPO === 'C') {
            this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'busquedacuentadetfacprocodalt', maestro[gridapi.getFocusedCell().rowIndex].CODALT_CODIGO);
          }

      }
      if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CODALT_CENCOS') {
            this.busquedaCentroCostos(maestro[gridapi.getFocusedCell().rowIndex], 'CEN_CODIGOCODALT', maestro[gridapi.getFocusedCell().rowIndex].CODALT_CENCOS);

      }
      if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'DETFAC_CODIGOTIPO') {
        console.log('entra----', maestro[gridapi.getFocusedCell().rowIndex].DETFAC_CODIGOTIPO )
        console.log('entra----', maestro[gridapi.getFocusedCell().rowIndex].DETFAC_TIPO )
          if (maestro[gridapi.getFocusedCell().rowIndex].DETFAC_TIPO === 'A') {
            this.busquedaarticulodetalle(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaarticulodetfachomo', maestro[gridapi.getFocusedCell().rowIndex].DETFAC_CODIGOTIPO);
          }
          if (maestro[gridapi.getFocusedCell().rowIndex].DETFAC_TIPO === 'S') {
            this.busquedaserviciopro(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaserviciodetfachomo', maestro[gridapi.getFocusedCell().rowIndex].DETFAC_CODIGOTIPO);

          }
          if (maestro[gridapi.getFocusedCell().rowIndex].DETFAC_TIPO === 'C') {
            this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'busquedacuentadetfachomo', maestro[gridapi.getFocusedCell().rowIndex].DETFAC_CODIGOTIPO);
          }

      }
      //GSRF
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'DETFACPRO_CODIGO') {
      if (gridapi.aggFuncService === 'detfacpro') {
        this.detalleSelDetFacPro = maestro[gridapi.getFocusedCell().rowIndex];
        console.log('DETFACPRO_CODIGO', maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_CODIGO)
        console.log('DETFACPRO_TIPODET', maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_TIPODET)
        if (maestro[gridapi.getFocusedCell().rowIndex].ENCFACPRO_NUMERO === '' &&
          maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_CODIGO !== '' &&
          maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_TIPODET === '') {
          this.tipoDetFactXML = 'noXML';
          this.displayDialogOpcionesDetProv = true;
          
        }else if (maestro[gridapi.getFocusedCell().rowIndex].ENCFACPRO_NUMERO === '' &&
        maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_CODIGO === '' &&
        maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_TIPODET === '') {
        this.tipoDetFactXML = 'noXML';
        this.displayDialogOpcionesDetProv = true;
        
      }else if (maestro[gridapi.getFocusedCell().rowIndex].ENCFACPRO_NUMERO !== '' &&
          maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_CODIGO === null &&
          maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_TIPODET !== '*') {
          this.tipoDetFactXML = 'noXML';
          this.displayDialogOpcionesDetProv = true;
        }else if (this.encfacproService.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_FLAG !== null 
          && this.encfacproService.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_FLAG !== ''
          && this.encfacproService.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_FLAG !== undefined){
            this.tipoDetFactXML = 'noXML';
            this.displayDialogOpcionesDetProv = true;
        } else {
          this.tipoDetFactXML = 'noXML';
          if (maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_TIPODET === 'A') {
            
            this.busquedaarticulodetalle(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaarticulodetfacpro', maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_CODIGO);
          }
          if (maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_TIPODET === 'S') {
            // this.busquedaserviciodetalle(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaserviciodetfacpro', maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_CODIGO);
            this.busquedaserviciopro(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaserviciodetfacpro', maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_CODIGO);

          }
          if (maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_TIPODET === 'C') {
            this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'busquedacuentadetfacpro', maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_CODIGO);
          }
        }
      }
    }
   //GSRF
   if (e.key === 'Enter' &&
   gridapi.getFocusedCell().column.colId === 'DETFACPRO_AJUSTE') {
   if (gridapi.aggFuncService === 'detfacpro') {
     this.encfacproService.spin=true;//CDPJ;
     this.detalleSelDetFacPro = maestro[gridapi.getFocusedCell().rowIndex];
     console.log('*-*-*',maestro[gridapi.getFocusedCell().rowIndex].ENCNOTREC_NUMERO);
     if (maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_TIPODET !== '*' && maestro[gridapi.getFocusedCell().rowIndex].ENCNOTREC_NUMERO ==='') {
       this.busquedaarticulodetalle(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaarticulodetfacproajuste', maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_AJUSTE);
     }
   }
 }
 if (e.key === 'Enter' &&
 gridapi.getFocusedCell().column.colId === 'DETNOTREC_AJUSTE') {
   console.log('---------',gridapi.aggFuncService)
 if (gridapi.aggFuncService === 'comnotrecRecepcion') {
   if (maestro[gridapi.getFocusedCell().rowIndex].DETNOTREC_TIPODET !== '*') {
     this.busquedaarticulodetalle(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaarticulonotrecajuste', maestro[gridapi.getFocusedCell().rowIndex].DETNOTREC_AJUSTE);
   }
 }
}
 //GSRF

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'DETORDTRA_CODIGO') {
      if (gridapi.aggFuncService === 'detalleordentrabajo') {
        if (maestro[gridapi.getFocusedCell().rowIndex].ORDTRA_NUMERO !== '' &&
          maestro[gridapi.getFocusedCell().rowIndex].DETORDTRA_CODIGO === null) {
        } else {
          if (maestro[gridapi.getFocusedCell().rowIndex].DETORDTRA_TIPODET === 'A') {
            this.busquedaarticulodetalle(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaarticulodetordtra', maestro[gridapi.getFocusedCell().rowIndex].DETORDTRA_CODIGO);
          }
          if (maestro[gridapi.getFocusedCell().rowIndex].DETORDTRA_TIPODET === 'S') {
            this.busquedaserviciodetalle(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaserviciodetordtra', maestro[gridapi.getFocusedCell().rowIndex].DETORDTRA_CODIGO);
          }
        }
      }
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'TIPGAS_CODIGO') {
      if (gridapi.aggFuncService === 'detfacpro') {
        if (maestro[gridapi.getFocusedCell().rowIndex].BOLTIPOGASTO === true) {
          this.busquedatipogastos(maestro[gridapi.getFocusedCell().rowIndex], 'busquedatipogastos', maestro[gridapi.getFocusedCell().rowIndex].TIPGAS_CODIGO);
        }
      }
      if (gridapi.aggFuncService === 'districc') {
        if (maestro[gridapi.getFocusedCell().rowIndex].BOLTIPOGASTO === true) {
          this.busquedatipogastos(maestro[gridapi.getFocusedCell().rowIndex], 'busquedatipogastosdistricc', maestro[gridapi.getFocusedCell().rowIndex].TIPGAS_CODIGO);
        }
      }
      if (gridapi.aggFuncService === 'cxppagovarios') {
        if (maestro[gridapi.getFocusedCell().rowIndex].BOLTIPOGASTO === true) {
          this.busquedatipogastos(maestro[gridapi.getFocusedCell().rowIndex], 'busquedatipogastoscxpvarios', maestro[gridapi.getFocusedCell().rowIndex].TIPGAS_CODIGO);
        }
      }
       //CDPJ
       if(gridapi.aggFuncService=== 'comdetdev'){
      
        this.busquedatipogastos(maestro[gridapi.getFocusedCell().rowIndex], 'busquedatipogastosDev', maestro[gridapi.getFocusedCell().rowIndex].TIPGAS_CODIGO);
      }
      //CDPJ
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CARGO_CODIGO') {
      if (gridapi.aggFuncService === 'trncargos') {
        this.busquedacargos(maestro[gridapi.getFocusedCell().rowIndex], 'busquedacargos', maestro[gridapi.getFocusedCell().rowIndex].CARGO_CODIGO);
      }
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'PROG_CODIGO') {
      if (gridapi.aggFuncService === 'conencasil') {
        this.busquedaProgramas(maestro[gridapi.getFocusedCell().rowIndex], 'PROG_CONENCASIL', maestro[gridapi.getFocusedCell().rowIndex].PROG_CODIGO);
      }
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'TIPODOC' && this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'cxpfpant') {
        this.busquedatipopago(maestro[gridapi.getFocusedCell().rowIndex], 'TIPODOCcxpfpant', maestro[gridapi.getFocusedCell().rowIndex].TIPODOC);
      }
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'BANCODIGO' && this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'cxpfpant') {
        this.busquedaBanco(maestro[gridapi.getFocusedCell().rowIndex], 'BANCODIGOcxpfpant',
          maestro[gridapi.getFocusedCell().rowIndex].BANCODIGO);
      }
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CAJADOC' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'cxpfpant') {
        this.busquedaCaja(maestro[gridapi.getFocusedCell().rowIndex], 'CAJADOCcxpfpant', maestro[gridapi.getFocusedCell().rowIndex].CAJADOC);
      }
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'TRNPAGOTIPO' && this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'cxpfpdev') {
        this.busquedatipopago(maestro[gridapi.getFocusedCell().rowIndex], 'TRNPAGOTIPOcxpfpdev',
          maestro[gridapi.getFocusedCell().rowIndex].TRNPAGOTIPO);
      }
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'BANCLICODIGO' && this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'cxpfpdev') {
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGOTIPO === 'CH') {
          this.busquedaBancoCliente(maestro[gridapi.getFocusedCell().rowIndex], 'BANCLICODIGOcxpfpdev',
            maestro[gridapi.getFocusedCell().rowIndex].BANCLICODIGO);
        }
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGOTIPO === 'DP') {
          this.busquedaBanco(maestro[gridapi.getFocusedCell().rowIndex], 'BANCLICODIGOcxpfpdev',
            maestro[gridapi.getFocusedCell().rowIndex].BANCLICODIGO);
        }
      }
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CAJCODIGO' ) {
      if (gridapi.aggFuncService === 'cxpfpdev') {
        if (maestro[gridapi.getFocusedCell().rowIndex].TRNPAGOTIPO === 'EF' || maestro[gridapi.getFocusedCell().rowIndex].TRNPAGOTIPO === 'CH') { //GSRF
          this.busquedaCaja(maestro[gridapi.getFocusedCell().rowIndex], 'CAJCODIGOcxpfpdev', maestro[gridapi.getFocusedCell().rowIndex].CAJCODIGO);
        }
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'MAERUBRO_TIPO' && this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'nomMaeRubros') {
        this.busquedaRubros(maestro[gridapi.getFocusedCell().rowIndex], 'tiporubro', maestro[gridapi.getFocusedCell().rowIndex].MAERUBRO_TIPO);
      }
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'MAERUBRO_UNIDAD' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'nomMaeRubros') {
        this.busquedaRubrosUnida(maestro[gridapi.getFocusedCell().rowIndex], 'NomRubUnidad', maestro[gridapi.getFocusedCell().rowIndex].MAERUBRO_UNIDAD);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CON_CODIGO_2' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'nomMaeRubros') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'NomRubConCodi2', maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO_2);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CON_CODIGO_3' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'nomMaeRubros') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'NomRubConCodi3', maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO_3);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CON_CODIGO_4' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'nomMaeRubros') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'NomRubConCodi4', maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO_4);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CON_CODIGO_5' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'nomMaeRubros') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'NomRubConCodi5', maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO_5);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CON_CODIGO_6' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'nomMaeRubros') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'NomRubConCodi6', maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO_6);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CON_CODIGO_7' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'nomMaeRubros') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'NomRubConCodi7', maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGO_7);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CON_CODIGOHABE' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'nomMaeRubros') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'NomRubConCodHab', maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGOHABE);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CON_CODIGOHABE_2' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'nomMaeRubros') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'NomRubConCodHab2', maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGOHABE_2);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CON_CODIGOHABE_3' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'nomMaeRubros') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'NomRubConCodHab3', maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGOHABE_3);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CON_CODIGOHABE_4' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'nomMaeRubros') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'NomRubConCodHab4', maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGOHABE_4);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CON_CODIGOHABE_5' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'nomMaeRubros') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'NomRubConCodHab5', maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGOHABE_5);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CON_CODIGOHABE_6' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'nomMaeRubros') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'NomRubConCodHab6', maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGOHABE_6);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CON_CODIGOHABE_7' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'nomMaeRubros') {
        this.busquedaCON(maestro[gridapi.getFocusedCell().rowIndex], 'NomRubConCodHab7', maestro[gridapi.getFocusedCell().rowIndex].CON_CODIGOHABE_7);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'MAERUBRO_CODIGO2' &&
      this.init.auxsalto === false) {
      console.log('entra aqui')
      if (gridapi.aggFuncService === 'nomMaeIngrFij') {
        this.busquedaRubroCod(maestro[gridapi.getFocusedCell().rowIndex], 'ingrRUBRO_CODIGO',
          maestro[gridapi.getFocusedCell().rowIndex].MAERUBRO_CODIGO);
      }
      if (gridapi.aggFuncService === 'nomMaeEgrFij') {
        this.busquedaEgreRubroCod(maestro[gridapi.getFocusedCell().rowIndex], 'egreRUBRO_CODIGO',
          maestro[gridapi.getFocusedCell().rowIndex].MAERUBRO_CODIGO);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'EMPCARGFAMI_TIPOIDE' &&
      this.init.auxsalto === false) {

      if (gridapi.aggFuncService === 'nomMaeCargaFami') {
        this.busquedaTipoID(maestro[gridapi.getFocusedCell().rowIndex], 'cargaFAMI_ID',
          maestro[gridapi.getFocusedCell().rowIndex].EMPCARGFAMI_TIPOIDE);
      }

    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'EMPCARGFAMI_SEXO' &&
      this.init.auxsalto === false) {

      if (gridapi.aggFuncService === 'nomMaeCargaFami') {
        this.busquedaAGSexo(maestro[gridapi.getFocusedCell().rowIndex], 'cargafaSEXO',
          maestro[gridapi.getFocusedCell().rowIndex].EMPCARGFAMI_SEXO);
      }

    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'EMPCARGFAMI_TIPORELA2' &&
      this.init.auxsalto === false) {

      if (gridapi.aggFuncService === 'nomMaeCargaFami') {
        this.busquedaAGTipoRela(maestro[gridapi.getFocusedCell().rowIndex], 'cargafaTIPORELA',
          maestro[gridapi.getFocusedCell().rowIndex].EMPCARGFAMI_TIPORELA2);
      }

    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'EMPCARGFAMI_ESTATUS' &&
      this.init.auxsalto === false) {

      if (gridapi.aggFuncService === 'nomMaeCargaFami') {
        this.busquedaAGESTATUS(maestro[gridapi.getFocusedCell().rowIndex], 'cargafaESTATUS',
          maestro[gridapi.getFocusedCell().rowIndex].EMPCARGFAMI_ESTATUS);
      }

    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'TRANVACAEMPL_TIPO' &&
      this.init.auxsalto === false) {

      if (gridapi.aggFuncService === 'nomMaeCrudVaca') {
        this.busquedaTipoVac(maestro[gridapi.getFocusedCell().rowIndex], 'valorcrudVaca',
          maestro[gridapi.getFocusedCell().rowIndex].TRANVACAEMPL_TIPO);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'TRANVACAEMPL_ESTADO' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'nomMaeCrudVaca') {
        this.busquedaVacSta(maestro[gridapi.getFocusedCell().rowIndex], 'valorcrudVacEst',
          maestro[gridapi.getFocusedCell().rowIndex].TRANVACAEMPL_ESTADO);
      }
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'SER_CODIGO' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'servicioped') {
        this.busquedaservicioPedido(maestro[gridapi.getFocusedCell().rowIndex], 'busquedavenserped', maestro[gridapi.getFocusedCell().rowIndex].SER_CODIGO);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'EMP_CODIGO' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'nomCabimpurent') {
        this.busquedaEmpleado(maestro[gridapi.getFocusedCell().rowIndex], 'empcodigocabi',
          maestro[gridapi.getFocusedCell().rowIndex].EMP_CODIGO);
      }
      if (gridapi.aggFuncService === 'nomTrannovedad') {
        this.busquedaEmpleado(maestro[gridapi.getFocusedCell().rowIndex], 'emptrannoved',
          maestro[gridapi.getFocusedCell().rowIndex].EMP_CODIGO);
      }
      if (gridapi.aggFuncService === 'trnhorashombre') {
        this.busquedaEmpleado(maestro[gridapi.getFocusedCell().rowIndex], 'emptrnhorashombre',
          maestro[gridapi.getFocusedCell().rowIndex].EMP_CODIGO);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'MAERUBRO_CODRUBR' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'nomTrannovedad') {
        this.busquedaRuboNovedad(maestro[gridapi.getFocusedCell().rowIndex], 'rubcodnoved',
          maestro[gridapi.getFocusedCell().rowIndex].MAERUBRO_CODRUBR);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'CIN_CODIGO_1' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'prodcostfech') {
        this.busquedaCostFecha(maestro[gridapi.getFocusedCell().rowIndex], 'costfecha',
          maestro[gridapi.getFocusedCell().rowIndex].CIN_CODIGO_1);
      }

    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'NOM_TIPOPAGOUTIL' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'nomutilidades') {
        this.busquedaTiposUtilidades(maestro[gridapi.getFocusedCell().rowIndex], 'NOM_TIPOPAGOUTIL',
          maestro[gridapi.getFocusedCell().rowIndex].NOM_TIPOPAGOUTIL);
      }

    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'NOM_TIPOPAGO_SALDIG' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'nomutilidades') {
        this.busquedaTiposUtilidades(maestro[gridapi.getFocusedCell().rowIndex], 'NOM_TIPOPAGO_SALDIG',
          maestro[gridapi.getFocusedCell().rowIndex].NOM_TIPOPAGO_SALDIG);
      }

    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'NOM_FORMPAGOCOD' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'nomutilidades') {
        this.busquedaTiposUtilidades(maestro[gridapi.getFocusedCell().rowIndex], 'NOM_FORMPAGOCOD',
          maestro[gridapi.getFocusedCell().rowIndex].NOM_FORMPAGOCOD);
      }

    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'NOM_BANCPAGOCOD' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'nomutilidades') {
        this.busquedaUtilidadesBancos(maestro[gridapi.getFocusedCell().rowIndex], 'NOM_BANCPAGOCOD',
          maestro[gridapi.getFocusedCell().rowIndex].NOM_BANCPAGOCOD);
      }

    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'NOM_TIPOCUENCOD' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'nomutilidades') {
        this.busquedaTiposUtilidades(maestro[gridapi.getFocusedCell().rowIndex], 'NOM_TIPOCUENCOD',
          maestro[gridapi.getFocusedCell().rowIndex].NOM_TIPOCUENCOD);
      }
    }


    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'ENCPED_NUMERO' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'trnpedidos') {
        this.busquedaPedidos(maestro[gridapi.getFocusedCell().rowIndex], 'busquedapedidosprod',
          maestro[gridapi.getFocusedCell().rowIndex].ENCPED_NUMERO);
      }
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'TRNTEC_CODIGO' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'trntecnico') {
        this.busquedaTecnico(maestro[gridapi.getFocusedCell().rowIndex], 'busquedatecnicosprod',
          maestro[gridapi.getFocusedCell().rowIndex].TRNTEC_CODIGO);
      }
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'TRNMAQ_CODIGO' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'trnmaquinaria') {
        this.busquedaMaquinaria(maestro[gridapi.getFocusedCell().rowIndex], 'busquedamaquinariaprod',
          maestro[gridapi.getFocusedCell().rowIndex].ENCPED_NUMERO);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'DETLIQUBENESOCI_FORMPAGODESC' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'nomliqdeceterc') {
        this.busquedaFormaPagoLiq(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaFormaPagoLiq',
          maestro[gridapi.getFocusedCell().rowIndex].DETLIQUBENESOCI_FORMPAGODESC);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'DETLIQUBENESOCI_BANCPAGODESC' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'nomliqdeceterc') {
        this.busquedaBancoLiq(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaBancoLiq',
          maestro[gridapi.getFocusedCell().rowIndex].DETLIQUBENESOCI_BANCPAGODESC);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'DETLIQUBENESOCI_TIPOCUENDESC' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'nomliqdeceterc') {
        this.busquedaNumCuenLiq(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaNumeCueLiq',
          maestro[gridapi.getFocusedCell().rowIndex].DETLIQUBENESOCI_TIPOCUENDESC);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'TRNLOTE_LOTE' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'trnloteprod') {
        this.busquedalotesMP(maestro[gridapi.getFocusedCell().rowIndex], 'busquedalotestrnlote',
          maestro[gridapi.getFocusedCell().rowIndex].TRNLOTE_LOTE);
      }
    }
    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'ETI_CODIGO' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'maeequipodet') {
        this.busquedaEtiqueta(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaetiqueta',
          maestro[gridapi.getFocusedCell().rowIndex].ETI_CODIGO);
      }
      if (gridapi.aggFuncService === 'tecdetmant') {
        this.busquedaEtiqueta(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaetiquetadetmant',
          maestro[gridapi.getFocusedCell().rowIndex].ETI_CODIGO);
      }
    }

    if (e.key === 'Enter' &&
      gridapi.getFocusedCell().column.colId === 'VEN_CODIGO' &&
      this.init.auxsalto === false) {
      if (gridapi.aggFuncService === 'registrollamadas') {
        this.busquedavendedor(maestro[gridapi.getFocusedCell().rowIndex], 'busquedavendedorreglla',
          maestro[gridapi.getFocusedCell().rowIndex].VEN_CODIGO);
      }
      if (gridapi.aggFuncService === 'enviocorreos') {
        this.busquedavendedor(maestro[gridapi.getFocusedCell().rowIndex], 'busquedavendedorenvcor',
          maestro[gridapi.getFocusedCell().rowIndex].VEN_CODIGO);
      }
    }

  }


  seleccion(params) {
    //GSRF
    this.rolProcesoSrv.numcellnomencrol=this.gridApi.getFocusedCell().rowIndex;
    this.encfacproService.numcellfacpro=this.gridApi.getFocusedCell().rowIndex;
    this.encfacproService.idcellfacpro=this.gridApi.getFocusedCell().column.colId;
    this.comDevFacService.idcelldevfacpro=this.gridApi.getFocusedCell().column.colId;
    this.venDevFacService.idcelldevfac=this.gridApi.getFocusedCell().rowIndex;
    console.log('selecionado--')
    console.log('***>local', this.gridApi.getFocusedCell().column.colId )
    console.log('***>envia ', this.idcell )
    console.log('***>local', this.gridApi.getFocusedCell().rowIndex )
    console.log('***>envia ', this.rowcell )
    if(this.idcell===''){
      this.idcell=this.gridApi.getFocusedCell().column.colId;
    }
    if(this.rowcell ===''){
      this.rowcell = this.gridApi.getFocusedCell().rowIndex;
    }
    
    if(this.gridApi.getFocusedCell().column.colId!==this.idcell ){
      window.alert('Presione enter para terminar edición de la celda')
      this.gridApi.setFocusedCell(this.rowcell, this.idcell);
      this.gridApi.startEditingCell({rowIndex: this.rowcell, colKey: this.idcell});
      this.idcell='';
      this.rowcell='';
      return;
      
    }
    if( this.gridApi.getFocusedCell().rowIndex !== this.rowcell ){
      window.alert('Presione enter para terminar edición de la celda')
       this.gridApi.setFocusedCell(this.rowcell, this.idcell);
      this.gridApi.startEditingCell({rowIndex: this.rowcell, colKey: this.idcell});
      this.idcell='';
      this.rowcell='';
      return;
      
    }
    this.idcell='';
    this.rowcell='';  
    //GSRF
    this.objetoSeleccionado = this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].getSelectedRows()[0];
    this.selected.emit({
      object: this.objetoSeleccionado,
      nameobject: this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].aggFuncService
    });
    this.init.auxsalto = false;
    this.copytoclipboard(params.event.target.innerHTML);

  }

  copytoclipboard(texto) {

    if (texto === '' || texto === undefined) {
      return;
    }
    if (texto.includes('<prueba-cell')) {
      return;
    }

    navigator.clipboard.writeText(texto).then(() => {
    }).catch(err => {
      console.log('error clipboard', err);
    })
  }


  getParameter(campo, parametro, columnDef) {
    for (const u of columnDef) {
      if (u.field === campo) {
        if (u.cellEditorParams !== undefined) {
          if (u.cellEditorParams.values !== undefined) {
            return u.cellEditorParams.values[parametro];
          }
        }
      }
    }
  }

  abrirDialogBusqueda(consulta, tabla, condicion) {
    this.busqService.busquedaTabla(consulta, tabla, condicion).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
      this.encfacproService.spin=false;//CDPJ
      this.cxptrncobroService.spin=false;//CDPJ
      this.encfacService.spin=false;//GSRF 
      this.artService.spin=false;//GSRF
    });
  }

  busquedaunidad(rowData, opcionbusqueda) {
    switch (opcionbusqueda) {
      case 'busquedaunidadprecio':
        this.indexunidad = this.artService.maeartprecio.findIndex(precio => precio.CLAVE === rowData.CLAVE);
        break;
      case 'busquedaunidadparte':
        this.indexunidad = this.artService.maeartparte.findIndex(parte => parte.ART_CODIGOP === rowData.ART_CODIGOP);
        break;
      case 'busquedaunidadformula':
        this.indexunidad = this.artService.maeartformula.findIndex(formula => formula.ART_CODIGOP === rowData.ART_CODIGOP);
        break;
      case 'UNI_CODIGODESTINO':
        this.indexunidad = this.unidadService.maeequivalencias.findIndex(equi => equi.EQU_FACTOR === rowData.EQU_FACTOR &&
          equi.EQU_CANTIDAD === rowData.EQU_CANTIDAD && equi.UNI_CODIGO === rowData.UNI_CODIGO);
        break;
      default:
        this.indexunidad = 0;
    }

    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'UNI_CODIGO' },
      { label: 'Nombre', value: 'UNI_NOMBRE' },
      { label: 'Símbolo', value: 'UNI_SIMBOLO' }
    ];

    this.busquedacampos = ['', '', ''];
    this.consulta = '*';
    this.tabla = 'INV_MAEUNIDAD';
    this.where = '';

    this.abrirDialogBusqueda('*', 'INV_MAEUNIDAD', '');
  }

  busquedanombreRetencion(rowData, opcionbusqueda, parametro) {
    let codigo;
    switch (opcionbusqueda) {
      case 'busquedaNombre':
        this.indexunidad = this.proveedorServicio.commaeproveedorRt.findIndex(retencion => retencion.LINEA === rowData.LINEA);
        codigo = rowData.RETENCION_CODIGO;
        break;

      default:
        this.indexunidad = 0;
    }

    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'RETENCION_CODIGO' },
      { label: 'Nombre', value: 'RETENCION_NOMBRE' },
      { label: 'Porcentaje', value: 'RETENCION_PORCENTAJE' },
      { label: 'CTACOMPRAS', value: 'CON_CODIGO' },
      { label: 'CTAVTAS', value: 'CON_CODIGO_VTA' },
      { label: 'Tipo retención', value: 'RETENCION_TIPRET' },

    ];
    this.busquedacampos = [parametro, '', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'saci_maeretencion';
    this.where = '';

    this.busqService.encontrarRegistro2(codigo).subscribe(eR => {
      if (eR !== null) {
        this.manejarSeleccion(eR[0]);
      } else {
        this.abrirDialogBusqueda('*', 'SACI_MAERETENCION', this.types[0].value + ' LIKE \'%' + parametro +
          '%\'');
      }
    });
  }

  busquedaAnticiposCli(rowData, opcionbusqueda, parametro) {
    let v_sentencia = 'TRNCOBRO_NROCOMPROBANTE,TRNCOBRO_NRODOC,TRNCOBRO_FECHATRN,TRNCOBRO_IMPORTE,';
    v_sentencia = v_sentencia + 'VEN_FNC_SALDOFACTURA(COM_CODIGO, TRNCOBRO_NRODOC) * (-1) AS SALDO,TRNCOBRO_CONCEPTO,';
    v_sentencia = v_sentencia + 'CLI_CODIGO,COM_CODIGO,TRNCOBRO_TIPODOC';

    switch (opcionbusqueda) {
      case 'cxcformapagoANC':
        this.indexunidad = this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice].findIndex(forma => forma.LINEA === rowData.LINEA);
        break;
      case 'pagconFormaPagoAnc':
        this.indexunidad = this.pagconservice.pagos.findIndex(caja => caja.CLAVE === rowData.CLAVE);
        break;
        //CDPJ
        case 'cxctrnabonoformapagoANC':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
      case 'cxctrncancelacionformapagoANC':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      break;
      // case 'cxctrnanticipoformapagoANC':
      //   this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      // break;
        //CDPJ
      default:
        this.indexunidad = 0;
    }

    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Comprobante', value: 'TRNCOBRO_NROCOMPROBANTE' },
      { label: 'Nro Doc', value: 'TRNCOBRO_NRODOC' },
      { label: 'Fecha', value: 'TRNCOBRO_FECHATRN' },
      { label: 'Importe', value: 'TRNCOBRO_IMPORTE' },
      { label: 'Saldo', value: 'SALDO' },
      { label: 'Concepto', value: 'TRNCOBRO_CONCEPTO' },

    ];
    this.busquedacampos = ['', '', '', '', '', ''];
    this.consulta = v_sentencia;
    this.tabla = 'CXC_TRNCOBRO';
    this.where = 'TRNCOBRO_TIPODOC = \'ANC\' AND VEN_FNC_SALDOFACTURA(COM_CODIGO, TRNCOBRO_NRODOC) <> 0 '
      + ' AND CLI_CODIGO = \'' + parametro + '\'';


    this.busqService.busquedaCXCTrnCobro4(parametro).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.arregloCons.map((registro) => {
        if (registro.TRNCOBRO_FECHATRN !== null) {
          registro.TRNCOBRO_FECHATRN = this.datePipe.transform(registro.TRNCOBRO_FECHATRN, 'dd/MM/yyyy');
        } else {
          registro.TRNCOBRO_FECHATRN = '';
        }
      });
      this.displayDialogBusquedaFast = true;
    });
  }

  busquedacomision(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'comisionCod':
        this.indexunidad = this.cxcCarteraService.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice].findIndex(comision => comision.LINEA === rowData.LINEA);
        break;
      //CDPJ
      case 'cxctrnabonootrosgastos':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
      case 'cxctrncancelacionotrosgastos':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      break;
      //CDPJ
      default:
        this.indexunidad = 0;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'COMISION_CODIGO' },
      { label: 'Nombre', value: 'COMISION_NOMBRE' },
      { label: 'Cta Contable', value: 'CON_CODIGO' },
    ];
    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.tabla = 'SACI_MAECOMISION';
    this.where = '';

    this.abrirDialogBusqueda('*', 'SACI_MAECOMISION', this.types[0].value + ' LIKE \'%' + parametro +
      '%\'');

  }

  busquedaFormaPago(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'busquedaNombresri':
        this.indexunidad = this.proveedorServicio.commaeproveedorFP.findIndex(forma => forma.LINEA === rowData.LINEA);
        this.where = 'CODSRI_TIPO=\'FP\'';
        break;
      case 'busquedafpdatoanexos':
        this.indexunidad = this.datosanexService.formapago.findIndex(fp => fp.CLAVE === rowData.CLAVE);
        this.where = 'CODSRI_TIPO=\'FP\' AND CODSRI_CODIGO IN (\'01\',\'20\',\'19\')';
        break;
      default:
        this.indexunidad = 0;
    }

    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = '*';
    this.tabla = 'ANEX_MAECODSRI';
    // this.where = '';

    this.busqService.encontrarRegistro3(this.where, parametro).subscribe(eR => {
      if (eR !== null) {
        this.manejarSeleccion(eR[0]);
      } else {
        this.abrirDialogBusqueda('*', 'ANEX_MAECODSRI', this.where + ' AND ' +
          this.types[0].value + ' LIKE \'%' + parametro + '%\'');
      }
    });


  }

  busquedaservicioPedido(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'busquedaPedidoServicio':
        this.indexunidad = this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice].findIndex(pedido => pedido.LINEA === rowData.LINEA);
        break;
      case 'busquedaOrdenServicio':
        //this.indexunidad = this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice].findIndex(orden => orden.LINEA === rowData.LINEA);
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex; //GSRF
        break;
      case 'busquedaGuiaServicio':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
      case 'busquedaDvfServicio':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
      case 'busquedaDevServicioCom':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
      case 'busquedavenserped':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
      default:
        this.indexunidad = 0;
    }

    this.opcionbusqueda = opcionbusqueda;
    if (this.opcionbusqueda === 'busquedaDevServicioCom' || this.opcionbusqueda === 'busquedaOrdenServicio') {
      this.types = [
        { label: 'Código', value: 'SERCOM_CODIGO' },
        { label: 'Descripción', value: 'SERCOM_DESCRIPCION' },
        { label: 'Código Contable', value: 'CON_CODIGO' },
        { label: 'Precio', value: 'SERCOM_PRECIO' },
        { label: 'IVA', value: 'SERCOM_TRIBUTAIVA' }
      ];
      this.busquedacampos = [parametro, '', '', '', ''];
      this.consulta = '*';
      this.tabla = 'COM_MAESERVICIO';
      this.where = 'SERCOM_CODIGO IS NOT NULL ORDER BY SERCOM_CODIGO asc';

      this.busqService.encontrarRegistro4(parametro).subscribe(eR => {
        if (eR !== null) {
          if (eR[0] !== undefined) {
            this.manejarSeleccion(eR[0]);
          } else {
            this.abrirDialogBusqueda('*', 'COM_MAESERVICIO', 'UPPER(' + this.types[0].value + ') LIKE UPPER(\'%' + parametro + '%\') and SERCOM_CODIGO IS NOT NULL ORDER BY SERCOM_CODIGO asc');
          }
        } else {
          this.abrirDialogBusqueda('*', 'COM_MAESERVICIO', 'UPPER(' + this.types[0].value + ') LIKE UPPER(\'%' + parametro + '%\') and SERCOM_CODIGO IS NOT NULL ORDER BY SERCOM_CODIGO asc');
        }
      });
    } else {
      this.types = [
        { label: 'Código', value: 'SER_CODIGO' },
        { label: 'Descripción', value: 'SER_DESCRIPCION' },
        { label: 'Código Contable', value: 'CON_CODIGO' },
        { label: 'Precio', value: 'SER_PRECIO' },
        { label: 'IVA', value: 'SER_TRIBUTAIVA' }
      ];
      this.busquedacampos = [parametro, '', '', '', ''];
      this.consulta = '*';
      this.tabla = 'VEN_MAESERVICIO';
      this.where = '';

      this.busqService.encontrarRegistro5(parametro).subscribe(eR => {
        if (eR !== null) {
          if (eR[0] !== undefined) {
            this.manejarSeleccion(eR[0]);
          } else {
            this.abrirDialogBusqueda('*', 'VEN_MAESERVICIO', this.types[0].value + ' LIKE \'%' + parametro + '%\'');
          }
        } else {
          this.abrirDialogBusqueda('*', 'VEN_MAESERVICIO', this.types[0].value + ' LIKE \'%' + parametro + '%\'');
        }
      });
    }
  }

  busquedaserviciopro(rowData, opcionbusqueda, parametro) {
    this.opcionbusqueda = opcionbusqueda;

    switch (opcionbusqueda) {
      case 'busquedaPromocionServicio':
        this.indexunidad = this.ServicioPromo.venmaepromo.findIndex(promo => promo.MAEPRM_NOMBRE === rowData.MAEPRM_NOMBRE);
        break;
      case 'busquedaPromocionServicio2':
        this.indexunidad = this.ServicioPromo.venmaepromo.findIndex(promo => promo.MAEPRM_NOMBRE === rowData.MAEPRM_NOMBRE);
        break;
      case 'busquedaDescuentoServicio':
        this.indexunidad = this.descuentoService.venmaedescuentos.findIndex(promo => promo.MAEDSCTO_NOMBRE === rowData.MAEDSCTO_NOMBRE);
        break;
      case 'busquedaserviciodetfacpro':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
       //GSRF
      case 'busquedaserviciodetfacprocodalt':
          this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'busquedaserviciodetfachomo':
          this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      break;
        //GSRF
      case 'busquedaservicioNotRec':
        this.indexunidad = this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].findIndex(detalle => detalle.LINEA === rowData.LINEA);
        break;
      default:
        this.indexunidad = 0;
    }

    this.types = [
      { label: 'Código', value: 'SERCOM_CODIGO' },
      { label: 'Descripción', value: 'SERCOM_DESCRIPCION' },
      { label: 'Código Contable', value: 'CON_CODIGO' },
      { label: 'Precio', value: 'SERCOM_PRECIO' },
      { label: 'IVA', value: 'SERCOM_TRIBUTAIVA' },
      { label: 'Centro de Costos', value: 'CEN_CODIGO' },
      { label: 'Form SRI', value: 'SER_FORMSRICOM' },
      { label: 'Código Contable 2', value: 'CON_CODIGO2' },
      { label: 'BASENOOBJIVA', value: 'SERCOM_BASENOOBJIVA' }
    ];
    if (parametro === null || parametro === undefined) {
      parametro = '';
    }

    this.busquedacampos = [parametro, '', '', '', '', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'COM_MAESERVICIO';
    this.where = 'SERCOM_CODIGO IS NOT NULL ORDER BY 1';

    this.busqService.encontrarRegistro6(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda('*', 'COM_MAESERVICIO', this.types[0].value + ' LIKE \'%' + parametro + '%\' ORDER BY 1');
        }
      } else {
        this.abrirDialogBusqueda('*', 'COM_MAESERVICIO', this.types[0].value + ' LIKE \'%' + parametro + '%\' ORDER BY 1');
      }
    });
  }

  busquedagrupoPro(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'busquedaGrupoPromocion':
        this.indexunidad = this.ServicioPromo.venmaepromo.findIndex(promo => promo.MAEPRM_NOMBRE === rowData.MAEPRM_NOMBRE);
        break;
      default:
        this.indexunidad = 0;
    }

    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'GRUP_CODIGO' },
      { label: 'Nombre', value: 'GRUP_NOMBRE' },
      { label: 'Ref Contable', value: 'GRUP_CODIGOP' },
      { label: 'Cen Costos', value: 'CEN_CODIGO' },
      { label: 'Valor1', value: 'GRUP_VALOR1' },
      { label: 'Valor2', value: 'GRUP_VALOR2' },
      { label: 'Valor3', value: 'GRUP_VALOR3' },
      { label: 'Valor4', value: 'GRUP_VALOR4' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'INV_MAEGRUPO';
    this.where = '';

    this.abrirDialogBusqueda('*', 'INV_MAEGRUPO', this.types[0].value + ' LIKE \'%' + parametro + '%\'');
  }


  busquedatipogastos(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'busquedatipogastos':
        //this.indexunidad = this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice].findIndex(detfacpro => detfacpro.CLAVE === rowData.CLAVE);
          //GSRF
        console.log('Aqui gastos');
        console.log('0>',this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice].findIndex(detfacpro => detfacpro.CLAVE));
        console.log('1>',rowData.DETFACPRO_LINEA);
        console.log('1.1>',rowData.LINEA);
        console.log('1.2>',rowData.CLAVE);
        console.log('2>',this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice].findIndex(detfacpro => detfacpro.CLAVE === rowData.CLAVE));
        if(rowData.CLAVE===undefined){
          this.indexunidad = rowData.DETFACPRO_LINEA -1;
        }else{
          this.indexunidad = this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice].findIndex(detfacpro => detfacpro.CLAVE === rowData.CLAVE); 
        }
        //GSRF
        break;
      case 'busquedatipogastosdistricc':
        this.indexunidad = this.distriCCService.distribucionCC.findIndex(distri => distri.CLAVE === rowData.CLAVE);
        break;
      case 'busquedatipogastoscxpvarios':
        this.indexunidad = this.cxptrnpagodocService.varios.findIndex(cxpvarios => cxpvarios.CLAVE === rowData.CLAVE);
        break;
      case 'tgcxpvarios':
        this.indexunidad = this.cxptrncobroService.variosarray[this.init.tabs[this.init.tabindex].indice].findIndex(varios => varios.CLAVE === rowData.CLAVE);
        break;
        //CDPJ
        case 'busquedatipogastosDev':
          this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
      //CDPJ
      default:
        this.indexunidad = 0;
    }

    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'TIPGAS_CODIGO' },
      { label: 'Nombre', value: 'TIPGAS_NOMBRE' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'CON_MAETIPGAS';
    this.where = '';

    this.abrirDialogBusqueda('*', 'CON_MAETIPGAS', this.types[0].value + ' LIKE \'%' + parametro + '%\'');
  }

  busquedacargos(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'busquedacargos':
        this.indexunidad = this.encfacproService.trncargosarray[this.init.tabs[this.init.tabindex].indice].findIndex(cargos => cargos.CLAVE === rowData.CLAVE);
        break;
      default:
        this.indexunidad = 0;
    }

    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Cód.', value: 'CARGO_CODIGO' },
      { label: 'Nombre', value: 'CARGO_NOMBRE' },
      { label: 'Cta.Contable', value: 'CON_CODIGO' },
      { label: 'Centro de Costos', value: 'CEN_CODIGO' },
    ];
    this.busquedacampos = [parametro, '', '', ''];
    this.consulta = '*';
    this.tabla = 'COM_MAECARGO';
    this.where = '';

    this.abrirDialogBusqueda('*', 'COM_MAECARGO', this.types[0].value + ' LIKE \'%' + parametro + '%\'');
  }

  busquedaarticulodetalle(rowData, opcionbusqueda, parametro) {
    this.opcionbusqueda = opcionbusqueda;
    switch (opcionbusqueda) {
      case 'busquedaarticuloparte':
        this.artService.spin=true;//GSRF
        this.indexunidad = this.artService.maeartparte.findIndex(parte => parte.ART_CODIGOP === rowData.ART_CODIGOP);
        break;
      case 'busquedaarticuloformula':
        this.artService.spin=true;//GSRF
        this.indexunidad = this.artService.maeartformula.findIndex(formula => formula.ART_CODIGOP === rowData.ART_CODIGOP);
        break;
      case 'busquedaPromocion':
        this.indexunidad = this.ServicioPromo.venmaepromo.findIndex(promocion => promocion.MAEPRM_NOMBRE === rowData.MAEPRM_NOMBRE);
        break;
      case 'busquedaPromocion2':
        this.indexunidad = this.ServicioPromo.venmaepromo.findIndex(promocion => promocion.MAEPRM_NOMBRE === rowData.MAEPRM_NOMBRE);
        break;
      case 'busquedaDescuento':
        this.indexunidad = this.descuentoService.venmaedescuentos.findIndex(promocion => promocion.MAEDSCTO_NOMBRE === rowData.MAEDSCTO_NOMBRE);
        break;
      case 'artmaelote':
        this.indexunidad = this.maeloteservice.detmaelote.findIndex(lote => lote.CLAVE === rowData.CLAVE);
        break;
      case 'busquedaarticulodetfacpro':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex
        break;
      //GSRF
      case 'busquedaarticulodetfacproajuste':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
      case 'busquedaarticulonotrecajuste':
          this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'busquedaarticulodetfaccodalt':
          this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'busquedaarticulodetfachomo':
          this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      break;
      //GSRF
      case 'ARTCODIGO_AJUSTE':
        this.indexunidad = this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice].findIndex(item => item.NUMERO === rowData.NUMERO);
        break;
      case 'ARTCODIGO_AJUSTENEG':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;      
      case 'busquedaarticulodetordtra':
        this.indexunidad = this.encordtraService.tableDetalle[this.init.tabs[this.init.tabindex].indice].findIndex(item => item.CLAVE === rowData.CLAVE);
        break;
      default:
        this.indexunidad = 0;
    }

    this.types = [
      { label: 'Código', value: 'ART_CODIGO' },
      { label: 'Nombre', value: 'ART_NOMBRE' },
      { label: 'Grupo', value: 'GRUP_CODIGO' },
      { label: 'Alias', value: 'ART_NOMBREC' },
      { label: 'Laboratorio', value: 'ART_LABORATORIO' },
      { label: 'Bod-Def', value: 'ART_UBICACION' },
      { label: 'CodigoBarras', value: 'ART_CODIGOALT1' },
      { label: 'Ubicación Física', value: 'ART_CODIGOALT2' },
      { label: 'Alterno3', value: 'ART_CODIGOALT3' },
    ];
    if (parametro === null || parametro === undefined) {
      parametro = '';
    }

    this.busquedacampos = [parametro, '', '', '', '', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'INV_MAEARTICULO';
    this.where = 'ART_ACTIVO=\'S\' order by 1';

    this.busqService.encontrarRegistro7(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
          this.encfacproService.spin=false;//CDPJ
          this.artService.spin=false;//GSRF
        } else {
          this.abrirDialogBusqueda('*', 'INV_MAEARTICULO', 'rownum <= 100 AND '
            + 'ART_ACTIVO=\'S\'' + ' AND ' +
            this.types[0].value + ' LIKE \'%' + parametro + '%\' order by 1');
        }
      } else {
        this.abrirDialogBusqueda('*', 'INV_MAEARTICULO', 'rownum <= 100 AND '
          + 'ART_ACTIVO=\'S\'' + ' AND '
          + this.types[0].value + ' LIKE \'%' + parametro + '%\' order by 1');
      }
    });
  }

  busquedaarticuloreserva(rowData, opcionbusqueda, parametro) {
    this.opcionbusqueda = opcionbusqueda;
    switch (opcionbusqueda) {
      case 'ARTCODIGO_RESERVA':
        this.indexunidad = this.invreservaService.detalleReservas[this.init.tabs[this.init.tabindex].indice].findIndex(item => item.LINEA === rowData.LINEA
          && item.TRNRES_NUMERO === rowData.TRNRES_NUMERO);
        break;
      default:
        this.indexunidad = 0;
    }

    this.types = [
      { label: 'CÓDIGO', value: 'ART_CODIGO' },
      { label: 'NOMBRE', value: 'ART_NOMBRE' },
      { label: 'UNIDAD', value: 'ART_UNIDADCOSTEO' },
      { label: 'EXISTENCIA', value: 'EXISTENCIA' },
      { label: 'PRECIO', value: 'PRECIO' },
      { label: 'CÓD:GRUPO', value: 'GRUP_CODIGO' },
      { label: 'NOMBRE GRUPO', value: 'GRUP_NOMBRE' },
      { label: 'CÓD.ALTERNO', value: 'ART_CODIGOALT1' },
      { label: 'UBICACIÓN', value: 'ART_UBICACION' }
    ];
    const consulta = 'ART_CODIGO, ART_NOMBRE, ART_UNIDADCOSTEO,' +
      ' INV_FNC_OBTENEREXISTARTCL_H(\'FAC\',ART_CODIGO,' + '\'' + this.invreservaService.detalleReservas[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO + '\'' + ',\'01\',' +
      ' TO_CHAR(SYSDATE,\'DD/MM/YYYY\'), TO_CHAR(SYSDATE,\'HH:MI\')) AS EXISTENCIA,' +
      ' GRUP_CODIGO, INV_FNC_OBTENER_NOMBRE_GRUPO(GRUP_CODIGO) AS GRUP_NOMBRE,' +
      ' ART_CODIGOALT1, ART_UBICACION,ART_TRIBUTAIVA, ART_TRIBUTAICE, ART_MULTIUNIDAD, ART_DESCUENTO';
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', ''];
    this.consulta = consulta;
    this.tabla = 'INV_MAEARTICULO';
    this.where = '';
    this.existencias=' INV_FNC_OBTENEREXISTARTCL_H(\'FAC\',ART_CODIGO,' + '\'' + this.invreservaService.detalleReservas[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO + '\'' + ',\'01\',' +' TO_CHAR(SYSDATE,\'DD/MM/YYYY\'), TO_CHAR(SYSDATE,\'HH:MI\')) '


    this.busqService.encontrarRegistro8(this.invreservaService.detalleReservas[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO, parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busqService.busquedaInvMaeArticulo6(this.invreservaService.detalleReservas[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO, parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaInvMaeArticulo6(this.invreservaService.detalleReservas[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO, parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedacomposicionquimica(rowData, opcionbusqueda, parametro) {
    this.opcionbusqueda = opcionbusqueda;
    switch (opcionbusqueda) {
      case 'TRNCOMQUI':
        this.indexunidad = this.artService.prodtrncomqui.findIndex(item => item.FILA === rowData.FILA
          && item.TRNCOMQUI_CODIGO === rowData.TRNCOMQUI_CODIGO);
        break;
      default:
        this.indexunidad = 0;
    }

    this.types = [
      { label: 'CÓDIGO', value: 'REF_CODIGO' },
      { label: 'NOMBRE CORTO', value: 'REF_NOMBRE' },
      { label: 'NOMBRE', value: 'REF_NOMBREC' },
    ];
    const consulta = '*';
    this.busquedacampos = [parametro, '', ''];
    this.consulta = consulta;
    this.tabla = 'saci_maereferencia';
    this.where = 'ref_tipo=\'COMQUI\' ';

    this.busqService.encontrarRegistro9(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busqService.busquedaSaciMaeReferencia8(parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaSaciMaeReferencia8(parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaprodaplicacion(rowData, opcionbusqueda, parametro) {
    this.opcionbusqueda = opcionbusqueda;
    switch (opcionbusqueda) {
      case 'TRNAPLI':
        this.indexunidad = this.artService.prodtrnaplicacion.findIndex(item => item.FILA === rowData.FILA
          && item.TRNAPLI_CODIGO === rowData.TRNAPLI_CODIGO);
        break;
      default:
        this.indexunidad = 0;
    }

    this.types = [
      { label: 'CÓDIGO', value: 'REF_CODIGO' },
      { label: 'NOMBRE CORTO', value: 'REF_NOMBRE' },
      { label: 'NOMBRE', value: 'REF_NOMBREC' },
    ];
    const consulta = '*';
    this.busquedacampos = [parametro, '', ''];
    this.consulta = consulta;
    this.tabla = 'saci_maereferencia';
    this.where = 'ref_tipo=\'APLPRO\' ';

    this.busqService.encontrarRegistro9(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busqService.busquedaSaciMaeReferencia9(parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaSaciMaeReferencia9(parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaactivosalida(rowData, opcionbusqueda, parametro) {
    this.opcionbusqueda = opcionbusqueda;
    switch (opcionbusqueda) {
      case 'ACTCODIGO':
        this.indexunidad = this.actencsalidaService.detSalidaArray[this.init.tabs[this.init.tabindex].indice].findIndex(item => item.LINEA === rowData.LINEA
          && item.DETSALIDA_NUMSEC === rowData.DETSALIDA_NUMSEC);
        break;
      default:
        this.indexunidad = 0;
    }

    this.types = [
      { label: 'CÓDIGO', value: 'ACT_CODIGO' },
      { label: 'NOMBRE', value: 'ACT_NOMBRE' },
    ];
    const consulta = '*';
    this.busquedacampos = [parametro, ''];
    this.consulta = consulta;
    this.tabla = 'act_maeactivo';
    this.where = 'nvl(act_estado,\'*\') <> \'3\' and ROWNUM < 100';

    this.busqService.encontrarRegistro51(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busqService.busquedaActMaeActivo2().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaActMaeActivo2().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaserviciodetalle(rowData, opcionbusqueda, parametro) {
    this.opcionbusqueda = opcionbusqueda;
    switch (opcionbusqueda) {
      case 'busquedaserviciodetfac':
        this.encfacService.spin=true;//GSRF
        this.indexunidad = this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice].findIndex(detfac => detfac.DETFAC_LINEA === rowData.DETFAC_LINEA);
        break;
      case 'busquedaserviciodetordtra':
        this.indexunidad = this.encordtraService.tableDetalle[this.init.tabs[this.init.tabindex].indice].findIndex(item => item.CLAVE === rowData.CLAVE);
        break;
      default:
        this.indexunidad = 0;
    }

    this.types = [
      { label: 'Código', value: 'SER_CODIGO' },
      { label: 'Nombre', value: 'SER_DESCRIPCION' },
      { label: 'Cuenta Contable', value: 'CON_CODIGO' },
      { label: 'Precio', value: 'SER_PRECIO' },
      { label: 'Tributa IVA', value: 'SER_TRIBUTAIVA' },
    ];
    this.busquedacampos = [parametro, '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'VEN_MAESERVICIO';
    this.where = '';

    this.busqService.encontrarRegistro5(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
          this.encfacService.spin=false;//GSRF
        } else {
          this.abrirDialogBusqueda('*', this.tabla, '' + this.types[0].value + ' LIKE \'%' + parametro + '%\'');
        }
      } else {
        this.abrirDialogBusqueda('*', this.tabla, '' + this.types[0].value + ' LIKE \'%' + parametro + '%\'');
      }
    });
  }

  busquedaarticulopedido(rowData, opcionbusqueda, parametro) {
    this.opcionbusqueda = opcionbusqueda;
    switch (opcionbusqueda) {
      case 'busquedaPedido':
        console.log('++--', this.gridApi.getFocusedCell().rowIndex);//GSRF
        // this.indexunidad = this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice].findIndex(pedido => pedido.DETPED_LINEA === rowData.DETPED_LINEA
        //   && pedido.LINEA === rowData.LINEA);
          this.indexunidad = this.gridApi.getFocusedCell().rowIndex;//GSRF
        break;
      default:
        this.indexunidad = 0;
    }

    this.types = [
      { label: 'Código', value: 'ART_CODIGO' },
      { label: 'Nombre', value: 'ART_NOMBRE' },
      { label: 'Unidad', value: 'ART_UNIDADVENTA' },
      { label: 'Alias', value: 'ART_NOMBREC' },
      { label: 'Existencia', value: 'EXISTENCIA' },
      { label: 'Precio', value: 'PRECIO' },
      { label: 'Cód.Grupo', value: 'GRUP_CODIGO' },
      { label: 'Nombre Grupo', value: 'GRUP_NOMBRE' },
      { label: 'CodigoBarras', value: 'ART_CODIGOALT1' },
      { label: 'Bod-Def', value: 'ART_UBICACION' }
    ];
    const consulta = 'ART_CODIGO, ART_NOMBRE, ART_NOMBREC, ART_UNIDADVENTA,' +
      ' INV_FNC_OBTENEREXISTARTCL_H(\'FAC\',ART_CODIGO,' + '\'' + this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO + '\'' + ',\'01\',' +
      ' TO_CHAR(SYSDATE,\'DD/MM/YYYY\'), TO_CHAR(SYSDATE,\'HH24:MI\')) AS EXISTENCIA,' +
      ' INV_FNC_OBTENERPRCARTICULO(ART_CODIGO,\'01\',' + '\'' + this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_LISTA + '\'' + ', ART_UNIDADVENTA) AS PRECIO,' +
      ' GRUP_CODIGO, INV_FNC_OBTENER_NOMBRE_GRUPO(GRUP_CODIGO) AS GRUP_NOMBRE,' +
      ' ART_CODIGOALT1, ART_UBICACION,ART_TRIBUTAIVA, ART_TRIBUTAICE, ART_MULTIUNIDAD, ART_DESCUENTO, ART_COMPUESTO,ART_VALORICE,ART_PORIVA,TARIIVA_CODIGO';
    this.consulta = consulta;
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', '', ''];
    this.tabla = 'INV_MAEARTICULO';
    this.existencias=' INV_FNC_OBTENEREXISTARTCL_H(\'FAC\',ART_CODIGO,' + '\'' + this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO + '\'' + ',\'01\',' + ' TO_CHAR(SYSDATE,\'DD/MM/YYYY\'), TO_CHAR(SYSDATE,\'HH24:MI\')) '
   this.precioarticulo=' INV_FNC_OBTENERPRCARTICULO(ART_CODIGO,\'01\',' + '\'' + this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_LISTA + '\'' + ', ART_UNIDADVENTA) '
    //GSRF
    console.log('++--', this.encpedService.cotizacionBooleanarray[this.init.tabs[this.init.tabindex].indice]);
      if(this.encpedService.obtenerConfig() === 0 && this.encpedService.cotizacionBooleanarray[this.init.tabs[this.init.tabindex].indice] === false){ //GSRF
            console.log('entra if---<')
            this.where = ' INV_FNC_OBTENEREXISTARTCL_H(\'FAC\',ART_CODIGO,' + '\'' + this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO + '\'' + ',\'01\',' +
            ' TO_CHAR(SYSDATE,\'DD/MM/YYYY\'), TO_CHAR(SYSDATE,\'HH24:MI\')) > 0';
            // this.encfacService.encontrarRegistro(consulta, this.tabla, this.types[0].value +
          this.busqService.encontrarRegistro10(this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO,
            this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_LISTA, parametro).subscribe(eR => {
              if (eR !== null) {
                if (eR[0] !== undefined) {
                  this.manejarSeleccion(eR[0]);
                } else {
                  this.abrirDialogBusqueda(consulta, 'INV_MAEARTICULO', 'rownum <= 50 and  ' + this.types[0].value + ' LIKE \'%' + parametro + '%\' AND '+this.where);
                }
              } else {
                this.abrirDialogBusqueda(consulta, 'INV_MAEARTICULO', 'rownum <= 50 and  ' + this.types[0].value + ' LIKE \'%' + parametro + '%\' AND '+this.where);
              }
            });
      }else{
          console.log('no entra---<')
          this.where = '';
          // this.encfacService.encontrarRegistro(consulta, this.tabla, this.types[0].value +
        this.busqService.encontrarRegistro10(this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO,
          this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_LISTA, parametro).subscribe(eR => {
            if (eR !== null) {
              if (eR[0] !== undefined) {
                this.manejarSeleccion(eR[0]);
              } else {
                this.abrirDialogBusqueda(consulta, 'INV_MAEARTICULO', 'rownum <= 50 and  ' + this.types[0].value + ' LIKE \'%' + parametro + '%\'');
              }
            } else {
              this.abrirDialogBusqueda(consulta, 'INV_MAEARTICULO', 'rownum <= 50 and  ' + this.types[0].value + ' LIKE \'%' + parametro + '%\'');
            }
          });
      }
    //GSRF
    
  }

  busquedaarticulodev(rowData, opcionbusqueda, parametro) {
    this.opcionbusqueda = opcionbusqueda;
    switch (opcionbusqueda) {
      case 'busquedaDVF':
        this.indexunidad = this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice].findIndex(dev => dev.LINEA === rowData.LINEA);
        break;
      case 'busquedaDEVCOM':
        this.indexunidad = this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice].findIndex(dev => dev.LINEA === rowData.LINEA);
        break;
      default:
        this.indexunidad = 0;
    }

    this.types = [
      { label: 'Código', value: 'ART_CODIGO' },
      { label: 'Nombre', value: 'ART_NOMBRE' },
      { label: 'Alias', value: 'ART_NOMBREC' },
      { label: 'Existencia', value: 'EXISTENCIA' },
      { label: 'Precio', value: 'PRECIO' },
      { label: 'Cód.Grupo', value: 'GRUP_CODIGO' },
      { label: 'Nombre Grupo', value: 'GRUP_NOMBRE' },
      { label: 'CodigoBarras', value: 'ART_CODIGOALT1' },
      { label: 'Bod-Def', value: 'ART_UBICACION' }
    ];
    let consulta = '';
    if (this.opcionbusqueda === 'busquedaDVF') {
      consulta = 'ART_CODIGO, ART_NOMBRE, ART_NOMBREC, ART_UNIDADVENTA,' +
        ' INV_FNC_OBTENEREXISTARTCL_H(\'FAC\',ART_CODIGO,' + '\'' + this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO + '\'' + ',\'01\',' +
        ' TO_CHAR(SYSDATE,\'DD/MM/YYYY\'), TO_CHAR(SYSDATE,\'HH24:MI\')) AS EXISTENCIA,' +
        ' INV_FNC_OBTENERPRCARTICULO(ART_CODIGO,\'01\',' + '\'' + this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_LISTA + '\'' + ', ART_UNIDADVENTA) AS PRECIO,' +
        ' GRUP_CODIGO, INV_FNC_OBTENER_NOMBRE_GRUPO(GRUP_CODIGO) AS GRUP_NOMBRE,' +
        ' ART_CODIGOALT1, ART_UBICACION,ART_TRIBUTAIVA, ART_TRIBUTAICE, ART_MULTIUNIDAD, ART_DESCUENTO';
        this.existencias=' INV_FNC_OBTENEREXISTARTCL_H(\'FAC\',ART_CODIGO,' + '\'' + this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO + '\'' + ',\'01\',' + ' TO_CHAR(SYSDATE,\'DD/MM/YYYY\'), TO_CHAR(SYSDATE,\'HH24:MI\')) '
        this.precioarticulo=' INV_FNC_OBTENERPRCARTICULO(ART_CODIGO,\'01\',' + '\'' + this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_LISTA + '\'' + ', ART_UNIDADVENTA) '
     
      } else if (this.opcionbusqueda === 'busquedaDEVCOM') {
      consulta = 'ART_CODIGO, ART_NOMBRE, ART_NOMBREC, ART_UNIDADVENTA,' +
        ' INV_FNC_OBTENEREXISTARTCL_H(\'FAC\',ART_CODIGO,' + '\'' + this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO + '\'' + ',\'01\',' +
        ' TO_CHAR(SYSDATE,\'DD/MM/YYYY\'), TO_CHAR(SYSDATE,\'HH24:MI\')) AS EXISTENCIA,' +
        ' INV_FNC_OBTENERPRCARTICULO(ART_CODIGO,\'01\',' + '\'' + 'A' + '\'' + ', ART_UNIDADVENTA) AS PRECIO,' +
        ' GRUP_CODIGO, INV_FNC_OBTENER_NOMBRE_GRUPO(GRUP_CODIGO) AS GRUP_NOMBRE,' +
        ' ART_CODIGOALT1, ART_UBICACION,ART_TRIBUTAIVA, ART_TRIBUTAICE, ART_MULTIUNIDAD, ART_DESCUENTO';
        this.existencias=' INV_FNC_OBTENEREXISTARTCL_H(\'FAC\',ART_CODIGO,' + '\'' + this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO + '\'' + ',\'01\',' + ' TO_CHAR(SYSDATE,\'DD/MM/YYYY\'), TO_CHAR(SYSDATE,\'HH24:MI\'))  '
        this.precioarticulo=' INV_FNC_OBTENERPRCARTICULO(ART_CODIGO,\'01\',' + '\'' + 'A' + '\'' + ', ART_UNIDADVENTA) '
      }
    this.consulta = consulta;
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', '', ''];
    this.tabla = 'INV_MAEARTICULO';
    this.where = '';
    //this.existencias=''
    this.busqService.encontrarRegistro11(consulta, parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda(consulta, 'INV_MAEARTICULO', 'rownum <= 50 and  ' + this.types[0].value + ' LIKE \'%' + parametro + '%\'');
        }
      } else {
        this.abrirDialogBusqueda(consulta, 'INV_MAEARTICULO', 'rownum <= 50 and  ' + this.types[0].value + ' LIKE \'%' + parametro + '%\'');
      }
    });
  }

  busquedaarticuloorden(rowData, opcionbusqueda, parametro) {
    this.opcionbusqueda = opcionbusqueda;
    switch (opcionbusqueda) {
      case 'busquedaOrden':
        //this.indexunidad = this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice].findIndex(orden => orden.LINEA === rowData.LINEA);
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex; //GSRF
        break;
      default:
        this.indexunidad = 0;
    }

    this.types = [
      { label: 'Código', value: 'ART_CODIGO' },
      { label: 'Nombre', value: 'ART_NOMBRE' },
      { label: 'Alias', value: 'ART_NOMBREC' },
      { label: 'Existencia', value: 'EXISTENCIA' },
      { label: 'Costo', value: 'COSTO' },
      { label: 'Cód.Grupo', value: 'GRUP_CODIGO' },
      { label: 'Nombre Grupo', value: 'GRUP_NOMBRE' },
      { label: 'CodigoBarras', value: 'ART_CODIGOALT1' },
      { label: 'Bod-Def', value: 'ART_UBICACION' }
    ];
    const consulta = 'ART_CODIGO, ART_NOMBRE, ART_NOMBREC, ART_UNIDADVENTA, ART_UNIDADCOSTEO, ' +
      ' INV_FNC_OBTENEREXISTARTCL_H(\'FAC\',ART_CODIGO,' + '\'' + this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO + '\'' + ',\'01\',' +
      ' TO_CHAR(SYSDATE,\'DD/MM/YYYY\'), TO_CHAR(SYSDATE,\'HH24:MI\')) AS EXISTENCIA,' +
      ' (SELECT COM_FNC_ULTIMO_COSTO_COM(ART_CODIGO, \'01\') FROM DUAL) AS COSTO,' +
      ' GRUP_CODIGO, INV_FNC_OBTENER_NOMBRE_GRUPO(GRUP_CODIGO) AS GRUP_NOMBRE,' +
      ' ART_CODIGOALT1, ART_UBICACION,ART_TRIBUTAIVA, ART_TRIBUTAICE, ART_MULTIUNIDAD, ART_DESCUENTO,ART_PORIVA,TARIIVA_CODIGO';
    this.consulta = consulta;
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', ''];
    this.tabla = 'INV_MAEARTICULO';
    this.where = '';
    this.existencias=' INV_FNC_OBTENEREXISTARTCL_H(\'FAC\',ART_CODIGO,' + '\'' + this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO + '\'' + ',\'01\',' + ' TO_CHAR(SYSDATE,\'DD/MM/YYYY\'), TO_CHAR(SYSDATE,\'HH24:MI\')) '
    
    this.busqService.encontrarRegistro12(this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO, parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda(consulta, 'INV_MAEARTICULO', 'rownum <= 50 and UPPER(' + this.types[0].value + ') LIKE UPPER(\'%' + parametro + '%\')');
        }
      } else {
        this.abrirDialogBusqueda(consulta, 'INV_MAEARTICULO', 'rownum <= 50 and UPPER(' + this.types[0].value + ') LIKE UPPER(\'%' + parametro + '%\')');
      }
    });

    //this.abrirDialogBusqueda(consulta, 'INV_MAEARTICULO', 'rownum <= 50 and  ' + this.types[0].value + ' LIKE \'%' + parametro + '%\'');
  }

  async busquedaarticuloguia(rowData, opcionbusqueda, parametro) {
    console.log('aqui');
    let bodega = '';
    let listaprecios = '';
    let fecha1 = '';
    let hora1 = '';
    const fecha = new Date();
    const day = fecha.getDay().toString();
    const month = fecha.getMonth().toString();
    let horas;
    let hora = fecha.getHours().toString();
    if (hora.length === 1) {
      hora = '0' + hora;
    }
    let minu = fecha.getMinutes().toString();
    if (minu.length === 1) {
      minu = '0' + minu;
    }
    let fechaReal: string;
    horas = String(hora) + ':' + String(minu);
    fechaReal = fecha.toLocaleString().substr(0, 9);
    console.log('fechareal',fechaReal)
    fechaReal=fechaReal.replace(',','');//CDPJ
    this.opcionbusqueda = opcionbusqueda;
    switch (opcionbusqueda) {
      case 'busquedaGuia':
        ///GSRF
        if(this.poscidetfac === undefined ){
          this.poscidetfac = -1;
        }
        console.log('0 >',this.poscidetfac);
        console.log('1 >',this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice].findIndex(guia => guia.DETGRE_LINEA === rowData.DETGRE_LINEA));
        console.log('2 >',rowData.DETGRE_LINEA -1 );
        //this.posartfac = this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice].findIndex(detfac => detfac.DETFAC_LINEA === rowData.DETFAC_LINEA);
        this.posartfac = rowData.DETGRE_LINEA -1;//CDPJ
        //console.log('3 >',this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice].findIndex(guia => guia.LINEA === rowData.LINEA));
        if(this.poscidetfac === this.posartfac){
          this.indexunidad = this.posartfac + 1;
        }else{
          this.indexunidad = this.posartfac;
        }
        ///GSRF
        //this.indexunidad = this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice].findIndex(guia => guia.LINEA === rowData.LINEA);
        ///GSRF
        this.poscidetfac = -2;
        bodega = this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO;
        listaprecios = this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_LISTA;
        //console.log('4 >',this.poscidetfac);
        //CDPJ
        fecha1 = '\'' + this.datePipe.transform(this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_FECHAEMISION, 'dd/MM/yyyy') + '\'';//'TO_CHAR(SYSDATE,\'DD/MM/YYYY\')';
        hora1 = '\'' + this.datePipe.transform(this.venenguiaRemisionService.horaSistema, 'HH:mm') + '\'';
        //CDPJ
        break;
      default:
        this.indexunidad = 0;
    }
    this.types = [
      { label: 'Código', value: 'ART_CODIGO' },
      { label: 'Nombre', value: 'ART_NOMBRE' },
      { label: 'Alias', value: 'ART_NOMBREC' },
      { label: 'Existencia', value: 'EXISTENCIA' },
      { label: 'Precio', value: 'PRECIO' },
      { label: 'Cód.Grupo', value: 'GRUP_CODIGO' },
      { label: 'Nombre Grupo', value: 'GRUP_NOMBRE' },
      { label: 'CodigoBarras', value: 'ART_CODIGOALT1' },
      { label: 'Bod-Def', value: 'ART_UBICACION' }
    ];
    const consulta = 'ART_CODIGO, ART_NOMBRE, ART_NOMBREC, ART_UNIDADVENTA,' +
      ' INV_FNC_OBTENEREXISTARTCL_H(\'FAC\',ART_CODIGO,' + '\'' + this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO + '\'' + ',\'01\',' +
      fecha1 + ',' + hora1 + ') AS EXISTENCIA,' +
      ' INV_FNC_OBTENERPRCARTICULO(ART_CODIGO,\'01\',' + '\'' + this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_LISTA + '\'' + ', ART_UNIDADVENTA) AS PRECIO,' +
      ' GRUP_CODIGO, INV_FNC_OBTENER_NOMBRE_GRUPO(GRUP_CODIGO) AS GRUP_NOMBRE,' +
      ' ART_CODIGOALT1, ART_CANTMIN, ART_UBICACION,ART_TRIBUTAIVA, ART_TRIBUTAICE, ART_MULTIUNIDAD, ART_DESCUENTO, INV_FNC_OBTENERCOSTOPROM(ART_CODIGO,TO_DATE(\'' + fechaReal + '\',\'DD/MM/YYYY\'),\'' + horas + '\', \'01\') AS COSTOPROM , ART_VALORICE,ART_PORIVA,TARIIVA_CODIGO';
    this.consulta = consulta;
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', '', ''];
    this.tabla = 'INV_MAEARTICULO';
    this.where = '';
    this.existencias=' INV_FNC_OBTENEREXISTARTCL_H(\'FAC\',ART_CODIGO,' + '\'' + this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO + '\'' + ',\'01\',' + ' TO_CHAR(SYSDATE,\'DD/MM/YYYY\'), TO_CHAR(SYSDATE,\'HH24:MI\')) '
    this.precioarticulo=' INV_FNC_OBTENERPRCARTICULO(ART_CODIGO,\'01\',' + '\'' + this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_LISTA + '\'' + ', ART_UNIDADVENTA) '
    //this.abrirDialogBusqueda(consulta, 'INV_MAEARTICULO', 'rownum <= 50 and  ' + this.types[0].value + ' LIKE \'%' + parametro + '%\'');
    //CDPJ
    fechaReal='\''+fechaReal+'\'';
    horas='\''+horas+'\'';
    const eR= await this.busqService.encontrarRegistroProm1Gre(bodega,fechaReal,horas,listaprecios,parametro)//.subscribe(eR => {    
    if(eR !== null){
      if(eR[0] !== undefined){
        this.manejarSeleccion(eR[0]);
      }else{
        this.abrirDialogBusqueda(consulta, 'INV_MAEARTICULO', 'rownum <= 50 and  ' + this.types[0].value + ' LIKE \'%' + parametro + '%\'');

      }
    }else{
      this.abrirDialogBusqueda(consulta, 'INV_MAEARTICULO', 'rownum <= 50 and  ' + this.types[0].value + ' LIKE \'%' + parametro + '%\'');

      }
   // });
    //CDPJ
  }

  async busquedaarticuloexistencia(rowData, opcionbusqueda, parametro) {
    
    console.log('aqui');
    let bodega = '';
    let listaprecios = '';
    let fecha = '';
    let hora = '';
    let CODBARFAC =0
    this.opcionbusqueda = opcionbusqueda;
    switch (opcionbusqueda) {
      case 'busquedaarticulodetallesalida':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        bodega = this.salidaService.detkardexsarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO;
        listaprecios = 'A';
        fecha = '\'' + this.datePipe.transform(this.salidaService.date1, 'dd/MM/yyyy') + '\'';//'TO_CHAR(SYSDATE,\'DD/MM/YYYY\')';
        hora = '\'' + this.datePipe.transform(this.salidaService.hora, 'HH:mm') + '\'';//'TO_CHAR(SYSDATE,\'HH24:MI\')';
        break;
      case 'busquedaarticulodetfac':
        ///GSRF
        if(this.poscidetfac === undefined ){
          this.poscidetfac = -1;
        }
        console.log('0 >',this.poscidetfac);
        console.log('1 >',this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice].findIndex(detfac => detfac.DETFAC_LINEA ));
        console.log('2 >',rowData.DETFAC_LINEA - 1);
        //this.posartfac = this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice].findIndex(detfac => detfac.DETFAC_LINEA === rowData.DETFAC_LINEA);
        this.posartfac = rowData.DETFAC_LINEA - 1;
        //console.log('3 >',this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice].findIndex(detfac => detfac.DETFAC_LINEA === rowData.DETFAC_LINEA));
        if(this.poscidetfac === this.posartfac){
          this.indexunidad = this.posartfac + 1;
        }else{
          this.indexunidad = this.posartfac;
        }
        this.indexunidad=this.gridApi.getFocusedCell().rowIndex;
        ///GSRF
        //this.indexunidad = this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice].findIndex(detfac => detfac.DETFAC_LINEA === rowData.DETFAC_LINEA);
        bodega = this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO;
        listaprecios = this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_LISTA;
        fecha = '\'' + this.datePipe.transform(this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_FECHAEMISION, 'dd/MM/yyyy') + '\'';
        hora = '\'' + this.datePipe.transform(this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_HORAEMISION, 'HH:mm') + '\'';
        ///GSRF
        this.poscidetfac = -2;
        CODBARFAC = this.encfacService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CODBARFAC').CFG_VALOR1;
        this.encfacService.spin=true;//GSRF
        break;
      case 'artcodigodetordpro':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;//this.encordproService.detordpro[this.init.tabs[this.init.tabindex].indice].findIndex(detordpro => detordpro.CLAVE === rowData.CLAVE);
        bodega = this.encordproService.detordpro[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO;
        listaprecios = 'A';
        fecha = '\'' + this.datePipe.transform(this.encordproService.encordpro[this.init.tabs[this.init.tabindex].indice].ENCORDPRO_FECHAINICIO, 'dd/MM/yyyy') + '\'';
        hora = '\'' + this.datePipe.transform(this.encordproService.encordpro[this.init.tabs[this.init.tabindex].indice].ENCORDPRO_FECHAINICIO, 'HH:mm') + '\'';
        break;
      case 'busquedaarticulodetfacpv':
        this.indexunidad = this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice].findIndex(detfac => detfac.CLAVE === rowData.CLAVE);
        bodega = this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO;
        listaprecios = this.encptovtaService.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_LISTA;
        fecha = '\'' + this.datePipe.transform(this.encptovtaService.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_FECHAEMISION, 'dd/MM/yyyy') + '\'';
        hora = '\'' + this.datePipe.transform(this.encptovtaService.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_FECHAEMISION, 'HH:mm') + '\'';
        break;
      default:
        this.indexunidad = 0;
    }
    ///GSRF
    this.types = [
      { label: 'Código', value: 'ART_CODIGO' },
      { label: 'Nombre', value: 'ART_NOMBRE' },
      { label: 'Alias', value: 'ART_NOMBREC' },
      { label: 'Existencia', value: 'EXISTENCIA' },
      { label: 'Precio', value: 'PRECIO' },
      { label: 'Descuento', value: 'DESCUENTO' },
      { label: 'Cód.Grupo', value: 'GRUP_CODIGO' },
      { label: 'Nombre Grupo', value: 'GRUP_NOMBRE' },
      { label: 'CodigoBarras', value: 'ART_CODIGOALT1' },
      { label: 'Bod-Def', value: 'ART_UBICACION' }
    ];

    const consulta = 'ART_CODIGO, ART_NOMBRE, ART_NOMBREC, ART_UNIDADVENTA,' +
      ' INV_FNC_OBTENEREXISTARTCL_H(\'FAC\',ART_CODIGO,' + '\'' + bodega + '\'' + ',\'01\',' +
      fecha + ',' + hora + ') AS EXISTENCIA,' +
      ' INV_FNC_OBTENERPRCARTICULO(ART_CODIGO,\'01\',\'' + listaprecios + '\', ART_UNIDADVENTA) AS PRECIO,' +
      ' INV_FNC_OBTENERDESCUARTICULO(ART_CODIGO,\'01\',\'' + listaprecios + '\', ART_UNIDADVENTA) AS DESCUENTO,' +
      ' GRUP_CODIGO, INV_FNC_OBTENER_NOMBRE_GRUPO(GRUP_CODIGO) AS GRUP_NOMBRE,' +
      ' ART_CODIGOALT1, ART_UBICACION, ART_ACTIVO, INV_FNC_OBTENER_CADUCAARTICULO(ART_CODIGO, \'01\') AS ART_CADUCA, ART_SERIALFLAG, ART_FORMSRIVTAS,ART_PORIVA,TARIIVA_CODIGO';

    this.consulta = consulta;
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', '',''];
    this.tabla = 'INV_MAEARTICULO';
    this.where = 'ART_ACTIVO=\'S\'';
    this.existencias=' INV_FNC_OBTENEREXISTARTCL_H(\'FAC\',ART_CODIGO,' + '\'' + bodega + '\'' + ',\'01\',' +
    fecha + ',' + hora + ') '
    this.precioarticulo=' INV_FNC_OBTENERPRCARTICULO(ART_CODIGO,\'01\',\'' + listaprecios + '\',ART_UNIDADVENTA) '
    this.descuentoarticulo=' INV_FNC_OBTENERDESCUARTICULO(ART_CODIGO,\'01\',\'' + listaprecios + '\',ART_UNIDADVENTA) '

    // const eR = await this.busqService.encontrarRegistroProm1(bodega, fecha, hora, listaprecios, parametro);
    // if (eR !== null) {
    //   if (eR[0] !== undefined) {
    //     this.manejarSeleccion(eR[0]);
    //   } else {
    //     this.abrirDialogBusqueda(consulta, 'INV_MAEARTICULO', 'rownum <= 100 AND ' + this.where + ' AND '
    //       + 'UPPER(' + this.types[0].value + ') LIKE UPPER(\'%' + parametro + '%\')');
    //   }
    // } else {
    //   this.abrirDialogBusqueda(consulta, 'INV_MAEARTICULO', 'rownum <= 100 AND ' + this.where + ' AND '
    //     + 'UPPER(' + this.types[0].value + ') LIKE UPPER(\'%' + parametro + '%\')');
    // }
    //CDPJ
    
    if(this.opcionbusqueda === 'busquedaarticulodetfac' && CODBARFAC === 1){
      const eR = await this.busqService.encontrarRegistroProm1C(bodega, fecha, hora, listaprecios, parametro);
    if (eR !== null) {
      if (eR[0] !== undefined) {
        this.manejarSeleccion(eR[0]);
        this.encfacService.spin=false;//GSRF
      } else {
        this.abrirDialogBusqueda(consulta, 'INV_MAEARTICULO', 'rownum <= 100 AND ' + this.where + ' AND '
          + 'UPPER(ART_CODIGOALT1) LIKE UPPER(\'%' + parametro + '%\')');
      }
    } else {
      this.abrirDialogBusqueda(consulta, 'INV_MAEARTICULO', 'rownum <= 100 AND ' + this.where + ' AND '
        + 'UPPER(ART_CODIGOALT1) LIKE UPPER(\'%' + parametro + '%\')');
    }
    }else{
      const eR = await this.busqService.encontrarRegistroProm1(bodega, fecha, hora, listaprecios, parametro);
    if (eR !== null) {
      if (eR[0] !== undefined) {
        this.manejarSeleccion(eR[0]);
        this.encfacService.spin=false;//CDPJ
      } else {
        this.abrirDialogBusqueda(consulta, 'INV_MAEARTICULO', 'rownum <= 100 AND ' + this.where + ' AND '
          + 'UPPER(' + this.types[0].value + ') LIKE UPPER(\'%' + parametro + '%\')');
      }
    } else {
      this.abrirDialogBusqueda(consulta, 'INV_MAEARTICULO', 'rownum <= 100 AND ' + this.where + ' AND '
        + 'UPPER(' + this.types[0].value + ') LIKE UPPER(\'%' + parametro + '%\')');
      }
    }
    //CDPJ
  }


  busquedaarticulotransferencia(rowData, opcionbusqueda, parametro) {
    let bodega = '';
    let listaprecios = '';
    let fecha = '';
    let hora = '';
    this.opcionbusqueda = opcionbusqueda;
    switch (opcionbusqueda) {
      case 'dettransfer':
        this.indexunidad = this.enctransferService.invdettransferarray[this.init.tabs[this.init.tabindex].indice].findIndex(data => data.CLAVE === rowData.CLAVE);
        bodega = this.enctransferService.invenctransferarray[this.init.tabs[this.init.tabindex].indice].BOD_CODIGO;
        fecha = 'TO_CHAR(SYSDATE,\'DD/MM/YYYY\')';
        hora = 'TO_CHAR(SYSDATE,\'HH24:MI\')';
        break;
      default:
        this.indexunidad = 0;
    }
    this.types = [
      { label: 'Código', value: 'ART_CODIGO' },
      { label: 'Nombre', value: 'ART_NOMBRE' },
      { label: 'Alias', value: 'ART_NOMBREC' },
      { label: 'Existencia', value: 'EXISTENCIA' },
      { label: 'Cód.Grupo', value: 'GRUP_CODIGO' },
      { label: 'Nombre Grupo', value: 'GRUP_NOMBRE' },
      { label: 'CodigoBarras', value: 'ART_CODIGOALT1' },
      { label: 'Bod-Def', value: 'ART_UBICACION' }
    ];

    const consulta = 'ART_CODIGO, ART_NOMBRE,ART_NOMBREC, ART_UNIDADVENTA,' +
      ' INV_FNC_OBTENEREXISTARTCL_H(\'FAC\',ART_CODIGO,' + '\'' + bodega + '\'' + ',\'01\',' +
      fecha + ',' + hora + ') AS EXISTENCIA,' +
      ' GRUP_CODIGO, INV_FNC_OBTENER_NOMBRE_GRUPO(GRUP_CODIGO) AS GRUP_NOMBRE,' +
      ' ART_CODIGOALT1, ART_UBICACION, ART_ACTIVO, ART_SERIALFLAG';//CDPJ agregue ART_SERIALFLAG

    this.consulta = consulta;
    this.busquedacampos = [parametro, '', '', '', '', '', '', ''];
    this.tabla = 'INV_MAEARTICULO';
    this.where = 'ART_ACTIVO=\'S\'';
    this.existencias=' INV_FNC_OBTENEREXISTARTCL_H(\'FAC\',ART_CODIGO,' + '\'' + bodega + '\'' + ',\'01\',' + fecha + ',' + hora + ') '

    this.busqService.encontrarRegistro13(bodega, fecha, hora, parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda(consulta, 'INV_MAEARTICULO', 'rownum <= 100 AND ' + this.where + ' AND '
            + 'UPPER(' + this.types[0].value + ') LIKE UPPER(\'%' + parametro + '%\')');
        }
      } else {
        this.abrirDialogBusqueda(consulta, 'INV_MAEARTICULO', 'rownum <= 100 AND ' + this.where + ' AND '
          + 'UPPER(' + this.types[0].value + ') LIKE UPPER(\'%' + parametro + '%\')');
      }
    });
  }

  busquedaarticuloexistenciaTom(rowData, opcionbusqueda, parametro) {

    let strFecha = this.tomService.encTomaFisica[this.init.tabs[this.init.tabindex].indice].TRNTOMAFISICA_FECHA;

    if (strFecha.toString().includes('-')) {
      strFecha = this.datePipe.transform(strFecha, 'dd/MM/yyyy');
    }
    strFecha=strFecha.replace(',','');//CDPJ
    const time = this.tomService.encTomaFisica[this.init.tabs[this.init.tabindex].indice].TRNTOMAFISICA_HORA;
    const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
    const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
    const strHora = hora + ':' + minutos;

    this.opcionbusqueda = opcionbusqueda;
    switch (opcionbusqueda) {
      case 'tomaBusArt':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;//CDPJ COMENTÉ//this.tomService.detTomaFisicaArray[this.init.tabs[this.init.tabindex].indice].findIndex(articulo => articulo.LINEA === rowData.LINEA);
        break;
      default:
        this.indexunidad = 0;
    }
    this.types = [
      { label: 'Código', value: 'ART_CODIGO' },
      { label: 'Nombre', value: 'ART_NOMBRE' },
      { label: 'Alias', value: 'ART_NOMBREC' },
      { label: 'Existencia', value: 'EXISTENCIA' },
      { label: 'Precio', value: 'PRECIO' },
      { label: 'Cód.Grupo', value: 'GRUP_CODIGO' },
      { label: 'Nombre Grupo', value: 'GRUP_NOMBRE' },
      { label: 'CodigoBarras', value: 'ART_CODIGOALT1' },
      { label: 'Bod-Def', value: 'ART_UBICACION' }
    ];
    const consulta = 'ART_CODIGO, ART_NOMBRE, ART_NOMBREC, ART_UNIDADVENTA, ART_UNIDADCOSTEO, ' +
      ' INV_FNC_OBTENEREXISTARTCL_H(\'FAC\',ART_CODIGO,' + '\'' + rowData.BOD_CODIGO + '\'' + ',\'01\',' +
      ' \'' + strFecha + '\', \'' + strHora + '\' ) AS EXISTENCIA,' +
      ' INV_FNC_OBTENERPRCARTICULO(ART_CODIGO,\'01\',\'A\', ART_UNIDADVENTA) AS PRECIO,' +
      ' GRUP_CODIGO, INV_FNC_OBTENER_NOMBRE_GRUPO(GRUP_CODIGO) AS GRUP_NOMBRE,' +
      ' ART_CODIGOALT1, ART_UBICACION, INV_FNC_OBTENERCOSTOPROM(ART_CODIGO,TO_DATE(\'' + strFecha + '\',\'DD/MM/YYYY\'),\'' + strHora + '\', \'01\') AS COSTOPROM, ART_ACTIVO';
    this.consulta = consulta;
    this.busquedacampos = ['', '', '', '', '', '', '', '', '', ''];
    this.tabla = 'INV_MAEARTICULO';
    this.where = 'ART_ACTIVO=\'S\'';
    console.log('strFecha',strFecha)
    this.existencias=' INV_FNC_OBTENEREXISTARTCL_H(\'FAC\',ART_CODIGO,' + '\'' + rowData.BOD_CODIGO + '\'' + ',\'01\',' +' \'' + strFecha + '\', \'' + strHora + '\' ) '

    this.busqService.encontrarRegistro14(rowData.BOD_CODIGO, strFecha, strHora, parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda(consulta, 'INV_MAEARTICULO', 'rownum <= 50 AND ' + this.where + ' AND '
            + 'UPPER(' + this.types[0].value + ') LIKE UPPER(\'%' + parametro + '%\')');
        }
      } else {
        this.abrirDialogBusqueda(consulta, 'INV_MAEARTICULO', 'rownum <= 50 AND ' + this.where + ' AND '
          + 'UPPER(' + this.types[0].value + ') LIKE UPPER(\'%' + parametro + '%\')');
      }
    });
  }


  async busquedaarticuloexistenciaEnt(rowData, opcionbusqueda, parametro) {
    this.opcionbusqueda = opcionbusqueda;
    switch (opcionbusqueda) {
      case 'artEntrada':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
      default:
        this.indexunidad = 0;
    }
    this.types = [
      { label: 'Código', value: 'ART_CODIGO' },
      { label: 'Nombre', value: 'ART_NOMBRE' },
      { label: 'Alias', value: 'ART_NOMBREC' },
      { label: 'Existencia', value: 'EXISTENCIA' },
      { label: 'Precio', value: 'PRECIO' },
      { label: 'Cód.Grupo', value: 'GRUP_CODIGO' },
      { label: 'Nombre Grupo', value: 'GRUP_NOMBRE' },
      { label: 'CodigoBarras', value: 'ART_CODIGOALT1' },
      { label: 'Bod-Def', value: 'ART_UBICACION' }
    ];
    const consulta = 'ART_CODIGO, ART_NOMBRE, ART_NOMBREC, ART_UNIDADVENTA,' +
      ' INV_FNC_OBTENEREXISTARTCL_H(\'FAC\',ART_CODIGO,' + '\'' + this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO + '\'' + ',\'01\',' +
      ' TO_CHAR(SYSDATE,\'DD/MM/YYYY\'), TO_CHAR(SYSDATE,\'HH24:MI\')) AS EXISTENCIA,' +
      ' INV_FNC_OBTENERPRCARTICULO(ART_CODIGO,\'01\',\'A\', ART_UNIDADVENTA) AS PRECIO,' +
      ' GRUP_CODIGO, INV_FNC_OBTENER_NOMBRE_GRUPO(GRUP_CODIGO) AS GRUP_NOMBRE,' +
      ' ART_CODIGOALT1, ART_UBICACION, ART_ACTIVO, INV_FNC_OBTENER_CADUCAARTICULO(ART_CODIGO, \'01\') AS ART_CADUCA, ART_SERIALFLAG';
    this.consulta = consulta;
    this.busquedacampos = ['', '', '', '', '', '', '', '', ''];
    this.tabla = 'INV_MAEARTICULO';
    this.where = 'ART_ACTIVO=\'S\'';
    this.existencias=' INV_FNC_OBTENEREXISTARTCL_H(\'FAC\',ART_CODIGO,' + '\'' + this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO + '\'' + ',\'01\',' +' TO_CHAR(SYSDATE,\'DD/MM/YYYY\'), TO_CHAR(SYSDATE,\'HH24:MI\')) '

    const eR = await this.busqService.encontrarRegistroProm2(this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO, parametro);
    if (eR !== null) {
      if (eR[0] !== undefined) {
        this.manejarSeleccion(eR[0]);
      } else {
        this.abrirDialogBusqueda(consulta, 'INV_MAEARTICULO', 'rownum <= 100 AND ' + this.where + ' AND '
          + 'UPPER(' + this.types[0].value + ') LIKE UPPER(\'%' + parametro + '%\')');
      }
    } else {
      this.abrirDialogBusqueda(consulta, 'INV_MAEARTICULO', 'rownum <= 100 AND ' + this.where + ' AND '
        + 'UPPER(' + this.types[0].value + ') LIKE UPPER(\'%' + parametro + '%\')');
    }
    /*this.busqService.encontrarRegistro(consulta, this.tabla, this.where + ' AND UPPER(' + this.types[0].value + ')' +
      ' LIKE UPPER(\'' + parametro + '\')').subscribe(eR => {
        if (eR !== null) {
          if (eR[0] !== undefined) {
            this.manejarSeleccion(eR[0]);
          } else {
            this.abrirDialogBusqueda(consulta, 'INV_MAEARTICULO', 'rownum <= 100 and ' + this.where + ' AND UPPER(' + this.types[0].value + ')' +
            ' LIKE UPPER(\'' + parametro + '\')');
          }
        } else {
          this.abrirDialogBusqueda(consulta, 'INV_MAEARTICULO', 'rownum <= 100 and ' + this.where + ' AND UPPER(' + this.types[0].value + ')' +
          ' LIKE UPPER(\'' + parametro + '\')');
        }
      });*/
  }

  busquedabodega(rowData, opcionbusqueda) {
    switch (opcionbusqueda) {
      case 'busquedabodega':
        this.indexunidad = this.artService.maeexistbod.findIndex(existbod => existbod.BOD_CODIGO === rowData.BOD_CODIGO);
        break;
      case 'busquedaToma':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;//this.tomService.detTomaFisicaArray[this.init.tabs[this.init.tabindex].indice].findIndex(toma => toma.LINEA === rowData.LINEA);
        break;
      case 'busquedabodegasal':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
      case 'busquedabodegaent':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;//CDPJ ANTES existbod.BOD_CODIGO === rowData.BOD_CODIGO
        break;
      case 'bod_codigo1':
        this.indexunidad = this.pryService.venmaepry.findIndex(existbod => existbod.BOD_CODIGO1 === rowData.BOD_CODIGO1);
        break;
      case 'bod_codigo2':
        this.indexunidad = this.pryService.venmaepry.findIndex(existbod => existbod.BOD_CODIGO2 === rowData.BOD_CODIGO2);
        break;
      case 'bod_codigo3':
        this.indexunidad = this.pryService.venmaepry.findIndex(existbod => existbod.BOD_CODIGO3 === rowData.BOD_CODIGO3);
        break;
      case 'bod_codigo4':
        this.indexunidad = this.pryService.venmaepry.findIndex(existbod => existbod.BOD_CODIGO4 === rowData.BOD_CODIGO4);
        break;
      case 'bod_codigo5':
        this.indexunidad = this.pryService.venmaepry.findIndex(existbod => existbod.BOD_CODIGO5 === rowData.BOD_CODIGO5);
        break;
      case 'bod_codigo6':
        this.indexunidad = this.pryService.venmaepry.findIndex(existbod => existbod.BOD_CODIGO6 === rowData.BOD_CODIGO6);
        break;
      case 'busquedabodegadetfac':
        this.indexunidad = this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice].findIndex(detfac => detfac.DETFAC_LINEA === rowData.DETFAC_LINEA);
        break;
      case 'busquedabodegadetfacpv':
        this.indexunidad = this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice].findIndex(detfac => detfac.CLAVE === rowData.CLAVE);
        break;
      case 'busquedabodegaped':
        this.indexunidad = this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice].findIndex(detped => detped.LINEA === rowData.LINEA && detped.DETPED_LINEA === rowData.DETPED_LINEA);
        break;
      case 'busquedabodegadetfacpro':
        this.indexunidad = this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice].findIndex(detfacpro => detfacpro.CLAVE === rowData.CLAVE);
        break;
      case 'BOD_CODIGOAJUSTE':
        this.indexunidad = this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice].findIndex(item => item.NUMERO === rowData.NUMERO);
        break;
      case 'BOD_CODIGOGUIAREM':
        this.indexunidad = this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice].findIndex(guia => guia.DETGRE_LINEA === rowData.DETGRE_LINEA);
        break;
      case 'bodordentrabajo':
        this.indexunidad = this.encordtraService.tableDetalle[this.init.tabs[this.init.tabindex].indice].findIndex(det => det.CLAVE === rowData.CLAVE);
        break;
      default:
        this.indexunidad = 0;
    }

    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'BOD_CODIGO' },
      { label: 'Nombre', value: 'BOD_NOMBRE' }
    ];
    this.busquedacampos = [rowData.BOD_CODIGO, ''];
    this.consulta = '*';
    this.tabla = 'INV_MAEBODEGA';
    this.where = '';

    this.busqService.encontrarRegistro15(rowData.BOD_CODIGO).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda('*', 'INV_MAEBODEGA', '');
        }
      } else {
        this.abrirDialogBusqueda('*', 'INV_MAEBODEGA', '');
      }
    });
  }

  busquedabodegaReserva(rowData, opcionbusqueda) {
    switch (opcionbusqueda) {

      case 'BOD_CODIGORESERVA':
        this.indexunidad = this.invreservaService.detalleReservas[this.init.tabs[this.init.tabindex].indice].findIndex(item => item.LINEA === rowData.LINEA &&
          item.TRNRES_NUMERO === rowData.TRNRES_NUMERO);
        break;
      default:
        this.indexunidad = 0;
    }

    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Codigo', value: 'BOD_CODIGO' },
      { label: 'Nombre', value: 'BOD_NOMBRE' }
    ];
    this.busquedacampos = [rowData.BOD_CODIGODES, ''];
    this.consulta = '*';
    this.tabla = 'INV_MAEBODEGA';
    this.where = '';

    this.busqService.encontrarRegistro15(rowData.BOD_CODIGODES).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busqService.busquedaInvMaeBodega6().subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaInvMaeBodega6().subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaTiposUtilidades(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {

      case 'NOM_TIPOPAGOUTIL':
        this.indexunidad = this.nomUtilidadesService.detalleUtilidades.findIndex(item => item.LINEA === rowData.LINEA);
        this.where = 'ref_tipo=\'NOMTIP\'';
        break;
      case 'NOM_TIPOPAGO_SALDIG':
        this.indexunidad = this.nomUtilidadesService.detalleUtilidades.findIndex(item => item.LINEA === rowData.LINEA);
        this.where = 'ref_tipo=\'NOMTIP\'';
        break;
      case 'NOM_FORMPAGOCOD':
        this.indexunidad = this.nomUtilidadesService.detalleUtilidades.findIndex(item => item.LINEA === rowData.LINEA);
        this.where = 'ref_tipo=\'NOMFP\'';
        break;
      case 'NOM_TIPOCUENCOD':
        this.indexunidad = this.nomUtilidadesService.detalleUtilidades.findIndex(item => item.LINEA === rowData.LINEA);
        this.where = 'ref_tipo=\'NOTPCT\'';
        break;
      default:
        this.indexunidad = 0;
        this.where = '';
    }

    this.opcionbusqueda = opcionbusqueda;

    this.types = [
      { label: 'Código', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' },
      { label: 'Nombre Corto', value: 'REF_NOMBREC' }
    ];

    if (parametro === null || parametro === undefined) {
      parametro = '';
    }

    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.tabla = 'SACI_MAEREFERENCIA';


    this.busqService.encontrarRegistro16(this.where, parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busqService.busquedaSaciMaeReferencia6(this.where).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaSaciMaeReferencia6(this.where).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedaUtilidadesBancos(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {

      case 'NOM_BANCPAGOCOD':
        this.indexunidad = this.nomUtilidadesService.detalleUtilidades.findIndex(item => item.LINEA === rowData.LINEA);
        this.where = '';
        break;
      default:
        this.indexunidad = 0;
    }

    this.opcionbusqueda = opcionbusqueda;

    this.types = [
      { label: 'Código', value: 'BANCLI_CODIGO' },
      { label: 'Nombre', value: 'BANCLI_NOMBRE' },
      { label: 'Estado', value: 'BANCLI_ESTADO' },
      { label: 'Compania', value: 'COM_CODIGO' }
    ];

    if (parametro === null || parametro === undefined) {
      parametro = '';
    }

    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.tabla = 'CXC_MAEBANCLI';


    this.busqService.encontrarRegistro17(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busqService.busquedaCXCMaeBanCli4(this.where).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaCXCMaeBanCli4(this.where).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  busquedacostosind(rowData, opcionbusqueda) {
    switch (opcionbusqueda) {
      case 'busquedacostosind':
        this.indexunidad = this.artService.maecostoestand.findIndex(costo => costo.CIN_CODIGO === rowData.CIN_CODIGO);
        break;
      case 'busquedacostosindprod':
        this.indexunidad = this.encordproService.trncostosindirectos[this.init.tabs[this.init.tabindex].indice].findIndex(costo => costo.CLAVE === rowData.CLAVE);
        break;
      case 'busquedacihorashombre':
        this.indexunidad = this.encordproService.trnhorashombre[this.init.tabs[this.init.tabindex].indice].findIndex(costo => costo.CLAVE === rowData.CLAVE);
        break;
      default:
        this.indexunidad = 0;
    }

    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'CIN_CODIGO' },
      { label: 'Nombre', value: 'CIN_NOMBRE' }
    ];
    this.busquedacampos = ['', ''];
    this.consulta = '*';
    this.tabla = 'PROD_MAECOSTOIND';
    this.where = '';
    this.abrirDialogBusqueda('*', 'PROD_MAECOSTOIND', '');
  }

  busquedaCaja(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'busquedaCaja':
        this.indexunidad = this.formapagosService.formaPagoServicio.findIndex(caja => caja.LINEA === rowData.LINEA);
        break;
      case 'busquedaCajaPago':
        this.indexunidad = this.pagconservice.pagos.findIndex(caja => caja.CLAVE === rowData.CLAVE);
        break;
      case 'busquedaCajaPagodvf':
        this.indexunidad = this.pagconservicedvf.pagos.findIndex(caja => caja.LINEA === rowData.LINEA);
        break;
      case 'busquedaCajaCXCpagoret':
        this.indexunidad = this.cxctrnpagoretService.cxcpagoretformapago.findIndex(caja => caja.LINEA === rowData.LINEA);
        break;
      case 'busquedaCajaformapago':
        this.indexunidad = this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice].findIndex(caja => caja.LINEA === rowData.LINEA);
        break;
      case 'busquedaCajaformapagodev':
        this.indexunidad = this.cxcCarteraService.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice].findIndex(caja => caja.LINEA === rowData.LINEA);
        break;
      case 'CAJADOCcxpfpant':
        this.indexunidad = this.cxptrncobroService.fpantarray[this.init.tabs[this.init.tabindex].indice].findIndex(tp => tp.CLAVE === rowData.CLAVE);
        break;
      case 'CAJCODIGOcxpfpdev':
        this.indexunidad = this.cxptrncobroService.fpdevarray[this.init.tabs[this.init.tabindex].indice].findIndex(tp => tp.CLAVE === rowData.CLAVE);
        break;
        //CDPJ
      case 'busquedaCajaTrnabonoformapago':
        this.indexunidad =this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'busquedaCajaTrncancelacionformapago':
        this.indexunidad =this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'busquedaCajaTrnanticipoformapago':
        this.indexunidad =this.gridApi.getFocusedCell().rowIndex;
      break;
        //CDPJ
      default:
        this.indexunidad = 0;
    }

    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'CAJ_CODIGO' },
      { label: 'Descripción', value: 'CAJ_DESCRIPCION' },
      { label: 'Ref.Contable', value: 'CON_CODIGO' },
      { label: 'Centro Costo', value: 'CEN_CODIGO' }
    ];
    this.busquedacampos = [parametro, '', '', '',];//CDPJ
    this.consulta = '*';
    this.tabla = 'BAN_MAECAJ';
    this.where = 'CAJ_CODIGO IS NOT NULL ORDER BY 1';//CDPJ

    // this.busqService.encontrarRegistro18(rowData.CAJ_CODIGO).subscribe(eR => {
    //   if (eR !== null) {
    //     this.manejarSeleccion(eR[0]);
    //   } else {
    //     this.abrirDialogBusqueda('*', 'BAN_MAECAJ', '');
    //   }
    // });
    //CDPJ
    //console.log('2');
    //if (parametro !== '' && parametro !== undefined && parametro !== null) {
      //console.log('3');
      this.busqService.encontrarRegistro18(parametro).subscribe(eR => {
        //console.log('4');
        if (eR !== null) {
          //console.log('5');
          console.log(eR);
          if (eR !== undefined) {
            //console.log('6');
            this.manejarSeleccion(eR[0]);
            //console.log('7');
          } else {
            //console.log('8');
            this.abrirDialogBusqueda('*', 'BAN_MAECAJ',this.types[0].value + ' LIKE \'%' + parametro + '%\' AND CAJ_CODIGO IS NOT NULL ORDER BY 1');//CDPJ
          }
        } else {
          //console.log('9');
          this.abrirDialogBusqueda('*', 'BAN_MAECAJ', this.types[0].value +' LIKE \'%' + parametro + '%\' AND CAJ_CODIGO IS NOT NULL ORDER BY 1');//CDPJ
        }
      });
    
    //}
    //CDPJ
  }

  busquedaCodigoSRI(rowData, opcionbusqueda) {
    switch (opcionbusqueda) {
      case 'busquedaCodigoSRI':
        this.indexunidad = this.formapagosService.formaPagoServicio.findIndex(caja => caja.LINEA === rowData.LINEA);
        break;
      case 'busquedaCodigoSRIpago':
        this.indexunidad = this.pagconservice.pagos.findIndex(caja => caja.CLAVE === rowData.CLAVE);
        break;
      case 'busquedaCodigoSRICartera':
        this.indexunidad = this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice].findIndex(caja => caja.LINEA === rowData.LINEA);
        break;
          //CDPJ
      case 'busquedaCodigoSRIAbono':
        this.indexunidad =this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'busquedaCodigoSRICancelacion':
        this.indexunidad =this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'busquedaCodigoSRIAnticipo':
        this.indexunidad =this.gridApi.getFocusedCell().rowIndex;
      break;
      //CDPJ
      default:
        this.indexunidad = 0;
    }

    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'CODIGOCON', value: 'CON_CODIGO' }
    ];
    this.busquedacampos = ['', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'ANEX_MAECODSRI';
    this.where = 'CODSRI_TIPO = \'CF\'';

    this.abrirDialogBusqueda('*', 'ANEX_MAECODSRI', 'CODSRI_TIPO = \'CF\'');
  }
  //GSRF
  busquedaprove(rowData, opcionbusqueda) {
    switch (opcionbusqueda) {
      case 'PRO_CODIGONOM':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;//this.comnotrecServicio.comnotrecImportacionarray[this.init.tabs[this.init.tabindex].indice].findIndex(servicio =>  servicio.LINEA === rowData.LINEA);
        this.where = 'COM_CODIGO = \'01\' and rownum <= 30 ';
        break;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      
      { label: 'Código', value: 'PRO_CODIGO' },
      { label: 'Nombre', value: 'PRO_NOMBRE' }
    ];
    this.busquedacampos = ['', ''];
    this.consulta = '*';
    this.tabla = 'COM_MAEPROVEEDOR';

    this.abrirDialogBusqueda('*', 'COM_MAEPROVEEDOR', this.where);
  }  
  //GSRF
  busquedaSRI(rowData, opcionbusqueda) {
    switch (opcionbusqueda) { 
      case 'SRI_BUS':
        this.indexunidad = this.comService.commaeservicio.findIndex(servicio => servicio.SERCOM_CODIGO === rowData.SERCOM_CODIGO);
        this.where = 'REF_TIPO = \'RESCOM\' AND COM_CODIGO = \'01\'';
        break;
      case 'SRI_BUSVEN':
        this.indexunidad = this.serviService.venmaeservicios.findIndex(servicio => servicio.SER_CODIGO === rowData.SER_CODIGO);
        this.where = 'REF_TIPO = \'RESVTA\' AND COM_CODIGO = \'01\'';//CDPJ antes RESCOM

        break;
      case 'REF_CODIGOIMP':
        console.log('/*/*/*aqui/*//*/')
       // this.indexunidad = this.comnotrecServicio.comnotrecImportacionarray[this.init.tabs[this.init.tabindex].indice].findIndex(servicio => servicio.LINEA === rowData.LINEA);
       this.indexunidad = this.gridApi.getFocusedCell().rowIndex; 
       this.where = 'REF_TIPO = \'IMP\' AND COM_CODIGO = \'01\'';

        break;
      case 'NUM_DOCREFBUS':
        this.indexunidad = this.numCService.detNumeracionC.findIndex(item => item.NUM_CLASE === rowData.NUM_CLASE);
        this.where = 'ref_tipo = \'TDNUPV\' AND COM_CODIGO = \'01\'';

        break;
      case 'DETFACPRO_TIPOSRI':
        this.indexunidad = this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice].findIndex(detfacpro => detfacpro.CLAVE
          === rowData.CLAVE);
        this.where = 'REF_TIPO = \'RESCOM\' AND COM_CODIGO = \'01\'';
        break;
      case 'DETFAC_TIPOSRI':
        this.indexunidad = this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice].findIndex(detfac => detfac.DETFAC_LINEA === rowData.DETFAC_LINEA);
        this.where = 'REF_TIPO = \'RESVTA\' AND COM_CODIGO = \'01\'';
        break;
        case 'DETDVF_TIPOSRI':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;//this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice].findIndex(detfac => detfac.DETFAC_LINEA === rowData.DETFAC_LINEA);
        this.where = 'REF_TIPO = \'RESVTA\' AND COM_CODIGO = \'01\'';
        break;
        
      default:
        this.indexunidad = 0;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' }
    ];
    this.busquedacampos = ['', ''];
    this.consulta = '*';
    this.tabla = 'SACI_MAEREFERENCIA';

    this.abrirDialogBusqueda('*', 'SACI_MAEREFERENCIA', this.where);
  }

  busquedaTar(rowData, opcionbusqueda, parametro) {
    //anex_maecodsri
    switch (opcionbusqueda) {
      case 'TAR_CODSRI':
        this.indexunidad = this.tarjetaService.cxcmaeTarjetaCredito.findIndex(tarjetaS => tarjetaS.LINEA === rowData.LINEA);
        break;
      case 'busquedaCodigoSRIpago':
        this.indexunidad = this.pagconservice.pagos.findIndex(tarjetaS => tarjetaS.CLAVE === rowData.CLAVE);
        break;
      case 'busquedaCodigoSRICartera':
        this.indexunidad = this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice].findIndex(tarjetaS => tarjetaS.LINEA === rowData.LINEA);
        break;
           //CDPJ
      case 'busquedaCodigoSRIAbono':
        this.indexunidad =this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'busquedaCodigoSRICancelacion':
        this.indexunidad =this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'busquedaCodigoSRIAnticipo':
        this.indexunidad =this.gridApi.getFocusedCell().rowIndex;
      break;
      
      //CDPJ
      default:
        this.indexunidad = 0;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Nombre', value: 'CODSRI_DESCRIPCION' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'Código Cont', value: 'CODSRI_DESCRIPCION' },
    ];

    this.busquedacampos = ['', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'ANEX_MAECODSRI';
    this.where = 'CODSRI_TIPO = \'FT\'';
    this.abrirDialogBusqueda('*', 'ANEX_MAECODSRI', 'CODSRI_TIPO = \'FT\'');
  }

  busquedaretencion(rowData, opcionbusqueda, parametro) {

    switch (opcionbusqueda) {
      case 'busquedaretencion':
        this.indexunidad = this.pagconservice.reten.findIndex(ret => ret.CLAVE === rowData.CLAVE);
        break;
      case 'trnretencion':
        this.indexunidad = this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice].findIndex(ret => ret.CLAVE === rowData.CLAVE);
        break;
      case 'cxcretenciones':
        this.indexunidad = this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice].findIndex(ret => ret.LINEA === rowData.LINEA);
        break;
      case 'cxcpagoretret':
        this.indexunidad = this.cxctrnpagoretService.cxcpagoretretenciones.findIndex(ret => ret.LINEA === rowData.LINEA);
        break;
      case 'BANTARJETA_RETENCION':
        this.indexunidad = this.bantrnTarjetaService.detModeloRetencion[this.init.tabs[this.init.tabindex].indice].findIndex(ret => ret.LINEA === rowData.LINEA);
        break;
         //CDPJ
         case 'cxctrnretenciones':
          this.indexunidad =this.gridApi.getFocusedCell().rowIndex;
          break;
          case 'cxctrnabonoretenciones':
            this.indexunidad =this.gridApi.getFocusedCell().rowIndex;
            break;
          case 'cxctrncancelacionretenciones':
            this.indexunidad =this.gridApi.getFocusedCell().rowIndex;
          break;
        //CDPJ
      default:
        this.indexunidad = 0;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'RETENCION_CODIGO' },
      { label: 'Nombre', value: 'RETENCION_NOMBRE' },
      { label: '%', value: 'RETENCION_PORCENTAJE' },
      { label: 'Cta Compras', value: 'CON_CODIGO' },
      { label: 'Cta Ventas', value: 'CON_CODIGO_VTA' },
      { label: 'TipRet', value: 'RETENCION_TIPRET' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'SACI_MAERETENCION';
    this.where = '';
    const tipos = this.types[1].value + ',' + this.types[2].value + ',' + this.types[5].value;

    this.busqService.encontrarRegistro19(parametro).subscribe(eR => {
      if (eR !== null) {

        this.manejarSeleccion(eR[0]);

      } else {
        this.abrirDialogBusqueda('*', 'SACI_MAERETENCION', '');
      }
    });


  }

  busquedaarticulosnotasrec(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'busquedaarticulosnotasrec':
        //this.indexunidad = this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].findIndex(detalle => detalle.LINEA === rowData.LINEA);
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;//GSRF
        break;
      default:
        this.indexunidad = 0;
    }


    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'ART_CODIGO' },
      { label: 'Nombre', value: 'ART_NOMBRE' },
      { label: 'Alias', value: 'ART_NOMBREC' },
      { label: 'Grupo', value: 'GRUP_CODIGO' },
      { label: 'Bod-Def', value: 'ART_UBICACION' },
      { label: 'CodigoBarras', value: 'ART_CODIGOALT1' }
    ];

    this.busquedacampos = [parametro, '', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'INV_MAEARTICULO';
    this.where = '';
    // + this.types[0].value + ' LIKE \'%' + parametro + '%\''
    //PROCEDIMIENTO DE BUSQUEDA RAPIDA
    this.proveedorServicio.erarticulosnotasrec(rowData.DETNOTREC_CODIGO).subscribe(eR => {
      if (eR !== null) {
        this.manejarSeleccion(eR[0]);
      } else {
        this.abrirDialogBusqueda('*', 'INV_MAEARTICULO', 'rownum <= 50 and  ART_ACTIVO=\'S\' AND COM_CODIGO = \'01\' ');
      }
    });
  }


  busquedaCliente(rowData, opcionbusqueda) {
    switch (opcionbusqueda) {
      case 'busquedaCodCli':
        this.indexunidad = this.pryService.venmaepry.findIndex(servicio => servicio.PRY_CODIGO === rowData.PRY_CODIGO);
        break;
      default:
        this.indexunidad = 0;
    }

    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'CLI_CODIGO' },
      { label: 'Nombre', value: 'CLI_NOMBRE' }
    ];
    // CAMPOS NECESARIOS BUSQUEDA FAST
    this.busquedacampos = ['', ''];
    this.consulta = '*';
    this.tabla = 'VEN_MAECLIENTE';
    this.where = '';

    this.abrirDialogBusqueda('*', 'ven_maecliente', 'rownum <= 50');
  }

  busquedaCEN(rowData, opcionbusqueda) {
    console.log('cen',opcionbusqueda);
    switch (opcionbusqueda) {
      case 'CEN':
        this.indexunidad = this.bodService.bodegas.findIndex(bodega => bodega.BOD_CODIGO === rowData.BOD_CODIGO);
        break;
      case 'CEN_NOTASREC':
        //this.indexunidad = this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice].findIndex(detalle => detalle.LINEA === rowData.LINEA);
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
      case 'TOM_CEN':
        this.indexunidad = this.tomService.detTomFisVarArray[this.init.tabs[this.init.tabindex].indice].findIndex(toma => toma.LINEA === rowData.LINEA);
        break;
      case 'SAL_CEN':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
      case 'ENT_CEN':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
        //CDPJ
        case 'SAL_CENI':
        this.indexunidad = this.salidaService.detkardexsarray[this.init.tabs[this.init.tabindex].indice].findIndex(salida => salida.CLAVE === rowData.CLAVE);
        break;
      case 'ENT_CENI':
        this.indexunidad = this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice].findIndex(entrada => entrada.CLAVE === rowData.CLAVE);
        break;
        case 'CEN_NOTREC':
          this.indexunidad = this.comnotrecServicio.comnotrecVararray[this.init.tabs[this.init.tabindex].indice].findIndex(varios => varios.NOTRECVAR_LINEA === rowData.NOTRECVAR_LINEA);
          break;
        case 'CEN_PED':
          this.indexunidad = this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice].findIndex(pedido => pedido.DETPED_LINEA === rowData.DETPED_LINEA
            && pedido.LINEA === rowData.LINEA);
        break;
        //CDPJ
      case 'CEN_CARGO':
        this.indexunidad = this.cargoService.commaecargos.findIndex(cargo => cargo.CARGO_CODIGO === rowData.CARGO_CODIGO);
        break;
      case 'CEN_SERVICIO':
        this.indexunidad = this.comService.commaeservicio.findIndex(servicio => servicio.SERCOM_CODIGO === rowData.SERCOM_CODIGO);
        break;
      case 'CEN_BANCAJA':
        this.indexunidad = this.BancajaServices.banmaecaja.findIndex(bancaja => bancaja.CAJ_CODIGO === rowData.CAJ_CODIGO);
        break;
      case 'CEN_VENSERVICIO':
        this.indexunidad = this.serviService.venmaeservicios.findIndex(servicio => servicio.SER_CODIGO === rowData.SER_CODIGO);
        break;
      case 'CEN_CODIGODISTRI':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;//this.distriCCService.distribucionCC.findIndex(distri => distri.CLAVE === rowData.CLAVE);
        break;
      case 'CEN_CODIGOCXPVARIOS':
        this.indexunidad = this.cxptrnpagodocService.varios.findIndex(cxpvarios => cxpvarios.CLAVE === rowData.CLAVE);
        break;
      case 'FACPRO_CEN':
        //this.indexunidad = this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice].findIndex(detfacpro => detfacpro.CLAVE === rowData.CLAVE);
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
      case 'DETFAC_CENCOD':
        this.indexunidad = this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice].findIndex(detfac => detfac.DETFAC_LINEA === rowData.DETFAC_LINEA);
        break;
      //CDPJ
      case 'FACPRODEV_CEN':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'CXCVAR_CENCOD':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'CEN_CODIGO_ACTSALIDA':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      break;
      // //CDPJ
      // case 'CEN_GUIA':
      //   this.indexunidad=this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice].findIndex(guia => guia.LINEA=== rowData.LINEA && guia.DETGRE_LINEA === rowData.DETGRE_LINEA);
      //   break;
      // //CDPJ
      // case 'CEN_CONENCASIL':
      //   this.indexunidad = this.encasilService.detencasil.findIndex(encasil => encasil.LINEA === rowData.LINEA);
      //   break;


      default:
        this.indexunidad = 0;
    }


    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'CEN_CODIGO' },
      { label: 'Nombre', value: 'CEN_NOMBRE' }
    ];
    this.busquedacampos = ['', ''];
    this.consulta = '*';
    this.tabla = 'CON_MAECEN';
    this.where = ' CEN_CODIGO IS NOT NULL ORDER BY CEN_CODIGO ASC ';

    this.busqService.encontrarRegistro20(rowData.CEN_CODIGO).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda('*', 'CON_MAECEN', 'CEN_CODIGO IS NOT NULL ORDER BY CEN_CODIGO ASC');
        }
      } else {
        this.abrirDialogBusqueda('*', 'CON_MAECEN', 'CEN_CODIGO IS NOT NULL ORDER BY CEN_CODIGO ASC');
      }
    });
  }

  busquedaCONLENTO(rowData, opcionbusqueda) {
    if (opcionbusqueda === 'CON') {
      switch (opcionbusqueda) {
        case 'CON':
          this.indexunidad = this.bodService.bodegas.findIndex(bodega => bodega.BOD_CODIGO === rowData.BOD_CODIGO);
          break;
        default:
          this.indexunidad = 0;
      }
    } else if (opcionbusqueda === 'CON_CODIGOING' && this.indice === 0) {
      switch (opcionbusqueda) {
        case 'CON_CODIGOING':
          this.indexunidad = this.grupService.invmaegrupos.findIndex(grupo => grupo.GRUP_CODIGO === rowData.GRUP_CODIGO);
          // this.indexunidad = this.grupService.invmaesubgrupos.findIndex(subgrupo => subgrupo.GRUP_CODIGOP === rowData.GRUP_CODIGOP);
          break;
        default:
          this.indexunidad = 0;
      }
    } else if (opcionbusqueda === 'CON_CODIGOFIVA' && this.indice === 0) {
      switch (opcionbusqueda) {
        case 'CON_CODIGOFIVA':
          this.indexunidad = this.tarjetaService.cxcmaeTarjetaCredito.findIndex
            (tarjetaI => tarjetaI.CON_CODIGOFIVA === rowData.CON_CODIGOFIVA);
          break;
        default:
          this.indexunidad = 0;
      }
    } else if (opcionbusqueda === 'CON_CODIGOGAS' && this.indice === 0) {
      switch (opcionbusqueda) {
        case 'CON_CODIGOGAS':
          this.indexunidad = this.grupService.invmaegrupos.findIndex(grupo => grupo.GRUP_CODIGO === rowData.GRUP_CODIGO);
          break;
        default:
          this.indexunidad = 0;
      }
    } else if (opcionbusqueda === 'CON_CODIGOING' && this.indice === 1) {
      switch (opcionbusqueda) {
        case 'CON_CODIGOING':
          this.indexunidad = this.grupService.invmaesubgrupos.findIndex(subgrupo => subgrupo.GRUP_CODIGO === rowData.GRUP_CODIGO);
          break;
        default:
          this.indexunidad = 0;
      }
    } else if (opcionbusqueda === 'CON_CODIGOGAS' && this.indice === 1) {
      switch (opcionbusqueda) {
        case 'CON_CODIGOGAS':
          this.indexunidad = this.grupService.invmaesubgrupos.findIndex(subgrupo => subgrupo.GRUP_CODIGO === rowData.GRUP_CODIGO);
          break;
        default:
          this.indexunidad = 0;
      }
    } else if (opcionbusqueda === 'CON_MOV') {
      switch (opcionbusqueda) {
        case 'CON_MOV':
          this.indexunidad = this.cajasService.movimientos.findIndex(grupo => grupo.TRNCAJ_NUMERO === rowData.TRNCAJ_NUMERO);
          // this.indexunidad = this.grupService.invmaesubgrupos.findIndex(subgrupo => subgrupo.GRUP_CODIGOP === rowData.GRUP_CODIGOP);
          break;
        default:
          this.indexunidad = 0;
      }
    } else if (opcionbusqueda === 'TOM_CON') {
      switch (opcionbusqueda) {
        case 'TOM_CON':
          this.indexunidad = this.tomService.detTomFisVarArray[this.init.tabs[this.init.tabindex].indice].findIndex(grupo => grupo.LINEA === rowData.LINEA);
          // this.indexunidad = this.grupService.invmaesubgrupos.findIndex(subgrupo => subgrupo.GRUP_CODIGOP === rowData.GRUP_CODIGOP);
          break;
        default:
          this.indexunidad = 0;
      }
    } else if (opcionbusqueda === 'CON_CODIGOSAL') {
      switch (opcionbusqueda) {
        case 'CON_CODIGOSAL':
          this.indexunidad = this.gridApi.getFocusedCell().rowIndex;//this.salidaService.detkardexsvararray[this.init.tabs[this.init.tabindex].indice].findIndex(salidavarcon => salidavarcon.KAR_NRO === rowData.KAR_NRO);
          // this.indexunidad = this.grupService.invmaesubgrupos.findIndex(subgrupo => subgrupo.GRUP_CODIGOP === rowData.GRUP_CODIGOP);
          break;
        default:
          this.indexunidad = 0;
      }
    } else if (opcionbusqueda === 'CON_CODIGOENT') {
      switch (opcionbusqueda) {
        case 'CON_CODIGOENT':
          this.indexunidad = this.gridApi.getFocusedCell().rowIndex;//this.entradaService.detkardexvararray[this.init.tabs[this.init.tabindex].indice].findIndex(entradavarcon => entradavarcon.KAR_NRO === rowData.KAR_NRO);
          // this.indexunidad = this.grupService.invmaesubgrupos.findIndex(subgrupo => subgrupo.GRUP_CODIGOP === rowData.GRUP_CODIGOP);
          break;
        default:
          this.indexunidad = 0;
      }
    } else if (opcionbusqueda === 'CON_GRUPO') {
      switch (opcionbusqueda) {
        case 'CON_GRUPO':
          this.indexunidad = this.grupoService.venmaegrupo.findIndex(groupCom => groupCom.GRU_CODIGO === rowData.GRU_CODIGO);
          // this.indexunidad = this.grupService.invmaesubgrupos.findIndex(subgrupo => subgrupo.GRUP_CODIGOP === rowData.GRUP_CODIGOP);
          break;
        default:
          this.indexunidad = 0;
      }
    } else if (opcionbusqueda === 'CON_CODIGO') {
      switch (opcionbusqueda) {
        case 'CON_CODIGO':
          this.indexunidad = this.serviService.venmaeservicios.findIndex(serviCom => serviCom.CON_CODIGO === rowData.CON_CODIGO);
          // this.indexunidad = this.grupService.invmaesubgrupos.findIndex(subgrupo => subgrupo.GRUP_CODIGOP === rowData.GRUP_CODIGOP);
          break;
        default:
          this.indexunidad = 0;
      }
    } else if (opcionbusqueda === 'CON_SERVICIO') {
      switch (opcionbusqueda) {
        case 'CON_SERVICIO':
          this.indexunidad = this.comService.commaeservicio.findIndex(servicio => servicio.SERCOM_CODIGO === rowData.SERCOM_CODIGO);
          break;
        default:
          this.indexunidad = 0;
      }
    } else if (opcionbusqueda === 'CON_COM_GRUPO') {
      switch (opcionbusqueda) {
        case 'CON_COM_GRUPO':
          this.indexunidad = this.commaeGrupo.commaegrupo.findIndex(servicio => servicio.GRU_CODIGO === rowData.GRU_CODIGO);
          break;
        default:
          this.indexunidad = 0;
      }
    } else if (opcionbusqueda === 'CON_CARGOS') {
      switch (opcionbusqueda) {
        case 'CON_CARGOS':
          this.indexunidad = this.cargoService.commaecargos.findIndex(servicio => servicio.CARGO_CODIGO === rowData.CARGO_CODIGO);
          break;
        default:
          this.indexunidad = 0;
      }
    } else if (opcionbusqueda === 'CON_COMISION') {
      switch (opcionbusqueda) {
        case 'CON_COMISION':
          this.indexunidad = this.comisionService.comisionesServicio.findIndex(servicio => servicio.COMISION_CODIGO
            === rowData.COMISION_CODIGO);
          break;
        default:
          this.indexunidad = 0;
      }
    } else if (opcionbusqueda === 'CON_SERVICIOS2') {
      switch (opcionbusqueda) {
        case 'CON_SERVICIOS2':
          this.indexunidad = this.comService.commaeservicio.findIndex(servicio => servicio.SERCOM_CODIGO
            === rowData.SERCOM_CODIGO);
          break;
        default:
          this.indexunidad = 0;
      }
    } else if (opcionbusqueda === 'RETENCION') {
      switch (opcionbusqueda) {
        case 'RETENCION':
          this.indexunidad = this.cuentaContableServicio.cxcmaeretencion.findIndex(servicio => servicio.RETENCION_CODIGO
            === rowData.RETENCION_CODIGO);
          break;
        default:
          this.indexunidad = 0;
      }
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'CON_CODIGO' },
      { label: 'Nombre', value: 'CON_NOMBRE' },
      { label: 'CNT. COSTOS', value: 'CON_CENTRO' }
    ];
    this.busquedacampos = ['', '', ''];
    this.consulta = '*';
    this.tabla = 'CON_MAECON';
    this.where = '';

    this.abrirDialogBusqueda('*', 'CON_MAECON', '');
  }

  busquedaRubros(rowData, opcionbusqueda, parametro) {
    console.log(rowData);
    if (opcionbusqueda === 'tiporubro') {
      this.indexunidad = this.nonmaeRubService.nommaeRubro.findIndex(item => item.NUMRUB === rowData.NUMRUB);
    }
    console.log(this.indexunidad);
    if (parametro === null || parametro === undefined) {
      parametro = '';
    }

    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' },
      { label: 'Nombre Corto', value: 'REF_NOMBREC' }
    ];
    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.tabla = 'SACI_MAEREFERENCIA';
    this.where = 'ref_Tipo = \'NOMTR\'';

    this.busqService.encontrarRegistro16(this.where, parametro).subscribe(eR => {

      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda('*', this.tabla, 'ROWNUM < 50 AND ' + this.types[0].value + ' LIKE \'%' + parametro + '%\' and ' + this.where);
        }
      } else {
        this.abrirDialogBusqueda('*', this.tabla, 'ROWNUM < 50 AND ' + this.types[0].value + ' LIKE \'%' + parametro + '%\' and ' + this.where);
      }
    });


  }

  busquedaRubrosUnida(rowData, opcionbusqueda, parametro) {
    console.log(rowData);
    if (opcionbusqueda === 'NomRubUnidad') {
      this.indexunidad = this.nonmaeRubService.nommaeRubro.findIndex(item => item.NUMRUB === rowData.NUMRUB);
    }
    if (parametro === null || parametro === undefined) {
      parametro = '';
    }

    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' },
      { label: 'Nombre Corto', value: 'REF_NOMBREC' }
    ];
    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.tabla = 'SACI_MAEREFERENCIA';
    this.where = 'ref_Tipo = \'NOMTUR\'';
    this.busqService.encontrarRegistro16(this.where, parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda('*', this.tabla, 'ROWNUM < 50 AND ' + this.types[0].value + ' LIKE \'%' + parametro + '%\' and ' + this.where);
        }
      } else {
        this.abrirDialogBusqueda('*', this.tabla, 'ROWNUM < 50 AND ' + this.types[0].value + ' LIKE \'%' + parametro + '%\' and ' + this.where);
      }
    });
  }

  busquedaCON(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'CON':
        this.indexunidad = this.bodService.bodegas.findIndex(bodega => bodega.BOD_CODIGO === rowData.BOD_CODIGO);
        break;
      case 'CON_CODIGOING':
        if (this.indice === 0) {
          this.indexunidad = this.grupService.invmaegrupos.findIndex(grupo => grupo.GRUP_CODIGO === rowData.GRUP_CODIGO);
        }
        if (this.indice === 1) {
          this.indexunidad = this.grupService.invmaesubgrupos.findIndex(subgrupo => subgrupo.GRUP_CODIGO === rowData.GRUP_CODIGO);
        }
        break;
      case 'CON_CODIGOGAS':
        if (this.indice === 0) {
          this.indexunidad = this.grupService.invmaegrupos.findIndex(grupo => grupo.GRUP_CODIGO === rowData.GRUP_CODIGO);
        }
        if (this.indice === 1) {
          this.indexunidad = this.grupService.invmaesubgrupos.findIndex(subgrupo => subgrupo.GRUP_CODIGO === rowData.GRUP_CODIGO);
        }
        break;
        //CDPJ
        case 'CON_CODIGOACT':
        if (this.indice === 0) {
          this.indexunidad = this.grupService.invmaegrupos.findIndex(grupo => grupo.GRUP_CODIGO === rowData.GRUP_CODIGO);
        }
        if (this.indice === 1) {
          this.indexunidad = this.grupService.invmaesubgrupos.findIndex(subgrupo => subgrupo.GRUP_CODIGO === rowData.GRUP_CODIGO);
        }
        break;
        //CDPJ
      case 'CON_CODIGOT':
        this.indexunidad = this.tarjetaService.cxcmaeTarjetaCredito.findIndex(tarjeta1 => tarjeta1.LINEA === rowData.LINEA);
        break;
      case 'CON_CODIGOCOM':
        this.indexunidad = this.tarjetaService.cxcmaeTarjetaCredito.findIndex
          (tarjeta2 => tarjeta2.LINEA === rowData.LINEA);
        break;
      case 'CON_CODIGOVARIOS':
        this.indexunidad = this.comnotrecServicio.comnotrecVararray[this.init.tabs[this.init.tabindex].indice].findIndex
          (varios => varios.NOTRECVAR_LINEA === rowData.NOTRECVAR_LINEA);
        break;
      case 'CON_CODIGORFTE':
        this.indexunidad = this.tarjetaService.cxcmaeTarjetaCredito.findIndex
          (tarjeta3 => tarjeta3.LINEA === rowData.LINEA);
        break;
      case 'CON_CODIGOFIVA':
        this.indexunidad = this.tarjetaService.cxcmaeTarjetaCredito.findIndex
          (tarjeta4 => tarjeta4.LINEA === rowData.LINEA);
        break;
      case 'CON_CODIGOCXC':
        this.indexunidad = this.cxcCarteraService.cxctrnvariosarray[this.init.tabs[this.init.tabindex].indice].findIndex
          (cartera => cartera.LINEA === rowData.LINEA);
        break;
      case 'CON_MOV':
        this.indexunidad = this.cajasService.movimientos.findIndex(grupo => grupo.TRNCAJ_NUMERO === rowData.TRNCAJ_NUMERO);
        break;
      case 'TOM_CON':
        this.indexunidad = this.tomService.detTomFisVarArray[this.init.tabs[this.init.tabindex].indice].findIndex(grupo => grupo.LINEA === rowData.LINEA);
        break;
      case 'CON_CODIGOSAL':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;//this.salidaService.detkardexsvararray[this.init.tabs[this.init.tabindex].indice].findIndex(salidavarcon => salidavarcon.DETKARDEXVAR_LINEA === rowData.DETKARDEXVAR_LINEA);
        break;
      case 'CON_CODIGOENT':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;//this.entradaService.detkardexvararray[this.init.tabs[this.init.tabindex].indice].findIndex(entradavarcon => entradavarcon.CLAVE === rowData.CLAVE);
        break;
      case 'CON_GRUPO':
        this.indexunidad = this.grupoService.venmaegrupo.findIndex(groupCom => groupCom.GRU_CODIGO === rowData.GRU_CODIGO);
        break;
      case 'CON_CODIGOSERV':
        this.indexunidad = this.serviService.venmaeservicios.findIndex(serviCom => serviCom.SER_CODIGO === rowData.SER_CODIGO);
        break;
      case 'CON_SERVICIO':
        this.indexunidad = this.comService.commaeservicio.findIndex(servicio => servicio.SERCOM_CODIGO === rowData.SERCOM_CODIGO);
        break;
      case 'CON_COM_GRUPO':
        this.indexunidad = this.commaeGrupo.commaegrupo.findIndex(servicio => servicio.GRU_CODIGO === rowData.GRU_CODIGO);
        break;
      case 'CON_CARGOS':
        this.indexunidad = this.cargoService.commaecargos.findIndex(servicio => servicio.CARGO_CODIGO === rowData.CARGO_CODIGO);
        break;
      case 'CON_COMISION':
        this.indexunidad = this.comisionService.comisionesServicio.findIndex(servicio => servicio.LINEA
          === rowData.LINEA);
        break;
      case 'CON_SERVICIOS2':
        this.indexunidad = this.comService.commaeservicio.findIndex(servicio => servicio.SERCOM_CODIGO
          === rowData.SERCOM_CODIGO);
        break;
      case 'RETENCION':
        this.indexunidad = this.cuentaContableServicio.cxcmaeretencion.findIndex(servicio => servicio.RETENCION_CODIGO
          === rowData.RETENCION_CODIGO);
        break;
      case 'RETENCIONvta':
        this.indexunidad = this.cuentaContableServicio.cxcmaeretencion.findIndex(servicio => servicio.RETENCION_CODIGO
          === rowData.RETENCION_CODIGO);
        break;
      case 'CON_BANCAJA':
        this.indexunidad = this.BancajaServices.banmaecaja.findIndex(bancaja => bancaja.CAJ_CODIGO
          === rowData.CAJ_CODIGO);
        break;
      case 'busquedacuentadetfac':
        this.encfacService.spin=true;//GSRF
        this.indexunidad = this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice].findIndex(bancaja => bancaja.DETFAC_LINEA
          === rowData.DETFAC_LINEA);
        break;
      case 'busquedacuentaguia':
        this.indexunidad = this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice].findIndex(guia => guia.DETGRE_LINEA
          === rowData.DETGRE_LINEA);
        break;
      case 'busquedacuentadev':
        this.indexunidad = this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice].findIndex(dev => dev.LINEA
          === rowData.LINEA);
        break;
      case 'busquedacuentadetfacpro':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
        //GSRF
      case 'busquedacuentadetfacprocodalt':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
        case 'busquedacuentadetfachomo':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
      //GSRF
      case 'busquedacuentadevCOM':
        this.indexunidad = this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice].findIndex(dev => dev.LINEA
          === rowData.LINEA);
        break;
      case 'ANEX_CON_CODIGO':
        this.indexunidad = this.anexmaecodsriService.codSRI.findIndex(codsri => codsri.Código === rowData.Código);
        break;
      case 'CON_CODIGODISTRI':
        this.indexunidad = this.distriCCService.distribucionCC.findIndex(distri => distri.CLAVE === rowData.CLAVE);
        break;
      case 'CON_CODIGOCXPVARIOS':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
      case 'CON_CODIGOENCASIL':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;//this.encasilService.detencasil.findIndex(encasil => encasil.LINEA === rowData.LINEA && encasil.DETASI_NROFILA === rowData.DETASI_NROFILA);
        break;
      case 'CON_CODIGOBANVARIOS':
        this.indexunidad = this.bantrnbanEditService.banTrnBanVarios.findIndex(varios => varios.LINEA === rowData.LINEA);
        break;
      case 'CON_CODIGODISTRIBCC':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
      case 'CON_CODIGOTRNTARJETA':
        this.indexunidad = this.bantrnTarjetaService.detModeloVarios[this.init.tabs[this.init.tabindex].indice].findIndex(varios => varios.LINEA === rowData.LINEA);
        break;
      case 'CON_CODIGOBANCAJA':
        this.indexunidad = this.bantrnCajaService.detModeloVarCaja.findIndex(varios => varios.LINEA === rowData.LINEA);
        break;
      case 'CONCODIGOcxpvarios':
        this.indexunidad = this.cxptrncobroService.variosarray[this.init.tabs[this.init.tabindex].indice].findIndex(varios => varios.CLAVE === rowData.CLAVE);
        break;
      case 'CON_CODIGOIMP':
        this.indexunidad = this.sacimaeconfService.detConMaeImpArray[this.init.tabs[this.init.tabindex].indice].findIndex(imp => imp.CODIGO === rowData.CODIGO);
        break;
      case 'CONCODIGO_AJUSTES':
        this.indexunidad = this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice].findIndex(item => item.NUMERO === rowData.NUMERO);
        break;
      case 'CONCODIGO_CXPCUOTA':
        this.indexunidad = this.cxpenccuotaService.detCuotaVarArray[this.init.tabs[this.init.tabindex].indice].findIndex(item => item.LINEA === rowData.LINEA);
        break;
      case 'CON_CODIGO_NOM_RUB':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;

        break;
      case 'NomRubConCodi2':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;

        break;
      case 'NomRubConCodi3':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;

        break;
      case 'NomRubConCodi4':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;

        break;

      case 'NomRubConCodi5':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;

        break;
      case 'NomRubConCodi6':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;

        break;
      case 'NomRubConCodi7':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;

        break;
      case 'NomRubConCodHab':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;

        break;
      case 'NomRubConCodHab2':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;

        break;
      case 'NomRubConCodHab3':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;

        break;
      case 'NomRubConCodHab4':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;

        break;
      case 'NomRubConCodHab5':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;

        break;
      case 'NomRubConCodHab6':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;

        break;
      case 'NomRubConCodHab7':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
      case 'CON_CODIGO_INGRFIJ':
        this.indexunidad = this.nomempleadosService.nommaenomIngrFijo.findIndex(item => item.NUMEGRFIJ === rowData.NUMEGRFIJ);
        break;
      case 'CON_CODIGO_EGREFIJ':
        this.indexunidad = this.nomempleadosService.nommaeEgreFijo.findIndex(item => item.NUMEGRFIJ === rowData.NUMEGRFIJ);
        break;
      case 'EMP_CODIGO':
        this.indexunidad = this.impurentService.detImpuRent.findIndex(item => item.NUMCAB === rowData.NUMCAB);
        break;
      case 'CON_CODIGO_LIQDECEVAR':
        this.indexunidad = this.liqservice.nomliquvar.findIndex(item => item.NUMLIQV === rowData.NUMLIQV);
        break;
      case 'CON_CODIGOcostosInd':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
      case 'CON_CODIGO_ACTGRUPO':
        this.indexunidad = this.actmaegrupoService.detmaeGrupoArray[this.init.tabs[this.init.tabindex].indice].findIndex
          (varios => varios.LINEA === rowData.LINEA);
        break;
      case 'CON_CODIGO_ACTSALIDA':
        this.indexunidad = this.actencsalidaService.detVariosSalidaArray[this.init.tabs[this.init.tabindex].indice].findIndex
          (varios => varios.LINEA === rowData.LINEA);
        break;
        case 'CONCODIGO_AJUSTESNEG':
          this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
      default:
        this.indexunidad = 0;
    }


    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'CON_CODIGO' },
      { label: 'Nombre', value: 'CON_NOMBRE' },
      { label: 'CNT. COSTOS', value: 'CON_CENTRO' }
    ];

    if (parametro === null || parametro === undefined) {
      parametro = '';
    }

    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.tabla = 'CON_MAECON';
    this.where = '';

    this.busqService.encontrarRegistro21(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
          this.cxptrncobroService.spin=false;//CDPJ
          this.encfacService.spin=false;//GSRF
        } else {
          this.abrirDialogBusqueda('*', 'CON_MAECON', ' ' + this.types[0].value + ' LIKE \'%' + parametro + '%\' order by con_codigo asc ');
        }
      } else {
        this.abrirDialogBusqueda('*', 'CON_MAECON', ' ' + this.types[0].value + ' LIKE \'%' + parametro + '%\' order by con_codigo asc ');
      }
    });
  }

  busquedatipopago(rowData, opcionbusqueda, parametro) {
    let condicion = '';
    switch (opcionbusqueda) {
      case 'busquedatipopago':
        this.indexunidad = this.pagconservice.pagos.findIndex(tp => tp.CLAVE === rowData.CLAVE);
        break;
      case 'busquedatipopagodvf':
        condicion = 'FORMAPAGO_TIPO IN (\'EF\',\'CH\',\'ANCL\')';
        this.indexunidad = this.pagconservicedvf.pagos.findIndex(tp => tp.LINEA === rowData.LINEA);
        break;
      case 'busquedaTipoCaj':
        condicion = 'FORMAPAGO_TIPO IN (\'EF\',\'CH\')';
        this.indexunidad = this.cxcCarteraService.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice].findIndex(tp => tp.LINEA === rowData.LINEA);
        break;
      case 'busquedaFormapagoCXC':
        this.indexunidad = this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice].findIndex(tp => tp.LINEA === rowData.LINEA);
        break;
      case 'busquedapagoretFormapago':
        condicion = 'FORMAPAGO_TIPO IN (\'EF\',\'CH\')';
        this.indexunidad = this.cxctrnpagoretService.cxcpagoretformapago.findIndex(tp => tp.LINEA === rowData.LINEA);
        break;
      case 'TIPODOCcxpfpant':
        condicion = 'FORMAPAGO_TIPO IN (\'EF\',\'CH\',\'ND\')';
        this.indexunidad = this.cxptrncobroService.fpantarray[this.init.tabs[this.init.tabindex].indice].findIndex(tp => tp.CLAVE === rowData.CLAVE);
        break;
      case 'TRNPAGOTIPOcxpfpdev':
        condicion = 'FORMAPAGO_TIPO IN (\'EF\',\'CH\',\'DP\')';
        this.indexunidad = this.cxptrncobroService.fpdevarray[this.init.tabs[this.init.tabindex].indice].findIndex(tp => tp.CLAVE === rowData.CLAVE);
        break;
        //CDPJ
      case 'busquedaFormapagoCXCTrnabono':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'busquedaFormapagoCXCTrncancelacion':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'busquedaFormapagoCXCTrnanticipo':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      break;

      //CDPJ
      default:
        this.indexunidad = 0;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Tipo', value: 'FORMAPAGO_TIPO' },
      { label: 'Nombre', value: 'FORMAPAGO_NOMBRE' },
      { label: 'Caja', value: 'CAJ_CODIGO' }
    ];
    if (parametro === null) {
      parametro = '';
    }
    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.tabla = 'CXC_MAEFORMAPAGO';
    this.where = condicion;

    this.busqService.encontrarRegistro22(condicion, parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda('*', 'CXC_MAEFORMAPAGO', condicion);
        }
      } else {
        this.abrirDialogBusqueda('*', 'CXC_MAEFORMAPAGO', condicion);
      }
    });
  }

  busquedaBancoCliente(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'busquedaBancoCliente':
        this.indexunidad = this.pagconservice.pagos.findIndex(tp => tp.CLAVE === rowData.CLAVE);
        break;
      case 'busquedaBancoClienteCarteraCH':
        this.indexunidad = this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice].findIndex(tp => tp.LINEA === rowData.LINEA);
        break;
      case 'BANCLICODIGOcxpfpdev':
        this.indexunidad = this.cxptrncobroService.fpdevarray[this.init.tabs[this.init.tabindex].indice].findIndex(tp => tp.CLAVE === rowData.CLAVE);
        break;
        //CDPJ
      case 'busquedaBancoClienteAbonoCH':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
      case 'busquedaBancoClienteCancelacionCH':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'busquedaBancoClienteAnticipoCH':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      break;
      //CDPJ
      default:
        this.indexunidad = 0;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'BANCLI_CODIGO' },
      { label: 'Banco', value: 'BANCLI_NOMBRE' }
    ];
    if (parametro === null) {
      parametro = '';
    }
    this.busquedacampos = [parametro, ''];
    this.consulta = '*';
    this.tabla = 'CXC_MAEBANCLI';
    this.where = '';

    this.busqService.encontrarRegistro17(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda('*', 'CXC_MAEBANCLI', '');
        }
      } else {
        this.abrirDialogBusqueda('*', 'CXC_MAEBANCLI', '');
      }
    });
  }

  busquedaTarjetaC(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'busquedaTarjetaC':
        this.indexunidad = this.pagconservice.pagos.findIndex(tp => tp.CLAVE === rowData.CLAVE);
        break;
      case 'busquedaTarjetaCartera':
        this.indexunidad = this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice].findIndex(tp => tp.LINEA === rowData.LINEA);
        break;
        //CDPJ
      case 'busquedaTarjetaAbono':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
      case 'busquedaTarjetaCancelacion':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'busquedaTarjetaAnticipo':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      break;
      //CDPJ
      default:
        this.indexunidad = 0;
    }

    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Número', value: 'TAR_CODIGO' },
      { label: 'Descripción', value: 'TAR_DESCRIPCION' },
      { label: '%', value: 'TAR_PORCENTAJE' },
      { label: 'R.C.Interés', value: 'CON_CODIGO' },
      { label: 'R.C.Comisión', value: 'CON_CODIGOCOM' },
      { label: 'Ret.Fuente', value: 'CON_CODIGORFTE' },
      { label: 'Ret.Iva', value: 'CON_CODIGOFIVA' },
      { label: 'Ruc', value: 'TAR_RUC' },
      { label: 'CódigoSri', value: 'TAR_CODSRI' }
    ];
    if (parametro === null) {
      parametro = '';
    }
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'CXC_MAETARJETA';
    this.where = '';

    this.busqService.encontrarRegistro23(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda('*', 'CXC_MAETARJETA', '');
        }
      } else {
        this.abrirDialogBusqueda('*', 'CXC_MAETARJETA', '');
      }
    });
  }

  busquedaBanco(rowData, opcionbusqueda, parametro) {

    switch (opcionbusqueda) {
      case 'busquedaBanco':
        this.indexunidad = this.pagconservice.pagos.findIndex(tp => tp.CLAVE === rowData.CLAVE);
        break;
      case 'busquedaBancodvf':
        this.indexunidad = this.pagconservicedvf.pagos.findIndex(tp => tp.LINEA === rowData.LINEA);
        break;
      case 'busquedaBancoFP':
        this.indexunidad = this.cxcCarteraService.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice].findIndex(tp => tp.LINEA === rowData.LINEA);
        break;
      case 'busquedaBancoClienteCartera':
        this.indexunidad = this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice].findIndex(tp => tp.LINEA === rowData.LINEA);
        break;
      case 'busquedaBancoPagoRet':
        this.indexunidad = this.cxctrnpagoretService.cxcpagoretformapago.findIndex(tp => tp.LINEA === rowData.LINEA);
        parametro = rowData.BANCLI_CODIGO;
        break;
      case 'BANCODIGOcxpfpant':
        this.indexunidad = this.cxptrncobroService.fpantarray[this.init.tabs[this.init.tabindex].indice].findIndex(tp => tp.CLAVE === rowData.CLAVE);
        break;
      case 'BANCLICODIGOcxpfpdev':
        this.indexunidad = this.cxptrncobroService.fpdevarray[this.init.tabs[this.init.tabindex].indice].findIndex(tp => tp.CLAVE === rowData.CLAVE);
        break;
        //CDPJ
      case 'busquedaBancoClienteAbono':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'busquedaBancoClienteCancelacion':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'busquedaBancoClienteAnticipo':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      break;
      //CDPJ
      default:
        this.indexunidad = 0;
    }

    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'BAN_CODIGO' },
      { label: 'Banco', value: 'BAN_BANCO' },
      { label: 'Sucursal', value: 'BAN_SUCURSAL' },
      { label: 'Cuenta Nro', value: 'BAN_CTANRO' },
      { label: 'Jefe Cuenta', value: 'BAN_JEFECTA' },
      { label: 'Direccion', value: 'BAN_DIRECCION' },
      { label: 'Telefono1', value: 'BAN_TELEFONO1' },
      { label: 'Telefono2', value: 'BAN_TELEFONO2' },
      { label: 'Correo', value: 'BAN_CORREO' },
      { label: 'Codigo contable', value: 'CON_CODIGO' },
      { label: 'Centro de Costo', value: 'CEN_CODIGO' },
      { label: 'Tipo de Cuenta', value: 'BAN_TIPOCUENTA' },
      { label: 'Doc. por Pagar', value: 'CON_CODIGO_2' },
      { label: 'Proceso', value: 'BAN_PROCESO_CM' },
      { label: 'directorioCM', value: 'BAN_DIRECTORIO_CM' },
      { label: 'DineroElec', value: 'BAN_DINEROELEC' },
      { label: 'TelCel', value: 'BAN_TELEFONOCEL' },
      { label: 'Ult.Cheque', value: 'BAN_ULTIMOCHEQUE' }
    ];
    if (parametro === null) {
      parametro = '';
    }
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'BAN_MAEBAN';
    this.where = 'BAN_CODIGO IS NOT NULL ORDER BY BAN_CODIGO ASC';

    this.busqService.encontrarRegistro24(parametro).subscribe(eR => {
      /* if (eR !== null) {
        this.manejarSeleccion(eR[0]);
      } else {
        this.abrirDialogBusqueda('*', 'BAN_MAEBAN', '');
      } */
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda('*', 'BAN_MAEBAN', this.types[0].value + ' LIKE \'%' + parametro + '%\' order by BAN_CODIGO asc ');
        }
      } else {
        this.abrirDialogBusqueda('*', 'BAN_MAEBAN', this.types[0].value + ' LIKE \'%' + parametro + '%\' order by BAN_CODIGO asc ');
      }
    });

  }

  busquedalotes(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'busquedalotes':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
      default:
        this.indexunidad = 0;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Número', value: 'LOTE_NUMERO' },
      { label: 'Vencimiento', value: 'LOTE_FECHAVENCIMIENTO' },
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = '*';
    this.tabla = 'INV_MAELOTE';
    this.where = '';

    this.busqService.busquedaInvMaeLote1().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      if (this.arregloCons !== null) {
        this.arregloCons.map((registro) => {
          if (registro.LOTE_FECHAVENCIMIENTO !== null) {
            registro.LOTE_FECHAVENCIMIENTO = this.datePipe.transform(registro.LOTE_FECHAVENCIMIENTO, 'dd/MM/yyyy');
          } else {
            registro.LOTE_FECHAVENCIMIENTO = '';
          }
        });
      }
      this.displayDialogBusquedaFast = true;
    });
  }

  busquedalotescant(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'busquedalotes':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
      default:
        this.indexunidad = 0;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Número', value: 'LOTE_NUMERO' },
      { label: 'Vencimiento', value: 'LOTE_FECHAVENCIMIENTO' },
      { label: 'Cantidad', value: 'LOTE_CANTIDAD' },
    ];
    this.busquedacampos = [parametro, '','',''];
    this.consulta = 'ART_CODIGO, LOTE_NUMERO, TO_DATE(TO_CHAR(LOTE_FECHAVENCIMIENTO,\'DD/MM/YYYY\'),\'DD/MM/YYYY\') AS LOTE_FECHAVENCIMIENTO,'
      + 'INV_FNC_EXISTENCIALOTE(LOTE_NUMERO,ART_CODIGO) AS LOTE_CANTIDAD';
    this.tabla = 'INV_MAELOTE';
    this.where = 'ART_CODIGO=\'' + rowData.ART_CODIGO
      + '\' AND inv_fnc_existencialote(lote_numero,ART_CODIGO)>0';

     //this.busqService.busquedaInvMaeLote2(rowData.ART_CODIGO).subscribe((datos: any[]) => {
    //   this.arregloCons = datos;
    //   if (this.arregloCons !== null) {
    //     this.arregloCons.map((registro) => {
    //       if (registro.LOTE_FECHAVENCIMIENTO !== null) {
    //         registro.LOTE_FECHAVENCIMIENTO = this.datePipe.transform(registro.LOTE_FECHAVENCIMIENTO, 'dd/MM/yyyy');
    //       } else {
    //         registro.LOTE_FECHAVENCIMIENTO = '';
    //       }
    //     });
    //   }
    //   this.displayDialogBusquedaFast = true;
    // });

    this.busqService.encontrarRegistro53(parametro,rowData.ART_CODIGO).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
         // this.abrirDialogBusqueda(this.consulta, 'INV_MAELOTE', this.types[0].value + ' LIKE \'%' + parametro + '%\''+' and '+this.where);
         this.busqService.busquedaInvMaeLote2(rowData.ART_CODIGO).subscribe((datos: any[]) => {
      this.arregloCons = datos;
      if (this.arregloCons !== null) {
        this.arregloCons.map((registro) => {
          if (registro.LOTE_FECHAVENCIMIENTO !== null) {
            registro.LOTE_FECHAVENCIMIENTO = this.datePipe.transform(registro.LOTE_FECHAVENCIMIENTO, 'dd/MM/yyyy');
          } else {
            registro.LOTE_FECHAVENCIMIENTO = '';
          }
        });
      }
      this.displayDialogBusquedaFast = true;
    });
        }
      } else {
        //this.abrirDialogBusqueda(this.consulta, 'INV_MAELOTE', this.types[0].value + ' LIKE \'%' + parametro + '%\''+' and '+this.where);
        this.busqService.busquedaInvMaeLote2(rowData.ART_CODIGO).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          if (this.arregloCons !== null) {
            this.arregloCons.map((registro) => {
              if (registro.LOTE_FECHAVENCIMIENTO !== null) {
                registro.LOTE_FECHAVENCIMIENTO = this.datePipe.transform(registro.LOTE_FECHAVENCIMIENTO, 'dd/MM/yyyy');
              } else {
                registro.LOTE_FECHAVENCIMIENTO = '';
              }
            });
          }
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  async busquedalotesMP(rowData, opcionbusqueda, parametro) {
    await this.encordproService.consultarLotesMP(this.encordproService.articulo[this.init.tabs[this.init.tabindex].indice]);
    switch (opcionbusqueda) {
      case 'busquedalotestrnlote':
        this.indexunidad = this.encordproService.lotes[this.init.tabs[this.init.tabindex].indice].findIndex(lote => lote.CLAVE === rowData.CLAVE);
        break;
      default:
        this.indexunidad = 0;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'LOTE', value: 'LOTE' },
      { label: 'CANTIDAD', value: 'CANTIDAD' },
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = '*';
    this.tabla = 'PROD_TRNTMPLOTE';
    this.where = '';

    this.busqService.busquedaProdTrnTmpLote1().subscribe((datos: any[]) => {
      this.arregloCons = datos;
      this.displayDialogBusquedaFast = true;
    });
  }

  busquedavendedor(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'busquedavendedorreglla':
        this.indexunidad = this.encordtraService.tableRegistroLLamadas[this.init.tabs[this.init.tabindex].indice].findIndex(res => res.CLAVE === rowData.CLAVE);
        break;
      case 'busquedavendedorenvcor':
        this.indexunidad = this.encordtraService.tableEnvioCorreos[this.init.tabs[this.init.tabindex].indice].findIndex(res => res.CLAVE === rowData.CLAVE);
        break;

      default:
        this.indexunidad = 0;
    }
    if (parametro === null) {
      parametro = '';
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'VEN_CODIGO' },
      { label: 'Nombre', value: 'VEN_NOMBRE' }
    ];
    this.busquedacampos = [parametro, ''];
    this.tabla = 'VEN_MAEVENDEDOR';
    this.where = '';

    this.busqService.encontrarRegistro25(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda('*', 'VEN_MAEVENDEDOR', this.types[0].value + ' LIKE \'%' + parametro + '%\'');
        }
      } else {
        this.abrirDialogBusqueda('*', 'VEN_MAEVENDEDOR', this.types[0].value + ' LIKE \'%' + parametro + '%\'');
      }
    });
  }

  busquedaProgramas(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'PROG_CONENCASIL':
        this.indexunidad = this.encasilService.detencasil.findIndex(encasil => encasil.LINEA === rowData.LINEA && encasil.DETASI_NROFILA === rowData.DETASI_NROFILA);
        break;
      default:
        this.indexunidad = 0;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'PROG_CODIGO', value: 'PROG_CODIGO' },
      { label: 'PROG_NOMBRE', value: 'PROG_NOMBRE' },
      { label: 'PROG_TIPO', value: 'PROG_TIPO' },
      { label: 'PROG_TIEMPO', value: 'PROG_TIEMPO' },
      { label: 'PROG_ESTADO', value: 'PROG_ESTADO' }

    ];

    this.busquedacampos = [parametro, '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'VEN_MAEPROGRAMAS';
    this.where = '';

    this.busqService.encontrarRegistro26(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda('*', 'VEN_MAEPROGRAMAS', this.types[0].value + ' LIKE \'%' + parametro + '%\'');
        }
      } else {
        this.abrirDialogBusqueda('*', 'VEN_MAEPROGRAMAS', this.types[0].value + ' LIKE \'%' + parametro + '%\'');
      }
    });

  }

  busquedaCentroCostos(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'CEN_CONENCASIL':
        this.indexunidad = this.encasilService.detencasil.findIndex(encasil => encasil.LINEA === rowData.LINEA && encasil.DETASI_NROFILA === rowData.DETASI_NROFILA);
        break;
      case 'CEN_BANVARIOS':
        this.indexunidad = this.bantrnbanEditService.banTrnBanVarios.findIndex(varios => varios.LINEA === rowData.LINEA);
        break;
      case 'CEN_CONDISTRIBCC':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
      case 'CEN_CODIGOTRNTARJETA':
        this.indexunidad = this.bantrnTarjetaService.detModeloVarios[this.init.tabs[this.init.tabindex].indice].findIndex(varios => varios.LINEA === rowData.LINEA);
        break;
      case 'CEN_CODIGOBANCAJA':
        this.indexunidad = this.bantrnCajaService.detModeloVarCaja.findIndex(varios => varios.LINEA === rowData.LINEA);
        break;
      case 'CENCODIGOcxpvarios':
        this.indexunidad = this.cxptrncobroService.variosarray[this.init.tabs[this.init.tabindex].indice].findIndex(varios => varios.CLAVE === rowData.CLAVE);
        break;
      case 'CENCODIGO_AJUSTES':
        this.indexunidad = this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice].findIndex(item => item.NUMERO === rowData.NUMERO);
        break;
      case 'TOM_CEN':
        this.indexunidad = this.tomService.detTomFisVarArray[this.init.tabs[this.init.tabindex].indice].findIndex(toma => toma.LINEA === rowData.LINEA);
        break;
      case 'CENCODIGO_CXPCUOTA':
        this.indexunidad = this.cxpenccuotaService.detCuotaVarArray[this.init.tabs[this.init.tabindex].indice].findIndex(toma => toma.LINEA === rowData.LINEA);
        break;
      case 'CEN_CODIGOCXPVARIOS':
        this.indexunidad = this.cxptrnpagodocService.varios.findIndex(cxpvarios => cxpvarios.CLAVE === rowData.CLAVE);
        break;
        //CDPJ
      case 'CEN_GUIA':
        this.indexunidad =  this.gridApi.getFocusedCell().rowIndex;
        break;
      case 'CEN_DEVF':
        this.indexunidad =  this.gridApi.getFocusedCell().rowIndex;
      break;
      //CDPJ
      //GSRF
      case 'CEN_CODIGOCODALT':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
      //GSRF
      default:
        this.indexunidad = 0;
    }
    this.opcionbusqueda = opcionbusqueda;

    this.types = [
      { label: 'Código', value: 'CEN_CODIGO' },
      { label: 'Nombre', value: 'CEN_NOMBRE' }
    ];

    this.busquedacampos = [parametro, ''];
    this.consulta = '*';
    this.tabla = 'CON_MAECEN';
    this.where = 'CEN_NOMBRE LIKE \'%%\' order by CEN_CODIGO';

    this.busqService.encontrarRegistro20(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda('*', 'CON_MAECEN', this.types[0].value + ' LIKE \'%' + parametro + '%\' order by CEN_CODIGO');
        }
      } else {
        this.abrirDialogBusqueda('*', 'CON_MAECEN', this.types[0].value + ' LIKE \'%' + parametro + '%\' order by CEN_CODIGO');
      }
    });

  }

  busquedaPedidos(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'busquedapedidosprod':
        this.indexunidad = this.encordproService.trnpedidos[this.init.tabs[this.init.tabindex].indice].findIndex(costo => costo.CLAVE === rowData.CLAVE);
        break;
      default:
        this.indexunidad = 0;
    }

    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Numero', value: 'ENCPED_NUMERO' },
      { label: 'L', value: 'DETPED_LINEA' },
      { label: 'Referencia', value: 'ENCPED_REFERENCIA' },
      { label: 'Cliente', value: 'CLI_CODIGO' },
      { label: 'Nombre', value: 'VEN_FNC_NOMBRE_CLIENTE(CLI_CODIGO,A.COM_CODIGO)' },
      { label: 'Fecha', value: 'ENCPED_FECHAEMISION' },
      { label: 'Codigo', value: 'DETPED_CODIGO' },
      { label: 'Descripción', value: 'DETPED_DESCRIPCION' },
      { label: 'Cantidad', value: 'DETPED_CANTIDAD' },
      { label: 'Saldo', value: 'PROD_FNC_SALPED_PROD(A.ENCPED_NUMERO,DETPED_LINEA)' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', '', '', '', '', '', ''];
    this.consulta = 'A.ENCPED_NUMERO, DETPED_LINEA, A.ENCPED_REFERENCIA, A.CLI_CODIGO,' +
      ' VEN_FNC_NOMBRE_CLIENTE(CLI_CODIGO,A.COM_CODIGO),ENCPED_FECHAEMISION, DETPED_CODIGO,' +
      'DETPED_DESCRIPCION,   DETPED_CANTIDAD,  PROD_FNC_SALPED_PROD(A.ENCPED_NUMERO,DETPED_LINEA)';
    this.tabla = 'VEN_ENCPED A,VEN_DETPED B  ';
    this.where = ' A.ENCPED_NUMERO=B.ENCPED_NUMERO AND A.COM_CODIGO=B.COM_CODIGO' +
    ' AND A.ENCPED_ESTADO<>\'A\' AND PROD_FNC_SALPED_PROD(A.ENCPED_NUMERO,DETPED_LINEA)>0' +
    ' AND DETPED_CODIGO = \'' + this.encordproService.encordpro[this.init.tabs[this.init.tabindex].indice].ART_CODIGO + '\' ';
    this.abrirDialogBusqueda(this.consulta, this.tabla, this.where +' AND a.'+this.types[0].value +
      ' LIKE \'%' + parametro + '%\'');
  }

  busquedaTecnico(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'busquedatecnicosprod':
        this.indexunidad = this.encordproService.trntecnicos[this.init.tabs[this.init.tabindex].indice].findIndex(data => data.CLAVE === rowData.CLAVE);
        break;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' },
      { label: 'Nombre C', value: 'REF_NOMBREC' },
    ];

    if (parametro === null || parametro === undefined) {
      parametro = '';
    }

    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.tabla = ' saci_maereferencia';
    this.where = " ref_tipo='TECOP' ";
    this.busqService.encontrarRegistro27(this.where, parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + ' AND REF_CODIGO LIKE \'%' + parametro + '%\'  order by ref_codigo');
        }
      } else {
        this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + ' AND REF_CODIGO LIKE \'%' + parametro + '%\'  order by ref_codigo');
      }
    });
  }

  busquedaMaquinaria(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'busquedamaquinariaprod':
        this.indexunidad = this.encordproService.trnmaquinaria[this.init.tabs[this.init.tabindex].indice].findIndex(data => data.CLAVE === rowData.CLAVE);
        break;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' },
      { label: 'Nombre C', value: 'REF_NOMBREC' },
    ];

    if (parametro === null || parametro === undefined) {
      parametro = '';
    }

    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.tabla = ' saci_maereferencia';
    this.where = " ref_tipo='MAQOP' ";
    this.busqService.encontrarRegistro27(this.where, parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + ' AND REF_CODIGO LIKE \'%' + parametro + '%\'  order by ref_codigo');
        }
      } else {
        this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + ' AND REF_CODIGO LIKE \'%' + parametro + '%\'  order by ref_codigo');
      }
    });
  }

  busquedaEtiqueta(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'busquedaetiqueta':
        this.indexunidad = this.equipoService.tecmaeequipodet[this.init.tabs[this.init.tabindex].indice].findIndex(data => data.CLAVE === rowData.CLAVE);
        break;
      case 'busquedaetiquetadetmant':
        this.indexunidad = this.tecencmantService.tecdetmant[this.init.tabs[this.init.tabindex].indice].findIndex(data => data.CLAVE === rowData.CLAVE);
        break;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'ETI_CODIGO' },
      { label: 'Nombre', value: 'ETI_NOMBRE' }
    ];

    if (parametro === null || parametro === undefined) {
      parametro = '';
    }

    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.tabla = ' TEC_MAEETIQUETA';
    this.where = '';
    this.busqService.encontrarRegistro28(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda(this.consulta, this.tabla, 'ETI_CODIGO LIKE \'%' + parametro + '%\'  order by ETI_CODIGO');
        }
      } else {
        this.abrirDialogBusqueda(this.consulta, this.tabla, 'ETI_CODIGO LIKE \'%' + parametro + '%\'  order by ETI_CODIGO');
      }
    });
  }
  busquedaTariivacodigo(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'detfacprotarivacod':
      this.indexunidad =  this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'detdevtarivacod':
      this.indexunidad =  this.gridApi.getFocusedCell().rowIndex;
      break;
      default:
        this.indexunidad = 0;
    }
    this.opcionbusqueda = opcionbusqueda;

    this.types = [
      { label: 'Código', value: 'TARIIVA_CODIGO' },
      { label: 'Descripcion', value: 'TARIIVA_DESCRIPCION' },
      { label: 'Porcentaje', value: 'TARIIVA_PORCENTAJE' }
    ];
    this.busquedacampos = ['', '', '',parametro];
    this.tabla = 'SRIFE_TARIIVA';
    this.where = 'TARIIVA_ACTIVO=\'S\'';
    this.consulta = '*';

if(Number(parametro) === 0){
  this.abrirDialogBusqueda('*', 'SRIFE_TARIIVA', this.where +' and '+this.types[2].value + ' LIKE \'%' + parametro + '%\'');
}else{
  this.busqService.encontrarRegistro52(parametro).subscribe(eR => {
    if (eR !== null) {
      if (eR[0] !== undefined) {
        this.manejarSeleccion(eR[0]);
      } else {
        this.abrirDialogBusqueda('*', 'SRIFE_TARIIVA', this.where +' and '+this.types[2].value + ' LIKE \'%' + parametro + '%\'');
      }
    } else {
      this.abrirDialogBusqueda('*', 'SRIFE_TARIIVA', this.where +' and '+this.types[2].value + ' LIKE \'%' + parametro + '%\'');
    }
  });
}
    
  }
  busquedaTariivacodigoS(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'tarivacodiovenmaeserS':
        this.indexunidad =  this.gridApi.getFocusedCell().rowIndex;
        break;
      case'tarivacodiocommaeserS':
      this.indexunidad =  this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'detordcomtarivacodS':
        this.indexunidad =  this.gridApi.getFocusedCell().rowIndex;
        break;
      case 'detnotrectarivacodS':
      this.indexunidad =  this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'detfacprotarivacodS':
      this.indexunidad =  this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'detdevtarivacodS':
      this.indexunidad =  this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'detpedtarivacodS':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'detgretarivacodS':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'detfactarivacodS':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'detdvffactarivacodS':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'detfacpvtarivacodS':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      break;
      default:
        this.indexunidad = 0;
    }
    this.opcionbusqueda = opcionbusqueda;

    this.types = [
      { label: 'Código', value: 'TARIIVA_CODIGO' },
      { label: 'Descripcion', value: 'TARIIVA_DESCRIPCION' },
      { label: 'Porcentaje', value: 'TARIIVA_PORCENTAJE' }
    ];
    this.busquedacampos = ['', '', '',parametro];
    this.tabla = 'SRIFE_TARIIVA';
    this.where = 'TARIIVA_ACTIVO=\'S\' and TARIIVA_PORCENTAJE <>0';
    this.consulta = '*';


    this.busqService.encontrarRegistro52(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda('*', 'SRIFE_TARIIVA', this.where +' and '+this.types[0].value + ' LIKE \'%' + parametro + '%\'');
        }
      } else {
        this.abrirDialogBusqueda('*', 'SRIFE_TARIIVA', this.where +' and '+this.types[0].value + ' LIKE \'%' + parametro + '%\'');
      }
    });
  }
  busquedaTariivacodigoN(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'tarivacodiovenmaeserN':
        this.indexunidad =  this.gridApi.getFocusedCell().rowIndex;
        break;
      case'tarivacodiocommaeserN':
      this.indexunidad =  this.gridApi.getFocusedCell().rowIndex;
      break;
      case 'detordcomtarivacodN':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
        case 'detnotrectarivacodN':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
        case 'detfacprotarivacodN':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
        case 'detdevtarivacodN':
          this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
        case 'detpedtarivacodN':
          this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
        case 'detgretarivacodN':
          this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
        case 'detfactarivacodN':
          this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
        case 'detdvffactarivacodN':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
        break;
        case 'detfacpvtarivacodN':
        this.indexunidad = this.gridApi.getFocusedCell().rowIndex;
      break;
      default:
        this.indexunidad = 0;
    }
    this.opcionbusqueda = opcionbusqueda;

    this.types = [
      { label: 'Código', value: 'TARIIVA_CODIGO' },
      { label: 'Descripcion', value: 'TARIIVA_DESCRIPCION' },
      { label: 'Porcentaje', value: 'TARIIVA_PORCENTAJE' }
    ];
    this.busquedacampos = ['', '', '',parametro];
    this.tabla = 'SRIFE_TARIIVA';
    this.where = 'TARIIVA_ACTIVO=\'S\' and TARIIVA_PORCENTAJE=0';
    this.consulta = '*';


    //this.busqService.encontrarRegistro52(parametro).subscribe(eR => {
      // if (eR !== null) {
      //   if (eR[0] !== undefined) {
      //     this.manejarSeleccion(eR[0]);
      //   } else {
      //     this.abrirDialogBusqueda('*', 'SRIFE_TARIIVA', this.where +' and '+this.types[0].value + ' LIKE \'%' + parametro + '%\'');
      //   }
      // } else {
       
      // }
      this.abrirDialogBusqueda('*', 'SRIFE_TARIIVA', this.where );
    //});

  }
  async manejarSeleccion(opcion) {
    this.idcell='';
      this.rowcell='';
    if (this.opcionbusqueda === 'busquedaunidadprecio') {
      this.artService.maeartprecio[this.indexunidad].UNI_CODIGO = opcion.UNI_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.artService.maeartprecio);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'ARTPRE_PRECIO');
    }
    if (this.opcionbusqueda === 'busquedaunidadparte') {
      this.artService.maeartparte[this.indexunidad].UNI_CODIGO = opcion.UNI_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.artService.maeartparte);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'ARTPARTE_CANTIDAD');
    }
    if (this.opcionbusqueda === 'busquedaunidadformula') {
      this.artService.maeartformula[this.indexunidad].UNI_CODIGO = opcion.UNI_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.artService.maeartformula);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'FOR_CANTTOTAL');
    }
    if (this.opcionbusqueda === 'busquedaarticuloparte') {
      console.log('********',opcion)
      this.artService.maeartparte[this.indexunidad].ART_CODIGOP = opcion.ART_CODIGO;
      this.artService.maeartparte[this.indexunidad].ART_NOMBRE = opcion.ART_NOMBRE;
      this.artService.maeartparte[this.indexunidad].UNI_CODIGO = opcion.ART_UNIDADVENTA;//GSRF
      this.artService.maeartparte[this.indexunidad].ART_MULTIUNI = opcion.ART_MULTIUNIDAD//GSRF
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.artService.maeartparte);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'UNI_CODIGO');
    }
    if (this.opcionbusqueda === 'busquedaDescuento') {
      this.descuentoService.venmaedescuentos[this.indexunidad].MAEDSCTO_ARTSVR = opcion.ART_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.descuentoService.venmaedescuentos);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'MAEDSCTO_PORCENTAJE');
    }
    if (this.opcionbusqueda === 'busquedaPedido') {
      let coinsidencias = 0;
      const intACTPEDVAL3 = Number(this.encpedService.getConfiguraciones('SALDP'));
      if(intACTPEDVAL3 === 1){
        for (const data of this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice]) {
          //console.log('array', data.DETPED_CODIGO)
          if (opcion.ART_CODIGO === data.DETPED_CODIGO && data.DETPED_DESCRIPCION !== '') {
            //console.log('repetido', this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_CODIGO)
            coinsidencias++;
            this.messageService.add({
              key: 'pedido',
              severity: 'warn',
              summary: 'Información',
              detail: 'El código del artículo no se puede repetir en el detalle.'
            });
            // this.pagconservice.reten[this.indexunidad].RETENCION_CODIGO = '';
            // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.pagconservice.reten);
            // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'RETENCION_CODIGO');
            //return;
            
          this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_CODIGO = '';
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice]);
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETPED_CODIGO');
          }
        }
      }
      //CDPJ
      console.log(coinsidencias)
      if(coinsidencias === 0){
        let existProdSel = opcion.EXISTENCIA;

      if (this.encpedService.obtenerConfig() === 0 && this.encpedService.cotizacionBooleanarray[this.init.tabs[this.init.tabindex].indice] === false && opcion.EXISTENCIA <= 0) {

        this.messageService.add({
          key: 'pedido',
          severity: 'error',
          summary: 'Información',
          detail: 'El articulo seleccionado no tiene existencias'
        });

        this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_CODIGO = '';
        this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_DESCRIPCION = '';
        this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_PRECIO = 0;
        this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].PRECIO = 0;
        this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_CANTIDAD = 0;
        this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_DESPACHO = 0;
        this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_DESCUENTO = 0;
        this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_TRIBIVA = '';
        this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_TRIBICE = '';
      }

      if (this.encpedService.obtenerConfig() === 1 || this.encpedService.cotizacionBooleanarray[this.init.tabs[this.init.tabindex].indice] === true || opcion.EXISTENCIA > 0) {

        this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_CODIGO = opcion.ART_CODIGO;
        this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_DESCRIPCION = opcion.ART_NOMBRE;
        this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_PRECIO = opcion.PRECIO;
        this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].PRECIO = opcion.PRECIO;
        this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_CANTIDAD = 1;
        this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_DESPACHO = 0;
        const intACTPEDVAL2 = Number(this.encpedService.getConfiguraciones('ACTPEDVAL'));
        if (intACTPEDVAL2 === 1) {
          this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_FACTURADO = Number(0).toFixed(2);
        }
        if (opcion.ART_DESCUENTO !== null) {
          this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_DESCUENTO = Number(opcion.ART_DESCUENTO);
        } else if (opcion.ART_DESCUENTO === null) {
          this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_DESCUENTO = 0;
        }
        this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_MULTIUNIDAD = opcion.ART_MULTIUNIDAD;
        if (opcion.ART_MULTIUNIDAD === 'S') {
          let opciones = [];
          //opciones.push(opcion.ART_UNIDADVENTA);
          // this.utilitariosService.getUnidadesEqui(opcion.ART_UNIDADVENTA).subscribe((res) => {
          //   if (res !== null) {
          //     res.map((unidades) => {
          //       opciones.push(unidades.UNI_CODIGODESTINO);
          //     });
          //   }
          // });
          let intNumReg = 1;
          this.utilitariosService.obtenerUnidadEqui(opcion.ART_CODIGO).subscribe((uni) => {
            if (uni !== null) {

              uni.forEach((re) => {
                if (intNumReg === 1) {
                  opciones.push(re.UNI_CODIGO);
                }
                opciones.push(re.UNI_CODIGODESTINO);
                intNumReg = intNumReg + 1;
              });
            }

          });
          this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_UNIDAD = opcion.ART_UNIDADVENTA;
          this.columnDefs[5].cellEditorParams = {
            values: opciones
          };
          this.refreshColumnDefs();
          // this.utilitariosService.getListaPrecioArt(opcion.ART_CODIGO).subscribe
          this.utilitariosService.verificarLista(opcion.ART_CODIGO).subscribe((res1) => {
            let opciones = [];
            opciones.push(this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_LISTA);
            res1.map((codigo) => {
              if (codigo.ARTPRE_CODIGO !== opciones[0]) {
                opciones.push(codigo.ARTPRE_CODIGO);
              }
            });
            this.columnDefs[12].cellEditorParams = {
              values: opciones
            };
            this.refreshColumnDefs();

            if (this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ENCPED_IVA === '0') {
              this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_TRIBIVA = 'N';
              this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_TRIBICE = 'N';
              this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_PORIVA=''
              this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=''

            } else if (this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ENCPED_IVA !== '0') {
              this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_TRIBIVA = opcion.ART_TRIBUTAIVA;
              this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_TRIBICE = opcion.ART_TRIBUTAICE;
              if(opcion.TARIIVA_CODIGO !== null && opcion.TARIIVA_CODIGO !== undefined && opcion.TARIIVA_CODIGO !==''){
                console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO))
                  const porceiva= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_PORCENTAJE
                  const tariivacod= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_CODIGO
                  if(porceiva !== null && porceiva !== undefined && porceiva !== ''){
                    this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_PORIVA=Number(porceiva).toFixed(this.confIniciales.getNumDecSist())
                  }if(tariivacod !== null && tariivacod !== undefined && tariivacod !== ''){
                    this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=tariivacod
                  }
                }else{
                  this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_PORIVA=''
                  this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=''
                  }
            }
            
           
            this.refreshColumnDefs();
            this.encpedService.calcularTotal();
            this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice]);
            this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETPED_CANTIDAD');
            // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].tabToNextCell();
            // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].tabToNextCell();
          });
        } else if (opcion.ART_MULTIUNIDAD === 'N' || opcion.ART_MULTIUNIDAD === null) {
          this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_UNIDAD = opcion.ART_UNIDADVENTA;
          this.columnDefs[5].cellEditorParams = {
            values: [this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_UNIDAD]
          };
          this.refreshColumnDefs();
          // this.utilitariosService.getListaPrecioArt(opcion.ART_CODIGO).subscribe
          this.utilitariosService.verificarLista(opcion.ART_CODIGO).subscribe((res1) => {
            let opciones = [];
            opciones.push(this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_LISTA);
            res1.map((codigo) => {
              if (codigo.ARTPRE_CODIGO !== opciones[0]) {
                opciones.push(codigo.ARTPRE_CODIGO);
              }
            });
            this.columnDefs[12].cellEditorParams = {
              values: opciones
            };
            this.refreshColumnDefs();
            // if (this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ENCPED_IVA === '0') {
            //   this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_TRIBIVA = 'N';
            //   this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_TRIBICE = 'N';
            // } else if (this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ENCPED_IVA !== '0') {
            //   this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_TRIBIVA = opcion.ART_TRIBUTAIVA;
            //   this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_TRIBICE = opcion.ART_TRIBUTAICE;
            // }
            if (this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ENCPED_IVA === '0') {
              this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_TRIBIVA = 'N';
              this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_TRIBICE = 'N';
              this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_PORIVA=''
              this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=''

            } else if (this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ENCPED_IVA !== '0') {
              this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_TRIBIVA = opcion.ART_TRIBUTAIVA;
              this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_TRIBICE = opcion.ART_TRIBUTAICE;
              if(opcion.TARIIVA_CODIGO !== null && opcion.TARIIVA_CODIGO !== undefined && opcion.TARIIVA_CODIGO !==''){
                console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO))
                  const porceiva= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_PORCENTAJE
                  const tariivacod= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_CODIGO
                  if(porceiva !== null && porceiva !== undefined && porceiva !== ''){
                    this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_PORIVA=Number(porceiva).toFixed(this.confIniciales.getNumDecSist())
                  }if(tariivacod !== null && tariivacod !== undefined && tariivacod !== ''){
                    this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=tariivacod
                  }
                }else{
                  this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_PORIVA=''
                  this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=''
                  }
            }
            this.refreshColumnDefs();
            this.encpedService.calcularTotal();
            this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice]);
            this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETPED_CANTIDAD');
            // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].tabToNextCell();
            // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].tabToNextCell();
          });
        }
        const intACTPEDVAL = Number(this.encpedService.getConfiguraciones('ACTPEDVAL'));
        if (intACTPEDVAL === 1) {
          if (opcion.ART_COMPUESTO === 'S') {
            this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_TIPODET = 'Z';
            this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_FACTURADO = Number(0).toFixed(2);
            const item = this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad];
            const data = await this.encpedService.consultarComponentes(item.DETPED_CODIGO, item.BOD_CODIGO, item.DETPED_LISTA);
            for (const rs of data) {
              const detped: VenDetped = await this.encpedService.crearNuevoItemServ();
              detped.DETPED_CODIGO = rs.ART_CODIGOP;
              detped.DETPED_UNIDAD = rs.UNI_CODIGO;
              detped.DETPED_DESCRIPCION = rs.ART_NOMBRE;
              detped.DETPED_CANTIDAD = rs.ARTPARTE_CANTIDAD;
              detped.DETPED_TIPODET = 'Y';
              detped.DETPED_LISTA = item.DETPED_LISTA;
              detped.BOD_CODIGO = item.BOD_CODIGO;
              detped.DETPED_PROMOCION = '0';
              detped.ENCPED_NUMERO = '';
              if (rs.PRECIO !== undefined) {
                detped.DETPED_PRECIO = rs.PRECIOC;
              } else {
                detped.DETPED_PRECIO = 0;
              }
              detped.DETPED_DESCUENTO = 0;
              console.log(detped);
              const dato = await this.encpedService.consultarArticuloSel(detped.DETPED_CODIGO,
                detped.DETPED_LISTA, detped.BOD_CODIGO);
              for (const data of dato) {
                if (this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ENCPED_IVA !== '0') {
                  detped.DETPED_TRIBIVA = data.ART_TRIBUTAIVA;
                  detped.DETPED_TRIBICE = data.ART_TRIBUTAICE;
                  //CDPJ
                  if(data.TARIIVA_CODIGO !== null && data.TARIIVA_CODIGO !== undefined && data.TARIIVA_CODIGO !==''){
                    console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === data.TARIIVA_CODIGO))
                      const porceiva= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === data.TARIIVA_CODIGO).TARIIVA_PORCENTAJE
                      const tariivacod= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === data.TARIIVA_CODIGO).TARIIVA_CODIGO
                      if(porceiva !== null && porceiva !== undefined && porceiva !== ''){
                        detped.DETPED_PORIVA=Number(porceiva).toFixed(this.confIniciales.getNumDecSist())
                      }if(tariivacod !== null && tariivacod !== undefined && tariivacod !== ''){
                        detped.TARIIVA_CODIGO=tariivacod
                      }
                    }else{
                  detped.DETPED_PORIVA='';
                  detped.TARIIVA_CODIGO='';
                      }
                  //CDPJ
                } else {
                  detped.DETPED_TRIBIVA = 'N';
                  detped.DETPED_TRIBICE = 'N';
                  //CDPJ
                  detped.DETPED_PORIVA='';
                  detped.TARIIVA_CODIGO='';
                  //CDPJ
                }
                detped.DETPED_TRIBICE = data.ART_TRIBUTAICE;
                detped.DETPED_UNIDAD = data.ART_UNIDADVENTA;
                detped.ART_MULTIUNIDAD = data.ART_MULTIUNIDAD;
              }

              this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice].push(detped);
              this.encpedService.calcularTotal();
              this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice]);
              this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETPED_CANTIDAD');
            }
          }
        }
        //CDPJ
        try {
          const dato = await this.encfacService.erArtPreDescuento(this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_CODIGO,
            this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_LISTA);
          //console.log('1.0',dato['ARTPRE_DESCUENTO']);
          if (dato !== null) {
            ///console.log('1',dato[0].ARTPRE_DESCUENTO);
            if (dato.length > 0) {
              //console.log('2',dato[0]);
              if (dato[0].ARTPRE_CODIGO !== null) {
                this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_DESCUENTO = Number(dato[0].ARTPRE_DESCUENTO);
                console.log('descuento', this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_DESCUENTO);
              } else {
                this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_DESCUENTO = 0;
              }
            } else {
              this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_DESCUENTO = 0;
            }

          } else {
            this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_DESCUENTO = 0;
          }
        } catch (err) {

        }

        //CDPJ
        /// configuracion descuento por linea segun cliente 
        // GSRF
        const DESCLINAUT = Number(this.encpedService.getConfiguraciones('DESCLINAUT'));
        if(DESCLINAUT === 1){ 
          console.log('ENTRA CONF * LINEA', this.encpedService.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO);
          const desc = await this.encfacService.descxlineacli( this.encpedService.encabezadoPedidoSeleccionadoarray[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO);
          console.log('DESCUENTO LINEA',desc[0].CLI_DESCUENTO);
          this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_DESCUENTO = desc[0].CLI_DESCUENTO;
        }
        // GSRF
        //GSRF
        if (this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_TRIBICE==='S'){
          this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_ICE = opcion.ART_VALORICE;
         console.log('/*/*/*/*', this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_ICE) 
        }
        //GSRF
        this.refreshColumnDefs();
        this.encpedService.calcularTotal();
      }
        this.refreshColumnDefs();
        this.encpedService.calcularTotal();
      }

      
    }

    //CDPJ
if(this.opcionbusqueda === 'CEN_PED'){
  this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
  this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice]);
  this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CEN_CODIGO');
}
    //CDPJ
    if (this.opcionbusqueda === 'busquedaarticulosnotasrec') {

      this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETNOTREC_CODIGO = opcion.ART_CODIGO;
      this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETNOTREC_DESCRIPCION = opcion.ART_NOMBRE;
      this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETNOTREC_TRIBIVA = opcion.ART_TRIBUTAIVA;
      this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETNOTREC_TRIBICE = opcion.ART_TRIBUTAICE;
      this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETNOTREC_UNIDAD = opcion.ART_UNIDADCOSTEO;
       //CDPJ
       if(opcion.TARIIVA_CODIGO !== null && opcion.TARIIVA_CODIGO !== undefined && opcion.TARIIVA_CODIGO !==''){
        const porceiva= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_PORCENTAJE
      const tariivacod= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_CODIGO
      if(porceiva !== null && porceiva !== undefined && porceiva !== ''){
        this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETNOTREC_PORIVA=Number(porceiva)
      }if(tariivacod !== null && tariivacod !== undefined && tariivacod !== ''){
        this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=tariivacod
      }
      }else{
        this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETNOTREC_PORIVA=''
        this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=''
        // if(opcion.ART_TRIBUTAIVA === 'S'){
        //   this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETNOTREC_PORIVA=this.comnotrecServicio.porceiva
        //   this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=this.comnotrecServicio.tariivacod
        //    }else{
        //     this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETNOTREC_PORIVA=0
        //     this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=''
        //    }
      }
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETNOTREC_CANTIDAD');
      this.cambioboton.emit(true);
      //CDPJ
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETNOTREC_CANTIDAD');
      this.cambioboton.emit(true);

      //this.encpedService.calcularTotal();
    }
    if (this.opcionbusqueda === 'busquedaservicioNotRec') {
      console.log('entrando bien');
      this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETNOTREC_CODIGO = opcion.SERCOM_CODIGO;
      this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETNOTREC_DESCRIPCION = opcion.SERCOM_DESCRIPCION;
      this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETNOTREC_TRIBIVA = opcion.SERCOM_TRIBUTAIVA;
      this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETNOTREC_TRIBICE = 'N';
      //CDPJ
      if(opcion.TARIIVA_CODIGO !== null && opcion.TARIIVA_CODIGO !== undefined && opcion.TARIIVA_CODIGO !==''){
        const porceiva= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_PORCENTAJE
      const tariivacod= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_CODIGO
      if(porceiva !== null && porceiva !== undefined && porceiva !== ''){
        this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETNOTREC_PORIVA=Number(porceiva)
      }if(tariivacod !== null && tariivacod !== undefined && tariivacod !== ''){
        this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=tariivacod
      }
      }else{
        this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETNOTREC_PORIVA=''
        this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=''
        // if(opcion.SERCOM_TRIBUTAIVA === 'S'){
        //   this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETNOTREC_PORIVA=this.comnotrecServicio.porceiva
        //   this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=this.encordService.codtariiva
        //    }else{
        //     this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETNOTREC_PORIVA=0
        //     this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=''
        //    }
      }
    //CDPJ
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETNOTREC_CANTIDAD');
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].ensureIndexVisible(this.indexunidad, 'bottom');
      this.cambioboton.emit(true);

      //this.encpedService.calcularTotal();
    }
    if (this.opcionbusqueda === 'busquedaOrden') {
      this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_CODIGO = opcion.ART_CODIGO;
      this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_DESCRIPCION = opcion.ART_NOMBRE;
      this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_COSTO = opcion.COSTO;
      this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].PRECIO = opcion.COSTO;
      this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_CANTIDAD = 0;
      this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_DESPACHO = 0;
      if (opcion.ART_DESCUENTO !== null) {
        this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_DESCUENTO = Number(opcion.ART_DESCUENTO);
      } else if (opcion.ART_DESCUENTO === null) {
        this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_DESCUENTO = 0;
      }
      this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_MULTIUNIDAD = opcion.ART_MULTIUNIDAD;
      // if (opcion.ART_MULTIUNIDAD === 'S') {
      //   let opciones = [];
      //   opciones.push(opcion.ART_UNIDADVENTA);
      //   this.utilitariosService.getUnidadesEqui(opcion.ART_UNIDADVENTA).subscribe((res) => {
      //     if (res !== null) {
      //       res.map((unidades) => {
      //         opciones.push(unidades.UNI_CODIGODESTINO);
      //       });
      //     }
      //   });
      //   this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_UNIDAD = opcion.ART_UNIDADCOSTEO;
      //   this.columnDefs[5].cellEditorParams = {
      //     values: opciones
      //   };
      //   this.refreshColumnDefs();
      //   this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_TRIBIVA = opcion.ART_TRIBUTAIVA;
      //   this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_TRIBICE = opcion.ART_TRIBUTAICE;
      //   this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice]);
      //   this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETORDCOM_CANTIDAD');
      //   this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_UNIDAD = opcion.ART_UNIDADCOSTEO;
      //
      // } else if (opcion.ART_MULTIUNIDAD === 'N' || opcion.ART_MULTIUNIDAD === null) {
      this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_UNIDAD = opcion.ART_UNIDADCOSTEO;
      this.columnDefs[5].cellEditorParams = {
        values: [this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_UNIDAD]
      };
      this.refreshColumnDefs();
      this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_TRIBIVA = opcion.ART_TRIBUTAIVA;
      this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_TRIBICE = opcion.ART_TRIBUTAICE;
    //CDPJ
      //const porceiva=this.encfacService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'DESCLINAUT').CFG_VALOR1;
      
      if(opcion.TARIIVA_CODIGO !== null && opcion.TARIIVA_CODIGO !== undefined && opcion.TARIIVA_CODIGO !==''){
        console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO))
          const porceiva= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_PORCENTAJE
          const tariivacod= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_CODIGO
          if(porceiva !== null && porceiva !== undefined && porceiva !== ''){
            this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_PORIVA=Number(porceiva)
          }if(tariivacod !== null && tariivacod !== undefined && tariivacod !== ''){
            this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=tariivacod
          }
       }else{
        this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_PORIVA='';
        this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';
        //  if(opcion.ART_TRIBUTAIVA === 'S'){
        //    if(Number(this.encordService.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_PORCEIVA) === 0){
        //     this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_PORIVA=this.encordService.porcetariiva
        //     this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=''
        //    }else{
        //     this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_PORIVA=this.encordService.porcetariiva
        //     this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=this.encordService.codtariiva
        //    }
        
        //  }else{
        //   if(Number(this.encordService.encOrdSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCORDCOM_PORCEIVA) !== 0){
        // this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_PORIVA=0;
        // this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='0';
        //   }else{
        //   this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_PORIVA=this.encordService.porcetariiva
        //   this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=this.encordService.codtariiva
        //   }
        //  }
      }
     //CDPJ





      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETORDCOM_CANTIDAD');
      // }
      this.encordService.calcularTotal();
    }
    if (this.opcionbusqueda === 'busquedaGuia') {
      console.log('/*/*/*/*',opcion)
      console.log('/*/*/*/*',opcion)
      if (this.venenguiaRemisionService.getConfigFCEXNEG() === 0 && opcion.EXISTENCIA <= 0) {
        if (opcion.EXISTENCIA <= 0) {
          this.messageService.add({
            key: 'guiaRemision',
            severity: 'error',
            summary: 'Información',
            detail: 'El articulo seleccionado no tiene existencias'
          });

          this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_CODIGO = '';
          this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_DESCRIPCION = '';
          this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_PRECIO = 0;
          this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].PRECIO = 0;
          this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_CANTIDAD = 0;
          this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_DESPACHO = '';
          this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_CANTIDADB = 0;
          this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_CANTIDADUND = 0;
          this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].EXISTENCIA = 0;
          this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].COSTOPROM = 0;
        //CDPJ
          this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO ='';
        //CDPJ
        }

      }

      if (this.venenguiaRemisionService.getConfigFCEXNEG() === 1 || opcion.EXISTENCIA > 0) {


        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_CODIGO = opcion.ART_CODIGO;
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_DESCRIPCION = opcion.ART_NOMBRE;
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_PRECIO = opcion.PRECIO;
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].PRECIO = opcion.PRECIO;
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_CANTIDAD = 1;
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_DESPACHO = '';
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_CANTIDADB = 0;
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_CANTIDADUND = 0;
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].EXISTENCIA = opcion.EXISTENCIA;
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].COSTOPROM = opcion.COSTOPROM.replace(',', '.');
        //CDPJ
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
        //CDPJ
        if (opcion.ART_CANTMIN !== null) {
          this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_CANTMIN = opcion.ART_CANTMIN;
        } else if (opcion.ART_CANTMIN === null || opcion.ART_CANTMIN === undefined) {
          this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_CANTMIN = 0;
        }
        if (opcion.ART_DESCUENTO !== null) {
          this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_DESCUENTO = Number(opcion.ART_DESCUENTO);
        } else if (opcion.ART_DESCUENTO === null) {
          this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_DESCUENTO = 0;
        }
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_MULTIUNIDAD = opcion.ART_MULTIUNIDAD;
        if (opcion.ART_MULTIUNIDAD === 'S') {
          console.log('aqui78')
          let opciones = [];
          opciones.push(opcion.ART_UNIDADVENTA);
          this.utilitariosService.getUnidadesEqui(opcion.ART_UNIDADVENTA).subscribe((res) => {
            if (res !== null) {
              res.map((unidades) => {
                opciones.push(unidades.UNI_CODIGODESTINO);
              });
            }
          });
          this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_UNIDAD = opcion.ART_UNIDADVENTA;
          this.columnDefs[5].cellEditorParams = {
            values: opciones
          };
          this.refreshColumnDefs();
          this.utilitariosService.getListaPrecioArt(opcion.ART_CODIGO).subscribe((res1) => {
            let opciones = [];
            opciones.push(this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_LISTA);
            res1.map((codigo) => {
              if (codigo.ARTPRE_CODIGO !== opciones[0]) {
                opciones.push(codigo.ARTPRE_CODIGO);
              }
            });
            this.columnDefs[10].cellEditorParams = {
              values: opciones
            };
            this.refreshColumnDefs();
            if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_IVA === '0') {
              this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_TRIBIVA = 'N';
              this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_TRIBICE = 'N';
              //CDPJ
              this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_PORIVA='';
              this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';
              //CDPJ
            } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_IVA !== '0') {
              this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_TRIBIVA = opcion.ART_TRIBUTAIVA;
              this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_TRIBICE = opcion.ART_TRIBUTAICE;
              //CDPJ
              if(opcion.TARIIVA_CODIGO !== null && opcion.TARIIVA_CODIGO !== undefined && opcion.TARIIVA_CODIGO !==''){
                console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO))
                  const porceiva= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_PORCENTAJE
                  const tariivacod= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_CODIGO
                  if(porceiva !== null && porceiva !== undefined && porceiva !== ''){
                    this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_PORIVA=Number(porceiva).toFixed(this.confIniciales.getNumDecSist())
                  }if(tariivacod !== null && tariivacod !== undefined && tariivacod !== ''){
                    this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=tariivacod
                  }
               }else{
                this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_PORIVA='';
                this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';
              //   if (opcion.ART_TRIBUTAIVA === 'S') {
              //     //this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_PORIVA = Number(this.comDevFacService.dblPorcIvaDevF);
              //     this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_PORIVA= Number(this.venenguiaRemisionService.porcetariiva).toFixed(this.confIniciales.getNumDecSist())
              //     this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=this.venenguiaRemisionService.codtariiva
              //   } else if (opcion.ART_TRIBUTAIVA === 'N') {
              //     this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_PORIVA=0;
              // this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';
              //   }
               }
              //CDPJ
            }
            this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice]);
            this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETGRE_CANTIDAD');
                    //GSRF
              console.log('/*/*/*/*', this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_TRIBICE)
              if (this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_TRIBICE==='S'){
                this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_ICE = opcion.ART_VALORICE;
              console.log('/*/*/*/*', this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_ICE) 
              }
              //GSRF
              this.venenguiaRemisionService.calcularTotal();
          });
          
        } else if (opcion.ART_MULTIUNIDAD === 'N' || opcion.ART_MULTIUNIDAD === null) {
          console.log('aqui79')
          this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_UNIDAD = opcion.ART_UNIDADVENTA;
          this.columnDefs[5].cellEditorParams = {
            values: [this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_UNIDAD]
          };
          this.refreshColumnDefs();
          this.utilitariosService.getListaPrecioArt(opcion.ART_CODIGO).subscribe((res1) => {
            let opciones = [];
            opciones.push(this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_LISTA);
            res1.map((codigo) => {
              if (codigo.ARTPRE_CODIGO !== opciones[0]) {
                opciones.push(codigo.ARTPRE_CODIGO);
              }
            });
            this.columnDefs[10].cellEditorParams = {
              values: opciones
            };
            this.refreshColumnDefs();
            // if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_IVA === '0') {
            //   this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_TRIBIVA = 'N';
            //   this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_TRIBICE = 'N';
            // } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_IVA !== '0') {
            //   this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_TRIBIVA = opcion.ART_TRIBUTAIVA;
            //   this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_TRIBICE = opcion.ART_TRIBUTAICE;
            // }
            if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_IVA === '0') {
              this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_TRIBIVA = 'N';
              this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_TRIBICE = 'N';
              //CDPJ
              this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_PORIVA='';
              this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';
              //CDPJ
            } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_IVA !== '0') {
              this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_TRIBIVA = opcion.ART_TRIBUTAIVA;
              this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_TRIBICE = opcion.ART_TRIBUTAICE;
              //CDPJ
              if(opcion.TARIIVA_CODIGO !== null && opcion.TARIIVA_CODIGO !== undefined && opcion.TARIIVA_CODIGO !==''){
                console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO))
                  const porceiva= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_PORCENTAJE
                  const tariivacod= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_CODIGO
                  if(porceiva !== null && porceiva !== undefined && porceiva !== ''){
                    this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_PORIVA=Number(porceiva).toFixed(this.confIniciales.getNumDecSist())
                  }if(tariivacod !== null && tariivacod !== undefined && tariivacod !== ''){
                    this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=tariivacod
                  }
               }else{
                this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_PORIVA='';
                this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';
              //   if (opcion.ART_TRIBUTAIVA === 'S') {
              //     //this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_PORIVA = Number(this.comDevFacService.dblPorcIvaDevF);
              //     this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_PORIVA= Number(this.venenguiaRemisionService.porcetariiva).toFixed(this.confIniciales.getNumDecSist())
              //     this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=this.venenguiaRemisionService.codtariiva
              //   } else if (opcion.ART_TRIBUTAIVA === 'N') {
              //     this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_PORIVA=0;
              // this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';
              //   }
               }
               //CDPJ
            }
            this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice]);
            this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETGRE_CANTIDAD');
                //GSRF
            console.log('/*/*/*/*', this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_TRIBICE)
            if (this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_TRIBICE==='S'){
              this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_ICE = opcion.ART_VALORICE;
            console.log('/*/*/*/*', this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_ICE) 
            }
            //GSRF
            this.venenguiaRemisionService.calcularTotal();
          });
        }
        
      } 
      this.venenguiaRemisionService.calcularTotal();
    }
    if (this.opcionbusqueda === 'busquedaDVF') {
      this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_CODIGO = opcion.ART_CODIGO;
      this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_DESCRIPCION = opcion.ART_NOMBRE;
      this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_PRECIO = opcion.PRECIO;
      this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].PRECIO = opcion.PRECIO;
      this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_CANTIDAD = 1;
      this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_DESPACHO = '';
      this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_CANTIDADUND = 0;
      if (opcion.ART_DESCUENTO !== null) {
        this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_DESCUENTO = Number(opcion.ART_DESCUENTO);
      } else if (opcion.ART_DESCUENTO === null) {
        this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_DESCUENTO = 0;
      }
      this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].MULTIUNIDAD = opcion.ART_MULTIUNIDAD;
      if (opcion.ART_MULTIUNIDAD === 'S') {
        let opciones = [];
        opciones.push(opcion.ART_UNIDADVENTA);
        this.utilitariosService.getUnidadesEqui(opcion.ART_UNIDADVENTA).subscribe((res) => {
          if (res !== null) {
            res.map((unidades) => {
              opciones.push(unidades.UNI_CODIGODESTINO);
            });
          }
        });
        this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_UNIDAD = opcion.ART_UNIDADVENTA;
        this.columnDefs[5].cellEditorParams = {
          values: opciones
        };
        this.refreshColumnDefs();
        this.utilitariosService.getListaPrecioArt(opcion.ART_CODIGO).subscribe((res1) => {
          let opciones = [];
          opciones.push(this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_LISTA);
          res1.map((codigo) => {
            if (codigo.ARTPRE_CODIGO !== opciones[0]) {
              opciones.push(codigo.ARTPRE_CODIGO);
            }
          });
          this.columnDefs[10].cellEditorParams = {
            values: opciones
          };
          this.refreshColumnDefs();
          if (this.venDevFacService.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_IVA === '0') {
            this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_TRIBIVA = 'N';
            this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_TRIBICE = 'N';
          } else if (this.venDevFacService.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_IVA !== '0') {
            this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_TRIBIVA = opcion.ART_TRIBUTAIVA;
            this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_TRIBICE = opcion.ART_TRIBUTAICE;
          }
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice]);
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETDVF_CANTIDAD');
        });
      } else if (opcion.ART_MULTIUNIDAD === 'N' || opcion.ART_MULTIUNIDAD === null) {
        this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_UNIDAD = opcion.ART_UNIDADVENTA;
        this.columnDefs[5].cellEditorParams = {
          values: [this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_UNIDAD]
        };
        this.refreshColumnDefs();
        this.utilitariosService.getListaPrecioArt(opcion.ART_CODIGO).subscribe((res1) => {
          let opciones = [];
          opciones.push(this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_LISTA);
          res1.map((codigo) => {
            if (codigo.ARTPRE_CODIGO !== opciones[0]) {
              opciones.push(codigo.ARTPRE_CODIGO);
            }
          });
          this.columnDefs[10].cellEditorParams = {
            values: opciones
          };
          this.refreshColumnDefs();
          if (this.venDevFacService.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_IVA === '0') {
            this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_TRIBIVA = 'N';
            this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_TRIBICE = 'N';
          } else if (this.venDevFacService.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_IVA !== '0') {
            this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_TRIBIVA = opcion.ART_TRIBUTAIVA;
            this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_TRIBICE = opcion.ART_TRIBUTAICE;
          }
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice]);
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETDVF_CANTIDAD');
        });
      }
      this.venDevFacService.calcularTotal();
    }
    if (this.opcionbusqueda === 'busquedaDEVCOM') {
      this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_CODIGO = opcion.ART_CODIGO;
      this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_DESCRIPCION = opcion.ART_NOMBRE;
      this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_COSTO = opcion.PRECIO;
      this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_CANTIDAD = 1;
      this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CANTIDAD = 10000;
      this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_CANTIDADUND = 0;
      this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_PORDES2 = 0;
      this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_PORDES3 = 0;
      this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_TRIBICE = opcion.ART_TRIBUTAICE;
      this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO = '';
      this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_TRIBICE = opcion.ART_TRIBUTAICE;
      this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TIPGAS_CODIGO = '';
      if (opcion.ART_TRIBUTAIVA === 'S') {
        this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_PORIVA = 12;
      } else if (opcion.ART_TRIBUTAIVA === 'N') {
        this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_PORIVA = 0;
      }
      if (opcion.ART_DESCUENTO !== 0) {
        this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_PORDES = Number(opcion.ART_DESCUENTO);
        this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_VALDES = (this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_PORDES *
          (this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_CANTIDAD * this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_COSTO)) / 100;
      } else if (opcion.ART_DESCUENTO === 0) {
        this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_PORDES = 0;
        this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_VALDES = 0;
      }
      this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].MULTIUNIDAD = opcion.ART_MULTIUNIDAD;
      if (opcion.ART_MULTIUNIDAD === 'S') {
        let opciones = [];
        opciones.push(opcion.ART_UNIDADVENTA);
        this.utilitariosService.getUnidadesEqui(opcion.ART_UNIDADVENTA).subscribe((res) => {
          if (res !== null) {
            res.map((unidades) => {
              opciones.push(unidades.UNI_CODIGODESTINO);
            });
          }
        });
        this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].UNI_CODIGO = opcion.ART_UNIDADVENTA;
        this.columnDefs[5].cellEditorParams = {
          values: opciones
        };
        this.refreshColumnDefs();
      } else if (opcion.ART_MULTIUNIDAD === 'N' || opcion.ART_MULTIUNIDAD === null) {
        this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].UNI_CODIGO = opcion.ART_UNIDADVENTA;
        this.columnDefs[5].cellEditorParams = {
          values: [this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].UNI_CODIGO]
        };
        this.refreshColumnDefs();
      }
      this.comDevFacService.calcularTotal();
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETDEV_CANTIDAD');
    }
    if (this.opcionbusqueda === 'busquedaarticulodetfac') {
      this.seleccionbusquedaarticulodetfac(opcion);
    }
    if (this.opcionbusqueda === 'busquedaarticulodetfacpv') {
      this.seleccionbusquedaarticulodetfacpv(opcion);
    }
    if (this.opcionbusqueda === 'busquedaserviciodetfac') {
      let grupo=this.encfacService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ARDFCG').CFG_VALOR1;
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_CODIGO = opcion.SER_CODIGO;
      if(grupo === 1){
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCRIPCION = opcion.SER_DESCRIPCION+' '+this.encfacService.grunombre ;
      }else{
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCRIPCION = opcion.SER_DESCRIPCION;
      }
      
      if (this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ENCFAC_IVA !== '0') {
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVA = opcion.SER_TRIBUTAIVA;
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVAAUX = opcion.SER_TRIBUTAIVA;
        if(opcion.TARIIVA_CODIGO !== null && opcion.TARIIVA_CODIGO !== undefined && opcion.TARIIVA_CODIGO !==''){
          console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO))
            const porceiva= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_PORCENTAJE
            const tariivacod= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_CODIGO
            if(porceiva !== null && porceiva !== undefined && porceiva !== ''){
              this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_PORIVA=Number(porceiva).toFixed(this.confIniciales.getNumDecSist())
            }if(tariivacod !== null && tariivacod !== undefined && tariivacod !== ''){
              this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=tariivacod
            }
          }else{
            this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_PORIVA='';//CDPJ
            this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';//CDPJ
            }
      } else {
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVA = 'N';
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVAAUX = 'N';
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_PORIVA='';//CDPJ
            this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';//CDPJ
      }
      /// configuracion descuento por linea segun cliente 
      // GSRF
      const DESCLINAUT = this.encfacService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'DESCLINAUT').CFG_VALOR1;
      if(DESCLINAUT === 1){
        console.log('ENTRA CONF * LINEA',this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO);
        const desc = await this.encfacService.descxlineacli(this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO);
        console.log('DESCUENTO LINEA',desc[0].CLI_DESCUENTO);
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCUENTO = desc[0].CLI_DESCUENTO;
        
      }
      // GSRF
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_PRECIO = opcion.SER_PRECIO;
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TOTAL = opcion.SER_PRECIO;
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBICE = 'N';
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBASENOOBJIVA = 'N';
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_CANTIDAD = 1;
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_UNIDAD = '';
      this.encfacService.calcularTotales();
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFAC_CANTIDAD');
    }
    if (this.opcionbusqueda === 'busquedacuentadetfac') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'cierre',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        /// configuracion descuento por linea segun cliente 
      // GSRF
      const DESCLINAUT = this.encfacService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'DESCLINAUT').CFG_VALOR1;
      if(DESCLINAUT === 1){
        console.log('ENTRA CONF * LINEA',this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO);
        const desc = await this.encfacService.descxlineacli(this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO);
        console.log('DESCUENTO LINEA',desc[0].CLI_DESCUENTO);
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCUENTO = desc[0].CLI_DESCUENTO;
        
      }
      // GSRF
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_CODIGO = opcion.CON_CODIGO;
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCRIPCION = opcion.CON_NOMBRE;
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVA = 'N';
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVAAUX = 'N';
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBICE = 'N';
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBASENOOBJIVA = '';
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_CANTIDAD = 1;
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_UNIDAD = '';
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_PRECIO = 0;
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TOTAL = 0;
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_PORIVA='';//CDPJ
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';//CDPJ
        this.encfacService.calcularTotales();
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFAC_CANTIDAD');
      }
    }
    if (this.opcionbusqueda === 'busquedacuentaguia') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'cierre',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_CODIGO = opcion.CON_CODIGO;
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_DESCRIPCION = opcion.CON_NOMBRE;
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_TRIBIVA = 'N';
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_TRIBICE = 'N';
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_CANTIDAD = 1;
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_UNIDAD = '';
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_PRECIO = 0;
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_CANTIDADB = 0;
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_CANTIDADUND = 0;
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_TOTAL = 0;
        //CDPJ
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_PORIVA='';
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';
        //CDPJ
        this.venenguiaRemisionService.calcularTotal();
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETGRE_CANTIDAD');
      //CDPJ
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      //CDPj
      }
    }
    if (this.opcionbusqueda === 'busquedacuentadev') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'cierre',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_CODIGO = opcion.CON_CODIGO;
        this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_DESCRIPCION = opcion.CON_NOMBRE;
        this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_TRIBIVA = 'N';
        this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_TRIBICE = 'N';
        this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_CANTIDAD = 1;
        this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_UNIDAD = '';
        this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_PRECIO = 0;
        this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_CANTIDADUND = 0;
        this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_TOTAL = 0;
        this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_PORIVA='';//CDPJ
        this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';//CDPJ
        this.venDevFacService.calcularTotal();
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETDVF_CANTIDAD');
      }
    }
    if (this.opcionbusqueda === 'busquedacuentadevCOM') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'devolucionCom',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_CODIGO = opcion.CON_CODIGO;
        this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_DESCRIPCION = opcion.CON_NOMBRE;
        this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_PORIVA = 0;
        this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_TRIBICE = 'N';
        this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_CANTIDAD = 1;
        this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CANTIDAD = 100000;
        this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].UNI_CODIGO = '';
        this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_COSTO = 0;
        this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_CANTIDADUND = 0;
        this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_TOTAL = 0;
        //CDPJ
        this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_PORIVA ='';
        this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO ='';
          //CDPJ
        this.comDevFacService.calcularTotal();
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETDEV_CANTIDAD');
      }
    }
    if (this.opcionbusqueda === 'busquedaCaja') {
      this.formapagosService.formaPagoServicio[this.indexunidad].CAJ_CODIGO = opcion.CAJ_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.formapagosService.formaPagoServicio);
      this.refreshaggrid(this.formapagosService.formaPagoServicio, 'formaPago');
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'FORMAPAGO_CODIGOSRI');
    }

    if (this.opcionbusqueda === 'busquedaCodigoSRI') {
      this.formapagosService.formaPagoServicio[this.indexunidad].FORMAPAGO_CODIGOSRI = opcion.CODSRI_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.formapagosService.formaPagoServicio);
      this.refreshaggrid(this.formapagosService.formaPagoServicio, 'formaPago');
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'FORMAPAGO_CODIGOSRI');
    }
    if (this.opcionbusqueda === 'TAR_CODSRI') {
      this.tarjetaService.cxcmaeTarjetaCredito[this.indexunidad].TAR_CODSRI = opcion.CODSRI_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.tarjetaService.cxcmaeTarjetaCredito);
    }
    if (this.opcionbusqueda === 'busquedaarticuloformula') {
      console.log('********1',opcion)
      this.artService.maeartformula[this.indexunidad].ART_CODIGOP = opcion.ART_CODIGO;
      this.artService.maeartformula[this.indexunidad].ART_NOMBRE = opcion.ART_NOMBRE;
      this.artService.maeartformula[this.indexunidad].ART_MULTIUNIDAD = opcion.ART_MULTIUNIDAD;//CDPJ
      const data = await this.artService.getUnidadCosteoArticulo(opcion.ART_CODIGO);
      for (const rs of data) {
        this.artService.maeartformula[this.indexunidad].UNI_CODIGO = rs.UNICOSTEO;
      }
      const strFecha = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
      const strHora = this.datePipe.transform(new Date(), 'HH:mm');

      const existencia = await this.artService.obtenerExistenciaBodegaProm('PED', opcion.ART_CODIGO, '', strFecha, strHora);
      for (const rs of existencia) {
        this.artService.maeartformula[this.indexunidad].ART_EXISTENCIA = Number(rs.EXIST).toFixed(this.artService.obtenerConfig('CANTIDAD', 'formula'));
      }

      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.artService.maeartformula);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'UNI_CODIGO');
    }
    if (this.opcionbusqueda === 'busquedabodega') {
      this.artService.maeexistbod[this.indexunidad].BOD_CODIGO = opcion.BOD_CODIGO;
      const strFecha = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
      const strHora = this.datePipe.transform(new Date(), 'HH:mm');
      // extrae la existencia
      this.artService.obtenerExistenciaBodega('FAC',
        this.artService.maeexistbod[this.indexunidad].ART_CODIGO, this.artService.maeexistbod[this.indexunidad].BOD_CODIGO,
        strFecha, strHora).subscribe(data => {
          for (const rs of data) {
            this.artService.maeexistbod[this.indexunidad].EXISTENCIAFECHA = rs.EXIST;
          }

          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.artService.maeexistbod);
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'ARTEXT_MAXIMA');

        });
    }
    if (this.opcionbusqueda === 'busquedabodegaent') {
      this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO = opcion.BOD_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'ART_CODIGO');
    }
    if (this.opcionbusqueda === 'bod_codigo1') {
      this.pryService.venmaepry[this.indexunidad].BOD_CODIGO1 = opcion.BOD_CODIGO;
      this.pryService.venmaepry[this.indexunidad].NOM_BOD1 = opcion.BOD_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.pryService.venmaepry);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'BOD_CODIGO1');
    }
    if (this.opcionbusqueda === 'bod_codigo2') {
      this.pryService.venmaepry[this.indexunidad].BOD_CODIGO2 = opcion.BOD_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.pryService.venmaepry);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'BOD_CODIGO2');
    }
    if (this.opcionbusqueda === 'bod_codigo3') {
      this.pryService.venmaepry[this.indexunidad].BOD_CODIGO3 = opcion.BOD_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.pryService.venmaepry);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'BOD_CODIGO3');
    }
    if (this.opcionbusqueda === 'bod_codigo4') {
      this.pryService.venmaepry[this.indexunidad].BOD_CODIGO4 = opcion.BOD_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.pryService.venmaepry);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'BOD_CODIGO4');
    }
    if (this.opcionbusqueda === 'bod_codigo5') {
      this.pryService.venmaepry[this.indexunidad].BOD_CODIGO5 = opcion.BOD_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.pryService.venmaepry);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'BOD_CODIGO5');
    }
    if (this.opcionbusqueda === 'bod_codigo6') {
      this.pryService.venmaepry[this.indexunidad].BOD_CODIGO6 = opcion.BOD_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.pryService.venmaepry);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'BOD_CODIGO6');
    }
    if (this.opcionbusqueda === 'busquedabodegadetfac') {
      //GSRF
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_CODIGO ='';
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCRIPCION ='';
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_CANTIDAD='';
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_PRECIO='';  
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TOTAL='';
      //GSRF
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO = opcion.BOD_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFAC_CODIGO');
    }
    if (this.opcionbusqueda === 'busquedabodegadetfacpv') {
      this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO = opcion.BOD_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFAC_CODIGO');
    }
    if (this.opcionbusqueda === 'busquedabodegaped') {
      const cenodigo = await this.encpedService.getbodcencodigoprom(opcion.BOD_CODIGO);//CDPJ
      //GSRF
      this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_CODIGO ='';
      this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_DESCRIPCION ='';
      this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_CANTIDAD='';
      this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_PRECIO='';  
      this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_TOTAL='';  
      //GSRF
      this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO = opcion.BOD_CODIGO;
      //CDPJ
      const intACTPEDVAL3 = Number(this.encpedService.getConfiguraciones('CCPIPED'));
      if(intACTPEDVAL3 === 1){
        if (cenodigo === null || cenodigo === undefined) {
          this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO='';
        } else{
          this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO=cenodigo[0].CEN_CODIGO === null || cenodigo[0].CEN_CODIGO === undefined ? '' : cenodigo[0].CEN_CODIGO;

        }        
      }
      //CDPJ

      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETPED_CODIGO');
    }
    if (this.opcionbusqueda === 'busquedabodegadetfacpro') {
      this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO = opcion.BOD_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFACPRO_CODIGO');
    }
    if (this.opcionbusqueda === 'FACPRO_CEN') {
      this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFACPRO_TIPOSRI');
    }
    //CDPJ
    if(this.opcionbusqueda === 'FACPRODEV_CEN'){
      this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TIPGAS_CODIGO');
    }
    if(this.opcionbusqueda === 'CXCVAR_CENCOD'){
     
      this.cxcCarteraService.cxctrnvariosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
     
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxcCarteraService.cxctrnvariosarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CEN_CODIGO');
      this.cambioboton.emit(true);

    
  }
   if (this.opcionbusqueda === 'CEN_CODIGO_ACTSALIDA') {

    this.actencsalidaService.detVariosSalidaArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      //this.actencsalidaService.detVariosSalidaArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETVARIOSSAL_DESCRIPCION = opcion.CON_NOMBRE;
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.actencsalidaService.detVariosSalidaArray[this.init.tabs[this.init.tabindex].indice]);
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CEN_CODIGO');
  }
    //CDPJ
    if (this.opcionbusqueda === 'SRI_BUS') {
      this.comService.commaeservicio[this.indexunidad].SER_FORMSRICOM = opcion.REF_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.comService.commaeservicio);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGO2');
    }

    if (this.opcionbusqueda === 'SRI_BUSVEN') {
      this.serviService.venmaeservicios[this.indexunidad].SER_FORMSRIVTAS = opcion.REF_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.serviService.venmaeservicios);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'SER_BASENOOBJIVA');
    }
    if (this.opcionbusqueda === 'REF_CODIGOIMP') {
      this.comnotrecServicio.comnotrecImportacionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].REF_CODIGO = opcion.REF_CODIGO;
      this.comnotrecServicio.comnotrecImportacionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].REF_NOMBRE = opcion.REF_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.comnotrecServicio.comnotrecImportacionarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'NOTRECIMP_BASE');
      this.cambioboton.emit(true);
    }
    //GSRF  
    if (this.opcionbusqueda === 'PRO_CODIGONOM') {
      //this.comnotrecServicio.comnotrecImportacionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].REF_CODIGO = opcion.REF_CODIGO;
      this.comnotrecServicio.comnotrecImportacionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].PRO_NOMBRE = opcion.PRO_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.comnotrecServicio.comnotrecImportacionarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'PRO_NOMBRE');
      this.cambioboton.emit(true);
    }
    //GSRF  
    if (this.opcionbusqueda === 'busquedacostosind') {
      this.artService.maecostoestand[this.indexunidad].CIN_CODIGO = opcion.CIN_CODIGO;
      this.artService.maecostoestand[this.indexunidad].CIN_NOMBRE = opcion.CIN_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.artService.maecostoestand);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CEST_COSTO');
    }
    if (this.opcionbusqueda === 'busquedacostosindprod') {
      this.encordproService.trncostosindirectos[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CIN_CODIGO = opcion.CIN_CODIGO;
      this.encordproService.trncostosindirectos[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CIN_NOMBRE = opcion.CIN_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encordproService.trncostosindirectos[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNCOSTOSIND_COSTO');
    }
    if (this.opcionbusqueda === 'busquedacihorashombre') {
      this.encordproService.trnhorashombre[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CIN_CODIGO = opcion.CIN_CODIGO;
      this.encordproService.trnhorashombre[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CIN_NOMBRE = opcion.CIN_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encordproService.trnhorashombre[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'EMP_CODIGO');
    }
    if (this.opcionbusqueda === 'busquedamaquinariaprod') {
      this.encordproService.trnmaquinaria[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNMAQ_CODIGO = opcion.REF_CODIGO;
      this.encordproService.trnmaquinaria[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNMAQ_NOMBRE = opcion.REF_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encordproService.trnmaquinaria[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNMAQ_NOMBRE');
    }
    if (this.opcionbusqueda === 'busquedatecnicosprod') {
      this.encordproService.trntecnicos[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNTEC_CODIGO = opcion.REF_CODIGO;
      this.encordproService.trntecnicos[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNTEC_NOMBRE = opcion.REF_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encordproService.trntecnicos[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNTEC_NOMBRE');
    }
    if (this.opcionbusqueda === 'busquedapedidosprod') {
      this.encordproService.trnpedidos[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ENCPED_NUMERO = opcion.ENCPED_NUMERO;
      this.encordproService.trnpedidos[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ENCPED_REFERENCIA = opcion.ENCPED_REFERENCIA;
      this.encordproService.trnpedidos[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ENCPED_FECHAEMISION = opcion.ENCPED_FECHAEMISION;
      this.encordproService.trnpedidos[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CODIGO_AUXILIAR = '';
      this.encordproService.trnpedidos[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_CODIGO = opcion.DETPED_CODIGO;
      this.encordproService.trnpedidos[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_DESCRIPCION = opcion.DETPED_DESCRIPCION;
      this.encordproService.trnpedidos[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_CANTIDAD = opcion.DETPED_CANTIDAD;
      this.encordproService.trnpedidos[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_LINEA = opcion.DETPED_LINEA;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encordproService.trnpedidos[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNTEC_NOMBRE');
    }
    if (this.opcionbusqueda === 'busquedaCodCli') {
      this.pryService.venmaepry[this.indexunidad].CLI_CODIGO = opcion.CLI_CODIGO;
      this.pryService.venmaepry[this.indexunidad].CLI_NOMBRE = opcion.CLI_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.pryService.venmaepry);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'BOD_CODIGO1');
    }
    if (this.opcionbusqueda === 'artEntrada') {
      this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_CODIGO = opcion.ART_CODIGO;
      this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_NOMBRE = opcion.ART_NOMBRE;
      this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_CADUCA = opcion.ART_CADUCA;
      this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_SERIALFLAG = opcion.ART_SERIALFLAG;
      if (this.entradaService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'ENTCOSCER').CFG_VALOR1 === 1) {
        this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETKAR_COSTOUNIT = 0;
      } else {
        this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETKAR_COSTOUNIT =
          await this.entradaService.obtenerCstPromArtEntrada(opcion.ART_CODIGO, this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].FECHA,
            this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].HORA);
      }
      // this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETKAR_COSTOUNIT = opcion.PRECIO.toString();
      this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETKAR_COSTOTOTAL =
        Number(this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETKAR_COSTOUNIT) *
        Number(this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETKAR_CANTIDAD);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETKAR_CANTIDAD');
      this.entradaService.calcularTotal();
    }
    if (this.opcionbusqueda === 'CEN') {
      this.bodService.bodegas[this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.bodService.bodegas);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CEN_CODIGO');
    }
    if (this.opcionbusqueda === 'TOM_CEN') {
      this.tomService.detTomFisVarArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.tomService.detTomFisVarArray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CEN_CODIGO');
    }
    if (this.opcionbusqueda === 'CEN_NOTASREC') {
      this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CEN_CODIGO');
    }

    if (this.opcionbusqueda === 'SAL_CEN') {
      this.salidaService.detkardexsvararray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.salidaService.detkardexsvararray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGO');
    }
    if (this.opcionbusqueda === 'CEN_SERVICIO') {
      this.comService.commaeservicio[this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.comService.commaeservicio);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'SER_FORMSRICOM');
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].ensureIndexVisible(this.indexunidad, 'bottom');
    }
    if (this.opcionbusqueda === 'CEN_VENSERVICIO') {
      this.serviService.venmaeservicios[this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.serviService.venmaeservicios);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'SER_FORMSRIVTAS');
    }
    if (this.opcionbusqueda === 'CEN_CODIGODISTRI') {
      this.distriCCService.distribucionCC[this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      this.distriCCService.distribucionCC[this.indexunidad].CEN_NOMBRE = opcion.CEN_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.distriCCService.distribucionCC);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNDIS_PORCE');
    }
    if (this.opcionbusqueda === 'CEN_CODIGOCXPVARIOS') {
      this.cxptrnpagodocService.varios[this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxptrnpagodocService.varios);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DEBE');
    }

    if (this.opcionbusqueda === 'CEN_CARGO') {
      this.cargoService.commaecargos[this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cargoService.commaecargos);

    }
    if (this.opcionbusqueda === 'CEN_BANCAJA') {
      this.BancajaServices.banmaecaja[this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.BancajaServices.banmaecaja);
    }
    if (this.opcionbusqueda === 'ENT_CEN') {
      this.entradaService.detkardexvararray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.entradaService.detkardexvararray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGO');
    }
    //CDPJ
        
    if (this.opcionbusqueda === 'ENT_CENI') {
      console.log(this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO)
      this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.entradaService.detkardexarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CEN_CODIGO');
    }
    if (this.opcionbusqueda === 'SAL_CENI') {
      this.salidaService.detkardexsarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.salidaService.detkardexsarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CEN_CODIGO');
    }
    //CDPJ

    if (this.opcionbusqueda === 'CON') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'bod',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        if (opcion.CON_CENTRO === 'Si') {
          this.columnDefs[11].editable = true;
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setColumnDefs(this.columnDefs);
        } else if (opcion.CON_CENTRO === 'No') {
          this.columnDefs[11].editable = false;
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setColumnDefs(this.columnDefs);
        }
        this.bodService.bodegas[this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.bodService.bodegas[this.indexunidad].CON_CENTRO = opcion.CON_CENTRO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.bodService.bodegas);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CEN_CODIGO');
      }
      // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'BOD_CODIGO');
    }

    if (this.opcionbusqueda === 'RETENCION') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {

        this.messageService.add({
          key: 'cxcretencion',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.cuentaContableServicio.cxcmaeretencion[this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cuentaContableServicio.cxcmaeretencion);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGO_VTA');
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].ensureIndexVisible(this.indexunidad, 'bottom');
      }
    }
    if (this.opcionbusqueda === 'RETENCIONvta') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'cxcretencion',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.cuentaContableServicio.cxcmaeretencion[this.indexunidad].CON_CODIGO_VTA = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cuentaContableServicio.cxcmaeretencion);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'RETENCION_TIPO');
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].ensureIndexVisible(this.indexunidad, 'bottom');
      }
      // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'BOD_CODIGO');
    }
    if (this.opcionbusqueda === 'CON_GRUPO') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'maegroup',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.grupoService.venmaegrupo[this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.grupoService.venmaegrupo);
      }
    }
    if (this.opcionbusqueda === 'CON_CODIGOSERV') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'maeservicios',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.serviService.venmaeservicios[this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.serviService.venmaeservicios);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'SER_PRECIO');
      }
    }
    if (this.opcionbusqueda === 'CON_CODIGOT') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'cxcmaetarjetacredito',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.tarjetaService.cxcmaeTarjetaCredito[this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.tarjetaService.cxcmaeTarjetaCredito);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGOCOM');
      }
    }
    if (this.opcionbusqueda === 'CON_CODIGOVARIOS') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'notrec',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.comnotrecServicio.comnotrecVararray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.comnotrecServicio.comnotrecVararray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_NOMBRE = opcion.CON_NOMBRE;
        this.comnotrecServicio.comnotrecVararray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CENTRO = opcion.CON_CENTRO;//CDPJ
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.comnotrecServicio.comnotrecVararray[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'NOTRECVAR_IMPORTE');
        this.cambioboton.emit(true);

      }
    }
        //CDPJ
        if (this.opcionbusqueda === 'CEN_NOTREC') {//-----------------      
       
            this.comnotrecServicio.comnotrecVararray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;  
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.comnotrecServicio.comnotrecVararray[this.init.tabs[this.init.tabindex].indice]);
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGO');
          //this.cambioboton.emit(true); 
         
      }
      //CDPJ
    if (this.opcionbusqueda === 'CON_CODIGOCOM') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'cxcmaetarjetacredito',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.tarjetaService.cxcmaeTarjetaCredito[this.indexunidad].CON_CODIGOCOM = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.tarjetaService.cxcmaeTarjetaCredito);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGORFTE');
      }
    }
    if (this.opcionbusqueda === 'CON_CODIGORFTE') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'cxcmaetarjetacredito',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.tarjetaService.cxcmaeTarjetaCredito[this.indexunidad].CON_CODIGORFTE = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.tarjetaService.cxcmaeTarjetaCredito);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGOFIVA');
      }
    }
    if (this.opcionbusqueda === 'CON_CODIGOFIVA') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'cxcmaetarjetacredito',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.tarjetaService.cxcmaeTarjetaCredito[this.indexunidad].CON_CODIGOFIVA = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.tarjetaService.cxcmaeTarjetaCredito);
      }
    }
    if (this.opcionbusqueda === 'CON_CODIGOCXC') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'cartera',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.cxcCarteraService.cxctrnvariosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.cxcCarteraService.cxctrnvariosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_NOMBRE = opcion.CON_NOMBRE;
        this.cxcCarteraService.erBancodigo(opcion.CON_CODIGO).subscribe((ban) => {
          this.cxcCarteraService.cxctrnvariosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CENTRO = opcion.CON_CENTRO;//CDPJ  
          if(ban !== null && ban != undefined){
            this.cxcCarteraService.cxctrnvariosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BAN_CODIGO = ban[0].BAN_CODIGO;
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxcCarteraService.cxctrnvariosarray[this.init.tabs[this.init.tabindex].indice]);
          }
        });
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxcCarteraService.cxctrnvariosarray[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DEBE');
      }
    }

    if (this.opcionbusqueda === 'CON_CARGOS') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'maecargos',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        if (opcion.CON_CENTRO === 'Si') {
          this.columnDefs[3].editable = true;
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setColumnDefs(this.columnDefs);
        } else if (opcion.CON_CENTRO === 'No') {
          this.columnDefs[3].editable = false;
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setColumnDefs(this.columnDefs);
        }
        this.cargoService.commaecargos[this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.cargoService.commaecargos[this.indexunidad].CON_CENTRO = opcion.CON_CENTRO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cargoService.commaecargos);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CEN_CODIGO');
      }
    }
    if (this.opcionbusqueda === 'CON_BANCAJA') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'maebancaja',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        if (opcion.CON_CENTRO === 'Si') {
          this.columnDefs[3].editable = true;
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setColumnDefs(this.columnDefs);
        } else if (opcion.CON_CENTRO === 'No') {
          this.columnDefs[3].editable = false;
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setColumnDefs(this.columnDefs);
        }
        this.BancajaServices.banmaecaja[this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.BancajaServices.banmaecaja[this.indexunidad].CON_CENTRO = opcion.CON_CENTRO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.BancajaServices.banmaecaja);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CEN_CODIGO');
      }
    }
    if (this.opcionbusqueda === 'CON_COMISION') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'comision',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.comisionService.comisionesServicio[this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.comisionService.comisionesServicio);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGO');
      }
    }
    if (this.opcionbusqueda === 'CON_SERVICIO') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'servicios',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.comService.commaeservicio[this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        //this.refreshaggrid(this.comService.commaeservicio, 'commaeservicios');
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.comService.commaeservicio);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'SERCOM_PRECIO');
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].ensureIndexVisible(this.indexunidad, 'bottom');
      }
    }
    if (this.opcionbusqueda === 'CON_SERVICIOS2') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'servicios',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.comService.commaeservicio[this.indexunidad].CON_CODIGO2 = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.comService.commaeservicio);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'SERCOM_BASENOOBJIVA');
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].ensureIndexVisible(this.indexunidad);
      }
    }
    if (this.opcionbusqueda === 'CON_COM_GRUPO') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'maecomgroup',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.commaeGrupo.commaegrupo[this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.commaeGrupo.commaegrupo);
      }
    }
    if (this.opcionbusqueda === 'ANEX_CON_CODIGO') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'anexmaecodsriKey',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.anexmaecodsriService.codSRI[this.indexunidad].CodigoCon = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.anexmaecodsriService.codSRI);
      }
    }
    if (this.opcionbusqueda === 'CON_CODIGODISTRI') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'distriCC',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.distriCCService.distribucionCC[this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.distriCCService.erContipgas(opcion.CON_CODIGO).subscribe(rs => {

          let strDato = '';
          let strTipoGasto = '';
          if (rs !== undefined) {
            if (rs[0] !== null) {
              strDato = rs[0].CON_TIPGAS;
            }
          }
          this.encfacproService.erTipGasCodigo1().subscribe(er => {
            if (er !== undefined) {
              if (er[0] !== null) {
                strTipoGasto = er[0].TIPGAS_CODIGO;
              }
            }

            if (strDato === 'Si') {
              this.distriCCService.distribucionCC[this.indexunidad].TIPGAS_CODIGO = strTipoGasto;
              this.distriCCService.distribucionCC[this.indexunidad].BOLTIPOGASTO = true;
            } else {
              this.distriCCService.distribucionCC[this.indexunidad].TIPGAS_CODIGO = '';
              this.distriCCService.distribucionCC[this.indexunidad].BOLTIPOGASTO = false;
            }
          });
        });
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.distriCCService.distribucionCC);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CEN_CODIGO');
      }
    }
    if (this.opcionbusqueda === 'CON_CODIGOCXPVARIOS') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'distriCC',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.cxptrnpagodocService.varios[this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.cxptrnpagodocService.varios[this.indexunidad].CON_NOMBRE = opcion.CON_NOMBRE;

        this.encasilService.erBancodigo(opcion.CON_CODIGO).subscribe((ban) => {
          if (ban !== null) {
            this.cxptrnpagodocService.varios[this.indexunidad].BAN_CODIGO = ban[0].BAN_CODIGO;
          } else {
            this.cxptrnpagodocService.varios[this.indexunidad].BAN_CODIGO = '';
          }
        });

        this.cxptrnpagodocService.erConTipGas(opcion.CON_CODIGO).subscribe(rs => {

          let strDato = '';
          let strTipoGasto = '';
          let strCentro = '';
          if (rs !== undefined) {
            if (rs[0] !== null) {
              strDato = rs[0].CON_TIPGAS;
              strCentro = rs[0].CON_CENTRO;
            }
          }

          this.cxptrnpagodocService.varios[this.indexunidad].CON_CENTRO = strCentro;

          this.cxptrnpagodocService.erTipGasCodigo().subscribe(er => {
            if (er !== undefined) {
              if (er[0] !== null) {
                strTipoGasto = er[0].TIPGAS_CODIGO;
              }
            }

            if (strDato === 'Si') {
              this.cxptrnpagodocService.varios[this.indexunidad].TIPGAS_CODIGO = strTipoGasto;
              this.cxptrnpagodocService.varios[this.indexunidad].BOLTIPOGASTO = true;
            } else {
              this.cxptrnpagodocService.varios[this.indexunidad].TIPGAS_CODIGO = '';
              this.cxptrnpagodocService.varios[this.indexunidad].BOLTIPOGASTO = false;
            }
          });
        });
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxptrnpagodocService.varios);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CEN_CODIGO');
      }
    }

    if (this.opcionbusqueda === 'busquedaToma') {
      this.tomService.detTomaFisicaArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO = opcion.BOD_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(
        this.tomService.detTomaFisicaArray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'ART_CODIGO');
    }
    if (this.opcionbusqueda === 'tomaBusArt') {
      this.tomService.detTomaFisicaArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_CODIGO = opcion.ART_CODIGO;
      this.tomService.detTomaFisicaArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_NOMBRE = opcion.ART_NOMBRE;
      this.tomService.detTomaFisicaArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_UNIDADCOSTEO = opcion.ART_UNIDADCOSTEO;
      this.tomService.detTomaFisicaArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNTOMAFISICA_CANTIDADK = Number(opcion.EXISTENCIA).toFixed(this.tomService.obtenerConfig('CANTIDAD', 'trntomaf'));
      this.tomService.detTomaFisicaArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNTOMAFISICA_COSTOPROM = Number(opcion.COSTOPROM).toFixed(this.tomService.obtenerConfig('COSTO', 'trntomaf'));
      this.tomService.detTomaFisicaArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNTOMAFISICA_TOTAL = Number(opcion.COSTOPROM) * Number(this.tomService.detTomaFisicaArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNVALOR_AJUSTE);
      this.tomService.detTomaFisicaArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNTOMAFISICA_TOTAL = Number(
        this.tomService.detTomaFisicaArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNTOMAFISICA_TOTAL).toFixed(this.tomService.obtenerConfig('COSTO', 'trntomaf'));
      this.tomService.btnNuevoDet[this.init.tabs[this.init.tabindex].indice] = false;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.tomService.detTomaFisicaArray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNTOMAFISICA_CANTIDAD');
    }
    if (this.opcionbusqueda === 'busquedaPromocion') {
      // this.ServicioPromo.venmaepromo[this.indexunidad].MAEPRM_ARTSRV = opcion.MAEPRM_ARTSRV;
      this.ServicioPromo.venmaepromo[this.indexunidad].MAEPRM_ARTSRV = opcion.ART_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.ServicioPromo.venmaepromo);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'MAEPRM_ARTCANT');
      this.ServicioPromo.unidades1 = [];
      this.ServicioPromo.getUnidades(opcion.ART_CODIGO).subscribe(uni => {
        let intnumreg = 1;
        for (const u of uni) {
          if (intnumreg === 1) {
            this.ServicioPromo.unidades1.push(u.UNI_CODIGO);
          }
          this.ServicioPromo.unidades1.push(u.UNI_CODIGODESTINO);
          intnumreg++;
        }
        this.columnDefs[8].cellEditorParams = {
          values: this.ServicioPromo.unidades1
        };
        this.refreshColumnDefs();
      });
    }
    if (this.opcionbusqueda === 'busquedaPromocion2') {
      // this.ServicioPromo.venmaepromo[this.indexunidad].MAEPRM_ARTSRV = opcion.MAEPRM_ARTSRV;
      this.ServicioPromo.venmaepromo[this.indexunidad].MAEPRM_ARTORG = opcion.ART_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.ServicioPromo.venmaepromo);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'MAEPRM_CANTREG');


      this.ServicioPromo.unidades2 = [];
      this.ServicioPromo.getUnidades(opcion.ART_CODIGO).subscribe(uni2 => {
        let intnumreg2 = 1;
        for (const u2 of uni2) {
          if (intnumreg2 === 1) {
            this.ServicioPromo.unidades2.push(u2.UNI_CODIGO);
          }
          this.ServicioPromo.unidades2.push(u2.UNI_CODIGODESTINO);
          intnumreg2++;
        }
        this.columnDefs[11].cellEditorParams = {
          values: this.ServicioPromo.unidades2
        };
        this.refreshColumnDefs();
      });
    }
    if (this.opcionbusqueda === 'busquedaPromocionServicio') {
      // this.ServicioPromo.venmaepromo[this.indexunidad].MAEPRM_ARTSRV = opcion.MAEPRM_ARTSRV;
      this.ServicioPromo.venmaepromo[this.indexunidad].MAEPRM_ARTSRV = opcion.SERCOM_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.ServicioPromo.venmaepromo);
      this.refreshColumnDefs();
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'MAEPRM_ARTCANT');
      this.ServicioPromo.unidades3 = [];
      this.columnDefs[8].cellEditorParams = {
        values: this.ServicioPromo.unidades3
      };
      this.refreshColumnDefs();
    }
    if (this.opcionbusqueda === 'busquedaDescuentoServicio') {
      // this.ServicioPromo.venmaepromo[this.indexunidad].MAEPRM_ARTSRV = opcion.MAEPRM_ARTSRV;
      this.descuentoService.venmaedescuentos[this.indexunidad].MAEDSCTO_ARTSVR = opcion.SERCOM_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.descuentoService.venmaedescuentos);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'MAEDSCTO_PORCENTAJE');
    }
    if (this.opcionbusqueda === 'comisionCod') {
      this.cxcCarteraService.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].COMISION_CODIGO = opcion.COMISION_CODIGO;
      this.cxcCarteraService.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNCOMISION_DESCRIPCION = opcion.COMISION_NOMBRE;
      this.cxcCarteraService.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNCOMISION_TOTALRETENIDO = 0;
      this.cxcCarteraService.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
      const strFechaActual = String(this.cxcCarteraService.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].LINEA) + new Date().getDate().toString() +
        new Date().getHours().toString() + new Date().getMinutes().toString();
      this.cxcCarteraService.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNCOMISION_NRO = strFechaActual;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxcCarteraService.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNCOMISION_TOTALRETENIDO');

    }
    //CDPJ
    if (this.opcionbusqueda === 'cxctrnabonootrosgastos') {
      this.cxctrnabonoService.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].COMISION_CODIGO = opcion.COMISION_CODIGO;
      this.cxctrnabonoService.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNCOMISION_DESCRIPCION = opcion.COMISION_NOMBRE;
      this.cxctrnabonoService.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNCOMISION_TOTALRETENIDO = 0;
      this.cxctrnabonoService.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
      const strFechaActual = String(this.cxctrnabonoService.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].LINEA) + new Date().getDate().toString() +
        new Date().getHours().toString() + new Date().getMinutes().toString();
      this.cxctrnabonoService.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNCOMISION_NRO = strFechaActual;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnabonoService.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNCOMISION_TOTALRETENIDO');

    }
    if (this.opcionbusqueda === 'cxctrncancelacionotrosgastos') {
      this.cxctrncancelacionService.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].COMISION_CODIGO = opcion.COMISION_CODIGO;
      this.cxctrncancelacionService.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNCOMISION_DESCRIPCION = opcion.COMISION_NOMBRE;
      this.cxctrncancelacionService.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNCOMISION_TOTALRETENIDO = 0;
      this.cxctrncancelacionService.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
      const strFechaActual = String(this.cxctrncancelacionService.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].LINEA) + new Date().getDate().toString() +
        new Date().getHours().toString() + new Date().getMinutes().toString();
      this.cxctrncancelacionService.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNCOMISION_NRO = strFechaActual;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrncancelacionService.cxctrnotrosgarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNCOMISION_TOTALRETENIDO');

    }
    
    //CDPJ
    if (this.opcionbusqueda === 'busquedaPedidoServicio') {
      this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_CODIGO = opcion.SER_CODIGO;
      this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_DESCRIPCION = opcion.SER_DESCRIPCION;
      this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_PRECIO = opcion.SER_PRECIO;
      this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].PRECIO = opcion.SER_PRECIO;
      this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_CANTIDAD = 1;
      this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_DESPACHO = 0;
      const intACTPEDVALSer = Number(this.encpedService.getConfiguraciones('ACTPEDVAL'));
      if (intACTPEDVALSer === 1) {
        this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_FACTURADO = Number(0).toFixed(2);
      }
      this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_DESCUENTO = 0;
      this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_UNIDAD = '';

      if (this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ENCPED_IVA === '0') {
        this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_TRIBIVA = 'N';
        this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_TRIBICE = 'N';
        this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_PORIVA=''
        this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=''
      } else if (this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ENCPED_IVA !== '0') {
        this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_TRIBIVA = opcion.SER_TRIBUTAIVA;
        this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_TRIBICE = 'N';
        if(opcion.TARIIVA_CODIGO !== null && opcion.TARIIVA_CODIGO !== undefined && opcion.TARIIVA_CODIGO !==''){
          console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO))
            const porceiva= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_PORCENTAJE
            const tariivacod= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_CODIGO
            if(porceiva !== null && porceiva !== undefined && porceiva !== ''){
              this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_PORIVA=Number(porceiva).toFixed(this.confIniciales.getNumDecSist())
            }if(tariivacod !== null && tariivacod !== undefined && tariivacod !== ''){
              this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=tariivacod
            }
          }else{
            this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_PORIVA=''
            this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=''
            }

      }



      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETPED_CANTIDAD');
      this.encpedService.calcularTotal();
    }
    if (this.opcionbusqueda === 'busquedaOrdenServicio') {
      this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_CODIGO = opcion.SERCOM_CODIGO;
      this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_DESCRIPCION = opcion.SERCOM_DESCRIPCION;
      this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_COSTO = opcion.SERCOM_PRECIO;
      this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].PRECIO = opcion.SERCOM_PRECIO;
      this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_CANTIDAD = 0;
      this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_DESPACHO = 0;
      this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_DESCUENTO = 0;
      this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_UNIDAD = '';
      this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_TRIBIVA = opcion.SERCOM_TRIBUTAIVA;
      this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_TRIBICE = 'N';
      //CDPJ
      if(opcion.TARIIVA_CODIGO !== null && opcion.TARIIVA_CODIGO !== undefined && opcion.TARIIVA_CODIGO !==''){
        console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO))
          const porceiva= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_PORCENTAJE
          const tariivacod= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_CODIGO
          if(porceiva !== null && porceiva !== undefined && porceiva !== ''){
            this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_PORIVA=Number(porceiva)
          }if(tariivacod !== null && tariivacod !== undefined && tariivacod !== ''){
            this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=tariivacod
          }
       }
       else{
        this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_PORIVA=''
        this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=''
        //  if(opcion.SERCOM_TRIBUTAIVA === 'S'){
        // this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_PORIVA=this.encordService.porcetariiva
        // this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=this.encordService.codtariiva
        //  }else{
        //   this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_PORIVA=0
        //   this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=''
        //  }
        
      }
      //CDPJ
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETORDCOM_CANTIDAD');
      this.encordService.calcularTotal();
    }
    if (this.opcionbusqueda === 'busquedaGuiaServicio') {
      this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_CODIGO = opcion.SER_CODIGO;
      this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_DESCRIPCION = opcion.SER_DESCRIPCION;
      this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_PRECIO = opcion.SER_PRECIO;
      this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].PRECIO = opcion.SER_PRECIO;
      this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_CANTIDAD = 1;
      this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_DESPACHO = '';
      this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_DESCUENTO = 0;
      this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_CANTIDADB = 0;
      this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_CANTIDADUND = 0;
      this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_UNIDAD = '';
      if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_IVA === '0') {
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_TRIBIVA = 'N';
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_TRIBICE = 'N';
        //CDPJ
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_PORIVA='';
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';
        //CDPJ
      
      } else if (this.venenguiaRemisionService.encabezadoGuiaSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCGRE_IVA !== '0') {
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_TRIBIVA = opcion.SER_TRIBUTAIVA;
        this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_TRIBICE = 'N';
        //CDPJ
        if(opcion.TARIIVA_CODIGO !== null && opcion.TARIIVA_CODIGO !== undefined && opcion.TARIIVA_CODIGO !==''){
          console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO))
            const porceiva= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_PORCENTAJE
            const tariivacod= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_CODIGO
            if(porceiva !== null && porceiva !== undefined && porceiva !== ''){
              this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_PORIVA=Number(porceiva).toFixed(this.confIniciales.getNumDecSist())
            }if(tariivacod !== null && tariivacod !== undefined && tariivacod !== ''){
              this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=tariivacod
            }
         }else{
          this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_PORIVA='';
          this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';
          // if (opcion.SER_TRIBUTAIVA === 'S') {
          //   //this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_PORIVA = Number(this.comDevFacService.dblPorcIvaDevF);
          // this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_PORIVA= Number(this.venenguiaRemisionService.porcetariiva).toFixed(this.confIniciales.getNumDecSist())
          // this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=this.venenguiaRemisionService.codtariiva
          // } else if (opcion.SER_TRIBUTAIVA === 'N') {
          // this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_PORIVA=0;
          // this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';
          // }
         }
         //CDPJ
      }

      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETGRE_CANTIDAD');
      this.venenguiaRemisionService.calcularTotal();
    }
    if (this.opcionbusqueda === 'busquedaDvfServicio') {
      this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_CODIGO = opcion.SER_CODIGO;
      this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_DESCRIPCION = opcion.SER_DESCRIPCION;
      this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_PRECIO = opcion.SER_PRECIO;
      this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].PRECIO = opcion.SER_PRECIO;
      this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_CANTIDAD = 1;
      this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_DESPACHO = '';
      this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_DESCUENTO = 0;
      this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_CANTIDADUND = 0;
      this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_UNIDAD = '';
      if (this.venDevFacService.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_IVA === '0') {
        this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_TRIBIVA = 'N';
        this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_TRIBICE = 'N';
        //CDPJ
        this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_PORIVA='';
        this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';
        //CDPJ
      } else if (this.venDevFacService.encdevfacarray[this.init.tabs[this.init.tabindex].indice].ENCDVF_IVA !== '0') {
        this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_TRIBIVA = opcion.SER_TRIBUTAIVA;
        this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_TRIBICE = 'N';
        //CDPJ
        if(opcion.TARIIVA_CODIGO !== null && opcion.TARIIVA_CODIGO !== undefined && opcion.TARIIVA_CODIGO !==''){
          console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO))
            const porceiva= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_PORCENTAJE
            const tariivacod= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_CODIGO
            if(porceiva !== null && porceiva !== undefined && porceiva !== ''){
              this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_PORIVA=Number(porceiva).toFixed(this.confIniciales.getNumDecSist())
            }if(tariivacod !== null && tariivacod !== undefined && tariivacod !== ''){
              this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=tariivacod
            }
         }else{
          this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_PORIVA='';
          this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';
         }
        //CDPJ



      }
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETDVF_CANTIDAD');
      this.venDevFacService.calcularTotal();
    }
    if (this.opcionbusqueda === 'busquedaDevServicioCom') {
      this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_CODIGO = opcion.SERCOM_CODIGO;
      this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_DESCRIPCION = opcion.SERCOM_DESCRIPCION;
      this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_COSTO = opcion.SERCOM_PRECIO;
      this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_CANTIDAD = 1;
      this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CANTIDAD = 10000;
      // this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_PORDES = 0;
      // this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_PORDES2 = 0;
      // this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_PORDES3 = 0;
      this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_CANTIDADUND = 0;
      this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].UNI_CODIGO = '';
      // if (this.comDevFacService.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_IVA === '0') {
      //   this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_PORIVA = 0;
      //   this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_TRIBICE = 'N';
      // } else if (this.comDevFacService.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_IVA !== '0') {
      //   if (opcion.SERCOM_TRIBUTAIVA === 'S') {
      //     this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_PORIVA = Number(this.comDevFacService.dblPorcIvaDevF);//CDPJ
      //   } else if (opcion.SERCOM_TRIBUTAIVA === 'N') {
      //     this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_PORIVA = 0;
      //   }
      //   this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_TRIBICE = 'N';
      // }
      //CDPJ
      
      if (this.comDevFacService.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_IVA === '0') {
        //CDPJ
        this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_PORIVA = 0;
        this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='7';
        //CDPJ
        this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_TRIBICE = 'N';

      } else if (this.comDevFacService.encDevSeleccionadaarray[this.init.tabs[this.init.tabindex].indice].ENCDEV_IVA !== '0') {
        if(opcion.TARIIVA_CODIGO !== null && opcion.TARIIVA_CODIGO !== undefined && opcion.TARIIVA_CODIGO !==''){
          console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO))
            const porceiva= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_PORCENTAJE
            const tariivacod= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_CODIGO
            if(porceiva !== null && porceiva !== undefined && porceiva !== ''){
              this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_PORIVA=Number(porceiva).toFixed(this.confIniciales.getNumDecSist())
            }if(tariivacod !== null && tariivacod !== undefined && tariivacod !== ''){
              this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=tariivacod
            }
         }
        if (opcion.SERCOM_TRIBUTAIVA === 'S') {
          //this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_PORIVA = Number(this.comDevFacService.dblPorcIvaDevF);
          this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_PORIVA= Number(this.comDevFacService.porceiva).toFixed(this.confIniciales.getNumDecSist())
          this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=this.comDevFacService.tariivacod
        } else if (opcion.SERCOM_TRIBUTAIVA === 'N') {
          this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_PORIVA = 0;
          this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';
        }
        this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_TRIBICE = 'N';
      }
      //CDPJ
      this.comDevFacService.calcularTotal();
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETDEV_CANTIDAD');
    }
    if (this.opcionbusqueda === 'busquedaPromocionServicio2') {
      // this.ServicioPromo.venmaepromo[this.indexunidad].MAEPRM_ARTSRV = opcion.MAEPRM_ARTSRV;
      this.ServicioPromo.venmaepromo[this.indexunidad].MAEPRM_ARTORG = opcion.SERCOM_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.ServicioPromo.venmaepromo);
      this.refreshColumnDefs();
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'MAEPRM_CANTREG');
      this.ServicioPromo.unidades3 = [];
      this.columnDefs[11].cellEditorParams = {
        values: this.ServicioPromo.unidades3
      };
      this.refreshColumnDefs();
    }
    if (this.opcionbusqueda === 'busquedaGrupoPromocion') {
      // this.ServicioPromo.venmaepromo[this.indexunidad].MAEPRM_ARTSRV = opcion.MAEPRM_ARTSRV;
      this.ServicioPromo.venmaepromo[this.indexunidad].GRUP_CODIGO = opcion.GRUP_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.ServicioPromo.venmaepromo);
      this.refreshColumnDefs();
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'MAEPRM_ARTSRV');
      this.ServicioPromo.unidades3 = [];
      this.columnDefs[8].cellEditorParams = {
        values: this.ServicioPromo.unidades3
      };
      this.refreshColumnDefs();
    }
    if (this.opcionbusqueda === 'UNI_CODIGODESTINO') {
      this.unidadService.maeequivalencias[this.indexunidad].UNI_CODIGODESTINO = opcion.UNI_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.unidadService.maeequivalencias);
      // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'BOD_CODIGO');
    }
    if (this.opcionbusqueda === 'CON_CODIGOGAS' && this.indice === 0) {
      this.grupService.invmaegrupos[this.indexunidad].CON_CODIGOGAS = opcion.CON_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.grupService.invmaegrupos);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGOING');
    }
    if (this.opcionbusqueda === 'CON_CODIGOING' && this.indice === 0) {
      this.grupService.invmaegrupos[this.indexunidad].CON_CODIGOING = opcion.CON_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.grupService.invmaegrupos);
      // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGOING');
    }

    if (this.opcionbusqueda === 'CON_CODIGOGAS' && this.indice === 1) {
      this.grupService.invmaesubgrupos[this.indexunidad].CON_CODIGOGAS = opcion.CON_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.grupService.invmaesubgrupos);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGOING');
    }
    //CDPJ
    if (this.opcionbusqueda === 'CON_CODIGOACT' && this.indice === 0) {
      this.grupService.invmaegrupos[this.indexunidad].CON_CODIGOACT = opcion.CON_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.grupService.invmaegrupos);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGOGAS');
    }
    if (this.opcionbusqueda === 'CON_CODIGOACT' && this.indice === 1) {
      this.grupService.invmaesubgrupos[this.indexunidad].CON_CODIGOACT = opcion.CON_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.grupService.invmaesubgrupos);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGOGAS');
    }
    //CDPJ
    if (this.opcionbusqueda === 'CON_CODIGOING' && this.indice === 1) {
      this.grupService.invmaesubgrupos[this.indexunidad].CON_CODIGOING = opcion.CON_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.grupService.invmaesubgrupos);
      // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGOING');
    }
    if (this.opcionbusqueda === 'busquedabodegasal') {
      console.log(this.init.tabs[this.init.tabindex].indice);
      console.log(this.indexunidad);
      console.log(this.salidaService.detkardexsarray);
      this.salidaService.detkardexsarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO = opcion.BOD_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.salidaService.detkardexsarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'ART_CODIGO');
    }
    if (this.opcionbusqueda === 'busquedaarticulodetallesalida') {
      this.salidaService.detkardexsarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETKAR_COSTOUNIT =
        await this.entradaService.obtenerCstPromBusArtDetSal(opcion.ART_CODIGO,
          this.salidaService.detkardexsarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].FECHA,
          this.salidaService.detkardexsarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].HORA);
      this.salidaService.detkardexsarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_CODIGO = opcion.ART_CODIGO;
      this.salidaService.detkardexsarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_NOMBRE = opcion.ART_NOMBRE;
      this.salidaService.detkardexsarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CANTIDAD_DISPONIBLE = opcion.EXISTENCIA;
      this.salidaService.detkardexsarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_CADUCA = opcion.ART_CADUCA;
      this.salidaService.detkardexsarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_SERIALFLAG = opcion.ART_SERIALFLAG;

      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.salidaService.detkardexsarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETKAR_CANTIDAD');
      this.salidaService.calcularTotal();
    }
    if (this.opcionbusqueda === 'CON_CODIGOSAL') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'cierre',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
        this.messageService.add({
          key: 'sal',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
        this.salidaService.detkardexsvararray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = '';
        this.salidaService.detkardexsvararray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_NOMBRE = '';
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.salidaService.detkardexsvararray[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETKARDEXVAR_IMPORTE');
      } else {
        this.salidaService.detkardexsvararray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.salidaService.detkardexsvararray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_NOMBRE = opcion.CON_NOMBRE;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.salidaService.detkardexsvararray[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETKARDEXVAR_IMPORTE');
      }
    }

    if (this.opcionbusqueda === 'CON_MOV') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'cierre',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.cajasService.movimientos[this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cajasService.movimientos);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNCAJ_NUMERO');
      }
    }
    if (this.opcionbusqueda === 'TOM_CON') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'tom',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.tomService.detTomFisVarArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.tomService.detTomFisVarArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_NOMBRE = opcion.CON_NOMBRE;
        this.encasilService.erConcentro(opcion.CON_CODIGO).subscribe((cen) => {
          if (cen !== null) {
            this.tomService.detTomFisVarArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CENTRO = cen[0].CON_CENTRO;
          }
        });
        this.tomService.btnNuevoDetVar[this.init.tabs[this.init.tabindex].indice] = false;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.tomService.detTomFisVarArray[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DEBE');

      }
    }
    if (this.opcionbusqueda === 'CON_CODIGOENT') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'ent',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
        this.entradaService.detkardexvararray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = '';
        this.entradaService.detkardexvararray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_NOMBRE = '';
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.entradaService.detkardexvararray[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETKARDEXVAR_IMPORTE');
     
      } else {
        this.entradaService.detkardexvararray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.entradaService.detkardexvararray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_NOMBRE = opcion.CON_NOMBRE;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.entradaService.detkardexvararray[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETKARDEXVAR_IMPORTE');
      }
    }
    if (this.opcionbusqueda === 'busquedatipopago') {
      this.seleccionbusquedatipopago(opcion);
    }
    if (this.opcionbusqueda === 'busquedaFormapagoCXC') {
      this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_TIPO = opcion.FORMAPAGO_TIPO;

      if (opcion.FORMAPAGO_TIPO === 'EF') {
        this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_CODSRI = '01';
      } else {
        this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_CODSRI =
          await this.cxcCarteraService.obtenerCodSRI(opcion.FORMAPAGO_TIPO);
      }

      if (!(opcion.FORMAPAGO_TIPO === 'DP') && !(opcion.FORMAPAGO_TIPO === 'ANCL')) {
        this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO = await this.cxcCarteraService.obtenerCaja(opcion.FORMAPAGO_TIPO);
        this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = await this.cxcCarteraService.obtenerCuentaCaja(
          this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO);
      } else {
        if (opcion.FORMAPAGO_TIPO === 'DP') { //DEPOSITO
          this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO = '';
          this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = '';
        }

        if (opcion.FORMAPAGO_TIPO === 'ANCL') { //ANTICIPO CLIENTES
          let strCodCuentaAnticipo = '';
          try {
            const rs = await this.cxcCarteraService.erConcodigo2(this.cxcCarteraService.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO);
            console.log(rs);
            strCodCuentaAnticipo = rs[0].CON_CODIGO2 === null ? '' : rs[0].CON_CODIGO2;
            this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO = await this.cxcCarteraService.obtenerCaja(opcion.FORMAPAGO_TIPO);
            this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = strCodCuentaAnticipo;
          } catch (err) {
            this.messageService.add({
              key: 'cartera',
              severity: 'error',
              summary: 'Error',
              detail: 'Proceso consultar la cuenta contable de anticipos de clientes no terminó satisfactoriamente ' + err.error
            });
          }
        }
      }

      if (String(opcion.FORMAPAGO_TIPO).length > 0) {
        this.botonNuevo = false;
      }
      //	asignar un número dependiendo del tipo de pago
      if (opcion.FORMAPAGO_TIPO === 'EF') {
        const strNumFila = this.indexunidad + 1;
        const strFechaActual = this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NUMERO = strNumFila + '' + strFechaActual;
      } else {
        this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NUMERO = '';
      }

      this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_CODIGO = '';
      this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_NOMBRE = '';
      this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NROCTA = '';


      //asignar el banco y la cuenta para los cheques según el maestro de clientes
      if (opcion.FORMAPAGO_TIPO === 'CH') {
        await this.obtenerBancoCuentaChCli();
      }

      this.cxcCarteraService.calcularTotalFormaPago();
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NUMERO');



      /*this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_TIPO = opcion.FORMAPAGO_TIPO;
      this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO = opcion.CAJ_CODIGO;
      if (opcion.FORMAPAGO_TIPO === 'EF') {
        this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_CODSRI = '01';
      } else {
        this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_CODSRI =
          await this.cxcCarteraService.obtenerCodSRI(opcion.FORMAPAGO_TIPO);
      }

      if (opcion.FORMAPAGO_TIPO === 'EF') {
        const strNumFila = this.indexunidad + 1;
        const strFechaActual = this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NUMERO = strNumFila + '' + strFechaActual;
        this.cxcCarteraService.encontrarRegistro('CON_CODIGO', 'BAN_MAECAJ',
          'CAJ_CODIGO = \'' + this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO + '\' AND COM_CODIGO = \'01\'').subscribe(d2 => {
            if (d2 !== undefined && d2 !== null) {
              this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = d2[0].CON_CODIGO;
            }
          });
      } else if (opcion.FORMAPAGO_TIPO === 'CH') {
        this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_CODSRI = '20';
        this.cxcCarteraService.encontrarRegistro('CAJ_CODIGO', 'CXC_MAEFORMAPAGO',
          'FORMAPAGO_TIPO = \'' + opcion.FORMAPAGO_TIPO + '\' AND COM_CODIGO = \'01\'').subscribe(data => {
            if (data !== undefined && data !== null) {
              this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO = data[0].CAJ_CODIGO;
              this.cxcCarteraService.encontrarRegistro('CON_CODIGO', 'BAN_MAECAJ',
                'CAJ_CODIGO = \'' + this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO + '\' AND COM_CODIGO = \'01\'').subscribe(d2 => {
                  if (d2 !== undefined && d2 !== null) {
                    this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = d2[0].CON_CODIGO;
                  }
                });
            }
          });
      } else if (opcion.FORMAPAGO_TIPO !== 'DP') {
        this.cxcCarteraService.encontrarRegistro('CAJ_CODIGO', 'CXC_MAEFORMAPAGO',
          'FORMAPAGO_TIPO = \'' + opcion.FORMAPAGO_TIPO + '\' AND COM_CODIGO = \'01\'').subscribe(data => {
            console.log(data);
            if (data !== undefined && data !== null) {
              this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO = data[0].CAJ_CODIGO;
              this.cxcCarteraService.encontrarRegistro('CON_CODIGO', 'BAN_MAECAJ',
                'CAJ_CODIGO = \'' + this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO + '\' AND COM_CODIGO = \'01\'').subscribe(d2 => {
                  console.log(d2);
                  if (d2 !== undefined && d2 !== null) {
                    this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = d2[0].CON_CODIGO;
                  }
                });
            }
          });
      } else {
        this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO = '';
        this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = '';
      }
      this.cxcCarteraService.calcularTotalFormaPago();
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NUMERO');
      this.botonNuevo = false; */
    }
    //CDPJ
    if (this.opcionbusqueda === 'busquedaFormapagoCXCTrnabono') {
      this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_TIPO = opcion.FORMAPAGO_TIPO;

      if (opcion.FORMAPAGO_TIPO === 'EF') {
        this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_CODSRI = '01';
      } else {
        this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_CODSRI =
          await this.cxctrnabonoService.obtenerCodSRI(opcion.FORMAPAGO_TIPO);
      }

      if (!(opcion.FORMAPAGO_TIPO === 'DP') && !(opcion.FORMAPAGO_TIPO === 'ANCL')) {
        this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO = await this.cxctrnabonoService.obtenerCaja(opcion.FORMAPAGO_TIPO);
        this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = await this.cxctrnabonoService.obtenerCuentaCaja(
          this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO);
      } else {
        if (opcion.FORMAPAGO_TIPO === 'DP') { //DEPOSITO
          this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO = '';
          this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = '';
        }

        if (opcion.FORMAPAGO_TIPO === 'ANCL') { //ANTICIPO CLIENTES
          let strCodCuentaAnticipo = '';
          try {
            const rs = await this.cxctrnabonoService.erConcodigo2(this.cxctrnabonoService.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO);
            console.log(rs);
            strCodCuentaAnticipo = rs[0].CON_CODIGO2 === null ? '' : rs[0].CON_CODIGO2;
            this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO = await this.cxctrnabonoService.obtenerCaja(opcion.FORMAPAGO_TIPO);
            this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = strCodCuentaAnticipo;
          } catch (err) {
            this.messageService.add({
              key: 'cxctrnabono',
              severity: 'error',
              summary: 'Error',
              detail: 'Proceso consultar la cuenta contable de anticipos de clientes no terminó satisfactoriamente ' + err.error
            });
          }
        }
      }

      if (String(opcion.FORMAPAGO_TIPO).length > 0) {
        this.botonNuevo = false;
      }
      //	asignar un número dependiendo del tipo de pago
      if (opcion.FORMAPAGO_TIPO === 'EF') {
        const strNumFila = this.indexunidad + 1;
        const strFechaActual = this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NUMERO = strNumFila + '' + strFechaActual;
      } else {
        this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NUMERO = '';
      }

      this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_CODIGO = '';
      this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_NOMBRE = '';
      this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NROCTA = '';


      //asignar el banco y la cuenta para los cheques según el maestro de clientes
      if (opcion.FORMAPAGO_TIPO === 'CH') {
        await this.obtenerBancoCuentaChCliAbono();
      }

      this.cxctrnabonoService.calcularTotalFormaPago();
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NUMERO');
    }
    if (this.opcionbusqueda === 'busquedaFormapagoCXCTrncancelacion') {
      this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_TIPO = opcion.FORMAPAGO_TIPO;

      if (opcion.FORMAPAGO_TIPO === 'EF') {
        this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_CODSRI = '01';
      } else {
        this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_CODSRI =
          await this.cxctrncancelacionService.obtenerCodSRI(opcion.FORMAPAGO_TIPO);
      }

      if (!(opcion.FORMAPAGO_TIPO === 'DP') && !(opcion.FORMAPAGO_TIPO === 'ANCL')) {
        this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO = await this.cxctrncancelacionService.obtenerCaja(opcion.FORMAPAGO_TIPO);
        this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = await this.cxctrncancelacionService.obtenerCuentaCaja(
          this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO);
      } else {
        if (opcion.FORMAPAGO_TIPO === 'DP') { //DEPOSITO
          this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO = '';
          this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = '';
        }

        if (opcion.FORMAPAGO_TIPO === 'ANCL') { //ANTICIPO CLIENTES
          let strCodCuentaAnticipo = '';
          try {
            const rs = await this.cxctrncancelacionService.erConcodigo2(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO);
            console.log(rs);
            strCodCuentaAnticipo = rs[0].CON_CODIGO2 === null ? '' : rs[0].CON_CODIGO2;
            this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO = await this.cxctrncancelacionService.obtenerCaja(opcion.FORMAPAGO_TIPO);
            this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = strCodCuentaAnticipo;
          } catch (err) {
            this.messageService.add({
              key: 'cxctrncancelacion',
              severity: 'error',
              summary: 'Error',
              detail: 'Proceso consultar la cuenta contable de anticipos de clientes no terminó satisfactoriamente ' + err.error
            });
          }
        }
      }

      if (String(opcion.FORMAPAGO_TIPO).length > 0) {
        this.botonNuevo = false;
      }
      //	asignar un número dependiendo del tipo de pago
      if (opcion.FORMAPAGO_TIPO === 'EF') {
        const strNumFila = this.indexunidad + 1;
        const strFechaActual = this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NUMERO = strNumFila + '' + strFechaActual;
      } else {
        this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NUMERO = '';
      }

      this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_CODIGO = '';
      this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_NOMBRE = '';
      this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NROCTA = '';


      //asignar el banco y la cuenta para los cheques según el maestro de clientes
      if (opcion.FORMAPAGO_TIPO === 'CH') {
        await this.obtenerBancoCuentaChCliCancelacion();
      }

      this.cxctrncancelacionService.calcularTotalFormaPago();
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NUMERO');
    }
    // if (this.opcionbusqueda === 'busquedaFormapagoCXCTrnanticipo') {
    //   this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_TIPO = opcion.FORMAPAGO_TIPO;

    //   if (opcion.FORMAPAGO_TIPO === 'EF') {
    //     this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_CODSRI = '01';
    //   } else {
    //     this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_CODSRI =
    //       await this.cxctrnanticipoService.obtenerCodSRI(opcion.FORMAPAGO_TIPO);
    //   }

    //   if (!(opcion.FORMAPAGO_TIPO === 'DP') && !(opcion.FORMAPAGO_TIPO === 'ANCL')) {
    //     this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO = await this.cxctrnanticipoService.obtenerCaja(opcion.FORMAPAGO_TIPO);
    //     this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = await this.cxctrnanticipoService.obtenerCuentaCaja(
    //       this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO);
    //   } else {
    //     if (opcion.FORMAPAGO_TIPO === 'DP') { //DEPOSITO
    //       this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO = '';
    //       this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = '';
    //     }

    //     if (opcion.FORMAPAGO_TIPO === 'ANCL') { //ANTICIPO CLIENTES
    //       let strCodCuentaAnticipo = '';
    //       try {
    //         const rs = await this.cxctrnanticipoService.erConcodigo2(this.cxctrnanticipoService.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO);
    //         console.log(rs);
    //         strCodCuentaAnticipo = rs[0].CON_CODIGO2 === null ? '' : rs[0].CON_CODIGO2;
    //         this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO = await this.cxctrnanticipoService.obtenerCaja(opcion.FORMAPAGO_TIPO);
    //         this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = strCodCuentaAnticipo;
    //       } catch (err) {
    //         this.messageService.add({
    //           key: 'cxctrnanticipos',
    //           severity: 'error',
    //           summary: 'Error',
    //           detail: 'Proceso consultar la cuenta contable de anticipos de clientes no terminó satisfactoriamente ' + err.error
    //         });
    //       }
    //     }
    //   }

    //   if (String(opcion.FORMAPAGO_TIPO).length > 0) {
    //     this.botonNuevo = false;
    //   }
    //   //	asignar un número dependiendo del tipo de pago
    //   if (opcion.FORMAPAGO_TIPO === 'EF') {
    //     const strNumFila = this.indexunidad + 1;
    //     const strFechaActual = this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
    //     this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NUMERO = strNumFila + '' + strFechaActual;
    //   } else {
    //     this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NUMERO = '';
    //   }

    //   this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_CODIGO = '';
    //   this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_NOMBRE = '';
    //   this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NROCTA = '';


    //   //asignar el banco y la cuenta para los cheques según el maestro de clientes
    //   if (opcion.FORMAPAGO_TIPO === 'CH') {
    //     await this.obtenerBancoCuentaChCliAnticipo();
    //   }

    //   this.cxctrnanticipoService.calcularTotalFormaPago();
    //   this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
    //   this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NUMERO');
    // }
    
    //CDPJ
    if (this.opcionbusqueda === 'busquedatipopagodvf') {
      this.pagconservicedvf.pagos[this.indexunidad].TRNPAGO_TIPO = opcion.FORMAPAGO_TIPO;
      this.pagconservicedvf.pagos[this.indexunidad].CAJ_CODIGO = opcion.CAJ_CODIGO;
      if (opcion.FORMAPAGO_TIPO === 'EF') {
        const strNumFila = this.indexunidad + 1;
        const strFechaActual = this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.pagconservicedvf.pagos[this.indexunidad].TRNPAGO_NUMERO = strNumFila + '' + strFechaActual;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.pagconservicedvf.pagos);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NUMERO');
      } else {
        this.pagconservicedvf.pagos[this.indexunidad].CAJ_CODIGO = '';
        this.pagconservicedvf.pagos[this.indexunidad].CON_CODIGO = '';
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.pagconservicedvf.pagos);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'BANCLI_CODIGO');
      }
    }
    if (this.opcionbusqueda === 'busquedaTipoCaj') {
      this.cxcCarteraService.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNCAJ_TIPO = opcion.FORMAPAGO_TIPO;
      this.cxcCarteraService.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO = opcion.CAJ_CODIGO;
      if (opcion.FORMAPAGO_TIPO === 'EF') {
        const strNumFila = this.indexunidad + 1;
        const strFechaActual = this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
        this.cxcCarteraService.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNCAJ_NUMERO = strNumFila + '' + strFechaActual;
        this.cxcCarteraService.erConcodigo(this.cxcCarteraService.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO).subscribe(d2 => {
          if (d2 !== undefined && d2 !== null) {
            this.cxcCarteraService.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = d2[0].CON_CODIGO;
          }
        });
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxcCarteraService.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNCAJ_NUMERO');
        this.cxcCarteraService.calcularTotalFormaPagoDev();
      } else {
        this.cxcCarteraService.erCajcodigo(opcion.FORMAPAGO_TIPO).subscribe(data => {
          console.log(data);
          if (data !== undefined && data !== null) {
            this.cxcCarteraService.erConcodigo(data[0].CAJ_CODIGO).subscribe(d2 => {
              if (d2 !== undefined && d2 !== null) {
                this.cxcCarteraService.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = d2[0].CON_CODIGO;
              }
            });
          }
        }
        );
        this.cxcCarteraService.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNCAJ_NUMERO = '';
        this.cxcCarteraService.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BAN_CODIGO = '';
        this.cxcCarteraService.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BAN_BANCO = '';
        //CDPJ
        if(this.cxcCarteraService.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].TRNCOBRO_TIPODOC === 'DAN'){
          this.cxcCarteraService.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO=''
        }
        //CDPJ
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxcCarteraService.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'BAN_CODIGO');
        this.cxcCarteraService.calcularTotalFormaPagoDev();
      }
    }
    if (this.opcionbusqueda === 'busquedapagoretFormapago') {
      this.cxctrnpagoretService.cxcpagoretformapago[this.indexunidad].TRNPAGO_TIPO = opcion.FORMAPAGO_TIPO;
      if (opcion.FORMAPAGO_TIPO === 'EF') {
        this.cxctrnpagoretService.cxcpagoretformapago[this.indexunidad].CAJ_CODIGO = opcion.CAJ_CODIGO;
        const fecha = new Date();
        const numeroEF = (this.indexunidad + 1).toString() + this.datePipe.transform(fecha, 'ddMMyyyyhhmmss');
        this.cxctrnpagoretService.cxcpagoretformapago[this.indexunidad].NUMERO = numeroEF;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnpagoretService.cxcpagoretformapago);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'NUMERO');

      } else if (opcion.FORMAPAGO_TIPO === 'CH') {
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnpagoretService.cxcpagoretformapago);
        this.cxctrnpagoretService.cxcpagoretformapago[this.indexunidad].CAJ_CODIGO = '';
        this.cxctrnpagoretService.cxcpagoretformapago[this.indexunidad].NUMERO = '';
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'BANCLI_CODIGO');
      }
    }
    if (this.opcionbusqueda === 'busquedaCajaPago') {
      this.pagconservice.pagos[this.indexunidad].CAJ_CODIGO = opcion.CAJ_CODIGO;
      this.pagconservice.erCodigoMaeCaja(opcion.CAJ_CODIGO).subscribe(er => {
        if (er !== null) {
          if (er[0] !== undefined) {
            this.pagconservice.pagos[this.indexunidad].CON_CODIGO = er[0].CON_CODIGO;
          }
        }
      });
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.pagconservice.pagos);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NUMERO');
    }
    if (this.opcionbusqueda === 'busquedaCajaPagodvf') {
      this.pagconservicedvf.pagos[this.indexunidad].CAJ_CODIGO = opcion.CAJ_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.pagconservicedvf.pagos);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NUMERO');
    }
    if (this.opcionbusqueda === 'busquedaCajaCXCpagoret') {
      this.cxctrnpagoretService.cxcpagoretformapago[this.indexunidad].CAJ_CODIGO = opcion.CAJ_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnpagoretService.cxcpagoretformapago);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'NUMERO');
    }
    if (this.opcionbusqueda === 'busquedaCajaformapago') {

      this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO = opcion.CAJ_CODIGO;
      this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = await this.cxcCarteraService.obtenerCuentaCaja(opcion.CAJ_CODIGO);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NUMERO');
    }
    //CDPJ
    if (this.opcionbusqueda === 'busquedaCajaTrnabonoformapago') {

      this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO = opcion.CAJ_CODIGO;
      this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = await this.cxctrnabonoService.obtenerCuentaCaja(opcion.CAJ_CODIGO);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NUMERO');
    }
    if (this.opcionbusqueda === 'busquedaCajaTrncancelacionformapago') {

      this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO = opcion.CAJ_CODIGO;
      this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = await this.cxctrncancelacionService.obtenerCuentaCaja(opcion.CAJ_CODIGO);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NUMERO');
    }
    // if (this.opcionbusqueda === 'busquedaCajaTrnanticipoformapago') {

    //   this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO = opcion.CAJ_CODIGO;
    //   this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = await this.cxctrnanticipoService.obtenerCuentaCaja(opcion.CAJ_CODIGO);
    //   this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
    //   this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NUMERO');
    // }
    
    //CDPJ
    if (this.opcionbusqueda === 'busquedaCajaformapagodev') {
      this.cxcCarteraService.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJ_CODIGO = opcion.CAJ_CODIGO;
      this.cxcCarteraService.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxcCarteraService.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNCAJ_NUMERO');
    }
    if (this.opcionbusqueda === 'busquedaBancoCliente') {
      this.pagconservice.pagos[this.indexunidad].BANCLI_CODIGO = opcion.BANCLI_CODIGO;
      this.pagconservice.pagos[this.indexunidad].BANCLI_NOMBRE = opcion.BANCLI_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.pagconservice.pagos);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NROCTA');
    }
    if (this.opcionbusqueda === 'busquedaBancoClienteCartera') {
      this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_CODIGO = opcion.BAN_CODIGO;
      this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_NOMBRE = opcion.BAN_BANCO;
      this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NROCTA');
    }
     //CDPJ
     if (this.opcionbusqueda === 'busquedaBancoClienteAbono') {
      this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_CODIGO = opcion.BAN_CODIGO;
      this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_NOMBRE = opcion.BAN_BANCO;
      this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NROCTA');
    }
    if (this.opcionbusqueda === 'busquedaBancoClienteCancelacion') {
      this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_CODIGO = opcion.BAN_CODIGO;
      this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_NOMBRE = opcion.BAN_BANCO;
      this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NROCTA');
    }
    // if (this.opcionbusqueda === 'busquedaBancoClienteAnticipo') {
    //   this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_CODIGO = opcion.BAN_CODIGO;
    //   this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_NOMBRE = opcion.BAN_BANCO;
    //   this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
    //   this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
    //   this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NROCTA');
    // }
    
    //CDPJ
    if (this.opcionbusqueda === 'busquedaBancoClienteCarteraCH') {
      this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_CODIGO = opcion.BANCLI_CODIGO;
      this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_NOMBRE = opcion.BANCLI_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NROCTA');
    }
    //CDPJ
    if (this.opcionbusqueda === 'busquedaBancoClienteAbonoCH') {
      this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_CODIGO = opcion.BANCLI_CODIGO;
      this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_NOMBRE = opcion.BANCLI_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NROCTA');
    }
    if (this.opcionbusqueda === 'busquedaBancoClienteCancelacionCH') {
      this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_CODIGO = opcion.BANCLI_CODIGO;
      this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_NOMBRE = opcion.BANCLI_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NROCTA');
    }
    // if (this.opcionbusqueda === 'busquedaBancoClienteAnticipoCH') {
    //   this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_CODIGO = opcion.BANCLI_CODIGO;
    //   this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_NOMBRE = opcion.BANCLI_NOMBRE;
    //   this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
    //   this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NROCTA');
    // }
    
    //CDPJ
    if (this.opcionbusqueda === 'busquedaBancoPagoRet') {
      this.cxctrnpagoretService.cxcpagoretformapago[this.indexunidad].BANCLI_CODIGO = opcion.BAN_CODIGO;
      this.cxctrnpagoretService.cxcpagoretformapago[this.indexunidad].BAN_NOMBRE = opcion.BAN_BANCO;
      this.cxctrnpagoretService.cxcpagoretformapago[this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;

      this.cxctrnpagoretService.obtenerNumeroCheque(opcion.BAN_CODIGO).subscribe(res => {
        console.log('res 1. 0', res);
        if (res !== null) {
          if (res[0] !== undefined) {
            const numcheque = Number(res[0].BAN_ULTIMOCHEQUE) + 1;
            this.cxctrnpagoretService.cxcpagoretformapago[this.indexunidad].NUMERO = String(numcheque);
            /* this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnpagoretService.cxcpagoretformapago);
              this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'NUMERO');*/
          }
        }

        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnpagoretService.cxcpagoretformapago);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'NUMERO');
      });
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnpagoretService.cxcpagoretformapago);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'NUMERO');

    }


    if (this.opcionbusqueda === 'busquedaBancodvf') {
      this.pagconservicedvf.pagos[this.indexunidad].BANCLI_CODIGO = opcion.BAN_CODIGO;
      this.pagconservicedvf.pagos[this.indexunidad].BANCLI_NOMBRE = opcion.BAN_BANCO;
      if (opcion.BAN_ULTIMOCHEQUE !== null) {
        this.pagconservicedvf.pagos[this.indexunidad].TRNPAGO_NUMERO = Number(opcion.BAN_ULTIMOCHEQUE) + 1;
      }
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.pagconservicedvf.pagos);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NUMERO');
    }

    if (this.opcionbusqueda === 'busquedaTarjetaC') {
      this.pagconservice.pagos[this.indexunidad].BANCLI_CODIGO = opcion.TAR_CODIGO;
      this.pagconservice.pagos[this.indexunidad].BANCLI_NOMBRE = opcion.TAR_DESCRIPCION;
      this.pagconservice.pagos[this.indexunidad].TRNPAGO_CODSRI = opcion.TAR_CODSRI;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.pagconservice.pagos);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NROCTA');
    }
    if (this.opcionbusqueda === 'busquedaTarjetaCartera') {
      this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_CODIGO = opcion.TAR_CODIGO;
      this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_NOMBRE = opcion.TAR_DESCRIPCION;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NROCTA');
    }
   //CDPJ
   if (this.opcionbusqueda === 'busquedaTarjetaAbono') {
    this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_CODIGO = opcion.TAR_CODIGO;
    this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_NOMBRE = opcion.TAR_DESCRIPCION;
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NROCTA');
  }if (this.opcionbusqueda === 'busquedaTarjetaCancelacion') {
    this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_CODIGO = opcion.TAR_CODIGO;
    this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_NOMBRE = opcion.TAR_DESCRIPCION;
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NROCTA');
  }
  // if (this.opcionbusqueda === 'busquedaTarjetaAnticipo') {
  //   this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_CODIGO = opcion.TAR_CODIGO;
  //   this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_NOMBRE = opcion.TAR_DESCRIPCION;
  //   this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
  //   this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NROCTA');
  // }
  
  //CDPJ
    if (this.opcionbusqueda === 'busquedaBanco') {
      this.pagconservice.pagos[this.indexunidad].BANCLI_CODIGO = opcion.BAN_CODIGO;
      this.pagconservice.pagos[this.indexunidad].BANCLI_NOMBRE = opcion.BAN_BANCO;

      this.pagconservice.erCodigoDineroElc(opcion.BAN_CODIGO).subscribe(data => {

        if (data !== null) {
          if (data[0] !== undefined) {
            this.pagconservice.pagos[this.indexunidad].CON_CODIGO = data[0].CON_CODIGO;
            // item.setPagoElectronico(Datos[2]);
            // si es pago electronico
            if (data[0].BAN_DINEROELEC === '1') {
              this.pagconservice.pagos[this.indexunidad].TRNPAGO_CODSRI = '17';
            }
          }
        }

        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.pagconservice.pagos);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NROCTA');
      });

    }
    if (this.opcionbusqueda === 'busquedaBancoFP') {
      this.cxcCarteraService.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BAN_CODIGO = opcion.BAN_CODIGO;
      this.cxcCarteraService.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BAN_BANCO = opcion.BAN_BANCO;
      this.cxcCarteraService.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
      this.cxcCarteraService.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNCAJ_NUMERO = Number(Number(opcion.BAN_ULTIMOCHEQUE) + 1);
      this.cxcCarteraService.calcularTotalFormaPagoDev();
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxcCarteraService.cxctrnformapagodevarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNCAJ_DESCRIPCION');
    }

    if (this.opcionbusqueda === 'busquedaCodigoSRIpago') {
      this.pagconservice.pagos[this.indexunidad].TRNPAGO_CODSRI = opcion.CODSRI_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.pagconservice.pagos);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_CODSRI');
    }
    if (this.opcionbusqueda === 'busquedaCodigoSRICartera') {
      this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_CODSRI = opcion.CODSRI_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_CODSRI');
    }
     //CDPJ
     if (this.opcionbusqueda === 'busquedaCodigoSRIAbono') {
      this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_CODSRI = opcion.CODSRI_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_CODSRI');
    }
    if (this.opcionbusqueda === 'busquedaCodigoSRICancelacion') {
      this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_CODSRI = opcion.CODSRI_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_CODSRI');
    }
    // if (this.opcionbusqueda === 'busquedaCodigoSRIAnticipo') {
    //   this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_CODSRI = opcion.CODSRI_CODIGO;
    //   this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
    //   this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_CODSRI');
    // }
    
    //CDPJ

    if (this.opcionbusqueda === 'busquedaretencion') {
      this.seleccionbusquedaretencion(opcion);
    }
    if (this.opcionbusqueda === 'cxcretenciones') {
       //CDPJ
      //const item = this.encfacproService.detfacproarray[this.indicador];
      //console.log('antes', this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad]);
     // console.log('opcion', opcion.RETENCION_CODIGO);
     let coinsidencias = 0;
     for (const data of this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice]) {
       //console.log('array', data.RETENCION_CODIGO)
       if (opcion.RETENCION_CODIGO === data.RETENCION_CODIGO && data.TRNRETENCION_DESCRIPCION !== '') {
         //console.log('repetido', this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO)
         coinsidencias++;
         this.messageService.add({
           key: 'cartera',
           severity: 'warn',
           summary: 'Información',
           detail: 'El código contable no se puede repetir en el detalle.'
         });
         this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO = '';
         this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice]);
         this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'RETENCION_CODIGO');
         // return;
       }
     }
     if (coinsidencias === 0) {
       this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO = opcion.RETENCION_CODIGO;
       this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_DESCRIPCION = opcion.RETENCION_NOMBRE;
       this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_PORCENTAJE = opcion.RETENCION_PORCENTAJE.toFixed(this.confIniciales.getNumDecSist());
       this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_ORIGEN = 'CXC';
       this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO_VTA;
       this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice]);
       this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNRETENCION_NRO');
       this.botonNuevo = false;
     }
     //CDPJ
      // this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO = opcion.RETENCION_CODIGO;
      // this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_DESCRIPCION = opcion.RETENCION_NOMBRE;
      // this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_PORCENTAJE = opcion.RETENCION_PORCENTAJE.toFixed(this.confIniciales.getNumDecSist());
      // this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_ORIGEN = 'CXC';
      // this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO_VTA;
      // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice]);
      // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNRETENCION_NRO');
      // this.botonNuevo = false;
    }
    //CDPJ
    if (this.opcionbusqueda === 'cxctrnretenciones') {
      //CDPJ
     //const item = this.encfacproService.detfacproarray[this.indicador];
     //console.log('antes', this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad]);
    // console.log('opcion', opcion.RETENCION_CODIGO);
    let coinsidencias = 0;
    for (const data of this.cxctrnretencionesService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice]) {
      //console.log('array', data.RETENCION_CODIGO)
      if (opcion.RETENCION_CODIGO === data.RETENCION_CODIGO && data.TRNRETENCION_DESCRIPCION !== '') {
        //console.log('repetido', this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO)
        coinsidencias++;
        this.messageService.add({
          key: 'cxctrnretenciones',
          severity: 'warn',
          summary: 'Información',
          detail: 'El código contable no se puede repetir en el detalle.'
        });
        this.cxctrnretencionesService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO = '';
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnretencionesService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'RETENCION_CODIGO');
        // return;
      }
    }
    if (coinsidencias === 0) {
      this.cxctrnretencionesService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO = opcion.RETENCION_CODIGO;
      this.cxctrnretencionesService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_DESCRIPCION = opcion.RETENCION_NOMBRE;
      this.cxctrnretencionesService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_PORCENTAJE = opcion.RETENCION_PORCENTAJE.toFixed(this.confIniciales.getNumDecSist());
      this.cxctrnretencionesService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_ORIGEN = 'CXC';
      this.cxctrnretencionesService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO_VTA;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnretencionesService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNRETENCION_NRO');
      this.botonNuevo = false;
    }
    //CDPJ
     // this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO = opcion.RETENCION_CODIGO;
     // this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_DESCRIPCION = opcion.RETENCION_NOMBRE;
     // this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_PORCENTAJE = opcion.RETENCION_PORCENTAJE.toFixed(this.confIniciales.getNumDecSist());
     // this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_ORIGEN = 'CXC';
     // this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO_VTA;
     // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice]);
     // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNRETENCION_NRO');
     // this.botonNuevo = false;
   }
   if (this.opcionbusqueda === 'cxctrnabonoretenciones') {
    //CDPJ
   //const item = this.encfacproService.detfacproarray[this.indicador];
   //console.log('antes', this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad]);
  // console.log('opcion', opcion.RETENCION_CODIGO);
  let coinsidencias = 0;
  for (const data of this.cxctrnabonoService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice]) {
    //console.log('array', data.RETENCION_CODIGO)
    if (opcion.RETENCION_CODIGO === data.RETENCION_CODIGO && data.TRNRETENCION_DESCRIPCION !== '') {
      //console.log('repetido', this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO)
      coinsidencias++;
      this.messageService.add({
        key: 'cxctrnabono',
        severity: 'warn',
        summary: 'Información',
        detail: 'El código contable no se puede repetir en el detalle.'
      });
      this.cxctrnabonoService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO = '';
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnabonoService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'RETENCION_CODIGO');
      // return;
    }
  }
  if (coinsidencias === 0) {
    this.cxctrnabonoService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO = opcion.RETENCION_CODIGO;
    this.cxctrnabonoService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_DESCRIPCION = opcion.RETENCION_NOMBRE;
    this.cxctrnabonoService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_PORCENTAJE = opcion.RETENCION_PORCENTAJE.toFixed(this.confIniciales.getNumDecSist());
    this.cxctrnabonoService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_ORIGEN = 'CXC';
    this.cxctrnabonoService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO_VTA;
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnabonoService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice]);
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNRETENCION_NRO');
    this.botonNuevo = false;
  }
  //CDPJ
   // this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO = opcion.RETENCION_CODIGO;
   // this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_DESCRIPCION = opcion.RETENCION_NOMBRE;
   // this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_PORCENTAJE = opcion.RETENCION_PORCENTAJE.toFixed(this.confIniciales.getNumDecSist());
   // this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_ORIGEN = 'CXC';
   // this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO_VTA;
   // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice]);
   // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNRETENCION_NRO');
   // this.botonNuevo = false;
 }
 if (this.opcionbusqueda === 'cxctrncancelacionretenciones') {
  //CDPJ
 //const item = this.encfacproService.detfacproarray[this.indicador];
 //console.log('antes', this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad]);
// console.log('opcion', opcion.RETENCION_CODIGO);
let coinsidencias = 0;
for (const data of this.cxctrncancelacionService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice]) {
  //console.log('array', data.RETENCION_CODIGO)
  if (opcion.RETENCION_CODIGO === data.RETENCION_CODIGO && data.TRNRETENCION_DESCRIPCION !== '') {
    //console.log('repetido', this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO)
    coinsidencias++;
    this.messageService.add({
      key: 'cxctrncancelacion',
      severity: 'warn',
      summary: 'Información',
      detail: 'El código contable no se puede repetir en el detalle.'
    });
    this.cxctrncancelacionService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO = '';
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrncancelacionService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice]);
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'RETENCION_CODIGO');
    // return;
  }
}
if (coinsidencias === 0) {
  this.cxctrncancelacionService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO = opcion.RETENCION_CODIGO;
  this.cxctrncancelacionService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_DESCRIPCION = opcion.RETENCION_NOMBRE;
  this.cxctrncancelacionService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_PORCENTAJE = opcion.RETENCION_PORCENTAJE.toFixed(this.confIniciales.getNumDecSist());
  this.cxctrncancelacionService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_ORIGEN = 'CXC';
  this.cxctrncancelacionService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO_VTA;
  this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrncancelacionService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice]);
  this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNRETENCION_NRO');
  this.botonNuevo = false;
}
//CDPJ
 // this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO = opcion.RETENCION_CODIGO;
 // this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_DESCRIPCION = opcion.RETENCION_NOMBRE;
 // this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_PORCENTAJE = opcion.RETENCION_PORCENTAJE.toFixed(this.confIniciales.getNumDecSist());
 // this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_ORIGEN = 'CXC';
 // this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO_VTA;
 // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxcCarteraService.cxctrnretencionarray[this.init.tabs[this.init.tabindex].indice]);
 // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNRETENCION_NRO');
 // this.botonNuevo = false;
}
    //CDPJ
    if (this.opcionbusqueda === 'cxcpagoretret') {
      //CDPJ
      //const item = this.encfacproService.detfacproarray[this.indicador];
      //console.log('antes', this.cxctrnpagoretService.cxcpagoretretenciones);
      //console.log('opcion', opcion.RETENCION_CODIGO);
      let coinsidencias = 0;
      for (const data of this.cxctrnpagoretService.cxcpagoretretenciones) {
        //console.log('array', data.RETENCION_CODIGO)
        if (opcion.RETENCION_CODIGO === data.RETENCION_CODIGO && data.TRNRETENCION_DESCRIPCION !== '') {
          //console.log('repetido', this.cxctrnpagoretService.cxcpagoretretenciones[this.indexunidad].RETENCION_CODIGO)
          coinsidencias++;
          this.messageService.add({
            key: 'cxctrnpagoretKey',
            severity: 'warn',
            summary: 'Información',
            detail: 'El código contable no se puede repetir en el detalle.'
          });
          this.cxctrnpagoretService.cxcpagoretretenciones[this.indexunidad].RETENCION_CODIGO = '';
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnpagoretService.cxcpagoretretenciones);
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'RETENCION_CODIGO');
          // return;
        }
      }
      //CDPJ
      //CDPJ
      if (coinsidencias === 0) {
        this.cxctrnpagoretService.cxcpagoretretenciones[this.indexunidad].RETENCION_CODIGO = opcion.RETENCION_CODIGO;
        this.cxctrnpagoretService.cxcpagoretretenciones[this.indexunidad].TRNRETENCION_DESCRIPCION = opcion.RETENCION_NOMBRE;
        this.cxctrnpagoretService.cxcpagoretretenciones[this.indexunidad].TRNRETENCION_PORCENTAJE = opcion.RETENCION_PORCENTAJE.toFixed(this.confIniciales.getNumDecSist());
        if (this.cxctrnpagoretService.cxcpagoretretenciones[this.indexunidad].RETENCION_CODIGO !== null) {
          this.cxctrnpagoretService.cargarDatosRetencion(this.cxctrnpagoretService.cxcpagoretretenciones[this.indexunidad].RETENCION_CODIGO).subscribe(res => {
            res.map(concodigo => {
              this.cxctrnpagoretService.cxcpagoretretenciones[this.indexunidad].CON_CODIGO = concodigo.CON_CODIGO_VTA;
            });
          });
        }
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnpagoretService.cxcpagoretretenciones);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNRETENCION_NRO');
        //  this.botonNuevo = false;
      }
      //CDPJ
      // this.cxctrnpagoretService.cxcpagoretretenciones[this.indexunidad].RETENCION_CODIGO = opcion.RETENCION_CODIGO;
      // this.cxctrnpagoretService.cxcpagoretretenciones[this.indexunidad].TRNRETENCION_DESCRIPCION = opcion.RETENCION_NOMBRE;
      // this.cxctrnpagoretService.cxcpagoretretenciones[this.indexunidad].TRNRETENCION_PORCENTAJE = opcion.RETENCION_PORCENTAJE.toFixed(this.confIniciales.getNumDecSist());


      // if (this.cxctrnpagoretService.cxcpagoretretenciones[this.indexunidad].RETENCION_CODIGO !== null) {

      //   this.cxctrnpagoretService.cargarDatosRetencion(this.cxctrnpagoretService.cxcpagoretretenciones[this.indexunidad].RETENCION_CODIGO).subscribe(res => {
      //     res.map(concodigo => {
      //       this.cxctrnpagoretService.cxcpagoretretenciones[this.indexunidad].CON_CODIGO = concodigo.CON_CODIGO_VTA;
      //     });
      //   });

      // }

      // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnpagoretService.cxcpagoretretenciones);
      // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNRETENCION_NRO');
      // //  this.botonNuevo = false;
    }

    if (this.opcionbusqueda === 'trnretencion') {
      this.selecciontrnretencion(opcion);
    }

    if (this.opcionbusqueda === 'busquedalotes') {
      this.trnloteservice.detlotearray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].LOTE_NUMERO = opcion.LOTE_NUMERO;
      if (opcion.LOTE_FECHAVENCIMIENTO.toString().includes('-')) {
        opcion.LOTE_FECHAVENCIMIENTO = this.datePipe.transform(opcion.LOTE_FECHAVENCIMIENTO, 'dd/MM/yyyy');
        console.log('opcion.LOTE_FECHAVENCIMIENTO',opcion.LOTE_FECHAVENCIMIENTO)
      }
      this.trnloteservice.detlotearray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNLOTE_FECHAVENCIMIENTO = opcion.LOTE_FECHAVENCIMIENTO;

      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.trnloteservice.detlotearray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNLOTE_FECHAVENCIMIENTO');
    }

    if (this.opcionbusqueda === 'artmaelote') {
      this.maeloteservice.detmaelote[this.indexunidad].ART_CODIGO = opcion.ART_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.maeloteservice.detmaelote);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'LOTE_CANTIDAD');
    }

    if (this.opcionbusqueda === 'busquedaarticulodetfacpro') {
      const CDDTIMP= this.encfacproService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CDDTIMP').CFG_VALOR1;
      
      if (this.tipoDetFactXML !== 'noXML'  ) {
        this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_CODIGO = opcion.ART_CODIGO;
        this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_TIPODET = this.tipoDetFactXML;
        //GSRF
        if(CDDTIMP === 1){
          this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_DESCRIPCION = opcion.ART_NOMBRE;
        }   
        this.encfacproService.consultarArticuloSel(opcion.ART_CODIGO).subscribe(datas => {
          for (const data of datas) {
            this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_UNIDAD = data.ART_UNIDADCOSTEO;
          }
           this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFACPRO_CODIGO');
        });
        //GSRF
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFACPRO_CODIGO');
      } else {
        this.seleccionbusquedaarticulodetfacpro(opcion);
      }

    }
    //GSRF
    if (this.opcionbusqueda === 'busquedaarticulodetfacproajuste') {
      this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_AJUSTE = opcion.ART_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFACPRO_AJUSTE');
    
    }
    if (this.opcionbusqueda === 'busquedaarticulonotrecajuste') {
      this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETNOTREC_AJUSTE = opcion.ART_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETNOTREC_AJUSTE');
      }
    if (this.opcionbusqueda === 'busquedaarticulodetfaccodalt') {
      this.AneximpdocsriService.arreglocodalt[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CODALT_CODIGO = opcion.ART_CODIGO;
      const art = await this.AneximpdocsriService.busqarti(opcion.ART_CODIGO);
      this.AneximpdocsriService.arreglocodalt[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CODALT_UNIDAD = art[0].ART_UNIDADCOSTEO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData( this.AneximpdocsriService.arreglocodalt[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad +1 , 'CODALT_TIPO');  
      
    }
    if (this.opcionbusqueda === 'busquedaserviciodetfacprocodalt') {
      this.AneximpdocsriService.arreglocodalt[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CODALT_CODIGO = opcion.SERCOM_CODIGO;
      this.AneximpdocsriService.arreglocodalt[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CODALT_UNIDAD = '';
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData( this.AneximpdocsriService.arreglocodalt[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad +1 , 'CODALT_TIPO'); 
    }
    if (this.opcionbusqueda === 'busquedacuentadetfacprocodalt') {
      this.AneximpdocsriService.arreglocodalt[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CODALT_CODIGO = opcion.CON_CODIGO;
      this.AneximpdocsriService.arreglocodalt[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CODALT_UNIDAD = '';
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData( this.AneximpdocsriService.arreglocodalt[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad +1 , 'CODALT_TIPO');       
    }
    if (this.opcionbusqueda === 'CEN_CODIGOCODALT') {
      this.AneximpdocsriService.arreglocodalt[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CODALT_CENCOS = opcion.CEN_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.AneximpdocsriService.arreglocodalt[this.init.tabs[this.init.tabindex].indice]);
      //this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFACPRO_AJUSTE');
    
     }
     if (this.opcionbusqueda === 'busquedaarticulodetfachomo') {
      this.ComimpdoceleService.arreglohomo[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_CODIGOTIPO = opcion.ART_CODIGO;
      this.ComimpdoceleService.arreglohomo[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_NOMBRETIPO = opcion.ART_NOMBRE;
      const art = await this.AneximpdocsriService.busqarti(opcion.ART_CODIGO);
      this.ComimpdoceleService.arreglohomo[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_UNIDAD = art[0].ART_UNIDADCOSTEO;
      this.ComimpdoceleService.arreglohomo[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_HOMOLOGADO = '1';
     this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData( this.ComimpdoceleService.arreglohomo[this.init.tabs[this.init.tabindex].indice]);
     this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad +1 , 'DETFAC_TIPO');     
   }
   if (this.opcionbusqueda === 'busquedaserviciodetfachomo') {
     this.ComimpdoceleService.arreglohomo[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_CODIGOTIPO = opcion.SERCOM_CODIGO;
     this.ComimpdoceleService.arreglohomo[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_NOMBRETIPO = opcion.SERCOM_DESCRIPCION;
     this.ComimpdoceleService.arreglohomo[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_UNIDAD = '';
     this.ComimpdoceleService.arreglohomo[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_HOMOLOGADO = '1';
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData( this.ComimpdoceleService.arreglohomo[this.init.tabs[this.init.tabindex].indice]);
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad +1 , 'DETFAC_TIPO'); 
   }
   if (this.opcionbusqueda === 'busquedacuentadetfachomo') {
      this.ComimpdoceleService.arreglohomo[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_CODIGOTIPO = opcion.CON_CODIGO;
      this.ComimpdoceleService.arreglohomo[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_NOMBRETIPO = opcion.CON_NOMBRE;
      this.ComimpdoceleService.arreglohomo[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_UNIDAD = '';
      this.ComimpdoceleService.arreglohomo[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_HOMOLOGADO = '1';
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData( this.ComimpdoceleService.arreglohomo[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad +1 , 'DETFAC_TIPO');      
   }
  //GSRF
    if (this.opcionbusqueda === 'busquedaserviciodetfacpro') {
      //CDPJ
      const CENCOSDET=this.encfacproService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CENCOSDET').CFG_VALOR1;
      if (CENCOSDET === 1){
        this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO=opcion.CEN_CODIGO;
      }
      //CDPJ
      if (this.tipoDetFactXML !== 'noXML') {
        const CDDTIMP= this.encfacproService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CDDTIMP').CFG_VALOR1;

        this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_CODIGO = opcion.SERCOM_CODIGO;
        this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_TIPODET = this.tipoDetFactXML;
        this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_UNIDAD = '';//GSRF
        if(CDDTIMP === 1){
        this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_DESCRIPCION = opcion.SERCOM_DESCRIPCION;//GSRF
        }

        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFACPRO_CODIGO');
      } else {
        this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_CODIGO = opcion.SERCOM_CODIGO;
        this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_DESCRIPCION = opcion.SERCOM_DESCRIPCION;
        // if (this.encfacproService.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_IVA !== '0') {
        //   if (opcion.SERCOM_TRIBUTAIVA === 'S') {
        //     this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_PORIVA = '12.00';
        //   } else {
        //     this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_PORIVA = '0.00';
        //   }
        // } else {
        //   this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_PORIVA = '0.00';
        // }
        if (this.encfacproService.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_IVA !== '0') {
          
          if(opcion.TARIIVA_CODIGO !== null && opcion.TARIIVA_CODIGO !== undefined && opcion.TARIIVA_CODIGO !==''){
            console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO))
              const porceiva= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_PORCENTAJE
              const tariivacod= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_CODIGO
              if(porceiva !== null && porceiva !== undefined && porceiva !== ''){
                this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_PORIVA=Number(porceiva).toFixed(this.confIniciales.getNumDecSist())
              }if(tariivacod !== null && tariivacod !== undefined && tariivacod !== ''){
                this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=tariivacod
              }
           }else{
            this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_PORIVA='';
            this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';
           }
          // if (opcion.SERCOM_TRIBUTAIVA === 'S') {
          //   this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_PORIVA= Number(this.encfacproService.porceiva).toFixed(this.confIniciales.getNumDecSist())
          //     this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=this.encfacproService.tariivacod
          // } else {
          //   this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_PORIVA=0.00;
          //   this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';
          // }
        } else {
          this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_PORIVA='';
          this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';
        }
        this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_COSTO = opcion.SERCOM_PRECIO;
        this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_TOTAL = opcion.SERCOM_PRECIO;
        this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_TRIBICE = 'N';
        this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_TRIBASENOOBJIVA = 'N';
        this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_CANTIDAD = 1;
        this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_UNIDAD = '';
        this.encfacproService.erTipGasCodigo1().subscribe(er => {
          if (er !== undefined) {
            if (er[0] !== null) {
              this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TIPGAS_CODIGO = er[0].TIPGAS_CODIGO;
              this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOLTIPOGASTO = true;
            }
          }
        });
        this.encfacproService.calcularTotales();
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFACPRO_CANTIDAD');
      }
    }
    if (this.opcionbusqueda === 'busquedacuentadetfacpro') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'cierre',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        if (this.tipoDetFactXML !== 'noXML') {
          const CDDTIMP= this.encfacproService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CDDTIMP').CFG_VALOR1;
          this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_CODIGO = opcion.CON_CODIGO;
          this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_UNIDAD = '';
          this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_TIPODET = this.tipoDetFactXML;
          if (CDDTIMP === 1){
          this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_DESCRIPCION = opcion.CON_NOMBRE;//GSRF
          }
          console.log(this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad]);
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice]);
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFACPRO_CODIGO');
        } else {
          this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_CODIGO = opcion.CON_CODIGO;
          this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_DESCRIPCION = opcion.CON_NOMBRE;
          this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_IVA = 12.00;
          this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_TRIBICE = 'N';
          this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_TRIBASENOOBJIVA = '';
          this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_CANTIDAD = 1;
          this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_UNIDAD = '';
          this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_COSTO = 0;
          this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_TOTAL = 0;
          //CDPJ
          this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_PORIVA ='';
          this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO ='';
          //CDPJ
          this.encfacproService.erConTipGas1(opcion.CON_CODIGO).subscribe(rs => {

            let strDato = '';
            let strTipoGasto = '';
            if (rs !== undefined) {
              if (rs[0] !== null) {
                strDato = rs[0].CON_TIPGAS;
              }
            }
            this.encfacproService.erTipGasCodigo1().subscribe(er => {
              if (er !== undefined) {
                if (er[0] !== null) {
                  strTipoGasto = er[0].TIPGAS_CODIGO;
                }
              }

              if (strDato === 'Si') {
                this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TIPGAS_CODIGO = strTipoGasto;
                this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOLTIPOGASTO = true;
              } else {
                this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TIPGAS_CODIGO = '';
                this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOLTIPOGASTO = false;
              }
            });
          });
          this.encfacproService.calcularTotales();
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice]);
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFACPRO_CANTIDAD');
        }
      }
    }

    if (this.opcionbusqueda === 'busquedafpdatoanexos') {
      this.datosanexService.formapago[this.indexunidad].TRNFORMAPAGO_CODIGO = opcion.CODSRI_CODIGO;
      this.datosanexService.formapago[this.indexunidad].TRNFORMAPAGO_DESCRIPCION = opcion.CODSRI_DESCRIPCION;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.datosanexService.formapago);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNFORMAPAGO_DESCRIPCION');
    }
    if (this.opcionbusqueda === 'busquedaNombresri') {
      this.proveedorServicio.commaeproveedorFP[this.indexunidad].TRNFORMAPAGO_CODIGO = opcion.CODSRI_CODIGO;
      this.proveedorServicio.commaeproveedorFP[this.indexunidad].TRNFORMAPAGO_DESCRIPCION = opcion.CODSRI_DESCRIPCION;

      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.proveedorServicio.commaeproveedorFP);

      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNFORMAPAGO_DESCRIPCION');

    }
    if (this.opcionbusqueda === 'busquedaNombre') {

      this.proveedorServicio.commaeproveedorRt[this.indexunidad].RETENCION_NOMBRE = opcion.RETENCION_NOMBRE;
      this.proveedorServicio.commaeproveedorRt[this.indexunidad].RETENCION_CODIGO = opcion.RETENCION_CODIGO;

      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.proveedorServicio.commaeproveedorRt);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'RETENCION_NOMBRE');
      this.guardarEnter.emit(true);


    }
    if (this.opcionbusqueda === 'cxcformapagoANC') {
      this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NUMERO = opcion.TRNCOBRO_NROCOMPROBANTE;
      //		colocar el valor de la forma de pago si el saldo del anticipo es menor al total del cobro
      if (String(this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NUMERO).length > 0) {
        const strNumDoc = await this.cxcCarteraService.encontrarNumDoc(this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NUMERO);
        const dblSaldoAnt = await this.cxcCarteraService.obtenerSaldoFactura(strNumDoc);

        if (dblSaldoAnt * (-1) < Number(this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_IMPORTE)) {
          this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_IMPORTE = Number(dblSaldoAnt * (-1)).toFixed(2);
        }


      }
      //  this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_IMPORTE = opcion.TRNCOBRO_IMPORTE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
      this.cxcCarteraService.calcularTotalFormaPago();
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_FECHA');
    }
    //CDPJ
    if (this.opcionbusqueda === 'cxctrnabonoformapagoANC') {
      this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NUMERO = opcion.TRNCOBRO_NROCOMPROBANTE;
      //		colocar el valor de la forma de pago si el saldo del anticipo es menor al total del cobro
      if (String(this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NUMERO).length > 0) {
        const strNumDoc = await this.cxctrnabonoService.encontrarNumDoc(this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NUMERO);
        const dblSaldoAnt = await this.cxctrnabonoService.obtenerSaldoFactura(strNumDoc);

        if (dblSaldoAnt * (-1) < Number(this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_IMPORTE)) {
          this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_IMPORTE = Number(dblSaldoAnt * (-1)).toFixed(2);
        }


      }
      //  this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_IMPORTE = opcion.TRNCOBRO_IMPORTE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
      this.cxctrnabonoService.calcularTotalFormaPago();
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_FECHA');
    }
    if (this.opcionbusqueda === 'cxctrncancelacionformapagoANC') {
      this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NUMERO = opcion.TRNCOBRO_NROCOMPROBANTE;
      //		colocar el valor de la forma de pago si el saldo del anticipo es menor al total del cobro
      if (String(this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NUMERO).length > 0) {
        const strNumDoc = await this.cxctrncancelacionService.encontrarNumDoc(this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NUMERO);
        const dblSaldoAnt = await this.cxctrncancelacionService.obtenerSaldoFactura(strNumDoc);

        if (dblSaldoAnt * (-1) < Number(this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_IMPORTE)) {
          this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_IMPORTE = Number(dblSaldoAnt * (-1)).toFixed(2);
        }


      }
      //  this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_IMPORTE = opcion.TRNCOBRO_IMPORTE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
      this.cxctrncancelacionService.calcularTotalFormaPago();
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_FECHA');
    }
    // if (this.opcionbusqueda === 'cxctrnanticipoformapagoANC') {
    //   this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NUMERO = opcion.TRNCOBRO_NROCOMPROBANTE;
    //   //		colocar el valor de la forma de pago si el saldo del anticipo es menor al total del cobro
    //   if (String(this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NUMERO).length > 0) {
    //     const strNumDoc = await this.cxctrnanticipoService.encontrarNumDoc(this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NUMERO);
    //     const dblSaldoAnt = await this.cxctrnanticipoService.obtenerSaldoFactura(strNumDoc);

    //     if (dblSaldoAnt * (-1) < Number(this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_IMPORTE)) {
    //       this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_IMPORTE = Number(dblSaldoAnt * (-1)).toFixed(2);
    //     }


    //   }
    //   //  this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_IMPORTE = opcion.TRNCOBRO_IMPORTE;
    //   this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxctrnanticipoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
    //   this.cxctrnanticipoService.calcularTotalFormaPago();
    //   this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_FECHA');
    // }
    
    //CDPJ
    if (this.opcionbusqueda === 'pagconFormaPagoAnc') {
      this.pagconservice.pagos[this.indexunidad].TRNPAGO_NUMERO = opcion.TRNCOBRO_NROCOMPROBANTE;
      this.pagconservice.pagos[this.indexunidad].TRNPAGO_IMPORTE = opcion.TRNCOBRO_IMPORTE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.pagconservice.pagos);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NUMERO');

      /*this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NUMERO = opcion.TRNCOBRO_NROCOMPROBANTE;
      this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_IMPORTE = opcion.TRNCOBRO_IMPORTE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice]);
      this.cxcCarteraService.calcularTotalFormaPago();
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_FECHA');*/
    }
    if (this.opcionbusqueda === 'busquedatipogastos') {
      this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TIPGAS_CODIGO = opcion.TIPGAS_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TIPGAS_CODIGO');
    }
      //CDPJ
      if(this.opcionbusqueda === 'busquedatipogastosDev'){
        this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TIPGAS_CODIGO = opcion.TIPGAS_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TIPGAS_CODIGO');
      }
      //CDPJ
    if (this.opcionbusqueda === 'busquedatipogastosdistricc') {
      this.distriCCService.distribucionCC[this.indexunidad].TIPGAS_CODIGO = opcion.TIPGAS_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TIPGAS_CODIGO');
    }
    if (this.opcionbusqueda === 'busquedatipogastoscxpvarios') {
      this.cxptrnpagodocService.varios[this.indexunidad].TIPGAS_CODIGO = opcion.TIPGAS_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TIPGAS_CODIGO');
    }
    if (this.opcionbusqueda === 'busquedacargos') {
      this.encfacproService.trncargosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CARGO_CODIGO = opcion.CARGO_CODIGO;
      this.encfacproService.trncargosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CARGO_NOMBRE = opcion.CARGO_NOMBRE;
      this.encfacproService.trncargosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
      this.encfacproService.trncargosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacproService.trncargosarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNCARGOS_IMPORTE');
    }

    if (this.opcionbusqueda === 'CON_CODIGOENCASIL') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'cierre',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {

        let strDato = '';
        let strTipoGasto = '';
        this.encasilService.detencasil[this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.encasilService.detencasil[this.indexunidad].CON_NOMBRE = opcion.CON_NOMBRE;

        if (this.encasilService.getConfiguraciones('OBSASIDET') === 0) {
          this.encasilService.detencasil[this.indexunidad].DETASI_CONCEPTO = opcion.CON_NOMBRE;
          this.encasilService.detencasil[this.indexunidad].DETASI_CONCEPTOPRY = opcion.CON_NOMBRE;
        }

        this.encasilService.erBancodigo(opcion.CON_CODIGO).subscribe((ban) => {
          if (ban !== null) {
            this.encasilService.detencasil[this.indexunidad].BAN_CODIGO = ban[0].BAN_CODIGO;
          } else {
            this.encasilService.detencasil[this.indexunidad].BAN_CODIGO = '';
          }
        });

        this.encasilService.erConcentro(opcion.CON_CODIGO).subscribe((cen) => {
          if (cen !== null) {
            this.encasilService.detencasil[this.indexunidad].CON_CENTRO = cen[0].CON_CENTRO;
          }
        });

        this.encasilService.erContipgas(opcion.CON_CODIGO).subscribe((tipGas) => {
          if (tipGas !== null) {
            strDato = tipGas[0].CON_TIPGAS;
          }
        });
        setTimeout(() => {
          this.encasilService.erTipgascodigo().subscribe((codTipGas) => {
            if (codTipGas !== null) {
              strTipoGasto = codTipGas[0].BAN_CODIGO;
              if (strDato === 'Si') {
                this.encasilService.detencasil[this.indexunidad].CON_TIPGAS = strTipoGasto;
                this.encasilService.detencasil[this.indexunidad].BOL_TIPOGASTO = true;
              } else {
                this.encasilService.detencasil[this.indexunidad].BOL_TIPOGASTO = false;
                this.encasilService.detencasil[this.indexunidad].CON_TIPGAS = '';
              }
            }
          });
        }, 1000);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encasilService.detencasil);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETASI_REFERENCIA');
      }
    }

    if (this.opcionbusqueda === 'CEN_CONENCASIL') {
      this.encasilService.detencasil[this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encasilService.detencasil);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETASI_DEBE');
    }

    if (this.opcionbusqueda === 'PROG_CONENCASIL') {
      this.encasilService.detencasil[this.indexunidad].PROG_CODIGO = opcion.PROG_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encasilService.detencasil);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CEN_CODIGO');
    }

    if (this.opcionbusqueda === 'CON_CODIGOBANVARIOS') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'cierre',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
        //GSRF
        this.messageService.add({
          key: 'trnbanEdit',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
        this.bantrnbanEditService.banTrnBanVarios[this.indexunidad].CON_CODIGO = '';
        this.bantrnbanEditService.banTrnBanVarios[this.indexunidad].CON_NOMBRE = '' ;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.bantrnbanEditService.banTrnBanVarios);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'VARIOS_DEBE');
     
        //GSRF
      } else {
        this.bantrnbanEditService.banTrnBanVarios[this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.bantrnbanEditService.banTrnBanVarios[this.indexunidad].CON_NOMBRE = opcion.CON_NOMBRE;
        this.encasilService.erConcentro(opcion.CON_CODIGO).subscribe((cen) => {
          if (cen !== null) {
            this.bantrnbanEditService.banTrnBanVarios[this.indexunidad].CON_CENTRO = cen[0].CON_CENTRO;
          }
        });
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.bantrnbanEditService.banTrnBanVarios);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'VARIOS_DEBE');
      }
    }

    if (this.opcionbusqueda === 'CEN_BANVARIOS') {
      this.bantrnbanEditService.banTrnBanVarios[this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.bantrnbanEditService.banTrnBanVarios);
      // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETASI_DEBE');
    }

    if (this.opcionbusqueda === 'CON_CODIGODISTRIBCC') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'cierre',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.contrnDistribccService.detDistribcc[this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.contrnDistribccService.detDistribcc);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CEN_CODIGO');
      }
    }

    if (this.opcionbusqueda === 'CEN_CONDISTRIBCC') {
      this.contrnDistribccService.detDistribcc[this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      this.contrnDistribccService.detDistribcc[this.indexunidad].CEN_NOMBRE = opcion.CEN_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.contrnDistribccService.detDistribcc);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNDISP_PORCE');
    }

    if (this.opcionbusqueda === 'BANTARJETA_RETENCION') {
      //CDPJ
      //CDPJ
      //const item = this.encfacproService.detfacproarray[this.indicador];
      //console.log('antes', this.bantrnTarjetaService.detModeloRetencion[this.init.tabs[this.init.tabindex].indice][this.indexunidad]);
      //console.log('opcion', opcion.RETENCION_CODIGO);
      let coinsidencias = 0;
      for (const data of this.bantrnTarjetaService.detModeloRetencion[this.init.tabs[this.init.tabindex].indice]) {
        //console.log('array', data.RETENCION_CODIGO)
        if (opcion.RETENCION_CODIGO === data.RETENCION_CODIGO && data.TRNRETENCION_DESCRIPCION !== '') {
          //console.log('repetido', this.bantrnTarjetaService.detModeloRetencion[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO)
          coinsidencias++;
          this.messageService.add({
            key: 'depositoTarjeta',
            severity: 'warn',
            summary: 'Información',
            detail: 'El código contable no se puede repetir en el detalle.'
          });
          this.bantrnTarjetaService.detModeloRetencion[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO = '';
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.bantrnTarjetaService.detModeloRetencion[this.init.tabs[this.init.tabindex].indice]);
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'RETENCION_CODIGO');
          //  // return;
        }
      }
      //CDPJ
      //CDPJ
      if (coinsidencias === 0) {
        this.bantrnTarjetaService.detModeloRetencion[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO = opcion.RETENCION_CODIGO;
        this.bantrnTarjetaService.detModeloRetencion[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_DESCRIPCION = opcion.RETENCION_NOMBRE;
        this.bantrnTarjetaService.detModeloRetencion[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO_VTA;
        this.bantrnTarjetaService.detModeloRetencion[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_PORCENTAJE = Number(opcion.RETENCION_PORCENTAJE).toFixed(2);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.bantrnTarjetaService.detModeloRetencion[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNRETENCION_SERIE');
        this.botonNuevo = false;
      }
      // this.bantrnTarjetaService.detModeloRetencion[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO = opcion.RETENCION_CODIGO;
      // this.bantrnTarjetaService.detModeloRetencion[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_DESCRIPCION = opcion.RETENCION_NOMBRE;
      // this.bantrnTarjetaService.detModeloRetencion[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO_VTA;
      // this.bantrnTarjetaService.detModeloRetencion[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_PORCENTAJE = Number(opcion.RETENCION_PORCENTAJE).toFixed(2);
      // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.bantrnTarjetaService.detModeloRetencion[this.init.tabs[this.init.tabindex].indice]);
      // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNRETENCION_SERIE');
    }

    if (this.opcionbusqueda === 'CON_CODIGOTRNTARJETA') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'cierre',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
        //GSRF
        this.messageService.add({
          key: 'depositoTarjeta',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
        this.bantrnTarjetaService.detModeloVarios[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = '';
        this.bantrnTarjetaService.detModeloVarios[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNTARVARIOS_DESCRIPCION = '';
       this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.bantrnTarjetaService.detModeloVarios[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DEBE');
      //GSRF
      } else {
        this.bantrnTarjetaService.detModeloVarios[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.bantrnTarjetaService.detModeloVarios[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNTARVARIOS_DESCRIPCION = opcion.CON_NOMBRE;
        this.encasilService.erConcentro(opcion.CON_CODIGO).subscribe((cen) => {
          if (cen !== null) {
            this.bantrnTarjetaService.detModeloVarios[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CENTRO = cen[0].CON_CENTRO;
          }
        });

        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.bantrnTarjetaService.detModeloVarios[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DEBE');
      }

    }

    if (this.opcionbusqueda === 'CEN_CODIGOTRNTARJETA') {
      this.bantrnTarjetaService.detModeloVarios[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.bantrnTarjetaService.detModeloVarios[this.init.tabs[this.init.tabindex].indice]);
    }

    if (this.opcionbusqueda === 'CON_CODIGOBANCAJA') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'cierre',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
        //GSRF
        this.messageService.add({
          key: 'trnmodificarCaja',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
        this.bantrnCajaService.detModeloVarCaja[this.indexunidad].CON_CODIGO = '';
        this.bantrnCajaService.detModeloVarCaja[this.indexunidad].CON_NOMBRE = '';
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.bantrnCajaService.detModeloVarCaja);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'VARIOS_DEBE');
      
        //GSRF
      } else {
        this.bantrnCajaService.detModeloVarCaja[this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.bantrnCajaService.detModeloVarCaja[this.indexunidad].CON_NOMBRE = opcion.CON_NOMBRE;
        this.encasilService.erConcentro(opcion.CON_CODIGO).subscribe((cen) => {
          if (cen !== null) {
            this.bantrnCajaService.detModeloVarCaja[this.indexunidad].CON_CENTRO = cen[0].CON_CENTRO;
          }
        });

        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.bantrnCajaService.detModeloVarCaja);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'VARIOS_DEBE');
      }

    }

    if (this.opcionbusqueda === 'CEN_CODIGOBANCAJA') {
      this.bantrnCajaService.detModeloVarCaja[this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.bantrnCajaService.detModeloVarCaja);
    }

    if (this.opcionbusqueda === 'dettransfer') {
      this.seleccionarticulotransfer(opcion);
    }

    if (this.opcionbusqueda === 'CONCODIGOcxpvarios') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'cxptrncobro',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.cxptrncobroService.variosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CONCODIGO = opcion.CON_CODIGO;
        this.cxptrncobroService.variosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CONNOMBRE = opcion.CON_NOMBRE;
        //CDPJ
        this.cxptrncobroService.erBancodigo(opcion.CON_CODIGO).subscribe((ban) => {
          if(ban !== null && ban != undefined){
            this.cxptrncobroService.variosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCODIGO = ban[0].BAN_CODIGO;
          //this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxcCarteraService.cxctrnvariosarray[this.init.tabs[this.init.tabindex].indice]);
          }
        });
        //CDPJ
        this.cxptrncobroService.erConCentro(opcion.CON_CODIGO).subscribe((cen) => {
          if (cen !== null) {
            if (cen[0] !== undefined) {
              this.cxptrncobroService.variosarray[this.init.tabs[this.init.tabindex].indice]
              [this.indexunidad].CONCENTRO = cen[0].CON_CENTRO;
            }
          }
        });
        this.cxptrncobroService.erConTipgas(opcion.CON_CODIGO).subscribe(rs => {

          let strDato = '';
          let strTipoGasto = '';
          if (rs !== undefined) {
            if (rs[0] !== null) {
              strDato = rs[0].CON_TIPGAS;
            }
          }
          this.encfacproService.erTipGasCodigo1().subscribe(er => {
            if (er !== undefined) {
              if (er[0] !== null) {
                strTipoGasto = er[0].TIPGAS_CODIGO;
              }
            }

            if (strDato === 'Si') {
              this.cxptrncobroService.variosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TIPGASCODIGO = strTipoGasto;
              this.cxptrncobroService.variosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOLTIPOGASTO = true;
            } else {
              this.cxptrncobroService.variosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TIPGASCODIGO = '';
              this.cxptrncobroService.variosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOLTIPOGASTO = false;
            }
          });
        });
        console.log(opcion.CON_CENTRO);
        if(opcion.CON_CENTRO === 'Si'){
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDef.objeto].setRowData(this.cxptrncobroService.variosarray[this.init.tabs[this.init.tabindex].indice]);
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CENCODIGO');
        }else{
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDef.objeto].setRowData(this.cxptrncobroService.variosarray[this.init.tabs[this.init.tabindex].indice]);
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DEBE');
        }
      }
    }

    if (this.opcionbusqueda === 'CENCODIGOcxpvarios') {
      this.cxptrncobroService.variosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CENCODIGO = opcion.CEN_CODIGO;
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDef.objeto].setRowData(this.cxptrncobroService.variosarray[this.init.tabs[this.init.tabindex].indice]);
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
          this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DEBE');
    }

    if (this.opcionbusqueda === 'tgcxpvarios') {
      this.cxptrncobroService.variosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TIPGASCODIGO = opcion.TIPGAS_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDef.objeto].setRowData(this.cxptrncobroService.variosarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TIPGASCODIGO');
    }

    if (this.opcionbusqueda === 'TIPODOCcxpfpant') {
      this.cxptrncobroService.fpantarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TIPODOC = opcion.FORMAPAGO_TIPO;
      if (opcion.FORMAPAGO_TIPO === 'EF') {
        this.cxptrncobroService.fpantarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJADOC =
          await this.cxptrncobroService.obtenerCaja(opcion.FORMAPAGO_TIPO);
        this.cxptrncobroService.fpantarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CONCODIGO =
          await this.cxptrncobroService.obtenerCuentaCaja(this.cxptrncobroService.fpantarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJADOC);
      }

      // Asignar un número dependiendo del tipo de pago
      if (opcion.FORMAPAGO_TIPO === 'EF') {
        this.cxptrncobroService.fpantarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].NUMERODOC =
          this.cxptrncobroService.obtenerNumero(this.indexunidad);
      } else {
        this.cxptrncobroService.fpantarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].NUMERODOC = '';
      }

      this.cxptrncobroService.fpantarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCODIGO = '';
      this.cxptrncobroService.fpantarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANBANCO = '';

      if (opcion.FORMAPAGO_TIPO === 'CH') {
        this.cxptrncobroService.fpantarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJADOC = '';
      }

      if (opcion.FORMAPAGO_TIPO === 'ND') {
        this.cxptrncobroService.fpantarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJADOC = '';
      }

      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(
        this.cxptrncobroService.fpantarray[this.init.tabs[this.init.tabindex].indice]);
      if (opcion.FORMAPAGO_TIPO === 'EF') {
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(
          this.indexunidad, 'CAJADOC');
      }
      if (opcion.FORMAPAGO_TIPO === 'CH') {
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(
          this.indexunidad, 'BANCODIGO');
      }
    }

    if (this.opcionbusqueda === 'BANCODIGOcxpfpant') {
      this.cxptrncobroService.fpantarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCODIGO = opcion.BAN_CODIGO;
      this.cxptrncobroService.fpantarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANBANCO = opcion.BAN_BANCO;

      if (this.cxptrncobroService.fpantarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TIPODOC === 'CH') {
        console.log(this.cxptrncobroService.intNumCheAut);
        if (this.cxptrncobroService.intNumCheAut === 1) {
          this.cxptrncobroService.fpantarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].NUMERODOC =
            await this.cxptrncobroService.obtenerNumeroCheque(opcion.BAN_CODIGO);
        } else {
          this.cxptrncobroService.fpantarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].NUMERODOC = '';
        }
      } else {
        this.cxptrncobroService.fpantarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].NUMERODOC = '';
      }
      this.cxptrncobroService.fpantarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CONCODIGO = await this.cxptrncobroService.obtenerCuentaBanco(opcion.BAN_CODIGO);

      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(
        this.cxptrncobroService.fpantarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(
        this.indexunidad, 'NUMERODOC');
    }

    if (this.opcionbusqueda === 'CAJADOCcxpfpant') {
      this.cxptrncobroService.fpantarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJADOC = opcion.CAJ_CODIGO;
      this.cxptrncobroService.fpantarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CONCODIGO =
        await this.cxptrncobroService.obtenerCuentaCaja(opcion.CAJ_CODIGO);

      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(
        this.cxptrncobroService.fpantarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(
        this.indexunidad, 'NUMERODOC');
    }

    if (this.opcionbusqueda === 'TRNPAGOTIPOcxpfpdev') {
      this.cxptrncobroService.fpdevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGOTIPO = opcion.FORMAPAGO_TIPO;
      if (opcion.FORMAPAGO_TIPO === 'EF') {
        this.cxptrncobroService.fpdevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJCODIGO =
          await this.cxptrncobroService.obtenerCaja(opcion.FORMAPAGO_TIPO);
        this.cxptrncobroService.fpdevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CONCODIGO =
          await this.cxptrncobroService.obtenerCuentaCaja(this.cxptrncobroService.fpdevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJCODIGO);
      }

      // Asignar un número dependiendo del tipo de pago
      if (opcion.FORMAPAGO_TIPO === 'EF') {
        this.cxptrncobroService.fpdevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGONUMERO =
          this.cxptrncobroService.obtenerNumero(this.indexunidad);
      } else {
        this.cxptrncobroService.fpdevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGONUMERO = '';
      }

      this.cxptrncobroService.fpdevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLICODIGO = '';
      this.cxptrncobroService.fpdevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLINOMBRE = '';

      if (opcion.FORMAPAGO_TIPO === 'CH') {
        this.cxptrncobroService.fpdevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJCODIGO =
          await this.cxptrncobroService.obtenerCaja(opcion.FORMAPAGO_TIPO);
        this.cxptrncobroService.fpdevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CONCODIGO =
          await this.cxptrncobroService.obtenerCuentaCaja(this.cxptrncobroService.fpdevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJCODIGO);
      }

      if (opcion.FORMAPAGO_TIPO === 'ND') {
        this.cxptrncobroService.fpdevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJCODIGO = '';
      }

      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(
        this.cxptrncobroService.fpdevarray[this.init.tabs[this.init.tabindex].indice]);
      if (opcion.FORMAPAGO_TIPO === 'EF') {
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(
          this.indexunidad, 'CAJADOC');
      }
      if (opcion.FORMAPAGO_TIPO === 'CH') {
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(
          this.indexunidad, 'BANCODIGO');
      }
    }

    if (this.opcionbusqueda === 'BANCLICODIGOcxpfpdev') {
      let bancodigo = '';
      let bannombre = '';
      if (this.cxptrncobroService.fpdevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGOTIPO === 'CH') {
        bancodigo = opcion.BANCLI_CODIGO;
        bannombre = opcion.BANCLI_NOMBRE;
      }
      if (this.cxptrncobroService.fpdevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGOTIPO === 'DP') {
        bancodigo = opcion.BAN_CODIGO;
        bannombre = opcion.BAN_BANCO;
        this.cxptrncobroService.fpdevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CONCODIGO =
          await this.cxptrncobroService.obtenerCuentaBanco(bancodigo);
      }
      this.cxptrncobroService.fpdevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLICODIGO = bancodigo;
      this.cxptrncobroService.fpdevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLINOMBRE = bannombre;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(
        this.cxptrncobroService.fpdevarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(
        this.indexunidad, 'NUMERODOC');
    }

    if (this.opcionbusqueda === 'CAJCODIGOcxpfpdev') {
      this.cxptrncobroService.fpdevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CAJCODIGO = opcion.CAJ_CODIGO;
      this.cxptrncobroService.fpdevarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CONCODIGO =
        await this.cxptrncobroService.obtenerCuentaCaja(opcion.CAJ_CODIGO);

      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(
        this.cxptrncobroService.fpdevarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(
        this.indexunidad, 'NUMERODOC');
    }

    if (this.opcionbusqueda === 'CON_CODIGOIMP') {
      this.sacimaeconfService.detConMaeImpArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
      this.sacimaeconfService.validarCuentaContable(
        this.sacimaeconfService.detConMaeImpArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(
        this.sacimaeconfService.detConMaeImpArray[this.init.tabs[this.init.tabindex].indice]);
    }

    if (this.opcionbusqueda === 'ARTCODIGO_AJUSTE') {
      const existe = this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice].find(
        ajuste => ajuste.ART_CODIGO === opcion.ART_CODIGO && ajuste.ART_NOMBRE !== '');

      this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_CODIGO = '';
      this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_NOMBRE = '';
      this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_EXISTENCIA = '';

      if (existe === undefined) {
        this.invtrnajustesService.existenciaActualArticulo(opcion.ART_CODIGO,
          this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO).subscribe((res) => {
            if (res !== null) {
              if (res[0].EXISTENCIA > 0) {

                this.invtrnajustesService.costoActualArticulo(opcion.ART_CODIGO).subscribe((costo) => {
                  if (costo !== null) {
                    this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_COSTO =
                      Number(costo[0].COSTOPROM).toFixed(this.invtrnajustesService.obtenerConfig('COSTOTOTAL', 'detkardex'));
                    this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_CODIGO = opcion.ART_CODIGO;
                    this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_NOMBRE = opcion.ART_NOMBRE;
                    this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_EXISTENCIA = res[0].EXISTENCIA;
                    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(
                      this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice]);
                    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(
                      this.indexunidad, 'COSTO_AJUSTE');
                  }
                });
              } else {
                this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_CODIGO = '';
                this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_NOMBRE = '';
                this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_EXISTENCIA = '';
                this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_COSTO = '';
                this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(
                  this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice]);
                this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(
                  this.indexunidad, 'ART_CODIGO');
                this.messageService.add({
                  key: 'invtrnajustes',
                  severity: 'error',
                  summary: 'Artículo',
                  detail: 'El artículo no tiene existencias'
                });
              }
            }
          });
      } else {
        this.messageService.add({
          key: 'invtrnajustes',
          severity: 'error',
          summary: 'Artículo',
          detail: 'El artículo seleccionado ya se encuentra en el detalle'
        });
        this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_CODIGO = '';
        this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_NOMBRE = '';
        this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_EXISTENCIA = '';
        this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_COSTO = '';
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(
          this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(
          this.indexunidad, 'ART_CODIGO');
      }
    }
    if (this.opcionbusqueda === 'ARTCODIGO_AJUSTENEG') {
      const existe = this.invtrnajustenegService.detTrnAjustesNeg.find(
        ajuste => ajuste.ART_CODIGO === opcion.ART_CODIGO && ajuste.ART_NOMBRE !== '');

      this.invtrnajustenegService.detTrnAjustesNeg[this.indexunidad].ART_CODIGO = '';
      this.invtrnajustenegService.detTrnAjustesNeg[this.indexunidad].ART_NOMBRE = '';
      this.invtrnajustenegService.detTrnAjustesNeg[this.indexunidad].ART_EXISTENCIA = '';

      if (existe === undefined) {
        this.invtrnajustenegService.existenciaActualArticuloAjNeg(opcion.ART_CODIGO,
          this.invtrnajustenegService.detTrnAjustesNeg[this.indexunidad].BOD_CODIGO).subscribe((res) => {
            if (res !== null) {
              if (res[0].EXISTENCIA > 0) {

                this.invtrnajustenegService.costoActualArticuloAjNeg(opcion.ART_CODIGO).subscribe((costo) => {
                  if (costo !== null) {
                    this.invtrnajustenegService.detTrnAjustesNeg[this.indexunidad].TRNART_COSTOT =
                      Number(costo[0].COSTOPROM).toFixed(this.invtrnajustenegService.decimales);
                    this.invtrnajustenegService.detTrnAjustesNeg[this.indexunidad].ART_CODIGO = opcion.ART_CODIGO;
                    this.invtrnajustenegService.detTrnAjustesNeg[this.indexunidad].ART_NOMBRE = opcion.ART_NOMBRE;
                    this.invtrnajustenegService.detTrnAjustesNeg[this.indexunidad].ART_EXISTENCIA = res[0].EXISTENCIA;
                    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(
                      this.invtrnajustenegService.detTrnAjustesNeg);
                    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(
                      this.indexunidad, 'TRNART_NUMERO');
                  }
                });
              } else {
                this.invtrnajustenegService.detTrnAjustesNeg[this.indexunidad].ART_CODIGO = '';
                this.invtrnajustenegService.detTrnAjustesNeg[this.indexunidad].ART_NOMBRE = '';
                this.invtrnajustenegService.detTrnAjustesNeg[this.indexunidad].ART_EXISTENCIA = '';
                this.invtrnajustenegService.detTrnAjustesNeg[this.indexunidad].TRNART_COSTOT = '';
                this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(
                  this.invtrnajustenegService.detTrnAjustesNeg);
                this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(
                  this.indexunidad, 'ART_CODIGO');
                this.messageService.add({
                  key: 'invtrnajustes',
                  severity: 'error',
                  summary: 'Artículo',
                  detail: 'El artículo no tiene existencias'
                });
              }
            }
          });
      } else {
        this.messageService.add({
          key: 'invtrnajustes',
          severity: 'error',
          summary: 'Artículo',
          detail: 'El artículo seleccionado ya se encuentra en el detalle'
        });
        this.invtrnajustenegService.detTrnAjustesNeg[this.indexunidad].ART_CODIGO = '';
        this.invtrnajustenegService.detTrnAjustesNeg[this.indexunidad].ART_NOMBRE = '';
        this.invtrnajustenegService.detTrnAjustesNeg[this.indexunidad].ART_EXISTENCIA = '';
        this.invtrnajustenegService.detTrnAjustesNeg[this.indexunidad].TRNART_COSTOT = '';
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(
          this.invtrnajustenegService.detTrnAjustesNeg);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(
          this.indexunidad, 'ART_CODIGO');
      }
    }
    
    if (this.opcionbusqueda === 'CONCODIGO_AJUSTES') {

      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'invtrnajustes',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;

        this.encasilService.erConcentro(opcion.CON_CODIGO).subscribe((cen) => {
          if (cen !== null) {
            this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CENTRO = cen[0].CON_CENTRO;
          }
        });

        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(
          this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(
          this.indexunidad, 'CEN_CODIGO');
        // this.invtrnajustesService.insertarElemento(this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice][this.indexunidad], 'CON_CODIGO');

      }
    }
    //CDPJ
    if (this.opcionbusqueda === 'CONCODIGO_AJUSTESNEG') {

      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'invtrnajustes',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.invtrnajustenegService.detTrnAjustesNeg[this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;

        this.encasilService.erConcentro(opcion.CON_CODIGO).subscribe((cen) => {
          if (cen !== null) {
            this.invtrnajustenegService.detTrnAjustesNeg[this.indexunidad].CON_CENTRO = cen[0].CON_CENTRO;
          }
        });

        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(
          this.invtrnajustenegService.detTrnAjustesNeg);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(
          this.indexunidad, 'CEN_CODIGO');
        // this.invtrnajustesService.insertarElemento(this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice][this.indexunidad], 'CON_CODIGO');

      }
    }
    //CDPJ
    if (this.opcionbusqueda === 'CENCODIGO_AJUSTES') {
      this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(
        this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice]);
      this.invtrnajustesService.activarBotonesDet(false, false, true);
      // this.invtrnajustesService.insertarElemento(this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice][this.indexunidad], 'CEN_CODIGO');
    }

    if (this.opcionbusqueda === 'BOD_CODIGOAJUSTE') {
      this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO = opcion.BOD_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(
        this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(
        this.indexunidad, 'ART_CODIGO');
      // this.invtrnajustesService.insertarElemento(this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice][this.indexunidad], 'CEN_CODIGO');
    }

    if (this.opcionbusqueda === 'DETFACPRO_TIPOSRI') {
      this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_TIPOSRI = opcion.REF_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFACPRO_TRIBASENOOBJIVA');
    }

    if (this.opcionbusqueda === 'DETFAC_TIPOSRI') {
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TIPOSRI = opcion.REF_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFAC_LISTA');

    }
    //CDPJ
    
    if (this.opcionbusqueda === 'DETDVF_TIPOSRI') {
      this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TIPOSRI = opcion.REF_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFAC_TIPOSRI');
    }
    //CDPJ
    if (this.opcionbusqueda === 'BOD_CODIGOGUIAREM') {
      //GSRF
      this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_CODIGO ='';
      this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_DESCRIPCION ='';
      this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_CANTIDAD='';
      this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_PRECIO='';  
      //GSRF
      this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO = opcion.BOD_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETGRE_CODIGO');
    }

    if (this.opcionbusqueda === 'bodordentrabajo') {
      this.encordtraService.tableDetalle[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGO = opcion.BOD_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encordtraService.tableDetalle[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETORDTRA_CODIGO');
    }

    if (this.opcionbusqueda === 'CENCODIGO_CXPCUOTA') {
      this.cxpenccuotaService.detCuotaVarArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cxpenccuotaService.detCuotaVarArray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CEN_CODIGO');
    }

    if (this.opcionbusqueda === 'CONCODIGO_CXPCUOTA') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'cxpcuota',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.cxpenccuotaService.detCuotaVarArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.cxpenccuotaService.detCuotaVarArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_NOMBRE = opcion.CON_NOMBRE;
        this.encasilService.erConcentro(opcion.CON_CODIGO).subscribe((cen) => {
          if (cen !== null) {
            this.cxpenccuotaService.detCuotaVarArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CENTRO = cen[0].CON_CENTRO;
          }
        });

        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(
          this.cxpenccuotaService.detCuotaVarArray[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(
          this.indexunidad, 'TRNCUOTASVAR_IMPORTE');

      }

    }
    if (this.opcionbusqueda === 'tiporubro') {
      this.nonmaeRubService.nommaeRubro[this.indexunidad].MAERUBRO_TIPO = opcion.REF_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nonmaeRubService.nommaeRubro);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'MAERUBRO_CODRUBR');

    }

    if (this.opcionbusqueda === 'NomRubUnidad') {
      this.nonmaeRubService.nommaeRubro[this.indexunidad].MAERUBRO_UNIDAD = opcion.REF_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nonmaeRubService.nommaeRubro);

      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'MAERUBRO_GRAVABLE');

    }
    if (this.opcionbusqueda === 'CON_CODIGO_NOM_RUB') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'maeRubrotoast',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.nonmaeRubService.nommaeRubro[this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nonmaeRubService.nommaeRubro);

        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGO_2');
      }


    }
    if (this.opcionbusqueda === 'NomRubConCodi2') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'maeRubrotoast',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.nonmaeRubService.nommaeRubro[this.indexunidad].CON_CODIGO_2 = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nonmaeRubService.nommaeRubro);

        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGO_3');
      }
    }
    if (this.opcionbusqueda === 'NomRubConCodi3') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'maeRubrotoast',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.nonmaeRubService.nommaeRubro[this.indexunidad].CON_CODIGO_3 = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nonmaeRubService.nommaeRubro);

        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGO_4');
      }
    }
    if (this.opcionbusqueda === 'NomRubConCodi4') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'maeRubrotoast',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.nonmaeRubService.nommaeRubro[this.indexunidad].CON_CODIGO_4 = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nonmaeRubService.nommaeRubro);

        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGO_5');
      }
    }
    if (this.opcionbusqueda === 'NomRubConCodi5') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'maeRubrotoast',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.nonmaeRubService.nommaeRubro[this.indexunidad].CON_CODIGO_5 = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nonmaeRubService.nommaeRubro);

        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGO_6');
      }
    }
    if (this.opcionbusqueda === 'NomRubConCodi6') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'maeRubrotoast',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.nonmaeRubService.nommaeRubro[this.indexunidad].CON_CODIGO_6 = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nonmaeRubService.nommaeRubro);

        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGO_7');
      }
    }
    if (this.opcionbusqueda === 'NomRubConCodi7') {

      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'maeRubrotoast',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.nonmaeRubService.nommaeRubro[this.indexunidad].CON_CODIGO_7 = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nonmaeRubService.nommaeRubro);

        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGOHABE');
      }
    }
    if (this.opcionbusqueda === 'NomRubConCodHab') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'maeRubrotoast',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.nonmaeRubService.nommaeRubro[this.indexunidad].CON_CODIGOHABE = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nonmaeRubService.nommaeRubro);

        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGOHABE_2');
      }
    }
    if (this.opcionbusqueda === 'NomRubConCodHab2') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'maeRubrotoast',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.nonmaeRubService.nommaeRubro[this.indexunidad].CON_CODIGOHABE_2 = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nonmaeRubService.nommaeRubro);

        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGOHABE_3');
      }
    }
    if (this.opcionbusqueda === 'NomRubConCodHab3') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'maeRubrotoast',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.nonmaeRubService.nommaeRubro[this.indexunidad].CON_CODIGOHABE_3 = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nonmaeRubService.nommaeRubro);

        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGOHABE_4');
      }
    }
    if (this.opcionbusqueda === 'NomRubConCodHab4') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'maeRubrotoast',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.nonmaeRubService.nommaeRubro[this.indexunidad].CON_CODIGOHABE_4 = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nonmaeRubService.nommaeRubro);

        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGOHABE_5');
      }
    }
    if (this.opcionbusqueda === 'NomRubConCodHab5') {

      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'maeRubrotoast',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.nonmaeRubService.nommaeRubro[this.indexunidad].CON_CODIGOHABE_5 = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nonmaeRubService.nommaeRubro);

        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGOHABE_6');
      }
    }
    if (this.opcionbusqueda === 'NomRubConCodHab6') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'maeRubrotoast',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.nonmaeRubService.nommaeRubro[this.indexunidad].CON_CODIGOHABE_6 = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nonmaeRubService.nommaeRubro);

        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGOHABE_7');
      }
    }
    if (this.opcionbusqueda === 'NomRubConCodHab7') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'maeRubrotoast',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.nonmaeRubService.nommaeRubro[this.indexunidad].CON_CODIGOHABE_7 = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nonmaeRubService.nommaeRubro);

        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'MAERUBRO_FECINI');
      }
    }

    if (this.opcionbusqueda === 'NUM_DOCREFBUS') {
      this.numCService.detNumeracionC[this.indexunidad].NUM_DOCREF = opcion.REF_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.numCService.detNumeracionC);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'NUM_NEMONICO');
    }

    if (this.opcionbusqueda === 'BOD_CODIGORESERVA') {
      this.invreservaService.detalleReservas[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOD_CODIGODES = opcion.BOD_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(
        this.invreservaService.detalleReservas[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(
        this.indexunidad, 'ART_CODIGO');
      // this.invtrnajustesService.insertarElemento(this.invtrnajustesService.detTrnAjustes[this.init.tabs[this.init.tabindex].indice][this.indexunidad], 'CEN_CODIGO');
    }

    if (this.opcionbusqueda === 'ARTCODIGO_RESERVA') {

      console.log(opcion);

      if (opcion.EXISTENCIA <= 0) {

        this.messageService.add({
          key: 'invreserva',
          severity: 'error',
          summary: 'Información',
          detail: 'El articulo seleccionado no tiene existencias'
        });

        this.invreservaService.detalleReservas[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_CODIGO = '';
        this.invreservaService.detalleReservas[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_NOMBRE = '';
        this.invreservaService.detalleReservas[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_UNIDAD = '';
        this.invreservaService.detalleReservas[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_EXISTENCIA = '';
        this.invreservaService.detalleReservas[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRES_CANTIDAD = Number(0).toFixed(2);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(
          this.invreservaService.detalleReservas[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(
          this.indexunidad, 'ART_CODIGO');

      } else {
        this.invreservaService.detalleReservas[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_CODIGO = opcion.ART_CODIGO;
        this.invreservaService.detalleReservas[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_NOMBRE = opcion.ART_NOMBRE;
        this.invreservaService.detalleReservas[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRES_CANTIDAD = Number(0).toFixed(2);
        this.invreservaService.detalleReservas[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_UNIDAD = opcion.ART_UNIDADCOSTEO;
        this.invreservaService.detalleReservas[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_EXISTENCIA = opcion.EXISTENCIA;

        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(
          this.invreservaService.detalleReservas[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(
          this.indexunidad, 'TRNRES_CANTIDAD');
      }
    }

    if (this.opcionbusqueda === 'DETFAC_CENCOD') {
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CEN_CODIGO');
    }
    //CDPJ
    if (this.opcionbusqueda === 'CEN_GUIA') {
    this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice]);
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CEN_CODIGO');
    }
    if (this.opcionbusqueda === 'CEN_DEVF'){
      this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CEN_CODIGO');
    }
        //CDPJ
    if (this.opcionbusqueda === 'CON_CODIGO_INGRFIJ') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'maeEmpleadob',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.nomempleadosService.nommaenomIngrFijo[this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.nomempleadosService.nommaenomIngrFijo[this.indexunidad].MAEINGRFIJO_DESCRIPCION = opcion.CON_NOMBRE;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nomempleadosService.nommaenomIngrFijo);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CEN_CODIGO');
      }
    }
    if (this.opcionbusqueda === 'ingrRUBRO_CODIGO') {

      this.nomempleadosService.nommaenomIngrFijo[this.indexunidad].MAERUBRO_CODIGO = opcion.MAERUBRO_CODIGO;
      this.nomempleadosService.nommaenomIngrFijo[this.indexunidad].MAERUBRO_CODIGO2 = opcion.MAERUBRO_CODRUBR;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nomempleadosService.nommaenomIngrFijo);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'MAEINGRFIJO_IMPORTE');

    }
    if (this.opcionbusqueda === 'CON_CODIGO_EGREFIJ') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'maeEmpleadob',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {

        this.nomempleadosService.nommaeEgreFijo[this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.nomempleadosService.nommaeEgreFijo[this.indexunidad].MAEEGREFIJO_DESCRIPCION = opcion.CON_NOMBRE;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nomempleadosService.nommaeEgreFijo);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CEN_CODIGO');
      }
    }
    if (this.opcionbusqueda === 'egreRUBRO_CODIGO') {
      this.nomempleadosService.nommaeEgreFijo[this.indexunidad].MAERUBRO_CODIGO = opcion.MAERUBRO_CODIGO;
      this.nomempleadosService.nommaeEgreFijo[this.indexunidad].MAERUBRO_CODIGO2 = opcion.MAERUBRO_CODRUBR;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nomempleadosService.nommaeEgreFijo);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'MAEEGREFIJO_IMPORTE');

    }
    if (this.opcionbusqueda === 'cargaFAMI_ID') {
      this.nomempleadosService.nomCargaFamil[this.indexunidad].EMPCARGFAMI_TIPOIDE = opcion.REF_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nomempleadosService.nomCargaFamil);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'EMPCARGFAMI_RUCIDE');

    }
    if (this.opcionbusqueda === 'cargafaSEXO') {
      this.nomempleadosService.nomCargaFamil[this.indexunidad].EMPCARGFAMI_SEXO = opcion.REF_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nomempleadosService.nomCargaFamil);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'EMPCARGFAMI_TIPORELA2');

    }
    if (this.opcionbusqueda === 'cargafaTIPORELA') {
      this.nomempleadosService.nomCargaFamil[this.indexunidad].EMPCARGFAMI_TIPORELA2 = opcion.REF_NOMBRE;
      this.nomempleadosService.nomCargaFamil[this.indexunidad].EMPCARGFAMI_TIPORELA = opcion.REF_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nomempleadosService.nomCargaFamil);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'EMPCARGFAMI_FECHNACI');

    }
    if (this.opcionbusqueda === 'cargafaESTATUS') {
      this.nomempleadosService.nomCargaFamil[this.indexunidad].EMPCARGFAMI_ESTATUS = opcion.REF_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nomempleadosService.nomCargaFamil);
    }
    if (this.opcionbusqueda === 'valorcrudVaca') {
      this.nomempleadosService.nomcrudVaca[this.indexunidad].TRANVACAEMPL_TIPO = opcion.REF_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nomempleadosService.nomcrudVaca);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRANVACAEMPL_PERIODO');
    }
    if (this.opcionbusqueda === 'valorcrudVacEst') {
      this.nomempleadosService.nomcrudVaca[this.indexunidad].TRANVACAEMPL_ESTADO = opcion.REF_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nomempleadosService.nomcrudVaca);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRANVACAEMPL_SALDODIAS');
    }
    if (this.opcionbusqueda === 'busquedavenserped') {
      this.cliService.venservicioped[this.init.tabs[this.init.tabindex].indice][this.indexunidad].SER_CODIGO = opcion.SER_CODIGO;
      this.cliService.venservicioped[this.init.tabs[this.init.tabindex].indice][this.indexunidad].SER_DESCRIPCION = opcion.SER_DESCRIPCION;
      this.cliService.venservicioped[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNSERTRAN_PRECIO = opcion.SER_PRECIO.toFixed(this.cliService.decimalesPrecio);
      this.cliService.venservicioped[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNSERTRAN_DESCUENTO = Number('0').toFixed(this.cliService.decimalesPrecio);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.cliService.venservicioped[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNSERTRAN_DESCUENTO');
    }
    if (this.opcionbusqueda === 'empcodigocabi') {
      this.impurentService.detImpuRent[this.indexunidad].EMP_CODIGO = opcion.EMP_CODIGO;
      this.impurentService.detImpuRent[this.indexunidad].EMP_NOMBRE = String(opcion.EMP_APELLIDO) + " " + String(opcion.EMP_NOMBRE);
      this.impurentService.detImpuRent[this.indexunidad].EMP_RUCIDE = opcion.EMP_RUCIDE;
      this.impurentService.detImpuRent[this.indexunidad].EMP_FECINGRESO = this.datePipe.transform(opcion.EMP_FECINGRESO, 'dd/MM/yyyy');
      this.impurentService.detImpuRent[this.indexunidad].EMP_FECSALIDA = this.datePipe.transform(opcion.EMP_FECSALIDA, 'dd/MM/yyyy');
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.impurentService.detImpuRent);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETIMPURENT_INGRGRAV_OE');
    }
    if (this.opcionbusqueda === 'emptrannoved') {
      this.nomtrannovedadservice.nonnovedad[this.indexunidad].EMP_NOMBRE = String(opcion.EMP_APELLIDO) + " " + String(opcion.EMP_NOMBRE);
      this.nomtrannovedadservice.nonnovedad[this.indexunidad].EMP_CODIGO2 = opcion.EMP_RUCIDE;
      this.nomtrannovedadservice.nonnovedad[this.indexunidad].EMP_CODIGO = opcion.EMP_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nomtrannovedadservice.nonnovedad);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'MAERUBRO_CODRUBR');
    }
    if (this.opcionbusqueda === 'emptrnhorashombre') {
      this.encordproService.trnhorashombre[this.init.tabs[this.init.tabindex].indice][this.indexunidad].EMP_CODIGO = opcion.EMP_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encordproService.trnhorashombre[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNHH_REFERENCIA');
    }

    if (this.opcionbusqueda === 'rubcodnoved') {
      /* this.nomtrannovedadservice.nonnovedad[this.indexunidad].EMP_NOMBRE = String(opcion.EMP_APELLIDO) + " " + String(opcion.EMP_NOMBRE);
      this.nomtrannovedadservice.nonnovedad[this.indexunidad].EMP_CODIGO2 = opcion.EMP_RUCIDE;
      this.nomtrannovedadservice.nonnovedad[this.indexunidad].EMP_CODIGO = opcion.EMP_CODIGO; */
      this.nomtrannovedadservice.nonnovedad[this.indexunidad].MAERUBRO_CODIGO = opcion.MAERUBRO_CODIGO;
      this.nomtrannovedadservice.nonnovedad[this.indexunidad].MAERUBRO_CODRUBR = opcion.MAERUBRO_CODRUBR;
      this.nomtrannovedadservice.nonnovedad[this.indexunidad].TRANNOVEDAD_DESCRIPCION = opcion.MAERUBRO_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nomtrannovedadservice.nonnovedad);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'MAERUBRO_CODRUBR');
    }

    if (this.opcionbusqueda === 'artcodigodetordpro') {
      this.seleccionbusquedaarticulodetordpro(opcion);
    }

    if (this.opcionbusqueda === 'TRNCOMQUI') {
      this.artService.prodtrncomqui[this.indexunidad].TRNCOMQUI_CODIGO = opcion.REF_CODIGO;
      this.artService.prodtrncomqui[this.indexunidad].TRNCOMQUI_NOMBRE = opcion.REF_NOMBREC;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.artService.prodtrncomqui);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNCOMQUI_NOMBRE');
    }

    if (this.opcionbusqueda === 'TRNAPLI') {
      this.artService.prodtrnaplicacion[this.indexunidad].TRNAPLI_CODIGO = opcion.REF_CODIGO;
      this.artService.prodtrnaplicacion[this.indexunidad].TRNAPLI_NOMBRE = opcion.REF_NOMBREC;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.artService.prodtrnaplicacion);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNAPLI_NOMBRE');
    }

    if (this.opcionbusqueda === 'NOM_TIPOPAGOUTIL') {
      this.nomUtilidadesService.detalleUtilidades[this.indexunidad].NOM_TIPOPAGOUTIL = opcion.REF_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nomUtilidadesService.detalleUtilidades);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'NOM_TIPOPAGOUTIL');
    }

    if (this.opcionbusqueda === 'NOM_TIPOPAGO_SALDIG') {
      this.nomUtilidadesService.detalleUtilidades[this.indexunidad].NOM_TIPOPAGO_SALDIG = opcion.REF_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nomUtilidadesService.detalleUtilidades);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'NOM_TIPOPAGO_SALDIG');
    }

    if (this.opcionbusqueda === 'NOM_FORMPAGOCOD') {
      this.nomUtilidadesService.detalleUtilidades[this.indexunidad].NOM_FORMPAGOCOD = opcion.REF_CODIGO;
      this.nomUtilidadesService.detalleUtilidades[this.indexunidad].NOM_FORMPAGODESC = opcion.REF_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nomUtilidadesService.detalleUtilidades);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'NOM_FORMPAGOCOD');
    }

    if (this.opcionbusqueda === 'NOM_BANCPAGOCOD') {
      this.nomUtilidadesService.detalleUtilidades[this.indexunidad].NOM_BANCPAGOCOD = opcion.BANCLI_CODIGO;
      this.nomUtilidadesService.detalleUtilidades[this.indexunidad].NOM_BANCPAGODESC = opcion.BANCLI_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nomUtilidadesService.detalleUtilidades);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'NOM_BANCPAGOCOD');
    }

    if (this.opcionbusqueda === 'NOM_TIPOCUENCOD') {
      this.nomUtilidadesService.detalleUtilidades[this.indexunidad].NOM_TIPOCUENCOD = opcion.REF_CODIGO;
      this.nomUtilidadesService.detalleUtilidades[this.indexunidad].NOM_TIPOCUENDESC = opcion.REF_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.nomUtilidadesService.detalleUtilidades);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'NOM_TIPOCUENCOD');
    }

    if (this.opcionbusqueda === 'costfecha') {
      console.log(opcion)
      this.costfechservi.costosfec[this.indexunidad].CIN_CODIGO_1 = opcion.CIN_CODIGO;
      this.costfechservi.costosfec[this.indexunidad].CIN_NOMBRE = opcion.CIN_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.costfechservi.costosfec);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CINF_COSTO');
    }
    if (this.opcionbusqueda === 'CON_CODIGO_LIQDECEVAR') {
      console.log(opcion)
      this.liqservice.nomliquvar[this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;//String(opcion.EMP_APELLIDO)+" "+String(opcion.EMP_NOMBRE);
      this.liqservice.nomliquvar[this.indexunidad].DETLIQUBENSOCVAR_DESCRIPCION = opcion.CON_NOMBRE;
      //this.nomtrannovedadservice.nonnovedad[this.indexunidad].MAERUBRO_CODIGO =opcion.MAERUBRO_CODIGO; 
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.liqservice.nomliquvar);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'cen_codigo');
    }
    if (this.opcionbusqueda === 'CENCODIGO_LIQ_SER') {
      console.log(opcion)
      this.liqservice.nomliquvar[this.indexunidad].CEN_CODIGO = opcion.CEN_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.liqservice.nomliquvar);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETLIQUBENSOCVAR_DEBE');
    }
    if (this.opcionbusqueda === 'busquedaFormaPagoLiq') {
      console.log(opcion)
      this.liqservice.nomliqdet[this.indexunidad].DETLIQUBENESOCI_FORMPAGODESC = opcion.REF_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.liqservice.nomliqdet);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETLIQUBENESOCI_BANCPAGODESC');
    }
    if (this.opcionbusqueda === 'busquedaBancoLiq') {
      console.log(opcion)
      this.liqservice.nomliqdet[this.indexunidad].DETLIQUBENESOCI_BANCPAGODESC = opcion.BANCLI_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.liqservice.nomliqdet);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETLIQUBENESOCI_TIPOCUENDESC');
    }
    if (this.opcionbusqueda === 'busquedaNumeCueLiq') {
      console.log(opcion)
      this.liqservice.nomliqdet[this.indexunidad].DETLIQUBENESOCI_TIPOCUENDESC = opcion.REF_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.liqservice.nomliqdet);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETLIQUBENESOCI_DESCRETEJUDI');
    }
    if (this.opcionbusqueda === 'busquedalotestrnlote') {
      this.encordproService.lotes[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNLOTE_LOTE = opcion.LOTE;
      this.encordproService.lotes[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNLOTE_CANTIDAD = opcion.CANTIDAD;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encordproService.lotes[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNLOTE_CANTIDAD');
    }
    if (this.opcionbusqueda === 'busquedaetiqueta') {
      this.equipoService.tecmaeequipodet[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ETI_CODIGO = opcion.ETI_CODIGO;
      this.equipoService.tecmaeequipodet[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ETI_NOMBRE = opcion.ETI_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.equipoService.tecmaeequipodet[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'ETI_NOMBRE');
    }
    if (this.opcionbusqueda === 'busquedaetiquetadetmant') {
      this.tecencmantService.tecdetmant[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ETI_CODIGO = opcion.ETI_CODIGO;
      this.tecencmantService.tecdetmant[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ETI_NOMBRE = opcion.ETI_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.tecencmantService.tecdetmant[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETMAT_DESCRIPCION');
    }

    if (this.opcionbusqueda === 'busquedaarticulodetordtra') {
      this.encordtraService.tableDetalle[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDTRA_CODIGO = opcion.ART_CODIGO;
      this.encordtraService.tableDetalle[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDTRA_DESCRIPCION = opcion.ART_NOMBRE;

      const datas = await this.encordtraService.consultarArticuloSelProm(opcion.ART_CODIGO);

      for (const data of datas) {
        this.encordtraService.tableDetalle[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDTRA_UNIDAD = data.ART_UNIDADVENTA;
        this.encordtraService.tableDetalle[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDTRA_TRIBIVA = data.ART_TRIBUTAIVA === null ? '' : data.ART_TRIBUTAIVA;
        this.encordtraService.tableDetalle[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDTRA_CANTIDAD = '1.00';
        this.encordtraService.tableDetalle[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDTRA_PRECIO = data.ART_PRECIO;
      }

      this.encordtraService.calcularTotales();

      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encordtraService.tableDetalle[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETORDTRA_CANTIDAD');
    }

    if (this.opcionbusqueda === 'busquedaserviciodetordtra') {
      this.encordtraService.tableDetalle[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDTRA_CODIGO = opcion.SER_CODIGO;
      this.encordtraService.tableDetalle[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDTRA_DESCRIPCION = opcion.SER_DESCRIPCION;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encordtraService.tableDetalle[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETORDTRA_CANTIDAD');
    }

    if (this.opcionbusqueda === 'CON_CODIGO_ACTGRUPO') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'maeEmpleadob',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {
        this.actmaegrupoService.detmaeGrupoArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.actmaegrupoService.detmaeGrupoArray[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CON_CODIGO');
      }
    }

    if (this.opcionbusqueda === 'busquedavendedorreglla') {
      this.encordtraService.tableRegistroLLamadas[this.init.tabs[this.init.tabindex].indice][this.indexunidad].VEN_CODIGO = opcion.VEN_CODIGO;
      this.encordtraService.tableRegistroLLamadas[this.init.tabs[this.init.tabindex].indice][this.indexunidad].VEN_NOMBRE = opcion.VEN_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encordtraService.tableRegistroLLamadas[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNREGLLAMOT_TIPO');
    }

    if (this.opcionbusqueda === 'busquedavendedorenvcor') {
      this.encordtraService.tableEnvioCorreos[this.init.tabs[this.init.tabindex].indice][this.indexunidad].VEN_CODIGO = opcion.VEN_CODIGO;
      this.encordtraService.tableEnvioCorreos[this.init.tabs[this.init.tabindex].indice][this.indexunidad].VEN_NOMBRE = opcion.VEN_NOMBRE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encordtraService.tableEnvioCorreos[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNCORREOSOT_MOTIVO');
    }

    if (this.opcionbusqueda === 'ACTCODIGO') {
      this.actencsalidaService.detSalidaArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ACT_CODIGO = opcion.ACT_CODIGO;
      this.actencsalidaService.detSalidaArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETSALIDA_DESCRIPCION = opcion.ACT_NOMBRE;

      let strCosto = '';
      try {
        const rs = await this.actencsalidaService.encontrarRegistroPromise('act_costo', 'act_maeactivo',
          'act_codigo = \'' + opcion.ACT_CODIGO + '\'');
        if (rs != null) {
          for (const res of rs) {
            strCosto = res.ACT_COSTO === null ? '' : Number(res.ACT_COSTO).toFixed(2);
          }
        }
      } catch (err) {

      }

      if (strCosto !== '') {
        this.actencsalidaService.detSalidaArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETSALIDA_IMPORTE = strCosto;
      }

      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.actencsalidaService.detSalidaArray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETSALIDA_REFERENCIA');

    }

    if (this.opcionbusqueda === 'CON_CODIGO_ACTSALIDA') {
      if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
        this.messageService.add({
          key: 'maeEmpleadob',
          severity: 'error',
          summary: 'Cuenta Contable',
          detail: 'No se puede elegir esa cuenta contable'
        });
      } else {

        this.actencsalidaService.detVariosSalidaArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = opcion.CON_CODIGO;
        this.actencsalidaService.detVariosSalidaArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETVARIOSSAL_DESCRIPCION = opcion.CON_NOMBRE;
        this.actencsalidaService.detVariosSalidaArray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CENTRO = opcion.CON_CENTRO;//CDPJ
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.actencsalidaService.detVariosSalidaArray[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETVARIOSSAL_IMPORTE');
      }

    }
    //CDPJ
      
    if (this.opcionbusqueda === 'tarivacodiovenmaeserS') {
      this.serviService.venmaeservicios[this.indexunidad].TARIIVA_CODIGO = opcion.TARIIVA_CODIGO;
      this.serviService.venmaeservicios[this.indexunidad].SER_PORCEIVA=opcion.TARIIVA_PORCENTAJE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.serviService.venmaeservicios);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CEN_CODIGO');
    }
    if (this.opcionbusqueda === 'tarivacodiovenmaeserN') {
      this.serviService.venmaeservicios[this.indexunidad].TARIIVA_CODIGO = opcion.TARIIVA_CODIGO;
      this.serviService.venmaeservicios[this.indexunidad].SER_PORCEIVA=opcion.TARIIVA_PORCENTAJE;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.serviService.venmaeservicios);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CEN_CODIGO');
    }
    if (this.opcionbusqueda === 'tarivacodiocommaeserS') {
      this.comService.commaeservicio[this.indexunidad].TARIIVA_CODIGO = opcion.TARIIVA_CODIGO;
      this.comService.commaeservicio[this.indexunidad].SERCOM_PORCEIVA=opcion.TARIIVA_PORCENTAJE
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.comService.commaeservicio);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CEN_CODIGO');
    }
    if (this.opcionbusqueda === 'tarivacodiocommaeserN') {
      this.comService.commaeservicio[this.indexunidad].TARIIVA_CODIGO = opcion.TARIIVA_CODIGO;
      this.comService.commaeservicio[this.indexunidad].SERCOM_PORCEIVA=opcion.TARIIVA_PORCENTAJE
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.comService.commaeservicio);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'CEN_CODIGO');
    }
    
    if(this.opcionbusqueda === 'detordcomtarivacodS'){
      this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_PORIVA=opcion.TARIIVA_PORCENTAJE
      this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=opcion.TARIIVA_CODIGO
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETORDCOM_PORIVA');
      this.encordService.calcularTotal();
    }
    if(this.opcionbusqueda === 'detordcomtarivacodN'){
      this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDCOM_PORIVA=opcion.TARIIVA_PORCENTAJE
      this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=opcion.TARIIVA_CODIGO
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encordService.detordenesarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETORDCOM_PORIVA');
      this.encordService.calcularTotal();
    }
    if(this.opcionbusqueda === 'detnotrectarivacodS'){
      this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETNOTREC_PORIVA = opcion.TARIIVA_PORCENTAJE;
      this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO = opcion.TARIIVA_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETNOTREC_PORIVA');
      console.log(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad])
      this.comnotrecServicio.calcularTotales(this.indexunidad);
    }
    if(this.opcionbusqueda === 'detnotrectarivacodN'){
      this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETNOTREC_PORIVA = opcion.TARIIVA_PORCENTAJE;
      this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO = opcion.TARIIVA_CODIGO;
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.comnotrecServicio.comnotrecRecepcionDETALLEarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETNOTREC_PORIVA');
      this.comnotrecServicio.calcularTotales(this.indexunidad);
    }
    // if(this.opcionbusqueda === 'detfacprotarivacodS'){
    //   this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_PORIVA = Number(opcion.TARIIVA_PORCENTAJE).toFixed(this.confIniciales.getNumDecSist());
    //   this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO = opcion.TARIIVA_CODIGO;
      
    //   this.encfacproService.calcularTotales();
    //   this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice]);
    //   this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFACPRO_PORIVA');
    // }
    if(this.opcionbusqueda === 'detfacprotarivacod'){
      this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_PORIVA = Number(opcion.TARIIVA_PORCENTAJE).toFixed(this.confIniciales.getNumDecSist());
      this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO = opcion.TARIIVA_CODIGO;
      
      this.encfacproService.calcularTotales();
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFACPRO_PORIVA');
    }
    if(this.opcionbusqueda === 'detdevtarivacod'){
      this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_PORIVA = Number(opcion.TARIIVA_PORCENTAJE).toFixed(this.confIniciales.getNumDecSist());
      this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO = opcion.TARIIVA_CODIGO;
      
    this.comDevFacService.calcularTotal();
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice]);
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETDEV_PORIVA');
    }
    // if(this.opcionbusqueda === 'detdevtarivacodN'){
    //   this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDEV_PORIVA = Number(opcion.TARIIVA_PORCENTAJE).toFixed(this.confIniciales.getNumDecSist());
    //   this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO = opcion.TARIIVA_CODIGO;
      
    // this.comDevFacService.calcularTotal();
    // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.comDevFacService.detdevFacarray[this.init.tabs[this.init.tabindex].indice]);
    // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETDEV_PORIVA');
    // }
    if(this.opcionbusqueda === 'detpedtarivacodS'){
      this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_PORIVA=Number(opcion.TARIIVA_PORCENTAJE).toFixed(this.confIniciales.getNumDecSist());
      this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=opcion.TARIIVA_CODIGO;
      this.encpedService.calcularTotal();
            this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice]);
            this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETPED_PORIVA');
    }
    if(this.opcionbusqueda === 'detpedtarivacodN'){
      this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETPED_PORIVA=Number(opcion.TARIIVA_PORCENTAJE).toFixed(this.confIniciales.getNumDecSist());
      this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=opcion.TARIIVA_CODIGO;
      this.encpedService.calcularTotal();
            this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encpedService.detpedidosarray[this.init.tabs[this.init.tabindex].indice]);
            this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETPED_PORIVA');
    }
    if(this.opcionbusqueda === 'detgretarivacodS'){
      this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_PORIVA=Number(opcion.TARIIVA_PORCENTAJE).toFixed(this.confIniciales.getNumDecSist());
      this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=opcion.TARIIVA_CODIGO;
      this.venenguiaRemisionService.calcularTotal();
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETGRE_PORIVA');
    }
    if(this.opcionbusqueda === 'detgretarivacodN'){
      this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETGRE_PORIVA=Number(opcion.TARIIVA_PORCENTAJE).toFixed(this.confIniciales.getNumDecSist());
      this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=opcion.TARIIVA_CODIGO;
      this.venenguiaRemisionService.calcularTotal();
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.venenguiaRemisionService.detguiaRemisionarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETGRE_PORIVA');
    }
    if(this.opcionbusqueda === 'detfactarivacodS'){
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_PORIVA=Number(opcion.TARIIVA_PORCENTAJE).toFixed(this.confIniciales.getNumDecSist());
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=opcion.TARIIVA_CODIGO;
      this.encfacService.calcularTotales();
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFAC_PORIVA');
    }
    if(this.opcionbusqueda === 'detfactarivacodN'){
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_PORIVA=Number(opcion.TARIIVA_PORCENTAJE).toFixed(this.confIniciales.getNumDecSist());
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=opcion.TARIIVA_CODIGO;
      this.encfacService.calcularTotales();
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFAC_PORIVA');
    }
    if(this.opcionbusqueda === 'detdvffactarivacodS'){
      this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_PORIVA=Number(opcion.TARIIVA_PORCENTAJE).toFixed(this.confIniciales.getNumDecSist());
      this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=opcion.TARIIVA_CODIGO;
      this.venDevFacService.calcularTotal();
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETDVF_PORIVA');
    }
    if(this.opcionbusqueda === 'detdvffactarivacodN'){
      this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETDVF_PORIVA=Number(opcion.TARIIVA_PORCENTAJE).toFixed(this.confIniciales.getNumDecSist());
      this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=opcion.TARIIVA_CODIGO;
      this.venDevFacService.calcularTotal();
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.venDevFacService.detdevfacarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETDVF_PORIVA');
    }
    if(this.opcionbusqueda === 'detfacpvtarivacodS'){
      this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_PORIVA=Number(opcion.TARIIVA_PORCENTAJE).toFixed(this.confIniciales.getNumDecSist());
      this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=opcion.TARIIVA_CODIGO;
      this.encptovtaService.calcularTotalesFact();
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFAC_PORIVA');
    }
    if(this.opcionbusqueda === 'detfacpvtarivacodN'){
      this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_PORIVA=Number(opcion.TARIIVA_PORCENTAJE).toFixed(this.confIniciales.getNumDecSist());
      this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=opcion.TARIIVA_CODIGO;
      this.encptovtaService.calcularTotalesFact();
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFAC_PORIVA');
    }
    
    //CDPJ
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].forEachNode((node) => {
      const h = this.indexunidad;
      if (Number(node.id) === h) {
        node.setSelected(true);
      }
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].ensureIndexVisible(h, 'middle');
    });

    this.displayDialogBusqueda = false;
    this.displayDialogBusquedaFast = false;
  }

  agregarpedido() {
    this.selected.emit('exportar');
  }

  enviarSenalGrid(valor) {
    console.log(valor);
    if (this.notlistenkey === true && valor !== 'verInfoArtPed' 
    && valor !== 'verInfoArtVeh' && valor !== 'homologaitem') {
      return;
    }
    this.selected.emit(valor);
  }

  onshowbusq() {
    this.busq.prueba();
  }

  onshowbusqF() {
    this.busqF.prueba();
  }

  seleccionrdb(event) {
    this.tipodetalle = event.target.nextSibling.data.replace(' ', '');
    this.rdbtipodet.emit(this.tipodetalle);
  }

  clicked(event) {
    console.log(event);
    console.log(event.target.attributes[3].value);
  }

  seleccionbusquedaarticulosalida(opcion) {
    this.salidaService.getCostoPromedioArt(opcion.ART_CODIGO).subscribe((res) => {
      const costoP = String(res[0].COSTOPROMEDIO);
      const costoArt = costoP.replace(',', '.');
      this.salidaService.detkardexsarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETKAR_COSTOUNIT = Number(costoArt).toFixed(6);
    });
    this.salidaService.detkardexsarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_CODIGO = opcion.ART_CODIGO;
    this.salidaService.detkardexsarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_NOMBRE = opcion.ART_NOMBRE;
    this.salidaService.detkardexsarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CANTIDAD_DISPONIBLE = opcion.EXISTENCIA;
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.salidaService.detkardexsarray[this.init.tabs[this.init.tabindex].indice]);
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETKAR_CANTIDAD');
    this.salidaService.calcularTotal();
  }

  async seleccionbusquedaarticulodetfac(opcion) {

    this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_CODIGO = opcion.ART_CODIGO;
    this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCRIPCION = opcion.ART_NOMBRE;
    this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TIPOSRI = opcion.ART_FORMSRIVTAS === null ? '' : opcion.ART_FORMSRIVTAS;


    const lista = await this.utilitariosService.getListaPrecioArtProm(opcion.ART_CODIGO);
    const LISTPREC = this.encfacService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'LISTPREC').CFG_VALOR1;
    if (LISTPREC === 1) {
      let opciones = [];
      opciones.push(this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_LISTA);
      lista.map((codigo) => {
        if (codigo.ARTPRE_CODIGO !== opciones[0]) {
          opciones.push(codigo.ARTPRE_CODIGO);
        }
      });
      this.columnDefs[11].cellEditorParams = {
        values: opciones
      };
      this.refreshColumnDefs();

    }

    const datas = await this.encfacService.consultarArticuloSelProm(opcion.ART_CODIGO);

    for (const data of datas) {
      //console.log("----->",data)
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].UNIDAD_VENTA = data.ART_UNIDADVENTA;
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_MULTIUNIDAD = data.ART_MULTIUNIDAD === null ? '' : data.ART_MULTIUNIDAD;
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_COMPUESTO = data.ART_COMPUESTO === null ? '' : data.ART_COMPUESTO;
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_UNIDAD = data.ART_UNIDADVENTA === null ? '' : data.ART_UNIDADVENTA;
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBASENOOBJIVA = data.ART_BASENOOBJIVA === null ? '' : data.ART_BASENOOBJIVA;
      //GSRF
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].EXISTENCIA = opcion.EXISTENCIA === null || opcion.EXISTENCIA === undefined ? 0 : opcion.EXISTENCIA;
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_IRBPNR = data.ART_IRBPNR=== null || data.ART_IRBPNR === undefined ? 0 : data.ART_IRBPNR;
      //GSRF
      const intFCEXSNEG = this.encfacService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FCEXSNEG').CFG_VALOR1;
      if (intFCEXSNEG == 0) {
        const dblExistArt = opcion.EXISTENCIA;
        if (dblExistArt <= 0) {
          if (this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TIPODET !== 'Y') // si el
          // artículo
          // no es
          // componente
          {
            this.messageService.add({
              key: 'encfac',
              severity: 'error',
              summary: 'Información',
              detail: 'No puede facturar un artículo con existencias negativas'
            });

            // limpia la línea actual
            this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_CODIGO = '';
            this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCRIPCION = '';
            this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVA = '';
            this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVAAUX = '';
            this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBICE = '';
            this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_UNIDAD = '';
            this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].UNIDAD_VENTA = '';
            this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_PRECIO = 0;
            this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TOTAL = 0;
            this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBASENOOBJIVA = '';
            this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_MULTIUNIDAD = '';
            this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_COMPUESTO = '';
            /*crearNuevoItem(itemDet); // limpia la línea
            // actual
            strLista = new String[0];
            cmpFact.toolBarDetalle.getItem(0).setEnabled(false);
            dlgFact.mnuNuevaLinea.setEnabled(false);*/
            this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_CANTIDAD = 0;
            return;
          } else {
            this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_CANTIDAD = 0;
          }
        } else {
          this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_CANTIDAD = 0;
        }

      } else if (this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TIPODET !== 'Y') {
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_CANTIDAD = 0;
      }

      if (this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_IVA !== '0') {// no esta exento de iva si tributa iva
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVA = data.ART_TRIBUTAIVA === null ? '' : data.ART_TRIBUTAIVA;
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVAAUX = data.ART_TRIBUTAIVA;

        if(opcion.TARIIVA_CODIGO !== null && opcion.TARIIVA_CODIGO !== undefined && opcion.TARIIVA_CODIGO !==''){
          console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO))
            const porceiva= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_PORCENTAJE
            const tariivacod= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_CODIGO
            if(porceiva !== null && porceiva !== undefined && porceiva !== ''){
              this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_PORIVA=Number(porceiva).toFixed(this.confIniciales.getNumDecSist())
            }if(tariivacod !== null && tariivacod !== undefined && tariivacod !== ''){
              this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=tariivacod
            }
          }else{
            this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_PORIVA='';//CDPJ
            this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';//CDPJ
            }
      } else {
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVA = 'N';
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVAAUX = 'N';
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_PORIVA='';//CDPJ
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';//CDPJ
      }

      

      const intNOICEFVTA = this.encfacService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NOICEFVTA').CFG_VALOR1;

      // indicar si tributa o no el ice de acuerdo a la configuracion
      if (intNOICEFVTA == 0) { // considerar ice del artículo	
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBICE = data.ART_TRIBUTAICE === null ? '' : data.ART_TRIBUTAICE;
      } else {
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBICE = 'N';
      }

      if (this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBICE === 'S') {
        if (data.PORICE > 0) {
          const dblPorIce = Number(Number(data.PORICE).toFixed(2));
          this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_PORCEICE = dblPorIce;

        } else if (data.ART_VALORICE > 0) {
          let dblValorIce = Number(Number(data.ART_VALORICE).toFixed(2));
          this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_ICE = dblValorIce;
        }
      }

      // colocar el precio del artículo según lista y configuracion
      console.log(opcion.PRECIO);
      let dblPrecio = opcion.PRECIO;

      // configuración que indica si el precio en los artículos está
      // incluído el iva
      const intFCPRINIVA = this.encfacService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'FCPRINIVA').CFG_VALOR1;

      if ((intFCPRINIVA == 1 && this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVA === 'S')
        || (intFCPRINIVA === 1 && this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVA === 'N' &&
          this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_IVA === '0')) {
        //dblPrecio = opcion.PRECIO / (1 + this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_PORCEIVA / 100);
        dblPrecio = opcion.PRECIO / (1 + Number(isNaN(Number(opcion.ART_PORIVA))?0:opcion.ART_PORIVA) / 100);
      }

      if (this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVA === 'S') {
        if (this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_IVA === '2'
          && intFCPRINIVA === 0) {
          //dblPrecio = dblPrecio / (1 + this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].ENCFAC_PORCEIVA / 100);
          dblPrecio = dblPrecio / (1 + Number(isNaN(Number(opcion.ART_PORIVA))?0:opcion.ART_PORIVA) / 100);
        }
      }

      dblPrecio = Number(Number(dblPrecio).toFixed(this.encfacService.decimalesPrecioFac));
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_CADUCA = data.CADUCA === null ? '' : data.CADUCA;

      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_PRECIO = dblPrecio;
      this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCUENTO = 0;

      try {
        const dato = await this.encfacService.erArtPreDescuento(this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_CODIGO,
          this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_LISTA);
          //console.log('1.0',dato['ARTPRE_DESCUENTO']);
        if (dato !== null) {
          ///console.log('1',dato[0].ARTPRE_DESCUENTO);
          if (dato.length > 0) {
            //console.log('2',dato[0]);
            if (dato[0].ARTPRE_CODIGO !== null) {
              this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCUENTO = Number(dato[0].ARTPRE_DESCUENTO);
              console.log('descuento',this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCUENTO);
            } else {
              this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCUENTO = 0;
            }
          } else {
            this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCUENTO = 0;
          }

        } else {
          this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCUENTO = 0;
        }
      } catch (err) {

      }
      /// configuracion descuento por linea segun cliente 
      // GSRF
      const DESCLINAUT = this.encfacService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'DESCLINAUT').CFG_VALOR1;
      if(DESCLINAUT === 1){
        console.log('ENTRA CONF * LINEA',this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO);
        const desc = await this.encfacService.descxlineacli(this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO);
        console.log('DESCUENTO LINEA',desc[0].CLI_DESCUENTO);
        this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCUENTO = desc[0].CLI_DESCUENTO;
        
      }
      // GSRF

      if (data.ART_COMPUESTO === 'S') {
        // this.encfacService.desplegarComponentes(this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad], this.indexunidad);
        const item = this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad];
        const ddat = await this.encfacService.consultarComponentes(item.DETFAC_CODIGO, item
          .BOD_CODIGO, item.DETFAC_LISTA);
        for (let rs of ddat) {
          const detfac: VenDetfacZ = this.encfacService.crearNuevoItemServ();
          detfac.DETFAC_CODIGO = rs.ART_CODIGOP;
          detfac.DETFAC_UNIDAD = rs.UNI_CODIGO;
          detfac.DETFAC_DESCRIPCION = rs.ART_NOMBRE;
          detfac.DETFAC_CANTIDAD = rs.ARTPARTE_CANTIDAD;
          detfac.DETFAC_TIPODET = 'Y';
          detfac.BOD_CODIGO = item.BOD_CODIGO;
          detfac.DETFAC_PROMOCION = '0';
          detfac.ENCFAC_NUMERO = '';
          detfac.DETFAC_IRBPNR = 0;//GSRF
          detfac.DETFAC_INCLUENDSCTO = 'N'//CDPJ
          if (rs.PRECIO !== undefined) {
            detfac.DETFAC_PRECIOC = rs.PRECIO;
            detfac.DETFAC_PRECIO = rs.PRECIO;
          } else {
            detfac.DETFAC_PRECIOC = 0;
            detfac.DETFAC_PRECIO = 0;
          }
          detfac.DETFAC_DESCUENTO = 0;
          console.log(detfac);
          this.encfacService.consultarArticuloSel(detfac.DETFAC_CODIGO).subscribe(datas => {
            for (const data of datas) {
              if (detfac.ENCFAC_IVA !== 0) {
                detfac.DETFAC_TRIBIVA = data.ART_TRIBUTAIVA;
                detfac.DETFAC_TRIBIVAAUX = data.ART_TRIBUTAIVA;
                //CDPJ
                if(data.TARIIVA_CODIGO !== null && data.TARIIVA_CODIGO !== undefined && data.TARIIVA_CODIGO !==''){
                  console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === data.TARIIVA_CODIGO))
                    const porceiva= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === data.TARIIVA_CODIGO).TARIIVA_PORCENTAJE
                    const tariivacod= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === data.TARIIVA_CODIGO).TARIIVA_CODIGO
                    if(porceiva !== null && porceiva !== undefined && porceiva !== ''){
                      detfac.DETFAC_PORIVA=Number(porceiva).toFixed(this.confIniciales.getNumDecSist())
                    }if(tariivacod !== null && tariivacod !== undefined && tariivacod !== ''){
                      detfac.TARIIVA_CODIGO=tariivacod
                    }
                  }else{
                    detfac.DETFAC_PORIVA='';//CDPJ
                    detfac.TARIIVA_CODIGO='';//CDPJ
                    }
                //CDPJ
              } else {
                detfac.DETFAC_TRIBIVA = 'N';
                detfac.DETFAC_TRIBIVAAUX = 'N';
                detfac.DETFAC_PORIVA='';
                detfac.TARIIVA_CODIGO='';
              }
              detfac.DETFAC_TRIBICE = data.ART_TRIBUTAICE;
              detfac.DETFAC_UNIDAD = data.ART_UNIDADVENTA;
              detfac.UNIDAD_VENTA = data.ART_UNIDADVENTA;
              detfac.DETFAC_TRIBASENOOBJIVA = data.ART_BASENOOBJIVA;
              detfac.ART_MULTIUNIDAD = data.ART_MULTIUNIDAD;
            }

            this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice].push(detfac);
            this.encfacService.calcularTotales();
            this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice]);
            this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFAC_CANTIDAD');
          });
        }

      }
      this.encfacService.calcularTotales();
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFAC_CANTIDAD');

    }

  }

  async seleccionbusquedaarticulodetfacpv(opcion) {

    this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_CODIGO = opcion.ART_CODIGO;
    this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCRIPCION = opcion.ART_NOMBRE;
    this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TIPOSRI = opcion.ART_FORMSRIVTAS === null ? '' : opcion.ART_FORMSRIVTAS;
    
    const lista = await this.utilitariosService.getListaPrecioArtProm(opcion.ART_CODIGO);
    const LISTPREC = this.encptovtaService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'LISTPREC').CFG_VALOR1;
    if (LISTPREC === 1) {
      let opciones = [];
      opciones.push(this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_LISTA);
      lista.map((codigo) => {
        if (codigo.ARTPRE_CODIGO !== opciones[0]) {
          opciones.push(codigo.ARTPRE_CODIGO);
        }
      });
      this.columnDefs[8].cellEditorParams = {
        values: opciones
      };
      this.refreshColumnDefs();
    }

    const datas = await this.encptovtaService.consultarArticuloSelProm(opcion.ART_CODIGO);

    for (const data of datas) {
      this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].UNIDAD_VENTA = data.ART_UNIDADVENTA;
      this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_MULTIUNIDAD = data.ART_MULTIUNIDAD === null ? '' : data.ART_MULTIUNIDAD;
      this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_COMPUESTO = data.ART_COMPUESTO === null ? '' : data.ART_COMPUESTO;
      this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_UNIDAD = data.ART_UNIDADVENTA === null ? '' : data.ART_UNIDADVENTA;
      this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBASENOOBJIVA = data.ART_BASENOOBJIVA === null ? '' : data.ART_BASENOOBJIVA;

      const intFCEXSNEG = this.encptovtaService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PVFACEXNEG').CFG_VALOR1;
      if (intFCEXSNEG == 0) {
        const dblExistArt = opcion.EXISTENCIA;
        if (dblExistArt <= 0) {
          if (this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TIPODET !== 'Y') // si el
          // artículo no es componente
          {
            this.messageService.add({
              key: 'encptovta',
              severity: 'error',
              summary: 'Información',
              detail: 'No puede facturar un artículo con existencias negativas'
            });

            // limpia la línea actual
            this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_CODIGO = '';
            this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCRIPCION = '';
            this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVA = '';
            this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVAAUX = '';
            this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBICE = '';
            this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_UNIDAD = '';
            this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].UNIDAD_VENTA = '';
            this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_PRECIO = 0;
            this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TOTAL = 0;
            this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBASENOOBJIVA = '';
            this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_MULTIUNIDAD = '';
            this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_COMPUESTO = '';
            this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_CANTIDAD = 0;

            this.encptovtaService.calcularTotalesFact();
            this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice]);
            this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFAC_CANTIDAD');

            return;
          } else {
            this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_CANTIDAD = 0;
          }
        } else {
          this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].EXISTENCIA=opcion.EXISTENCIA//CDPJ
          this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_CANTIDAD = 1;
        }

      } else if (this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TIPODET !== 'Y') {
        this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_CANTIDAD = 1;
      }

      if (this.encptovtaService.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_IVA !== '0') {// no esta exento de iva si tributa iva
        this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVA = data.ART_TRIBUTAIVA === null ? '' : data.ART_TRIBUTAIVA;
        this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVAAUX = data.ART_TRIBUTAIVA;
        if(data.TARIIVA_CODIGO !== null && data.TARIIVA_CODIGO !== undefined && data.TARIIVA_CODIGO !==''){
          console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === data.TARIIVA_CODIGO))
            const porceiva= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === data.TARIIVA_CODIGO).TARIIVA_PORCENTAJE
            const tariivacod= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === data.TARIIVA_CODIGO).TARIIVA_CODIGO
            if(porceiva !== null && porceiva !== undefined && porceiva !== ''){
              this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_PORIVA=Number(porceiva).toFixed(this.confIniciales.getNumDecSist())
            }if(tariivacod !== null && tariivacod !== undefined && tariivacod !== ''){
              this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=tariivacod
            }
          }else{
            this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_PORIVA='';//CDPJ
            this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';//CDPJ
            }
      } else {
        this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVA = 'N';
        this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVAAUX = 'N';
        this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_PORIVA='';//CDPJ
        this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';//CDPJ
      }

      //GSRF  
      console.log('--->',this.encptovtaService.encfac[this.init.tabs[this.init.tabindex].indice].tipopv)
      if (this.encptovtaService.encfac[this.init.tabs[this.init.tabindex].indice].tipopv === 'NV') {// es nota de venta
        this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVA = 'N';
        this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVAAUX = 'N';
        this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_PORIVA='';//CDPJ
        this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';//CDPJ
      }else {
        this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVA = data.ART_TRIBUTAIVA === null ? '' : data.ART_TRIBUTAIVA;
        this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVAAUX = data.ART_TRIBUTAIVA;
      }
      //GSRF
      const intNOICEFVTA = this.encptovtaService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'NOICEFVTA').CFG_VALOR1;

      // indicar si tributa o no el ice de acuerdo a la configuracion
      if (intNOICEFVTA == 0) { // considerar ice del artículo	
        this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBICE = data.ART_TRIBUTAICE === null ? '' : data.ART_TRIBUTAICE;
      } else {
        this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBICE = 'N';
      }

      if (this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBICE === 'S') {
        if (data.PORICE > 0) {
          const dblPorIce = Number(Number(data.PORICE).toFixed(2));
          this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_PORCEICE = dblPorIce;

        } else if (data.ART_VALORICE > 0) {
          let dblValorIce = Number(Number(data.ART_VALORICE).toFixed(2));
          this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_ICE = dblValorIce;
        }
      }

      // colocar el precio del artículo según lista y configuracion      
      let dblPrecio = opcion.PRECIO;

      // configuración que indica si el precio en los artículos está  incluído el iva
      const intFCPRINIVA = this.encptovtaService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PVPREAIVA').CFG_VALOR1;

      if ((intFCPRINIVA == 1 && this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVA === 'S')
        || (intFCPRINIVA === 1 && this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVA === 'N' &&
          this.encptovtaService.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_IVA === '0')) {
        dblPrecio = data.ART_PRECIO / (1 + Number(isNaN(Number(data.ART_PORIVA))?0:data.ART_PORIVA) / 100);
      }

      if (this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_TRIBIVA === 'S') {
        if (this.encptovtaService.encfac[this.init.tabs[this.init.tabindex].indice].ENCFAC_IVA === '2'
          && intFCPRINIVA === 0) {
          dblPrecio = dblPrecio / (1 + Number(isNaN(Number(data.ART_PORIVA))?0:data.ART_PORIVA) / 100);
        }
      }

      dblPrecio = Number(Number(dblPrecio).toFixed(this.encptovtaService.NUMDECPRECIO));
      this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_CADUCA = data.CADUCA === null ? '' : data.CADUCA;

      this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_PRECIO = dblPrecio;
      this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCUENTO = 0;

      // try {
      //   const dato = await this.encfacService.erArtPreDescuento(this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_CODIGO,
      //     this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_LISTA);
      //   if (dato !== null) {
      //     if (dato.length > 0) {
      //       if (dato[0].ARTPRE_CODIGO !== null) {
      //         this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCUENTO = Number(dato);
      //       } else {
      //         this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCUENTO = 0;
      //       }
      //     } else {
      //       this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCUENTO = 0;
      //     }

      //   } else {
      //     this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCUENTO = 0;
      //   }
      // } catch (err) {

      // }

      try {
        const dato = await this.encfacService.erArtPreDescuento(this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_CODIGO,
          this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_LISTA);
          //console.log('1.0',dato['ARTPRE_DESCUENTO']);
        if (dato !== null) {
          ///console.log('1',dato[0].ARTPRE_DESCUENTO);
          if (dato.length > 0) {
            //console.log('2',dato[0]);
            if (dato[0].ARTPRE_CODIGO !== null) {
              this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCUENTO = Number(dato[0].ARTPRE_DESCUENTO);
              console.log('descuento',this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCUENTO);
            } else {
              this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCUENTO = 0;
            }
          } else {
            this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCUENTO = 0;
          }

        } else {
          this.encfacService.detfacarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFAC_DESCUENTO = 0;
        }
      } catch (err) {

      }
      if (data.ART_COMPUESTO === 'S') {
        const item = this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice][this.indexunidad];
        const ddat = await this.encfacService.consultarComponentes(item.DETFAC_CODIGO, item.BOD_CODIGO, item.DETFAC_LISTA);
        for (let rs of ddat) {
          const detfac: VenDetfacZ = this.encptovtaService.crearNuevoItemServ();
          detfac.DETFAC_CODIGO = rs.ART_CODIGOP;
          detfac.DETFAC_UNIDAD = rs.UNI_CODIGO;
          detfac.DETFAC_DESCRIPCION = rs.ART_NOMBRE;
          detfac.DETFAC_CANTIDAD = rs.ARTPARTE_CANTIDAD;
          detfac.DETFAC_TIPODET = 'Y';
          detfac.BOD_CODIGO = item.BOD_CODIGO;
          detfac.DETFAC_PROMOCION = '0';
          detfac.ENCFAC_NUMERO = '';
          detfac.DETFAC_IRBPNR = 0;//GSRF
          detfac.DETFAC_INCLUENDSCTO = 'N'//CDPJ
          if (rs.PRECIO !== undefined) {
            detfac.DETFAC_PRECIOC = rs.PRECIO;
            detfac.DETFAC_PRECIO = rs.PRECIO;
          } else {
            detfac.DETFAC_PRECIOC = 0;
            detfac.DETFAC_PRECIO = 0;
          }
          detfac.DETFAC_DESCUENTO = 0;
          console.log(detfac);
          const datas = await this.encptovtaService.consultarArticuloSelProm(detfac.DETFAC_CODIGO);
          for (const data of datas) {
            if (detfac.ENCFAC_IVA !== 0) {
              detfac.DETFAC_TRIBIVA = data.ART_TRIBUTAIVA;
              detfac.DETFAC_TRIBIVAAUX = data.ART_TRIBUTAIVA;
              //CDPJ
              if(data.TARIIVA_CODIGO !== null && data.TARIIVA_CODIGO !== undefined && data.TARIIVA_CODIGO !==''){
                console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === data.TARIIVA_CODIGO))
                  const porceiva= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === data.TARIIVA_CODIGO).TARIIVA_PORCENTAJE
                  const tariivacod= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === data.TARIIVA_CODIGO).TARIIVA_CODIGO
                  if(porceiva !== null && porceiva !== undefined && porceiva !== ''){
                    detfac.DETFAC_PORIVA=Number(porceiva).toFixed(this.confIniciales.getNumDecSist())
                  }if(tariivacod !== null && tariivacod !== undefined && tariivacod !== ''){
                    detfac.TARIIVA_CODIGO=tariivacod
                  }
                }else{
                  detfac.DETFAC_PORIVA='';//CDPJ
                  detfac.TARIIVA_CODIGO='';//CDPJ
                  }
              //CDPJ
            } else {
              detfac.DETFAC_TRIBIVA = 'N';
              detfac.DETFAC_TRIBIVAAUX = 'N';
              detfac.DETFAC_PORIVA='';//CDPJ
              detfac.TARIIVA_CODIGO='';//CDPJ
            }
            detfac.DETFAC_TRIBICE = data.ART_TRIBUTAICE;
            detfac.DETFAC_UNIDAD = data.ART_UNIDADVENTA;
            detfac.UNIDAD_VENTA = data.ART_UNIDADVENTA;
            detfac.DETFAC_TRIBASENOOBJIVA = data.ART_BASENOOBJIVA;
            detfac.ART_MULTIUNIDAD = data.ART_MULTIUNIDAD;
          }

          this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice].push(detfac);
        }

        this.encptovtaService.calcularTotalesFact();
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFAC_CANTIDAD');

      } else {

        this.encptovtaService.calcularTotalesFact();
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encptovtaService.detfac[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFAC_CANTIDAD');
      }
    }

  }

  seleccionbusquedaarticulodetfacpro(opcion) {
    this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_CODIGO = opcion.ART_CODIGO;
    this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_DESCRIPCION = opcion.ART_NOMBRE;
    this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_CODIGOALT = opcion.ART_CODIGOALT3;
    this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_TIPOSRI = opcion.ART_FORMSRICOM === null ? '' : opcion.ART_FORMSRICOM;
    this.encfacproService.consultarArticuloSel(opcion.ART_CODIGO).subscribe(datas => {
      for (const data of datas) {
        // if (data.ART_TRIBUTAIVA === 'S') {
        //   this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_PORIVA = 12.00;
        // } else {
        //   this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_PORIVA = 0.00;
        // }
        //CDPJ
        if(data.TARIIVA_CODIGO !== null && data.TARIIVA_CODIGO !== undefined && data.TARIIVA_CODIGO !==''){
          console.log(this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO))
            const porceiva= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_PORCENTAJE
            const tariivacod= this.confIniciales.srifetariiva.find(element => element.TARIIVA_CODIGO === opcion.TARIIVA_CODIGO).TARIIVA_CODIGO
            if(porceiva !== null && porceiva !== undefined && porceiva !== ''){
              this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_PORIVA=Number(porceiva).toFixed(this.confIniciales.getNumDecSist())
            }if(tariivacod !== null && tariivacod !== undefined && tariivacod !== ''){
              this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=tariivacod
            }
         }else{
          this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_PORIVA='';
          this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';
          // if (this.encfacproService.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_IVA !== '0') {
          //   if(data.ART_TRIBUTAIVA === 'S'){
          //     this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_PORIVA=this.encfacproService.porceiva
          //     this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO=this.encfacproService.tariivacod
          //    }else{
          //     this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_PORIVA=0.00;
          //     this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='';
          //    }
          // }else{
          //   this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_PORIVA=0.00;
          //     this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TARIIVA_CODIGO='7';
          // }
           
        }
        //CDPJ
        this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_TRIBICE = data.ART_TRIBUTAICE;
        // this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_UNIDAD = data.ART_UNIDADVENTA;
        this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_UNIDAD = data.ART_UNIDADCOSTEO;
        this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].UNIDAD = data.ART_UNIDADCOSTEO;
        this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_COSTO = data.ULTIMOCOSTOCOMPRA;
        this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_TOTAL = data.ULTIMOCOSTOCOMPRA;
        this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETFACPRO_TRIBASENOOBJIVA = data.ART_BASENOOBJIVA;
        this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_MULTIUNIDAD = data.ART_MULTIUNIDAD;

        this.encfacproService.erTipGasCodigo1().subscribe(er => {
          if (er !== undefined) {
            if (er[0] !== null) {
              this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TIPGAS_CODIGO = er[0].TIPGAS_CODIGO;
              this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BOLTIPOGASTO = true;
            }
          }
        });
        this.encfacproService.calcularTotales();
      }
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacproService.detfacproarray[this.init.tabs[this.init.tabindex].indice]);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETFACPRO_CANTIDAD');
    });
  }

  seleccionbusquedaretencion(opcion) {
     //CDPJ
    //const item = this.encfacproService.detfacproarray[this.indicador];
    //console.log('antes', this.pagconservice.reten);
    //console.log('opcion', opcion.RETENCION_CODIGO);
    let coinsidencias = 0;
    for (const data of this.pagconservice.reten) {
     // console.log('array', data.RETENCION_CODIGO)
      if (opcion.RETENCION_CODIGO === data.RETENCION_CODIGO && data.TRNRETENCION_DESCRIPCION !== '') {
        //console.log('repetido', this.pagconservice.reten[this.indexunidad].RETENCION_CODIGO)
        coinsidencias++;
        this.messageService.add({
          key: 'pagcon',
          severity: 'warn',
          summary: 'Información',
          detail: 'El código contable no se puede repetir en el detalle.'
        });
        this.pagconservice.reten[this.indexunidad].RETENCION_CODIGO = '';
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.pagconservice.reten);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'RETENCION_CODIGO');
        //return;
      }
    }
    //CDPJ
    if (coinsidencias === 0) {
      this.pagconservice.reten[this.indexunidad].RETENCION_CODIGO = opcion.RETENCION_CODIGO;
      this.pagconservice.reten[this.indexunidad].TRNRETENCION_DESCRIPCION = opcion.RETENCION_NOMBRE;
      this.pagconservice.reten[this.indexunidad].TRNRETENCION_PORCENTAJE = opcion.RETENCION_PORCENTAJE.toFixed(this.confIniciales.getNumDecSist());
      this.pagconservice.reten[this.indexunidad].TRNRETENCION_ORIGEN = 'FAC';
      this.pagconservice.erCodigoVtaRetencion(this.pagconservice.reten[this.indexunidad].RETENCION_CODIGO).subscribe(d2 => {
        if (d2 !== undefined && d2 !== null) {
          this.pagconservice.reten[this.indexunidad].CON_CODIGO = d2[0].CON_CODIGO_VTA;
        }
      });
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.pagconservice.reten);
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNRETENCION_NRO');
      this.botonNuevo = false;
    }
    //CDPJ
    // this.pagconservice.reten[this.indexunidad].RETENCION_CODIGO = opcion.RETENCION_CODIGO;
    // this.pagconservice.reten[this.indexunidad].TRNRETENCION_DESCRIPCION = opcion.RETENCION_NOMBRE;
    // this.pagconservice.reten[this.indexunidad].TRNRETENCION_PORCENTAJE = opcion.RETENCION_PORCENTAJE.toFixed(this.confIniciales.getNumDecSist());
    // this.pagconservice.reten[this.indexunidad].TRNRETENCION_ORIGEN = 'FAC';
    // this.pagconservice.erCodigoVtaRetencion(this.pagconservice.reten[this.indexunidad].RETENCION_CODIGO).subscribe(d2 => {
    //   if (d2 !== undefined && d2 !== null) {
    //     this.pagconservice.reten[this.indexunidad].CON_CODIGO = d2[0].CON_CODIGO_VTA;
    //   }
    // });
    // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.pagconservice.reten);
    // this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNRETENCION_NRO');
    // this.botonNuevo = false;
  }

  selecciontrnretencion(opcion) {
    //CDPJ
    //const item = this.encfacproService.detfacproarray[this.indicador];
    //console.log('antes',this.encfacproService.trnretencionarray);
    //console.log('opcion',opcion.RETENCION_CODIGO);
    let coinsidencias = 0;
    for (const data of this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice]) {
      //console.log('array',data.RETENCION_CODIGO)
      if (opcion.RETENCION_CODIGO === data.RETENCION_CODIGO && data.TRNRETENCION_DESCRIPCION !== '') {
        // console.log('repetido',this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO)
        coinsidencias++;
        this.messageService.add({
          key: 'encfacpro',
          severity: 'warn',
          summary: 'Información',
          detail: 'El código contable no se puede repetir en el detalle.'
        });
        this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO = ''
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'RETENCION_CODIGO');
        return;
      }
    }
    //CDPJ
    if (coinsidencias === 0) {
      this.encfacproService.erConCodigo1(opcion.RETENCION_CODIGO).subscribe(er => {
        if (er === null) {
          this.messageService.add({
            key: 'encfacpro',
            severity: 'error',
            summary: 'Información',
            detail: 'Código contable de la retención no existe.'
          });
          this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO = '';
          this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_DESCRIPCION = '';
        } else {
          if (er[0] === undefined) {
            this.messageService.add({
              key: 'encfacpro',
              severity: 'error',
              summary: 'Información',
              detail: 'Código contable de la retención no existe.'
            });
            this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO = '';
            this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_DESCRIPCION = '';
          } else {
            if (er[0].CON_CODIGO === '' || er[0].CON_CODIGO === null) {
              this.messageService.add({
                key: 'encfacpro',
                severity: 'error',
                summary: 'Información',
                detail: 'Código contable de la retención no existe.'
              });
              this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO = '';
              this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_DESCRIPCION = '';
            } else {
              this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO = opcion.RETENCION_CODIGO;
              this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_DESCRIPCION = opcion.RETENCION_NOMBRE;
              if (opcion.RETENCION_PORCENTAJE !== '' && opcion.RETENCION_PORCENTAJE !== undefined && opcion.RETENCION_PORCENTAJE !== null) {
                this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_PORCENTAJE = opcion.RETENCION_PORCENTAJE.toFixed(this.confIniciales.getNumDecSist());
              } else {
                const cero = 0;
                this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_PORCENTAJE = cero.toFixed(this.confIniciales.getNumDecSist());
              }
              this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = er[0].CON_CODIGO;
              this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_TIPRET = opcion.RETENCION_TIPRET;

              if (opcion.RETENCION_CODIGO === '345' || opcion.RETENCION_CODIGO === '346') {
                this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_FECHAPAGODIV = this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_FECHAEMISION;
              }

              const intRETBASAUT = this.encfacproService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'RETBASAUT').CFG_VALOR1;

              if (intRETBASAUT == 1) {
                if (opcion.RETENCION_TIPRET === 'RF') {
                  this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_BASEG = this.init.quitarComas(this.encfacproService.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASEIVA);
                  this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_BASE0 = this.init.quitarComas(this.encfacproService.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASECERO);
                  this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_BASE = 0;
                } else {
                  this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_BASEG = 0;
                  this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_BASE0 = 0;
                  this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_BASE =this.init.quitarComas(this.encfacproService.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_VALORIVA); 
                }
              }

              this.encfacproService.calcularTotalesRetencion();
              this.encfacproService.calcularTotalConRetenciones();
              this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice]);
              this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNRETENCION_BASEG');
              this.botonNuevo = false;

              this.utilitariosService.configRegMic().subscribe(data => {
                let glbintregimenMicroempresas = '';
                let glbintagenteret = '';
                for (const rs of data) {
                  glbintregimenMicroempresas = rs.COM_CONREGMIC;
                  glbintagenteret = rs.COM_AGENTERET;
                }

                if (glbintregimenMicroempresas === '1' && glbintagenteret === '0') {
                  if (this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO !== '332'
                    && this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO !== '346'
                    && this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO !== '') {
                    this.messageService.add({
                      key: 'encfacpro',
                      severity: 'error',
                      summary: 'Información',
                      detail: 'La empresa aplica al régimen para microempresas, verifique el código de la retención'
                    });
                  }
                }
              });
            }
          }
        }
      });
    }
    //CDPJ
    // this.encfacproService.erConCodigo1(opcion.RETENCION_CODIGO).subscribe(er => {
    //   if (er === null) {
    //     this.messageService.add({
    //       key: 'encfacpro',
    //       severity: 'error',
    //       summary: 'Información',
    //       detail: 'Código contable de la retención no existe.'
    //     });
    //     this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO = '';
    //     this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_DESCRIPCION = '';
    //   } else {
    //     if (er[0] === undefined) {
    //       this.messageService.add({
    //         key: 'encfacpro',
    //         severity: 'error',
    //         summary: 'Información',
    //         detail: 'Código contable de la retención no existe.'
    //       });
    //       this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO = '';
    //       this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_DESCRIPCION = '';
    //     } else {
    //       if (er[0].CON_CODIGO === '' || er[0].CON_CODIGO === null) {
    //         this.messageService.add({
    //           key: 'encfacpro',
    //           severity: 'error',
    //           summary: 'Información',
    //           detail: 'Código contable de la retención no existe.'
    //         });
    //         this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO = '';
    //         this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_DESCRIPCION = '';
    //       } else {
    //         this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO = opcion.RETENCION_CODIGO;
    //         this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_DESCRIPCION = opcion.RETENCION_NOMBRE;
    //         if (opcion.RETENCION_PORCENTAJE !== '' && opcion.RETENCION_PORCENTAJE !== undefined && opcion.RETENCION_PORCENTAJE !== null) {
    //           this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_PORCENTAJE = opcion.RETENCION_PORCENTAJE.toFixed(this.confIniciales.getNumDecSist());
    //         } else {
    //           const cero = 0;
    //           this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_PORCENTAJE = cero.toFixed(this.confIniciales.getNumDecSist());
    //         }
    //         this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].CON_CODIGO = er[0].CON_CODIGO;
    //         this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_TIPRET = opcion.RETENCION_TIPRET;

    //         if (opcion.RETENCION_CODIGO === '345' || opcion.RETENCION_CODIGO === '346') {
    //           this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_FECHAPAGODIV = this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_FECHAEMISION;
    //         }

    //         const intRETBASAUT = this.encfacproService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'RETBASAUT').CFG_VALOR1;

    //         if (intRETBASAUT == 1) {
    //           if (opcion.RETENCION_TIPRET === 'RF') {
    //             this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_BASEG = this.init.quitarComas(this.encfacproService.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASEIVA);
    //             this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_BASE0 = this.init.quitarComas(this.encfacproService.encfacproarray[this.init.tabs[this.init.tabindex].indice].ENCFACPRO_BASECERO);
    //             this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_BASE = 0;
    //           } else {
    //             this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_BASEG = 0;
    //             this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_BASE0 = 0;
    //             this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNRETENCION_BASE = 0;
    //           }
    //         }

    //         this.encfacproService.calcularTotalesRetencion();
    //         this.encfacproService.calcularTotalConRetenciones();
    //         this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice]);
    //         this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNRETENCION_BASEG');
    //         this.botonNuevo = false;

    //         this.utilitariosService.configRegMic().subscribe(data => {
    //           let glbintregimenMicroempresas = '';
    //           let glbintagenteret = '';
    //           for (const rs of data) {
    //             glbintregimenMicroempresas = rs.COM_CONREGMIC;
    //             glbintagenteret = rs.COM_AGENTERET;
    //           }

    //           if (glbintregimenMicroempresas === '1' && glbintagenteret === '0') {
    //             if (this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO !== '332'
    //               && this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO !== '346'
    //               && this.encfacproService.trnretencionarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].RETENCION_CODIGO !== '') {
    //               this.messageService.add({
    //                 key: 'encfacpro',
    //                 severity: 'error',
    //                 summary: 'Información',
    //                 detail: 'La empresa aplica al régimen para microempresas, verifique el código de la retención'
    //               });
    //             }
    //           }
    //         });
    //       }
    //     }
    //   }
    // });
  }

  async seleccionbusquedatipopago(opcion) {
    this.pagconservice.pagos[this.indexunidad].TRNPAGO_TIPO = opcion.FORMAPAGO_TIPO;
    //this.pagconservice.pagos[this.indexunidad].CAJ_CODIGO = opcion.CAJ_CODIGO;
    this.pagconservice.pagos[this.indexunidad].TRNPAGO_NUMERO = '';

    if (opcion.FORMAPAGO_TIPO === 'EF') {
      const strNumFila = this.indexunidad + 1;
      const strFechaActual = this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.pagconservice.pagos[this.indexunidad].TRNPAGO_NUMERO = strNumFila + '' + strFechaActual;
      this.pagconservice.pagos[this.indexunidad].TRNPAGO_CODSRI = '01';
    } else {
      this.pagconservice.erFormaPagoSri(opcion.FORMAPAGO_TIPO).subscribe(er => {
        if (er !== null) {
          if (er[0] !== undefined) {
            this.pagconservice.pagos[this.indexunidad].TRNPAGO_CODSRI = er[0].FORMAPAGO_CODIGOSRI;
          }
        }
      });
    }

    if (opcion.FORMAPAGO_TIPO !== 'DP') {
     // if (this.pagconservice.CAJXVEN === 0) {

        // this.pagconservice.erCodigoFormaP(opcion.FORMAPAGO_TIPO).subscribe(data => {
        //   if (data !== null) {
        //     if (data[0] !== undefined) {
        //       this.pagconservice.pagos[this.indexunidad].CAJ_CODIGO = data[0].CAJ_CODIGO;
        //       this.pagconservice.erCajaCodigoBan(this.pagconservice.pagos[this.indexunidad].CAJ_CODIGO).subscribe(d2 => {
        //         if (d2 !== undefined && d2 !== null) {
        //           this.pagconservice.pagos[this.indexunidad].CON_CODIGO = d2[0].CON_CODIGO;
        //         }
        //       });
        //     }
        //   }
        // }
        // );
        const data = await this.pagconservice.erCodigoFormaPromise(opcion.FORMAPAGO_TIPO);
        if(data !== null && data[0] !== undefined){
          this.pagconservice.pagos[this.indexunidad].CAJ_CODIGO= data[0].CAJ_CODIGO === null || data[0].CAJ_CODIGO === undefined?'':data[0].CAJ_CODIGO
        } else{
          this.pagconservice.pagos[this.indexunidad].CAJ_CODIGO='';
        }
        
         
          console.log(data);
          let ven_codigo='';
          const intAsignarCajaVend=this.pagconservice.hmpConfiguracion.find(element => element.CFG_CODIGO === 'CAJXVEN').CFG_VALOR1;
          const pvcajavende=this.pagconservice.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PVCAJADEPV').CFG_VALOR1;
          //console.log('ven_codigo',this.encptovtaService.encfac[this.init.tabs[this.init.tabindex].indice].VEN_CODIGO);
          if(this.pagconservice.modulo === 'ventas'){
            ven_codigo=this.encfacService.encfacarray[this.init.tabs[this.init.tabindex].indice].VEN_CODIGO
          }
          if(this.pagconservice.modulo === 'ptovta'){
            ven_codigo=this.pagconservice.ven_codigo;
          }
          const data2= await this.pagconservice.erCodigoVendedorPromise(ven_codigo);
          console.log(data2);
          console.log('1.1');
          //this.pagconservice.erCodigoVendedor(ven_codigo).subscribe(er2 => {
            //console.log('1',er2);
            if (data2 !== null) {
              if (data2[0] !== undefined) {
                if (intAsignarCajaVend === 1 && this.pagconservice.modulo === 'ventas') {
                  console.log('2',data2[0].CAJ_CODIGO);
                  //strCaja = er2[0].CAJ_CODIGO === null?'':er2[0].CAJ_CODIGO;//CDPJ
                  //console.log('b',strCaja);
                  this.pagconservice.pagos[this.indexunidad].CAJ_CODIGO=data2[0].CAJ_CODIGO === null?'':data2[0].CAJ_CODIGO;
                }//CDPJ
                if(pvcajavende === 1 && this.pagconservice.modulo === 'ptovta'){
                  console.log('3',data2[0].CAJ_CODIGO);
                  //console.log(this.modulo)
                  //strCaja = er2[0].CAJ_CODIGO === null?'':er2[0].CAJ_CODIGO;
                  //console.log('c',strCaja);
                  this.pagconservice.pagos[this.indexunidad].CAJ_CODIGO=data2[0].CAJ_CODIGO === null?'':data2[0].CAJ_CODIGO;
                }//CDPJ
              }else{
                this.pagconservice.pagos[this.indexunidad].CAJ_CODIGO='';
              }
            }else{
              this.pagconservice.pagos[this.indexunidad].CAJ_CODIGO='';
            }
            //this.pagconservice.pagos[this.indexunidad].CAJ_CODIGO = strCaja
            // this.pagconservice.erCajaCodigoBan(this.pagconservice.pagos[this.indexunidad].CAJ_CODIGO).subscribe(d2 => {
            //   if (d2 !== undefined && d2 !== null) {
            //     this.pagconservice.pagos[this.indexunidad].CON_CODIGO = d2[0].CON_CODIGO;
            //   }
            // });
            const d2 = await this.pagconservice.erCajaCodigoBanPromise(this.pagconservice.pagos[this.indexunidad].CAJ_CODIGO);
            if (d2 !== undefined && d2 !== null) {
              this.pagconservice.pagos[this.indexunidad].CON_CODIGO = d2[0].CON_CODIGO;
            }
          //});
        

      //} else {
        //CDPJ---
        // this.pagconservice.erCajaCodigoVen().subscribe(data => {
        //   if (data !== undefined && data !== null) {
        //     this.pagconservice.pagos[this.indexunidad].CAJ_CODIGO = data[0].CAJ_CODIGO;
        //     this.pagconservice.erCajaCodigoBan(this.pagconservice.pagos[this.indexunidad].CAJ_CODIGO).subscribe(d2 => {
        //       if (d2 !== undefined && d2 !== null) {
        //         this.pagconservice.pagos[this.indexunidad].CON_CODIGO = d2[0].CON_CODIGO;
        //       }
        //     });
        //   }
        // });
       
      //}
    } else {
      this.pagconservice.pagos[this.indexunidad].CAJ_CODIGO = '';
      this.pagconservice.pagos[this.indexunidad].CON_CODIGO = '';
    }

    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.pagconservice.pagos);
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'TRNPAGO_NUMERO');
    this.botonNuevo = false;
  }

  msgValidacionDescVenPed(mensaje) {
    this.messageService.add({
      key: 'pedido',
      severity: 'error',
      summary: 'Información',
      detail: mensaje
    });
  }

  msgValidacionDescDETGRE(mensaje) {
    this.messageService.add({
      key: 'guiaRemision',
      severity: 'error',
      summary: 'Información',
      detail: mensaje
    });
  }

  seleccionarticulotransfer(opcion) {
    this.enctransferService.invdettransferarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_CODIGO = opcion.ART_CODIGO;
    this.enctransferService.invdettransferarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_NOMBRE = opcion.ART_NOMBRE;
    this.enctransferService.invdettransferarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_SERIALFLAG = opcion.ART_SERIALFLAG;//CDPJ

    // extrae la unidad de costeo
    this.enctransferService.erFindTransferNro(opcion.ART_CODIGO).subscribe(er => {
      if (er !== null) {
        if (er[0] !== undefined) {
          this.enctransferService.invdettransferarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].UNI_CODIGO = er[0].ART_UNIDADCOSTEO;
        }
      }

      // extrae la existencia
      const dblExistencia = Number(opcion.EXISTENCIA);
      if (dblExistencia <= 0) {
        this.messageService.add({
          key: 'enctransfer',
          severity: 'error',
          summary: 'Información',
          detail: 'Existencia Negativa'
        });
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.enctransferService.invdettransferarray[this.init.tabs[this.init.tabindex].indice]);
        this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETTRANSFER_CANTIDAD');
        this.enctransferService.calcularCostoTotal();
      } else {
        this.enctransferService.invdettransferarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETTRANSFER_CANTIDAD = '1.00';
        const strArtCodigo = opcion.ART_CODIGO;
        const fecha = this.enctransferService.invenctransferarray[this.init.tabs[this.init.tabindex].indice].TRANSFER_FECHAEMISION;
        const strFecha = this.datePipe.transform(fecha, 'dd/MM/yyyy HH:mm:ss');
        let strHora1;
          if( this.enctransferService.horacam === this.enctransferService.invenctransferarray[this.init.tabs[this.init.tabindex].indice].TRANSFER_HORA){
            strHora1 = this.enctransferService.invenctransferarray[this.init.tabs[this.init.tabindex].indice].TRANSFER_HORA;
          //console.log('aggrid11111',strHora);
          }else{
              strHora1 = this.datePipe.transform(this.enctransferService.invenctransferarray[this.init.tabs[this.init.tabindex].indice].TRANSFER_HORA, 'HH:mm');
            //console.log('aggrid2222',strHora);
          }
        //const strHora = this.datePipe.transform(this.enctransferService.invenctransferarray[this.init.tabs[this.init.tabindex].indice].TRANSFER_HORA, 'HH:mm');

        let dblCostoPromedio = 0;

        // Extrae el costo
        let strSentencia = 'select INV_FNC_OBTENERCOSTOPROM(\'' + strArtCodigo + '\',';
        strSentencia = strSentencia + 'TO_DATE(\'' + strFecha + '\',\'DD/MM/YYYY HH24:MI:SS\'),\'';
        strSentencia = strSentencia + strHora1 + '\',\'01\') AS COSTO FROM DUAL ';

        this.enctransferService.ecGetCostoProm(strArtCodigo, strFecha, strHora1).subscribe(data => {
          for (let rs of data) {
            dblCostoPromedio = Number(rs.COSTO);
          }
          this.enctransferService.invdettransferarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETTRANSFER_COSTOUNIT = dblCostoPromedio.toFixed(this.enctransferService.decdetcosto);
          this.enctransferService.invdettransferarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETTRANSFER_COSTOTOTAL = dblCostoPromedio.toFixed(2);
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.enctransferService.invdettransferarray[this.init.tabs[this.init.tabindex].indice]);
          this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'DETTRANSFER_CANTIDAD');
          this.enctransferService.calcularCostoTotal();
        });
      }
    });
    //CDPJ
    const fecha = this.enctransferService.invenctransferarray[this.init.tabs[this.init.tabindex].indice].TRANSFER_FECHAEMISION;
    const strFecha = this.datePipe.transform(fecha, 'dd/MM/yyyy');
    let strHora;
    if( this.enctransferService.horacam === this.enctransferService.invenctransferarray[this.init.tabs[this.init.tabindex].indice].TRANSFER_HORA){
      strHora = this.enctransferService.invenctransferarray[this.init.tabs[this.init.tabindex].indice].TRANSFER_HORA;
     console.log('aggrid11111',strHora);
   }else{
      strHora = this.datePipe.transform(this.enctransferService.invenctransferarray[this.init.tabs[this.init.tabindex].indice].TRANSFER_HORA, 'HH:mm');
     console.log('aggrid2222',strHora);
   }
    this.enctransferService.obtenerExistenciaBodegaD('FAC',
      this.enctransferService.invdettransferarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_CODIGO,
      this.enctransferService.invenctransferarray[this.init.tabs[this.init.tabindex].indice].BOD_CODIGO,
      strFecha,
      strHora).subscribe(er => {
        for (let rs of er) {
          this.enctransferService.invdettransferarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].EXSBODORIGEN = Number(rs.EXIST).toFixed(3);
        }
      });

    this.enctransferService.obtenerExistenciaBodegaD('FAC',
      this.enctransferService.invdettransferarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_CODIGO,
      this.enctransferService.invenctransferarray[this.init.tabs[this.init.tabindex].indice].BOD_DESTINO,
      strFecha,
      strHora).subscribe(er => {
        for (let rs of er) {
          this.enctransferService.invdettransferarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].EXSBODDESTINO = Number(rs.EXIST).toFixed(3);
        }
      });
    //CDPJ

  }

  seleccionTipoDetDetFacProv() {
    this.displayDialogOpcionesDetProv = false;
    if (this.tipoDetFactXML === 'A') {
      this.busquedaarticulodetalle(this.detalleSelDetFacPro, 'busquedaarticulodetfacpro', '');
    }
    if (this.tipoDetFactXML === 'S') {
      // this.busquedaserviciodetalle(maestro[gridapi.getFocusedCell().rowIndex], 'busquedaserviciodetfacpro', maestro[gridapi.getFocusedCell().rowIndex].DETFACPRO_CODIGO);
      this.busquedaserviciopro(this.detalleSelDetFacPro, 'busquedaserviciodetfacpro', '');
    }
    if (this.tipoDetFactXML === 'C') {
      this.busquedaCON(this.detalleSelDetFacPro, 'busquedacuentadetfacpro', '');
    }
  }
  busquedaRubroCod(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'ingrRUBRO_CODIGO':
        this.indexunidad = this.nomempleadosService.nommaenomIngrFijo.findIndex(ingrfig => ingrfig.NUMEGRFIJ === rowData.NUMEGRFIJ);
        break;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'MAERUBRO_CODRUBR' },
      { label: 'Nombre', value: 'MAERUBRO_NOMBRE' },
    ];

    if (parametro === null || parametro === undefined) {
      parametro = '';
    }
    this.busquedacampos = [parametro, '', ''];
    this.consulta = 'MAERUBRO_CODIGO,MAERUBRO_CODRUBR,MAERUBRO_NOMBRE,COM_CODIGO,MAERUBRO_TIPO';
    this.tabla = ' NOM_MAERUBRO';
    this.where = " MAERUBRO_TIPO = 'INGRESO' AND COM_CODIGO = '01'";
    this.busqService.encontrarRegistro29(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + ' AND MAERUBRO_CODRUBR LIKE \'%' + parametro + '%\' ');
        }
      } else {
        this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + ' AND MAERUBRO_CODRUBR LIKE \'%' + parametro + '%\' ');
      }
    });
  }
  busquedaEgreRubroCod(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'egreRUBRO_CODIGO':
        this.indexunidad = this.nomempleadosService.nommaeEgreFijo.findIndex(ingrfig => ingrfig.NUMEGRFIJ === rowData.NUMEGRFIJ);
        break;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'MAERUBRO_CODRUBR' },
      { label: 'Nombre', value: 'MAERUBRO_NOMBRE' },
    ];

    if (parametro === null || parametro === undefined) {
      parametro = '';
    }
    this.busquedacampos = [parametro, '', ''];
    this.consulta = 'MAERUBRO_CODIGO,MAERUBRO_CODRUBR,MAERUBRO_NOMBRE,COM_CODIGO,MAERUBRO_TIPO';
    this.tabla = ' NOM_MAERUBRO';
    this.where = " MAERUBRO_TIPO = 'DESCUENTO' AND COM_CODIGO = '01'";
    this.busqService.encontrarRegistro30(parametro).subscribe(eR => {
      console.log(eR);
      if (eR !== null) {
        if (eR[0] !== undefined) {

          this.manejarSeleccion(eR[0]);
        } else {

          this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + ' AND MAERUBRO_CODRUBR LIKE \'%' + parametro + '%\' ');
        }
      } else {

        this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + ' AND MAERUBRO_CODRUBR LIKE \'%' + parametro + '%\' ');
      }
    });
  }
  busquedaTipoID(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'cargaFAMI_ID':
        this.indexunidad = this.nomempleadosService.nomCargaFamil.findIndex(carfa => carfa.NUMCARFA === rowData.NUMCARFA);
        break;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' },
      { label: 'Nombre C', value: 'REF_NOMBREC' },
    ];

    if (parametro === null || parametro === undefined) {
      parametro = '';
    }

    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.tabla = ' saci_maereferencia';
    this.where = " ref_tipo='NOMTID'";
    this.busqService.encontrarRegistro27(this.where, parametro).subscribe(eR => {

      if (eR !== null) {
        if (eR[0] !== undefined) {

          this.manejarSeleccion(eR[0]);
        } else {

          this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + ' AND REF_CODIGO LIKE \'%' + parametro + '%\'  order by ref_codigo');
        }
      } else {

        this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + ' AND REF_CODIGO LIKE \'%' + parametro + '%\'  order by ref_codigo');
      }
    });
  }
  busquedaAGSexo(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'cargafaSEXO':
        this.indexunidad = this.nomempleadosService.nomCargaFamil.findIndex(carfa => carfa.NUMCARFA === rowData.NUMCARFA);
        break;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' },
      { label: 'Nombre C', value: 'REF_NOMBREC' },
    ];

    if (parametro === null || parametro === undefined) {
      parametro = '';
    }

    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.tabla = ' saci_maereferencia';
    this.where = " ref_tipo='SEX' ";
    this.busqService.encontrarRegistro27(this.where, parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + ' AND REF_CODIGO LIKE \'%' + parametro + '%\'  order by ref_codigo');
        }
      } else {
        this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + ' AND REF_CODIGO LIKE \'%' + parametro + '%\'  order by ref_codigo');
      }
    });
  }
  busquedaAGTipoRela(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'cargafaTIPORELA':
        this.indexunidad = this.nomempleadosService.nomCargaFamil.findIndex(carfa => carfa.NUMCARFA === rowData.NUMCARFA);
        break;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' },
      { label: 'Nombre C', value: 'REF_NOMBREC' },
    ];

    if (parametro === null || parametro === undefined) {
      parametro = '';
    }
    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.tabla = ' saci_maereferencia';
    this.where = " ref_tipo='NOMPAR' ";
    this.busqService.encontrarRegistro27(this.where, parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + ' AND REF_NOMBRE LIKE \'%' + parametro + '%\' order by ref_codigo');
        }
      } else {
        this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + ' AND REF_NOMBRE LIKE \'%' + parametro + '%\' order by ref_codigo');
      }
    });
  }
  busquedaAGESTATUS(rowData, opcionbusqueda, parametro) {

    switch (opcionbusqueda) {
      case 'cargafaESTATUS':
        this.indexunidad = this.nomempleadosService.nomCargaFamil.findIndex(carfa => carfa.NUMCARFA === rowData.NUMCARFA);
        break;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' },
      { label: 'Nombre C', value: 'REF_NOMBREC' },
    ];

    if (parametro === null || parametro === undefined) {
      parametro = '';
    }
    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.tabla = ' saci_maereferencia';
    this.where = " ref_tipo='NOMST' ";
    this.busqService.encontrarRegistro27(this.where, parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + ' AND REF_CODIGO LIKE \'%' + parametro + '%\' order by ref_codigo');
        }
      } else {
        this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + ' AND REF_CODIGO LIKE \'%' + parametro + '%\' order by ref_codigo');
      }
    });
  }
  busquedaTipoVac(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'valorcrudVaca':
        this.indexunidad = this.nomempleadosService.nomcrudVaca.findIndex(vaca => vaca.NUMVAC === rowData.NUMVAC);
        break;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' },
      { label: 'Nombre C', value: 'REF_NOMBREC' },
    ];

    if (parametro === null || parametro === undefined) {
      parametro = '';
    }

    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.tabla = ' saci_maereferencia';
    this.where = " ref_tipo='NOMTVA' ";

    this.busqService.encontrarRegistro27(this.where, parametro).subscribe(eR => {
      console.log(eR);
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + ' AND REF_CODIGO LIKE \'%' + parametro + '%\' order by ref_codigo');
        }
      } else {
        this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + ' AND REF_CODIGO LIKE \'%' + parametro + '%\' order by ref_codigo');
      }
    });
  }
  busquedaVacSta(rowData, opcionbusqueda, parametro) {

    switch (opcionbusqueda) {
      case 'valorcrudVacEst':
        this.indexunidad = this.nomempleadosService.nomcrudVaca.findIndex(vaca => vaca.NUMVAC === rowData.NUMVAC);
        break;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' },
      { label: 'Nombre C', value: 'REF_NOMBREC' },
    ];

    if (parametro === null || parametro === undefined) {
      parametro = '';
    }

    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.tabla = ' saci_maereferencia';
    this.where = " ref_tipo='NOMEVA' ";
    this.busqService.encontrarRegistro27(this.where, parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + ' AND REF_CODIGO LIKE \'%' + parametro + '%\' order by ref_codigo');
        }
      } else {
        this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + ' AND REF_CODIGO LIKE \'%' + parametro + '%\' order by ref_codigo');
      }
    });
  }

  async obtenerBancoCuentaChCli() {
    let strSql = 'SELECT * FROM VEN_MAECLIENTE WHERE CLI_CODIGO = \'' + this.cxcCarteraService.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO + '\'';
    strSql = strSql + ' AND COM_CODIGO = \'01\'';

    try {
      const rs = await this.cxcCarteraService.obtenermaecliente(this.cxcCarteraService.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO);
      console.log(rs);
      for (const item of rs) {
        this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_CODIGO = item.BANCLI_CODIGO === null ? '' : item.BANCLI_CODIGO;
        if (item.BANCLI_CODIGO !== null) {
          this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_NOMBRE = this.cxcCarteraService.obtenerNombreBanco(item.BANCLI_CODIGO);
        }

        this.cxcCarteraService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NROCTA = item.CLI_NROCUENTA === null ? '' : item.CLI_NROCUENTA;
      }
    } catch (err) {
      this.messageService.add({
        key: 'cartera',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede obtener el banco y la cuenta de los cheques del cliente' + err.error
      });
    }
  }
  //CDPJ
  async obtenerBancoCuentaChCliAbono() {
    let strSql = 'SELECT * FROM VEN_MAECLIENTE WHERE CLI_CODIGO = \'' + this.cxctrnabonoService.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO + '\'';
    strSql = strSql + ' AND COM_CODIGO = \'01\'';

    try {
      const rs = await this.cxctrnabonoService.obtenermaecliente(this.cxctrnabonoService.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO);
      console.log(rs);
      for (const item of rs) {
        this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_CODIGO = item.BANCLI_CODIGO === null ? '' : item.BANCLI_CODIGO;
        if (item.BANCLI_CODIGO !== null) {
          this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_NOMBRE = this.cxctrnabonoService.obtenerNombreBanco(item.BANCLI_CODIGO);
        }

        this.cxctrnabonoService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NROCTA = item.CLI_NROCUENTA === null ? '' : item.CLI_NROCUENTA;
      }
    } catch (err) {
      this.messageService.add({
        key: 'cxctrnabono',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede obtener el banco y la cuenta de los cheques del cliente' + err.error
      });
    }
  }
  async obtenerBancoCuentaChCliCancelacion() {
    let strSql = 'SELECT * FROM VEN_MAECLIENTE WHERE CLI_CODIGO = \'' + this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO + '\'';
    strSql = strSql + ' AND COM_CODIGO = \'01\'';

    try {
      const rs = await this.cxctrncancelacionService.obtenermaecliente(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO);
      console.log(rs);
      for (const item of rs) {
        this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_CODIGO = item.BANCLI_CODIGO === null ? '' : item.BANCLI_CODIGO;
        if (item.BANCLI_CODIGO !== null) {
          this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_NOMBRE = this.cxctrncancelacionService.obtenerNombreBanco(item.BANCLI_CODIGO);
        }

        this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NROCTA = item.CLI_NROCUENTA === null ? '' : item.CLI_NROCUENTA;
      }
    } catch (err) {
      this.messageService.add({
        key: 'cxctrncancelacion',
        severity: 'error',
        summary: 'Error',
        detail: 'No se puede obtener el banco y la cuenta de los cheques del cliente' + err.error
      });
    }
  }
  // async obtenerBancoCuentaChCliAnticipo() {


  //   try {
  //     const rs = await this.cxctrncancelacionService.obtenermaecliente(this.cxctrncancelacionService.encabezadoCarteraActivaarray[this.init.tabs[this.init.tabindex].indice].CLI_CODIGO);
  //     console.log(rs);
  //     for (const item of rs) {
  //       this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_CODIGO = item.BANCLI_CODIGO === null ? '' : item.BANCLI_CODIGO;
  //       if (item.BANCLI_CODIGO !== null) {
  //         this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].BANCLI_NOMBRE = this.cxctrncancelacionService.obtenerNombreBanco(item.BANCLI_CODIGO);
  //       }

  //       this.cxctrncancelacionService.cxctrnformapagoarray[this.init.tabs[this.init.tabindex].indice][this.indexunidad].TRNPAGO_NROCTA = item.CLI_NROCUENTA === null ? '' : item.CLI_NROCUENTA;
  //     }
  //   } catch (err) {
  //     this.messageService.add({
  //       key: 'cxctrnanticipos',
  //       severity: 'error',
  //       summary: 'Error',
  //       detail: 'No se puede obtener el banco y la cuenta de los cheques del cliente' + err.error
  //     });
  //   }
  // }
  
  //CDPJ
  busquedaEmpleado(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'empcodigocabi':
        this.indexunidad = this.impurentService.detImpuRent.findIndex(cab => cab.NUMCAB === rowData.NUMCAB);
        break;
      case 'emptrannoved':
        this.indexunidad = this.nomtrannovedadservice.nonnovedad.findIndex(cab => cab.NUMNOV === rowData.NUMNOV);
        break;
      case 'emptrnhorashombre':
        this.indexunidad = this.encordproService.trnhorashombre[this.init.tabs[this.init.tabindex].indice].findIndex(d => d.CLAVE === rowData.CLAVE);
        break;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'CÓDIGO', value: 'EMP_CODIGO' },
      { label: 'ID', value: 'EMP_RUCIDE' },
      { label: 'Apellido', value: 'EMP_APELLIDO' },
      { label: 'Nombre', value: 'EMP_NOMBRE' },
      { label: 'Fech.Ingreso', value: 'EMP_FECINGRESO' },

    ];

    if (parametro === null || parametro === undefined) {
      parametro = '';
    }

    this.busquedacampos = ['', '', '', '', ''];
    this.consulta = 'EMP_CODIGO,COM_CODIGO,EMP_ACTIVO,EMP_RUCIDE,EMP_APELLIDO,EMP_NOMBRE,EMP_FECINGRESO,EMP_FECSALIDA';
    this.tabla = 'NOM_MAEEMPLEADO ';
    this.where = "com_codigo = '01' and nvl(emp_activo,'1') = '1'  ";
    this.busqService.encontrarRegistro31(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + ' AND EMP_CODIGO LIKE \'%' + parametro + '%\' order by emp_apellido,emp_nombre ');
        }
      } else {
        this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + ' AND EMP_CODIGO LIKE \'%' + parametro + '%\' order by emp_apellido,emp_nombre');
      }
    });
  }
  busquedaRuboNovedad(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'rubcodnoved':
        this.indexunidad = this.nomtrannovedadservice.nonnovedad.findIndex(cab => cab.NUMNOV === rowData.NUMNOV);
        break;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'MAERUBRO_CODRUBR' },
      { label: 'Nombre', value: 'MAERUBRO_NOMBRE' },
    ];

    if (parametro === null || parametro === undefined) {
      parametro = '';
    }

    this.busquedacampos = ['', ''];
    this.consulta = 'maerubro_tipo,maerubro_codigo,maerubro_codrubr,maerubro_nombre, maerubro_rubrorde,maerubro_tipoorde,MAERUBRO_ACTIVO,MAERUBRO_NOVEDAD';
    this.tabla = 'nom_maerubro ';
    this.where = "maerubro_tipo ='DESCUENTO'and MAERUBRO_ACTIVO =1 and MAERUBRO_NOVEDAD=1";
    this.busqService.encontrarRegistro32(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + ' AND maerubro_codrubr LIKE \'%' + parametro + '%\' order by maerubro_tipoorde,maerubro_rubrorde ');
        }
      } else {
        this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + ' AND maerubro_codrubr LIKE \'%' + parametro + '%\' order by maerubro_tipoorde,maerubro_rubrorde');
      }
    });
  }

  async seleccionbusquedaarticulodetordpro(opcion) {
    this.encordproService.detordpro[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_CODIGO = opcion.ART_CODIGO;
    this.encordproService.detordpro[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDPRO_DESCRIPCION = opcion.ART_NOMBRE;

    let Existencia = 0;
    if (this.encordproService.selectTipoProd[this.init.tabs[this.init.tabindex].indice].codigo === 'F') {
      Existencia = opcion.EXISTENCIA;
    } else {
      Existencia = 1;
    }
    if (opcion == null) {
      this.messageService.add({
        key: 'encordpro',
        severity: 'error',
        summary: 'Información',
        detail: 'El Artículo no corresponde a la Materia prima'
      });
      // this.encordproService.detordpro[this.init.tabs[this.init.tabindex].indice][this.indexunidad].COLORFORMULACION = 'Y';
      this.encordproService.detordpro[this.init.tabs[this.init.tabindex].indice][this.indexunidad].ART_CODIGO = '';
      this.encordproService.detordpro[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDPRO_DESCRIPCION = '';
      return false;
    } else { // Si existen datos      
      this.encordproService.detordpro[this.init.tabs[this.init.tabindex].indice][this.indexunidad].EXISTENCIA = Existencia;
      if (Existencia > 0) {
        if (Existencia < 1) {
          this.encordproService.detordpro[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDPRO_CANTIDAD = Existencia;
        } else {
          this.encordproService.detordpro[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDPRO_CANTIDAD = 1;
        }
      } else {
        // No Hay en existencia
        const ORDPROEXSNEG = this.encordproService.hmpConfiguracion.find(element => element.CFG_CODIGO === 'PROXPROC').CFG_VALOR1;
        if (ORDPROEXSNEG == 0 || this.encordproService.selectTipoProd[this.init.tabs[this.init.tabindex].indice].codigo === 'P') {
          this.messageService.add({
            key: 'encordpro',
            severity: 'error',
            summary: 'Información',
            detail: 'No hay en existencia'
          });
          this.encordproService.detordpro[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDPRO_CANTIDAD = 0;
          this.encordproService.detordpro[this.init.tabs[this.init.tabindex].indice][this.indexunidad].COLOREXISTENCIA = "Y";
        } else { // Permitir factura con existencia negativa
          this.encordproService.detordpro[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDPRO_CANTIDAD = 1;
        }
      }
      // Costo
      const strFecha = this.datePipe.transform(this.encordproService.encordpro[this.init.tabs[this.init.tabindex].indice].ENCORDPRO_FECHAINICIO, 'dd/MM/yyyy');
      const strHora = this.datePipe.transform(this.encordproService.encordpro[this.init.tabs[this.init.tabindex].indice].ENCORDPRO_FECHAINICIO, 'HH:mm');

      const data: any = await this.encordproService.costopromedio(opcion.ART_CODIGO, strFecha, strHora);
      for (const rs of data) {
        this.encordproService.detordpro[this.init.tabs[this.init.tabindex].indice][this.indexunidad].DETORDPRO_COSTO = rs.COSTOPROM;
        this.encordproService.detordpro[this.init.tabs[this.init.tabindex].indice][this.indexunidad].UNI_COSTEO = rs.ART_UNIDADVENTA; // Codigo de la unidad de costeo
        this.encordproService.detordpro[this.init.tabs[this.init.tabindex].indice][this.indexunidad].UNI_SALIDA = rs.ART_UNIDADCOSTEO; // Codigo de la unidad en la que sale
        this.encordproService.detordpro[this.init.tabs[this.init.tabindex].indice][this.indexunidad].UNI_CODIGO = rs.ART_UNIDADCOSTEO; // Simbolo de la unidad de salida
      }
    }
    this.encordproService.modeloDetalleVerificaSiEsParteFormula(opcion.ART_CODIGO, this.indexunidad);
    this.encordproService.SumarTotal();
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setRowData(this.encordproService.detordpro[this.init.tabs[this.init.tabindex].indice]);
    this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDef.objeto].setFocusedCell(this.indexunidad, 'UNI_CODIGO');
  }
  busquedaCostFecha(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'costfecha':
        this.indexunidad = this.costfechservi.costosfec.findIndex(cab => cab.NUMCOS === rowData.NUMCOS);
        break;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'CIN_CODIGO' },
      { label: 'Nombre', value: 'CIN_NOMBRE' },
      { label: 'Costo Unitario', value: 'CIN_COSTO_U' },
      { label: 'Ref Contable', value: 'CON_CODIGO' },
      { label: 'Centros de Costos', value: 'CEN_CODIGO' },
    ];

    if (parametro === null || parametro === undefined) {
      parametro = '';
    }
    this.busquedacampos = [parametro, '', '','','',''];
    this.consulta = '*';
    this.tabla = 'PROD_MAECOSTOIND ';
    this.where = "";
    this.busqService.encontrarRegistro33(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + '  CIN_CODIGO LIKE \'%' + parametro + '%\'  ORDER BY CIN_CODIGO ');
        }
      } else {
        this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + '  CIN_CODIGO LIKE \'%' + parametro + '%\'  ORDER BY CIN_CODIGO');
      }
    });
  }
  busquedaCCVarios(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'CENCODIGO_LIQ_SER':
        this.indexunidad = this.liqservice.nomliquvar.findIndex(cab => cab.NUMLIQV === rowData.NUMLIQV);
        break;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'CEN_CODIGO' },
      { label: 'Nombre', value: 'CEN_NOMBRE' },
    ];

    if (parametro === null || parametro === undefined) {
      parametro = '';
    }
    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.tabla = 'CON_MAECEN ';
    this.where = "";
    this.busqService.encontrarRegistro34(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + '  CEN_CODIGO LIKE \'%' + parametro + '%\'  ORDER BY CEN_CODIGO ');
        }
      } else {
        this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + '  CEN_CODIGO LIKE \'%' + parametro + '%\'  ORDER BY CEN_CODIGO');
      }
    });
  }
  busquedaFormaPagoLiq(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'busquedaFormaPagoLiq':
        this.indexunidad = this.liqservice.nomliqdet.findIndex(cab => cab.NUMCAB === rowData.NUMCAB);
        break;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' },
      { label: 'Nombre Corto', value: 'REF_NOMBREC' },
    ];

    if (parametro === null || parametro === undefined) {
      parametro = '';
    }
    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.tabla = 'SACI_MAEREFERENCIA ';
    this.where = " REF_TIPO = 'NOMFP' AND ";
    this.busqService.encontrarRegistro35(this.where, parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + '  REF_NOMBRE LIKE \'%' + parametro + '%\'  ORDER BY REF_CODIGO ');
        }
      } else {
        this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + '  REF_NOMBRE LIKE \'%' + parametro + '%\'  ORDER BY REF_CODIGO');
      }
    });
  }
  busquedaBancoLiq(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'busquedaBancoLiq':
        this.indexunidad = this.liqservice.nomliqdet.findIndex(cab => cab.NUMCAB === rowData.NUMCAB);
        break;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'BANCLI_CODIGO' },
      { label: 'Banco', value: 'BANCLI_NOMBRE' },
      { label: 'Estado', value: 'BANCLI_ESTADO' },
    ];

    if (parametro === null || parametro === undefined) {
      parametro = '';
    }
    this.busquedacampos = [parametro, '', ''];
    this.consulta = 'BANCLI_CODIGO , BANCLI_NOMBRE , BANCLI_ESTADO ';
    this.tabla = 'CXC_MAEBANCLI';
    this.where = "";
    this.busqService.encontrarRegistro36(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + '  BANCLI_NOMBRE LIKE \'%' + parametro + '%\'  ORDER BY  BANCLI_NOMBRE ASC ');
        }
      } else {
        this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + '  BANCLI_NOMBRE LIKE \'%' + parametro + '%\'  ORDER BY  BANCLI_NOMBRE ASC');
      }
    });
  }
  busquedaNumCuenLiq(rowData, opcionbusqueda, parametro) {
    switch (opcionbusqueda) {
      case 'busquedaNumeCueLiq':
        this.indexunidad = this.liqservice.nomliqdet.findIndex(cab => cab.NUMCAB === rowData.NUMCAB);
        break;
    }
    this.opcionbusqueda = opcionbusqueda;
    this.types = [
      { label: 'Código', value: 'REF_CODIGO' },
      { label: 'Nombre', value: 'REF_NOMBRE' },
      { label: 'Nombre Corto', value: 'REF_NOMBREC' },
    ];

    if (parametro === null || parametro === undefined) {
      parametro = '';
    }
    this.busquedacampos = [parametro, '', ''];
    this.consulta = '*';
    this.tabla = ' SACI_MAEREFERENCIA';
    this.where = " REF_TIPO ='NOTPCT' AND";
    this.busqService.encontrarRegistro35(this.where, parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + '  REF_NOMBRE LIKE \'%' + parametro + '%\'  ORDER BY REF_CODIGO ');
        }
      } else {
        this.abrirDialogBusqueda(this.consulta, this.tabla, this.where + '  REF_NOMBRE LIKE \'%' + parametro + '%\'  ORDER BY REF_CODIGO');
      }
    });
  }

  async vercehiculo() {
        this.displayvehiculo = true; 
        
  }
}
