<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="cliente"></p-toast>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra (signal)="manejarSenales($event)" [barraBotones3]="barraBotones2"
			[barraBotonesAsiCon]="barraBotones2" [botonBorrar]="botonBorrar" [botonNuevo]="botonNuevo"
			[botonGuardar]="botonGuardar" [botonBuscar]="false" [botonRegresar]="botonRegresar" [barraBotones5]="false"
			[botonPrimero]="!botonPrimero" [botonAnterior]="!botonAnterior" [botonSiguiente]="!botonSiguiente"
			[botonUltimo]="!botonUltimo" [botonAlmacenardoc]="true" [botonBinocular]="true"></app-invmaebarra>
	</div>
</div>
<p-tabView [(activeIndex)]="index" (onChange)="changeTabs()">
	<p-tabPanel header="Generales" [disabled]="botonNuevo && index === 6">
		<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 250px)'}">
			<div class="contorno" id="contorno">
				<div class="divgrups">
					<div class="p-grid">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span class="obligatorio">Tipo ID:</span>
								</div>
								<div class="p-col-2">
									<p-dropdown [options]="cmbide" [style]="{'minWidth': '100%', 'width':'100%'}"
										optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selectide"
										(onChange)="busqide()"></p-dropdown>
								</div>
								<div class="p-col-1">
									<span class="obligatorio">RUC / IDE</span>
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-10">
											<label for="CLI_RUCIDE"></label>
											<input class="frm" id="CLI_RUCIDE" type="text" pInputText
												[(ngModel)]="maecliente.CLI_RUCIDE" (keydown.enter)="setFocus($event)"
												(keydown)="botmodclirucide()" (focusout)="setFocus($event)"
												autocomplete="off"
												style="text-transform: uppercase;">
										</div>
										<div class="p-col-2">
											<button type="button"
												style="background-image: url(../../../assets/images/iconos/visto.png);"
												(click)="validar(maecliente.CLI_RUCIDE)"></button>
										</div>
									</div>
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-3">
											<span>{{lblvalida}}</span>
										</div>
										<div class="p-col-9">
											<span class="obligatorio">Código</span>
										</div>
									</div>
								</div>
								<div class="p-col-3">
									<label for="CLI_CODIGO"></label>
									<input class="frm" id="CLI_CODIGO" type="text" pInputText
										[(ngModel)]="maecliente.CLI_CODIGO" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off" [disabled]="bolcliente"
										style="text-transform: uppercase;">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -25px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span class="obligatorio">Nombre:</span>
								</div>
								<div class="p-col-5">
									<label for="CLI_NOMBRE"></label>
									<input class="frm" id="CLI_NOMBRE" type="text" pInputText
										[(ngModel)]="maecliente.CLI_NOMBRE" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" (focusout)="setFocus($event)" autocomplete="off">
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-3">
										</div>
										<div class="p-col-9">
											<span class="obligatorio">Nombre Corto:</span>
										</div>
									</div>
								</div>
								<div class="p-col-2">
									<label for="CLI_NOMBREC"></label>
									<input class="frm" id="CLI_NOMBREC" type="text" pInputText maxlength="12"
										[(ngModel)]="maecliente.CLI_NOMBREC" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -20px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Contacto:</span>
								</div>
								<div class="p-col-5">
									<label for="CLI_CONTACTO"></label>
									<input class="frm" id="CLI_CONTACTO" type="text" pInputText
										[(ngModel)]="maecliente.CLI_CONTACTO" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-3">
										</div>
										<div class="p-col-9">
											<span>Fecha Emisión</span>
										</div>
									</div>
								</div>
								<div class="p-col-1">
									<p-calendar id="CLI_FECING" [inputStyle]="{width: '100%', height: '20px'}"
										[(ngModel)]="maecliente.CLI_FECING" dateFormat="dd/mm/yy"
										(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()">
									</p-calendar>
								</div>
								<div class="p-col-2">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -20px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span class="obligatorio">Grupo:</span>
								</div>
								<div class="p-col-1">
									<label for="GRU_CODIGO"></label>
									<input class="frm" id="GRU_CODIGO" type="text" pInputText
										[(ngModel)]="maecliente.GRU_CODIGO" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="busquedagrupo(maecliente.GRU_CODIGO)"></button>
								</div>
								<div class="p-col-3">
									<label for="GRU_NOMBRE"></label>
									<input class="frm" id="GRU_NOMBRE" type="text" pInputText disabled
										[(ngModel)]="maecliente.GRU_NOMBRE" autocomplete="off">
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-3">
										</div>
										<div class="p-col-9">
											<span>F. Nacimiento:</span>
										</div>
									</div>
								</div>
								<div class="p-col-1">
									<p-calendar id="CLI_FECHANACIMIENTO" [inputStyle]="{width: '100%', height: '20px'}"
										[(ngModel)]="maecliente.CLI_FECHANACIMIENTO" dateFormat="dd/mm/yy"
										(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()">
									</p-calendar>
								</div>
								<div class="p-col-2">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -20px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Est. Civil:</span>
								</div>
								<div class="p-col-1">
									<label for="CLI_ESTCIV"></label>
									<input class="frm" id="CLI_ESTCIV" type="text" pInputText
										[(ngModel)]="maecliente.CLI_ESTCIV" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="busquedaestciv(maecliente.CLI_ESTCIV)"></button>
								</div>
								<div class="p-col-3">
									<label for="CLI_ESTCIVNOMBRE"></label>
									<input class="frm" id="CLI_ESTCIVNOMBRE" type="text" pInputText disabled
										[(ngModel)]="maecliente.CLI_ESTCIVNOMBRE" autocomplete="off">
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-3">
										</div>
										<div class="p-col-9">
											<span>Org. Ingresos:</span>
										</div>
									</div>
								</div>
								<div class="p-col-1">
									<label for="CLI_ORGING"></label>
									<input class="frm" id="CLI_ORGING" type="text" pInputText
										[(ngModel)]="maecliente.CLI_ORGING" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="busquedaorging(maecliente.CLI_ORGING)"></button>
								</div>
								<div class="p-col-1">
									<label for="CLI_ORGINGNOMBRE"></label>
									<input class="frm" id="CLI_ORGINGNOMBRE" type="text" pInputText disabled
										[(ngModel)]="maecliente.CLI_ORGINGNOMBRE" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -20px; margin-bottom: -20px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Sexo:</span>
								</div>
								<div class="p-col-1">
									<label for="CLI_SEXO"></label>
									<input class="frm" id="CLI_SEXO" type="text" pInputText
										[(ngModel)]="maecliente.CLI_SEXO" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="busquedasexo(maecliente.CLI_SEXO)"></button>
								</div>
								<div class="p-col-1">
									<label for="CLI_SEXONOMBRE"></label>
									<input class="frm" id="CLI_SEXONOMBRE" type="text" pInputText disabled
										[(ngModel)]="maecliente.CLI_SEXONOMBRE" autocomplete="off">
								</div>
								<div class="p-col-1">
									<div class="p-grid">
										<div class="p-col-3">
											<p-checkbox id="chkunificado" name="chkunificado" value="" label=""
												binary="{{chkunificado}}" [(ngModel)]="chkunificado"
												(click)="botonesmodificar()" (keydown.enter)="setFocus($event)">
											</p-checkbox>
										</div>
										<div class="p-col-9">
											<span>Unificado</span>
										</div>
									</div>
								</div>
								<div class="p-col-1">
									<div class="p-grid">
										<div class="p-col-3">
											<p-checkbox id="chkexcluye" name="chkexcluye" value="" label=""
												binary="{{chkexcluye}}" [(ngModel)]="chkexcluye"
												(click)="botonesmodificar()" (keydown.enter)="setFocus($event)">
											</p-checkbox>
										</div>
										<div class="p-col-9">
											<span>Excluye</span>
										</div>
									</div>
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-3">
										</div>
										<div class="p-col-9">
											<span class="obligatorio">Vendedor:</span>
										</div>
									</div>
								</div>
								<div class="p-col-1">
									<label for="VEN_CODIGO"></label>
									<input class="frm" id="VEN_CODIGO" type="text" pInputText
										[(ngModel)]="maecliente.VEN_CODIGO" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="busquedavendedor(maecliente.VEN_CODIGO)"></button>
								</div>
								<div class="p-col-1">
									<label for="VEN_NOMBRE"></label>
									<input class="frm" id="VEN_NOMBRE" type="text" pInputText disabled
										[(ngModel)]="maecliente.VEN_NOMBRE" autocomplete="off">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="divgrups">
					<div class="p-grid">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span class="obligatorio">Dirección 1:</span>
								</div>
								<div class="p-col-8">
									<label for="CLI_DIRECCION1"></label>
									<input class="frm" id="CLI_DIRECCION1" type="text" pInputText
										[(ngModel)]="maecliente.CLI_DIRECCION1" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Dirección 2:</span>
								</div>
								<div class="p-col-8">
									<label for="CLI_DIRECCION2"></label>
									<input class="frm" id="CLI_DIRECCION2" type="text" pInputText
										[(ngModel)]="maecliente.CLI_DIRECCION2" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span class="obligatorio">Teléfono 1:</span>
								</div>
								<div class="p-col-2">
									<label for="CLI_TELEFONO1"></label>
									<input class="frm" id="CLI_TELEFONO1" type="text" pInputText
										[(ngModel)]="maecliente.CLI_TELEFONO1" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
									<span>Teléfono 2:</span>
								</div>
								<div class="p-col-2">
									<label for="CLI_TELEFONO2"></label>
									<input class="frm" id="CLI_TELEFONO2" type="text" pInputText
										[(ngModel)]="maecliente.CLI_TELEFONO2" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
									<span>Fax:</span>
								</div>
								<div class="p-col-2">
									<label for="CLI_FAX"></label>
									<input class="frm" id="CLI_FAX" type="text" pInputText
										[(ngModel)]="maecliente.CLI_FAX" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-2"></div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Cód. Postal:</span>
								</div>
								<div class="p-col-2">
									<label for="CLI_CODPOSTAL"></label>
									<input class="frm" id="CLI_CODPOSTAL" type="text" pInputText
										[(ngModel)]="maecliente.CLI_CODPOSTAL" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
									<span>Cargo:</span>
								</div>
								<div class="p-col-2">
									<label for="CLI_CARGO"></label>
									<input class="frm" id="CLI_CARGO" type="text" pInputText
										[(ngModel)]="maecliente.CLI_CARGO" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="busquedacargo(maecliente.CLI_CARGO)"></button>
								</div>
								<div class="p-col-2">
									<label for="CLI_CARGONOMBRE"></label>
									<input class="frm" id="CLI_CARGONOMBRE" type="text" pInputText disabled
										[(ngModel)]="maecliente.CLI_CARGONOMBRE" autocomplete="off">
								</div>
								<div class="p-col-2"></div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -18px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span class="obligatorio">Email 1:</span>
								</div>
								<div class="p-col-3">
									<label for="CLI_CORREO"></label>
									<input class="frm" id="CLI_CORREO" type="text" pInputText
										[(ngModel)]="maecliente.CLI_CORREO" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
								</div>
								<div class="p-col-1">
									<span>Email 2:</span>
								</div>
								<div class="p-col-3">
									<label for="CLI_CORREO2"></label>
									<input class="frm" id="CLI_CORREO2" type="text" pInputText
										[(ngModel)]="maecliente.CLI_CORREO2" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -15px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Provincia:</span>
								</div>
								<div class="p-col-1">
									<label for="CLI_PROVINCIA"></label>
									<input class="frm" id="CLI_PROVINCIA" type="text" pInputText
										[(ngModel)]="maecliente.CLI_PROVINCIA" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-3">
											<button type="button"
												style="background-image: url(../../../assets/images/iconos/buscar.png);"
												(click)="busquedaprovincia(maecliente.CLI_PROVINCIA)"></button>
										</div>
										<div class="p-col-9">
											<label for="CLI_PROVINCIANOMBRE"></label>
											<input class="frm" id="CLI_PROVINCIANOMBRE" type="text" pInputText disabled
												[(ngModel)]="maecliente.CLI_PROVINCIANOMBRE" autocomplete="off">
										</div>
									</div>
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Ciudad:</span>
								</div>
								<div class="p-col-1">
									<label for="CIU_CODIGO"></label>
									<input class="frm" id="CIU_CODIGO" type="text" pInputText
										[(ngModel)]="maecliente.CIU_CODIGO" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-3">
											<button type="button"
												style="background-image: url(../../../assets/images/iconos/buscar.png);"
												(click)="busquedaciudad(maecliente.CIU_CODIGO)"></button>
										</div>
										<div class="p-col-9">
											<label for="CIU_NOMBRE"></label>
											<input class="frm" id="CIU_NOMBRE" type="text" pInputText disabled
												[(ngModel)]="maecliente.CIU_NOMBRE" autocomplete="off">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -23px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Cantón:</span>
								</div>
								<div class="p-col-1">
									<label for="CAN_CODIGO"></label>
									<input class="frm" id="CAN_CODIGO" type="text" pInputText
										[(ngModel)]="maecliente.CAN_CODIGO" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-3">
											<button type="button"
												style="background-image: url(../../../assets/images/iconos/buscar.png);"
												(click)="busquedacanton(maecliente.CAN_CODIGO)"></button>
										</div>
										<div class="p-col-9">
											<label for="CAN_NOMBRE"></label>
											<input class="frm" id="CAN_NOMBRE" type="text" pInputText disabled
												[(ngModel)]="maecliente.CAN_NOMBRE" autocomplete="off">
										</div>
									</div>
								</div>
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Parroquia:</span>
								</div>
								<div class="p-col-1">
									<label for="PARR_CODIGO"></label>
									<input class="frm" id="PARR_CODIGO" type="text" pInputText
										[(ngModel)]="maecliente.PARR_CODIGO" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-3">
											<button type="button"
												style="background-image: url(../../../assets/images/iconos/buscar.png);"
												(click)="busquedaparroquia(maecliente.PARR_CODIGO)"></button>
										</div>
										<div class="p-col-9">
											<label for="PARR_NOMBRE"></label>
											<input class="frm" id="PARR_NOMBRE" type="text" pInputText disabled
												[(ngModel)]="maecliente.PARR_NOMBRE" autocomplete="off">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -23px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Zona:</span>
								</div>
								<div class="p-col-1">
									<label for="CLI_ZONA"></label>
									<input class="frm" id="CLI_ZONA" type="text" pInputText
										[(ngModel)]="maecliente.CLI_ZONA" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-3">
											<button type="button"
												style="background-image: url(../../../assets/images/iconos/buscar.png);"
												(click)="busquedazona(maecliente.CLI_ZONA)"></button>
										</div>
										<div class="p-col-9">
											<label for="CLI_ZONANOMBRE"></label>
											<input class="frm" id="CLI_ZONANOMBRE" type="text" pInputText disabled
												[(ngModel)]="maecliente.CLI_ZONANOMBRE" autocomplete="off">
										</div>
									</div>
								</div>
								<div class="p-col-5"></div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -23px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>G. Maps:</span>
								</div>
								<div class="p-col-8">
									<label for="CLI_GMAPS"></label>
									<input class="frm" id="CLI_GMAPS" type="text" pInputText
										[(ngModel)]="maecliente.CLI_GMAPS" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/067-binocular.png);"
										(click)="busquedagmaps(maecliente.CLI_GMAPS)"></button>
								</div>
								<div class="p-col-2"></div>
							</div>
						</div>
						<div class="p-col-12" style="margin-top: -28px; margin-bottom: -20px">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-1">
									<span>Observación:</span>
								</div>
								<div class="p-col-8">
									<label for="CLI_OBSERVACION"></label>
									<input class="frm" id="CLI_OBSERVACION" type="text" pInputText
										[(ngModel)]="maecliente.CLI_OBSERVACION" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</p-scrollPanel>
	</p-tabPanel>
	<p-tabPanel header="Administrativos" [disabled]="botonNuevo && index === 6">
		<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 250px)'}">
			<div class="divgrups">
				<div class="p-grid">
					<div class="p-col-3">
						<span>{{maecliente.CLI_CODIGO}}</span>
					</div>
					<div class="p-col-5">
						<span>{{maecliente.CLI_NOMBRE}}</span>
					</div>
					<div class="p-col-4">
					</div>
				</div>
			</div>
			<div class="divgrups">
				<div class="p-grid">
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Contribuyente:</span>
							</div>
							<div class="p-col-2">
								<p-dropdown [options]="cmbcontribuyente" [style]="{'minWidth': '100%', 'width':'100%'}"
									optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selectcontribuyente"
									(onChange)="busqcontribuyente($event)"></p-dropdown>

							</div>
							<div class="p-col-2">
								<span>Parte Relacionada:</span>
							</div>
							<div class="p-col-2">
								<p-dropdown [options]="cmbparterelacionada"
									[style]="{'minWidth': '100%', 'width':'100%'}" optionLabel="name"
									[panelStyle]="{'width':'110%'}" [(ngModel)]="selectparterelacionada"
									(onChange)="busqparterelacionada($event)"></p-dropdown>
							</div>
							<div class="p-col-2">
								<span>Denominación:</span>
							</div>
							<div class="p-col-2">
								<p-dropdown [options]="cmbdenominacion" [style]="{'minWidth': '100%', 'width':'100%'}"
									disabled="{{denominaciondisabled}}" optionLabel="name"
									[panelStyle]="{'width':'110%'}" [(ngModel)]="selectdenominacion"
									(onChange)="botonesmodificar()"></p-dropdown>
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Tipo Cliente:</span>
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-4">
										<label for="CLI_SUJETO"></label>
										<input class="frm" id="CLI_SUJETO" type="text" pInputText
											[(ngModel)]="maecliente.CLI_SUJETO" (keydown.enter)="setFocus($event)"
											(keydown)="botonesmodificar()" autocomplete="off">
									</div>
									<div class="p-col-2">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"
											(click)="busquedatipo(maecliente.CLI_SUJETO)"></button>
									</div>
									<div class="p-col-6">
										<label for="CLI_SUJETONOMBRE"></label>
										<input class="frm" id="CLI_SUJETONOMBRE" type="text" pInputText disabled
											[(ngModel)]="maecliente.CLI_SUJETONOMBRE" autocomplete="off">
									</div>
								</div>
							</div>
							<div class="p-col-2">
								<span>Tipo:</span>
							</div>
							<div class="p-col-2">
								<p-dropdown [options]="cmbtipo" [style]="{'minWidth': '100%', 'width':'100%'}"
									optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selecttipo"
									(onChange)="busqtipo($event)"></p-dropdown>
							</div>
							<div class="p-col-1">
								<div class="p-grid">
									<div class="p-col-4">
										<p-checkbox name="chklider" value="" label="" binary="{{chklider}}"
											[(ngModel)]="chklider" (click)="botonesmodificar()"></p-checkbox>
									</div>
									<div class="p-col-8">
										<span>Lider</span>
									</div>
								</div>
							</div>
							<div class="p-col-1"></div>
							<div class="p-col-1">
								<div class="p-grid">
									<div class="p-col-4">
										<p-checkbox name="chkjefezonal" value="" label="" binary="{{chkjefezonal}}"
											[(ngModel)]="chkjefezonal" (click)="botonesmodificar()"></p-checkbox>
									</div>
									<div class="p-col-8">
										<span>Jefe Zonal</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -25px">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Descuento:</span>
							</div>
							<div class="p-col-2">
								<label for="CLI_DESCUENTO"></label>
								<input class="frm" id="CLI_DESCUENTO" type="text" pInputText
									[(ngModel)]="maecliente.CLI_DESCUENTO" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-2">
								<span>Descuento Límite:</span>
							</div>
							<div class="p-col-2">
								<label for="CLI_DESCUENTOLIM"></label>
								<input class="frm" id="CLI_DESCUENTOLIM" type="text" pInputText
									[(ngModel)]="maecliente.CLI_DESCUENTOLIM" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-2">
										<p-checkbox name="chkfacturacionelec" value="" label=""
											binary="{{chkfacturacionelec}}" [(ngModel)]="chkfacturacionelec"
											(click)="botonesmodificar()"></p-checkbox>
									</div>
									<div class="p-col-10">
										<span>Facturación Electrónica</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Límite Crédito:</span>
							</div>
							<div class="p-col-2">
								<label for="CLI_LIMCREDIT"></label>
								<input class="frm" id="CLI_LIMCREDIT" type="text" pInputText
									[(ngModel)]="maecliente.CLI_LIMCREDIT" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-2">
								<span>Días Crédito:</span>
							</div>
							<div class="p-col-2">
								<label for="CLI_DIACREDIT"></label>
								<input class="frm" id="CLI_DIACREDIT" type="text" pInputText
									[(ngModel)]="maecliente.CLI_DIACREDIT" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-2">
								<span>Días Cheque Pos:</span>
							</div>
							<div class="p-col-2">
								<label for="CLI_DIACHPOS"></label>
								<input class="frm" id="CLI_DIACHPOS" type="text" pInputText
									[(ngModel)]="maecliente.CLI_DIACHPOS" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Banco Cheques:</span>
							</div>
							<div class="p-col-1">
								<label for="BANCLI_CODIGO"></label>
								<input class="frm" id="BANCLI_CODIGO" type="text" pInputText
									[(ngModel)]="maecliente.BANCLI_CODIGO" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-1">
								<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									(click)="busquedabanco(maecliente.BANCLI_CODIGO)"></button>
							</div>
							<div class="p-col-4">
								<label for="BANCLI_NOMBRE"></label>
								<input class="frm" id="BANCLI_NOMBRE" type="text" pInputText disabled
									[(ngModel)]="maecliente.BANCLI_NOMBRE" autocomplete="off">
							</div>
							<div class="p-col-2">
								<span>Cta. Banco Cheques:</span>
							</div>
							<div class="p-col-2">
								<label for="CLI_NROCUENTA"></label>
								<input class="frm" id="CLI_NROCUENTA" type="text" pInputText
									[(ngModel)]="maecliente.CLI_NROCUENTA" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -18px">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Forma Pago Cod. Sri:</span>
							</div>
							<div class="p-col-1">
								<label for="CLI_FORMAPAGO_CODSRI"></label>
								<input class="frm" id="CLI_FORMAPAGO_CODSRI" type="text" pInputText
									[(ngModel)]="maecliente.CLI_FORMAPAGO_CODSRI" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-1">
								<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									(click)="busquedaformapago(maecliente.CLI_FORMAPAGO_CODSRI)"></button>
							</div>
							<div class="p-col-4">
								<label for="CLI_FORMAPAGO_CODSRINOMBRE"></label>
								<input class="frm" id="CLI_FORMAPAGO_CODSRINOMBRE" type="text" pInputText disabled
									[(ngModel)]="maecliente.CLI_FORMAPAGO_CODSRINOMBRE" autocomplete="off">
							</div>
							<div class="p-col-2">
								<div class="p-grid">
									<div class="p-col-2">
										<p-checkbox name="chktarjetadesc" value="" label="" binary="{{chktarjetadesc}}"
											[(ngModel)]="chktarjetadesc" (click)="botonesmodificar()"></p-checkbox>
									</div>
									<div class="p-col-10">
										<span>Cliente con tarjeta descuento</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -20px">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Estado:</span>
							</div>
							<div class="p-col-1">
								<label for="CLI_ESTADO"></label>
								<input class="frm" id="CLI_ESTADO" type="text" pInputText
									[(ngModel)]="maecliente.CLI_ESTADO" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-1">
								<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									(click)="busquedaestado(maecliente.CLI_ESTADO)"></button>
							</div>
							<div class="p-col-1">
								<label for="CLI_ESTADONOMBRE"></label>
								<input class="frm" id="CLI_ESTADONOMBRE" type="text" pInputText disabled
									[(ngModel)]="maecliente.CLI_ESTADONOMBRE" autocomplete="off">
							</div>
							<div class="p-col-1">
								<span>Lista:</span>
							</div>
							<div class="p-col-2">
								<p-dropdown [options]="cmblista" [style]="{'minWidth': '100%', 'width':'100%'}"
									optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selectlista"
									(onChange)="busqlista($event)"></p-dropdown>
							</div>
							<div class="p-col-2">
								<span>I.V.A:</span>
							</div>
							<div class="p-col-2">
								<p-dropdown [options]="cmbiva" [style]="{'minWidth': '100%', 'width':'100%'}"
									optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selectiva"
									(onChange)="botonesmodificar()"></p-dropdown>
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -20px">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Referencia Contable:</span>
							</div>
							<div class="p-col-2">
								<label for="CON_CODIGO1"></label>
								<input class="frm" id="CON_CODIGO1" type="text" pInputText
									[(ngModel)]="maecliente.CON_CODIGO1" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-1">
								<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									(click)="busquedaconcodigo('1',maecliente.CON_CODIGO1)"></button>
							</div>
							<div class="p-col-4">
								<label for="CON_CODIGO1NOMBRE"></label>
								<input class="frm" id="CON_CODIGO1NOMBRE" type="text" pInputText disabled
									[(ngModel)]="maecliente.CON_CODIGO1NOMBRE" autocomplete="off">
							</div>
							<div class="p-col-3"></div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -20px">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Ref.a Cont. Ant:</span>
							</div>
							<div class="p-col-2">
								<label for="CON_CODIGO2"></label>
								<input class="frm" id="CON_CODIGO2" type="text" pInputText
									[(ngModel)]="maecliente.CON_CODIGO2" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-1">
								<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									(click)="busquedaconcodigo('2',maecliente.CON_CODIGO2)"></button>
							</div>
							<div class="p-col-4">
								<label for="CON_CODIGO2NOMBRE"></label>
								<input class="frm" id="CON_CODIGO2NOMBRE" type="text" pInputText disabled
									[(ngModel)]="maecliente.CON_CODIGO2NOMBRE" autocomplete="off">
							</div>
							<div class="p-col-3"></div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -20px">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Ref. Cont. Descuento:</span>
							</div>
							<div class="p-col-2">
								<label for="CON_CODIGO3"></label>
								<input class="frm" id="CON_CODIGO3" type="text" pInputText
									[(ngModel)]="maecliente.CON_CODIGO3" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-1">
								<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									(click)="busquedaconcodigo('3',maecliente.CON_CODIGO3)"></button>
							</div>
							<div class="p-col-4">
								<label for="CON_CODIGO3NOMBRE"></label>
								<input class="frm" id="CON_CODIGO3NOMBRE" type="text" pInputText disabled
									[(ngModel)]="maecliente.CON_CODIGO3NOMBRE" autocomplete="off">
							</div>
							<div class="p-col-3"></div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -20px">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Centro de Costos:</span>
							</div>
							<div class="p-col-2">
								<label for="CEN_CODIGO"></label>
								<input class="frm" id="CEN_CODIGO" type="text" pInputText
									[(ngModel)]="maecliente.CEN_CODIGO" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-1">
								<button type="button"
									style="background-image: url(../../../assets/images/iconos/buscar.png);"
									(click)="busquedacencodigo(maecliente.CEN_CODIGO)"></button>
							</div>
							<div class="p-col-4">
								<label for="CEN_CODIGONOMBRE"></label>
								<input class="frm" id="CEN_CODIGONOMBRE" type="text" pInputText disabled
									[(ngModel)]="maecliente.CEN_CODIGONOMBRE" autocomplete="off">
							</div>
							<div class="p-col-3"></div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -20px">
						<div class="p-grid">
							<div class="p-col-2">
								<span>Local:</span>
							</div>
							<div class="p-col-6">
								<label for="CLI_LOCAL"></label>
								<input class="frm" id="CLI_LOCAL" type="text" pInputText
									[(ngModel)]="maecliente.CLI_LOCAL" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-2">
								<span>Placa:</span>
							</div>
							<div class="p-col-2">
								<label for="CLI_PLACA"></label>
								<input class="frm" id="CLI_PLACA" type="text" pInputText
									[(ngModel)]="maecliente.CLI_PLACA" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-8">
						<div class="divgrups">
							<div class="p-grid">
								<div class="p-col-2">
									<span>AC-20:</span>
								</div>
								<div class="p-col-2">
									<label for="CLI_PRECIOAC20"></label>
									<input class="frm" id="CLI_PRECIOAC20" type="text" pInputText
										[(ngModel)]="maecliente.CLI_PRECIOAC20" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-2"></div>
								<div class="p-col-2">
									<span>RC-250:</span>
								</div>
								<div class="p-col-2">
									<label for="CLI_PRECIORC250"></label>
									<input class="frm" id="CLI_PRECIORC250" type="text" pInputText
										[(ngModel)]="maecliente.CLI_PRECIORC250" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()" autocomplete="off">
								</div>
								<div class="p-col-2"></div>
							</div>
						</div>
					</div>
					<div class="p-col-4">
						<div class="p-grid">
							<div class="p-col-4">
								<span>N° Cuotas:</span>
							</div>
							<div class="p-col-8">
								<label for="CLI_NUMEROCUOTAS"></label>
								<input class="frm" id="CLI_NUMEROCUOTAS" type="text" pInputText
									[(ngModel)]="maecliente.CLI_NUMEROCUOTAS" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
							<div class="p-col-12" style="margin-top: -10px">
								<div class="p-grid">
									<div class="p-col-4">
										<span>Incremento:</span>
									</div>
									<div class="p-col-8">
										<label for="CLI_INCREMENTO"></label>
										<input class="frm" id="CLI_INCREMENTO" type="text" pInputText
											[(ngModel)]="maecliente.CLI_INCREMENTO" (keydown.enter)="setFocus($event)"
											(keydown)="botonesmodificar()" autocomplete="off">
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px">
						<div class="p-grid">
							<div class="p-col-3" hidden="true">
								<div class="p-grid">
									<div class="p-col-1">
										<p-checkbox id="chkconregmic" name="chkconregmic" value="" label=""
											binary="{{chkconregmic}}" [(ngModel)]="chkconregmic"
											(click)="botonesmodificar()" (keydown.enter)="setFocus($event)">
										</p-checkbox>
									</div>
									<div class="p-col-10">
										<span>Contribuyente régimen Microempresas</span>
									</div>
								</div>
							</div>
							<div class="p-col-3">
								<div class="p-grid">
									<div class="p-col-1">
										<p-checkbox id="chkconregrimpe" name="chkconregrimpe" value="" label=""
											binary="{{chkconregrimpe}}" [(ngModel)]="chkconregrimpe"
											(click)="botonesmodificar()" (keydown.enter)="setFocus($event)">
										</p-checkbox>
									</div>
									<div class="p-col-10">
										<span>Régimen RIMPE</span>
									</div>
								</div>
							</div>
							<div class="p-col-3">
								<div class="p-grid">
									<div class="p-col-1">
										<p-checkbox id="chkagenteret" name="chkagenteret" value="" label=""
											binary="{{chkagenteret}}" [(ngModel)]="chkagenteret"
											(click)="botonesmodificar()" (keydown.enter)="setFocus($event)">
										</p-checkbox>
									</div>
									<div class="p-col-10">
										<span>Agente de retención</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</p-scrollPanel>
	</p-tabPanel>
	<p-tabPanel header="Estado de Cuenta" [disabled]="(botonNuevo && index<2) || (botonNuevo && index === 6)">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Cliente:</span>
				</div>
				<div class="p-col-6">
					<span>{{maecliente.CLI_CODIGO + ' - ' + maecliente.CLI_NOMBRE}}</span>
				</div>
				<div class="p-col-4">
				</div>
			</div>
		</div>
		<app-aggridsaci [width]="" [height]="largoestcta" [enableSorting]="true" [enableFilter]="true"
			[rowData]="cxctmptrnresumen" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsCxctmptrnresumen"
			[defaultColDef]="defaultColDefCxctmptrnresumen" [rowStyle]="rowStyleEC" [botones]="false"
			[singleClickEdit]="false"></app-aggridsaci>
	</p-tabPanel>
	<p-tabPanel header="Saldos" [disabled]="(botonNuevo && index<2) || (botonNuevo && index === 6)">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Cliente:</span>
				</div>
				<div class="p-col-6">
					<span>{{maecliente.CLI_CODIGO + ' - ' + maecliente.CLI_NOMBRE}}</span>
				</div>
				<div class="p-col-4">
				</div>
			</div>
		</div>
		<app-aggridsaci [width]="" [height]="largoestcta" [enableSorting]="true" [enableFilter]="true"
			[rowData]="cxctrnresumen" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsCxctrnresumen"
			[defaultColDef]="defaultColDefCxctrnresumen" [rowStyle]="rowStyleSaldo" [botones]="false"
			[singleClickEdit]="false"></app-aggridsaci>
	</p-tabPanel>
	<p-tabPanel header="Comentarios" [disabled]="(botonNuevo && index<2) || (botonNuevo && index === 6)">
		<label for="CLI_COMENTARIOS"></label>
		<textarea style="width: 100%; height: 275px" id="CLI_COMENTARIOS" type="text" pInputTextarea
			[(ngModel)]="maecliente.CLI_COMENTARIOS" (keydown)="botonesmodificar()" autocomplete="off"></textarea>
	</p-tabPanel>
	<p-tabPanel header="Servicios de Pedidos" [disabled]="(botonNuevo && index<2) || (botonNuevo && index === 6)">
		<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="true"
			[rowData]="cliService.venservicioped[indicador]" [frameworkComponents]="frameworkComponents"
			[animateRows]="true" [rowSelection]="rowSelection" [columnDefs]="columnDefServicioPed"
			[defaultColDef]="defaultColDefServicioPed" [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true"
			(cambios)="botonesmodificardet()" (selected)="seleccionvenserped($event)">
		</app-aggridsaci>
	</p-tabPanel>
	<p-tabPanel header="Equipos" [disabled]="botonNuevo && index<2">
		<app-tecencmant [auxiliar]="auxiliarequipo" [auxiliarsenal]="auxiliarsenalequipo" [maestrocliente]="maecliente"
			[recibosenal]="enviarsenalequipo" (cambiarbotones)="cambiarbotones($event)">
		</app-tecencmant>
	</p-tabPanel>
	<p-tabPanel header="Estadísticas" [disabled]="disabledEstadisticas">
		<div class="p-grid">
			<!--LINEA 1-->
			<div class="p-col-6">
				<div class="p-grid">
					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-4">
								<button pButton (click)="mostrarEstadisticas()"
									style="background-image: url(../../../assets/images/iconos/cogwheel.png); width: 40px; height: 40px; background-color: transparent;"
									title="Generar estadísticas">
								</button>
							</div>
							<div class="p-col-4">
								<span style="font-size: 16px; font-weight: bold; text-align: center">VENTAS POR
									MESES</span>
							</div>
							<div class="p-col-4">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 20px;">
						<p-chart [options]="options" type="bar" [data]="dataVentasAcum" height="415px"></p-chart>
					</div>
				</div>
			</div>
			<div class="p-col-6">
				<div class="p-grid">
					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-3">
							</div>
							<div class="p-col-6">
								<span style="font-size: 16px; font-weight: bold;">
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									PRODUCTOS MÁS VENDIDOS</span>
							</div>
							<div class="p-col-3">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 10px;">
						<div class="p-grid">
							<div class="p-col-3">
							</div>
							<div class="p-col-2">
								<span>Ordenar por:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-radioButton name="orden" value="cantidad" inputId="orden1"
									(onClick)="orderByTopProd('cantidad')"></p-radioButton>
								<label> Cantidad </label>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-radioButton name="orden" value="precio" inputId="orden2"
									(onClick)="orderByTopProd('total')"></p-radioButton>
								<label> Total </label>
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px;">
						<app-aggridsaci [width]="" [height]="'390'" [enableSorting]="true" [enableFilter]="true"
							[rowData]="detTopProductos" [frameworkComponents]="frameworkComponents" [animateRows]="true"
							[rowSelection]="rowSelection" [columnDefs]="columnDefsTopProductos"
							[defaultColDef]="defaultColTopProductos" [rowStyle]="rowStyleTopProd" [botones]="false"
							[singleClickEdit]="false">
						</app-aggridsaci>
					</div>
				</div>
			</div>
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-12">
					</div>
				</div>
			</div>
			<div class="p-col-6">
				<div class="p-grid">

					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-4">
							</div>
							<div class="p-col-2">
								<span style="font-size: 16px; font-weight: bold; text-align: center">DOCUMENTOS</span>
							</div>
							<div class="p-col-5">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold;">Documentos por Cobrar:</span>
							</div>
							<div class="p-col-2">
								<input id="TOTAL_DXC" type="text" pInputText readonly style="text-align: right"
									[(ngModel)]="totalDocXCobrar" autocomplete="off">
							</div>
							<div class="p-col-2">
								<span>{{totalDocXCobrarPorc}} %</span>
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold;">Documentos Vencidos:</span>
							</div>
							<div class="p-col-2">
								<input id="TOTAL_DOCVENCIDOS" type="text" pInputText readonly style="text-align: right"
									[(ngModel)]="totalDocVencidos" autocomplete="off">
							</div>
							<div class="p-col-2">
								<span>{{totalDocVencidosPorc}} %</span>
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold;">Cheques Posfechados:</span>
							</div>
							<div class="p-col-2">
								<input id="TOTAL_CHPOSFECHADOS" type="text" pInputText readonly
									style="text-align: right" [(ngModel)]="totalChPosfechados" autocomplete="off">
							</div>
							<div class="p-col-2">
								<span>{{totalChPosfechadosPorc}} %</span>
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold; float: right">Total:
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
							</div>
							<div class="p-col-2">
								<input id="TOTAL_DOCUMENTOS" type="text" pInputText readonly
									style="text-align: right; font-weight: bold" [(ngModel)]="totalDocumentos"
									autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold;">Cheques Protestados:</span>
							</div>
							<div class="p-col-1">
								<input id="TOTAL_CHPROTESTADOS" type="text" pInputText readonly
									style="text-align: right" [(ngModel)]="totalChProtestados" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold;">Pedidos Pendientes:</span>
							</div>
							<div class="p-col-1">
								<input id="TOTAL_PEDIDOPEND" type="text" pInputText readonly style="text-align: right"
									[(ngModel)]="totalPedPendientes" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold;">Pedidos Satisfechos:</span>
							</div>
							<div class="p-col-1">
								<input id="TOTAL_PEDSATISF" type="text" pInputText readonly style="text-align: right"
									[(ngModel)]="totalPedSatisfechos" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold; ">Primera Factura: </span>
							</div>
							<div class="p-col-2">
								<input id="FechaPrimeraFact" type="text" pInputText readonly style="text-align: right"
									[(ngModel)]="fechaPrimeraFact" autocomplete="off">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="p-col-6">
				<div class="p-grid">
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-4">
							</div>
							<div class="p-col-4">
								<span style="font-size: 16px; font-weight: bold; text-align: center">DETALLE DE
									FACTURAS</span>
							</div>
							<div class="p-col-4">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 10px;">
						<div class="p-grid">
							<div class="p-col-3">
							</div>
							<div class="p-col-2">
								<span>Ordenar por:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-radioButton name="orden" value="fecha" inputId="orden1"
									(onClick)="orderByDetFacturas('fecha')"></p-radioButton>
								<label> Fecha </label>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-radioButton name="orden" value="producto" inputId="orden2"
									(onClick)="orderByDetFacturas('producto')"></p-radioButton>
								<label> Producto </label>
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<app-aggridsaci [width]="" [height]="'390'" [enableSorting]="true" [enableFilter]="true"
							[rowData]="detFacturas" [frameworkComponents]="frameworkComponents" [animateRows]="true"
							[rowSelection]="rowSelection" [columnDefs]="columnDefsDetFacturas"
							[defaultColDef]="defaultColDetFacturas" [rowStyle]="rowStyleDetFacts" [botones]="false"
							[singleClickEdit]="false">
						</app-aggridsaci>
					</div>
				</div>
			</div>
		</div>
	</p-tabPanel>
</p-tabView>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusqueda" [transitionOptions]="'0ms'" [modal]="false"
	[style]="{width: '70vw', height: '70vh'} " [focusOnShow]="true">
	<app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
		(opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda>
</p-dialog>


<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="false"
	[style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla"
		[where]="where"
		[busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'VENMAECLIENTE'"
		[txtNumComprobante]="maecliente.CLI_CODIGO" (signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>

<p-dialog header="Clave Autorización" [(visible)]="displayDialogAutorizacion" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" (onHide)="onHideAutorizacion()" [style]="{width: '40vw', height: 'auto'} ">
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="autorizacion"></p-toast>
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							(keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
</div>