<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="invhisserie"></p-toast>
<div class="p-grid" style="width: 98%">
    <div class="p-col-12">
        <app-invmaebarra (signal)="manejarSenales($event)" [botonNuevo]="true" [botonGuardar]="true"
            [botonBorrar]="true" [botonRegresar]="false" [barraBotonesDesplaz]="true"
            [barraBotones3]="true" [barraBotonesAsiCon]="true" [barraBotones5]="true"
             [barraCartera]="false"
            ></app-invmaebarra>
    </div>
</div>
<div class="divgrups">
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Artículo:</span>
                </div>
                <div class="p-col-2">
                    <input pInputText type="text" size="14"  id="txtartcodigo"
                        [(ngModel)]="txtartcodigo" autocomplete="off">
                </div>
                <div class="p-col-1">
                    <button class="littlebuttons"
                        style="background-image: url(../../../assets/images/iconos/buscar.png);"
                        (click)="busquedaarticulo(txtartcodigo)"
                    ></button>
                </div>
                <div class="p-col-3">
                    <label for="GRUP_NOMBRE"></label>
                    <input class="frm" id="txtnomarticulo" type="text" pInputText disabled
                        style="color: blue;"
                        [(ngModel)]="txtnomarticulo"
                        autocomplete="off">
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Serie:</span>
                </div>
                <div class="p-col-2">
                    <input pInputText type="text" size="14"  id="txtserie"
                        [(ngModel)]="txtserie" autocomplete="off">
                </div>
                <div class="p-col-1">
                    <button class="littlebuttons"
                        style="background-image: url(../../../assets/images/iconos/buscar.png);"
                        (click)="busquedaserie(txtserie)"
                    ></button>
                </div>
                <div class="p-col-3">                   
                    <button class="sincss" pButton (click)="revisarhistoria()" >Revisar Historia
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="divg" style="height: 450px">
    <app-aggridsaci [width]=""
                    [height]="-1000"
                    [enableSorting]="true"
                    [enableFilter]="true"
                    [rowData]="dethistorialserial"
                    [frameworkComponents]="frameworkComponents"
                    [animateRows]="true"
                    [rowSelection]="rowSelection"
                    [columnDefs]="columnDefshistorial"
                    [defaultColDef]="defaultColDefhisserial"
                    [rowStyle]="rowStyle"
                    [singleClickEdit]="false"
                    [botones]="false"
                    
    >
    </app-aggridsaci>
</div>
<p-dialog header="Búsqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
        [busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)"
        [consulta]="consulta"></app-busquedadlg>
</p-dialog>