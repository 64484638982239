import { Component, OnInit, ViewChild } from '@angular/core';
import { CellEditorPrueba } from 'src/app/advantage/config/cell-edit-prueba';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { NomutilidadesService } from '../nomservicios/nomutilidades.service';
import { Usuario } from 'src/app/usuario';
import { DatePipe } from '@angular/common';
import { SelectItem, ConfirmationService, MessageService } from 'primeng';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { PermisosService } from 'src/app/advantage/advantageservices/permisos.service';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { NomUtilidades } from '../nominterfaces/nomutilidades';
import { SegMaePermiso } from 'src/app/advantage/advantageinterfaces/segmaepermiso';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Component({
  selector: 'app-nomutilidades',
  templateUrl: './nomutilidades.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class NomutilidadesComponent implements OnInit {
  
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;

  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  btnBuscar = false;

  lblNumLiqUti = '';
  lblEstado = '';
  txtusuarioModulo = 'admin';
  txtfecha = '';
  txthora = '';
  txtCABOTROSIEG_ANIO = '';
  txtCABIMPURENT_DESCRIPCION = '';
  boolBtnAceptar = false;
  boolTxtAnio = false;
  boolTxtImporte = false;
  boolTxtDescripcion = false;
  
  txtTRNCOBRO_importe = '';
  cmbFiltro: any[] = [];
  strCmbFiltro: any;
  txtDatoBuscar = '';
  choosedColorG: string;

  // Tabla
  largo: string;
  public frameworkComponents;
  rowStyle;
  defaultNomUtilidades;
  rowSelection = 'multiple';

  btnNuevoGrid: boolean;
  btnGuardarGrid: boolean;
  btnBorrarGrid: boolean;
  btnCancelarGrid: boolean;

  str_TiposFormaPago: String;

  opcion: string;
  displayDialogBusquedaFast: boolean;
  displayDialogBusquedaMae: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  condicion: string;
  arregloCons: any[];
  arregloBus: any[];
  where = '';

  colorColumna = '#91F0F3';

  // Permisos
  permisos: SegMaePermiso;
  nuevo = false;
  spin = false;

  claveDialog: boolean;
  claveAuth: string;
  accionClave: string;

  // Archivo de texto
  cmbFormato: any[] = [];
  selectFormato: any;
  
  cargarCombo= false;

  separacion = ',';
  btnVisto = true;
  nombreTxt = '';
  displayDialogArchPlano = false;

  detalleSeleccionado: NomUtilidades = {};

  displayDialogDocumentos = false;
  
  control=0;

  columnDefsNomUtilidades = [
    {
      headerName: 'Código Empleado', field: 'EMP_CODIGO', editable: false, width: 110, cellStyle: { background: this.colorColumna}, pinned: 'left'
    },
    {
      headerName: 'Cédula', field: 'EMP_RUCIDE', editable: false, width: 120, cellStyle: { background: this.colorColumna}, pinned: 'left'
    },
    {
      headerName: 'Secuencia', field: 'NOM_SECUENCIA', editable: false, width: 100, cellStyle: { background: this.colorColumna}, pinned: 'left'
    },
    {
      headerName: 'Nombres', field: 'EMP_NOMBRE', editable: false, width: 200, cellStyle: { background: this.colorColumna}, pinned: 'left'
    },
    {
      headerName: 'Apellidos', field: 'EMP_APELLIDO', editable: false, pinned: 'left', width: 200, cellStyle: { background: this.colorColumna}
    },
    {
      headerName: 'Fecha Ingreso', field: 'EMP_FECINGRESO', editable: false, width: 125, cellStyle: { background: this.colorColumna}
    },
    {
      headerName: 'Fecha Salida', field: 'EMP_FECSALIDA', editable: false, width: 125, cellStyle: { background: this.colorColumna}
    },
    {
      headerName: 'Género', field: 'EMP_SEXO', editable: false, width: 80, cellStyle: { background: this.colorColumna}
    },
    {
      headerName: 'Ocupación', field: 'EMP_CARGO', editable: false, width: 120, cellStyle: { background: this.colorColumna}
    },
    {
      headerName: 'Cargas Familiares', field: 'NOM_NUMCARFAM', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right'}, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        }
    },
    {
      headerName: 'Días laborados(360 días equivalen a un año)', field: 'NOM_DIASLABORADOS', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right'}, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        }
    },
    {
      headerName: 'Utilidad año actual', field: 'NOM_UTILIDAD', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right', background: this.colorColumna}, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        }
    },
    {
      headerName: 'Descuento de Utilidad', field: 'NOM_DESCPROV', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right'}, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        }
    },
    {
      headerName: 'Anticipo de Utilidad', field: 'NOM_ANTICIPO_UTIL', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right', background: this.colorColumna}, editable: false,
    },
    {
      headerName: 'Retención Judicial', field: 'NOM_RETENCION_JUD', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right'}, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        }
    },
    {
      headerName: 'Impuesto Retención', field: 'NOM_IMPUESTO_RET', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right'}, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        }
    },
    {
      headerName: 'Neto de Pagar Utilidades', field: 'NOM_NETOAPAGAR', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right', background: this.colorColumna}, editable: false,
      
    },
    {
      headerName: 'Tipo de Pago Utilidad(Pago Directo=P Depósito MDT=D Para Declaraciones y Déposito Empresa=E para Declaraciones Acreditación en Cuenta=A Retención Pago)', 
      field: 'NOM_TIPOPAGOUTIL', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Jornada parcial permanente(ponga una x si el trabajador tiene una jornada parcial permanente)',
      field: 'NOM_JORNADAPARCIALPER', cellEditor: 'cellPrueba', width: 100, cellStyle: {background: this.colorColumna}, editable: false,
      
    },
    {
      headerName: 'Determine en horas la jornada parcial permanente semanal estípulado en el contrato',
      field: 'NOM_HORAS_JORPARPERSEM', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right',background: this.colorColumna}, editable: false,
      
    },
    {
      headerName: 'Discapacitados(ponga una x si el trabajador tiene discapacidad)',
      field: 'NOM_DISCAPACIDAD', cellEditor: 'cellPrueba', width: 100, cellStyle: {background: this.colorColumna}, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        }
    },
    {
      headerName: 'RUC de la empresa complementaria o de unificación',
      field: 'NOM_RUCEMPCOM', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
          }
        }
    },
    {
      headerName: 'Decimo tercer valor proporcional al tiempo laborado',
      field: 'NOM_DECTERCERO', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right',background: this.colorColumna}, editable: false,
     
    },
    {
      headerName: 'Decimo cuarto valor proporcional al tiempo laborado',
      field: 'NOM_DECCUARTO', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right',background: this.colorColumna}, editable: false,
    },
    {
      headerName: 'Participación de utilidades año anterior',
      field: 'NOM_PARTICIPACION_UTI', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right'}, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        }
    },
    {
      headerName: 'Salarios percibidos',
      field: 'NOM_SALARIOS_PER', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right', background: this.colorColumna}, editable: false,
    },
    {
      headerName: 'Fondos de reserva',
      field: 'NOM_FONDOS_RESERVA', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right', background: this.colorColumna}, editable: false,
      
    },
    {
      headerName: 'Comisiones año',
      field: 'NOM_COMISIONES', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right', background: this.colorColumna}, editable: false,
    
    },
    {
      headerName: 'Beneficios adicionales en efectivo',
      field: 'NOM_BENEFICIOS_ADIEFE', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right', background: this.colorColumna}, editable: false,
    },
    {
      headerName: 'Valor Salario Digno',
      field: 'NOM_SALDIG', cellEditor: 'cellPrueba', width: 100, cellStyle: {textAlign: 'right'}, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: false,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true,
            int: true
          }
        }
    },
    {
      headerName: 'Tipo de Pago Salario Digno(Pago Directo=P Déposito MDT=D Para declaraciones de Depósito Empresa=E para declaraciones)',
      field: 'NOM_TIPOPAGO_SALDIG', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Código de Forma de Pago, Cheque, Depósito',
      field: 'NOM_FORMPAGOCOD', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Descripción de Forma de Pago',
      field: 'NOM_FORMPAGODESC', width: 100, editable: false, cellStyle: {background: this.colorColumna},
    
    },
    {
      headerName: 'Código del banco',
      field: 'NOM_BANCPAGOCOD', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Nombre del Banco',
      field: 'NOM_BANCPAGODESC', width: 100, editable: false, cellStyle: {background: this.colorColumna}
    
    },
    {
      headerName: 'Código del tipo de cuenta',
      field: 'NOM_TIPOCUENCOD', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Nombre del tipo de cuenta',
      field: 'NOM_TIPOCUENDESC', width: 100, editable: false, cellStyle: {background: this.colorColumna}
    },
    {
      headerName: 'Número de Cuenta',
      field: 'NOM_NUMECUEN', cellEditor: 'cellPrueba', width: 100, editable: (params) => {
        const boolEdit = this.isEdit(params.data);
        return boolEdit;
      },
      cellEditorParams:
        {
          values: {
            mayusculas: true,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: false,
            fecha: false,
            tamanocelda: 32,
          }
        }
    },

  ]

  constructor(public utilidadesService: NomutilidadesService, public usuario: Usuario,
    private datePipe: DatePipe, private busquedaSer: InvbusquedaService,
    private confirmationService: ConfirmationService,
    public init: NuevoComponentService, private auditoriaGralService: AuditoriagralService,
    private permisosService: PermisosService, private message: MessageService,
    private errorService: ErroresBaseDatosService,
    private confIniciales: ConfInicialesService) { 
    this.agTable();
    this.utilidadesService.detalleUtilidades = [];
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
   
    
    this.defaultNomUtilidades = {
      editable: true,
      width: 100,
      objeto: 'nomutilidades',
      resizable: true
    };
  }

  ngOnInit() {
    this.largo = '350';
    this.choosedColorG = '#39E5EA';
    this.txtTRNCOBRO_importe = Number(0).toFixed(2);
    this.txtusuarioModulo = this.usuario.identificacion;
    this.txtfecha = this.datePipe.transform(new Date() , 'dd/MM/yyyy');
    this.txthora = this.datePipe.transform(new Date(), 'HH:mm:ss');
    this.lblNumLiqUti = '';
    this.habilitarBotonesBarra(false, true, false);
    this.habilitarBotonesDet(true, false, false);

    this.cmbFiltro = [{opcion: 0, filtro: ''},{opcion: 1, filtro: 'Código Empleado'},{opcion: 2, filtro: 'Cédula'},
    {opcion: 3, filtro: 'Nombres'}, {opcion: 4, filtro: 'Apellidos'}, {opcion: 5, filtro: 'Fecha Ingreso'}];
    this.strCmbFiltro = this.cmbFiltro[0];
    this.getPermisos();
  }

  async getPermisos() {
    try {
      const data = await this.permisosService.getPermisosFrm(this.init.tabs[this.init.tabindex - 1].component);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisos = data[0];
        } else {
          this.permisos = null;
        }
      } else {
        this.permisos = null;
      }
    } catch (e) {
      this.permisos = null;
      this.mensajeAlerta('error', 'Error', 'No se pudo obtener los permisos del formulario');
    }
  }

  async manejarSenalesArchPlan(valor) {
    if (valor === 'Salir') {
      this.displayDialogArchPlano = false;
      this.cmbFormato = [];
      this.selectFormato = {MAEPROEXPLIQUTIL_CODIGO: '', MAEPROEXPLIQUTIL_NOMBRE: ''};
      this.nombreTxt = '';
    }
    if (valor === 'Visto') {
      try {
        const p = await this.utilidadesService.erProcesoArchivo(this.selectFormato.MAEPROEXPLIQUTIL_CODIGO);
        console.log(p);
        if( p === null ){

        } else{
          await this.utilidadesService.generarArchivo(p[0].MAEPROEXPLIQUTIL_PROCESO, this.lblNumLiqUti);
        }
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlertaArch('error', 'Error', 'Ha Ocurrido Un Error En La Transacción ' + err.error);
        return;
      }

      await this.funGenArchTexto();
      this.mensajeAlertaArch('success', 'Proceso', 'Proceso terminado ');


    }
  }

  async funGenArchTexto() {
    let cadena = '';
    try {
      const lineas = await this.utilidadesService.funGenArchTexto(1);
      console.log(lineas);
      if( lineas !== null ){
          for( let linea of lineas ){
            if (linea.TMPLIBESOARCHTEXT_TEXTO !== null) {
              cadena = cadena + linea.TMPLIBESOARCHTEXT_TEXTO + '\r\n';
            }
          }

        this.download(this.nombreTxt, cadena);

      }else {
        this.mensajeAlertaArch('error', 'Error', 'No se ha encontrado información para generar el archivo');
      }
    } catch (err) {
      let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlertaArch('error', 'Error', 'Ha Ocurrido Un Error En La Transacción');
    }
  }

  download(filename, text) {
    if( filename === '' ){
      this.mensajeAlertaArch('error', 'Error', 'Ingrese un nombre de archivo');
    } else {

      const blob = new Blob([text], {type: 'text/plain'});
      const element = document.createElement('a');
      element.setAttribute('download', filename);
      element.setAttribute('href', window.URL.createObjectURL(blob));
      element.click();
    }
  }

  async manejarSenales(valor) {
    
    if (valor === 'Nuevo') {
      this.nuevo = true;
      this.nuevoRegistro();
    }
    if (valor === 'Guardar') {
      if(this.control === 0){
        this.control=1;
        this.btnGuardar=true;
        this.guardarRegistro();
      }
      
    }
    if (valor === 'Borrar') {
      this.eliminarRegistro();
    }
    if(valor === 'Cancelar') {
      if (Number(this.lblNumLiqUti) > 0 ) {
        this.llenarCampos(this.lblNumLiqUti);
      } {
        this.nuevoRegistro();
      }
      this.habilitarBotonesBarra(false, true, false);
    }
    if (valor === 'Visto') {
      if (this.btnGuardar === false) {
        this.mensajeAlerta('info', 'Información', 'Por favor primero guarde el documento');
        return;
      }

      if (Number(this.lblNumLiqUti) === 0) {
        return;
      }

      if (String(this.lblEstado).trim() === 'CONFIRMADO') {
        this.confirmationService.confirm({
          message: '¿ Está seguro que necesita reversar la confirmación del documento ?',
          header: 'Utilidades',
          icon: 'pi pi-exclamation-triangle',
          key: 'nomutilidadesConf',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            this.reversarUtilidad();
          },
          reject: () => {
            return;
          }
        });
        return; 
      }

      if (Number(this.lblNumLiqUti) === 0) {
        return;
      }

      this.accionClave = 'Confirmar';
      this.claveDialog = true;
 
    }
    if (valor === 'Buscar') {
      this.buscarRegistro();
    }
    if (valor === 'procesar') {
      if(this.btnGuardar === false ) {
        this.mensajeAlerta('info', 'Información', 'Por favor primero guarde el documento');
        return;
      }

      if (Number(this.lblNumLiqUti) !== 0 && String(this.lblEstado).trim() === '') {
        this.GenerarLiquidacion();
      }
    }
    if (valor === 'generarTxt') {
      if (Number(this.lblNumLiqUti) === 0) {
        return;
      }
      if (String(this.lblEstado) !== 'CONFIRMADO') {
        return;
      }
      await this.FunListaMaestroCargas();
      this.displayDialogArchPlano = true;

    }
    if (valor === 'Borrar') {
      this.eliminarRegistro();
    }

    if (valor === 'facturasAbonadas') {
      this.buscarRegistroDetalle();
    }

    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }

    if (valor === 'Almacenardoc') {
      if (this.lblNumLiqUti !== '') {
        const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
        if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
          this.displayDialogDocumentos = true;
        } else {
          this.message.add({
            key: 'nomutilidades',
            severity: 'info',
            summary: 'Información',
            detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
          });
        }
        
      }
    }

  }

  async reversarUtilidad() {
    this.accionClave = 'Reversar';
    this.claveDialog = true;
    
  }

  async FunListaMaestroCargas() {

    try {
      const rs = await this.utilidadesService.FunListaMaestroCargas();
      this.cmbFormato = [];
      this.cmbFormato.push({MAEPROEXPLIQUTIL_CODIGO: '', MAEPROEXPLIQUTIL_NOMBRE: ''});
      for (const item of rs) {
        this.cmbFormato.push({MAEPROEXPLIQUTIL_CODIGO: item.MAEPROEXPLIQUTIL_CODIGO, MAEPROEXPLIQUTIL_NOMBRE: item.MAEPROEXPLIQUTIL_NOMBRE});
      }
      this.selectFormato = this.cmbFormato[0];
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', err.error);
    }

  }

  async comprobarClave() {
    let claveReal;
    const clave = await this.utilidadesService.obtenerClave(this.accionClave);
    if (Object.keys(clave).length > 0) {
      claveReal = clave[0].AUTORIZACION_CLAVE;
      if (claveReal === this.claveAuth) {
        this.claveDialog = false;

        if (this.accionClave === 'Reversar') {
          const fecha = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
          await this.utilidadesService.actualizarEstadoRever(fecha, Number(this.lblNumLiqUti));
          this.lblEstado = '';
          let str_dato = 'COD:' + this.lblNumLiqUti;
          str_dato = str_dato + '-AÑO:'+ this.txtCABOTROSIEG_ANIO;
          str_dato = str_dato + '-EST:\'PENDIENTE\'';
          this.auditoriaGralService.registrarAuditoria('NOM_ENCUTILIDAD', str_dato, 'A',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
          this.ActivarBoton('C');
          this.boolTxtDescripcion = false;
          this.boolTxtImporte = false;
        }

        if (this.accionClave === 'Confirmar') {
          const fecha = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
          await this.utilidadesService.actualizarEstadoConfirm(fecha, Number(this.lblNumLiqUti));
          this.lblEstado = 'CONFIRMADO';
          let str_dato = 'COD:' + this.lblNumLiqUti;
          str_dato = str_dato + '-AÑO:'+ this.txtCABOTROSIEG_ANIO;
          str_dato = str_dato + '-EST:\'CNFRM\'';
          this.auditoriaGralService.registrarAuditoria('NOM_ENCUTILIDAD', str_dato, 'A',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});

          this.ActivarBoton('NO');
        }
        
        this.claveAuth = '';
    } else if (clave !== this.claveAuth) {
          this.claveDialog = false;
          this.mensajeAlerta('error', 'Error', 'La clave está incorrecta');
        }
    } else {
        this.claveDialog = false;
        this.mensajeAlerta('error', 'Error', 'La clave está incorrecta');
      }
  }

  async GenerarLiquidacion() {
    const parametrosSp: any[] = [];
    parametrosSp[0] = '01';
    parametrosSp[1] = Number(this.lblNumLiqUti);

    try {
      await this.utilidadesService.liquidarUtilidades(parametrosSp);
      this.mensajeAlerta('success', 'Utilidades', 'Proceso terminó exitosamente');
      this.llenarCampos(Number(this.lblNumLiqUti));
    } catch(err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', err.error);
    }
  }

  async guardarRegistro() {
    let codigo;
    let TipDet;
    let cantidad;
    let CtrlEliminaDet;
    let Identificacion;

    if(this.nuevo) {
      if (this.permisos !== null ) {
        if (this.permisos.PERTODO === 0 ) {
          if (this.permisos.PERINSERCION === 0 ) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ', no tiene permiso de inserción. Consulte con el Administrador del Sistema.');
              this.control=0;
              this.btnGuardar=false;
            return;
          }
        }
      }
    }
    if(!this.nuevo) {
      if (this.permisos !== null ) {
        if (this.permisos.PERTODO === 0 ) {
          if (this.permisos.PERACTUALIZACION === 0 ) {
            this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
              ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.');
              this.control=0;
              this.btnGuardar=false;
            return;
          }
        }
      }
    }

    // GRABA LA CABECERA
    if (this.txtCABOTROSIEG_ANIO === '') {
      this.mensajeAlerta('info', 'Información', 'Ingrese el año a procesar');
      this.control=0;
      this.btnGuardar=false;
      return;
    }

    if (String(this.txtCABIMPURENT_DESCRIPCION).trim() === '') {
      this.mensajeAlerta('info', 'Información', 'Ingrese la descripción por favor');
      this.control=0;
      this.btnGuardar=false;
      return;
    }

    this.spin = true;

    const parametrosSp:any[] = [];
    parametrosSp[0] = Number(this.lblNumLiqUti);
    parametrosSp[1] = '01';
    parametrosSp[2] = this.txtCABOTROSIEG_ANIO;
    parametrosSp[3] = this.txtCABIMPURENT_DESCRIPCION;
    parametrosSp[4] = Number(Number(this.txtTRNCOBRO_importe).toFixed(2));
    parametrosSp[5] = this.usuario.identificacion;
                
    try {
      const rs = await this.utilidadesService.guardarEncUtilidad(parametrosSp);
      console.log(rs);
      this.lblNumLiqUti = rs[0][':B1'];
    } catch(err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
      this.control=0;
      this.btnGuardar=false;
      return;
    }

    // GRABA EL DETALLE

    for (const item of this.utilidadesService.detalleUtilidades) {
      codigo = item.EMP_CODIGO;
      Identificacion = item.EMP_RUCIDE;
      if (codigo !== '' && Identificacion !== '') {
        const parametrosSp: any[] = [];
        parametrosSp[0] =  Number(this.lblNumLiqUti);
        parametrosSp[1] = String(item.EMP_CODIGO).trim();
        parametrosSp[2] = '01';
        parametrosSp[3] = item.NOM_SECUENCIA;
        parametrosSp[4] = Number(item.NOM_DIASLABORADOS);
        parametrosSp[5] = item.NOM_TIPOPAGOUTIL;
        parametrosSp[6] = item.NOM_SECUENCIA;
        parametrosSp[7] = Number(item.NOM_HORAS_JORPARPERSEM).toFixed(2);
        parametrosSp[8] = item.NOM_DISCAPACIDAD;
        parametrosSp[9] = item.NOM_RUCEMPCOM;
        parametrosSp[10] = Number(item.NOM_DECTERCERO).toFixed(2);
        parametrosSp[11] = Number(item.NOM_DECCUARTO).toFixed(2);
        parametrosSp[12] = Number(item.NOM_PARTICIPACION_UTI).toFixed(2);
        parametrosSp[13] = Number(item.NOM_SALARIOS_PER).toFixed(2);
        parametrosSp[14] = Number(item.NOM_FONDOS_RESERVA).toFixed(2);
        parametrosSp[15] = Number(item.NOM_COMISIONES).toFixed(2);
        parametrosSp[16] = Number(item.NOM_BENEFICIOS_ADIEFE).toFixed(2);
        parametrosSp[17] = Number(item.NOM_ANTICIPO_UTIL).toFixed(2);
        parametrosSp[18] = Number(item.NOM_RETENCION_JUD).toFixed(2);
        parametrosSp[19] = Number(item.NOM_IMPUESTO_RET).toFixed(2);
        parametrosSp[20] = item.NOM_SALDIG;
        parametrosSp[21] = item.NOM_TIPOPAGO_SALDIG;
        parametrosSp[22] = Number(item.NOM_UTILIDAD).toFixed(2);
        parametrosSp[23] = Number(item.NOM_DESCPROV).toFixed(2);
        parametrosSp[24] = item.NOM_FORMPAGOCOD;
        parametrosSp[25] = item.NOM_FORMPAGODESC;
        parametrosSp[26] = item.NOM_BANCPAGOCOD;
        parametrosSp[27] = item.NOM_BANCPAGODESC;
        parametrosSp[28] = item.NOM_TIPOCUENCOD;
        parametrosSp[29] = item.NOM_TIPOCUENDESC;
        parametrosSp[30] = item.NOM_NUMECUEN;
        parametrosSp[31] = Number(item.NOM_NETOAPAGAR);
        parametrosSp[32] = Number(item.NOM_NUMCARFAM);

        try {
          await this.utilidadesService.guardarDetUtilidad(parametrosSp);
        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', mensaje);
          this.spin = false;
          this.control=0;
          this.btnGuardar=false;
        }
      
      }
    }

    this.spin = false;
    this.ActivarBoton('G');

    this.boolTxtAnio = true;
    this.boolTxtDescripcion = true;
    this.boolBtnAceptar = true;
    
    this.mensajeAlerta('success', 'Utilidades', 'Proceso terminó exitosamente');
    this.control=0;
    this.habilitarBotonesBarra(false, true, false);
    
  }

  eliminarRegistro() {
    this.confirmationService.confirm({
      message: '¿ Está seguro que desea eliminar el registro ?',
      header: 'Cálculo de Utilidades',
      icon: 'pi pi-exclamation-triangle',
      key: 'nomutilidadesConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarUtilidad();
      },
      reject: () => {
      }
    });
  }

  async eliminarUtilidad() {

    const parametros: any[] = [];
    parametros[0] = Number(this.lblNumLiqUti);
    parametros[1] = '01';

    try {
      await this.utilidadesService.eliminarUtilidad(parametros);
      this.auditoriaGralService.registrarAuditoria('NOM_ENCUTILIDAD', this.lblNumLiqUti + '/' + this.txtTRNCOBRO_importe, 'E',
              this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {});
      this.mensajeAlerta('success', 'Eliminación', 'Registro eliminado exitosamente....!!!');
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
      return;
    }

    this.LimpiarDatos();
    this.habilitarBotonesBarra(false, true, false);

  }

  nuevoRegistro() {
    document.getElementById('divPrincipal').style.pointerEvents = 'all';
    this.habilitarBotonesDet(true, false, false);
    this.boolTxtAnio = false;
    this.boolTxtImporte = false;
    this.boolTxtDescripcion = false;
    this.boolBtnAceptar = false;
    this.control=0;
    this.LimpiarDatos();


    this.habilitarBotonesBarra(true, false, false);

  }

  LimpiarDatos() {
    this.txtCABOTROSIEG_ANIO  = '';
    this.txtCABIMPURENT_DESCRIPCION = '';
    this.utilidadesService.detalleUtilidades = [];
    this.lblNumLiqUti = '';
    this.txtTRNCOBRO_importe = Number(0).toFixed(2);
    this.lblEstado = '';
  }

  buscarRegistro() {
    console.log('buscar');
    this.opcion = 'BUSCAR';
    this.types = [
      {label: 'CÓDIGO', value: 'NOM_TRNUTICODIGO'},
      {label: 'AÑO', value: 'NOM_ANIO'},
      {label: 'DESCRIPCIÓN', value: 'NOM_DESCRIPCION'},
      {label: 'UTILIDAD', value: 'NOM_IMPORTE_UTILIDAD'}
    ];
    this.busquedacampos = ['', '', '',''];
    this.consulta='NOM_TRNUTICODIGO,NOM_ANIO,NOM_DESCRIPCION,NOM_IMPORTE_UTILIDAD';
    this.tabla = 'NOM_ENCUTILIDAD';
    this.where = '' ;
    this.busquedaSer.busquedaNomMaeEncUtilidad1().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.displayDialogBusquedaFast = true;
    });
  }

  async aceptar() {
    const p = await this.utilidadesService.existeUtilidad(this.txtCABOTROSIEG_ANIO);
      if (p[0].CONT !== 0) {
        this.mensajeAlerta('info', 'Información', 'La liquidación de la utilidad para este año ya existe');
        return;
      }

      if (this.txtCABOTROSIEG_ANIO === '' ) {
        this.mensajeAlerta('info', 'Información', 'Ingrese el año a procesar');
        return;
      }

      if (this.txtCABIMPURENT_DESCRIPCION === '' ) {
        this.mensajeAlerta('info', 'Información', 'Ingrese la descripción por favor');
        return;
      }


      this.LlenarCampos1(this.txtCABOTROSIEG_ANIO);
      this.boolBtnAceptar = true;

  }

  async LlenarCampos1(codigo) {
    this.spin = true;
    let strJordaParcial = '';
    const parametros: any[] = [];
    parametros[0] = String(codigo).trim();
    parametros[1] = '01';

    try {
      this.utilidadesService.detalleUtilidades =  [];
      const rs = await this.utilidadesService.listarInformacion(parametros);
      const detalle = [];
      if (rs !== null) {
        if (rs.length > 0) {
          let num = 0;
          rs.map(item => {
            item.LINEA = num++;
            item.EMP_CODIGO = item.EMP_CODIGO === null ? '': item.EMP_CODIGO;
            item.EMP_RUCIDE = item.EMP_RUCIDE === null ? '' : item.EMP_RUCIDE;
            item.NOM_SECUENCIA = 'A';
            item.EMP_NOMBRE = item.EMP_NOMBRE === null ? '' : item.EMP_NOMBRE;
            item.EMP_APELLIDO = item.EMP_APELLIDO === null ? '' : item.EMP_APELLIDO;
            if (item.EMP_FECINGRESO !== null) {
              item.EMP_FECINGRESO = this.datePipe.transform(item.EMP_FECINGRESO, 'dd/MM/yyyy');
            }
            if (item.EMP_FECSALIDA !== null) {
              item.EMP_FECSALIDA = this.datePipe.transform(item.EMP_FECSALIDA, 'dd/MM/yyyy');
            }

            item.EMP_SEXO = item.EMP_SEXO === null ? '' : item.EMP_SEXO;
            item.EMP_CARGO = item.EMP_CARGO === null ? '' : item.EMP_CARGO;
            item.NOM_NUMCARFAM =  item.EMP_NUMCARGAS === null ? Number(0).toFixed(2) : Number(item.EMP_NUMCARGAS).toFixed(2);
            item.NOM_DIASLABORADOS = Number(0).toFixed(2);
            item.NOM_UTILIDAD =  Number(0).toFixed(2);
            item.NOM_DESCPROV = Number(0).toFixed(2);
            item.NOM_ANTICIPO_UTIL = Number(0).toFixed(2);
            item.NOM_RETENCION_JUD = Number(0).toFixed(2);
            item.NOM_IMPUESTO_RET = Number(0).toFixed(2);
            item.NOM_NETOAPAGAR = Number(0).toFixed(2);
            item.NOM_TIPOPAGOUTIL = '';

            console.log('res.EMP_TRABJORNPARC', item.EMP_TRABJORNPARC);
            if (item.EMP_TRABJORNPARC !== null) {
              if (item.EMP_TRABJORNPARC === '0') {
                strJordaParcial = '';
              } else {
                strJordaParcial = 'X';
              }
            } else {
              strJordaParcial = '';
            }

            item.NOM_JORNADAPARCIALPER = strJordaParcial;
            item.NOM_HORAS_JORPARPERSEM = Number(0).toFixed(2);
            item.NOM_DISCAPACIDAD = '';
            item.NOM_RUCEMPCOM = '';
            item.NOM_DECTERCERO = Number(0).toFixed(2);
            item.NOM_DECCUARTO = Number(0).toFixed(2);
            item.NOM_PARTICIPACION_UTI = Number(0).toFixed(2);
            item.NOM_SALARIOS_PER = Number(0).toFixed(2);
            item.NOM_FONDOS_RESERVA = Number(0).toFixed(2);
            item.NOM_COMISIONES = Number(0).toFixed(2);
            item.NOM_BENEFICIOS_ADIEFE = Number(0).toFixed(2);
            item.NOM_SALDIG = Number(0).toFixed(2);
            item.NOM_TIPOPAGO_SALDIG = '';
            item.NOM_FORMPAGOCOD = '';
            item.NOM_FORMPAGODESC = '';
            item.NOM_BANCPAGOCOD = '';
            item.NOM_BANCPAGODESC = '';
            item.NOM_TIPOCUENCOD = '';
            item.NOM_TIPOCUENDESC = '';
            item.NOM_NUMECUEN = '';

          });
          this.utilidadesService.detalleUtilidades = rs;
          this.habilitarBotonesBarra(true, false, false);
          this.spin = false;
        }
      }

     
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
      this.spin = false;
    }
    this.spin = false;
  }

  buscarFiltro(txtDatoBuscar) {

  }

  seleccionDetUtilidades(valor) {
    if (valor === 'eliminar') {
      if (Object.keys(this.detalleSeleccionado).length > 0) {
        this.confirmationService.confirm({
          message: '¿ Está seguro que desea eliminar el registro ?',
          header: 'Utilidades',
          icon: 'pi pi-exclamation-triangle',
          key: 'nomutilidadesConf',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => {
            this.eliminarDetalle();
          },
          reject: () => {
            return;
          }
        });
      } else {
        this.mensajeAlerta('info', 'Información', 'Seleccione una fila...!!!');
      }
      
      
    }

    if (valor.object !== undefined) {
      this.detalleSeleccionado = valor.object;
    } 
  }

  async eliminarDetalle() {
    const parametros: any[] = [];
    parametros[0] = this.lblNumLiqUti;
    parametros[1] = '01';
    parametros[2] = this.detalleSeleccionado.EMP_CODIGO;
    parametros[3] = this.detalleSeleccionado.NOM_SECUENCIA;
    try {
      await this.utilidadesService.eliminarLineaDetalle(parametros);

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'Ha Ocurrido Un Error En La Transacción... ' + mensaje);
      return;
    }

    this.utilidadesService.detalleUtilidades.splice( this.utilidadesService.detalleUtilidades.findIndex(
      item => item.LINEA === this.detalleSeleccionado.LINEA),
      1);

      this.aggrid.refreshaggrid(this.utilidadesService.detalleUtilidades, this.defaultNomUtilidades.objeto);
  }

  cambio(cambio) {
    if (this.btnGuardar === true) {
      this.habilitarBotonesBarra(true, false, true);
    }
  }

  habilitarBotonesBarra(nuevo, guardar, eliminar) {
    this.btnNuevo = nuevo;
    this.btnBorrar = eliminar;
    this.btnGuardar = guardar;
  }

  habilitarBotonesDet(nuevo, eliminar, cancelar) {
    this.btnNuevoGrid = nuevo;
    this.btnBorrarGrid = eliminar;
    this.btnCancelarGrid = cancelar;
  }

  async manejarSeleccion(valor) {
    
    if (this.opcion === 'BUSCAR') {
      await this.llenarCampos(valor.NOM_TRNUTICODIGO);
      
    }
    
    this.displayDialogBusquedaFast = false;
  }

  async llenarCampos(codigo) {
    this.nuevo = false;
    try {
      this.utilidadesService.detalleUtilidades = [];
      const rs = await this.utilidadesService.buscarUtilidades(codigo);
      this.utilidadesService.detalleUtilidades = rs;
      if (rs !== null) {
        if (rs.length > 0) {
          let num = 0;
          this.utilidadesService.detalleUtilidades.map(item => {
            item.LINEA = num++;
            item.EMP_CODIGO = item.EMP_CODIGO === null ? '': item.EMP_CODIGO;
            item.EMP_RUCIDE = item.EMP_RUCIDE === null ? '' : item.EMP_RUCIDE;
            item.NOM_SECUENCIA = item.NOM_SECUENCIA === null ? '' : item.NOM_SECUENCIA;
            item.EMP_NOMBRE = item.EMP_NOMBRE === null ? '' : item.EMP_NOMBRE;
            item.EMP_APELLIDO = item.EMP_APELLIDO === null ? '' : item.EMP_APELLIDO;

            if (item.EMP_FECINGRESO !== null) {
              item.EMP_FECINGRESO = this.datePipe.transform(item.EMP_FECINGRESO, 'dd/MM/yyyy');
            }
            if (item.EMP_FECSALIDA !== null) {
              item.EMP_FECSALIDA = this.datePipe.transform(item.EMP_FECSALIDA, 'dd/MM/yyyy');
            }

            item.EMP_SEXO = item.EMP_SEXO === null ? '' : item.EMP_SEXO;
            item.EMP_CARGO = item.EMP_CARGO === null ? '' : item.EMP_CARGO;
            item.NOM_NUMCARFAM =  item.NOM_NUMCARFAM === null ? Number(0).toFixed(2) : Number(item.NOM_NUMCARFAM).toFixed(2);
            item.NOM_DIASLABORADOS = item.NOM_DIASLABORADOS === null ? item.NOM_DIASLABORADOS : Number(item.NOM_DIASLABORADOS).toFixed(2);
            item.NOM_UTILIDAD =  item.NOM_UTILIDAD === null ? Number(0).toFixed(2) : Number(item.NOM_UTILIDAD).toFixed(2);
            item.NOM_DESCPROV = item.NOM_DESCPROV === null ? item.NOM_DESCPROV : Number(item.NOM_DESCPROV).toFixed(2);
            item.NOM_ANTICIPO_UTIL = item.NOM_ANTICIPO_UTIL === null ? item.NOM_ANTICIPO_UTIL : Number(item.NOM_ANTICIPO_UTIL).toFixed(2);
            item.NOM_RETENCION_JUD = item.NOM_RETENCION_JUD === null ? item.NOM_RETENCION_JUD: Number(item.NOM_RETENCION_JUD).toFixed(2);
            item.NOM_IMPUESTO_RET = item.NOM_IMPUESTO_RET === null ? item.NOM_IMPUESTO_RET : Number(item.NOM_IMPUESTO_RET).toFixed(2);
            item.NOM_NETOAPAGAR =  item.NOM_NETOAPAGAR  === null ? item.NOM_NETOAPAGAR  : Number(item.NOM_NETOAPAGAR ).toFixed(2);
            item.NOM_TIPOPAGOUTIL = item.NOM_TIPOPAGOUTIL === null ? '' : item.NOM_TIPOPAGOUTIL;
            item.NOM_JORNADAPARCIALPER = item.NOM_JORNADAPARCIALPER === null ? '' : item.NOM_JORNADAPARCIALPER;
            item.NOM_HORAS_JORPARPERSEM = item.NOM_HORAS_JORPARPERSEM === null ? item.NOM_HORAS_JORPARPERSEM : Number(item.NOM_HORAS_JORPARPERSEM).toFixed(2);
            item.NOM_DISCAPACIDAD = item.NOM_DISCAPACIDAD === null ? '' : item.NOM_DISCAPACIDAD;
            item.NOM_RUCEMPCOM = item.NOM_RUCEMPCOM === null ? '' : item.NOM_RUCEMPCOM;
            item.NOM_DECTERCERO = item.NOM_DECTERCERO === null ? item.NOM_DECTERCERO : Number(item.NOM_DECTERCERO).toFixed(2);
            item.NOM_DECCUARTO = item.NOM_DECCUARTO === null ? item.NOM_DECCUARTO : Number(item.NOM_DECCUARTO).toFixed(2);
            item.NOM_PARTICIPACION_UTI = item.NOM_PARTICIPACION_UTI === null ? item.NOM_PARTICIPACION_UTI : Number(item.NOM_PARTICIPACION_UTI).toFixed(2);
            item.NOM_SALARIOS_PER = item.NOM_SALARIOS_PER === null ? item.NOM_SALARIOS_PER : Number(item.NOM_SALARIOS_PER).toFixed(2);
            item.NOM_FONDOS_RESERVA = item.NOM_FONDOS_RESERVA === null ? item.NOM_FONDOS_RESERVA : Number(item.NOM_FONDOS_RESERVA).toFixed(2);
            item.NOM_COMISIONES = item.NOM_COMISIONES === null ? item.NOM_COMISIONES : Number(item.NOM_COMISIONES).toFixed(2);
            item.NOM_BENEFICIOS_ADIEFE = item.NOM_BENEFICIOS_ADIEFE === null ? item.NOM_BENEFICIOS_ADIEFE : Number(item.NOM_BENEFICIOS_ADIEFE).toFixed(2);
            item.NOM_SALDIG = item.NOM_SALDIG === null ? item.NOM_SALDIG : Number(item.NOM_SALDIG).toFixed(2);



          
          });
          this.lblEstado = rs[0].NOM_ESTADO === null ? '' : rs[0].NOM_ESTADO;
          this.txtCABIMPURENT_DESCRIPCION = rs[0].NOM_DESCRIPCION;
          this.txtCABOTROSIEG_ANIO = rs[0].NOM_ANIO;
          this.txtTRNCOBRO_importe = rs[0].NOM_IMPORTE_UTILIDAD === null ? Number(0).toFixed(2) :  Number(rs[0].NOM_IMPORTE_UTILIDAD).toFixed(2);
          this.lblNumLiqUti = rs[0].NOM_TRNUTICODIGO;
        }
      }
      this.boolTxtAnio = true;
      this.boolTxtDescripcion = false;
      this.boolBtnAceptar = true;

      if (this.lblEstado === 'CONFIRMADO') {
        this.ActivarBoton('NO');
      } else {
        this.ActivarBoton('B');
      }

    } catch(err) {

    }
  }

  buscarRegistroDetalle() {
    this.opcion = 'BUSCARDETALLE';
    this.types = [
      {label: 'Código', value: 'EMP_CODIGO'},
      {label: 'Cédula', value: 'EMP_RUCIDE'},
      {label: 'Nombres', value: 'EMP_NOMBRE'},
      {label: 'Apellidos', value: 'EMP_APELLIDO'},
      {label: 'Fecha Ingreso', value: 'EMP_FECINGRESO'},
    ];
  
    this.busquedacampos = ['', '', '', '', ''];
    this.consulta = 'EMP_CODIGO,EMP_RUCIDE,EMP_NOMBRE,EMP_APELLIDO,EMP_FECINGRESO';
    this.tabla = 'NOM_MAEEMPLEADO';
    this.arregloCons = [];
    this.condicion = 'ROWNUM<50 order by EMP_APELLIDO';
    this.displayDialogBusquedaMae = true;
  }


  ActivarBoton(Accion) {
    if (Accion === 'N' || Accion === 'D') {
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
    
      this.habilitarBotonesBarra(true, false, true);
      this.btnBuscar = true;
    }

    if (Accion === 'M') {
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
    
      this.habilitarBotonesBarra(true, false, false);
      this.btnBuscar = true;
    }

    if (Accion === 'C' || Accion === 'B' || Accion === 'L') {
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      this.habilitarBotonesDet(true, false, false);
      this.habilitarBotonesBarra(false, true, false);
      this.btnBuscar = false;
    }

    if (Accion === 'G') {
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      this.habilitarBotonesDet(true, false, false);
      this.habilitarBotonesBarra(false, true, false);
      this.btnBuscar = false;
    }

    if (Accion === 'E') {
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      this.habilitarBotonesDet(true, false, false);
      this.habilitarBotonesBarra(false, true, false);
      this.btnBuscar = false;
    }

    if (Accion === 'NO') {
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
      this.habilitarBotonesDet(true, true, true);
      this.habilitarBotonesBarra(false, true, true);
      this.btnBuscar = false;
    }
  }

  manejarSelecGridbus(elemento){
    this.displayDialogBusquedaMae = false;
    for(let a of elemento){
      let valor=this.utilidadesService.detalleUtilidades.find(ele=>ele.EMP_CODIGO==a.EMP_CODIGO);
      if(this.isNullOrUndefined(valor)){
        //this.mensajeAlerta('error', 'Error', 'No existe ningún registro');
        continue;
      }else{
        const indice= this.utilidadesService.detalleUtilidades.indexOf(valor);
        this.aggrid.gridApi.setFocusedCell(indice , 'EMP_CODIGO');
        this.aggrid.refreshaggridindex(this.utilidadesService.detalleUtilidades, this.defaultNomUtilidades.objeto, indice);
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultNomUtilidades.objeto].setFocusedCell(
          indice , 'EMP_CODIGO');
        return;
      }
    }
    this.mensajeAlerta('error', 'Error', 'No existe ningún registro');
    return;
  }

  isNullOrUndefined(valor): boolean {
    if (valor === null) {
      return true;
    }
    if (valor === undefined) {
      return true;
    }

    return false;
  }

  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'nomutilidades',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

  mensajeAlertaArch(tipo, titulo, msg) {
    this.message.add({
      key: 'nomutilarch',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

  
  isEdit(data): boolean {
    if(this.lblEstado === 'CONFIRMADO') {
      return false;
    } else {
      return true;
    }
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos  = false;
    }
  }


}
