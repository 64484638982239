import { Injectable } from '@angular/core';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class InvhistorialserialService {

  public url: string;
  public way: string;
  constructor(
    private confIniciales:ConfInicialesService,
    private http:HttpClient
  ) { 
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }
  consultarhisserial(txtcod,txtserie ) {
    return this.http.post<any>(this.way + '/inventario/historialserial/83bb19738edc980', {
      elementos: {
        cod:txtcod,
        serie:txtserie
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erpBusquedaArticulo(tabla,campo,parametro ) {
    return this.http.post<any>(this.way + '/inventario/historialserial/257fafdc56fb260000', {
      elementos: {
        Valor: tabla,
        Value: campo,
        Params: parametro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erpBusquedaSerial(tabla,campo,parametro,art ) {
    return this.http.post<any>(this.way + '/inventario/historialserial/95febf738edc980', {
      elementos: {
        Valor: tabla,
        Value: campo,
        Params: parametro,
        value2: art
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
}
