<p-toast [style]="{marginTop: '41px'}" position="top-center" key="invtrnajustes"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="invtrnajustesconf" title="Confirmar Acción"
				 appendTo="body"></p-confirmDialog>
<div class="p-grid" style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra (signal)="manejarSenales($event)" [botonNuevo]="btnNuevo"
						 [botonGuardar]="btnGuardar"
						 [botonBorrar]="btnBorrar" [botonRegresar]="false"
						 
						 [barraBotonesDesplaz]="true" [barraBotones3]="true"
						 [barraBotonesAsiCon]="true" [barraBotones5]="true"
						 [botonBuscarPermanente]="true"
		></app-invmaebarra>
	</div>
</div>
<div class="divg" style="height: 450px">
    <app-aggridsaci [width]=""
						[height]="-1800"
						[enableSorting]="false"
						[enableFilter]="false"
						[rowData]="invTrnAjusteService.detTrnAjustesNeg"
						[frameworkComponents]="frameworkComponents"
						[animateRows]="true"
						[rowSelection]="rowSelection"
						[columnDefs]="columnDefstrnAjustesNeg"
						[defaultColDef]="defaultColTrnAjustesNeg"
						[rowStyle]="rowStyle"
						[singleClickEdit]="false"
						[botones]="false"
						[mostrarGuardar]="false"
						(selected)="seleccionajuste($event)"
		></app-aggridsaci>
</div>