import { Component, OnInit, ViewChild } from '@angular/core';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { PermisosService } from 'src/app/advantage/advantageservices/permisos.service';
import { MessageService, ConfirmationService } from 'primeng';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { DatePipe } from '@angular/common';
import { Usuario } from 'src/app/usuario';
import { NomtrannovedadService } from '../nomservicios/nomtrannovedad.service';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import { AggridBanComponent } from 'src/app/ban/aggrid-ban/aggrid-ban.component';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { nomtrannovedad } from '../nominterfaces/nomtrannovedad';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-nomtrannovedad',
  templateUrl: './nomtrannovedad.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class NomtrannovedadComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnGuardar: boolean;
  btnNuevo: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  indexTab:number;
  dteFECHA_INIPERIACTI;
  dteFECHA_FINPERIACTI;
  cargoDatos:boolean=false;
  selectednomtrannovedades:nomtrannovedad;
  public frameworkComponents;
  control=0;
  rowStyle;
  largo = '350';
  defaultColDefTrannoved;
  rowSelection = 'multiple';
  columnnomTrannove=[
    {
      headerName: 'Código', editable: true, field: 'EMP_CODIGO', cellEditor: 'cellPrueba', width: 80,  
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 100,
            tienecalculos: false,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Cédula', editable: false, field: 'EMP_CODIGO2', cellEditor: 'cellPrueba', width: 120,  
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 100,
            tienecalculos: false,
            
          }
        }
    },
    {
      headerName: 'Empleado', editable: false, field: 'EMP_NOMBRE', cellEditor: 'cellPrueba', width: 180,  
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 100,
            tienecalculos: false,
            
          }
        }
    },
    {
      headerName: 'Código', editable: true, field: 'MAERUBRO_CODRUBR', cellEditor: 'cellPrueba', width: 100,  
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 100,
            tienecalculos: false,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Descripción', editable: true, field: 'TRANNOVEDAD_DESCRIPCION', cellEditor: 'cellPrueba', width: 200,  
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 100,
            tienecalculos: false,
            
          }
        }
    },
    {
      headerName: 'FecIni', editable: true, field: 'TRANNOVEDAD_FECINI', cellEditor: 'cellPrueba', width: 120,  
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: true,
            tamanocelda: 100,
            tienecalculos: false,
           
          }
        }
    },
    {
      headerName: 'FecFin', editable: true, field: 'TRANNOVEDAD_FECFIN', cellEditor: 'cellPrueba', width: 120,  
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: true,
            tamanocelda: 100,
            tienecalculos: false,
            
          }
        }
    },
    {
      headerName: 'Observación', editable: true, field: 'TRANNOVEDAD_OBSERVACION', cellEditor: 'cellPrueba', width: 200,  
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 100,
            tienecalculos: false,
           
          }
        }
    },
    {
      headerName: 'Estado', editable: false, field: 'TRANNOVEDAD_ESTADO', cellEditor: 'cellPrueba', width: 110,  
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 100,
            tienecalculos: false,
            
          }
        }
    }
  ]
  constructor(public nomtrannovedadservice:NomtrannovedadService ,
    private init: NuevoComponentService,
    private permisosService: PermisosService,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private confirmationService: ConfirmationService,
    private auditoriaGralService: AuditoriagralService,
    private datePipe: DatePipe,
    public usuario: Usuario
    ) { 
      this.btnGuardar=true;
      this.btnNuevo=false;
      this.btnBorrar=false;
      this.btnRegresar=false;
      this.agTable();
    }

  ngOnInit() {
    this.cargoDatos=true;
    this.nomtrannovedadservice.nonnovedad=[];
     this.nomtrannovedadservice.llenardatosnovedad().then((res:any)=>{
      
      try{
      this.nomtrannovedadservice.nonnovedad=res;
      let i=0;
      this.nomtrannovedadservice.nonnovedad.map(x=>{
        x.TRANNOVEDAD_FECINI=this.nomtrannovedadservice.cambiarfechanull(x.TRANNOVEDAD_FECINI);
        x.TRANNOVEDAD_FECFIN=this.nomtrannovedadservice.cambiarfechanull(x.TRANNOVEDAD_FECFIN);
        x.NUEVO=false;
        x.NUMNOV=i;
        i++;
      }); 
      setTimeout(() => {
        this.aggrid.refreshaggrid(this.nomtrannovedadservice.nonnovedad, this.defaultColDefTrannoved.objeto); 
        this.aggrid.gridApi.setFocusedCell(0 , 'EMP_CODIGO');
        this.cargoDatos=false;
      }, 300);
      
    }catch(e){
      this.cargoDatos=false;
    }
    });

    this.nomtrannovedadservice.obtenerperiodo().then((re:any)=>{
      this.dteFECHA_INIPERIACTI=this.nomtrannovedadservice.cambiarfechanull(re[0].PERIODO_FECINI);
      this.dteFECHA_FINPERIACTI=this.nomtrannovedadservice.cambiarfechanull(re[0].PERIODO_FECFIN);
    });
    
  }
  onTab1Change(elemento){

  }
  manejarSenales(valor){

    switch (valor) {
      case 'Nuevo':
        this.opcionNuevo();
        break;
      case 'Guardar':
        if(this.control === 0){
          this.control=1;
          this.btnGuardar=true;
          this.opcionGuardar();
        }
        break;
      case 'Borrar':
        this.opcionBorrar();
        break;
      case 'Cancelar':
        this.opcionRecargar();
        break;
      case 'Recarga':
        this.opcionRecargar();
        break;
      case 'Salir':
        this.init.tabs.splice(  this.init.tabindex, 1);
        break;
    };
    return;
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefTrannoved= {
      editable: true,
      width: 130,
      objeto: 'nomTrannovedad'
    };
  }
  opcionListarTodos(){
    let i=0;
    this.nomtrannovedadservice.todastrannovedad().then(async (res:any)=>{
      this.nomtrannovedadservice.nonnovedad=res;
      this.nomtrannovedadservice.nonnovedad.map(x=>{
        x.TRANNOVEDAD_FECINI=this.nomtrannovedadservice.cambiarfechanull(x.TRANNOVEDAD_FECINI);
        x.TRANNOVEDAD_FECFIN=this.nomtrannovedadservice.cambiarfechanull(x.TRANNOVEDAD_FECFIN);
        x.NUEVO=false;
        x.NUMNOV=i;
        i++;
      });
      this.aggrid.refreshaggrid(this.nomtrannovedadservice.nonnovedad, this.defaultColDefTrannoved.objeto ); 
    })
  }
  opcionNuevo(){
   this.btnNuevo = true;
    this.btnBorrar = false;
    this.btnRegresar = false;
    this.control=0;
    const newModulo: nomtrannovedad={  
      COM_CODIGO:'01',
      EMP_CODIGO:'',
      EMP_CODIGO2:'',
      EMP_NOMBRE:'',
      MAERUBRO_CODIGO:'',
      MAERUBRO_CODRUBR:'',
      TRANNOVEDAD_CODIGO:0,
      TRANNOVEDAD_DESCRIPCION:'',
      TRANNOVEDAD_ESTADO:'',
      TRANNOVEDAD_FECFIN:'',
      TRANNOVEDAD_FECINI:'',
      TRANNOVEDAD_OBSERVACION:'',
      NUEVO:true,
      NUMNOV:this.nomtrannovedadservice.nonnovedad.length
    };
    try{
      this.nomtrannovedadservice.nonnovedad.push(newModulo); 
      const indice= this.nomtrannovedadservice.nonnovedad.indexOf(newModulo);
     
      setTimeout(() => {
        this.aggrid.refreshaggrid(this.nomtrannovedadservice.nonnovedad, this.defaultColDefTrannoved.objeto );  
        this.aggrid.gridApi.setFocusedCell(indice , 'EMP_CODIGO');
      }, 300);
      
    }catch(err){
    }
  }
  async opcionGuardar(){
    let errors:string[]=[];
    let ind:number=0;
    this.aggrid.gridApi.stopEditing();
    if(!isNullOrUndefined(this.selectednomtrannovedades)){
      errors=this.validarNulos(this.selectednomtrannovedades);
      if(!isNullOrUndefined(errors)){
        ind=errors.length;
      }
      if(ind!=0){
        for(let a of errors){
          this.mensajeAlerta('error', 'Error', a);
          this.control=0;
          this.btnGuardar=false;
        }
      }else{
        const  bool=await this.nomtrannovedadservice.funCtrlNoveRepet(this.selectednomtrannovedades);
        if(bool==true){
          try{
            this.nomtrannovedadservice.guardartrannovedad(this.selectednomtrannovedades).then(re=>{
              if (this.selectednomtrannovedades.NUEVO === true) {
                this.mensajeAlerta('success', 'Información', '¡Registro guardado exitosamente!');
                this.control=0;
              } else {
                this.mensajeAlerta('success', 'Información', '¡Registro actualizado exitosamente!');
                this.control=0;
              }
              
              this.opcionRecargar();
              let au=this.selectednomtrannovedades.NUEVO==true?'I':'A';
              this.auditoriaGralService.registrarAuditoria('nom_trannovedad', this.selectednomtrannovedades.TRANNOVEDAD_CODIGO.MAERUBRO_CODIGO, au,
              '', '01', '', '', '', '').toPromise().then(() => {
              });
            })
          }catch(e){
            this.mensajeAlerta('error', 'Error', 'Ha Ocurrido Un Error En La Transacción');
            this.control=0;
            this.btnGuardar=false;
          }
        }else{
          this.mensajeAlerta('error', 'Error', 'Error, la novedad  no puede registrarse debido a que existen otros registros con estas fechas');
          this.control=0;
          this.btnGuardar=false;
        };
      }
    }else{
      this.control=0;
      this.btnGuardar=false;
    }
  }

  validarNulos(novedad:nomtrannovedad){
  
    let  resp:string[]=[];
    if(isNullOrUndefined(novedad.TRANNOVEDAD_FECINI)|| novedad.TRANNOVEDAD_FECINI==""){
      resp.push('Empleado ingrese fecha inicial');
      return resp;
    };
    if(isNullOrUndefined(novedad.TRANNOVEDAD_FECFIN)|| novedad.TRANNOVEDAD_FECFIN==""){
      resp.push('Empleado ingrese fecha final');
      return resp;
    };
    if(isNullOrUndefined(novedad.EMP_CODIGO)|| novedad.EMP_CODIGO==""){
      resp.push('Empleado campo obligatorio');
      return resp;
    }
   
    if(isNullOrUndefined(novedad.MAERUBRO_CODIGO)|| novedad.MAERUBRO_CODIGO==""){
      resp.push('Rubro campo obligatorio');
      return resp;
    }
    if(this.nomtrannovedadservice.validarMayor(novedad.TRANNOVEDAD_FECINI,novedad.TRANNOVEDAD_FECFIN)){
      resp.push('Fecha final no puede ser menor que fecha inicio');
      return resp;
    }
    if(this.funVerificaSiModificaFechaInicial(novedad)){
      if(this.nomtrannovedadservice.validarMenor(novedad.TRANNOVEDAD_FECINI,this.dteFECHA_INIPERIACTI)){
        resp.push('Fecha inicio no puede ser menor que el período activo');
        return resp;
      }
    };
    
    if(this.nomtrannovedadservice.validarMenor(novedad.TRANNOVEDAD_FECFIN,this.dteFECHA_INIPERIACTI)){
      resp.push('Fecha fin no puede ser menor que el período activo');
      return resp;
    }
    //return resp;
  }
async funVerificaSiModificaFechaInicial(novedad:nomtrannovedad){
;
    if(parseInt(novedad.TRANNOVEDAD_CODIGO)==0){
      return true;
    }
    try{
      let fechiniaux;
      const fe=await  this.nomtrannovedadservice.vermodfechinic(novedad.TRANNOVEDAD_CODIGO).then(res=>{
        if(isNullOrUndefined(res[0].TRANNOVEDAD_FECINI)||res[0].TRANNOVEDAD_FECINI==""){
        
          return true
        }else{
          fechiniaux=res[0].TRANNOVEDAD_FECINI;
          if(this.nomtrannovedadservice.validarIgual(novedad.TRANNOVEDAD_FECINI,fechiniaux)){
            return false;
          }else{
            return true;
          }
        }
      })
    }catch(e){

    }
  }
  opcionBorrar(){
    if ( this.selectednomtrannovedades !== undefined ) {
      this.confirmationService.confirm({
        message: '¿Está seguro que desea eliminar?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        key: 'nomNovedad',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: () => {
          try{
            try{
              this.nomtrannovedadservice.eliminartrannovedad(this.selectednomtrannovedades.TRANNOVEDAD_CODIGO).then(async res=>{
                this.mensajeAlerta('success', 'Información', '¡Registro eliminado!');
                this.opcionRecargar();
                this.auditoriaGralService.registrarAuditoria('nom_trannovedad', this.selectednomtrannovedades.TRANNOVEDAD_CODIGO.MAERUBRO_CODIGO, 'E',
                '', '01', '', '', '', '').toPromise().then(() => {
                });
              });
            }catch(e){
              this.mensajeAlerta('error', 'error', e.error);
                
            }
    
          }catch(e){
            let mensaje: string;
            mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
            this.mensajeAlerta('error', 'Error', mensaje);
          }
        },
        reject: () => {
        }
      })
    }
  }
  async opcionRecargar(){
    this.nomtrannovedadservice.nonnovedad=[];
    const reca=await this.nomtrannovedadservice.llenardatosnovedad().then(async (res:any)=>{
     
      this.nomtrannovedadservice.nonnovedad=res;
      this.nomtrannovedadservice.nonnovedad.map(x=>{
        x.TRANNOVEDAD_FECINI=this.nomtrannovedadservice.cambiarfechanull(x.TRANNOVEDAD_FECINI);
        x.TRANNOVEDAD_FECFIN=this.nomtrannovedadservice.cambiarfechanull(x.TRANNOVEDAD_FECFIN);
      });
      this.aggrid.refreshaggrid(this.nomtrannovedadservice.nonnovedad, this.defaultColDefTrannoved.objeto ); 
    
    });

    this.nomtrannovedadservice.obtenerperiodo().then((re:any)=>{
      
      this.dteFECHA_INIPERIACTI=this.nomtrannovedadservice.cambiarfechanull(re[0].PERIODO_FECINI);
      this.dteFECHA_FINPERIACTI=this.nomtrannovedadservice.cambiarfechanull(re[0].PERIODO_FECFIN);
    })
    this.btnGuardar=true;
    this.btnNuevo=false;
    this.btnBorrar=false;
    this.btnRegresar=false;
  }
  selecnonnovedad(valor){
    if (valor.object !== undefined) {
      this.selectednomtrannovedades=valor.object;
    }
  }
  cambiononnovedad(){
    this.btnGuardar=false;
    this.btnNuevo=true;
    this.btnBorrar=true;
    this.btnRegresar=false;
  }
  async busIDEmpl(parametro){
    let resp="";
    if (parametro == null || parametro== undefined) {
      parametro = '';
    }
    
    return new Promise<any>(async (resolve)=>{
     await this.nomtrannovedadservice.erIDEmpl(parametro).then(eR => {
      if (eR !== null) {
          if (eR[0] !== undefined) {
            resolve(eR[0]);
          } else {
            resolve(resp);
          }
        } else {
          resolve(resp);
        }
      });
    });
  }
 
  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'novedad',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
  }
}
