import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NomencrolcerradosService } from '../nomservicios/nomencrolcerrados.service';
import { Usuario } from 'src/app/usuario';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { CellEditorPrueba } from 'src/app/advantage/config/cell-edit-prueba';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { SelectItem } from 'primeng/api/selectitem';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';
import { MessageService, ConfirmationService } from 'primeng';
import { ErroresBaseDatosService } from 'src/app/servicios/errores-base-datos.service';
import { NomencrolService } from '../nomservicios/nomencrol.service';
import { DetRolProceso, DetProvProceso, EncRolIndi, Mat_periodo, Mat_vacacion, Mat_vacacion_gp } from '../nominterfaces/nomencrol';
import { isNullOrUndefined } from 'util';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Component({
  selector: 'app-nomencrol',
  templateUrl: './nomencrol.component.html',
  styleUrls: ['../../advantage/styles/saciwebmae.css']
})
export class NomencrolComponent implements OnInit {

  @ViewChild('gridRol') aggrid: AggridsaciComponent;
  @ViewChild('gridProv') provAggrid: AggridsaciComponent;

  btnGuardar = true;
  btnBorrar = true;
  btnRegresar = true;
  diasvacapro = 0;//GSRF
  botonPrimero = false;
  botonAnterior = false;
  botonSiguiente = false;
  botonUltimo = false;
  btnBuscar = false;

  botonnuevo = true;
  botoneliminar = true;
  botoncancelar = true;



  rol = '';
  estado = '';
  txtPERIODO_FECINI = '';
  txtPERIODO_FECFIN = '';
  txtENCROL_ANIO = '';
  lblEMP_CODIGO = '';
  txtEMP_SUELDO = '';
  txtEMP_NOMBRE = '';
  lblPERIODO_CODIGO = '';
  txtENCROLINDI_ESTADO = '';
  txtTIPOROL_CODIGO = '';
  txtEMP_FECINGRESO = '';
  txtEMP_FECSALIDA = '';
  fechaSalida = '';
  lblENCROLINDI_CODIGO = '';
  txtEMP_RUCIDE = '';
  cmbTIPROL = [];
  cmbTIPROL_CODIGO = '';
  cmbASI_NRO = [];

  selectedRol: DetRolProceso;
  selectedProv: DetProvProceso;
  selectedAsiento;

  displayAcciones: boolean;

  total = {
    totalUno: '0',
    totalDos: '0',
    netoPagar: '0'
  }

  fecha = new Date();
  identiUsuario = '';
  tabIndex = 0;

  largo = '258';
  rowStyle;
  defaultColDefDetRol;
  rowSelection = 'multiple';
  detalleRoles: DetRolProceso[] = [];
  public frameworkComponents;
  detalleProv: DetProvProceso[] = [];
  defaultColDefDetProv;

  types: SelectItem[];
  tabla: string;
  consulta: string;
  busquedacampos: any[];
  where: string;
  arregloCons: any[];
  arregloBus: any[];

  periodoProceso = '';
  periodoCodigo = 0;
  matEncRolIndi: EncRolIndi = {
    detrolindi_ingresos_graviess: 0,
  }

  displayDialogBusquedaFast = false;
  displayAsiContable = false;
  displayDialogBusquedaCodR = false;
  displayDialogBusquedaTipoR = false;
  displayDialogBusquedaCodProv = false;
  displayDialogReportes: boolean;

  // reporte
  auxreporte: number = 0;
  strNombreForma: string;

  asiento: any;

  monstrarEncRol3 = false;
  monstrarEncRol1 = false;

  datosRol3 = {
    TIPO_RUBRO: '',
    CODIGO_RUBRO: '',
    TIPO_CALCULO: '',
    dblNUMERO_DIAS: 0,
    lblNUM_DIASVACA: 0,
    lblPERIODO_FECINI: '',
    lblEMP_CODIGO: '',
    txtPERIODO_FECINI: '',
    txtPERIODO_FECFIN: '',
    lblPERIODO_CODIGO: '',
    lblRUBRO_CODRUBR: '',
    lblENCROLINDI_CODIGO: '',
    detrolindi_diasvacagoza: '',
    valor_vacaciones: '',
    fecha_inicio: '',
    fecha_fin: '',
    observacion: '',
    editado:false
  }

  mat_periodos: Mat_periodo[] = [];
  mat_vacaciones: Mat_vacacion[] = [];



  spin = false;
  control:boolean;//CDPJ
  controlg=0;//CDPJ
  displayDialogDocumentos = false;

  columnDefDetRol = [
    {
      headerName: 'Tipo', field: 'DETROLINDI_TIPORUBR', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 400,
          tienecalculos: true,
        }
      }, onCellValueChanged: (params) => {
        //if (params.data.DETROLINDI_TIPORUBR === '') {
          this.busquedaRubroTipoR(params.data.DETROLINDI_TIPORUBR);
        //}

      }
    },
    {
      headerName: 'Código', field: 'DETROLINDI_CODIGO', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      }, onCellValueChanged: (params) => {
        //if (params.data.DETROLINDI_CODIGO === '') {
          this.busquedaRubroCodigo(params.data.DETROLINDI_CODIGO);
        //}

      },
      editable: (params) => {
        return (params.data.lock === '1') ? false : true;
      }
    },
    {
      headerName: 'Descripción', field: 'DETROLINDI_DESCRIPCION', cellEditor: 'cellPrueba', width: 300,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      },
      editable: (params) => {
        return (params.data.lock === '1') ? false : true;
      }
    },
    {
      headerName: 'T', field: 'DETROLINDI_TIPODET', cellEditor: 'cellPrueba', width: 50,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 1,
          tienecalculos: true,
        }
      }, onCellValueChanged: async (params) => {
        if (params.data.editado) {
          this.verificarTipoRol();
          //this.sumTotales();
        }

      },
      editable: (params) => {
        return (params.data.lock === '1') ? false : true;
      }
    },
    {
      headerName: 'Horas', field: 'DETROLINDI_HORAS', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,// GSRF
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      }, onCellValueChanged: async (params) => {
        params.data.DETROLINDI_HORAS = Number(params.data.DETROLINDI_HORAS).toFixed(2);
        let index = this.detalleRoles.findIndex(x => x === params.data);
        await this.funCalculaValorHorasExtras();

        this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);

        await this.calcularRubros().then(() => {
          this.aggrid.gridApi.setFocusedCell(index, 'DETROLINDI_DIAS');

        })
        //this.sumTotales();

      },
      editable: (params) => {
        let validar = this.rolProcesoSrv.getObjetoValidar(params.data.DETROLINDI_TIPORUBR, 'HORA', params.data.DETROLINDI_CODIGO);
        let lock = (params.data.lock === '1') ? false : true;
        return (validar && lock);
      }
    },
    {
      headerName: 'Días', field: 'DETROLINDI_DIAS', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,//GSRF
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      }, onCellValueChanged: (params) => {
        params.data.DETROLINDI_DIAS = Number(params.data.DETROLINDI_DIAS).toFixed(2);
        //let index = this.detalleProv.findIndex( x => x === params.data);
        //this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);
        this.abrirEncRol3();
        //this.aggrid.gridApi.setFocusedCell(index, 'DETROLINDI_INGRESOS' );
      },
      editable: (params) => {
        let validar = this.rolProcesoSrv.getObjetoValidar(params.data.DETROLINDI_TIPORUBR, 'DIA', params.data.DETROLINDI_CODIGO);
        let lock  = this.validarEdicion(params.data);

        return (validar && lock);
      }
    },
    {
      headerName: 'Ingresos', field: 'DETROLINDI_INGRESOS', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,



        }
      }, onCellValueChanged: async(params) => {
        params.data.DETROLINDI_INGRESOS = Number(params.data.DETROLINDI_INGRESOS).toFixed(2);
        let index = this.detalleProv.findIndex(x => x === params.data);
        this.sumTotales();
        this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);
        this.provAggrid.gridApi.setFocusedCell(index, 'DETROLINDI_INGRESOS');
         //GSRF
         await this.calcularRubros().then(() => {
          this.aggrid.gridApi.setFocusedCell(index, 'DETROLINDI_DIAS');
        })
        //GSRF
      },
      editable: (params) => {
        let validar = false;
        //if (params.data.DETROLINDI_TIPODET === 'C') {
          validar = this.validarEdicion(params.data)
          //(params.data.DETROLINDI_TIPORUBR, 'VALOR', params.data.DETROLINDI_CODIGO);
        //}
        let lock = (params.data.lock === '1') ? false : true;
        
        return (validar )//&& lock);
      }
    },
    {
      headerName: 'Egresos', field: 'DETROLINDI_EGRESOS', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      }, onCellValueChanged: (params) => {
        params.data.DETROLINDI_EGRESOS = Number(params.data.DETROLINDI_EGRESOS).toFixed(2);
        let index = this.detalleProv.findIndex(x => x === params.data);
        this.sumTotales();
        this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);
        this.provAggrid.gridApi.setFocusedCell(index, 'DETROLINDI_FECHFINRUBR');
      },
      editable: (params) => {
        let validar = false;
        // let validar2 = false;
        // if (params.data.DETROLINDI_TIPORUBR === 'DESCUENTO') {
        //   validar = this.rolProcesoSrv.getObjetoValidar(params.data.DETROLINDI_TIPORUBR, 'VALOR', params.data.DETROLINDI_CODIGO);
        //   validar2 = this.rolProcesoSrv.getObjetoValidar(params.data.DETROLINDI_TIPORUBR, 'UNIDAD', params.data.DETROLINDI_CODIGO);
        // }
        //if (params.data.DETROLINDI_TIPODET === 'C') {
          validar = this.validarEdicion(params.data)
          //(params.data.DETROLINDI_TIPORUBR, 'VALOR', params.data.DETROLINDI_CODIGO);
        //}
        let lock = (params.data.lock === '1') ? false : true;
        return (validar )//|| validar2) && lock);
      }
    },
    {
      headerName: 'FechIni', field: 'DETROLINDI_FECHINIRUBR', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: true,
          tamanocelda: 80,
          tienecalculos: false,
        }
      }, onCellValueChanged: (params) => {
        params.data.DETROLINDI_FECHINIRUBR =
          this.pipe.transform(params.data.DETROLINDI_FECHINIRUBR, 'dd/MM/yyyy');
        let index = this.detalleRoles.findIndex(x => x === params.data);
        this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);
        this.aggrid.gridApi.setFocusedCell(index, 'TRANVACAEMPL_OBSERVACIONES');
      },
      editable: (params) => {
        return (params.data.lock === '1') ? false : true;
      }
    },
    {
      headerName: 'FechFin', field: 'DETROLINDI_FECHFINRUBR', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: true,
          tamanocelda: 80,
          tienecalculos: false,
        }
      }, onCellValueChanged: (params) => {
        params.data.DETROLINDI_FECHFINRUBR =
          this.pipe.transform(params.data.DETROLINDI_FECHFINRUBR, 'dd/MM/yyyy');
        let index = this.detalleRoles.findIndex(x => x === params.data);
        this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);
        this.aggrid.gridApi.setFocusedCell(index, 'DETROLINDI_FECHINIRUBR');
      },
      editable: (params) => {
        return (params.data.lock === '1') ? false : true;
      }
    },

    {
      headerName: 'Observación', field: 'TRANVACAEMPL_OBSERVACIONES', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      }, onCellValueChanged: (params) => {
        params.data.DETROLINDI_FECHINIRUBR =
          this.pipe.transform(params.data.DETROLINDI_FECHINIRUBR, 'dd/MM/yyyy');
        let index = this.detalleRoles.findIndex(x => x === params.data);
        this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);
        this.aggrid.gridApi.setFocusedCell(index, 'TRANVACAEMPL_OBSERVACIONES');
      },
      editable: (params) => {
        return (params.data.lock === '1') ? false : true;
      }
    },

  ];

  columnDefDetProv = [
    {
      headerName: 'Tipo', field: 'DETROLPROV_TIPORUBR', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      },
      editable: (params) => {
        return (params.data.DETROLPROV_TIPODET === 'C') ? false : true;
      }
    },
    {
      headerName: 'Código', field: 'DETROLPROV_CODIGO', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      }, onCellValueChanged: (params) => {
        //GSRF
        console.log('*****',params.data.DETROLPROV_CODIGO)
        if(params.data.DETROLPROV_CODIGO === 'VAC'){
          params.data.DETROLPROV_DIAS = Number(params.data.DETROLPROV_DIAS).toFixed(4);
        }else{
          params.data.DETROLPROV_DIAS = Number(params.data.DETROLPROV_DIAS).toFixed(2);
        }
        let index = this.detalleProv.findIndex(x => x === params.data);
        this.provAggrid.refreshaggrid(this.detalleProv, this.defaultColDefDetProv.objeto);
        this.provAggrid.gridApi.setFocusedCell(index, 'DETROLPROV_BASE');
        //GSRF
        this.funCol3Prov();
        /*  let index = this.detalleProv.findIndex( x => x === params.data);
         await this.calcularRubros().then(() => {
           
           this.provAggrid.refreshaggrid(this.detalleProv, this.defaultColDefDetProv.objeto);
           this.provAggrid.gridApi.setFocusedCell(index, 'DETROLPROV_DESCRIPCION' );
           
         }) */
      },
      editable: (params) => {
        return (params.data.DETROLPROV_TIPODET === 'C') ? false : true;
      }
    },
    {
      headerName: 'Descripción', field: 'DETROLPROV_DESCRIPCION', cellEditor: 'cellPrueba', width: 300,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      },
      editable: (params) => {
        return (params.data.DETROLPROV_TIPODET === 'C') ? false : true;
      }
    },
    {
      headerName: 'T', field: 'DETROLPROV_TIPODET', cellEditor: 'cellPrueba', width: 50,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      }, onCellValueChanged: (params) => {
        this.verificarTipoProv();
      },
      editable: (params) => {
        return (params.data.DETROLPROV_TIPODET === 'C') ? false : true;
      }
    },
    {
      headerName: 'Días', field: 'DETROLPROV_DIAS', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      }, onCellValueChanged: (params) => {
        //GSRF
        console.log('*****',params.data.DETROLPROV_CODIGO)
        if(params.data.DETROLPROV_CODIGO === 'VAC'){
          params.data.DETROLPROV_DIAS = Number(params.data.DETROLPROV_DIAS).toFixed(4);
        }else{
          params.data.DETROLPROV_DIAS = Number(params.data.DETROLPROV_DIAS).toFixed(2);
        }
        //GSRF
        let index = this.detalleProv.findIndex(x => x === params.data);
        this.provAggrid.refreshaggrid(this.detalleProv, this.defaultColDefDetProv.objeto);
        this.provAggrid.gridApi.setFocusedCell(index, 'DETROLPROV_BASE');
      },
      editable: (params) => {
        return (params.data.DETROLPROV_TIPODET === 'C') ? false : true;
      }
    },
    {
      headerName: 'Base', field: 'DETROLPROV_BASE', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      }, onCellValueChanged: (params) => {
        params.data.DETROLPROV_BASE = Number(params.data.DETROLPROV_BASE).toFixed(2);
        let index = this.detalleProv.findIndex(x => x === params.data);
        this.provAggrid.refreshaggrid(this.detalleProv, this.defaultColDefDetProv.objeto);
        this.provAggrid.gridApi.setFocusedCell(index, 'DETROLPROV_VALOR');
      },
      editable: (params) => {
        return (params.data.DETROLPROV_TIPODET === 'C') ? false : true;
      }
    },
    {
      headerName: 'Valor', field: 'DETROLPROV_VALOR', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      }, onCellValueChanged: (params) => {
        params.data.DETROLPROV_VALOR = Number(params.data.DETROLPROV_VALOR).toFixed(2);
        let index = this.detalleProv.findIndex(x => x === params.data);
        this.provAggrid.refreshaggrid(this.detalleProv, this.defaultColDefDetProv.objeto);
        this.provAggrid.gridApi.setFocusedCell(index, 'DETROLPROV_FECHFINRUBR');
      },
      editable: (params) => {
        return (params.data.DETROLPROV_TIPODET === 'C') ? false : true;
      }
    },
    {
      headerName: 'FechFin', field: 'DETROLPROV_FECHFINRUBR', cellEditor: 'cellPrueba', width: 100,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 600,
          tienecalculos: true,
        }
      },
      editable: (params) => {
        return (params.data.DETROLPROV_TIPODET === 'C') ? false : true;
      }
    },
  ]


  constructor(
    private datePipe: DatePipe,
    private rolCerradoSrv: NomencrolcerradosService,
    private rolProcesoSrv: NomencrolService,

    public usuario: Usuario,
    private init: NuevoComponentService,
    private busquedaSer: InvbusquedaService,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private confirmationService: ConfirmationService,
    private pipe: DatePipe,
    private confIniciales: ConfInicialesService
  ) {
    this.identiUsuario = this.usuario.identificacion;
    this.datePipe.transform(this.fecha, 'dd/MM/yy');

    this.agTable();
  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.defaultColDefDetRol = {
      editable: true,
      width: 130,
      objeto: 'detalleRol'
    };
    this.defaultColDefDetProv = {
      editable: true,
      width: 130,
      objeto: 'detalleProvisiones'
    };
  }

  ngOnInit(): void {
    this.txtENCROL_ANIO = new Date().getFullYear().toString();
    this.rolProcesoSrv.getPeriodoProceso().then(p => {
      this.periodoProceso = this.convertDate(p[0].PERIODO_FECINI) + ' ' + this.convertDate(p[0].PERIODO_FECFIN);
      this.periodoCodigo = p[0].PERIODO_CODIGO;
      this.getListaTipRol();
    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);

    });

    this.displayAcciones = false;
  }
//CDPJ
validarEdicion(data){

  if (data.DETROLINDI_TIPODET === 'C'){
    let index = this.detalleRoles.findIndex(x => x === this.selectedRol);
    this.selectedRol.DETROLINDI_TIPODET = '';
      this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);
      this.mensajeAlerta('info', 'Roles en Proceso', `Ingrese F->Fijo, V->Variable`);
      this.aggrid.gridApi.setFocusedCell(index, 'DETROLINDI_TIPODET');
      return false;
  }else{
    return true;
  }

}
//CDPJ
  async getListaTipRol() {
    let tipRol = {
      TIPROL_CODIGO: "",
      TIPROL_NOMBRE: ""
    }
    await this.rolProcesoSrv.FunListaTipRol().then(lista => {
      this.cmbTIPROL = [tipRol, ...lista];

      this.rolProcesoSrv.getRubros().then(rubros => {
        this.rolProcesoSrv.separarTipo(rubros);
      });

    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);

    });
  }


  convertDate(valor) {
    if (valor === '' || valor === undefined || valor === null) {

      return '';

    }


    // const fecha = new Date(date);

    // let month = '' + (fecha.getMonth() + 1);
    // let day = '' + fecha.getDate();
    // let year = fecha.getFullYear();

    // if (month.length < 2) { month = '0' + month }
    // if (day.length < 2) { day = '0' + day }

    // return `${day}/${month}/${year}`;
    if (valor === '' || valor === undefined || valor === null) {

      return '';

    }

    let res="";
    // const fecha = new Date(date);

    // let month = '' + (fecha.getMonth() + 1);
    // let day = '' + fecha.getDate();
    // let year = fecha.getFullYear();

    // if (month.length < 2) { month = '0' + month }
    // if (day.length < 2) { day = '0' + day }

    // return `${day}/${month}/${year}`;
    //CDPJ
    try{
      if(valor.toString().includes('-')){
        res=this.pipe.transform(valor,'dd/MM/yyyy');
        return res;
      }else{
        return valor;
      }
    }catch(err){         
      return valor;
    } 
    //CDPJ
  }

  fechaYmD(fecha: string) {
    if (fecha === '') {
      return '';
    }
    let fechaArray = fecha.split('/');

    return `${fechaArray[2]}/${fechaArray[1]}/${fechaArray[0]}`;


  }

  async manejarSenales(valor) {
    switch (valor) {
      case 'Guardar':
        if(this.controlg === 0){
          this.spin=true;//CDPJ
          this.controlg=1;
          this.btnGuardar=true;
          this.guardar();
        }
       
        break;
      case 'Salir':
        this.init.tabs.splice(this.init.tabindex, 1);
        break;
      case 'Siguiente':
        this.getSiguiente();
        break;

      case 'Ultimo':
        this.getUltimo();
        break;
      case 'Anterior':
        this.getAnterior();
        break;
      case 'Primero':
        this.getPrimero();
        break;

      case 'Buscar':
        this.busqueda();
        break;

      case 'Asiento':
        this.cmdasilote();
        break;

      case 'Cancelar':
        if (this.lblPERIODO_CODIGO !== '') {

          this.recargar();
        }
        break;

      case 'Borrar':
        this.confirmationService.confirm({
          message: '¿Está seguro que desea eliminar el registro?',
          header: 'Confirmación',
          icon: 'pi pi-exclamation-triangle',
          key: 'confirmElimRol',
          acceptLabel: 'Sí',
          rejectLabel: 'No',
          accept: () => {
            this.cmdBorrar();

          },
          reject: () => {
          }
        })

        break;
      case 'Imprimir':
        this.strNombreForma = this.init.tabs[this.init.tabindex].component;
        this.auxreporte++;
        this.displayDialogReportes = true;
        break;
      case 'Imprimir':
        this.strNombreForma = this.init.tabs[this.init.tabindex].component;
        this.auxreporte++;
        this.displayDialogReportes = true;
        break;
      case 'Almacenardoc':
        if (this.lblENCROLINDI_CODIGO !== '') {
          const usu = await this.confIniciales.obtenerPermisoUsuario(this.usuario.identificacion);
          if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
            this.displayDialogDocumentos = true;
          } else {
            this.messageService.add({
              key: 'rolProceso',
              severity: 'info',
              summary: 'Información',
              detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
            });
          }

        }
        break;
    }
  }


  recargar() {
    this.getDetalleRol();
  }

  async getUltimo() {
    let condicion = '';
    if (this.cmbTIPROL_CODIGO !== '') {
      condicion = `${condicion} AND TIPROL_CODIGO ='${this.cmbTIPROL_CODIGO}'`;//CDPJ
    }
    await this.rolProcesoSrv.rolNavegar('U', '', condicion).then(res => {

      if (res !== null) {
        this.llenarCampos(res[0].ENCROLINDI_CODIGO);
      }
    }).catch(e => {
      ;
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);

    });
  }

  async getPrimero() {
    let condicion = '';
    // if (this.cmbTIPROL_CODIGO !== '') {
    //   condicion = `${condicion}' AND TIPROL_CODIGO ='${this.cmbTIPROL_CODIGO}`;
    // }
    //CDPJ
    if (this.cmbTIPROL_CODIGO !== '') {
      condicion = `${condicion} AND TIPROL_CODIGO ='${this.cmbTIPROL_CODIGO}'`;
    }
    //CDPJ
    await this.rolProcesoSrv.rolNavegar('P', '', condicion).then(res => {

      if (res !== null) {
        this.llenarCampos(res[0].ENCROLINDI_CODIGO);
      }
    }).catch(e => {

      ;
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);

    });
  }

  async getAnterior() {
    // let condicion = `${this.fechaYmD(this.txtPERIODO_FECINI)} ${this.txtEMP_NOMBRE}`;
    // if (this.cmbTIPROL_CODIGO !== '') {
    //   condicion = `${condicion}' AND TIPROL_CODIGO ='${this.cmbTIPROL_CODIGO}`;
    // }
    //CDPJ
    let condicion = `${this.fechaYmD(this.txtPERIODO_FECINI)} ${this.txtEMP_NOMBRE}`;
    if (this.cmbTIPROL_CODIGO !== '') {
      condicion = `${condicion}' AND TIPROL_CODIGO ='${this.cmbTIPROL_CODIGO}`;
    }
    //CDPJ
    await this.rolProcesoSrv.rolNavegar('A', '', condicion).then(res => {

      if (res !== null) {
        this.llenarCampos(res[0].ENCROLINDI_CODIGO);
      }

    }).catch(e => {
      ;
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);

    });
  }


  async getSiguiente() {
    // let condicion = `${this.fechaYmD(this.txtPERIODO_FECINI)} ${this.txtEMP_NOMBRE}`;
    // if (this.cmbTIPROL_CODIGO !== '') {
    //   condicion = `${condicion}' AND TIPROL_CODIGO ='${this.cmbTIPROL_CODIGO}`;
    // }
    //CDPJ
    let condicion = `${this.fechaYmD(this.txtPERIODO_FECINI)} ${this.txtEMP_NOMBRE}`;
    if (this.cmbTIPROL_CODIGO !== '') {
      condicion =`${condicion}' AND TIPROL_CODIGO ='${this.cmbTIPROL_CODIGO}`;
    }
    //CDPJ
    await this.rolProcesoSrv.rolNavegar('S', '', condicion).then(res => {

      if (res !== null) {
        this.llenarCampos(res[0].ENCROLINDI_CODIGO);
      }
    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);

    });
  }


  async llenarCampos(ENCROLINDI_CODIGO) {
    this.spin = true;//GSRF
    await this.rolProcesoSrv.llenaCampos(ENCROLINDI_CODIGO).then(async cab => {

      this.lblENCROLINDI_CODIGO = cab[0].ENCROLINDI_CODIGO;
      this.txtENCROL_ANIO = cab[0].ENCROLINDI_ANIO;
      this.lblPERIODO_CODIGO = cab[0].PERIODO_CODIGO;
      this.lblEMP_CODIGO = cab[0].EMP_CODIGO;
      this.txtEMP_SUELDO = cab[0].EMP_SUELDO.toFixed(2);
      this.txtENCROLINDI_ESTADO = cab[0].ENCROLINDI_ESTADO;
      this.txtEMP_NOMBRE = cab[0].EMP_APELLIDO + ' ' + cab[0].EMP_NOMBRE;
      this.matEncRolIndi.codigo = ENCROLINDI_CODIGO;
      this.matEncRolIndi.detrolindi_ingresos_graviess = (cab[0].DETROLINDI_INGRESOS_GRAVIESS !== null) ? cab[0].DETROLINDI_INGRESOS_GRAVIESS : 0;
      this.matEncRolIndi.emp_porcaporindi = (cab[0].EMP_PORCAPORINDI !== null) ? cab[0].EMP_PORCAPORINDI : 0;
      this.matEncRolIndi.emp_porcaporpatr = (cab[0].EMP_PORCAPORPATR !== null) ? cab[0].EMP_PORCAPORPATR : 0;
      this.matEncRolIndi.cfgctbl_salariobasico = (cab[0].CFGCTBL_SALARIOBASICO !== null) ? cab[0].CFGCTBL_SALARIOBASICO : 0;
      this.matEncRolIndi.detrolprov_basedeciterc = (cab[0].DETROLPROV_BASEDECITERC !== null) ? cab[0].DETROLPROV_BASEDECITERC : 0;
      this.matEncRolIndi.detrolprov_basedecicuar = (cab[0].DETROLPROV_BASEDECICUAR !== null) ? cab[0].DETROLPROV_BASEDECICUAR : 0;
      this.matEncRolIndi.detrolprov_basevaca = (cab[0].DETROLPROV_BASEDECIVACA !== null) ? cab[0].DETROLPROV_BASEDECIVACA : 0;
      this.matEncRolIndi.detrolprov_basefondrese = (cab[0].DETROLPROV_BASEFONDRESE !== null) ? cab[0].DETROLPROV_BASEFONDRESE : 0;
      this.matEncRolIndi.detrolprov_baseiesspatr = (cab[0].DETROLPROV_BASEIESSPATR !== null) ? cab[0].DETROLPROV_BASEIESSPATR : 0;
      this.matEncRolIndi.total_periodos = (cab[0].TOTAL_PERIODOS !== null) ? cab[0].TOTAL_PERIODOS : 0;
      this.matEncRolIndi.emp_porcfondreser = (cab[0].EMP_PORCFONDRESER !== null) ? cab[0].EMP_PORCFONDRESER : 0;


      if (cab[0].ASI_NRO !== null) {
        let asiento = {
          ASI_NRO: cab[0].ASI_NRO
        }
        this.cmbASI_NRO = [{ ASI_NRO: '' }, asiento];
      } else {
        let asiento = {
          ASI_NRO: cab[0].ASI_NRO2
        }
        this.cmbASI_NRO = [{ ASI_NRO: '' }, asiento];
      }



      await this.getDatosAdicionales();

    }).catch(e => {

      /* let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje); */

    });
    this.spin = false;//GSRF
  }

  async getDatosAdicionales() {

    let fechaIni = await this.rolCerradoSrv.encRegistroPeriodo('periodo_fecini', `periodo_codigo=${this.lblPERIODO_CODIGO}`);

    let fechaFin = await this.rolCerradoSrv.encRegistroPeriodo('periodo_fecfin', `periodo_codigo=${this.lblPERIODO_CODIGO}`);

    let empRucide = await this.rolCerradoSrv.encRegistroEmpleado('emp_rucide', `emp_codigo=${this.lblEMP_CODIGO}`);

    let FecIngreso = await this.rolCerradoSrv.encRegistroEmpleado('EMP_FECINGRESO', `emp_codigo=${this.lblEMP_CODIGO}`);

    let FecSalida = await this.rolCerradoSrv.encRegistroEmpleado('EMP_FECSALIDA', `emp_codigo=${this.lblEMP_CODIGO}`);

    let tiprolCodigo = await this.rolCerradoSrv.encRegistroEmpleado('tiprol_codigo', `emp_codigo=${this.lblEMP_CODIGO}`);





    Promise.all([
      fechaIni,
      fechaFin,
      empRucide,
      FecIngreso,
      FecSalida,
      tiprolCodigo
    ]).then(async valores => {
      this.txtPERIODO_FECINI = this.convertDate(valores[0][0].PERIODO_FECINI);
      this.txtPERIODO_FECFIN = this.convertDate(valores[1][0].PERIODO_FECFIN);
      this.txtEMP_RUCIDE = valores[2][0].EMP_RUCIDE;
      this.txtEMP_FECINGRESO = (valores[3][0].EMP_FECINGRESO === null) ? '' : this.convertDate(valores[3][0].EMP_FECINGRESO);
      this.txtEMP_FECSALIDA = (valores[4][0].EMP_FECSALIDA === null) ? '' : this.convertDate(valores[4][0].EMP_FECSALIDA);
      this.txtTIPOROL_CODIGO = valores[5][0].TIPROL_CODIGO;

      await this.rolCerradoSrv.encRegistroTipoRol('tiprol_nombre', `tiprol_codigo =${this.txtTIPOROL_CODIGO}`).then(nomRol => {

        this.txtTIPOROL_CODIGO = this.txtTIPOROL_CODIGO + '-' + nomRol[0].TIPROL_NOMBRE;
        this.getDetalleRol();
      })


    })
      .catch(e => {
        ;
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);

      });



  }

  async funCalculaValorHorasExtras() {
    let resolve;
    let codigo = this.selectedRol.DETROLINDI_CODIGO;
    let sueldo = Number(this.txtEMP_SUELDO);
    const empSueldo = (sueldo % 1 === 0) ? parseInt(this.txtEMP_SUELDO) : Number(this.txtEMP_SUELDO);
    if ((this.selectedRol.DETROLINDI_CODIGO === 'H25' ||
      this.selectedRol.DETROLINDI_CODIGO === 'H50' || this.selectedRol.DETROLINDI_CODIGO === 'H100') && this.selectedRol.DETROLINDI_TIPODET === 'V') {
      resolve = await this.rolProcesoSrv.funCalculaValorHorasExtras(Number(this.txtEMP_SUELDO), this.selectedRol.DETROLINDI_CODIGO, this.selectedRol.DETROLINDI_HORAS).then(r => {

        this.selectedRol.DETROLINDI_INGRESOS = r[0][`NOM_PCK_GENERALIQUIDACION.FUN_SP_OBTIENEVALORHORAEXTRA(${empSueldo},'${codigo}',${this.selectedRol.DETROLINDI_HORAS})`].toFixed(2);
        //this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);
        return;
      }).catch(e => {
        ;
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      })
    }
    return resolve;
  }

  async getDetalleRol() {
    let resolve = false;

    await this.rolProcesoSrv.getDetalleRol(this.lblENCROLINDI_CODIGO).then(async detRol => {

      this.detalleRoles = [...detRol];

      this.detalleRoles.map(det => {

        det.DETROLINDI_INGRESOS = (det.DETROLINDI_INGRESOS !== null) ? (det.DETROLINDI_INGRESOS).toFixed(2) : '0.00';
        det.DETROLINDI_EGRESOS = (det.DETROLINDI_EGRESOS !== null) ? (det.DETROLINDI_EGRESOS).toFixed(2) : '0.00';
        det.DETROLINDI_HORAS = (det.DETROLINDI_HORAS !== null) ? (det.DETROLINDI_HORAS).toFixed(2) : '0.00';
        det.DETROLINDI_DIAS = (det.DETROLINDI_DIAS !== null) ? (det.DETROLINDI_DIAS).toFixed(2) : '0.00';
        det.DETROLINDI_FECHINIRUBR = (det.DETROLINDI_FECHINIRUBR !== null) ? this.convertDate(det.DETROLINDI_FECHINIRUBR) : null;
        det.DETROLINDI_FECHFINRUBR = (det.DETROLINDI_FECHFINRUBR !== null) ? this.convertDate(det.DETROLINDI_FECHFINRUBR) : null;
        if(det.DETROLINDI_CODIGO === 'VACG' || det.DETROLINDI_CODIGO === 'VACP'){
          det.DETROLINDI_EDITABLEVACA=true
        }
      })
      this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);

      return await this.rolProcesoSrv.getDetalleProvision(this.lblENCROLINDI_CODIGO).then(async (detProv) => {

        this.detalleProv = [...detProv];
        console.log('2222',this.detalleProv);
        this.detalleProv.map(detp => {
          detp.DETROLPROV_BASE = (detp.DETROLPROV_BASE !== null) ? (detp.DETROLPROV_BASE).toFixed(2) : '0.00';
          detp.DETROLPROV_VALOR = (detp.DETROLPROV_VALOR !== null) ? (detp.DETROLPROV_VALOR).toFixed(2) : '0.00';
          console.log('99999',detp.DETROLPROV_DIAS);
          console.log('99999',detp.DETROLPROV_CODIGO);
          if (detp.DETROLPROV_CODIGO === 'VAC') {//GSRF
            detp.DETROLPROV_DIAS = (detp.DETROLPROV_DIAS !== null) ? (detp.DETROLPROV_DIAS).toFixed(4) : '0.0000';//GSRF
            this.diasvacapro=  detp.DETROLPROV_DIAS//GSRF
          } else {
            detp.DETROLPROV_DIAS = (detp.DETROLPROV_DIAS !== null) ? (detp.DETROLPROV_DIAS).toFixed(2) : '0.00';//GSRF
          }



        })

        resolve = await this.funBloquearFilasPrestFijos().then((r) => {
          this.provAggrid.refreshaggrid(this.detalleProv, this.defaultColDefDetProv.objeto);
          return this.sumTotales();

        })
        this.botonnuevo = false;
        this.botoneliminar = false;
        this.btnBorrar = false;


      })
    }).catch(e => {
      ;
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);

    });

    return resolve;

  }

  async funBloquearFilasPrestFijos() {
    let promise = await new Promise(async (resolve, reject) => {
      let res = await this.funVerificaCodRubroIngrEgreFijo();
      if (res) { resolve(res) }
      else {
        reject(new Error('Ha Ocurrido Un Error En La Transacción'));
      }
    })

    return promise;
  }


  async funVerificaCodRubroIngrEgreFijo() {
    let resolve = true;
    for (let detRol of this.detalleRoles) {

      await this.rolProcesoSrv.funVerificaCodRubroIngrEgreFijo(detRol.MAERUBRO_CODIGO, Number(this.lblEMP_CODIGO)).then(res => {

        detRol.lock = res[0][`NOM_PCK_MAERUBRO_C.NOM_FNC_RUBRPERTINGREGREFIJO(${this.lblEMP_CODIGO},${detRol.MAERUBRO_CODIGO})`]
      }).catch(e => {

        resolve = false;
      });
    }

    return resolve;
  }

  onTabChange({ index }) {
    this.tabIndex = index;
    this.sumTotales();
  }

  async sumTotales() {
    this.total = {
      totalUno: '0',
      totalDos: '0',
      netoPagar: '0'
    }
    let totalUno = 0;
    let totalDos = 0;
    let totalNeto = 0;
    switch (this.tabIndex) {
      case 0:

        this.detalleRoles.map(det => {

          totalUno = totalUno + Number(det.DETROLINDI_INGRESOS);
          totalDos = totalDos + Number(det.DETROLINDI_EGRESOS);


        });
        totalNeto = totalUno - totalDos;
        this.total.totalUno = totalUno.toFixed(2);
        this.total.totalDos = totalDos.toFixed(2);
        this.total.netoPagar = totalNeto.toFixed(2);
        break;

      case 1:

        this.detalleProv.map(det => {

          totalUno = totalUno + Number(det.DETROLPROV_BASE)
          totalDos = totalDos + Number(det.DETROLPROV_VALOR)

        });

        this.total.totalUno = totalUno.toFixed(2);
        this.total.totalDos = totalDos.toFixed(2);
        this.total.netoPagar = this.total.totalDos;
        break;

    }
    return true;
  }





  selecTipoRol(dato) {
    this.cmbTIPROL_CODIGO = dato.value.TIPROL_CODIGO;
  }
  selecAsiento(event) {
  }

  busqueda() {
    this.types = [
      { label: 'Cod', value: 'ENCROLINDI_CODIGO' },
      { label: 'Código', value: 'NOM_FNC_OBTIENCOD2EMPL(EMP_CODIGO)' },
      { label: 'Empleado', value: 'NOM_FNC_OBTIENENMBREMPL(EMP_CODIGO)' },
      { label: 'Fec.Ing.', value: 'EMP_FECINGRESO' },
      { label: 'Est.Cli.', value: 'EMP_ACTIVO' },
      { label: 'Período', value: 'PERIODO_DESPRICION' },
      { label: 'FecIni', value: 'PERIODO_FECINI' },
      { label: 'FecFin', value: 'PERIODO_FECFIN' }
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', ''];
    this.consulta = `to_char(encrolindi_codigo)  AS ENCROLINDI_CODIGO, NOM_FNC_OBTIENCOD2EMPL(EMP_CODIGO) , NOM_FNC_OBTIENENMBREMPL(EMP_CODIGO),` +
      `nom_fnc_obtienfecingempl(emp_codigo) AS EMP_FECINGRESO, nom_fnc_obtienempactivo (emp_codigo) AS EMP_ACTIVO, periodo_despricion ,` +
      ` a.periodo_fecini as "PERIODO_FECINI" , a.periodo_fecfin as "PERIODO_FECFIN" `;
    this.tabla = 'nom_encrolindi a,nom_maeperiodo b ' 

    this.where = ' a.periodo_codigo = b.periodo_codigo and nvl(encrolindi_estado, \'*\') = \'*\'   and a.periodo_codigo =' + this.periodoCodigo;

    this.busquedaSer.busquedaNomEncRolIndi1(this.periodoCodigo).subscribe((res: any[]) => {

      res.map(emp => {
        emp.PERIODO_FECINI = this.convertDate(emp.PERIODO_FECINI);
        emp.PERIODO_FECINI = this.convertDate(emp.PERIODO_FECINI);
        emp.EMP_FECINGRESO = this.convertDate(emp.EMP_FECINGRESO);
      })

      this.arregloCons = [...res];
      this.displayDialogBusquedaFast = true;
    });
  }

  busquedaRubroTipoR(parametro) {
    this.types = [
      { label: 'CÓDIGO', value: 'REF_CODIGO' },
      { label: 'NOMBRE', value: 'REF_NOMBRE' },
      { label: 'Nombre Corto', value: 'REF_NOMBREC' },
    ];
    this.busquedacampos = [parametro,'', '', ''];
    this.consulta = `*`;
    this.tabla = 'SACI_MAEREFERENCIA';

    this.where = `REF_TIPO = 'NOMTIE'`;

    // this.busquedaSer.busquedaSaciMaeReferencia6(this.where).subscribe((res: any[]) => {

    //   /* res.map( emp => {
    //     emp.FecIni = this.convertDate(emp.FecIni);
    //     emp.FecFin = this.convertDate(emp.FecFin);
    //   }  ) */

    //   this.arregloCons = [...res];
    //   this.displayDialogBusquedaTipoR = true;
    // });
    //CDPJ
    this.busquedaSer.busquedaSaciMaeReferencia11(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccionTipoR(eR[0]);
        } else {
          this.busquedaSer.busquedaSaciMaeReferencia6(this.where).subscribe((res: any[]) => {

            /* res.map( emp => {
              emp.FecIni = this.convertDate(emp.FecIni);
              emp.FecFin = this.convertDate(emp.FecFin);
            }  ) */
      
            this.arregloCons = [...res];
            this.displayDialogBusquedaTipoR = true;
          });
        }
      } else {
        this.busquedaSer.busquedaSaciMaeReferencia6(this.where).subscribe((res: any[]) => {

          /* res.map( emp => {
            emp.FecIni = this.convertDate(emp.FecIni);
            emp.FecFin = this.convertDate(emp.FecFin);
          }  ) */
    
          this.arregloCons = [...res];
          this.displayDialogBusquedaTipoR = true;
        });
      }
    });//CDPJ
  }


  busquedaRubroCodigo(parametro) {

    let parametro2='';//CDPJ
    let strWhere = '';
    let strRUBR_TIPO = this.selectedRol.DETROLINDI_TIPORUBR;
    let strRUBR_CODIGO = this.selectedRol.DETROLINDI_CODIGO;
    let strRUBR_NOMBRE = this.selectedRol.DETROLINDI_DESCRIPCION;

    let strSql = 'maerubro_codigo,maerubro_codrubr,maerubro_nombre, maerubro_gravable,maerubro_aporrent,maerubro_proyrent ';

    this.types = [
      { label: '', value: 'num' },
      { label: 'CÓDIGO', value: 'MAERUBRO_CODRUBR' },
      { label: 'NOMBRE', value: 'MAERUBRO_NOMBRE' },
      { label: 'Grava', value: 'MAERUBRO_GRAVABLE' },
      { label: 'Ap. Rnt.', value: 'MAERUBRO_APORRENT' },
      { label: 'Pr. Rnt.', value: 'MAERUBRO_PROYRENT' },
    ];
    this.busquedacampos = [parametro,'', '', '', '', '', ''];

    console.log('strRUBR_CODIGO',strRUBR_CODIGO)
    console.log(parametro)
     // if (strRUBR_CODIGO !== '') {
    //   strWhere = `where upper(MAERUBRO_CODRUBR) like  upper('${strRUBR_CODIGO}')`;
    // } else if (strRUBR_NOMBRE !== '') {
    //   strWhere = `where upper(maerubro_nombre) like upper('${strRUBR_NOMBRE}')`;
    // }

    // if (strWhere.length > 0) {
    //   if (strRUBR_TIPO !== '') {
    //     strWhere = strWhere + ` and upper(maerubro_tipo) like upper('${strRUBR_TIPO}')`
    //   }
    // } else {
    //   if (strRUBR_TIPO !== '') {
      strWhere = ` upper(maerubro_tipo) like upper('${strRUBR_TIPO}')`
      //   }
      // }
      //if(parametro !== null && parametro !== '' && parametro !== undefined){
        parametro2 = ` upper(MAERUBRO_CODRUBR) like  upper('${strRUBR_CODIGO}') and upper(maerubro_tipo) like upper('${strRUBR_TIPO}')`
      //}
  
      if (strWhere.length > 0) {
        strWhere = strWhere + ` AND COM_CODIGO= '01'`
      } else {
        strWhere = ` ROWNUM<=10 AND COM_CODIGO='01'`;
      }
  
      strWhere = strWhere + ` and MAERUBRO_ACTIVO = 1`;


    this.where = strWhere;
    this.consulta = strSql;
    this.tabla = 'nom_maerubro' //+ ' ' + strWhere;
    // this.busquedaSer.busquedaNomMaeRubro1(strWhere).subscribe((res: any[]) => {
    //   res.map(x => {
    //     x.num = res.indexOf(x) + 1
    //   })
    //   this.arregloCons = [...res];
    //   this.displayDialogBusquedaCodR = true;
    // });
    this.busquedaSer.busquedaNomMaeRubro2(parametro2).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccionCodR(eR[0]);
        } else {
          this.busquedaSer.busquedaNomMaeRubro1(strWhere).subscribe((res: any[]) => {
            if(res !== null && res !== undefined){
              res.map(x => {
                x.num = res.indexOf(x) + 1
              })
              this.arregloCons = [...res];
              this.displayDialogBusquedaCodR = true;
            
            }
          });
        }
      } else {
        this.busquedaSer.busquedaNomMaeRubro1(strWhere).subscribe((res: any[]) => {
          if(res !== null && res !== undefined){
            res.map(x => {
              x.num = res.indexOf(x) + 1
            })
            this.arregloCons = [...res];
            this.displayDialogBusquedaCodR = true;
          
          }
        });
      }
    });


  }

  cmdasilote() {

    if (this.selectedAsiento === undefined || this.selectedAsiento.ASI_NRO === '') {
      this.mensajeAlerta('error', 'Error', 'No ha generado el Asiento Contable');
    } else {
      this.asiento = this.selectedAsiento.ASI_NRO;
      this.displayAsiContable = true;
    }

  }

  manejarSeleccion(emp) {
    this.llenarCampos(emp['ENCROLINDI_CODIGO']);
    this.displayDialogBusquedaFast = false;
  }


  manejarSeleccionCodR(dato) {
    let index = this.detalleRoles.findIndex(x => x === this.selectedRol);
    this.selectedRol.DETROLINDI_CODIGO = dato.MAERUBRO_CODRUBR;
    this.selectedRol.DETROLINDI_DESCRIPCION = dato.MAERUBRO_NOMBRE;
    this.selectedRol.MAERUBRO_GRAVABLE = dato.MAERUBRO_GRAVABLE;
    this.selectedRol.MAERUBRO_PROYRENT = dato.MAERUBRO_PROYRENT;
    this.selectedRol.MAERUBRO_CODIGO = dato.MAERUBRO_CODIGO;
    //this.selectedRol.MAERUBRO_CODIGO = dato.MAERUBRO_CODIGO;
    this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);
    this.displayDialogBusquedaCodR = false;
    this.aggrid.gridApi.setFocusedCell(index, 'DETROLINDI_DESCRIPCION');
  }

  manejarSeleccionTipoR(dato) {
    console.log(dato);
    let index = this.detalleRoles.findIndex(x => x === this.selectedRol);
    //if (this.selectedRol.DETROLINDI_TIPORUBR === '') {
      this.selectedRol.DETROLINDI_TIPORUBR = dato.REF_CODIGO;
    //}
    this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);
    this.displayDialogBusquedaTipoR = false;
    this.aggrid.gridApi.setFocusedCell(index, 'DETROLINDI_CODIGO');
  }



  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }



  reset() {
    this.rol = '';
    this.estado = '';
    this.txtPERIODO_FECINI = '';
    this.txtPERIODO_FECFIN = '';
    this.txtENCROL_ANIO = '';
    this.lblEMP_CODIGO = '';
    this.txtEMP_SUELDO = '';
    this.txtEMP_NOMBRE = '';
    this.lblPERIODO_CODIGO = '';
    this.txtENCROLINDI_ESTADO = '';
    this.txtTIPOROL_CODIGO = '';
    this.txtEMP_FECINGRESO = '';
    this.txtEMP_FECSALIDA = '';
    this.fechaSalida = '';
    this.lblENCROLINDI_CODIGO = '';
    this.txtEMP_RUCIDE = '';
    this.cmbTIPROL = [];
    this.cmbTIPROL_CODIGO = '';
    this.cmbASI_NRO = [];
    this.selectedRol = undefined;
    this.selectedAsiento;


    this.btnGuardar = true;
    this.btnBorrar = true;
    this.botoneliminar = true;
    this.botonnuevo = true;

    this.detalleRoles = [];
    this.detalleProv = [];
    this.total = {
      totalUno: '0',
      totalDos: '0',
      netoPagar: '0'
    }
    this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);
    this.aggrid.refreshaggrid(this.detalleProv, this.defaultColDefDetProv.objeto);

  }


  cambio(event) {

    this.btnGuardar = false;
    switch (this.tabIndex) {
      case 0:
        this.selectedRol.editado = true;
        break;
      case 1:
        this.selectedProv.editado = true;
        break;
    }


  }

  selectDetRol(valor) {
    if (valor.nameobject !== undefined && valor.object !== undefined) {
      this.selectedRol = valor.object;
    } else {
      switch (valor) {
        case 'nuevo':
          this.nuevoDetRol();
          break;
        case 'btnGridBuscar':
          //this.buscarRegistro();
          break;
        case 'cancelar':
          break;
        case 'eliminar':
          if (this.tabIndex === 0) {
            this.funEliminaLineaDetIngrDesc();
          }
          break;

      }
    }

  }

  selectDetProv(valor) {
    if (valor.nameobject !== undefined && valor.object !== undefined) {
      this.selectedProv = valor.object;
    } else {

      switch (valor) {
        case 'nuevo':
          this.nuevoDetProv();
          break;
        case 'btnGridBuscar':
          //this.buscarRegistro();
          break;
        case 'cancelar':
          break;
        case 'eliminar':
          if (this.tabIndex === 1) {
            this.funEliminaLineaDetProv();
          }
          break;

      }
    }

  }

  nuevoDetRol() {
    let newDetRol: DetRolProceso = {
      ENCROLINDI_CODIGO: this.lblENCROLINDI_CODIGO,
      DETROLINDI_NUMSEC: 0,
      COM_CODIGO: '01',
      DETROLINDI_TIPORUBR: '',
      DETROLINDI_CODIGO: '',
      DETROLINDI_DESCRIPCION: '',
      DETROLINDI_TIPODET: '',
      DETROLINDI_HORAS: '0.00',
      DETROLINDI_DIAS: '0.00',
      DETROLINDI_INGRESOS: '0.00',
      DETROLINDI_EGRESOS: '0.00',
      DETROLINDI_FECHFINRUBR: '',
      MAERUBRO_CODIGO: 0,
      MAERUBRO_ORDEN: 0,
      DETROLINDI_FECHINIRUBR: '',
      DETROLINDI_DIASVACAGOZA: 0,
      DETROLINDI_VALOVACAGOZA: 0,
      TRANVACAEMPL_OBSERVACIONES: '',
      editado: true,
      DETROLINDI_EDITABLEVACA:false
    }

    this.selectedRol = newDetRol;
    this.detalleRoles.push(newDetRol);
    this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);

  }

  nuevoDetProv() {
    let newDetProv: DetProvProceso = {
      ENCROLINDI_CODIGO: this.lblENCROLINDI_CODIGO,
      DETROLPROV_NUMSEC: 0,
      COM_CODIGO: '01',
      DETROLPROV_TIPORUBR: 'PROVISION',
      DETROLPROV_CODIGO: '',
      DETROLPROV_DESCRIPCION: '',
      DETROLPROV_TIPODET: '',
      DETROLPROV_HORAS: '0.00',
      DETROLPROV_DIAS: '0.00',
      DETROLPROV_BASE: '0.00',
      DETROLPROV_VALOR: '0.00',
      DETROLPROV_FECHFINRUBR: null,
      MAERUBRO_CODIGO: 0,
      MAERUBRO_ORDEN: 0,
      editado: true
    }

    this.detalleProv.push(newDetProv);
    this.provAggrid.refreshaggrid(this.detalleProv, this.defaultColDefDetProv.objeto);
  }

  guardar() {
    this.spin=true;//CDPJ
    //if (this.tabIndex === 0) {
      this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
        this.defaultColDefDetRol.objeto].stopEditing();
      this.funActualizaRolPagos();
    // }

    // if (this.tabIndex === 1) {
    //   this.init.grids[this.init.tabs[this.init.tabindex].indice + '_' +
    //     this.defaultColDefDetProv.objeto].stopEditing();

    //   //this.nuevoDetProv();
    //   this.funActualizaRolPagos();
    // }
  }

  async calcularRubros() {

    let promise = await new Promise(async (resolve) => {
      let res = await this.funRecalcularRubrosGravIessRol();
      if (res) { resolve(res) }
    })

    return promise;
  }

  async funRecalcularRubrosGravIessRol() {
    this.matEncRolIndi.detrolindi_ingresos_graviess = await this.funDblObtieneValorGrabaIess();
    for (let x of this.detalleRoles) {

      switch (x.DETROLINDI_CODIGO) {
        case 'IEP':
          if (x.DETROLINDI_TIPORUBR === 'DESCUENTO' && x.DETROLINDI_TIPODET === 'C') {
            await this.rolProcesoSrv.funDblObtieValoIessPerso(this.matEncRolIndi.detrolindi_ingresos_graviess, this.matEncRolIndi.emp_porcaporindi)
              .then(res => {
                x.DETROLINDI_EGRESOS = res[0][`NOM_PCK_GENERALIQUIDACION.NOM_FNC_OBTIVALOIESSPERS(${this.matEncRolIndi.detrolindi_ingresos_graviess},${this.matEncRolIndi.emp_porcaporindi})`].toFixed(2);
                //this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);
              }).catch(e => {

                let mensaje: string;
                mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
                this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);

              });
          }
          break;
        case 'D13':
          if (x.DETROLINDI_TIPORUBR === 'INGRESO' && x.DETROLINDI_TIPODET === 'C') {
            await this.rolProcesoSrv.funDblObtieValoDeciTerc(this.matEncRolIndi.detrolindi_ingresos_graviess, this.matEncRolIndi.total_periodos)
              .then(res => {
                x.DETROLINDI_INGRESOS = res[0][`NOM_PCK_GENERALIQUIDACION.NOM_FNC_OBTIVALODECITERC(${this.matEncRolIndi.detrolindi_ingresos_graviess},${this.matEncRolIndi.total_periodos})`].toFixed(2);
                //this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);
              }).catch(e => {
                ;
                let mensaje: string;
                mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
                this.mensajeAlerta('error', 'Error', mensaje);

              });
          }
          break;
        case 'VACP':
          if (x.DETROLINDI_TIPORUBR === 'INGRESO' && x.DETROLINDI_TIPODET === 'C') {
            let dbl_dias_vac = await this.funDblObtieValoVaca();


            await this.rolProcesoSrv.obtivalovaca(this.matEncRolIndi.detrolindi_ingresos_graviess, this.matEncRolIndi.total_periodos,
              dbl_dias_vac).then(r => {
                x.DETROLINDI_INGRESOS = r[0][`NOM_PCK_GENERALIQUIDACION.NOM_FNC_OBTIVALOVACA(${this.matEncRolIndi.detrolindi_ingresos_graviess},${this.matEncRolIndi.total_periodos},${dbl_dias_vac})`].toFixed(2);
                //this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);
              }).catch(e => {

                let mensaje: string;
                mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
                this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);

              });
          }
          break;
        case 'FRE':
          if (x.DETROLINDI_TIPORUBR === 'INGRESO' && x.DETROLINDI_TIPODET === 'C') {
            await this.rolProcesoSrv.funDblObtieFondRese(this.matEncRolIndi.detrolindi_ingresos_graviess, this.matEncRolIndi.emp_porcfondreser).then(r => {
              x.DETROLINDI_INGRESOS = r[0][`NOM_PCK_GENERALIQUIDACION.NOM_FNC_OBTIFONDRESE(${this.matEncRolIndi.detrolindi_ingresos_graviess},${this.matEncRolIndi.emp_porcfondreser})`].toFixed(2);
              //this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);
            }).catch(e => {

              let mensaje: string;
              mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
              this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);

            });
          }
      }

    };
    this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);
    return this.funRecalcularRubrosGravIessProv();

  }


  funDblObtieneValorGrabaIess() {
    let dblVALOR_GRAVA_IESS = 0;

    this.detalleRoles.map(x => {

      if (x.DETROLINDI_TIPORUBR === 'INGRESO' && x.MAERUBRO_GRAVABLE === 'Si') {
        dblVALOR_GRAVA_IESS = dblVALOR_GRAVA_IESS + Number(x.DETROLINDI_INGRESOS);
      }

    })

    return dblVALOR_GRAVA_IESS;
  }

  async funDblObtieValoVaca() {
    let dbl_dias_vac = 0;
    let dbl_dias_trabajados = await this.funObtieneDiasTrabajados();

    dbl_dias_vac = await this.rolProcesoSrv.obtieneDiasVacacion(this.txtEMP_FECINGRESO, this.lblPERIODO_CODIGO, this.matEncRolIndi.total_periodos, dbl_dias_trabajados)
      .then(r => {
        if (!isNullOrUndefined(r[0][`NOM_PCK_GENERALIQUIDACION.FUN_SP_OBTIENEDIASVACACION(${this.txtEMP_FECINGRESO},${this.lblPERIODO_CODIGO},${this.matEncRolIndi.total_periodos},${dbl_dias_trabajados})`])) {
          return r[0][`NOM_PCK_GENERALIQUIDACION.FUN_SP_OBTIENEDIASVACACION(${this.txtEMP_FECINGRESO},${this.lblPERIODO_CODIGO},${this.matEncRolIndi.total_periodos},${dbl_dias_trabajados})`];
        }
        return 0;
      }).catch(e => {

        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);

      });

    return dbl_dias_vac;
  }

  async funObtieneDiasTrabajados() {
    let dias = 0;
    dias = await this.rolProcesoSrv.funObtieneDiasTrabajados(this.lblENCROLINDI_CODIGO).then(days => {
      if (!isNullOrUndefined(days[0])) {
        return days[0]['ENCROLINDI_DIASTRAB'];
      }
      return 0;
    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);

    });

    return dias;
  }




  async funRecalcularRubrosGravIessProv() {
    let strTIPO_RUBRO = ''; let strCODI_RUBRO = ''; let strTIPO_RUBRCALC = '';
    this.matEncRolIndi.detrolindi_ingresos_graviess = this.funDblObtieneValorGrabaIess();

    for (let prov of this.detalleProv) {


      strTIPO_RUBRO = prov.DETROLPROV_TIPORUBR;
      strCODI_RUBRO = prov.DETROLPROV_CODIGO;
      strTIPO_RUBRCALC = prov.DETROLPROV_TIPODET;

      switch (strCODI_RUBRO) {
        case 'D13':

          if (strTIPO_RUBRO === "PROVISION" && strTIPO_RUBRCALC === "C") {
            this.matEncRolIndi.detrolprov_basedeciterc = this.matEncRolIndi.detrolindi_ingresos_graviess;
            prov.DETROLPROV_BASE = this.matEncRolIndi.detrolindi_ingresos_graviess.toFixed(2);
            prov.DETROLPROV_VALOR = await this.funDblObtieValoDeciTerc(this.matEncRolIndi.detrolindi_ingresos_graviess, this.matEncRolIndi.total_periodos);

          }

          break;
        case 'VAC':
          if (strTIPO_RUBRO === "PROVISION" && strTIPO_RUBRCALC === "C") {
            console.log('<<< vaca >>>',this.diasvacapro)
            this.matEncRolIndi.detrolprov_basevaca = this.matEncRolIndi.detrolindi_ingresos_graviess;
            prov.DETROLPROV_BASE = this.matEncRolIndi.detrolindi_ingresos_graviess.toFixed(2);
            //prov.DETROLPROV_VALOR = await this.funDblObtieValoDeciTerc(this.matEncRolIndi.detrolindi_ingresos_graviess, this.matEncRolIndi.total_periodos);
            prov.DETROLPROV_VALOR = await this.funDblObtieValoVacacion1(this.matEncRolIndi.detrolindi_ingresos_graviess, this.matEncRolIndi.total_periodos,this.diasvacapro);//GSRF
          }
          break;
        case 'FRE':
          if (strTIPO_RUBRO === "PROVISION" && strTIPO_RUBRCALC === "C") {
            this.matEncRolIndi.detrolprov_basefondrese = this.matEncRolIndi.detrolindi_ingresos_graviess;
            prov.DETROLPROV_BASE = this.matEncRolIndi.detrolindi_ingresos_graviess.toFixed(2);
            prov.DETROLPROV_VALOR = await this.funDblObtieValofondores(this.matEncRolIndi.detrolindi_ingresos_graviess, '8.33');//GSRF
          }
          break;

        case 'IET':
          if (strTIPO_RUBRO === "PROVISION" && strTIPO_RUBRCALC === "C") {
            this.matEncRolIndi.detrolprov_baseiesspatr = this.matEncRolIndi.detrolindi_ingresos_graviess;
            prov.DETROLPROV_BASE = this.matEncRolIndi.detrolindi_ingresos_graviess.toFixed(2);
            prov.DETROLPROV_VALOR = await this.funDblObtieValoIessPatro(this.matEncRolIndi.detrolindi_ingresos_graviess, this.matEncRolIndi.emp_porcaporpatr);
          }
          break;

      }
    }
    //CDPJ
    this.mensajeAlerta('success', 'Información', 'Rubros calculados satisfactoriamente');
    //CDPJ
    this.provAggrid.refreshaggrid(this.detalleProv, this.defaultColDefDetProv.objeto);

    return this.sumTotales();

  }
  //GSRF
  async funDblObtieValofondores(detrolindi_ingresos_graviess, total_periodos) {
    let valor = 0;
    valor =  await this.rolProcesoSrv.funDblObtieFondRese(this.matEncRolIndi.detrolindi_ingresos_graviess, this.matEncRolIndi.emp_porcfondreser).then(r => {
      let data =  r[0][`NOM_PCK_GENERALIQUIDACION.NOM_FNC_OBTIFONDRESE(${this.matEncRolIndi.detrolindi_ingresos_graviess},${this.matEncRolIndi.emp_porcfondreser})`].toFixed(2);
      return isNullOrUndefined(data) ? 0 : data;
      }).catch(e => {

        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);

      });
    
    return valor;
  }
  //GSRF
  async funDblObtieValoDeciTerc(detrolindi_ingresos_graviess, total_periodos) {
    let valor = 0;
    valor = await this.rolProcesoSrv.funDblObtieValoDeciTerc(Number(detrolindi_ingresos_graviess), total_periodos)
      .then(res => {

        let data = (res[0][`NOM_PCK_GENERALIQUIDACION.NOM_FNC_OBTIVALODECITERC(${detrolindi_ingresos_graviess},${total_periodos})`]);
        return isNullOrUndefined(data) ? 0 : data;
      }).catch(e => {
        ;
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);

      });
    return valor;
  }

  //GSRF
  async funDblObtieValoVacacion1(detrolindi_ingresos_graviess, total_periodos,dia_vaca) {
    let valor = 0;
    console.log('<<< vaca >>>',dia_vaca)
    valor = await this.rolProcesoSrv.funDblObtieValovaca(Number(detrolindi_ingresos_graviess), total_periodos,dia_vaca)
      .then(res => {

        let data = (res[0][`NOM_PCK_GENERALIQUIDACION.NOM_FNC_OBTIVALOVACA(${detrolindi_ingresos_graviess},${total_periodos},${dia_vaca})`]);
        return isNullOrUndefined(data) ? 0 : data;
      }).catch(e => {
        ;
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', mensaje);

      });
    return valor;
  }

  //GSRF
  async funDblObtieValoIessPatro(detrolindi_ingresos_graviess: number, emp_porcaporpatr: number) {
    let valor = 0;
    valor = await this.rolProcesoSrv.funDblObtieValoIessPatro(detrolindi_ingresos_graviess, emp_porcaporpatr).then(res => {

      let data = (res[0][`NOM_PCK_GENERALIQUIDACION.NOM_FNC_OBTIIESSPATR(${detrolindi_ingresos_graviess},${emp_porcaporpatr})`]);
      return isNullOrUndefined(data) ? 0 : data;
    }).catch(e => {
      ;
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', mensaje);

    });

    return valor;
  }

  verificarCabecera() {
    if (this.txtENCROL_ANIO === '') {
      this.mensajeAlerta('error', 'Error', 'Ingrese el año');
      return false;
    }
    if (this.lblEMP_CODIGO === '') {
      this.mensajeAlerta('error', 'Error', 'Ingrese el empleado');
      return false;
    }

    if (this.lblPERIODO_CODIGO === '') {
      this.mensajeAlerta('error', 'Error', 'Ingrese el periodo');
      return false;
    }

    return true;

  }


  async abrirEncRol3() {
    let index = this.detalleRoles.findIndex(x => x === this.selectedRol);
    /* let strTIPO_RUBRO = this.selectedRol.DETROLINDI_TIPORUBR;
    let strCODIGO_RUBRO = this.selectedRol.ENCROLINDI_CODIGO;
    let strTIPO_CALCULO = this.selectedRol.DETROLINDI_TIPODET;
    let dblNUMERO_DIAS = this.selectedRol.DETROLINDI_DIAS; */

    if (this.selectedRol.DETROLINDI_TIPORUBR === 'INGRESO' && (this.selectedRol.DETROLINDI_CODIGO === 'VACG' || this.selectedRol.DETROLINDI_CODIGO === 'VACP')
      && this.selectedRol.DETROLINDI_TIPODET === 'V' && Number(this.selectedRol.DETROLINDI_DIAS) > 0) {

      //this.datosRol3.lblNUM_DIASVACA = Number(this.selectedRol.DETROLINDI_DIAS),
      this.datosRol3.lblNUM_DIASVACA = Math.round(Number(this.selectedRol.DETROLINDI_DIAS)),//GSRF
        this.datosRol3.lblPERIODO_FECINI = this.txtPERIODO_FECINI,
        this.datosRol3.lblEMP_CODIGO = this.lblEMP_CODIGO,
        this.datosRol3.txtPERIODO_FECINI = this.txtPERIODO_FECINI,
        this.datosRol3.txtPERIODO_FECFIN = this.txtPERIODO_FECFIN,
        this.datosRol3.lblPERIODO_CODIGO = this.lblPERIODO_CODIGO,
        this.datosRol3.lblRUBRO_CODRUBR = this.selectedRol.DETROLINDI_CODIGO,
        this.datosRol3.lblENCROLINDI_CODIGO = this.lblENCROLINDI_CODIGO
        this.datosRol3.editado=this.selectedRol.DETROLINDI_EDITABLEVACA

      this.monstrarEncRol3 = true;
    }

    if (this.selectedRol.DETROLINDI_TIPORUBR === 'INGRESO' && this.selectedRol.DETROLINDI_CODIGO === 'SUE'
      && this.selectedRol.DETROLINDI_TIPODET === 'V' && Number(this.selectedRol.DETROLINDI_DIAS) > 0) {

      this.selectedRol.DETROLINDI_INGRESOS = await this.funCalculaSueldoDiasTrab(Number(this.txtEMP_SUELDO), Number(this.selectedRol.DETROLINDI_DIAS));
      this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);
    }


    if (this.selectedRol.DETROLINDI_TIPORUBR === 'INGRESO') {
      this.aggrid.gridApi.setFocusedCell(index, 'DETROLINDI_INGRESOS');
    }
    if (this.selectedRol.DETROLINDI_TIPORUBR === 'DESCUENTO') {
      this.aggrid.gridApi.setFocusedCell(index, 'DETROLINDI_EGRESOS');
    }

    this.sumTotales();

  }

  async funCalculaSueldoDiasTrab(dblEMP_REMSALUNI: number, dblNUMERO_DIAS: number) {
    let calSueDTRab = await this.rolProcesoSrv.calculaSueldoDiasTrab(dblEMP_REMSALUNI, dblNUMERO_DIAS).then(res => {

      return res[0][`NOM_PCK_ENCROLINDI.NOM_FNC_OBTISUELDOPRORDIASMES(${dblNUMERO_DIAS},${dblEMP_REMSALUNI})`];

    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
    })

    return calSueDTRab;

  }

  salirf(elemento) {
    if (!elemento) {//llega false
      this.monstrarEncRol3 = false;
      const index = this.detalleRoles.findIndex(i => i === this.selectedRol);
      this.selectedRol.DETROLINDI_FECHINIRUBR = this.rolProcesoSrv.AUXPANTVACA.fecha_inicio;
      this.selectedRol.DETROLINDI_FECHFINRUBR = this.rolProcesoSrv.AUXPANTVACA.fecha_fin;
      this.selectedRol.DETROLINDI_DIAS = this.rolProcesoSrv.AUXPANTVACA.detrolindi_diasvacagoza;
      this.selectedRol.DETROLINDI_INGRESOS = this.rolProcesoSrv.AUXPANTVACA.valor_vacaciones;
      this.selectedRol.TRANVACAEMPL_OBSERVACIONES = this.rolProcesoSrv.AUXPANTVACA.observacion;
      this.detalleRoles[index].DETROLINDI_FECHINIRUBR = this.rolProcesoSrv.AUXPANTVACA.fecha_inicio;
      this.detalleRoles[index].DETROLINDI_FECHFINRUBR = this.rolProcesoSrv.AUXPANTVACA.fecha_fin;
      this.detalleRoles[index].DETROLINDI_DIAS = this.rolProcesoSrv.AUXPANTVACA.detrolindi_diasvacagoza;
      this.detalleRoles[index].DETROLINDI_INGRESOS = this.rolProcesoSrv.AUXPANTVACA.valor_vacaciones;
      this.detalleRoles[index].TRANVACAEMPL_OBSERVACIONES = this.rolProcesoSrv.AUXPANTVACA.observacion;
      this.detalleRoles[index].DETROLINDI_EDITABLEVACA=this.rolProcesoSrv.AUXPANTVACA.control//CDPJ
      this.control=this.rolProcesoSrv.AUXPANTVACA.control//CDPJ
      this.FunUbicaVacacionGozadaEnRubroSueldo();
      this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);
    }
  }

  FunUbicaVacacionGozadaEnRubroSueldo() {
    let intDiasVacaGoza = 0;
    let dblValorVacaGoza = 0, strCodRubro = '';
    let intDiasSueldo = 0, dblValorSueldo = 0, strTipoCalcRubrSuel = '';

    for (const item of this.detalleRoles) {
      strCodRubro = item.DETROLINDI_CODIGO;
      console.log(strCodRubro);
      if (strCodRubro === 'VACG') {
        intDiasVacaGoza = intDiasVacaGoza + Number(item.DETROLINDI_DIAS);
        dblValorVacaGoza = dblValorVacaGoza + Number(item.DETROLINDI_INGRESOS);
      }
    }
    /*With vspNOM_DETROL
        For i = 1 To .MaxRows
            .Row = i
            .Col = 3: strCodRubro = Trim(.Text)
            If strCodRubro = "VACG" Then
                .Col = 7: intDiasVacaGoza = intDiasVacaGoza + Val(formatearNumero(.Text, 2, glb_str_simbolodec))
                .Col = 8: dblValorVacaGoza = dblValorVacaGoza + Val(formatearNumero(.Text, 2, glb_str_simbolodec))
            End If
        Next*/
    //--ubica en columna sueldo
    for (let i = 0; i < this.detalleRoles.length; i++) {
      console.log(this.detalleRoles[i]);
      strCodRubro = this.detalleRoles[i].DETROLINDI_CODIGO;
      console.log(strCodRubro);
      if (strCodRubro === 'SUE') {
        strTipoCalcRubrSuel = this.detalleRoles[i].DETROLINDI_TIPODET;
        console.log(strTipoCalcRubrSuel);
        if (strTipoCalcRubrSuel === 'C') {
          intDiasSueldo = Number(this.detalleRoles[i].DETROLINDI_DIASORIG);
          dblValorSueldo = Number(this.detalleRoles[i].DETROLINDI_SUELORIG);
          this.detalleRoles[i].DETROLINDI_DIASVACAGOZA = Number(intDiasVacaGoza).toFixed(2);
          this.detalleRoles[i].DETROLINDI_VALOVACAGOZA = Number(dblValorVacaGoza).toFixed(2);
          intDiasSueldo = intDiasSueldo - intDiasVacaGoza;
          dblValorSueldo = dblValorSueldo - dblValorVacaGoza;
          this.detalleRoles[i].DETROLINDI_DIAS = Number(intDiasSueldo).toFixed(2);
          this.detalleRoles[i].DETROLINDI_INGRESOS = Number(dblValorSueldo).toFixed(2);
        }
      }
    }
    this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);

  }

  verificarTipoRol() {
    let index = this.detalleRoles.findIndex(x => x === this.selectedRol);
    //this.sumTotales();
    if (this.selectedRol.DETROLINDI_TIPODET === 'V' || this.selectedRol.DETROLINDI_TIPODET === 'F') {
      if (this.selectedRol.DETROLINDI_TIPODET === 'F') {
        this.monstrarEncRol1 = true;
      }
    } else {
      this.selectedRol.DETROLINDI_TIPODET = '';
      this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);
      this.mensajeAlerta('info', 'Roles en Proceso', `Ingrese F->Fijo, V->Variable`);
      this.aggrid.gridApi.setFocusedCell(index, 'DETROLINDI_TIPODET');
      return;
    }

  }

  salirFechaFin(elemento) {
    if (this.tabIndex === 0) {

      if (elemento !== '') {
        this.selectedRol.DETROLINDI_FECHFINRUBR = elemento;
        this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);
      }
    } else {
      if (elemento !== '') {
        this.selectedProv.DETROLPROV_FECHFINRUBR = elemento;
        this.provAggrid.refreshaggrid(this.detalleProv, this.defaultColDefDetProv.objeto);
      }
    }


    this.monstrarEncRol1 = false;
  }


  /* FunCalculaTotalesRol(){
    let dblTOT_INGRESOS  = 0;
    let dblTOT_EGRESOS   = 0;
    let dblNETO_PAGAR    = 0;
    this.detalleRoles.map( det => {
     dblTOT_INGRESOS = dblTOT_INGRESOS + Number(det.DETROLINDI_INGRESOS);
     dblTOT_EGRESOS = dblTOT_EGRESOS + Number(det.DETROLINDI_INGRESOS);
    })
 
 
  } */


  /**
   * /////////////////////////////////////////////////////////////////////////////////////////////////
   * /////////////////////////////////////////////////////////////////////////////////////////////////
   * /////////////////////////////////////////////////////////////////////////////////////////////////
   * /////////////////////////////////////////////////////////////////////////////////////////////////
   * /////////////////////////////////////////////////////////////////////////////////////////////////
   * /////////////////////////////////////////////////////////////////////////////////////////////////
   * /////////////////////////////////////////////////////////////////////////////////////////////////
   * /////////////////////////////////////////////////////////////////////////////////////////////////
   * /////////////////////////////////////////////////////////////////////////////////////////////////
   * 
   * 
   * ----------------------------------------------------TABLA PROVISIONES
   */

  async funCol3Prov() {
    let glb_str_NOMwhere = '';

    let strMAERUBRO_TIPO = this.selectedProv.DETROLPROV_TIPORUBR;
    let strMAERUBRO_CODRUBR = this.selectedProv.DETROLPROV_CODIGO;

    if (strMAERUBRO_TIPO !== '') {
      glb_str_NOMwhere = ` maerubro_tipo = ${strMAERUBRO_TIPO} and MAERUBRO_ACTIVO = 1`
    }

    if (strMAERUBRO_CODRUBR !== '') {
      await this.rolProcesoSrv.funCol3Prov(strMAERUBRO_CODRUBR, strMAERUBRO_TIPO).then(r => {

        if (r === null) {
          this.busquedaProvRubroCodigo();
        } else {

          this.selectedProv.MAERUBRO_CODIGO = r[0].MAERUBRO_CODIGO;
          this.selectedProv.DETROLPROV_DESCRIPCION = r[0].MAERUBRO_NOMBRE;
          this.provAggrid.refreshaggrid(this.detalleProv, this.defaultColDefDetProv.objeto);

        }

      }).catch(e => {

        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
      })
    } else {
      this.busquedaProvRubroCodigo();
    }
  }

  busquedaProvRubroCodigo() {


    let strWhere = '';
    let strRUBR_TIPO = this.selectedProv.DETROLPROV_TIPORUBR;
    let strRUBR_CODIGO = this.selectedProv.DETROLPROV_CODIGO;
    let strRUBR_NOMBRE = this.selectedProv.DETROLPROV_DESCRIPCION;

    let strSql = 'maerubro_codigo,maerubro_codrubr,maerubro_nombre,maerubro_gravable,maerubro_aporrent,maerubro_proyrent ';

    this.types = [
      { label: '', value: 'num' },
      { label: 'CÓDIGO', value: 'MAERUBRO_CODRUBR' },
      { label: 'NOMBRE', value: 'MAERUBRO_NOMBRE' },
      { label: 'Grava', value: 'MAERUBRO_GRAVABLE' },
      { label: 'Ap. Rnt.', value: 'MAERUBRO_APORRENT' },
      { label: 'Pr. Rnt.', value: 'MAERUBRO_PROYRENT' },
    ];
    this.busquedacampos = ['', '', '', '', ''];



    if (strRUBR_CODIGO !== '') {
      if (strWhere !== '') {

        strWhere = `where upper(maerubro_codigo) like upper(${strRUBR_CODIGO})`;
      }
    }/*  else if (strRUBR_NOMBRE !== '' ) {
      strWhere = `where upper(maerubro_nombre) like upper('${strRUBR_NOMBRE}')`;
    } */

    if (strWhere.length > 0) {
      if (strRUBR_TIPO !== '') {
        strWhere = strWhere + ` and upper(maerubro_tipo) like upper('${strRUBR_TIPO}')`
      }
    } else {
      if (strRUBR_TIPO !== '') {
        strWhere = `where upper(maerubro_tipo) like upper('${strRUBR_TIPO}')`
      }
    }


    if (strWhere.length > 0) {
      strWhere = strWhere + ` AND COM_CODIGO= '01'`
    } else {
      strWhere = `where ROWNUM<=10 AND COM_CODIGO='01'`;
    }

    strWhere = strWhere + ` and MAERUBRO_ACTIVO = 1`;


    this.where = '';
    this.consulta = strSql;
    this.tabla = 'nom_maerubro' + ' ' + strWhere;
    this.busquedaSer.busquedaNomMaeRubro1(strWhere).subscribe((res: any[]) => {



      res.map(x => {
        x.num = res.indexOf(x) + 1
      })

      this.arregloCons = [...res];
      this.displayDialogBusquedaCodProv = true;
    });


  }

  manejarSeleccionCodProv(dato) {
    let index = this.detalleProv.findIndex(x => x === this.selectedProv);
    this.selectedProv.MAERUBRO_CODIGO = dato.MAERUBRO_CODIGO;
    this.selectedProv.DETROLPROV_CODIGO = dato.MAERUBRO_CODRUBR;
    this.selectedProv.DETROLPROV_DESCRIPCION = dato.MAERUBRO_NOMBRE

    this.selectedProv.MAERUBRO_APORRENT = dato.MAERUBRO_APORRENT;
    this.selectedProv.MAERUBRO_GRAVABLE = dato.MAERUBRO_GRAVABLE;
    this.selectedProv.MAERUBRO_PROYRENT = dato.MAERUBRO_PROYRENT;
    this.displayDialogBusquedaCodProv = false;
    this.provAggrid.refreshaggrid(this.detalleProv, this.defaultColDefDetProv.objeto);
    this.provAggrid.gridApi.setFocusedCell(index, 'DETROLPROV_DESCRIPCION');
  }


  verificarTipoProv() {

    let index = this.detalleProv.findIndex(x => x === this.selectedProv);
    //this.sumTotales();
    if (this.selectedProv.DETROLPROV_TIPODET === 'V' || this.selectedProv.DETROLPROV_TIPODET === 'F') {
      if (this.selectedProv.DETROLPROV_TIPODET === 'F') {
        this.monstrarEncRol1 = true;
      }
    } else {
      this.selectedProv.DETROLPROV_TIPODET = '';
      this.provAggrid.refreshaggrid(this.detalleProv, this.defaultColDefDetProv.objeto);
      this.mensajeAlerta('info', 'Roles en Proceso', `Ingrese F->Fijo, V->Variable`);
      this.provAggrid.gridApi.setFocusedCell(index, 'DETROLPROV_TIPODET');
      return;
    }

  }









  async funActualizaRolPagos() {
    let resolve = true;
    if (this.funValidaNulos()) {
      //resolve = false;
      this.spin=false;//CDPJ
      this.controlg=0;
      this.btnGuardar=false;
      return;
    }


    for (let detRol of this.detalleRoles) {
      //if (detRol.editado) { //GSRF
        let index = this.detalleRoles.findIndex(x => x === detRol);
        detRol.MAERUBRO_ORDEN = index;
        resolve = await this.rolProcesoSrv.actuDetRolIndi(detRol).then(res => {
          detRol.editado = false;
          return true;

        }).catch(e => {

          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
          this.spin=false;//CDPJ
          this.controlg=0;
          this.btnGuardar=false;
          return false;
        })
     // }//GSRF

    }
    console.log('*/*/ afuera',resolve);
    if (resolve) {
      console.log('*/*/ entra',resolve);
      this.funActualizaRolProvision();
    }


  }


  async funActualizaRolProvision() {
    let resolve = true;
    if (this.funValidaFechaFin()) {
      this.spin=false;//CDPJ
      return;
    }

    for (let detProv of this.detalleProv) {
     // console.log('*/*/ entra',detProv);
      //if (detProv.editado) {
       // console.log('*/*/ entra',detProv.editado);
        let index = this.detalleProv.findIndex(x => x === detProv);
        detProv.MAERUBRO_ORDEN = index;
        resolve = await this.rolProcesoSrv.actuDetRolProv(detProv).then(res => {
          detProv.editado = false;
          return true;
        }).catch(e => {

          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
          this.spin=false;//CDPJ
          this.controlg=0;
          this.btnGuardar=false;
          return false;
        });
     // }
    }

    if (resolve) {
      this.funActuInfoMaeRubroRol();
    }
  }

  funValidaFechaFin() {
    let valida = false;
    for (let detProv of this.detalleProv) {
      let index = this.detalleProv.findIndex(x => x === detProv);
      if ((detProv.DETROLPROV_FECHFINRUBR === '' || detProv.DETROLPROV_FECHFINRUBR === null) && detProv.DETROLPROV_TIPODET === 'F') {
        this.selectedProv.DETROLPROV_FECHFINRUBR = '';
        this.provAggrid.refreshaggrid(this.detalleProv, this.defaultColDefDetProv.objeto);
        this.mensajeAlerta('info', 'Roles en Proceso', `Ingrese una fecha fin`);
        this.provAggrid.gridApi.setFocusedCell(index, 'DETROLPROV_FECHFINRUBR');
        valida = true;
      }
    }
    return valida;
  }



  funValidaNulos() {
    if (this.lblENCROLINDI_CODIGO === '0' || this.lblENCROLINDI_CODIGO === '') {
      return true;
    }
    if (this.lblEMP_CODIGO === '0' || this.lblEMP_CODIGO === '') {
      return true;
    }

    return !this.funContrTipRolNoNulo();

  }



  funContrTipRolNoNulo() {
    for (let detRol of this.detalleRoles) {
      if (detRol.DETROLINDI_TIPODET === '') {
        this.mensajeAlerta('error', 'Roles Proceso', 'Especificar el tipo de rubro');
        let index = this.detalleRoles.findIndex(x => x === detRol);
        this.aggrid.gridApi.setFocusedCell(index, 'DETROLINDI_TIPODET');
        return false;
      }
    }
    return true;
  }

  async funActuInfoMaeRubroRol() {
    await this.rolProcesoSrv.actuInfoAdicRubrRol(this.lblENCROLINDI_CODIGO).then(() => {
      this.funActuInfoMaeRubroProv()
    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
      this.spin=false;//CDPJ
      this.controlg=0;
      this.btnGuardar=false;
    });
  }

  async funActuInfoMaeRubroProv() {
    await this.rolProcesoSrv.actuInfoAdicRubrProv(this.lblENCROLINDI_CODIGO).then(() => {
      this.funActualizaParametroCalculos();
    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
      this.spin=false;//CDPJ
      this.controlg=0;
      this.btnGuardar=false;
    });
  }

  async funActualizaParametroCalculos() {
    await this.rolProcesoSrv.actuInfoEncRolIndi(this.matEncRolIndi).then(() => {

      this.funActualizaDecimalesBaseDatos();

    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
      this.spin=false;//CDPJ
      this.controlg=0;
      this.btnGuardar=false;
    });
  }

  async funActualizaDecimalesBaseDatos() {
    await this.rolProcesoSrv.actualizaDecimalesBaseDatos(this.matEncRolIndi.codigo).then(() => {

      this.funActuInfoVacaRol();

    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
      this.spin=false;//CDPJ
      this.controlg=0;
      this.btnGuardar=false;
    });
  }

  async funActuInfoVacaRol() {
    await this.rolProcesoSrv.funActuInfoVacaRol(this.lblENCROLINDI_CODIGO).then(() => {
      this.funActuInfoVacaGozadaPagada();
    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
      this.spin=false;//CDPJ
      this.controlg=0;
      this.btnGuardar=false;
    });
  }

  async funActuInfoVacaGozadaPagada() {

    this.funGlbObtieneVacaXEmpleXPeriodo();



  }

  async funGlbObtieneVacaXEmpleXPeriodo() {
    let resolve = true;
    let n_regMatrPeri = 0
    await this.rolProcesoSrv.elimVacaPorNumRolIndi(this.lblENCROLINDI_CODIGO).then(async () => {

      await this.rolProcesoSrv.obtiSumDiasProrXPeri(this.lblEMP_CODIGO).then(res => {


        if (isNullOrUndefined(res)) {
          this.mat_periodos.length = 0;
        } else {
          res.map(x => {
            let matPeriodo: Mat_periodo = {
              PERIODO: x.TRANVACAEMPL_PERIODO,
              DIAS_PERIOD: x.TRANVACAEMPL_DIASPRORR,
              SALDO_DIAS: x.TRANVACAEMPL_DIASPRORR,
              VALOR: x.TRANVACAEMPL_VALOR,
            }
            this.mat_periodos.push(matPeriodo);
            n_regMatrPeri++;
          })
        }



        this.obtiInfoVacGozPagXEmpl(n_regMatrPeri)



      })


    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
      this.spin=false;//CDPJ
      this.controlg=0;
      this.btnGuardar=false;
    });
  }

  async obtiInfoVacGozPagXEmpl(n_regMatrPeri: Number) {
    let n_regMatrVaca = 0;
    await this.rolProcesoSrv.obtiInfoVacGozPagXEmpl(this.lblENCROLINDI_CODIGO).then((res) => {

      if (isNullOrUndefined(res)) {
        this.mat_vacaciones.length = 0;
      } else {
        res.map(x => {
          let matvaca: Mat_vacacion = {

            MAERUBRO_CODRRBR: x.MAERUBRO_CODRUBR,
            DIAS_TOMADOS: x.DIAS_VACATOMA,
            VALOR: x.VACATOMA_IMPORTE,
            SALDO_DIAS: x.DIAS_VACATOMA,
            FEC_INIVACA: x.FECINI_VACACION,
            FEC_FINVACA: x.FECFIN_VACACION,
            OBSERVACION: x.VACATOMA_OBSEV,
            NUMSEC: x.NUMSEC

          }
          this.mat_vacaciones.push(matvaca);
          n_regMatrVaca++;
        })
      }


      this.registraVacaciones(n_regMatrVaca, n_regMatrPeri);

    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
      this.spin=false;//CDPJ
      this.controlg=0;
      this.btnGuardar=false;
    });
  }

  async registraVacaciones(n_regMatrVaca: number, n_regMatrPeri: Number) {
    let aux_saldo_dias_v;
    let aux_saldo_valor_v;
    let aux_saldo_dias_p;
    let aux_saldo_valor_p;


    let matVacacion_gp: Mat_vacacion_gp[] = [];

    let n_regMatVacaGozPag = 0;

    for (let i = 0; i < n_regMatrVaca; i++) {

      aux_saldo_dias_v = this.mat_vacaciones[i].SALDO_DIAS;
      aux_saldo_valor_v = this.mat_vacaciones[i].VALOR;

      for (let j = 0; j < n_regMatrPeri; j++) {


        aux_saldo_dias_p = this.mat_periodos[j].SALDO_DIAS;
        aux_saldo_valor_p = this.mat_periodos[j].VALOR;

        if (this.mat_periodos[j].SALDO_DIAS > 0) {
          if (aux_saldo_dias_v <= aux_saldo_dias_p) {
            let mat_vacacion_gp: Mat_vacacion_gp = {

              PERIODO: this.mat_periodos[j].PERIODO,
              VALOR: aux_saldo_valor_v,
              DIAS: aux_saldo_dias_v,
              MAERUBRO_CODRRBR: this.mat_vacaciones[i].MAERUBRO_CODRRBR,
              FEC_INIVACA: this.mat_vacaciones[i].FEC_INIVACA,
              FEC_FINVACA: this.mat_vacaciones[i].FEC_FINVACA,
              OBSERVACION: this.mat_vacaciones[i].OBSERVACION,
              NUMSEC: this.mat_vacaciones[i].NUMSEC,
            }
            matVacacion_gp.push(mat_vacacion_gp);

            this.mat_periodos[j].SALDO_DIAS = this.mat_periodos[j].SALDO_DIAS - aux_saldo_dias_v;
            this.mat_periodos[j].VALOR = this.mat_periodos[j].VALOR - aux_saldo_valor_v;
            aux_saldo_dias_v = 0;
          } else {
            let mat_vacacion_gp: Mat_vacacion_gp = {

              PERIODO: this.mat_periodos[j].PERIODO,
              VALOR: aux_saldo_valor_p,
              DIAS: aux_saldo_dias_p,
              MAERUBRO_CODRRBR: this.mat_vacaciones[i].MAERUBRO_CODRRBR,
              FEC_INIVACA: this.mat_vacaciones[i].FEC_INIVACA,
              FEC_FINVACA: this.mat_vacaciones[i].FEC_FINVACA,
              OBSERVACION: this.mat_vacaciones[i].OBSERVACION,
              NUMSEC: this.mat_vacaciones[i].NUMSEC,
            }
            matVacacion_gp.push(mat_vacacion_gp);
            aux_saldo_dias_v = aux_saldo_dias_v - aux_saldo_dias_p;
            aux_saldo_valor_v = aux_saldo_valor_v - aux_saldo_valor_p;
            this.mat_vacaciones[i].SALDO_DIAS = aux_saldo_dias_v;
            this.mat_vacaciones[i].VALOR = aux_saldo_valor_v;
            this.mat_periodos[j].SALDO_DIAS = 0;
            this.mat_periodos[j].VALOR = 0;
          }

          n_regMatVacaGozPag++;
          if (aux_saldo_dias_v === 0) {
            break;
          }
        }

      }

    }


    for (let i = 0; i < n_regMatVacaGozPag; i++) {
      await this.rolProcesoSrv.inserVacacionEmple(
        matVacacion_gp[i].MAERUBRO_CODRRBR,
        matVacacion_gp[i].PERIODO,
        this.txtPERIODO_FECINI,
        this.txtPERIODO_FECINI,
        matVacacion_gp[i].DIAS,
        matVacacion_gp[i].VALOR,
        this.lblPERIODO_CODIGO,
        this.lblEMP_CODIGO,
        this.lblENCROLINDI_CODIGO,
        this.convertDate(matVacacion_gp[i].FEC_INIVACA),
        this.convertDate(matVacacion_gp[i].FEC_FINVACA),
        matVacacion_gp[i].OBSERVACION,
        matVacacion_gp[i].NUMSEC
      ).catch(e => {

        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
        this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
        this.spin=false;//CDPJ
        this.controlg=0;
        this.btnGuardar=false;
      });
    }


    this.funActuSaldoVacaGozadaPagada();


  }

  async funActuSaldoVacaGozadaPagada() {
    await this.rolProcesoSrv.funActuSaldoVacaGozadaPagada(this.lblENCROLINDI_CODIGO, this.lblEMP_CODIGO).then(() => {
      this.funGeneraAuditoriaRolPagos();
    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
      this.spin=false;//CDPJ
      this.controlg=0;
      this.btnGuardar=false;
    });
  }


  async funGeneraAuditoriaRolPagos() {
    await this.rolProcesoSrv.funGeneraAuditoriaRolPagos(this.lblENCROLINDI_CODIGO).then(() => {

      this.mensajeAlerta('success', 'Información', 'Datos guardados');
      this.btnGuardar = true;
      this.spin=false;//CDPJ
      this.controlg=0;
      this.recargar();//GSRF
    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);
      this.spin=false;//CDPJ
      this.controlg=0;
      this.btnGuardar=false;
    });
  }

  ///////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////
  ///////////////////////        BOTON ELIMINAR      ////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////



  async funEliminaLineaDetIngrDesc() {
    this.spin = true;//GSRF
    console.log('aqui --- 2',this.selectedRol)
    //GSRF
    if(this.selectedRol.DETROLINDI_NUMSEC===0){
      console.log('dentro --- 2',this.selectedRol.DETROLINDI_NUMSEC);
      console.log('dentro --- 2', this.rolProcesoSrv.numcellnomencrol);
      
      this.detalleRoles.splice(
          this.rolProcesoSrv.numcellnomencrol,
        1);
       this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);
          this.mensajeAlerta('success', 'Información', 'Datos eliminados satisfactoriamente');
          this.sumTotales();
        
    }else{
    //GSRF
        await this.rolProcesoSrv.elimDetRolIndi(this.lblENCROLINDI_CODIGO, this.selectedRol.DETROLINDI_NUMSEC).then(() => {

          if (this.selectedRol.DETROLINDI_CODIGO === 'VACG') {
            this.detalleRoles.map(det => {

              if (det.DETROLINDI_CODIGO === 'SUE') {
                det.DETROLINDI_DIAS = det.DETROLINDI_DIASORIG;
                det.DETROLINDI_INGRESOS = det.DETROLINDI_SUELORIG;

              }
            })
          }


          this.calcularEliminados().then(r => {

            this.funRecalcularRubrosGravIessRol().then(r => {

              this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);
              this.mensajeAlerta('success', 'Información', 'Datos eliminados satisfactoriamente');
            });

          })


        }).catch(e => {

          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
          this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);

        });
    }
    await this.calcularRubros();//GSRF
    this.spin = false;//GSRF
  }


  async calcularEliminados() {

    let promise = await new Promise(async (resolve) => {
      let res = await this.getDetalleRol();
      if (res) { resolve(res) }
    })

    return promise;
  }


  async funEliminaLineaDetProv() {
    await this.rolProcesoSrv.elimDetRolprov(this.lblENCROLINDI_CODIGO, this.selectedProv.DETROLPROV_NUMSEC).then(() => {
      this.calcularEliminados().then(r => {

        this.funRecalcularRubrosGravIessRol().then(r => {

          this.provAggrid.refreshaggrid(this.detalleProv, this.defaultColDefDetProv.objeto);
          this.mensajeAlerta('success', 'Información', 'Datos eliminados satisfactoriamente');
        });

      })

    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);

    });
  }

  async cmdBorrar() {
    await this.rolProcesoSrv.elimiRegiRol(this.lblPERIODO_CODIGO, this.lblEMP_CODIGO).then(() => {

      this.reset();
    }).catch(e => {

      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(e.error);
      this.mensajeAlerta('error', 'Error', `Ha Ocurrido Un Error En La Transacción: ${mensaje}`);

    });
  }




  mensajeAlerta(tipo, titulo, mensaje) {
    this.messageService.add({
      key: 'rolProceso',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
  }

  cerrarReportes() {
    this.displayDialogReportes = false;
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos = false;
    }
  }

  acciones() {
    this.displayAcciones = !this.displayAcciones;
    const elemento = document.getElementsByClassName('layout-config');
    if (this.displayAcciones === true) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className += ' layout-config-active';
      }
    } else if (this.displayAcciones === false) {
      for (let i = 1; i <= elemento.length; i++) {
        elemento[i - 1].className = 'layout-config';
      }
    }
  }

  closeSidebar() {
    this.displayAcciones = false;
    const elemento = document.getElementsByClassName('layout-config');
    for (let i = 1; i <= elemento.length; i++) {
      elemento[i - 1].className = 'layout-config';
    }
  }

  async recalcular() {
    this.closeSidebar();
    
    await this.calcularRubros();

    this.detalleRoles.map(det => {

      det.DETROLINDI_INGRESOS = (det.DETROLINDI_INGRESOS !== null) ? Number(det.DETROLINDI_INGRESOS).toFixed(2) : '0.00';
      det.DETROLINDI_EGRESOS = (det.DETROLINDI_EGRESOS !== null) ? Number(det.DETROLINDI_EGRESOS).toFixed(2) : '0.00';
      det.DETROLINDI_HORAS = (det.DETROLINDI_HORAS !== null) ? Number(det.DETROLINDI_HORAS).toFixed(2) : '0.00';
      det.DETROLINDI_DIAS = (det.DETROLINDI_DIAS !== null) ? Number(det.DETROLINDI_DIAS).toFixed(2) : '0.00';
      det.DETROLINDI_FECHINIRUBR = (det.DETROLINDI_FECHINIRUBR !== null) ? this.convertDate(det.DETROLINDI_FECHINIRUBR) : null;
      det.DETROLINDI_FECHFINRUBR = (det.DETROLINDI_FECHFINRUBR !== null) ? this.convertDate(det.DETROLINDI_FECHFINRUBR) : null;
      det.editado = true;
    })
    this.aggrid.refreshaggrid(this.detalleRoles, this.defaultColDefDetRol.objeto);

    this.detalleProv.map(detp => {
      detp.DETROLPROV_BASE = (detp.DETROLPROV_BASE !== null) ? Number(detp.DETROLPROV_BASE).toFixed(2) : '0.00';
      detp.DETROLPROV_VALOR = (detp.DETROLPROV_VALOR !== null) ? Number(detp.DETROLPROV_VALOR).toFixed(2) : '0.00';
      //detp.DETROLPROV_DIAS = (detp.DETROLPROV_DIAS !== null) ? Number(detp.DETROLPROV_DIAS).toFixed(2) : '0.00';
      //CDPJ
      if(detp.DETROLPROV_CODIGO === 'VAC'){
        detp.DETROLPROV_DIAS = (detp.DETROLPROV_DIAS !== null) ? Number(detp.DETROLPROV_DIAS).toFixed(4) : '0.00';//CDPJ ANTES 2 DECIAMLES AHORA 4
      }else{
        detp.DETROLPROV_DIAS = (detp.DETROLPROV_DIAS !== null) ? Number(detp.DETROLPROV_DIAS).toFixed(2) : '0.00';
      }
      //CDPJ
      detp.editado = true;
    })

    this.provAggrid.refreshaggrid(this.detalleProv, this.defaultColDefDetProv.objeto);
    await this.sumTotales();
    await this.manejarSenales('Guardar');

  }

}
