import { Component, OnInit, ViewChild } from '@angular/core';
import { SegmaeusuarioService } from '../segservicios/segmaeusuario.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { ConfirmationService, MessageService, SelectItem, TreeNode } from 'primeng';
import { Usuario } from '../../usuario';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { DatePipe } from '@angular/common';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import {
  SaciMaeGuiObjPer,
  SaciMaeNumeracion,
  SegMaeOpcion,
  SegMaePerfil,
  SegMaePermisos,
  SegMaeUsuario
} from '../seginterfaces/segmaeusuario';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { UtilitariosService } from '../../advantage/advantageservices/utilitarios.service';
import { UsuarioEsquema } from '../../usuarioEsquema';


@Component({
  selector: 'app-segmaeusuario',
  templateUrl: './segmaeusuario.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css'],
})
export class SegmaeusuarioComponent implements OnInit {

  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;

  files3: TreeNode[];
  selectedFiles2: any[] = [];

  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  botonPrimero: boolean;
  botonAnterior: boolean;
  botonSiguiente: boolean;
  botonUltimo: boolean;
  btnBuscar: boolean;

  indexTabUsuarios = 0;
  enabledIdUsuario;
  strPerfilUsuario: any;

  modulo = '';
  menu = 0;
  opcionper = 0;
  tipo = '';

  // Busqueda
  displayDialogBus: boolean;
  displayDialogBusquedaFast: boolean;
  types: SelectItem[];
  busquedacampos: any[];
  tabla: string;
  consulta: string;
  arregloCons: any[];
  arregloBus: any[];
  where: string;
  opcion: string;

  CodigoUsuario = '';
  item: SegMaeUsuario;
  permisos: SegMaePermiso;
  // detalleNumeracion: SaciMaeNumeracion[];
  arrayOpciones: SegMaeOpcion[];

  datosUsuario: SegMaeUsuario[];

  txtCodVendedor = '';
  treePermisos;
  cmbMenu;
  cmbModulo;
  cmbUsuario;
  txtCodBodIngDev = '';
  txtCodBodAsig = '';
  txtConfirmarClave;
  txtClave;
  txtCiRuc;
  txtNombres;
  txtApellidos;
  cmbPerfil: any[];
  txtIdUsuario;
  dateTimeFechaExpedicion = new Date();
  dateTimeFechaCaducidad = new Date();
  bodegaAsignadoLabel;
  lblNomBodVentas = '';
  lblNomBodIngDev = '';
  grupoDatosGenerales;
  lblNombreUsuario;
  newItemTreeItem = null;
  lblNombreUsuarioPer = null;
  chkSeleccionarTodosModulos = null;
  chkSeleccionarTodosMenus = null;
  tabItemPermisosEspeciales = null;
  intItemSelec = 0;
  tabUsuarios;
  tabItemNumeraciones;
  lblNombreVen = '';
  datosPerfil: SegMaePerfil[];

  treeData: any[] = [];
  arrayPermisos: SegMaePermisos[];

  //  Permisos predefinidos
  strCmbUsuario: any;
  strCmbModulo: any;
  strCmbMenu: any;

  // Permisos Especiales
  strCmbModuloPerEsp: any;
  cmbModuloPerEsp: any[];
  strCmbOpcPerEsp: any;
  cmbOpcPerEsp: any[];
  strCmbSuperUser: any;
  cmbSuperUser: any[];
  txtIdUsuarioPerEsp = '';

  disabledSelTodosMod = true;
  disabledSelTodosMenu = true;

  selectTab0 = true;
  txtIdUsuarioNum = '';
  lblNombreUsuarioNum = '';
  lblNombreUsuarioPerEsp = '';

  public frameworkComponents;
  rowStyle;
  defaultSegNumPV;
  defaultSegPerEsp;
  largo: string;
  rowSelection = 'multiple';

  btnNuevoGrid: boolean;
  btnGuardarGrid: boolean;
  btnBorrarGrid: boolean;
  btnCancelarGrid: boolean;

  detNumeracionSel: SaciMaeNumeracion;
  detPerEspecialesSel: SaciMaeGuiObjPer;

  indicador: any;
  bolNuevoItemNum = false;
  bolNuevoItemPerEsp = false;

  disabledTab0 = false;
  disabledTab1 = true;
  disabledTab2 = true;
  disabledTab3 = true;
  disabledTab4 = true;

  estadoUsuario = '';

  txtIPPublica = '';
  txtIPLocal = '';
  txtCorreoPin = '';
  txtCelularPin = '';
  spin = false;

  chkautentifi: boolean;
  chkverpreconsexis:boolean;//CDPJ
  chkvertotcxc:boolean;
  displayToken = false;
  tokenusuario = '';


  usuIdentificacionT = '';
  usuClaveT = '';
  usuRuciT = '';
  usuApellidoT = '';
  usuNombreT = '';
  comCodigoT = '';
  usuPerfilT = '';
  usuFechaInicioT = '';
  usuFechaFinalT = '';
  bodCodigoT = '';
  bodCodigoDevT = '';
  strVenCodigoT = '';
  strCorreoPinT = '';
  strTelefonoPinT = '';
  usuautenticacionT = '';
  usualmacenardocT = '';
  usuvisualizardocT = '';
  usuverpreciosconsuexist='';//CDPJ
  usuvertotcxc='';//CDPJ
  marcasaci = false;
  emailAdmin = false;
  chkbtnALMACENARDOC = false;
  chkbtnVISUALIZARDOC = false;
  chkbtntodclientes = false;//GSRF
  chkbtntodpedidos = false;//GSRF
  usutodclientes ='';//GSRF
  usutodpedidos ='';//GSRF

  columnDefsSegNumPV = [
    {
      headerName: 'NumDocRef', field: 'NUM_DOCREF', cellEditor: 'agSelectCellEditor', width: 110,
      cellEditorParams:
      {
        values: ['FAC', 'NV']
      }
    },
    {
      headerName: 'NumSerie', field: 'NUM_SERIE', cellEditor: 'cellPrueba', width: 150, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 14,
          opcionbuscar: false
        }
      }
    },
    {
      headerName: 'Inicio', field: 'NUM_SECINICIAL', cellEditor: 'cellPrueba', width: 150, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 14,
          int: true,
          opcionbuscar: false
        }
      }
    },
    {
      headerName: 'Final', field: 'NUM_SECFINAL', cellEditor: 'cellPrueba', width: 150, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 14,
          int: true,
          opcionbuscar: false
        }
      }
    },
    {
      headerName: 'Actual', field: 'NUM_SECACTUAL', cellEditor: 'cellPrueba', width: 150, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 9,
          int: true,
          opcionbuscar: false
        }
      }
    },
    {
      headerName: 'Autorización', field: 'NUM_AUTORIZACION', cellEditor: 'cellPrueba', width: 150, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 10,
          int: true,
          opcionbuscar: false
        }
      }
    },
    {
      headerName: 'Fecha', field: 'NUM_FECHACAD', cellEditor: 'cellPrueba', editable: true, width: 120, cellStyle: { textAlign: 'left' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: true,
          numerodecimales: 0,
          obligatorio: false,
          fecha: true,
          tamanocelda: 80,
          tienecalculos: true
        }
      }
    }
  ];
  columnDefsSegPerEsp = [
    {
      headerName: 'Tipo', field: 'GUIOBJ_TIPO', cellEditor: 'cellPrueba', width: 100, editable: false
    },
    {
      headerName: 'Nombre', field: 'GUIOBJ_NOMBRE', cellEditor: 'cellPrueba', width: 200, editable: false
    },
    {
      headerName: 'Tab/Col', field: 'GUIOBJ_TABCOL', cellEditor: 'cellPrueba', width: 110, editable: false
    },
    {
      headerName: 'Clave', field: 'GUIOBJ_TIPOPER', cellEditor: 'cellPrueba', width: 120, editable: false
    },
    {
      headerName: 'Aplica C', field: 'APLICA_CLAVE', cellEditor: 'cellPrueba', width: 110, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          tamanocelda: 1,
          tienecalculos: true,
          regexp: /^[S]$|^[s]$/,
        }
      }
    },
    {
      headerName: 'Clave', field: 'USU_CLAVESUP', cellEditor: 'cellPrueba', width: 120, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          tamanocelda: 50,
          opcionbuscar: false
        }
      }
    },
    // {
    //   headerName: '%-MaxDsc', field: 'GUIOBJPER_DSCTOMAXVTA', cellEditor: 'cellPrueba', width: 150, editable: true,
    //   cellEditorParams:
    //     {
    //       values: {
    //         mayusculas: false,
    //         numeros: true,
    //         alphabetic: false,
    //         saltoslinea: 1,
    //         newrow: false,
    //         numerodecimales: 0,
    //         obligatorio: false,
    //         fecha: false,
    //         tamanocelda: 10,
    //         int: true,
    //         opcionbuscar: false
    //       }
    //     }
    // },
    // {
    //   headerName: 'V-MaxDesc', field: 'GUIOBJPER_DSCTOMAXVTAV', cellEditor: 'cellPrueba', editable: true, width: 100, cellStyle: {textAlign: 'left'},
    //   cellEditorParams:
    //     {
    //       values: {
    //         mayusculas: false,
    //         numeros: true,
    //         alphabetic: true,
    //         saltoslinea: 1,
    //         newrow: true,
    //         numerodecimales: 0,
    //         obligatorio: false,
    //         fecha: true,
    //         tamanocelda: 80,
    //         tienecalculos: true
    //       }
    //     }
    // },
    {
      headerName: 'Bloquea', field: 'GUIOBJPERESTADO', cellEditor: 'cellPrueba', editable: true, width: 100, cellStyle: { textAlign: 'left' },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          tamanocelda: 1,
          tienecalculos: true,
          regexp: /^[S]$|^[s]$/,
        }
      }
    }
  ];


  constructor(public segMaeUsuarioService: SegmaeusuarioService, private auditoriaGralService: AuditoriagralService,
    private permisosService: PermisosService, private message: MessageService, public usuario: Usuario,
    private errorService: ErroresBaseDatosService, private busquedaSer: InvbusquedaService,
    private datePipe: DatePipe, private confirmationService: ConfirmationService, private confIniciales: ConfInicialesService,
    public init: NuevoComponentService, private utilitariosService: UtilitariosService, public usuarioEsquema: UsuarioEsquema) {
    this.datosPerfil = [];
    // this.detalleNumeracion = [];
    this.segMaeUsuarioService.detnumeracionarray[this.indicador] = [];
    this.segMaeUsuarioService.detguiobjpermiarray[this.indicador] = [];
    this.agTable();

  }

  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.defaultSegNumPV = {
      editable: true,
      width: 500,
      objeto: 'segUsuarioNumPV'
    };

    this.defaultSegPerEsp = {
      editable: true,
      width: 500,
      objeto: 'segUsuarioPerEsp'
    };
  }

  async ngOnInit() {
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;

    this.largo = '500';

    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = true;
    this.botonAnterior = false;
    this.botonPrimero = false;
    this.botonSiguiente = false;
    this.botonUltimo = false;
    this.btnBuscar = false;
    this.getPermisos();

    this.marcasaci = await this.utilitariosService.esmarcasaci();

    this.desplegarPerfiles();
    this.activaBotones('INI');
    this.indexTabUsuarios = 0;
    this.segMaeUsuarioService.detnumeracionarray[this.indicador] = [];
    this.segMaeUsuarioService.detguiobjpermiarray[this.indicador] = [];
    
  }

  async getPermisos() {
    try {
      const data = await this.permisosService.getPermisosFrm('frmSEG_MAEUSUARIO');
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisos = data[0];
        } else {
          this.permisos = null;
        }
      } else {
        this.permisos = null;
      }
    } catch (e) {
      this.permisos = null;
      this.mensajeAlerta('error', 'Error', 'No se pudo obtener los permisos del formulario');
    }
  }


  async manejarSenales(valor) {
    if (valor === 'Nuevo') {
      this.nuevoRegistro();
      this.disabledTab(false, true, true, true, true);
    }
    if (valor === 'Guardar') {
      if (this.indexTabUsuarios === 0) {
        this.emailAdmin = false;
        if (this.enabledIdUsuario === false) {
          this.guardar();
        } else {
          this.actualizar();
        }
      } else {
        this.emailAdmin = true;
        //GSRF
        console.log(this.usuarioEsquema.esquema)
        // if(this.usuarioEsquema.esquema ==='D' || this.usuarioEsquema.esquema ==='DEMO' 
        // ||this.usuarioEsquema.esquema ==='FRAS'|| this.usuarioEsquema.esquema ==='1721288221001' 
        // || this.usuarioEsquema.esquema==='1712117975001' || this.usuarioEsquema.esquema==='1793143865001'
        // || this.usuarioEsquema.esquema==='1708190911001' || this.usuarioEsquema.esquema==='1706076104001'
        // || this.usuarioEsquema.esquema ==='1704598513001' || this.usuarioEsquema.esquema ==='2290352080001'
        // || this.usuarioEsquema.esquema ==='1793168833001' || this.usuarioEsquema.esquema ==='0925395733001'
        // || this.usuarioEsquema.esquema ==='1791856910001' || this.usuarioEsquema.esquema ==='1791323548001'
        // || this.usuarioEsquema.esquema ==='1792326222001' || this.usuarioEsquema.esquema ==='1793031331001'
        // || this.usuarioEsquema.esquema ==='0993373823001' || this.usuarioEsquema.esquema ==='1792155495001'
        // || this.usuarioEsquema.esquema ==='1710095108001' || this.usuarioEsquema.esquema ==='1792088674001'
        // || this.usuarioEsquema.esquema ==='0992149213001' || this.usuarioEsquema.esquema ==='1793082025001'
        // || this.usuarioEsquema.esquema ==='1793095518001' || this.usuarioEsquema.esquema ==='1801982636001'
        // || this.usuarioEsquema.esquema ==='1792014778001' || this.usuarioEsquema.esquema ==='0993252921001'
        // || this.usuarioEsquema.esquema ==='1792581532001' || this.usuarioEsquema.esquema ==='1002157871001'
        // || this.usuarioEsquema.esquema ==='1792363640001' || this.usuarioEsquema.esquema ==='1792506816001'
        // || this.usuarioEsquema.esquema ==='1792028272001' || this.usuarioEsquema.esquema ==='1791264169001'
        // || this.usuarioEsquema.esquema ==='1706728944001' || this.usuarioEsquema.esquema ==='1714402490001'
        // || this.usuarioEsquema.esquema ==='0992937238001' || this.usuarioEsquema.esquema ==='1791050487001'
        // || this.usuarioEsquema.esquema ==='1792788862002' || this.usuarioEsquema.esquema ==='1793203069001'
        // || this.usuarioEsquema.esquema ==='1792902630001' || this.usuarioEsquema.esquema ==='0890039278001'
        // || this.usuarioEsquema.esquema ==='ACCESSROSS' || this.usuarioEsquema.esquema ==='CONSULTORA'
        // || this.usuarioEsquema.esquema ==='CRIBRYAR' || this.usuarioEsquema.esquema ==='BARLA'
        // || this.usuarioEsquema.esquema ==='MARIGALDI' || this.usuarioEsquema.esquema ==='CARSHOP'
        // || this.usuarioEsquema.esquema ==='AUDASA' || this.usuarioEsquema.esquema ==='MULTIFASHION'
        // || this.usuarioEsquema.esquema ==='AUTOMAXGYE' || this.usuarioEsquema.esquema ==='FRASCOSA'
        // || this.usuarioEsquema.esquema ==='VERONSA' || this.usuarioEsquema.esquema ==='AUTOMAX'){
      const essaci = await this.utilitariosService.esmarcasaci();
        if(essaci === true){
        const rs = await this.utilitariosService.enviarCorreoTokenActAdmaws(this.usuarioEsquema.esquema,'admin');
          if (rs) {
            this.displayToken = true;
          }
        }else{
          const rs = await this.utilitariosService.enviarCorreoTokenActAdm(this.usuarioEsquema.esquema, 'admin');
          if (rs) {
            this.displayToken = true;
          }
        }
        //GSRF
        /*if (this.codigoPerfil() === 'ADMIN') {
          const rs = await this.utilitariosService.enviarCorreoTokenActAdm(this.usuarioEsquema.esquema, this.txtIdUsuario);
          if (rs) {
            this.displayToken = true;
          }
        } else {
          if (this.indexTabUsuarios === 2) {
            this.guardarPermisosPredefinidos();
          }

          if (this.indexTabUsuarios === 1) {
            if (await this.guardarPermisosNodos() === true) {
              this.spin = false;
              this.mensajeAlerta('success', 'Información', 'Permisos guardados exitosamente !!! ');
              this.activaBotones('G');
              this.disabledTab(false, false, false, false, false);
            }

          }
          if (this.indexTabUsuarios === 4) {

            // this.aggrid.gridApi.stopEditing();
            // this.aggrid.gridApi.tabToPreviousCell();
            this.insertarElementoPerEsp();
            // compositePerEsp.GuardarDatos();
          }
          if (this.indexTabUsuarios === 3) {
            this.aggrid.gridApi.stopEditing();
            // this.aggrid.gridApi.tabToPreviousCell();
            // this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultSegNumPV.objeto].setFocusedCell(
            //   this.segMaeUsuarioService.detnumeracionarray[this.indicador].indexOf(this.detNumeracionSel), 'NUM_AUTORIZACION');
            this.guardarDatosNum();
          }
        }*/
      }

    }
    if (valor === 'Borrar') {
      if (this.indexTabUsuarios === 0) {
        this.eliminar();
      }

    }

    if (valor === 'Cancelar') {
      if (this.txtIdUsuario !== '') {
        this.item = await this.seleccionarRegistro('SR', this.txtIdUsuario, '');
        this.mostrarDatos(this.item);
        if (this.indexTabUsuarios === 0) {
          this.disabledTab(false, false, false, false, false);
        } else {
          this.indexTabUsuarios = 0;
        }
      } else {
        this.nuevoRegistro();
        document.getElementById('divPrincipal').style.pointerEvents = 'none';
        this.disabledTab(false, true, true, true, true);
      }
      this.activaBotones('C');
    }
    if (valor === 'Primero') {
      this.inicio();
    }
    if (valor === 'Siguiente') {
      this.siguiente();
    }
    if (valor === 'Anterior') {
      this.anterior();
    }
    if (valor === 'Ultimo') {
      this.fin();
    }
    if (valor === 'Buscar') {
      this.buscarDatos();
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }

  async seleccionarRegistro(tipo, codigoUsu, condicion) {

    if (tipo !== 'P' && tipo !== 'A' && tipo !== 'S' && tipo !== 'U') {
      this.CodigoUsuario = codigoUsu;
    }
    const item: SegMaeUsuario = {};

    try {

      const rs = await this.segMaeUsuarioService.consultaRegistro(tipo, this.CodigoUsuario, condicion);
      if (rs !== null) {
        this.CodigoUsuario = rs[0].USUIDENTIFICACION;
        item.BOD_CODIGO = rs[0].BOD_CODIGO;
        item.BOD_CODIGO_DEV = rs[0].BOD_CODIGO_DEV;
        item.COMCODIGO = rs[0].COMCODIGO;
        item.USUAPELLIDO = rs[0].USUAPELLIDO;
        item.USUCLAVE = rs[0].USUCLAVE;
        item.USUFECHAFINAL = rs[0].USUFECHAFINAL;
        item.USUFECHAINICIO = rs[0].USUFECHAINICIO;
        item.USUIDENTIFICACION = this.CodigoUsuario;
        item.USUNOMBRE = rs[0].USUNOMBRE;
        item.USURUCI = rs[0].USURUCI;
        item.PERFIL_CODIGO = rs[0].PERFIL_CODIGO;
        item.BOD_NOMBRE_VEN = rs[0].BOD_NOMBRE_VEN;
        item.BOD_NOMBRE_DEV = rs[0].BOD_NOMBRE_DEV;
        item.VEN_CODIGO = rs[0].VEN_CODIGO;
        item.USU_ENUSO = rs[0].USU_ENUSO;
        item.USU_IPLOCAL = rs[0].USU_IPLOCAL;
        item.USU_IPPUBLICA = rs[0].USU_IPPUBLICA;
        item.USU_CORREOPIN = rs[0].USU_CORREOPIN;
        item.USU_TELEFONOPIN = rs[0].USU_TELEFONOPIN;
        item.USUAUTENTICACION = rs[0].USUAUTENTICACION;
        item.USU_ALMACENARDOC = rs[0].USU_ALMACENARDOC;
        item.USU_VISUALIZARDOC = rs[0].USU_VISUALIZARDOC;
        item.USU_VERPRECIOCONSULEXIS=rs[0].USU_VERPRECIOCONSULEXIS;//CDPJ
        item.USU_VERTOTCXC=rs[0].USU_VERTOTCXC;//CDPJ
        item.USU_MOV_TODCLIENTES=rs[0].USU_MOV_TODCLIENTES;//GSRF
        item.USU_MOV_TODPEDIDOS=rs[0].USU_MOV_TODPEDIDOS;//GSRF
      } else {
        return null;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
    return item;
  }

  async inicio() {
    this.item = await this.seleccionarRegistro('P', '', '');
    this.mostrarDatos(this.item);
  }

  async anterior() {
    this.item = await this.seleccionarRegistro('A', '', '');
    this.mostrarDatos(this.item);
  }

  async siguiente() {
    this.item = await this.seleccionarRegistro('S', '', '');
    this.mostrarDatos(this.item);
  }

  async fin() {
    this.item = await this.seleccionarRegistro('U', '', '');
    this.mostrarDatos(this.item);
  }

  async mostrarDatos(item: SegMaeUsuario) {
    if (this.permisos != null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERSELECCION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , no tiene permiso de Consulta. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }

    if (this.item !== null) {
      this.limpiarDatos();
      this.indexTabUsuarios = 0;
      this.txtCiRuc = item.USURUCI;
      this.txtNombres = item.USUNOMBRE;
      this.txtApellidos = item.USUAPELLIDO;
      this.txtIdUsuario = item.USUIDENTIFICACION;
      this.txtClave = item.USUCLAVE;
      this.dateTimeFechaExpedicion = new Date(item.USUFECHAINICIO);
      this.dateTimeFechaCaducidad = new Date(item.USUFECHAFINAL);

      if (item.BOD_CODIGO !== null) {
        this.txtCodBodAsig = item.BOD_CODIGO;
        this.lblNomBodVentas = item.BOD_NOMBRE_VEN;

      }
      if (item.BOD_CODIGO_DEV !== null) {
        this.txtCodBodIngDev = item.BOD_CODIGO_DEV;
        this.lblNomBodIngDev = item.BOD_NOMBRE_DEV;

      }

      if (item.VEN_CODIGO !== null) {
        this.txtCodVendedor = item.VEN_CODIGO;
      }

      if (item.USU_ENUSO === 1) {
        this.estadoUsuario = 'CONECTADO';
      } else if (item.USU_ENUSO === 0) {
        this.estadoUsuario = 'DESCONECTADO';
      } else {
        this.estadoUsuario = '';
      }

      this.txtIPPublica = item.USU_IPPUBLICA;
      this.txtIPLocal = item.USU_IPLOCAL;
      this.txtCorreoPin = item.USU_CORREOPIN === null ? '' : item.USU_CORREOPIN;
      this.txtCelularPin = item.USU_TELEFONOPIN === null ? '' : item.USU_TELEFONOPIN;

      this.lblNombreUsuarioPer = this.txtIdUsuario + '  ' + this.txtNombres + '  ' + this.txtApellidos;
      if (item.USUAUTENTICACION === 1) {
        this.chkautentifi = true;
      } else {
        this.chkautentifi = false;
      }
      //CDPJ
      console.log('usu',item.USU_VERPRECIOCONSULEXIS)
      if(item.USU_VERPRECIOCONSULEXIS === 1){
      this.chkverpreconsexis=true;
      }else{
      this.chkverpreconsexis=false;
      }
      if(item.USU_VERTOTCXC === 1){
        this.chkvertotcxc=true;
        }else{
        this.chkvertotcxc=false;
        }
      
            //CDPJ

      if (item.USU_ALMACENARDOC === 1) {
        this.chkbtnALMACENARDOC = true;
      } else {
        this.chkbtnALMACENARDOC = false;
      }

      if (item.USU_VISUALIZARDOC === 1) {
        this.chkbtnVISUALIZARDOC = true;
      } else {
        this.chkbtnVISUALIZARDOC = false;
      }
      //GSRF
      if (item.USU_MOV_TODPEDIDOS === 1) {
        this.chkbtntodpedidos = true;
      } else {
        this.chkbtntodpedidos = false;
      }
      if (item.USU_MOV_TODCLIENTES === 1) {
        this.chkbtntodclientes = true;
      } else {
        this.chkbtntodclientes = false;
      }
      //GSRF
      let strNombreVendor = '';
      try {
        const rs = await this.segMaeUsuarioService.erVenNombre(this.txtCodVendedor);
        strNombreVendor = rs === null ? '' : rs[0].VEN_NOMBRE;
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      }
      this.lblNombreVen = strNombreVendor;

      this.actualizarPerfil();
      this.activaBotones('NAV');
      // this.crearNodosPermisos();
      this.disabledTab(false, false, false, false, false);
    }
  }

  limpiarDatos() {

    this.txtCiRuc = '';
    this.txtNombres = '';
    this.txtApellidos = '';
    this.strPerfilUsuario = this.cmbPerfil[0];
    this.txtIdUsuario = '';
    this.txtClave = '';
    this.txtConfirmarClave = '';
    this.dateTimeFechaExpedicion = new Date();
    this.dateTimeFechaCaducidad = new Date();
    this.txtCodBodAsig = '';
    this.txtCodBodIngDev = '';
    this.lblNomBodVentas = '';
    this.lblNomBodIngDev = '';
    this.lblNombreVen = '';
    this.txtCodVendedor = '';
    this.cmbMenu = [];
    this.disabledSelTodosMenu = true;
    this.cmbModulo = [];
    this.disabledSelTodosMod = true;
    this.chkSeleccionarTodosMenus = true;
    this.chkSeleccionarTodosModulos = true;
    this.estadoUsuario = '';
    this.txtIPPublica = '';
    this.txtIPLocal = '';
    this.txtCelularPin = '';
    this.txtCorreoPin = '';
    this.chkbtnALMACENARDOC = false;
    this.chkbtnVISUALIZARDOC = false;
    this.chkverpreconsexis=false;//CDPJ
    this.chkvertotcxc=false;//CDPJ
    this.chkbtntodclientes = false;//GSRF
    this.chkbtntodpedidos = false;//GSRF

  }

  actualizarPerfil() {
    for (const a of this.cmbPerfil) {
      if (a.perfilcodigo === this.item.PERFIL_CODIGO) {
        this.strPerfilUsuario = { perfilcodigo: a.perfilcodigo, comcodigo: a.comcodigo, perfilnombre: a.perfilnombre };
      }
    }
  }

  async desplegarPerfiles() {
    try {
      this.datosPerfil = await this.segMaeUsuarioService.consultarMaePerfil('01');
      this.cmbPerfil = [];
      for (const perfil of this.datosPerfil) {
        this.cmbPerfil.push({ perfilcodigo: perfil.PERFIL_CODIGO, comcodigo: perfil.COM_CODIGO, perfilnombre: perfil.PERFIL_NOMBRE });
      }
      this.strPerfilUsuario = this.cmbPerfil[0];
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  }

  activaBotones(tipo) {

    if (tipo === 'N') { // Nuevo registro
      this.btnGuardar = false;
      this.btnNuevo = true;
      this.btnBorrar = true;

      this.botonPrimero = true;
      this.botonUltimo = true;
      this.botonSiguiente = true;
      this.botonAnterior = true;

      this.btnBuscar = true;
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      // this.grupoDatosGenerales = false;
      this.enabledIdUsuario = false;
    }

    if (tipo === 'C') { // calcelar acción
      this.btnGuardar = true;
      this.btnNuevo = false;

      if (this.txtCiRuc !== '') {
        this.btnBorrar = false;
      }

      this.botonPrimero = false;
      this.botonUltimo = false;
      this.botonSiguiente = false;
      this.botonAnterior = false;
      this.btnBuscar = false;
    }

    if (tipo === 'G') { // Guardar

      this.btnGuardar = true;
      this.btnNuevo = false;
      this.btnBorrar = false;

      this.botonPrimero = false;
      this.botonUltimo = false;
      this.botonSiguiente = false;
      this.botonAnterior = false;
      this.btnBuscar = false;

    }

    if (tipo === 'NAV') { // Consultar un registro

      this.btnGuardar = true;
      this.btnNuevo = false;
      this.btnBorrar = false;

      this.botonPrimero = false;
      this.botonUltimo = false;
      this.botonSiguiente = false;
      this.botonAnterior = false;
      this.btnBuscar = false;

      // this.grupoDatosGenerales = false;
      document.getElementById('divPrincipal').style.pointerEvents = 'all';
      this.enabledIdUsuario = true;

    }

    if (tipo === 'M') { // Modificar Registro

      this.btnGuardar = false;
      this.btnNuevo = true;
      this.btnBorrar = true;

      this.botonPrimero = true;
      this.botonUltimo = true;
      this.botonSiguiente = true;
      this.botonAnterior = true;
      this.btnBuscar = true;

    }

    if (tipo === 'INI') { // INICIAR CON LA PANTALLA EN BLANCO

      this.btnGuardar = true;
      this.btnNuevo = false;
      this.btnBorrar = true;

      this.botonPrimero = false;
      this.botonUltimo = false;
      this.botonSiguiente = false;
      this.botonAnterior = false;
      this.btnBuscar = false;
    }
  }

  buscarDatos() {
    if (this.permisos != null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERSELECCION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , no tiene permiso de Consulta. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }

    this.opcion = 'BuscarUsuario';
    this.types = [
      { label: 'USUIDENTIFICACION', value: 'USUIDENTIFICACION' },
      { label: 'USURUCI', value: 'USURUCI' },
      { label: 'USUAPELLIDO', value: 'USUAPELLIDO' },
      { label: 'USUNOMBRE', value: 'USUNOMBRE' },
      { label: 'PERFIL_CODIGO', value: 'PERFIL_CODIGO' },
      { label: 'USUFECHAINICIO', value: 'USUFECHAINICIO' },
      { label: 'USUFECHAFINAL', value: 'USUFECHAFINAL' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', ''];
    this.tabla = 'seg_maeusuario';
    this.consulta = '*';
    this.where = '';
    this.busquedaSer.busquedaSegMaeUsuario1().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.arregloCons.map((dato) => {
        if (dato.USUFECHAINICIO !== null) {
          dato.USUFECHAINICIO = this.datePipe.transform(dato.USUFECHAINICIO, 'dd/MM/yyyy');
        } else {
          dato.USUFECHAINICIO = '';
        }

        if (dato.USUFECHAFINAL !== null) {
          dato.USUFECHAFINAL = this.datePipe.transform(dato.USUFECHAFINAL, 'dd/MM/yyyy');
        } else {
          dato.USUFECHAFINAL = '';
        }
      });
      this.displayDialogBusquedaFast = true;
    });
  }

  async manejarSeleccion(valor) {

    if (this.opcion === 'BuscarUsuario') {
      this.item = await this.seleccionarRegistro('SR', valor.USUIDENTIFICACION, '');
      this.mostrarDatos(this.item);
      this.indexTabUsuarios = 0;
    }
    if (this.opcion === 'BuscarBodega') {
      this.lblNomBodVentas = valor.BOD_NOMBRE;
      this.txtCodBodAsig = valor.BOD_CODIGO;
      this.activaBotones('M');
    }

    if (this.opcion === 'BuscarBodegaDev') {
      this.lblNomBodIngDev = valor.BOD_NOMBRE;
      this.txtCodBodIngDev = valor.BOD_CODIGO;
      this.activaBotones('M');
    }

    if (this.opcion === 'BuscarVendedor') {
      this.lblNombreVen = valor.VEN_NOMBRE;
      this.txtCodVendedor = valor.VEN_CODIGO;
      this.activaBotones('M');
    }

    this.displayDialogBus = false;
    this.displayDialogBusquedaFast = false;
  }

  nuevoRegistro() {
    console.log(this.permisos);
    if (this.permisos != null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERINSERCION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , no tiene permiso de inserción. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }

    document.getElementById('divPrincipal').style.pointerEvents = 'all';
    this.activaBotones('N');
    this.limpiarDatos();
    document.getElementById('numCedUser').focus();
    this.indexTabUsuarios = 0;
  }

  guardar() {
    if (this.permisos != null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERINSERCION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , no tiene permiso de Inserción. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }
    const fechaInicio = this.dateTimeFechaExpedicion.toLocaleString('en-GB').split(',');
    const fechaFinal = this.dateTimeFechaCaducidad.toLocaleString('en-GB').split(',');

    const CodigoPerfil = this.codigoPerfil();

    // Si los datos estan correctos guarda el registro
    if (this.verificarDatosCorrectos() === true) {
      try {
        this.opeInsertar(this.txtIdUsuario.toString().toLowerCase(), this.txtClave, this.txtCiRuc, this.txtApellidos, this.txtNombres, '01', CodigoPerfil,
          fechaInicio[0], fechaFinal[0], this.txtCodBodAsig, this.txtCodBodIngDev, this.txtCodVendedor,
          String(this.txtCorreoPin).trim(), String(this.txtCelularPin).trim(), this.chkautentifi === true ? 1 : 0, this.chkbtnALMACENARDOC === true? 1: 0, this.chkbtnVISUALIZARDOC === true? 1:0,
          this.chkverpreconsexis === true ? 1:0,//CDPJ
          this.chkvertotcxc === true ? 1:0, //CDPJ
          this.chkbtntodclientes  === true ? 1:0,//GSRF
          this.chkbtntodpedidos  === true ? 1:0 );//GSRF
        this.enabledIdUsuario = true;
        this.activaBotones('G');
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      }
    }
  }

  verificarDatosCorrectos(): boolean {
    if (this.txtNombres === '') {
      this.mensajeAlerta('error', 'Validación', 'Nombre incorrecto');
      document.getElementById('nombreUser').focus();
      return false;
    }

    if (this.txtApellidos === '') {
      this.mensajeAlerta('error', 'Validación', 'Apellido incorrecto');
      document.getElementById('apellidoUser').focus();
      return false;
    }

    if (this.strPerfilUsuario.perfilnombre === '') {
      this.mensajeAlerta('error', 'Validación', 'Perfíl incorrecto');
      document.getElementById('cmbPerfilUsuario').focus();
      return false;
    }

    if (this.txtIdUsuario === '') {
      this.mensajeAlerta('error', 'Validación', 'Usuario incorrecto');
      document.getElementById('usuario').focus();
      return false;
    }

    if (this.txtClave === '') {
      this.mensajeAlerta('error', 'Validación', 'Clave incorrecta');
      document.getElementById('clave').focus();
      return false;
    }

    if (this.txtConfirmarClave === '') {
      this.mensajeAlerta('error', 'Validación', 'Confirmación de la clave incorrecta');
      document.getElementById('confirmarClave').focus();
      return false;
    }
    if (this.dateTimeFechaExpedicion.getFullYear() === 1900) {
      this.mensajeAlerta('error', 'Validación', 'Fecha de expedición incorrecta');
      return false;
    }

    if (this.dateTimeFechaCaducidad.getFullYear() === 1900) {
      this.mensajeAlerta('error', 'Validación', 'Fecha de caducidad incorrecta');
      return false;
    }
    //GSRF
    console.log(this.txtClave);
    const reg=/^[a-zA-ZáéíóúÁÉÍÓÚñÑ]$/
    for(var i = 0; i < this.txtClave.length; i++){
      var letraactual=this.txtClave.charAt(i);
      console.log(letraactual);
      //if(letraactual<'0'||letraactual>'9'){
        if(reg.test(letraactual)){
        console.log(letraactual);
        if(this.mayus(letraactual)){
          console.log(letraactual);
          this.mensajeAlerta('error', 'Validación', 'Coloque la clave sin mayusculas');
          return false;
        }
      }
    }  
    //GSRF
    if (this.txtClave !== this.txtConfirmarClave) {
      this.mensajeAlerta('error', 'Validación', 'Confirmación de la clave incorrecta');
      document.getElementById('confirmarClave').focus();
      return false;
    }

    if (this.dateTimeFechaCaducidad.getFullYear() <= this.dateTimeFechaExpedicion.getFullYear() &&
      this.dateTimeFechaCaducidad.getMonth() <= this.dateTimeFechaExpedicion.getMonth()
      && this.dateTimeFechaCaducidad.getDate() < this.dateTimeFechaExpedicion.getDate()) {
      this.mensajeAlerta('error', 'Validación', 'Fecha de caducidad no puede ser menor de la Fecha de Expedición');
      return false;
    }

    if (String(this.txtCorreoPin).trim() === '') {
      this.mensajeAlerta('error', 'Validación', 'Correo electrónico incorrecto');
      return false;
    }
    if (String(this.txtCelularPin).trim() === '') {
      this.mensajeAlerta('error', 'Validación', 'Teléfono móvil incorrecto');
      return false;
    }

    return true;
  }
  //GSRF
  mayus(letra) {
    console.log(letra.toUpperCase())
    return letra === letra.toUpperCase();
  }
  //GSRF

  codigoPerfil() {
    return this.strPerfilUsuario.perfilcodigo;
  }

  async opeInsertar(usuIdentificacion, usuClave, usuRuci, usuApellido, usuNombre, comCodigo, usuPerfil, usuFechaInicio, usuFechaFinal, bodCodigo,
    bodCodigoDev, strVenCodigo, strCorreoPin, strTelefonoPin, usuautenticacion, almacenardoc, visualizadoc,verpreconexist, vertotcxc,
    usu_todclientes ,//GSRF
    usu_todpedidos,//GSRF
    ) {//CDPJ AGREGUE  verpreconexist y vertotcxc
    try {
      await this.segMaeUsuarioService.insertarUsuario(usuIdentificacion, usuClave, usuRuci, usuApellido, usuNombre, comCodigo,
        usuPerfil, usuFechaInicio, usuFechaFinal, bodCodigo, bodCodigoDev, strVenCodigo, strCorreoPin, strTelefonoPin, usuautenticacion, almacenardoc, visualizadoc,verpreconexist,vertotcxc,
        usu_todclientes ,//GSRF
        usu_todpedidos,//GSRF
        );//CDPJ AGREGUE verpreconexist y vertotcxc
      this.mensajeAlerta('success', 'Información', 'Usuario registrado exitosamente !!!!');
      this.disabledTab(false, false, false, false, false);
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  }

  actualizar() {
    if (this.permisos != null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERACTUALIZACION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , no tiene permiso de Actualización. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }

    const fechaInicio = this.dateTimeFechaExpedicion.toLocaleString('en-GB').split(',');
    const fechaFinal = this.dateTimeFechaCaducidad.toLocaleString('en-GB').split(',');

    const CodigoPerfil = this.codigoPerfil();


    // Si los datos estan correctos guarda el registro
    if (this.verificarDatosCorrectos() === true) {
      try {
        this.opeActualizar(this.txtIdUsuario, this.txtClave, this.txtCiRuc, this.txtApellidos, this.txtNombres, '01',
          CodigoPerfil, fechaInicio[0], fechaFinal[0], this.txtCodBodAsig, this.txtCodBodIngDev, this.txtCodVendedor,
          String(this.txtCorreoPin).trim(), String(this.txtCelularPin).trim(), this.chkautentifi === true ? 1 : 0, this.chkbtnALMACENARDOC === true? 1: 0, this.chkbtnVISUALIZARDOC === true? 1:0,
          this.chkverpreconsexis === true?1:0,//CDPJ
          this.chkvertotcxc === true ? 1:0, //CDPJ
          this.chkbtntodclientes  === true ? 1:0,//GSRF
          this.chkbtntodpedidos  === true ? 1:0 );//GSRF
        this.activaBotones('G');
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      }
    }
  }

  async opeActualizar(usuIdentificacion, usuClave, usuRuci, usuApellido, usuNombre, comCodigo, usuPerfil, usuFechaInicio, usuFechaFinal,
    bodCodigo, bodCodigoDev, strVenCodigo, strCorreoPin, strTelefonoPin, usuautenticacion, almacenardoc, visualizadoc,verpreconexist,vertotcxc,
    usu_todclientes ,//GSRF
    usu_todpedidos,//GSRF
    ) {//CDPJ AGREGUE  verpreconexist y vertotcxc

    if (usuPerfil === 'ADMIN') {
      this.usuIdentificacionT = usuIdentificacion;
      this.usuClaveT = usuClave;
      this.usuRuciT = usuRuci;
      this.usuApellidoT = usuApellido;
      this.usuNombreT = usuNombre;
      this.comCodigoT = comCodigo;
      this.usuPerfilT = usuPerfil;
      this.usuFechaInicioT = usuFechaInicio;
      this.usuFechaFinalT = usuFechaFinal;
      this.bodCodigoT = bodCodigo;
      this.bodCodigoDevT = bodCodigoDev;
      this.strVenCodigoT = strVenCodigo;
      this.strCorreoPinT = strCorreoPin;
      this.strTelefonoPinT = strTelefonoPin;
      this.usuautenticacionT = usuautenticacion;
      this.usualmacenardocT = almacenardoc;
      this.usuvisualizardocT = visualizadoc;
      this.usuverpreciosconsuexist=verpreconexist;//CDPJ 
      this.usuvertotcxc=vertotcxc;//CDPJ
      this.usutodclientes =usu_todclientes;//GSRF
      this.usutodpedidos =usu_todpedidos;//GSRF     
        
        //GSRF
        console.log(this.usuarioEsquema.esquema)
        // if(this.usuarioEsquema.esquema ==='D' || this.usuarioEsquema.esquema ==='DEMO' ||this.usuarioEsquema.esquema ==='FRAS' || this.usuarioEsquema.esquema ==='1721288221001' ){
        const essaci = await this.utilitariosService.esmarcasaci();
        if(essaci === true){
        const rs = await this.utilitariosService.enviarCorreoTokenActAdmaws(this.usuarioEsquema.esquema, usuIdentificacion);
          if (rs) {
            this.displayToken = true;
          }
        }else{
          const rs = await this.utilitariosService.enviarCorreoTokenActAdm(this.usuarioEsquema.esquema, usuIdentificacion);
          if (rs) {
            this.displayToken = true;
          }
        }
        //GSRF
    } else {
      try {
        await this.segMaeUsuarioService.actualizarUsuario(usuIdentificacion, usuClave, usuRuci, usuApellido, usuNombre, comCodigo,
          usuPerfil, usuFechaInicio, usuFechaFinal, bodCodigo, bodCodigoDev, strVenCodigo, strCorreoPin, strTelefonoPin, usuautenticacion, almacenardoc, visualizadoc,verpreconexist,vertotcxc,
          this.usutodclientes,//GSRF
          this.usutodpedidos,//GSRF
          );//CDPJ);//CDPJ AGREGUÉ verpreconexist y vertotcxc
        this.mensajeAlerta('success', 'Información', 'Usuario actualizado exitosamente !!!!');
        this.disabledTab(false, false, false, false, false);
      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      }
    }

  }

  cambio(event) {
    this.activaBotones('M');
    if (this.indexTabUsuarios === 0) {
      this.disabledTab(false, true, true, true, true);
    }
  }
  cambioBodega(event) {
    this.activaBotones('M');
    this.lblNomBodVentas = '';
  }
  cambioBodegaDev(event) {
    this.activaBotones('M');
    this.lblNomBodIngDev = '';
  }
  cambioVendedor(event) {
    this.activaBotones('M');
    this.lblNombreVen = '';
  }

  async eliminar() {

    if (this.usuario.identificacion === this.txtIdUsuario) {
      this.mensajeAlerta('info', 'Información', 'No se puede eliminar el usuario, ya que esta actualmente conectado');
      return;
    }

    // el usuario administrador no puede ser borrado


    try {
      const rs = await this.segMaeUsuarioService.erPerfilCodigo(this.txtIdUsuario);
      const strPerfil = rs === null ? '' : rs[0].PERFIL_CODIGO;

      if (strPerfil === 'ADMIN') {
        this.mensajeAlerta('info', 'Información', 'El Usuario no puede ser eliminado ya que es el administrador del Sistema');
        return;
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Error al consultar perfíl del usuario ! ' + mensaje);
    }

    // el usuario no puede ser eliminado si tiene realizadas transacciones

    try {
      let strSentencia = '';
      strSentencia = 'select count(*) from saci_maeauditoria ';
      strSentencia = strSentencia + ' where usu_identificacion=\'' + this.txtIdUsuario + '\'';

      const rs = await this.segMaeUsuarioService.consultarTransacciones(this.txtIdUsuario);
      if (rs.length > 0) {
        if (rs[0]['COUNT(*)'] !== 0) {
          this.mensajeAlerta('info', 'Información', 'El Usuario no puede ser eliminado ya que tiene transacciones realizadas en el Sistema');
          return;
        }
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Error al consultar transacciones del usuario ! ' + mensaje);
    }


    if (this.permisos != null) {
      if (this.permisos.PERTODO === 0) {
        if (this.permisos.PERELIMACION === 0) {
          this.mensajeAlerta('info', 'Información', 'El usuario ' + this.usuario.identificacion +
            ' , no tiene permiso de Eliminación. Consulte con el Administrador del Sistema.');
          return;
        }
      }
    }

    if (this.usuario.codperfil !== 'ADMIN') {
      this.mensajeAlerta('info', 'Información', 'No es posible eliminar el usuario. Consulte con el Administrador del Sistema.');
      return;
    }

    this.confirmationService.confirm({
      message: 'Está seguro de eliminar el registro?',
      header: 'Eliminar Usuario',
      icon: 'pi pi-question-circle',
      key: 'segmaeusuarioConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmEliminar();
      },
      reject: () => {
        this.init.tabs.splice(this.init.tabindex, 1);
      }
    });

  }

  confirmEliminar() {
    try {
      this.opeEliminar(this.txtIdUsuario, '01');
      // if ( compositeNumeraciones == null )
      //   compositeNumeraciones = new Numeraciones(tabUsuarios, SWT.NONE,navegadorBotones,txtIdUsuario.getText(),
      //   txtIdUsuario.getText() + " " + txtNombres.getText() + " " + txtApellidos.getText());
      this.eliminarNumeracionesUsuario();
      this.limpiarDatos();
      this.activaBotones('INI');
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Proceso no terminó satisfactoriamente.... ! ' + mensaje);
    }
  }

  async opeEliminar(usuIdentificacion, comCodigo) {
    try {
      await this.segMaeUsuarioService.eliminarUsuario(usuIdentificacion, comCodigo);
      this.mensajeAlerta('success', 'Información', 'Usuario eliminado exitosamente !!!');
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  }

  async eliminarNumeracionesUsuario() {
    for (const item of this.segMaeUsuarioService.detnumeracionarray[this.indicador]) {
      try {
        await this.segMaeUsuarioService.eliminarNumeracion(this.txtIdUsuario, '01', item.NUM_DOCREF, item.NUM_DOCREF, item.NUM_SERIE);
        this.auditoriaGralService.registrarAuditoria('SEG_MAENUMERACION', item.NUM_DOCREF + '/' + item.NUM_NEMONICO, 'E',
          this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
          });
        // lstNumeracion.remove(item);

      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', mensaje);
      }
    }
  }

  onTab1Change(event) {

    if (this.indexTabUsuarios === 2) {
      this.lblNombreUsuario = this.txtIdUsuario + ' ' + this.txtNombres + ' ' + this.txtApellidos;
      this.desplegarUsuario();
    }

    if (this.indexTabUsuarios === 3) {
      this.txtIdUsuarioNum = this.txtIdUsuario;
      this.lblNombreUsuarioNum = this.txtIdUsuario + ' ' + this.txtNombres + ' ' + this.txtApellidos;
      this.mostrarNumeraciones();
      this.activarBotonesNum('I');
    }

    if (this.indexTabUsuarios === 4) {
      this.txtIdUsuarioPerEsp = this.txtIdUsuario;
      this.lblNombreUsuarioPerEsp = this.txtIdUsuario + ' ' + this.txtNombres + ' ' + this.txtApellidos;
      this.mostrarDatosModulosPerEsp();
      this.mostrarUsuariosPerEsp();
      this.strCmbOpcPerEsp = { name: '', codigo: '', objeto: '' };
      this.segMaeUsuarioService.detguiobjpermiarray[this.indicador] = [];
      // compositePerEsp=new ComPermisosEspeciales(tabUsuarios,SWT.NONE,txtIdUsuario.getText(),txtIdUsuario.getText()
      // + " " + txtNombres.getText() + " " + txtApellidos.getText(),navegadorBotones);
      // tabItemPermisosEspeciales.setControl(compositePerEsp);
    }

    if (this.indexTabUsuarios === 1) {
      this.lblNombreUsuarioPer = this.txtIdUsuario + '  ' + this.txtNombres + '  ' + this.txtApellidos;
      this.crearNodosPermisos1();
    }
  }

  async mostrarNumeraciones() {
    try {
      const rs = await this.segMaeUsuarioService.listarNumeraciones(this.txtIdUsuarioNum);
      this.segMaeUsuarioService.detnumeracionarray[this.indicador] = [];
      if (rs !== null) {
        for (const num of rs) {
          const item: SaciMaeNumeracion = {};
          item.NUM_CLASE = num.NUM_CLASE;
          item.NUM_DOCREF = num.NUM_DOCREF;
          item.NUM_NEMONICO = num.NUM_NEMONICO;
          item.NUM_SECACTUAL = num.NUM_SECACTUAL;
          item.NUM_SECFINAL = num.NUM_SECFINAL;
          item.NUM_SECINICIAL = num.NUM_SECINICIAL;
          item.NUM_SERIE = num.NUM_SERIE;
          item.NUM_AUTORIZACION = num.NUM_AUTORIZACION === null ? '' : num.NUM_AUTORIZACION;

          if (num.NUM_FECHACAD !== null) {
            item.NUM_FECHACAD = num.NUM_FECHACAD;
            if (item.NUM_FECHACAD.toString().includes('-') && item.NUM_FECHACAD !== '') {
              item.NUM_FECHACAD = this.datePipe.transform(item.NUM_FECHACAD, 'dd/MM/yyyy');
            }
          }
          this.segMaeUsuarioService.detnumeracionarray[this.indicador].push(item);
        }
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  }

  async crearNodosPermisos() {
    this.spin = true;
    let i = 0;
    let j = 0;
    let k = 0;

    try {
      const rs = await this.segMaeUsuarioService.obtenerModulos();
      console.log(rs);
      this.treeData = [];
      for (const modulo of rs) {
        const mod = {
          label: modulo.MODULO,
          data: modulo.PREFIJO,
          icon: 'pi pi-folder',
          tipo: 'MOD',
          modulo: modulo.PREFIJO,
          children: []
        };
        this.treeData.push(mod);
      }
      for (i = 0; i < this.treeData.length; i++) {
        if (this.treeData[i].tipo === 'MOD') {
          const rs1 = await this.segMaeUsuarioService.obtenerMenu(this.treeData[i].data);
          console.log(rs1);
          this.treeData[i].children = [];
          for (const menu of rs1) {
            const men = {
              label: menu.MENNOMBRE,
              data: menu.MENCODIGO,
              icon: 'pi pi-list',
              tipo: 'MEN',
              modulo: this.treeData[i].modulo,
              menu: menu.MENCODIGO,
              children: []
            };
            this.treeData[i].children.push(men);
          }
        }
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }

    /*for (i = 0; i < this.treeData.length; i++) {
      if (this.treeData[i].tipo === 'MOD') {
        try {
          const rs = await this.segMaeUsuarioService.obtenerMenu(this.treeData[i].data);
          console.log(rs);
          for (const menu of rs) {
            const men  = {
              label: menu.MENNOMBRE,
              data: menu.MENCODIGO,
              icon: 'pi pi-list',
              tipo: 'MEN',
              modulo: this.treeData[i].modulo,
              menu: menu.MENCODIGO,
              children: []
            };
            this.treeData[i].children.push(men);
          }
        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        }
      }
    }*/

    try {
      const rs = await this.segMaeUsuarioService.obtenerOpciones();
      console.log(rs);
      this.arrayOpciones = rs;

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }


    for (i = 0; i < this.treeData.length; i++) {
      for (j = 0; j < this.treeData[i].children.length; j++) {
        if (this.treeData[i].children[j].tipo === 'MEN') {
          for (const opcion of this.arrayOpciones) {
            if (opcion.MODPREFIJO === this.treeData[i].children[j].modulo && opcion.MENCODIGO === this.treeData[i].children[j].menu) {
              const opc = {
                label: opcion.OPCNOMBRE,
                data: opcion.OPCCODIGO,
                icon: 'pi pi-file-o',
                tipo: 'OPC',
                modulo: this.treeData[i].children[j].modulo,
                menu: this.treeData[i].children[j].menu,
                opcion: opcion.OPCCODIGO,
                children: [
                  {
                    label: 'Consulta',
                    data: 'SEL',
                    icon: 'pi pi-search',
                    tipo: 'SEL',
                    modulo: this.treeData[i].children[j].modulo,
                    menu: this.treeData[i].children[j].menu,
                    opcion: opcion.OPCCODIGO,
                    permiso: 0
                  },
                  {
                    label: 'Inserción',
                    data: 'INS',
                    icon: 'pi pi-plus',
                    tipo: 'INS',
                    modulo: this.treeData[i].children[j].modulo,
                    menu: this.treeData[i].children[j].menu,
                    opcion: opcion.OPCCODIGO,
                    permiso: 0
                  },
                  {
                    label: 'Actualización',
                    data: 'ACT',
                    icon: 'pi pi-pencil',
                    tipo: 'ACT',
                    modulo: this.treeData[i].children[j].modulo,
                    menu: this.treeData[i].children[j].menu,
                    opcion: opcion.OPCCODIGO,
                    permiso: 0
                  },
                  {
                    label: 'Eliminación',
                    data: 'ELI',
                    icon: 'pi pi-times',
                    tipo: 'ELI',
                    modulo: this.treeData[i].children[j].modulo,
                    menu: this.treeData[i].children[j].menu,
                    opcion: opcion.OPCCODIGO,
                    permiso: 0,
                    check: false
                  }
                ]
              };
              this.treeData[i].children[j].children.push(opc);
            }
          }
        }
      }
    }

    try {
      const rs = await this.segMaeUsuarioService.obtenerPermisos(this.txtIdUsuario, '01');
      this.arrayPermisos = rs;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }


    for (i = 0; i < this.treeData.length; i++) {
      for (j = 0; j < this.treeData[i].children.length; j++) {
        for (k = 0; k < this.treeData[i].children[j].children.length; k++) {
          for (let l = 0; l < this.treeData[i].children[j].children[k].children.length; l++) {
            for (const permiso of this.arrayPermisos) {
              if (permiso.MODPREFIJO === this.treeData[i].children[j].children[k].modulo
                && permiso.MENCODIGO === this.treeData[i].children[j].children[k].menu
                && permiso.OPCCODIGO === this.treeData[i].children[j].children[k].opcion) {

                if (this.treeData[i].children[j].children[k].children[l].tipo === 'INS') {
                  this.treeData[i].children[j].children[k].children[l].permiso = permiso.PERINSERCION;
                }
                if (this.treeData[i].children[j].children[k].children[l].tipo === 'SEL') {
                  this.treeData[i].children[j].children[k].children[l].permiso = permiso.PERSELECCION;
                }
                if (this.treeData[i].children[j].children[k].children[l].tipo === 'ACT') {
                  this.treeData[i].children[j].children[k].children[l].permiso = permiso.PERACTUALIZACION;
                }
                if (this.treeData[i].children[j].children[k].children[l].tipo === 'ELI') {
                  this.treeData[i].children[j].children[k].children[l].permiso = permiso.PERELIMACION;
                }
              }
            }
          }
        }
      }
    }

    this.selectedFiles2 = [];

    for (i = 0; i < this.treeData.length; i++) {
      for (j = 0; j < this.treeData[i].children.length; j++) {
        for (k = 0; k < this.treeData[i].children[j].children.length; k++) {
          for (let l = 0; l < this.treeData[i].children[j].children[k].children.length; l++) {
            const item = this.treeData[i].children[j].children[k].children[l];
            if (item.permiso === 1) {
              this.treeData[i].children[j].children[k].children[l].check = true;
              const node = this.treeData[i].children[j].children[k].children[l];
              this.treeData[i].children[j].children[k].partialSelected = true;
              this.treeData[i].children[j].partialSelected = true;
              this.treeData[i].partialSelected = true;
              this.selectedFiles2.push(...[node]);
            }
          }

        }
      }
    }

    this.spin = false;

  }

  async crearNodosPermisos1() {
    this.spin = true;
    let i = 0;
    let j = 0;
    let k = 0;
    const arreglo = [];
    try {
      const rs = await this.segMaeUsuarioService.obtenerModulos();

      for (const modulo of rs) {
        const mod = {
          label: modulo.MODULO,
          data: modulo.PREFIJO,
          icon: 'pi pi-folder',
          tipo: 'MOD',
          modulo: modulo.PREFIJO,
          children: []
        };
        arreglo.push(mod);
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }

    for (i = 0; i < arreglo.length; i++) {
      if (arreglo[i].tipo === 'MOD') {
        try {
          const rs = await this.segMaeUsuarioService.obtenerMenu(arreglo[i].data);
          for (const menu of rs) {
            const men = {
              label: menu.MENNOMBRE,
              data: menu.MENCODIGO,
              icon: 'pi pi-list',
              tipo: 'MEN',
              modulo: arreglo[i].modulo,
              menu: menu.MENCODIGO,
              children: []
            };
            arreglo[i].children.push(men);
          }
        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', mensaje);
        }
      }
    }

    try {
      const rs = await this.segMaeUsuarioService.obtenerOpciones();
      this.arrayOpciones = rs;

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }


    for (i = 0; i < arreglo.length; i++) {
      for (j = 0; j < arreglo[i].children.length; j++) {
        if (arreglo[i].children[j].tipo === 'MEN') {
          for (const opcion of this.arrayOpciones) {
            if (opcion.MODPREFIJO === arreglo[i].children[j].modulo && opcion.MENCODIGO === arreglo[i].children[j].menu) {
              const opc = {
                label: opcion.OPCNOMBRE,
                data: opcion.OPCCODIGO,
                icon: 'pi pi-file-o',
                tipo: 'OPC',
                modulo: arreglo[i].children[j].modulo,
                menu: arreglo[i].children[j].menu,
                opcion: opcion.OPCCODIGO,
                children: [
                  {
                    label: 'Consulta',
                    data: 'SEL',
                    icon: 'pi pi-search',
                    tipo: 'SEL',
                    modulo: arreglo[i].children[j].modulo,
                    menu: arreglo[i].children[j].menu,
                    opcion: opcion.OPCCODIGO,
                    permiso: 0
                  },
                  {
                    label: 'Inserción',
                    data: 'INS',
                    icon: 'pi pi-plus',
                    tipo: 'INS',
                    modulo: arreglo[i].children[j].modulo,
                    menu: arreglo[i].children[j].menu,
                    opcion: opcion.OPCCODIGO,
                    permiso: 0
                  },
                  {
                    label: 'Actualización',
                    data: 'ACT',
                    icon: 'pi pi-pencil',
                    tipo: 'ACT',
                    modulo: arreglo[i].children[j].modulo,
                    menu: arreglo[i].children[j].menu,
                    opcion: opcion.OPCCODIGO,
                    permiso: 0
                  },
                  {
                    label: 'Eliminación',
                    data: 'ELI',
                    icon: 'pi pi-times',
                    tipo: 'ELI',
                    modulo: arreglo[i].children[j].modulo,
                    menu: arreglo[i].children[j].menu,
                    opcion: opcion.OPCCODIGO,
                    permiso: 0,
                    check: false
                  }
                ]
              };
              arreglo[i].children[j].children.push(opc);
            }
          }
        }
      }
    }

    try {
      const rs = await this.segMaeUsuarioService.obtenerPermisos(this.txtIdUsuario, '01');
      this.arrayPermisos = rs;
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }


    for (i = 0; i < arreglo.length; i++) {
      for (j = 0; j < arreglo[i].children.length; j++) {
        for (k = 0; k < arreglo[i].children[j].children.length; k++) {
          for (let l = 0; l < arreglo[i].children[j].children[k].children.length; l++) {
            for (const permiso of this.arrayPermisos) {
              if (permiso.MODPREFIJO === arreglo[i].children[j].children[k].modulo
                && permiso.MENCODIGO === arreglo[i].children[j].children[k].menu
                && permiso.OPCCODIGO === arreglo[i].children[j].children[k].opcion) {

                if (arreglo[i].children[j].children[k].children[l].tipo === 'INS') {
                  arreglo[i].children[j].children[k].children[l].permiso = permiso.PERINSERCION;
                }
                if (arreglo[i].children[j].children[k].children[l].tipo === 'SEL') {
                  arreglo[i].children[j].children[k].children[l].permiso = permiso.PERSELECCION;
                }
                if (arreglo[i].children[j].children[k].children[l].tipo === 'ACT') {
                  arreglo[i].children[j].children[k].children[l].permiso = permiso.PERACTUALIZACION;
                }
                if (arreglo[i].children[j].children[k].children[l].tipo === 'ELI') {
                  arreglo[i].children[j].children[k].children[l].permiso = permiso.PERELIMACION;
                }
              }
            }
          }
        }
      }
    }

    this.selectedFiles2 = [];

    for (i = 0; i < arreglo.length; i++) {
      for (j = 0; j < arreglo[i].children.length; j++) {
        for (k = 0; k < arreglo[i].children[j].children.length; k++) {
          for (let l = 0; l < arreglo[i].children[j].children[k].children.length; l++) {
            const item = arreglo[i].children[j].children[k].children[l];
            if (item.permiso === 1) {
              arreglo[i].children[j].children[k].children[l].check = true;
              const node = arreglo[i].children[j].children[k].children[l];
              arreglo[i].children[j].children[k].partialSelected = true;
              arreglo[i].children[j].partialSelected = true;
              arreglo[i].partialSelected = true;
              this.selectedFiles2.push(...[node]);
            }
          }

        }
      }
    }

    this.treeData = arreglo;
    this.spin = false;
  }

  async desplegarUsuario() {

    this.datosUsuario = await this.consultarUsuarios('01');

    this.cmbUsuario = [];
    this.cmbUsuario.push({ label: '', codigo: '' });
    for (const item of this.datosUsuario) {
      this.cmbUsuario.push({ name: item.USUNOMBRE + ' ' + item.USUAPELLIDO, codigo: item.USUIDENTIFICACION });
    }
    this.strCmbUsuario = this.cmbUsuario[0];
  }

  cambioCmbUser(val) {
    this.activaBotones('M');
    this.mostrarModulosxUsuario();
    this.disabledTab(true, true, false, true, true);
  }

  async mostrarModulosxUsuario() {
    const codigoUsu = this.codigoUsuario();

    let condicion = 'USUidentificacion like \'' + codigoUsu + '\'' + ' AND ';
    condicion = condicion + 'COMcodigo like \'01\'';
    let condicion2 = ' USUidentificacion like \'' + this.txtIdUsuario + '\'' + ' AND ';
    condicion2 = condicion2 + ' COMcodigo like \'01\'';
    this.cmbModulo = [];

    try {
      const rs = await this.segMaeUsuarioService.consultaRegistro('UM', condicion, condicion2);
      this.cmbModulo = [];
      if (rs !== null) {
        this.cmbModulo.push({ name: '', codigo: '' });
        for (const modulo of rs) {
          this.cmbModulo.push({ name: modulo.MODPREFIJO + ' - ' + modulo.MODNOMBRE, codigo: modulo.MODPREFIJO });
        }
        this.strCmbModulo = this.cmbModulo[0];
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Error al mostrar los Módulos.... ! ' + mensaje);
    }

  }

  cambioCmbModulo(e) {
    this.mostrarMenusxUsuario();
  }

  async mostrarMenusxUsuario() {
    const codigoUsu = this.codigoUsuario();
    const strPrefijo = this.strCmbModulo.codigo;

    let strCondicion = ' COMcodigo like \'01\'  AND ';
    strCondicion = strCondicion + ' USUidentificacion like \'' + codigoUsu + '\' AND ';
    strCondicion = strCondicion + ' MODprefijo like \'' + strPrefijo + '\'';

    this.cmbMenu = [];

    console.log(strCondicion);

    try {
      const rs = await this.segMaeUsuarioService.consultaRegistro('UN', '', strCondicion);
      console.log(rs);
      this.cmbMenu = [];
      if (rs !== null) {
        this.cmbMenu.push({ name: '', codigo: '' });
        for (const menu of rs) {
          console.log(menu);
          this.cmbMenu.push({ name: menu.MENCODIGO + ' - ' + menu.MENNOMBRE, codigo: menu.MENCODIGO });
        }
        console.log(this.cmbMenu);
        this.strCmbMenu = this.cmbMenu[0];
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Error al mostrar los Menus.... ! ' + mensaje);
    }
  }

  codigoUsuario() {
    return this.strCmbUsuario.codigo;
  }

  async consultarUsuarios(compania) {
    const lista: SegMaeUsuario[] = [];

    try {
      const rs = await this.segMaeUsuarioService.consultarUsuarios(compania);
      if (rs !== null) {
        for (const usu of rs) {
          const item: SegMaeUsuario = {};
          item.BOD_CODIGO = usu.BOD_CODIGO;
          item.BOD_CODIGO_DEV = usu.BOD_CODIGO_DEV;
          item.COMCODIGO = usu.COMCODIGO;
          item.USUAPELLIDO = usu.USUAPELLIDO;
          item.USUCLAVE = usu.USUCLAVE;
          item.USUFECHAFINAL = usu.USUFECHAFINAL;
          item.USUFECHAINICIO = usu.USUFECHAINICIO;
          item.BOD_CODIGO_DEV = usu.BOD_CODIGO_DEV;
          item.USUIDENTIFICACION = usu.USUIDENTIFICACION;
          item.USUNOMBRE = usu.USUNOMBRE;
          item.USURUCI = usu.USURUCI;
          item.PERFIL_CODIGO = usu.PERFIL_CODIGO;

          lista.push(item);
        }
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
      return [];
    }

    return lista;
  }

  buscarBodega(parametro) {

    this.opcion = 'BuscarBodega';
    this.types = [
      { label: 'CODIGO', value: 'BOD_CODIGO' },
      { label: 'NOMBRE', value: 'BOD_NOMBRE' },
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = 'BOD_CODIGO, BOD_NOMBRE, BOD_TIPO';
    this.tabla = 'INV_MAEBODEGA';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\' and nvl(BOD_TIPO,\'dddd\')<>\'E\'';

    this.busquedaSer.busquedaInvMaeBodega5(parametro).subscribe((res: any[]) => {
      this.arregloCons = res;
      this.displayDialogBusquedaFast = true;
    });


  }

  buscarBodegaDev(parametro) {

    this.opcion = 'BuscarBodegaDev';
    this.types = [
      { label: 'CODIGO', value: 'BOD_CODIGO' },
      { label: 'NOMBRE', value: 'BOD_NOMBRE' },
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = 'BOD_CODIGO, BOD_NOMBRE, BOD_TIPO';
    this.tabla = 'INV_MAEBODEGA';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\' and nvl(BOD_TIPO,\'dddd\')<>\'E\'';

    this.busquedaSer.busquedaInvMaeBodega5(parametro).subscribe((res: any[]) => {
      this.arregloCons = res;
      this.displayDialogBusquedaFast = true;
    });


  }

  buscarVendedor(parametro) {
    this.opcion = 'BuscarVendedor';
    this.types = [
      { label: 'CODIGO', value: 'VEN_CODIGO' },
      { label: 'NOMBRE', value: 'VEN_NOMBRE' },
    ];
    this.busquedacampos = [parametro, ''];
    this.consulta = 'VEN_CODIGO, VEN_NOMBRE';
    this.tabla = 'VEN_MAEVENDEDOR';
    this.where = this.types[0].value + ' LIKE \'' + parametro + '%\'';

    this.segMaeUsuarioService.erMaeVendedor(parametro).subscribe(eR => {
        if (eR !== null) {
          if (Object.keys(eR).length === 1) {
            this.manejarSeleccion(eR[0]);
          } else {
            this.busquedaSer.busquedaVenMaeVendedor3(parametro).subscribe((res: any[]) => {
              this.arregloCons = res;
              this.displayDialogBusquedaFast = true;
            });
          }
        } else {
          this.busquedaSer.busquedaVenMaeVendedor3(parametro).subscribe((res: any[]) => {
            this.arregloCons = res;
            this.displayDialogBusquedaFast = true;
          });
        }
      });
  }

  async guardarPermisosNodos() {
    this.spin = true;
    let i = 0;
    let j = 0;
    let k = 0;

    let intSel = 0;
    let intEli = 0;
    let intIns = 0;
    let intAct = 0;
    let intTodo = 0;

    try {
      await this.segMaeUsuarioService.eliminarPermisos(this.txtIdUsuario, '01');
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Error al eliminar los permisos...! ' + mensaje);
      return false;
    }

    for (i = 0; i < this.treeData.length; i++) {
      for (j = 0; j < this.treeData[i].children.length; j++) {
        for (k = 0; k < this.treeData[i].children[j].children.length; k++) {
          intSel = 0;
          intEli = 0;
          intIns = 0;
          intAct = 0;
          intTodo = 0;
          for (let l = 0; l < this.treeData[i].children[j].children[k].children.length; l++) {
            const item = this.treeData[i].children[j].children[k].children[l];
            for (let n = 0; n < this.selectedFiles2.length; n++) {
              if (item.menu === this.selectedFiles2[n].menu && item.tipo === this.selectedFiles2[n].tipo
                && item.modulo === this.selectedFiles2[n].modulo && item.opcion === this.selectedFiles2[n].opcion) {
                if (item.tipo === 'SEL') {
                  intSel = 1;
                }
                if (item.tipo === 'ELI') {
                  intEli = 1;
                }
                if (item.tipo === 'INS') {
                  intIns = 1;
                }
                if (item.tipo === 'ACT') {
                  intAct = 1;
                }

              }
            }
            if (intSel === 1 && intEli === 1 && intIns === 1 && intAct === 1) {
              intTodo = 1;
            }
          }
          if (intSel === 1 || intEli === 1 || intIns === 1 || intAct === 1) {
            try {
              console.log(this.treeData[i].children[j].children[k].label, this.treeData[i].children[j].children[k].opcion);
              await this.segMaeUsuarioService.insertarPermisos(this.txtIdUsuario, '01', intSel, intIns, intAct, intEli, intTodo,
                this.treeData[i].children[j].children[k].opcion, this.treeData[i].children[j].children[k].menu,
                this.treeData[i].children[j].children[k].modulo);
            } catch (err) {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
              this.mensajeAlerta('error', 'Error', '! Error al guardar los permisos.... ! ' + mensaje);
              return false;
            }
          }
        }
      }
    }




    return true;




  }

  permisoSelect(e) {
    this.btnGuardar = false;
    this.btnNuevo = true;
    this.btnBorrar = true;
    this.disabledTab(true, false, true, true, true);


  }

  permisoUnselect(e) {
    this.btnGuardar = false;
    this.btnNuevo = true;
    this.btnBorrar = true;
    this.disabledTab(true, false, true, true, true);
  }

  selTodosModulos(e) {
    if (e.checked) {
      this.disabledSelTodosMod = true;
    } else {
      this.disabledSelTodosMod = false;
    }
  }

  selTodosMenus(e) {
    if (e.checked) {
      this.disabledSelTodosMenu = true;
    } else {
      this.disabledSelTodosMenu = false;
    }
  }

  async guardarPermisosPredefinidos() {
    let i;
    const NumItems = this.cmbModulo.length;
    const codigoUsu = this.codigoUsuario();
    let modulo = this.strCmbModulo;
    let strPrefijo = '';
    let strMenu = '';

    // Asigna todos los permisos
    if (this.disabledSelTodosMod === true && this.disabledSelTodosMenu === true) {
      for (i = 1; i < NumItems; i++) {
        modulo = this.cmbModulo[i];
        // intPos = modulo.indexOf("-");
        strPrefijo = modulo.codigo;

        try {
          await this.segMaeUsuarioService.copiarPermisos('MN', '01', codigoUsu, this.txtIdUsuario, strPrefijo, '');
          this.disabledTab(false, false, false, false, false);
          // this.mensajeAlerta('success', 'Información', 'Permisos copiados satisfactoriamente...! ');
        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', '! Error al copiar  los permisos.... ! ' + mensaje);
        } finally {
          this.activaBotones('G');
        }
      }
    } // fin si se asigna todos los permisos

    // Asigna solo los permisos del módulo seleccionado
    if (this.disabledSelTodosMod === false && this.disabledSelTodosMenu === true) {
      if (this.strCmbModulo.name === '') {
        this.mensajeAlerta('info', 'Información', 'Seleccione un módulo');
      } else {
        // modulo = cmbModulo.getText();
        // int_pos=modulo.indexOf("-");
        strPrefijo = this.strCmbModulo.codigo;
        try {
          await this.segMaeUsuarioService.copiarPermisos('M', '01', codigoUsu, this.txtIdUsuario, strPrefijo, '');
          this.mensajeAlerta('success', 'Información', 'Permisos copiados satisfactoriamente...! ');
          this.disabledTab(false, false, false, false, false);
        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', '! Error al copiar los permisos.... ! ' + mensaje);
        } finally {
          this.activaBotones('G');
        }
      }
    } // fin solo se asigna permisos del módulo seleccionado

    // Asigna los permisos del menu seleccionado
    if (this.disabledSelTodosMod === false && this.disabledSelTodosMenu === false) {

      if (this.strCmbModulo.name === '' && this.strCmbMenu.name === '') {
        this.mensajeAlerta('info', 'Información', 'Seleccione un módulo y un menú');

      } else {
        strPrefijo = this.strCmbModulo.codigo;
        strMenu = this.strCmbMenu.codigo;

        try {
          await this.segMaeUsuarioService.copiarPermisos('N', '01', codigoUsu, this.txtIdUsuario, strPrefijo, strMenu);
          this.activaBotones('G');
          this.mensajeAlerta('success', 'Información', 'Permisos copiados satisfactoriamente...! ');
          this.disabledTab(false, false, false, false, false);
        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', '! Error al copiar los permisos.... ! ' + mensaje);
        } finally {
          this.activaBotones('G');
        }
      }
    } // fin asigna permisos para el módulo y menú seleccionado
  }


  activarBotonesNum(strTipo) {
    if (strTipo === 'N') { // Nuevo registro
      this.btnCancelarGrid = false;
      this.btnBorrarGrid = true;
      this.btnNuevoGrid = true;
      this.btnGuardar = false;
    }
    if (strTipo === 'E') { // Elimina registro
      this.btnCancelarGrid = true;
      this.btnBorrarGrid = false;
      this.btnNuevoGrid = false;
      this.btnGuardar = true;
    }
    if (strTipo === 'C') { // cancela acción
      this.btnCancelarGrid = true;
      this.btnBorrarGrid = false;
      this.btnNuevoGrid = false;
      this.btnGuardar = true;
      this.cancelarNumeraciones();
    }
    if (strTipo === 'I') { // Iniciar

      this.btnCancelarGrid = true;
      this.btnBorrarGrid = false;
      this.btnNuevoGrid = false;
      this.btnGuardar = true;

      // getTable().redraw();
      // getTable().setFocus();
      // int intNumFilas=modelo.doGetRowCount()-1;
      // if (intNumFilas>=1)
      //   getTable().setSelection(1, intNumFilas, true);
    }
    if (strTipo === 'G') { // Guardar
      this.btnCancelarGrid = true;
      this.btnBorrarGrid = false;
      this.btnNuevoGrid = false;
      this.btnGuardar = true;
    }
    if (strTipo === 'M') { // Modificar elemento
      this.btnCancelarGrid = false;
      this.btnBorrarGrid = true;
      this.btnNuevoGrid = true;
      this.btnGuardar = false;
    }
  }

  seleccionDetNumeracion(valor) {
    console.log(valor);
    if (valor === 'nuevo') {
      this.nuevoElementoNum();

    }
    if (valor === 'eliminar') {
      this.eliminarRegistroNum();
    }
    if (valor === 'cancelar') {
      this.activarBotonesNum('C');
      this.cancelarNumeraciones();
    }

    if (valor.object !== undefined) {
      this.detNumeracionSel = valor.object;
    }
  }

  nuevoElementoNum() {
    if (this.btnNuevoGrid) {
      return;
    }
    const item: SaciMaeNumeracion = {};
    item.NUM_DOCREF = '';
    item.NUM_NEMONICO = '';
    item.NUM_SERIE = '';
    item.NUM_SECINICIAL = '0000001';
    item.NUM_SECFINAL = '9999999';
    item.NUM_SECACTUAL = '';

    const indi = this.segMaeUsuarioService.detnumeracionarray[this.indicador].indexOf(this.detNumeracionSel);
    if (indi === -1) {
      this.segMaeUsuarioService.detnumeracionarray[this.indicador].push(item);
      this.detNumeracionSel = this.segMaeUsuarioService.detnumeracionarray[this.indicador]
      [this.segMaeUsuarioService.detnumeracionarray[this.indicador].length - 1];
      this.aggrid.refreshaggrid(this.segMaeUsuarioService.detnumeracionarray[this.indicador], this.defaultSegNumPV.objeto);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultSegNumPV.objeto].setFocusedCell(
        this.segMaeUsuarioService.detnumeracionarray[this.indicador].length - 1, 'NUM_DOCREF');
    } else {
      this.segMaeUsuarioService.detnumeracionarray[this.indicador].splice(indi + 1, 0, item);
      this.aggrid.refreshaggridindex(this.segMaeUsuarioService.detnumeracionarray[this.indicador], this.defaultSegNumPV.objeto, indi);
      this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultSegNumPV.objeto].setFocusedCell(
        indi + 1, 'NUM_DOCREF');
    }

    this.bolNuevoItemNum = true;
    this.activarBotonesNum('N');
    // this.segMaeUsuarioService.detnumeracionarray[this.indicador].push(item);
    // this.aggrid.refreshaggridindex(this.segMaeUsuarioService.detnumeracionarray[this.indicador], this.defaultSegNumPV.objeto, indi);
    //
    // this.aggrid.gridApi.setFocusedCell(indi + 1, 'NUM_DOCREF');

  }

  eliminarRegistroNum() {
    if (Object.keys(this.detNumeracionSel).length > 0) {
      this.confirmEliminarDetNum();
    } else {
      this.mensajeAlerta('error', 'Error', '! Seleccione una fila.... !');
    }
  }

  confirmEliminarDetNum() {
    this.confirmationService.confirm({
      message: '¿ Está seguro de eliminar el registro ?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      key: 'segmaeusuarioConf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminarDetalleNum();
      },
      reject: () => {
      }
    });
  }

  async eliminarDetalleNum() {

    try {
      console.log(this.txtIdUsuarioNum);
      await this.segMaeUsuarioService.eliminarDetNumeracion(this.txtIdUsuarioNum, '01', this.detNumeracionSel.NUM_DOCREF,
        this.detNumeracionSel.NUM_DOCREF, this.detNumeracionSel.NUM_SERIE);
      this.auditoriaGralService.registrarAuditoria('SEG_MAENUMERACION', this.detNumeracionSel.NUM_DOCREF + '/' + this.detNumeracionSel.NUM_NEMONICO, 'E',
        this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
        });
      this.segMaeUsuarioService.detnumeracionarray[this.indicador].splice(this.segMaeUsuarioService.detnumeracionarray[this.indicador].findIndex(
        item => item.NUM_CLASE === this.detNumeracionSel.NUM_CLASE && item.NUM_NEMONICO === this.detNumeracionSel.NUM_NEMONICO),
        1);

      this.aggrid.refreshaggrid(this.segMaeUsuarioService.detnumeracionarray[this.indicador], this.defaultSegNumPV.objeto);

      this.activarBotonesNum('E');

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  }

  async guardarDatosNum() {
    if (this.bolNuevoItemNum === true) { // Crea un nuevo registro

      if (await this.existeNumRepetidas(this.detNumeracionSel.NUM_DOCREF)) {
        return;
      }
      if (this.verificarDatos() === true) {
        const item: SaciMaeNumeracion = this.detNumeracionSel;
        try {
          let fechaCad = '';
          if (item.NUM_FECHACAD.toString().includes('-')) {
            fechaCad = this.datePipe.transform(item.NUM_FECHACAD, 'dd/MM/yyyy');
          } else {
            fechaCad = item.NUM_FECHACAD;
          }
          await this.segMaeUsuarioService.insertarNumeracion(this.txtIdUsuarioNum, '01', item.NUM_DOCREF, item.NUM_DOCREF,
            item.NUM_SERIE, String(item.NUM_SECINICIAL), String(item.NUM_SECFINAL), String(item.NUM_SECACTUAL), '',
            String(item.NUM_AUTORIZACION), fechaCad, '', '', '','');
          this.auditoriaGralService.registrarAuditoria('SEG_MAENUMERACION', this.detNumeracionSel.NUM_DOCREF + '/' + item.NUM_NEMONICO, 'I',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
            });
          this.activarBotonesNum('G');
          this.mensajeAlerta('success', 'Información', 'Numeración guardada exitosamente !!!');
        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', '! Error al guardar la numeración.... ! ' + mensaje);
        }
      }
      // Fin Crea un nuevo registro
    } else if (this.bolNuevoItemNum === false) {
      if (this.verificarDatos() === true) {
        const item: SaciMaeNumeracion = this.detNumeracionSel;

        try {
          let fechaCad = '';
          if (item.NUM_FECHACAD.toString().includes('-')) {
            fechaCad = this.datePipe.transform(item.NUM_FECHACAD, 'dd/MM/yyyy');
          } else {
            fechaCad = item.NUM_FECHACAD;
          }

          await this.segMaeUsuarioService.actualizarNumeracion(this.txtIdUsuarioNum, '01', item.NUM_DOCREF, item.NUM_DOCREF,
            item.NUM_SERIE, String(item.NUM_SECINICIAL), String(item.NUM_SECFINAL), String(item.NUM_SECACTUAL),
            String(item.NUM_AUTORIZACION), fechaCad, '', '','');

          // Registrar Auditoria
          this.auditoriaGralService.registrarAuditoria('SEG_MAENUMERACION', this.detNumeracionSel.NUM_DOCREF + '/' + item.NUM_NEMONICO, 'A',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
            });
          this.activarBotonesNum('G');
          this.mensajeAlerta('success', 'Información', 'Numeración actualizada exitosamente !!!');

        } catch (err) {
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', '! Error al actualizar la numeración.... ! ' + mensaje);
        }
      }

    } // fin actualizar datos
  }

  verificarDatos(): boolean {
    const item: SaciMaeNumeracion = this.detNumeracionSel;

    if (item.NUM_DOCREF.length === 0 || item.NUM_SERIE.length === 0 || item.NUM_SECACTUAL.length === 0 || item.NUM_SECINICIAL.length === 0
      || item.NUM_SECFINAL.length === 0 || item.NUM_AUTORIZACION.length === 0 || item.NUM_FECHACAD.length === 0) {
      this.mensajeAlerta('error', 'Error', 'Ingrese correctamente todos los datos');
      return false;
    }
    return true;
  }

  async existeNumRepetidas(strTipoDoc) {
    let bolExiste = false;
    const strSql = 'SELECT COUNT(*) as cont FROM SACI_MAENUMERACION WHERE NUM_TIPO IS NULL AND NUM_CLASE = \'' +
      this.txtIdUsuarioNum + '\' AND NUM_DOCREF = \'' + strTipoDoc + '\' AND COM_CODIGO = \'01\'';

    try {
      const rs = await this.segMaeUsuarioService.ejecutarSentencia(this.txtIdUsuarioNum, strTipoDoc);
      if (rs !== null) {
        if (rs[0].CONT > 0) {
          this.mensajeAlerta('error', 'Error', 'No se puede guardar otra numeración para ' +
            'el tipo de documento ' + strTipoDoc);
          bolExiste = true;
        }
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', 'No se puede verificar si existe numeraciones repetidas ' + mensaje);
    }
    return bolExiste;
  }

  cambioGrid(event) {
    this.activarBotonesNum('M');
  }

  cancelarNumeraciones() {
    this.mostrarNumeraciones();
    this.bolNuevoItemNum = false;
  }

  disabledTab(tab0, tab1, tab2, tab3, tab4) {
    this.disabledTab0 = tab0;
    this.disabledTab1 = tab1;
    this.disabledTab2 = tab2;
    this.disabledTab3 = tab3;
    this.disabledTab4 = tab4;
  }

  async mostrarDatosModulosPerEsp() {
    this.cmbModuloPerEsp = [];

    try {
      const rs = await this.segMaeUsuarioService.listarModuloPerEsp();
      this.cmbModuloPerEsp = [];
      if (rs !== null) {
        this.cmbModuloPerEsp.push({ name: '', codigo: '' });
        for (const modulo of rs) {
          this.cmbModuloPerEsp.push({ name: modulo.MODNOMBRE, codigo: modulo.MOD_PREFIJO });
        }
        this.strCmbModuloPerEsp = this.cmbModuloPerEsp[0];
      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Error al desplegar los Módulos.... ! ' + mensaje);
    }
  }

  async mostrarUsuariosPerEsp() {
    this.cmbSuperUser = [];
    try {
      const rs = await this.segMaeUsuarioService.listarUsuariosPerEsp();
      this.cmbSuperUser = [];
      if (rs !== null) {
        this.cmbSuperUser.push({ name: '', codigo: '', clave: '' });
        for (const user of rs) {
          this.cmbSuperUser.push({ name: user.USUNOMBRE + ' ' + user.USUAPELLIDO, codigo: user.USUIDENTIFICACION, clave: user.USUCLAVE });
        }
        this.strCmbSuperUser = this.cmbSuperUser[0];
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Error al mostrar los usuarios.... ! ' + mensaje);
    }
  }

  cambioCmbModuloPerEsp(e) {
    this.mostrarOpciones(this.strCmbModuloPerEsp.codigo);
  }

  async mostrarOpciones(strPrefijo) {
    this.cmbOpcPerEsp = [];
    try {
      const rs = await this.segMaeUsuarioService.listarOpcionesPerEsp(strPrefijo);
      this.cmbOpcPerEsp = [];
      if (rs !== null) {
        this.cmbOpcPerEsp.push({ name: '', codigo: '', objeto: '' });
        for (const opc of rs) {
          this.cmbOpcPerEsp.push({ name: opc.OPCNOMBRE, codigo: opc.OPCCODIGO, objeto: opc.OPCOBJETO });
        }
        this.strCmbOpcPerEsp = this.cmbOpcPerEsp[0];
      }
    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', '! Error al mostrar los usuarios.... ! ' + mensaje);
    }
  }

  cambioCmbOpcionPerEsp(e) {
    this.listarPermisosEspeciales(this.txtIdUsuarioPerEsp, this.strCmbModuloPerEsp.codigo, this.strCmbOpcPerEsp.objeto);
    this.mostrarSuperUsuario(this.txtIdUsuarioPerEsp);
  }

  async mostrarSuperUsuario(strIdUsuario) {
    console.log(strIdUsuario);
    if (strIdUsuario !== '') {
      try {
        const rs = await this.segMaeUsuarioService.mostrarSuperUsuario(strIdUsuario);
        console.log(rs);
        if (rs.length > 0) {
          for (const a of this.cmbSuperUser) {
            if (a.codigo === rs[0].USUIDENTIFICACION) {
              this.strCmbSuperUser = { name: rs[0].USUNOMBRE + ' ' + rs[0].USUAPELLIDO, codigo: rs[0].USUIDENTIFICACION, clave: rs[0].USUCLAVE };
              this.strCmbSuperUser = a;
              this.segMaeUsuarioService.strSuperUserSelect = this.strCmbSuperUser;
            }
          }
          console.log(this.strCmbSuperUser);
        }
        // rs=eje.EjecutaSP("SACI_PCK_CONSULTA.GEN_SP_consulta",2, parametrosSp, true);

        // while (rs.next()) {
        //   cmbUsuarios.setText(rs.getString("usunombre") + " " + rs.getString("usuapellido").trim());
        // }

      } catch (err) {
        let mensaje: string;
        mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
        this.mensajeAlerta('error', 'Error', '! Error al mostrar el SuperUsuario.... ! ' + mensaje);
      }
    }
    this.segMaeUsuarioService.strSuperUserSelect = this.strCmbSuperUser;
  }

  async listarPermisosEspeciales(strIdUsuario, strPrefijo, strForma) {
    const detalle: SaciMaeGuiObjPer[] = [];
    try {
      const rs = await this.segMaeUsuarioService.listarPermisosEspeciales(strForma, strPrefijo, strIdUsuario);
      console.log(rs);
      if (rs !== null) {
        for (const perm of rs) {
          const item: SaciMaeGuiObjPer = {};

          item.MOD_PREFIJO = perm.MOD_PREFIJO;
          item.GUIOBJ_FORMA = perm.GUIOBJ_FORMA;
          item.GUIOBJ_NOMBOBJ = perm.GUIOBJ_NOMBOBJ;
          item.GUIOBJ_DESCRIPCION = perm.GUIOBJ_DESCRIPCION;
          item.GUIOBJ_NOMBRE = perm.GUIOBJ_NOMBRE;
          item.GUIOBJ_TIPO = perm.GUIOBJ_TIPO;
          item.GUIOBJ_NOMBOPC = perm.GUIOBJ_NOMBOPC;
          item.GUIOBJ_TABCOL = perm.GUIOBJ_TABCOL === 0 ? '' : perm.GUIOBJ_TABCOL;
          item.GUIOBJ_TIPOPER = perm.GUIOBJ_TIPOPER === null ? '' : perm.GUIOBJ_TIPOPER;
          item.GUIOBJ_VALORMAX = perm.GUIOBJ_VALORMAX === null ? '' : perm.GUIOBJ_VALORMAX;
          item.COM_CODIGO = perm.COM_CODIGO;
          item.GUIOBJ_CODIGO = perm.GUIOBJ_CODIGO_P;
          item.GUIOBJPER_CODIGO = perm.GUIOBJPER_CODIGO;
          item.GUIOBJPER_DESCRIPCION = perm.GUIOBJPER_DESCRIPCION;
          item.GUIOBJPER_DSCTOMAXVTA = perm.GUIOBJPER_DSCTOMAXVTA === 0 ? '' : perm.GUIOBJPER_DSCTOMAXVTA;
          item.GUIOBJPER_DSCTOMAXVTAV = perm.GUIOBJPER_DSCTOMAXVTAV === 0 ? '' : perm.GUIOBJPER_DSCTOMAXVTAV;

          if (perm.GUIOBJPER_ESTADO === 0 && perm.GUIOBJPER_CODIGO !== null) {//CDPJ SE ELIMINÓ && perm.GUIOBJ_TIPOPER === 'D' PARA QUE AL ACTUALIZAR NO SE BORRE EL IDE SUÉRUSUARIO 
            item.GUIOBJPERESTADO = 'S';
          } else {
            item.GUIOBJPERESTADO = '';
          }
          item.GUIOBJPER_PERFIL = perm.GUIOBJPER_PERFIL;
          item.GUIOBJPERSUP_ESTADO = perm.GUIOBJPERSUP_ESTADO;
          item.OPCCODIGO = perm.OPCCODIGO;
          item.USU_CLAVESUP = perm.USU_CLAVESUP;

          if (perm.USU_CLAVESUP !== null) {
            item.APLICA_CLAVE = 'S';
          }

          item.USU_IDENTIFICACION = perm.USU_IDENTIFICACION;
          item.USU_IDENTIFICACIONSUP = perm.USU_IDENTIFICACIONSUP;


          detalle.push(item);
        }
        this.segMaeUsuarioService.detguiobjpermiarray[this.indicador] = detalle;
      } else {
        this.segMaeUsuarioService.detguiobjpermiarray[this.indicador] = [];
      }

    } catch (err) {
      let mensaje: string;
      mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
      this.mensajeAlerta('error', 'Error', mensaje);
    }
  }

  mensajeAlerta(tipo, titulo, msg) {
    this.message.add({
      key: 'segMaeUsuario',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }

  cambioCmbSuperUser(e) {
    this.segMaeUsuarioService.strSuperUserSelect = this.strCmbSuperUser;
  }

  async insertarElementoPerEsp() {


    let strIdsuperUsuario = this.strCmbSuperUser.codigo;
    const index = this.segMaeUsuarioService.detguiobjpermiarray[this.indicador].indexOf(this.detPerEspecialesSel);
    for (let i = 0; i < this.segMaeUsuarioService.detguiobjpermiarray[this.indicador].length; i++) {

      const item = this.segMaeUsuarioService.detguiobjpermiarray[this.indicador][i];
      console.log(item);

      if (this.bolNuevoItemPerEsp === false) { // Guarda del registro

        if (item.GUIOBJPERESTADO === 'S') {
          item.GUIOBJPER_ESTADO = 1;
          strIdsuperUsuario = this.strCmbSuperUser.codigo;//CDPJ
        } else {
          item.GUIOBJPER_ESTADO = 0;
          strIdsuperUsuario = '';
        }

        if (item.APLICA_CLAVE === 'S') {
          item.GUIOBJPERSUP_ESTADO = 1;
        } else {
          item.GUIOBJPERSUP_ESTADO = 0;
        }
        //CDPJ
        item.OPCCODIGO=this.strCmbOpcPerEsp.codigo;
        //CDPJ

        try {
          const rs = await this.segMaeUsuarioService.guardarPerEspeciales(item.GUIOBJ_CODIGO, this.txtIdUsuarioPerEsp, '01', item.GUIOBJPER_ESTADO,
            item.GUIOBJPER_PERFIL, item.GUIOBJPER_DESCRIPCION, item.GUIOBJPER_CODIGO, item.OPCCODIGO, item.GUIOBJPERSUP_ESTADO, strIdsuperUsuario,
            item.USU_CLAVESUP, item.GUIOBJPER_DSCTOMAXVTA, item.GUIOBJPER_DSCTOMAXVTA, '');

          if (rs !== null) {
            if (rs[0].GUIOBJPER_CODIGO !== null) {
              this.segMaeUsuarioService.detguiobjpermiarray[this.indicador][i].GUIOBJPER_CODIGO = rs[0].GUIOBJPER_CODIGO;
            }

          }

          // Registrar Auditoria

          this.auditoriaGralService.registrarAuditoria('SACI_MAEGUIOBJPER', item.GUIOBJPER_CODIGO + '/' + item.GUIOBJ_TIPO, 'A',
            this.usuario.identificacion, '01', '', '', '', '').subscribe(() => {
            });


        } catch (err) {
          console.log(err.error);
          let mensaje: string;
          mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
          this.mensajeAlerta('error', 'Error', '! Error al guardar los permisos especiales.... ! ' + mensaje);
        }

      } // Fin Crea un nuevo registro
    }
    this.mensajeAlerta('success', 'Información', 'Permisos actualizados exitosamente... !!!');
    this.disabledTab(false, false, false, false, false);

    this.activaBotones('G');
  }

  seleccionDetPerEsp(valor) {
    if (valor.object !== undefined) {
      this.detPerEspecialesSel = valor.object;
    }

  }

  cambioGridPerEsp(e) {
    this.activaBotones('M');
    this.disabledTab(true, true, true, true, false);
  }

  cambiarFoco(evento) {
    if (evento.target.id === 'numCedUser') {
      document.getElementById('nombreUser').focus();
    }

    if (evento.target.id === 'nombreUser') {
      document.getElementById('apellidoUser').focus();
    }
    if (evento.target.id === 'apellidoUser') {
      document.getElementById('correoPinUser').focus();
    }
    if (evento.target.id === 'correoPinUser') {
      document.getElementById('celularPinUser').focus();
    }
    if (evento.target.id === 'celularPinUser') {
      document.getElementById('cmbPerfilUsuario').focus();
    }
    if (evento.target.id === 'cmbPerfilUsuario') {
      document.getElementById('usuario').focus();
    }
    if (evento.target.id === 'usuario') {
      document.getElementById('clave').focus();
    }
    if (evento.target.id === 'clave') {
      document.getElementById('confirmarClave').focus();
    }
    if (evento.target.id === 'confirmarClave') {
      document.getElementById('BOD_VENTAS').focus();
    }
    if (evento.target.id === 'BOD_VENTAS') {
      document.getElementById('BOD_INGDEV').focus();
    }
    if (evento.target.id === 'BOD_INGDEV') {
      document.getElementById('CODIGO_VENDEDOR').focus();
    }
  }

  async enterToken(event: any) {
    if (this.emailAdmin === false) {
      if (event.keyCode === 13) {
        const resp = await this.utilitariosService.validarTokenActAdm(this.tokenusuario, this.txtIdUsuario);
        if (resp === true) {
          this.tokenusuario = '';
          this.displayToken = false;
          if (this.indexTabUsuarios === 0) {
            try {
              await this.segMaeUsuarioService.actualizarUsuario(this.usuIdentificacionT, this.usuClaveT, this.usuRuciT, this.usuApellidoT, this.usuNombreT, this.comCodigoT,
                this.usuPerfilT, this.usuFechaInicioT, this.usuFechaFinalT, this.bodCodigoT, this.bodCodigoDevT, this.strVenCodigoT, this.strCorreoPinT, this.strTelefonoPinT, this.usuautenticacionT, this.usualmacenardocT, this.usuvisualizardocT,
                this.usuverpreciosconsuexist,
                this.usuvertotcxc,
                  this.usutodclientes,//GSRF
                this.usutodpedidos,//GSRF
                );//CDPJ);//CDPJ AGREGUÉ verpreconexist y vertotcxc
              this.mensajeAlerta('success', 'Información', 'Usuario actualizado exitosamente !!!!');
              this.disabledTab(false, false, false, false, false);
            } catch (err) {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
              this.mensajeAlerta('error', 'Error', mensaje);
            }
          }
  
          if (this.indexTabUsuarios === 2) {
            this.guardarPermisosPredefinidos();
          }
  
          if (this.indexTabUsuarios === 1) {
            if (await this.guardarPermisosNodos() === true) {
              this.spin = false;
              this.mensajeAlerta('success', 'Información', 'Permisos guardados exitosamente !!! ');
              this.activaBotones('G');
              this.disabledTab(false, false, false, false, false);
            }
  
          }
          if (this.indexTabUsuarios === 4) {
  
            // this.aggrid.gridApi.stopEditing();
            // this.aggrid.gridApi.tabToPreviousCell();
            this.insertarElementoPerEsp();
            // compositePerEsp.GuardarDatos();
          }
          if (this.indexTabUsuarios === 3) {
            this.aggrid.gridApi.stopEditing();
            // this.aggrid.gridApi.tabToPreviousCell();
            // this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultSegNumPV.objeto].setFocusedCell(
            //   this.segMaeUsuarioService.detnumeracionarray[this.indicador].indexOf(this.detNumeracionSel), 'NUM_AUTORIZACION');
            this.guardarDatosNum();
          }
        } else {
          this.tokenusuario = '';
          this.message.add({
            key: 'segMaeUsuToken',
            severity: 'error',
            summary: 'Error de Actualización',
            detail: 'Token ingresado no coincide. Verifique su correo'
          });
        }
      }
    } else {
      if (event.keyCode === 13) {
        const resp = await this.utilitariosService.validarTokenActAdm(this.tokenusuario, 'admin');
        if (resp === true) {
          this.tokenusuario = '';
          this.displayToken = false;
          if (this.indexTabUsuarios === 0) {
            try {
              await this.segMaeUsuarioService.actualizarUsuario(this.usuIdentificacionT, this.usuClaveT, this.usuRuciT, this.usuApellidoT, this.usuNombreT, this.comCodigoT,
                this.usuPerfilT, this.usuFechaInicioT, this.usuFechaFinalT, this.bodCodigoT, this.bodCodigoDevT, this.strVenCodigoT, this.strCorreoPinT, this.strTelefonoPinT, this.usuautenticacionT, this.usualmacenardocT, this.usuvisualizardocT,
                this.usuverpreciosconsuexist,
                this.usuvertotcxc,
                this.usutodclientes,//GSRF
              this.usutodpedidos,//GSRF
              );//CDPJ);//CDPJ AGREGUÉ verpreconexist
              this.mensajeAlerta('success', 'Información', 'Usuario actualizado exitosamente !!!!');
              this.disabledTab(false, false, false, false, false);
            } catch (err) {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
              this.mensajeAlerta('error', 'Error', mensaje);
            }
          }
  
          if (this.indexTabUsuarios === 2) {
            this.guardarPermisosPredefinidos();
          }
  
          if (this.indexTabUsuarios === 1) {
            if (await this.guardarPermisosNodos() === true) {
              this.spin = false;
              this.mensajeAlerta('success', 'Información', 'Permisos guardados exitosamente !!! ');
              this.activaBotones('G');
              this.disabledTab(false, false, false, false, false);
            }
  
          }
          if (this.indexTabUsuarios === 4) {
  
            // this.aggrid.gridApi.stopEditing();
            // this.aggrid.gridApi.tabToPreviousCell();
            this.insertarElementoPerEsp();
            // compositePerEsp.GuardarDatos();
          }
          if (this.indexTabUsuarios === 3) {
            this.aggrid.gridApi.stopEditing();
            // this.aggrid.gridApi.tabToPreviousCell();
            // this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultSegNumPV.objeto].setFocusedCell(
            //   this.segMaeUsuarioService.detnumeracionarray[this.indicador].indexOf(this.detNumeracionSel), 'NUM_AUTORIZACION');
            this.guardarDatosNum();
          }
        } else {
          this.tokenusuario = '';
          this.message.add({
            key: 'segMaeUsuToken',
            severity: 'error',
            summary: 'Error de Actualización',
            detail: 'Token ingresado no coincide. Verifique su correo'
          });
        }
      }
    }
    
  }

  async aceptarToken() {
    if (this.emailAdmin === false) {
      
        const resp = await this.utilitariosService.validarTokenActAdm(this.tokenusuario, this.txtIdUsuario);
        if (resp === true) {
          this.tokenusuario = '';
          this.displayToken = false;
          if (this.indexTabUsuarios === 0) {
            try {
              await this.segMaeUsuarioService.actualizarUsuario(this.usuIdentificacionT, this.usuClaveT, this.usuRuciT, this.usuApellidoT, this.usuNombreT, this.comCodigoT,
                this.usuPerfilT, this.usuFechaInicioT, this.usuFechaFinalT, this.bodCodigoT, this.bodCodigoDevT, this.strVenCodigoT, this.strCorreoPinT, this.strTelefonoPinT, this.usuautenticacionT, this.usualmacenardocT, this.usuvisualizardocT,
                this.usuverpreciosconsuexist,
                this.usuvertotcxc,
                this.usutodclientes,//GSRF
              this.usutodpedidos,//GSRF
              );//CDPJ);//CDPJ AGREGUÉ verpreconexist
              this.mensajeAlerta('success', 'Información', 'Usuario actualizado exitosamente !!!!');
              this.disabledTab(false, false, false, false, false);
            } catch (err) {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
              this.mensajeAlerta('error', 'Error', mensaje);
            }
          }
  
          if (this.indexTabUsuarios === 2) {
            this.guardarPermisosPredefinidos();
          }
  
          if (this.indexTabUsuarios === 1) {
            if (await this.guardarPermisosNodos() === true) {
              this.spin = false;
              this.mensajeAlerta('success', 'Información', 'Permisos guardados exitosamente !!! ');
              this.activaBotones('G');
              this.disabledTab(false, false, false, false, false);
            }
  
          }
          if (this.indexTabUsuarios === 4) {
  
            // this.aggrid.gridApi.stopEditing();
            // this.aggrid.gridApi.tabToPreviousCell();
            this.insertarElementoPerEsp();
            // compositePerEsp.GuardarDatos();
          }
          if (this.indexTabUsuarios === 3) {
            this.aggrid.gridApi.stopEditing();
            // this.aggrid.gridApi.tabToPreviousCell();
            // this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultSegNumPV.objeto].setFocusedCell(
            //   this.segMaeUsuarioService.detnumeracionarray[this.indicador].indexOf(this.detNumeracionSel), 'NUM_AUTORIZACION');
            this.guardarDatosNum();
          }
        } else {
          this.tokenusuario = '';
          this.message.add({
            key: 'segMaeUsuToken',
            severity: 'error',
            summary: 'Error de Actualización',
            detail: 'Token ingresado no coincide. Verifique su correo'
          });
        }
      
    } else {
      
        const resp = await this.utilitariosService.validarTokenActAdm(this.tokenusuario, 'admin');
        if (resp === true) {
          this.tokenusuario = '';
          this.displayToken = false;
          if (this.indexTabUsuarios === 0) {
            try {
              await this.segMaeUsuarioService.actualizarUsuario(this.usuIdentificacionT, this.usuClaveT, this.usuRuciT, this.usuApellidoT, this.usuNombreT, this.comCodigoT,
                this.usuPerfilT, this.usuFechaInicioT, this.usuFechaFinalT, this.bodCodigoT, this.bodCodigoDevT, this.strVenCodigoT, this.strCorreoPinT, this.strTelefonoPinT, this.usuautenticacionT, this.usualmacenardocT, this.usuvisualizardocT,
                this.usuverpreciosconsuexist,
                this.usuvertotcxc,
                this.usutodclientes,//GSRF
              this.usutodpedidos,//GSRF
              );//CDPJ);//CDPJ AGREGUÉ verpreconexist
              this.mensajeAlerta('success', 'Información', 'Usuario actualizado exitosamente !!!!');
              this.disabledTab(false, false, false, false, false);
            } catch (err) {
              let mensaje: string;
              mensaje = this.errorService.generarMensajeErrorCompleto(err.error);
              this.mensajeAlerta('error', 'Error', mensaje);
            }
          }
  
          if (this.indexTabUsuarios === 2) {
            this.guardarPermisosPredefinidos();
          }
  
          if (this.indexTabUsuarios === 1) {
            if (await this.guardarPermisosNodos() === true) {
              this.spin = false;
              this.mensajeAlerta('success', 'Información', 'Permisos guardados exitosamente !!! ');
              this.activaBotones('G');
              this.disabledTab(false, false, false, false, false);
            }
  
          }
          if (this.indexTabUsuarios === 4) {
  
            // this.aggrid.gridApi.stopEditing();
            // this.aggrid.gridApi.tabToPreviousCell();
            this.insertarElementoPerEsp();
            // compositePerEsp.GuardarDatos();
          }
          if (this.indexTabUsuarios === 3) {
            this.aggrid.gridApi.stopEditing();
            // this.aggrid.gridApi.tabToPreviousCell();
            // this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultSegNumPV.objeto].setFocusedCell(
            //   this.segMaeUsuarioService.detnumeracionarray[this.indicador].indexOf(this.detNumeracionSel), 'NUM_AUTORIZACION');
            this.guardarDatosNum();
          }
        } else {
          this.tokenusuario = '';
          this.message.add({
            key: 'segMaeUsuToken',
            severity: 'error',
            summary: 'Error de Actualización',
            detail: 'Token ingresado no coincide. Verifique su correo'
          });
        }
      
    }
  }

  cancelarToken(){
    this.displayToken = false;
  }

}
