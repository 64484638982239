import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import {DatePipe} from '@angular/common';
import {InvDetAjuInvNeg, InvEncAjuInvNeg, InvTrnAjustes} from '../invinterfaces/invtrnajustes';
import {Observable} from 'rxjs';
import {MessageService} from 'primeng';
import {ErroresBaseDatosService} from '../../servicios/errores-base-datos.service';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {ConfInicialesService} from '../../servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class InvtrnajustesService {
  
  public detTrnAjustes: InvDetAjuInvNeg[][] = [];
  public encTrnAjustes: InvEncAjuInvNeg[] = [];
  public detTrnAjustesAut: InvTrnAjustes[][] = [];
  public configDecimales: any[];
  public btnNuevo: boolean[] = [];
  public btnGuardar: boolean[] = [];
  public btnEliminar: boolean[] = [];
  public btnBuscar: boolean[] = [];
  public btnNuevoDet: boolean[] = [];
  public btnEliminarDet: boolean[] = [];
  public btnCancelarDet: boolean[] = [];
  
  private way: string;
  
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private init: NuevoComponentService,
              private datePipe: DatePipe,
              private messageService: MessageService,
              private errorService: ErroresBaseDatosService,
              private confIniciales: ConfInicialesService,
              private auditoriagral: AuditoriagralService) {
    this.way = sessionStorage.getItem('way');
  }
  
  actualizarDetDoc(strComprobante) {
    return this.http.post<any[]>(this.way + '/inventario/trnajustes/a677caac9aeeb800', {
        elementos: {
          comprobante: strComprobante
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  verificarexistenciaAjuste(strHora, strFecha) {
    return this.http.post<any[]>(this.way + '/inventario/trnajustes/1f76e4f7719255000', {
        elementos: {
          hora: strHora,
          fecha: strFecha
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  contarRegistrosAjuste(strHora, strFecha) {
    return this.http.post<any[]>(this.way + '/inventario/trnajustes/662fddba12e778000000', {
        elementos: {
          hora: strHora,
          fecha: strFecha
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  listarDatosAjuste(strComprobante) {
    return this.http.post<any[]>(this.way + '/inventario/trnajustes/2b2e755b6abb8e0000', {
        elementos: {
          comprobante: strComprobante
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  listarDatosAut(strNumero) {
    return this.http.post<any[]>(this.way + '/inventario/trnajustes/acb9d5355dc2c00', {
        elementos: {
          numero: strNumero
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  mostrarEncabezado(strComprbante) {
    return this.http.post<any[]>(this.way + '/inventario/trnajustes/16c73bb56dd763000', {
        elementos: {
          comprobante: strComprbante
        },
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  
  
  obtenerConfig(campo, tabla): number {
    let numDecimales = 0;
    const decimales = this.configDecimales.find(element => element.NUMDEC_CAMPO === campo && element.NUMDEC_TIPDOC === tabla);
    if (decimales !== undefined) {
      numDecimales = decimales.NUMDEC_NUMDEC;
    } else {
      numDecimales = 2;
    }
    console.log(numDecimales);
    
    return numDecimales;
  }
  
  getDecimales(valor: string) {
    return this.http.post<any>(this.way + '/inventario/trnajustes/83bad7325655600', {
        elementos: {
          NUMDEC_FORMA: valor,
        }
      }, this.confIniciales.httpOptions()
    ).toPromise();
  }
  
  MostrarDatosAdicionales(artCodigo, fecha, hora) {
    return this.http.post<any>(this.way + '/inventario/trnajustes/c2fae6abb8e2980', {
      elementos: {
        p_art_codigo: artCodigo,
        p_fecha: fecha,
        p_hora: hora,
        p_com_codigo: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  encontrarAsiNro(strComprobante) {
    return this.http.post(this.way + '/inventario/trnajustes/76df2bb55c95f80', {
      elementos: {
        comprobante: strComprobante
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  
  insertarTrnKardex(item: InvDetAjuInvNeg) {
    const fechaAct = this.encTrnAjustes[this.init.tabs[this.init.tabindex].indice].FECHA;
    let strFecha = '';
    
    if (fechaAct.toString().includes('-')) {
      strFecha = this.datePipe.transform(fechaAct, 'dd/MM/yyyy');
    }
    
    let strHora = '';
    
    if (this.encTrnAjustes[this.init.tabs[this.init.tabindex].indice].HORA.toString().includes('-')) {
      const time = this.encTrnAjustes[this.init.tabs[this.init.tabindex].indice].HORA;
      const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
      const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
      strHora = hora + ':' + minutos;
    } else {
      strHora = this.encTrnAjustes[this.init.tabs[this.init.tabindex].indice].HORA;
    }
    
    return this.http.post<any>(this.way + '/inventario/trnajustes/4afceeef456db0', {
      elementos: {
        p_ART_codigo_1: item.ART_CODIGO,
        p_BOD_codigo_3: item.BOD_CODIGO,
        p_TRNART_fechaes_4: strFecha,
        p_TRNART_tipo_5: 'AC',
        p_TRNART_hora_6: strHora,
        p_TRNART_numero_7: item.COMPROBANTE,
        p_TRNART_referencia_8: item.TRNART_REFERENCIA,
        p_TRNART_cantidad_9: 0,
        p_TRNART_costot_10: Number(item.TRNART_COSTOT),
        p_COM_codigo_2: '01',
        p_TRNART_origen_11: 'INVA',
        p_con_codigo: item.CON_CODIGO,
        p_cen_codigo: item.CEN_CODIGO
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  
  eliminarTrnKardex(item: InvDetAjuInvNeg) {
    return this.http.post<any>(this.way + '/inventario/trnajustes/3ab2eeef456dae', {
      elementos: {
        p_BOD_codigo_3: item.BOD_CODIGO,
        p_ART_codigo_1: item.ART_CODIGO,
        p_TRNART_numero_4: item.COMPROBANTE,
        p_COM_codigo_2: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  guardarEncAjuInvNeg() {
    const fechaAct = this.encTrnAjustes[this.init.tabs[this.init.tabindex].indice].FECHA;
    let strFecha = '';
    
    if (fechaAct.toString().includes('-')) {
      strFecha = this.datePipe.transform(fechaAct, 'dd/MM/yyyy');
    }
    
    
    const time = this.encTrnAjustes[this.init.tabs[this.init.tabindex].indice].HORA;
    const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
    const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
    const strHora = hora + ':' + minutos;
    
    
    return this.http.post<any>(this.way + '/inventario/trnajustes/8794ebb153f5e00', {
      elementos: {
        p_ENCAJUINVNEG_comprobante_1: '',
        p_ENCAJUINVNEG_fecha_2: strFecha,
        p_ENCAJUINVNEG_hora_3: strHora,
        p_usuidentificacion_4: this.usuario.identificacion
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  actualizarEncAjuInvNeg() {
    const fechaAct = this.encTrnAjustes[this.init.tabs[this.init.tabindex].indice].FECHA;
    let strFecha = '';
    
    if (fechaAct.toString().includes('-')) {
      strFecha = this.datePipe.transform(fechaAct, 'dd/MM/yyyy');
    } else {
      strFecha = this.encTrnAjustes[this.init.tabs[this.init.tabindex].indice].FECHA;
    }
    
    
    let strHora = '';
    
    if (this.encTrnAjustes[this.init.tabs[this.init.tabindex].indice].HORA.toString().includes('-')) {
      const time = this.encTrnAjustes[this.init.tabs[this.init.tabindex].indice].HORA;
      const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
      const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
      strHora = hora + ':' + minutos;
    } else {
      strHora = this.encTrnAjustes[this.init.tabs[this.init.tabindex].indice].HORA;
    }
    
    return this.http.post<any>(this.way + '/inventario/trnajustes/533aebb153f5dc0', {
      elementos: {
        p_ENCAJUINVNEG_comprobante_1: this.encTrnAjustes[this.init.tabs[this.init.tabindex].indice].COMPROBANTE,
        p_ENCAJUINVNEG_fecha_2: strFecha,
        p_ENCAJUINVNEG_hora_3: strHora,
        p_usuidentificacion_4: this.usuario.identificacion
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarDetAjuNeg() {
    return this.http.post<any>(this.way + '/inventario/trnajustes/eacacd77553f6000', {
      elementos: {
        p_COMPROBANTE_1: this.encTrnAjustes[this.init.tabs[this.init.tabindex].indice].COMPROBANTE,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarEncAjuNeg() {
    return this.http.post<any>(this.way + '/inventario/trnajustes/eacacebb153f6000', {
      elementos: {
        p_COMPROBANTE_1: this.encTrnAjustes[this.init.tabs[this.init.tabindex].indice].COMPROBANTE,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  activarBotonesDet(boolNuevo, boolEliminar, boolCancelar) {
    this.btnNuevoDet[this.init.tabs[this.init.tabindex].indice] = boolNuevo;
    this.btnEliminarDet[this.init.tabs[this.init.tabindex].indice] = boolEliminar;
    this.btnCancelarDet[this.init.tabs[this.init.tabindex].indice] = boolCancelar;
  }
  
  existenciaActualArticulo(strArtCodigo, strBodCodigo): Observable<any[]> {
    
    let strHora = '';
    
    if (this.encTrnAjustes[this.init.tabs[this.init.tabindex].indice].HORA.toString().includes('-')) {
      const time = this.encTrnAjustes[this.init.tabs[this.init.tabindex].indice].HORA;
      const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
      const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
      strHora = hora + ':' + minutos;
    } else {
      strHora = this.encTrnAjustes[this.init.tabs[this.init.tabindex].indice].HORA;
    }
    
    const fechaAct = this.encTrnAjustes[this.init.tabs[this.init.tabindex].indice].FECHA;
    let strFecha = '';
    
    if (fechaAct.toString().includes('-')) {
      strFecha = this.datePipe.transform(fechaAct, 'dd/MM/yyyy');
    }
    
    
    return this.http.post<any[]>(this.way + '/inventario/trnajustes/3b8c533be56fb2', {
      elementos: {
        art_codigo: strArtCodigo,
        bod_codigo: strBodCodigo,
        fecha: strFecha,
        hora: strHora
      }
    }, this.confIniciales.httpOptions());
    
  }

  costoActualArticulo(strArtCodigo): Observable<any[]> {
    
    let strHora = '';
    
    if (this.encTrnAjustes[this.init.tabs[this.init.tabindex].indice].HORA.toString().includes('-')) {
      const time = this.encTrnAjustes[this.init.tabs[this.init.tabindex].indice].HORA;
      const minutos = String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes();
      const hora = String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours();
      strHora = hora + ':' + minutos;
    } else {
      strHora = this.encTrnAjustes[this.init.tabs[this.init.tabindex].indice].HORA;
    }
    
    const fechaAct = this.encTrnAjustes[this.init.tabs[this.init.tabindex].indice].FECHA;
    let strFecha = '';
    
    if (fechaAct.toString().includes('-')) {
      strFecha = this.datePipe.transform(fechaAct, 'dd/MM/yyyy');
    }
    
    return this.http.post<any[]>(this.way + '/inventario/trnajustes/331cea99df2b7c', {
      elementos: {
        art_codigo: strArtCodigo,
        fecha: strFecha,
        hora: strHora
      }
    }, this.confIniciales.httpOptions());
    
  }
  
  insertarDetAjuNeg(item: InvDetAjuInvNeg) {
    return this.http.post<any>(this.way + '/inventario/trnajustes/95f8d77553f5e00', {
      elementos: {
        p_ENCAJUINVNEG_comprobante_1: this.encTrnAjustes[this.init.tabs[this.init.tabindex].indice].COMPROBANTE,
        p_BOD_codigo_2: item.BOD_CODIGO,
        p_ART_codigo_3: item.ART_CODIGO,
        p_TRNART_referencia_4: 'AJUSTE MANUAL',
        p_TRNART_costot_5: item.TRNART_COSTOT,
        p_TRNART_origen_6: 'INVA',
        p_CON_codigo_7: item.CON_CODIGO,
        p_CEN_codigo_8: item.CEN_CODIGO,
        p_LINEA_9: 0,
        p_ART_existencia_10: item.ART_EXISTENCIA,
        p_ART_costo_11: item.ART_COSTO,
        p_COSTO_ajuste_12: item.COSTO_AJUSTE
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  generarAsiento() {
    const fecha = this.encTrnAjustes[this.init.tabs[this.init.tabindex].indice].FECHA;
    const mes = fecha.getUTCMonth() + 1;
    let strFecha = '';
    
    if (fecha.toString().includes('-')) {
      strFecha = this.datePipe.transform(fecha, 'dd/MM/yyyy');
    }
    
    
    return this.http.post<any>(this.way + '/inventario/trnajustes/20ebbb6adab9280000', {
      elementos: {
        p_fecha_es: strFecha,
        p_mes: String(mes),
        p_concepto: 'AJUSTE MANUAL',
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: 'AAIN',
        p_trnart_numero: this.encTrnAjustes[this.init.tabs[this.init.tabindex].indice].COMPROBANTE
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  eliminarAsientoCont() {
    return this.http.post<any>(this.way + '/inventario/trnajustes/eacad2dab9276000', {
      elementos: {
        p_ASI_nro: this.encTrnAjustes[this.init.tabs[this.init.tabindex].indice].ASI_NRO,
        p_COM_codigo_2: '01'
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  
  mensajeAlerta(tipo, titulo, msg) {
    this.messageService.add({
      key: 'invtrnajustes',
      severity: tipo,
      summary: titulo,
      detail: msg
    });
  }
  
  
  
  
}
